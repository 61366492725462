import React from "react";
import Navbar from "../../views/LandingPage/Navbar";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import BXIFLOWIMAGE from "../../assets/HomePageImages/Buysellimg.png";
import JuspayLogo from "../../assets/Juspaylogo.png";
import Signzy from "../../assets/HomePageImages/signzy.png";
import Escrowpay from "../../assets/HomePageImages/Escrowpay.png";
import BillDesk from "../../assets/HomePageImages/billdeskimg.png";
import Unada from "../../assets/HomePageImages/Unada.png";
import PineLabs from "../../assets/HomePageImages/PineLabs.png";
import GridItem from "./GridItem";
import Fade from "react-reveal/Fade";
import AnimationOnImg from "./Animation/AnimationOnImg";
import Footer from "./Footer";
import PricingDetails from "../../pages/Register/PricingDetails";
import ShadowBox from './ShadowBox';
import Sell_product from '../../assets/HomePageImages/seller.svg';
import Money_bag from '../../assets/HomePageImages/coiniconfinal.svg';
import Cooperation from '../../assets/HomePageImages/bussinessdevelopment.png';
import Clip_path from '../../assets/HomePageImages/Network.png';
import Rotate_arrow from '../../assets/HomePageImages/newcash.svg';
import Add_to_cart from '../../assets/HomePageImages/whatuwant.png';
import Application from '../../assets/HomePageImages/multiplecategories.png';
import Rotate_arrow_black from '../../assets/HomePageImages/rotate_arrow.svg';
import Best_deal from '../../assets/HomePageImages/Bdeal.png';
import Shop_bag from '../../assets/HomePageImages/bwbcoin.png';

function Marketplace() {
  const HandleFlick = () => {
    navigate('/', {
      state: 'ABClick',
    });
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="inherit"
            component="span"
            sx={{
              ...headingStyle,
              fontSize: {
                xl: '35px',
                lg: '35px',
                md: '35px',
                sm: '25px',
                xs: '25px',
              },
              fontWeight: 600,
              textTransform: 'none',
            }}
          >
            Explore Inventory Available on the Marketplace
          </Typography>
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontSize: {
                  xl: '35px',
                  lg: '35px',
                  md: '35px',
                  sm: '25px',
                  xs: '25px',
                },
                fontWeight: 600,
                textTransform: 'none',
              }}
            >
              Active Categories
            </Typography>
          </Fade>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ padding: '20px 60px' }}>
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Airline.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/AirlineWhite.svg`}
          label="Airline Ticket"
          name="Airlines Tickets"
          width={''}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Hotel.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/HotelWhite.svg`}
          label="Hotel"
          name="Hotel"
          mt={-0.2}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/OfficeSupply.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/OfficeSupplyWhite.svg`}
          label="Office Supply"
          name="Office Supply"
          width={'28px'}
          mt={0.6}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/RestaurantQSR.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/RestaurantQSRWhite.svg`}
          label="Restaurant QSR"
          name="QSR"
          width={'38px'}
          mt={-0.5}
        />
        <Grid item xs={6} sm={6} md={0.8} lg={0.8} xl={0.8}>
          <Box sx={categoriesEmptyBoxDesign} />
        </Grid>
        <Grid item xs={6} sm={6} md={0.8} lg={0.8} xl={0.8}>
          <Box sx={categoriesEmptyBoxDesign} />
        </Grid>
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Media.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/MediaWhite.svg`}
          label="Media"
          name="Media"
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Textile.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/TextileWhite.svg`}
          label="Textile"
          name="Textile"
          width={'40px'}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/FMCG.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/FMCGWhite.svg`}
          label="FMCG"
          name="FMCG"
          width={'35px'}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Mobility.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/MobilityWhite.svg`}
          label="Mobility"
          name="Mobility"
          width={'45px'}
          mt={-1.5}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Lifestyle.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/LifestyleWhite.svg`}
          label="Lifestyle"
          name="Lifestyle"
          width={'38px'}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Events.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/EventsWhite.svg`}
          label="Entertainment & Events"
          name="Entertainment & Events"
          width={'35px'}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Electronics.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/ElectronicsWhite.svg`}
          label="Electronics"
          name="Electronics"
          width={'35px'}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Other.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/OtherWhite.svg`}
          label="Other"
          name="Others"
          width={'25px'}
          mt={1.2}
        />
        <Grid item xs={6} sm={6} md={0.8} lg={0.8} xl={0.8}>
          <Box sx={categoriesEmptyBoxDesign} />
        </Grid>
      </Grid>
      <Paper
        elevation={0}
        sx={{
          height: {
            xl: '80vh',
            lg: '80vh',
            md: '80vh',
            sm: '50vh',
            xs: '50vh',
          },
          width: '80%',
          mx: 'auto',
          mt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontSize: {
                  xs: '22px',
                  sm: '25px',
                  md: '28px',
                  lg: '28px',
                  xl: '28px',
                },
              }}
            >
              To Buy & Sell How
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{
            width: '100%',
            mx: 'auto',
            mt: 5,
            height: '95%',
          }}
        >
          <Box
            component={'img'}
            src={BXIFLOWIMAGE}
            alt="HBS"
            sx={{
              width: '100%',
              height: { xl: '70%', lg: '70%', md: '70%', sm: '60%', xs: '60%' },
              marginTop: '5px',
              marginLeft: {
                xl: '30px',
                lg: '30px',
                md: '30px',
                sm: '0px',
                xs: '0px',
              },
            }}
          />
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          height: {
            xl: '90vh',
            lg: '90vh',
            md: '90vh',
            sm: '100vh',
            xs: '100vh',
          },
          width: '100%',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: { xl: 5, lg: 5, md: 5, sm: 2, xs: 2 },
              // marginBottom: "20px",
            }}
          >
            <Fade top duration={1000}>
              <Typography
                variant="inherit"
                component="span"
                sx={{ ...headingStyle, fontWeight: 600 }}
              >
                Seller Benefits
              </Typography>
            </Fade>
          </Box>
          <Box sx={{ mt: { xl: 3, lg: 3, md: 3, sm: 1, xs: 1 } }}>
            <Grid
              container
              sx={{
                width: {
                  xl: '70%',
                  lg: '70%',
                  md: '70%',
                  sm: '90%',
                  xs: '90%',
                },
                mx: 'auto',
              }}
            >
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Sell_product}
                    title={'Sell Your Products/Services'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Money_bag}
                    title={'Earn Barter Coins'}
                    height={'60px'}
                    width={'60px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Cooperation}
                    title={'New Business Development'}
                    height={'48px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Clip_path}
                    title={'Network'}
                    height={'56px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Rotate_arrow}
                    title={'New Cash Flow'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: { xl: 6, lg: 4, md: 4, sm: 1, xs: 1 },
              marginBottom: {
                xl: '30px',
                lg: '30px',
                md: '30px',
                sm: '5px',
                xs: '5px',
              },
            }}
          >
            <Fade top duration={1000}>
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  ...headingStyle,
                  fontWeight: 600,
                }}
              >
                Buyer Benefits
              </Typography>
            </Fade>
          </Box>
          <Box
            sx={{
              marginBottom: {
                xl: '00px',
                lg: '00px',
                md: '00px',
                sm: '25px',
                xs: '25px',
              },
            }}
          >
            <Grid
              container
              sx={{
                width: {
                  xl: '70%',
                  lg: '70%',
                  md: '70%',
                  sm: '90%',
                  xs: '90%',
                },
                mx: 'auto',
              }}
            >
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Add_to_cart}
                    title={'Buy What You Want'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Application}
                    title={'Multiple Categories To Choose From'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Rotate_arrow_black}
                    title={'Save Cash Flow'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Best_deal}
                    title={'Get The Best Deals'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid item xs={12} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Shop_bag}
                    title={'Buy With Barter Coins'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      {/* Our Business Accelerators */}
      <Box sx={{ marginTop: '60px', marginBottom: '50px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{ ...headingStyle }}
            >
              TRUST & HOW
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{
            width: { xl: '70%', lg: '70%', md: '70%', sm: '100%', xs: '100%' },
            mx: 'auto',
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid item xl={3} lg={3} xs={3} sm={3} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={Signzy}
                    alt="signzy"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '50%',
                        lg: '50%',
                        md: '50%',
                        sm: '60%',
                        xs: '100%',
                      },
                      mt: '15px',
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} xs={3} sm={3} md={3}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={Unada}
                    alt="Unada"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '30%',
                        lg: '30%',
                        md: '30%',
                        sm: '40%',
                        xs: '100%',
                      },
                      mt: '20px',
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} xs={3} sm={3} md={3}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'end',
                }}
              >
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={BillDesk}
                    alt="BillDesk"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '50%',
                        lg: '50%',
                        md: '50%',
                        sm: '60%',
                        xs: '100%',
                      },

                      mt: '20px',
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} xs={3} sm={3} md={3}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'end',
                }}
              >
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={Escrowpay}
                    alt="Escrowpay"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '50%',
                        lg: '50%',
                        md: '50%',
                        sm: '60%',
                        xs: '100%',
                      },
                      mt: '20px',
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: '90%', mx: 'auto', mt: 2 }}>
          <Typography sx={contentStyleee}>
            At BXI, we don't just talk trust, we supercharge it!{' '}
          </Typography>
          <Typography sx={contentStyleee}>
            <span style={boldTextStyle}>
            Unada Labs
            </span>
            , a leader in disruptive technology, has been instrumental in architecting BXI's entire platform, revolutionizing B2B barter by fully digitizing and optimizing the process.
          </Typography>
          <Typography sx={contentStyleee}>
            <span style={boldTextStyle}>
              
              Escrowpay{' '}
            </span>
            , is a trusted partner that eliminates the risk of fraud in
            transactions. Buy and sell with confidence as Escrowpay holds your
            payment securely until you receive your goods or services, ensuring
            a smooth and transparent experience.
          </Typography>
        </Box>
        <Box sx={{ width: '90%', mx: 'auto', mt: 2 }}>
          <Typography sx={contentStyleee}>
            Award Winning Platform <span style={boldTextStyle}>Signzy </span>{' '}
            secures the marketplace by helping us onboarding ROC regulated
            organizations. This helps our members to barter trade with reputable
            and trustworthy companies only.
          </Typography>
          <Typography sx={contentStyleee}>
            <span style={boldTextStyle}>BillDesk -</span> eNACH(auto debit) is
            facilitated by Billdesk which ensures timely receivable of GST, TDS,
            and platform fees in time and can be deposited as per laws.
            </Typography>
        </Box>
      </Box>
<PricingDetails />

    </>
  );
}

export default Marketplace;

const categoriesEmptyBoxDesign = {
  display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
  border: '1.5px solid #2261A2',
  borderRadius: ' 15.121169090270996px',
  width: '100%',
  height: '94.1357421875px',
};

const headingStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '20px',
    sm: '20px',
    md: '30px',
    lg: '30px',
    xl: '30px',
  },
  lineHeight: '65px',
  display: 'flex',
  alignItems: 'center',
  // letterSpacing: "-1.62343px",
  color: '#0D0E0E',
  marginRight: '10px',
  textTransform: 'uppercase',
  textAlign: 'center',
};

const gradientText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '30px',
    sm: '30px',
    md: '30px',
    lg: '25px',
    xl: '25px',
  },
  textTransform: 'uppercase',
  lineHeight: '65px',
  display: 'flex',
  alignItems: 'center',
  background:
    'linear-gradient(90deg, rgba(55, 93, 187, 1), rgba(7, 167, 247, 1))',

  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
};

const contentStyleee = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '12px' },
  // display: "flex",
  alignItems: 'center',
  color: 'rgba(73, 75, 122, 1)',
  marginBottom: '15px',
};

const boldTextStyle = {
  fontWeight: 'bold',
};
