import { Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
// import AirlineIcon from "../../assets/FilterCarousel/AirlineIcon.svg";
// import ElectronicsIcon from "../../assets/FilterCarousel/ElectronicsIcon.svg";
// import EntertainmentAndEventsIcon from "../../assets/FilterCarousel/EntertainmentAndEventsIcon.svg";
// import FMCGIcon from "../../assets/FilterCarousel/FMCGIcon.svg";
// import HotelIcon from "../../assets/FilterCarousel/HotelIcon.svg";
// import LifestyleIcon from "../../assets/FilterCarousel/LifestyleIcon.svg";
// import MediaIcon from "../../assets/FilterCarousel/MediaIcon.svg";
// import MobilityIcon from "../../assets/FilterCarousel/MobilityIcon.svg";
// import OfficeSupplyIcon from "../../assets/FilterCarousel/OfficeSupplyIcon.svg";
// import OthersIcon from "../../assets/FilterCarousel/OthersIcon.svg";
// import QSRIcon from "../../assets/FilterCarousel/QSRIcon.svg";
// import TextileIcon from "../../assets/FilterCarousel/TextileIcon.svg";
import GoLeftImage from "../../assets/Images/CommonImages/GoLeftImg.png";
import GoRightImage from "../../assets/Images/CommonImages/GoRightImg.png";
import { getCompanyType } from "../../redux/action/Company/CompanyTypeActions";
import { getCompanyDetails } from "../../redux/action/CompanyActions";
import { getProduct } from "../../redux/action/Home-Filter/products";
import sendEvents from "../../utils/sendEvents";

import "./Carousel.css";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    maxWidth: "100%",
    "@media (min-width: 768px)": {
      maxWidth: "calc(100% / 7)",
    },
  },
}));
// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 5,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// const CustomLeftArrow = () => {
//   return (
//     <Button
//       sx={{
//         width: "40px",
//         height: "auto",
//         position: "absolute",
//       }}
//     >
//       <img src={GoLeftImage} alt="GoLeftImage" width={"100%"} height={"100%"} />
//     </Button>
//   );
// };

export let Category_Id = "";
export let Category_Ids = null;

function CustomRightArrow({ onClick }) {
  function handleClick() {
    onClick();
  }

  return (
    <Button
      onClick={handleClick}
      sx={{
        background: "transparent",
        position: "absolute",
        right: {
          xl: "-15px",
          lg: "-15px",
          md: "-20px",
          sm: "-20px",
          xs: "-20px",
        },
        width: "auto",
        height: "auto",
        border: "none",
        cursor: "pointer",
        padding: "0",
        outline: "none",
        justifyItems: "center",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
        marginTop: "10px",
      }}
      aria-label="Go to next slide"
      // className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
    >
      <Box
        component={"img"}
        src={GoRightImage}
        alt="GoRightImage"
        sx={{
          width: {
            xl: "40px",
            lg: "40px",
            md: "25px",
            sm: "25px",
            xs: "25px",
          },
        }}
        height={"auto"}
      />
    </Button>
  );
}

function CustomLeftArrow({ onClick }) {
  function handleClick() {
    onClick();
  }

  return (
    <Button
      onClick={handleClick}
      sx={{
        background: "transparent",
        position: "absolute",
        left: {
          xl: "-15px",
          lg: "-15px",
          md: "-20px",
          sm: "-20px",
          xs: "-20px",
        },
        width: "auto",
        height: "auto",
        border: "none",
        cursor: "pointer",
        padding: "0",
        outline: "none",
        justifyItems: "center",
        alignItems: "center",
        display: "flex",
        alignContent: "center",
        marginTop: {
          xl: "10px",
          lg: "10px",
          md: "10px",
          sm: "10px",
          xs: "10px",
        },
      }}
      aria-label="Go to next slide"
      // className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
    >
      <Box
        component={"img"}
        src={GoLeftImage}
        alt="GoLeftImage"
        sx={{
          width: {
            xl: "40px",
            lg: "40px",
            md: "25px",
            sm: "25px",
            xs: "25px",
          },
        }}
        width={"40px"}
        height={"auto"}
      />
    </Button>
  );
}

const ProductDetailsCarousel = ({ onSendData }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [categoryId, setcategoryId] = useState("");
  const [categoryIdList, setcategoryIdList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  Category_Id = categoryId;
  Category_Ids = categoryIdList;
  let CategoryData = [];

  async function storecategory(props) {
    CategoryData.push(props);
  }

  const { CompanyTypeData } = useSelector((state) => state.CompanyType);

  const { company } = useSelector((state) => state.companyDetails);

  window.onload = () => {
    setRefresh(true);
  };
  window.addEventListener("beforeunload", function (event) {});

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProduct("", "", "", "", "", categoryIdList, refresh));
  }, [dispatch, categoryId, categoryIdList]);

  useEffect(() => {
    dispatch(getCompanyType());
  }, [dispatch]);

  return (
    <Paper
      sx={{
        width: "100%",
        height: "90%",
        minHeight: "80px",
        zIndex: 1,
        bgcolor: "transparent",
      }}
      elevation={0}
    >
      <Carousel
        additionalTransfrom={0}
        scrollable
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          superLargeDesktop: {
            breakpoint: {
              max: 4000,
              min: 1440,
            },
            items: 7,
            partialVisibilityGutter: 10,
          },

          largeDesktop: {
            breakpoint: {
              max: 2200,
              min: 1440,
            },
            items: 6,
            partialVisibilityGutter: 10,
          },
          desktop: {
            breakpoint: {
              max: 1440,
              min: 768,
            },
            items: 5,
            partialVisibilityGutter: 10,
          },
          tablet: {
            breakpoint: {
              max: 768,
              min: 464,
            },
            items: 3,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 320,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {CompanyTypeData?.length > 0 &&
          CompanyTypeData.slice()
            .sort((a, b) => a.CompanyTypeName.localeCompare(b.CompanyTypeName))
            .map((item, index) => {
              return (
                <Box
                  className={classes.boxContainer}
                  onClick={() => {
                    const data = {
                      carouselChnage: true,
                    };
                    onSendData(data);
                    localStorage.removeItem("myObj");
                    setRefresh(false);
                    storecategory(item._id);
                    setcategoryId(item._id);
                    if (!categoryIdList.some((user) => user === item._id)) {
                      setcategoryIdList([...categoryIdList, item._id]);
                    } else if (
                      categoryIdList.some((user) => user === item._id)
                    ) {
                      setcategoryIdList(
                        categoryIdList.filter((id) => id !== item._id)
                      );
                    }
                  }}
                  key={index}
                  sx={{
                    px: {
                      xl: "5px",
                      lg: "5px",
                      md: "5px",
                      sm: "0px",
                      xs: "0px",
                    },
                    borderRadius: "10px",
                    // mr: 1,
                    py: { xl: 1, lg: 1, md: 1, sm: 1.5, xs: 1.5 },
                    width: "auto",
                    // maxWidth: "150px",
                    display: "flex",
                    gap: {
                      xl: "5px",
                      lg: "5px",
                      md: "5px",
                      sm: "0px",
                      xs: "0px",
                    },
                    // height: "80px",
                    // bgcolor: "red",
                  }}
                >
                  <Paper
                    onClick={() => {
                      sendEvents("Click on category button on marketplace");
                    }}
                    sx={{
                      ...FilterPaper,
                      border: categoryIdList.includes(item._id)
                        ? "2px solid #445FD2"
                        : "2px solid #FFFFFF",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: categoryId !== item._id ? 500 : 600,
                      fontSize: {
                        xl: "13px",
                        lg: "13px",
                        md: "12px",
                        sm: "6px",
                        xs: "6px",
                      },
                      textAlign: "center",
                      mt: 0.5,
                      color: categoryId !== item._id ? "#6F6F6F" : "#445FD2",
                      "&:hover": {
                        border: "2px solid #E0F0FF",
                        cursor: "pointer",
                        color: "#445FD2",
                        fontWeight: 600,
                        background: "#E0F0FF",
                      },
                      // height: "50px",
                      // bgcolor: "green",
                    }}
                    key={index}
                    elevation={0}
                  >
                    {/* <img
                      src={item?.Icon}
                      alt="filter"
                      width="35px"
                      height="auto"
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '5px',
                      }}
                    /> */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={item?.Icon}
                        alt="filter"
                        sx={{
                          width: {
                            xl: "35px",
                            lg: "35px",
                            md: "35px",
                            sm: "25px",
                            xs: "25px",
                          },
                          height: "auto",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        textAlign: "center",
                        color: "#6F6F6F",
                      }}
                    >
                      {item.CompanyTypeName}
                    </Typography>
                  </Paper>
                </Box>
              );
            })}
      </Carousel>
    </Paper>
  );
};

export default ProductDetailsCarousel;

const FilterPaper = {
  background: "#ffffff",
  // boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
  borderRadius: "20px",
  width: {
    xl: "100%",
    lg: "100%",
    md: "100%",
    sm: "90%",
    xs: "90%",
  },
  minWidth: { xl: "200px", lg: "200px", md: "160px", sm: "130px", xs: "100px" },
  // maxWidth: "10px",
  // px: "20px",
  mx: "auto",
  height: "80%",
  minHeight: { xl: "70px", lg: "70px", md: "70px", sm: "60px", xs: "60px" },
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  cursor: "pointer",
  // mx: {
  //   xl: 0,
  //   lg: 0,
  //   md: 0,
  //   sm: 1,
  //   xs: 1,
  // },
};
