const CommaSeparator = (props) => {
  const number = props?.Price;
  const roundedNumber = Number(number);
  const isValidNumber = !isNaN(roundedNumber);
  const formattedNumber = isValidNumber
    ? roundedNumber.toLocaleString("en-IN", {
        minimumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
      })
    : "";

  if (formattedNumber <= 0) {
    return "";
  } else if (!Number.isInteger(roundedNumber)) {
    return formattedNumber;
  } else {
    return formattedNumber + ".00";
  }
};

export default CommaSeparator;
