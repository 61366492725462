import React, { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { makeStyles } from "@material-ui/core/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { z } from "zod";
import { toast } from "react-toastify";
import Slider from "@mui/material/Slider";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import sendEvents from "../../utils/sendEvents";
import { MdModeEditOutline } from "react-icons/md";
import TostMessagesTop from "../../Component/Toast";
import { useDispatch, useSelector } from "react-redux";
import User from "../../assets/Images/profile/User.svg";
import About from "../../assets/Images/profile/About.svg";
import Phone from "../../assets/Images/profile/Phone.svg";
import City from "../../assets/Images/profile/Building.svg";
import Cancle from "../../assets/Images/profile/Icon (1).svg";
import Address from "../../assets/Images/profile/Address.svg";
import * as Styles from "../../components/common/Styled/Styles.js";
import CameraIcon from "../../assets/Images/profile/CameraIcon.svg";
import { EditProfile } from "../../redux/action/Profile/Edit-Profile";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import SecuredLetter from "../../assets/Images/profile/SecuredLetter.svg";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { notifications } from "../../redux/action/Notification/notification";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import { ProfileCompletions } from "../../redux/action/Profile/Profile-Completion";
import { getLoggedCompanyDetails } from "../../redux/action/Company/LoggedInCompanyAction";
import {
  useFetchCompaniesAllGstandAddresesBasedOnPanQuery,
  useUpdateCompanyInterstateGstPref,
} from "./CompanyHooks";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser.js";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
const ProfileInterest = () => {
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [AvatarIcon, setAvatarIcon] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [value, setValue] = React.useState("1");
  const [ProfileRequest, setProfileRequest] = useState([]);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openimgupload, setOpenImgUpload] = React.useState(false);
  const [openBankUpdate, setOpenBankUpdate] = useState(false);

  const [taxbtn, setTaxbtn] = React.useState("");
  const [WalletBalance, setBalance] = useState();
  const [hasExecuted, setHasExecuted] = useState(false);
  const [GSTIGSTOPEN, setGSTIGSTOPEN] = useState(false);
  const [GSTIGSTCLOSE, setGSTIGSTCLOSE] = useState(false);
  const [loading, setLoading] = useState(true);
  const [StoreMemberShipData, setMemberShipData] = useState();
  const [StoreBankList, setBankList] = useState();
  const [bankAccountLoader, setBankAccountLoader] = useState(false);

  const handleClick = () => {
    inputRef1.current.click();
  };
  const handleClick2 = () => {
    inputRef2.current.click();
  };
  // Form Feild
  const [formData, setFormData] = useState({
    companyName: "",
    merchantName: "",
    website: "",
    email: "",
    companyRegisteredAddress: "",
    city: "",
    pincode: "",
    phone: "",
  });

  // bank details

  const [bankformData, setBankFormData] = useState({
    bankAccountNo: "",
    ifsc: "",
    branchName: "",
    bankName: "",
  });

  const {
    data: companyGstandAddress,
    isLoading: companyGstAndAddressLoading,
    isError: companyGstAndAddressError,
  } = useFetchCompaniesAllGstandAddresesBasedOnPanQuery();
  // Modal
  const handleOpen = () => setOpen(true);
  const handleOpenImgUpload = () => setOpenImgUpload(true);
  const handleCloseImgUpload = () => {
    setOpenImgUpload(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpdateBank = () => {
    setOpenBankUpdate(false);
  };

  // check profile %
  const { ProfileCompletion } = useSelector((state) => state.profileCompletion);
  let findPercentage =
    typeof ProfileCompletion?.ProfileMessage === "string"
      ? ProfileCompletion?.ProfileMessage.split(" ")
      : null;

  let finalPercentage =
    findPercentage && findPercentage.length >= 4
      ? findPercentage[3]?.toString()?.split(".")
      : null;

  const { GetLoggedCompanyData } = useSelector(
    (state) => state.GetLoggedCompany
  );

  // send company communication
  async function CreateCommunication() {
    await axios
      .post("communcations/addCompanyCommunications")
      .then((res) => {});
  }

  // use useForm
  const {
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        address1: z.string().min(1),
        address2: z.string().min(1),
        pincode: z.string().min(1),
        city: z.string().min(1),
        state: z.string().min(1),
      })
    ),
  });

  useEffect(() => {
    dispatch(getLoggedCompanyDetails());
  }, []);

  // change gst
  const {
    mutate: UpdateCompanyInterstateGstPref,
    data: companyInterstateGstPrefData,
    isLoading: companyInterstateGstPrefLoading,
    isError: companyInterstateGstPrefError,
  } = useUpdateCompanyInterstateGstPref();

  const [BusinessName, setBusinessName] = useState(
    GetLoggedCompanyData?.companyName
      ? GetLoggedCompanyData?.companyName
      : GetLoggedCompanyData?.companyName
  );

  // GET wallet balance
  const reqBalance = async () => {
    await axios
      .get("wallet/mywallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.data?.balance);
      });
  };

  useEffect(() => {
    reqBalance();
    CreateCommunication();
  }, []);

  useEffect(() => {
    setBusinessName(GetLoggedCompanyData?.companyName);
  }, [GetLoggedCompanyData?.companyName]);

  const { data: loggedUser } = useGetAuthUser();
  console.log("loggedUser", loggedUser?.data);

  useEffect(() => {
    if (GetLoggedCompanyData != null && GetLoggedCompanyData != undefined) {
      if (WalletBalance < 4000000 && !hasExecuted) {
        dispatch(
          SendMessageOnWhatsApp(
            GetLoggedCompanyData?.phone,
            "negative_wallet_balance",
            "address",
            "Ayodahaya nagar",
            "tracking_code",
            "ORID23456FG78",
            "address",
            "Ayodahaya nagar"
          )
        );
        setHasExecuted(true);
      }

      if (GetLoggedCompanyData?.CompanyProfileVisit === false && !hasExecuted) {
        dispatch(
          SendMessageOnWhatsApp(
            GetLoggedCompanyData?.phone,
            "welcome_message",
            "address",
            "Ayodahaya nagar",
            "tracking_code",
            "ORID23456FG78",
            "address",
            "Ayodahaya nagar"
          )
        );
        setHasExecuted(true);
      }
    }
  }, [GetLoggedCompanyData, GetLoggedCompanyData?.CompanyProfileVisit]);

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
    refetch: userRefetch,
  } = useGetLoggedInUser();
  // who logged
  const { data: loggedInUserData } = useGetLoggedInUser();

  console.log("loggedInUserData", loggedInUserData);

  // all Key
  const contactDetails = [
    {
      imgSrcContact: User,
      labelContact1: "Name",
      labelContact2: userData?.data?.companyName,
    },
    {
      imgSrcContact: Address,
      labelContact1: "Address",
      labelContact2: userData?.data?.CompanyAddress?.AddressLine,
    },
    {
      imgSrcContact: City,
      labelContact1: "City",
      labelContact2: userData?.data?.CompanyAddress?.City,
    },
    {
      imgSrcContact: City,
      labelContact1: "State",
      labelContact2: userData?.data?.CompanyAddress?.State,
    },
    {
      imgSrcContact: About,
      labelContact1: "Postal Code",
      labelContact2: userData?.data?.CompanyAddress?.Pincode,
    },
    {
      imgSrcContact: Phone,
      labelContact1: "Phone Number",
      labelContact2: userData?.data?.phone,
    },
    {
      imgSrcContact: SecuredLetter,
      labelContact1: "E-mail ID",
      labelContact2: userData?.data?.email,
    },
    {
      imgSrcContact: SecuredLetter,
      labelContact1: "Website",
      labelContact2: userData?.data?.website,
    },
  ];
  // all Value
  const boxFileds = [
    {
      label: "Business Name",
      data: userData?.data?.companyName
        ? userData?.data?.companyName
        : userData?.data?.companyName,
    },
    {
      label: "Email",
      data: userData?.data?.email,
    },
    {
      label: "GST Number",
      data: userData?.data?.gst,
    },
    {
      label: "CIN",
      data: userData?.data?.cin,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(ProfileCompletions());
  }, [dispatch]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputBankChange = (e) => {
    setBankFormData({ ...bankformData, [e.target.name]: e.target.value });
  };

  const handleSubmits = (e) => {
    e.preventDefault();
    dispatch(EditProfile(formData));
    const AdminId = "650ac9c7b29820230639ab99";
    let receiver = AdminId;
    let sender = loggedInUserData?.data?._id;
    let message = `${loggedInUserData?.data?.companyName} company request generated profile changes`;
    let type = "Profile";
    dispatch(notifications(receiver, sender, message, type));
    setOpen(false);
    toast.success("Request Sent To Admin Successfully", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setReload(!reload);
  };

  const handleSubmitBankData = async () => {
    setBankAccountLoader(true);
    try {
      await axios
        .put(`company/update-company-bank-details`, {
          data: bankformData,
        })
        .then((res) => {
          if (res?.data?.success === false) {
            setBankAccountLoader(false);
            setOpenBankUpdate(false);
            toast.error(res?.data?.message, "error", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (res?.data?.success === true) {
            setBankAccountLoader(false);
            handleCloseUpdateBank();
            setOpenBankUpdate(false);
            toast.success("Bank Account Updated Successfully", "success", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    } catch (error) {
      setBankAccountLoader(false);
      handleCloseUpdateBank();
      setOpenBankUpdate(false);
      toast.error(error?.response?.data?.message, "error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  // GEt admin req
  const Profile_Request_Status = async () => {
    try {
      const response = await axios.get("company/admineditcompany", {
        withCredentials: true,
      });
      const data = response.data;
      setProfileRequest(data);
    } catch (error) {}
  };
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
      setAvatarIcon(fileObj);
      const url = URL.createObjectURL(fileObj);
      setImageUrl(url);
      handleOpenImgUpload();
    }
  };

  useEffect(() => {
    Profile_Request_Status();
  }, [reload]);

  let ProfileRequestLength = ProfileRequest?.length - 1;
  const ProfileRequestData = ProfileRequest[ProfileRequestLength];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // Get AVTAR
  const uploadAvatar = async () => {
    const formData = new FormData();
    formData.append("avatar", AvatarIcon);
    await axios
      .post("/api/v1/avatar/upload-avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        userRefetch();
        setReload(!reload);
        setAvatarIcon(null);
        setOpenImgUpload(false);
      })
      .catch((err) => {});
  };

  const Profile_Visit = async () => {
    try {
      await axios
        .put("communcations/updateCompanyCommunications", {
          template: "Welcome-BXI",
        })
        .then((response) => {});
      await axios.post("wallet/create_wallet").then((res) => {});
    } catch (error) {}
  };
  useEffect(() => {
    Profile_Visit();
  }, []);

  async function GetmemberShipData() {
    await axios.get("juspay/get-membership-data").then((res) => {
      setMemberShipData(res?.data?.data?.MembershipPlan?.PlanName);
    });
  }

  // const fetchAllBanksOfEnach = async () => {
  //   try {
  //     await axios.get("enach_mandate/get_enach_bank_list").then((res) => {
  //       setBankList(res?.data);
  //     });
  //   } catch (error) {}
  // };

  useEffect(() => {
    GetmemberShipData();
    // fetchAllBanksOfEnach();
  }, []);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <BreadCrumbHeader MainText={"Profile"} showbreadcrumb={true} />

        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              width: "20%",
              height: "200px",
              background: "#fff",
              borderRadius: "10px",
              // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              position: "relative",
            }}
          >
            <>
              {loading ? (
                <Box sx={{ width: "95%", mx: "auto" }}>
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    animation="wave"
                    sx={{ borderRadius: "10px", mt: 1, width: "100%" }}
                  />
                </Box>
              ) : (
                <>
                  <Typography
                    sx={{ ...Styles.textProfile, textAlign: "center", mt: 1 }}
                  >
                    Business Logo
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "150px",
                        height: "150px",
                      }}
                    >
                      <Box component="div" sx={Styles.outsideLogo}>
                        <Box component="div" sx={Styles.insideLogo}>
                          {userData?.data?.CompanyAvatar?.url ? (
                            <img
                              src={
                                userData?.data?.CompanyAvatar?.url ||
                                "https://bxi-development.s3.amazonaws.com/companyAvatars/03F46EC38968EAC7B1"
                              }
                              alt="ProfileBxi"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <Typography>Add Company Logo here</Typography>
                          )}
                        </Box>
                      </Box>
                      <input
                        style={{ display: "none" }}
                        ref={inputRef1}
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Box
                        onClick={() => {
                          handleClick();
                          sendEvents("Click on Camera");
                        }}
                        sx={{
                          position: "absolute",
                          bottom: 17,
                          right: 17,
                          cursor: "pointer",
                        }}
                      >
                        <img src={CameraIcon} alt="CameraIcon" />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </>
          </Box>
          <Box
            sx={{
              width: "40%",
              height: "200px",
              background: "#fff",
              borderRadius: "10px",
              display: "flex",
              gap: "10px",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: !userData?.data?.bankdetails?.bankAccountNo
                  ? "60%"
                  : "100%",
              }}
            >
              {finalPercentage?.at(0) === "100" ||
              finalPercentage?.at(0) === 100 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 1,
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Typography sx={Styles.headText}>
                    Profile Completed
                  </Typography>

                  <img
                    src={
                      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/companyAvatars/tick-mark.png"
                    }
                    alt="profile"
                    style={{ width: "80px", height: "80px" }}
                  />

                  <Button
                    endIcon={<MdModeEditOutline />}
                    onClick={() => {
                      handleOpen(null);
                      sendEvents("Click on account details");
                    }}
                    sx={{
                      width: "200px",
                      textTransform: "none",
                      color: "#778DA9",
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      textAlign: "center",
                      borderTop: "inherit",
                      fontStyle: "Poppins",
                    }}
                  >
                    Update Profile
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 1,
                    gap: "05px",
                  }}
                >
                  <Typography sx={Styles.headText}>
                    Profile Completion
                  </Typography>

                  <Typography sx={Styles.subText}>
                    Please submit accurate information so that your profile can
                    be completed.
                  </Typography>

                  {ProfileRequestData?.adminAprove === false ? (
                    <Typography
                      sx={{
                        width: 300,
                        paddingTop: 2,
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      Requested
                    </Typography>
                  ) : null}

                  <Box
                    sx={{
                      width: 300,
                      paddingTop: 1,
                    }}
                  >
                    <Slider
                      aria-label="Always visible"
                      value={
                        finalPercentage && finalPercentage.length > 0
                          ? Number(finalPercentage?.[0])
                          : 0
                      }
                      step={10}
                      valueLabelDisplay="on"
                      readOnly
                    />
                  </Box>
                  <Box>
                    <Button
                      endIcon={<MdModeEditOutline />}
                      onClick={() => {
                        handleOpen(null);
                        sendEvents("Click on account details");
                      }}
                      sx={Styles.AccDetail}
                    >
                      Account Details
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>

            {!userData?.data?.bankdetails?.bankAccountNo ? (
              <Box
                sx={{
                  bgcolor: "transparent",
                  height: "100%",
                  width: "35%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<MdModeEditOutline />}
                  sx={{ ...Styles.liveBtn, fontSize: "14px" }}
                  onClick={() => setOpenBankUpdate(true)}
                >
                  Update Bank Details
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              width: "40%",
              height: "200px",
              background: "#fff",
              borderRadius: "10px",
            }}
          >
            <Typography sx={{ ...Styles.headText, fontSize: "20px", mt: 2 }}>
              User Accounts
            </Typography>
            <Typography
              sx={{
                ...Styles.headText,
                fontSize: "14px",
                mt: 2,
                color: "#ADB8CC",
              }}
            >
              You can add members and assign them responsibilities and
              permissions.
            </Typography>
            <Box
              mt={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: "10px",
                width: {
                  xl: "90%",
                  lg: "90%",
                  md: "90%",
                  sm: "80%",
                  xs: "100%",
                },
                mx: "auto",
              }}
            >
              <Button
                variant="contained"
                sx={Styles.liveBtn}
                onClick={() => {
                  navigate("/home/company_members");
                  sendEvents("Click on view members");
                }}
              >
                View Users
              </Button>
              {loggedUser?.data?.superAdmin &&
              loggedUser.data?.roleAndPermission !== "No" ? (
                <Button
                  variant="outlined"
                  sx={Styles.draftBtn}
                  onClick={() => {
                    navigate("/home/addmember");
                    sendEvents("Click on add member");
                  }}
                >
                  Add Users
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            flexDirection: "row",
            textAlign: "center",
            width: "100%",
            gap: "02px",
          }}
        >
          <Box
            sx={{
              width: "35%",
              display: "flex",
              flexDirection: "row",
              mt: 1,
            }}
          >
            <Box sx={Styles.profileDeltailsGrid}>
              <Box
                mt={0}
                sx={{
                  display: "flex",
                  height: "auto",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {boxFileds?.map((el, idx) => {
                  return (
                    <Box sx={{ ...Styles.boxStyle, mt: 3 }} mt={3}>
                      <Typography sx={Styles.textBox}>{el.label}</Typography>
                      <Box sx={Styles.insideTextFeild}>
                        <Typography sx={Styles.textProfile}>
                          {el.data}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    height: "1px",
                    width: "100%",
                    mt: "25px",
                    backgroundImage: `linear-gradient(to right, #FFFFFF , #c6c7d0, #FFFFFF);`,
                  }}
                ></Box>
                {userData?.data?.InterStateGSTPref ? (
                  <Box sx={{ ...Styles.boxStyle, mt: 3 }} mt={3}>
                    <Typography sx={Styles.textBox}>
                      Selected <b>Interstate</b> Billing GST
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          ...Styles.insideTextFeild,
                          width: "135px",
                        }}
                      >
                        <Typography sx={Styles.textProfile}>
                          {userData?.data?.InterStateGSTPref}
                        </Typography>
                      </Box>
                      <Button
                        sx={Styles.CommonBtn}
                        onClick={() => setGSTIGSTOPEN(true)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        ...Styles.textBox,
                        fontSize: "14px",
                      }}
                    >
                      How do you wish to get your Inter State GST calculated?
                    </Typography>

                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={taxbtn}
                      onChange={(e) => {
                        setTaxbtn(e.target.value);
                      }}
                      sx={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        value="CGST + SGST"
                        control={<Radio size="medium" />}
                        label={
                          <span
                            style={{
                              ...Styles.TypographyStyle,
                              fontWeight: 450,
                              color: taxbtn === "SGST" ? "#445FD2" : "#6B7A99",
                            }}
                          >
                            CGST + SGST
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="IGST"
                        control={<Radio size="medium" />}
                        label={
                          <span
                            style={{
                              ...Styles.TypographyStyle,
                              fontWeight: 450,
                              color: taxbtn === "IGST" ? "#445FD2" : "#6B7A99",
                            }}
                          >
                            IGST
                          </span>
                        }
                      />
                      <Button
                        variant="contained"
                        disabled={
                          taxbtn === null || taxbtn === "" ? true : false
                        }
                        onClick={() => {
                          UpdateCompanyInterstateGstPref(taxbtn, {
                            onSuccess: () => {},
                          });
                          sendEvents("Click on confirm");
                        }}
                        sx={Styles.CommonBtn}
                      >
                        Confirm
                      </Button>
                    </RadioGroup>
                    <Typography sx={ErrorStyle}>
                      {errors["InterStateGST"]?.message}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    mt: 3,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={Styles.textBox}>
                    You are on
                    <span style={{ fontWeight: "600", fontSize: "16px" }}>
                      {" "}
                      {StoreMemberShipData}{" "}
                    </span>
                    Membership Plan
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ ...Styles.CommonBtn, fontSize: "12px" }}
                    onClick={() => navigate("/home/planpricing")}
                  >
                    View Membership
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ ...Styles.textBox, textAlign: "center" }}>
                    Please Sign Mandate for seller commission payment
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      ...Styles.CommonBtn,
                      width: "100%",
                      height: "35px",
                      fontSize: "14px",
                      mt: 1,
                    }}
                    onClick={() => navigate("/home/terms")}
                  >
                    Sign Mandate
                  </Button>
                </Box>
              </Box>

              <Modal
                open={GSTIGSTOPEN}
                onClose={GSTIGSTCLOSE}
                aria-labelledby="modal-modal-title"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transform: "translate(0%, 0%)",
                }}
              >
                <Box sx={Styles.modalDes}>
                  <Typography
                    sx={{
                      ...Styles.textBox,
                      fontSize: "14px",
                      mb: "20px",
                      mt: 2,
                    }}
                  >
                    How do you wish to get your Inter State GST calculated?
                  </Typography>

                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={taxbtn}
                    onChange={(e) => {
                      setTaxbtn(e.target.value);
                    }}
                    sx={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="CGST + SGST"
                      control={<Radio size="medium" />}
                      label={
                        <span
                          style={{
                            ...Styles.TypographyStyle,
                            fontWeight: 450,
                            color: taxbtn === "SGST" ? "#445FD2" : "#6B7A99",
                          }}
                        >
                          CGST + SGST
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="IGST"
                      control={<Radio size="medium" />}
                      label={
                        <span
                          style={{
                            ...Styles.TypographyStyle,
                            fontWeight: 450,
                            color: taxbtn === "IGST" ? "#445FD2" : "#6B7A99",
                          }}
                        >
                          IGST
                        </span>
                      }
                    />
                    <Button
                      variant="contained"
                      disabled={taxbtn === null || taxbtn === "" ? true : false}
                      onClick={() => {
                        UpdateCompanyInterstateGstPref(taxbtn, {
                          onSuccess: () => {
                            toast.success("GST Updated Successfully", {
                              position: "top-center",
                              autoClose: 2000,
                              hideProgressBar: false,
                            });
                            setGSTIGSTOPEN(false);
                          },
                        });
                        sendEvents("Click on confirm");
                      }}
                      sx={Styles.CommonBtn}
                    >
                      Confirm
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setGSTIGSTOPEN(false);
                      }}
                      sx={Styles.CommonBtn}
                    >
                      Cancel
                    </Button>
                  </RadioGroup>
                  <Typography sx={ErrorStyle}>
                    {errors["InterStateGST"]?.message}
                  </Typography>
                </Box>
              </Modal>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalContainer}
                sx={{
                  borderRadius: "20px",
                }}
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        ...Styles.CommonTextStyle,
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                      variant="h6"
                      id="modal-modal-title"
                    >
                      Edit Profile
                    </Typography>

                    <Box
                      onClick={() => {
                        handleClose(null);
                        sendEvents("Click on cancle");
                      }}
                      sx={{ ml: "auto", cursor: "pointer" }}
                    >
                      <img
                        src={Cancle}
                        alt="cancle"
                        style={{
                          ...Styles.CommonTextStyle,
                          fontSize: "16px",
                          marginTop: "14px",
                          "&:hover": {
                            background: " black",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        width: "50%",
                      }}
                    >
                      <Box
                        sx={{ mt: 2, width: "100%" }}
                        id="modal-modal-description"
                      >
                        <form className={classes.form} onSubmit={handleSubmits}>
                          <Box
                            sx={{
                              ...Styles.scrollDes,
                              maxHeight: "600px",
                              height: "100%",
                              pr: 1.5,
                            }}
                          >
                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                Member Name
                              </Typography>
                              <TextField
                                focused
                                name="merchantName"
                                value={formData.merchantName}
                                onChange={handleInputChange}
                                placeholder="Member Name"
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                              />
                            </Box>

                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                Website
                              </Typography>
                              <TextField
                                focused
                                name="website"
                                value={formData.website}
                                onChange={handleInputChange}
                                placeholder="Website"
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                Email
                              </Typography>
                              <TextField
                                focused
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                Address
                              </Typography>
                              <TextField
                                placeholder="Company Registered Address"
                                name="companyRegisteredAddress"
                                value={formData.companyRegisteredAddress}
                                onChange={handleInputChange}
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                City
                              </Typography>
                              <TextField
                                value={formData.city}
                                name="city"
                                onChange={handleInputChange}
                                placeholder="City"
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                Postal Code
                              </Typography>
                              <TextField
                                value={formData.pincode}
                                name="pincode"
                                onChange={handleInputChange}
                                placeholder="Pincode"
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography sx={Styles.CommonTextStyle}>
                                Phone Number
                              </Typography>
                              <TextField
                                value={formData.phone}
                                name="phone"
                                onChange={handleInputChange}
                                placeholder="Phone Number"
                                variant="standard"
                                InputProps={Styles.InputPropsStyle}
                                sx={Styles.TextFieldStyle}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === " " &&
                                    e.target.selectionStart === 0
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                          </Box>

                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.submitButton}
                            sx={{
                              ...Styles.CommonBtn,
                              mt: 2,
                              width: "100%",
                            }}
                          >
                            Save
                          </Button>
                        </form>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
              <Dialog
                open={openimgupload}
                onClose={handleCloseImgUpload}
                fullWidth
                maxWidth="xs"
                sx={{
                  backdropFilter: "blur(2px)",
                }}
                PaperProps={{
                  sx: {
                    width: "60%",
                    maxHeight: 600,
                    borderRadius: "20px",
                  },
                }}
              >
                <DialogTitle id="responsive-dialog-title">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        width: "80%",
                        mx: "auto",
                      }}
                    ></Box>
                    <Box>
                      <CloseIcon
                        sx={{
                          color: "#667085",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setAvatarIcon(null);
                          setOpenImgUpload(false);
                          sendEvents("Click to close");
                        }}
                      />
                    </Box>
                  </Box>
                </DialogTitle>

                <DialogContent
                  sx={{
                    overflow: "auto",
                    "::-webkit-scrollbar": {
                      display: "flex",
                    },
                    "::-webkit-scrollbar-thumb": {
                      dynamic: "#8d8e90",
                      minHeight: "10px",
                      borderRadius: "3px",
                    },
                    "::-webkit-scrollbar-thumb:vertical": {
                      miaxHeight: "10px",
                    },
                  }}
                >
                  {AvatarIcon ? (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 1 }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "150px",
                          height: "150px",
                          textAlign: "center",
                        }}
                      >
                        <Box component="div" sx={Styles.outsideLogo}>
                          <Box component="div" sx={Styles.insideLogo}>
                            <img
                              src={imageUrl}
                              alt="ProfileBxi"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        </Box>
                        <Typography>{AvatarIcon?.name}</Typography>
                        <input
                          style={{ display: "none" }}
                          ref={inputRef2}
                          type="file"
                          onChange={handleFileChange}
                        />
                        <Box
                          onClick={() => {
                            handleClick2();
                            sendEvents("Click on Camera");
                          }}
                          sx={{
                            position: "absolute",
                            bottom: 17,
                            right: 17,
                            cursor: "pointer",
                          }}
                        >
                          <img src={CameraIcon} alt="CameraIcon" />
                        </Box>
                      </Box>
                    </Box>
                  ) : null}
                </DialogContent>
                <DialogTitle id="responsive-dialog-title">
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ ...reqbtn, width: "auto" }}
                      onClick={() => {
                        uploadAvatar(null);
                        sendEvents("Click on upload");
                      }}
                    >
                      Upload
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        ...reqbtn,
                        width: "auto",
                        fontWeight: 700,
                        background: "none",
                        border: "0.7px solid #EBEDEE",
                        color: "#445FD2",
                      }}
                      onClick={() => {
                        setAvatarIcon(null);
                        setOpenImgUpload(false);
                        sendEvents("Click on cancel");
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </DialogTitle>
              </Dialog>
            </Box>
          </Box>
          <Box
            sx={{
              width: "65%",
              mt: 1,
            }}
          >
            <Box sx={Styles.profileTabs}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    sx={{ width: "100%" }}
                    variant="fullWidth"
                  >
                    <Tab
                      label={
                        <Typography sx={Styles.textProfile}>
                          Contact Details
                        </Typography>
                      }
                      value="1"
                    />
                    <Tab
                      label={
                        <Typography sx={Styles.textProfile}>
                          Payment Details
                        </Typography>
                      }
                      value="3"
                    />
                    <Tab
                      label={
                        <Typography sx={Styles.textProfile}>
                          Gst & Locations
                        </Typography>
                      }
                      value="4"
                    />
                  </TabList>
                </Box>
                <Box sx={Styles.scrollDes}>
                  <TabPanel value="1">
                    {contactDetails?.map((el, idx) => {
                      if (!el.labelContact2) return null;
                      return (
                        <Grid container>
                          <Box sx={Styles.TabsBody}>
                            <Box>
                              {loading ? (
                                <Box
                                  sx={{
                                    width: "25px",
                                    mx: "auto",
                                    hieght: "25px",
                                  }}
                                >
                                  <Skeleton
                                    variant="circle"
                                    animation="wave"
                                    sx={{
                                      borderRadius: "10px",
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                </Box>
                              ) : (
                                <img
                                  src={el.imgSrcContact}
                                  alt="user"
                                  style={{ height: "auto", width: "25px" }}
                                />
                              )}
                            </Box>
                            <Box sx={Styles.tabContent}>
                              <Typography sx={Styles.userName}>
                                {el.labelContact1}
                              </Typography>
                              <Typography sx={Styles.nameUser}>
                                {el.labelContact2}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </TabPanel>
                  <TabPanel value="3">
                    <Typography
                      sx={{
                        ...Styles.tabText,
                        textAlign: "left",
                        width: {
                          xl: "90%",
                          lg: "90%",
                          md: "50%",
                          sm: "80%",
                          xs: "95%",
                        },
                      }}
                    >
                      Payment Profile
                    </Typography>

                    <Box sx={Styles.TabsBody}>
                      <Box sx={Styles.tabContent}>
                        <Typography sx={Styles.userName}>
                          Account Number
                        </Typography>
                        <Typography sx={Styles.nameUser}>
                          {userData?.data?.bankdetails?.bankAccountNo}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={Styles.TabsBody}>
                      <Box sx={Styles.tabContent}>
                        <Typography sx={Styles.userName}>
                          Branch Address
                        </Typography>
                        <Typography sx={Styles.nameUser}>
                          {userData?.data?.bankdetails?.branchName}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={Styles.TabsBody}>
                      <Box sx={Styles.tabContent}>
                        <Typography sx={Styles.userName}>IFSC Code</Typography>
                        <Typography sx={Styles.nameUser}>
                          {userData?.data?.bankdetails?.ifsc}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={Styles.TabsBody}>
                      <Box sx={Styles.tabContent}>
                        <Typography sx={Styles.userName}>Pan No</Typography>
                        <Typography sx={Styles.nameUser}>
                          {userData?.data?.bankdetails?.pan}
                        </Typography>
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel value="4">
                    {companyGstandAddress?.data?.data?.GSTAddresses?.map(
                      (el, idx) => {
                        return (
                          <>
                            <Typography
                              sx={{
                                ...Styles.tabText,
                                textAlign: "start",
                                mx: 0,
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: "#6B7A99",
                                }}
                              >
                                {idx + 1 + ")"} GST :-
                              </span>{" "}
                              {el?.GST}
                            </Typography>
                            <Typography
                              sx={{
                                ...Styles.tabText,
                                textAlign: "start",
                                mx: 0,
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                  color: "#6B7A99",
                                }}
                              >
                                Prinicipal Address :-
                              </span>{" "}
                              {el?.data?.Address}
                            </Typography>
                            <hr />
                          </>
                        );
                      }
                    )}
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </Box>
        </Box>

        <Modal
          open={openBankUpdate}
          onClose={handleCloseUpdateBank}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalContainer}
          sx={{
            borderRadius: "20px",
          }}
        >
          <Box sx={styletwo}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  ...Styles.CommonTextStyle,
                  fontSize: "18px",
                  fontWeight: 500,
                }}
                variant="h6"
                id="modal-modal-title"
              >
                Add Bank Details
              </Typography>

              <Box
                onClick={() => {
                  setOpenBankUpdate(false);
                  sendEvents("Click on cancle");
                }}
                sx={{ ml: "auto", cursor: "pointer" }}
              >
                <img
                  src={Cancle}
                  alt="cancle"
                  style={{
                    ...Styles.CommonTextStyle,
                    fontSize: "16px",
                    marginTop: "14px",
                    "&:hover": {
                      background: " black",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box sx={{ mt: 2, width: "100%" }} id="modal-modal-description">
                <Box
                  sx={{
                    ...Styles.scrollDes,
                    maxHeight: "600px",
                    height: "100%",
                    pr: 1.5,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      mt: 1,
                    }}
                  >
                    <Box>
                      <Box>
                        <Typography sx={Styles.CommonTextStyle}>
                          Bank Name
                        </Typography>
                        <select
                          style={{
                            width: "100%",
                            height: "35px",
                            border: "1px solid lightgray",
                            outline: "none",
                            borderRadius: "5px",
                            fontSize: "14px",
                            color: "#2261A2",
                            padding: "5px",
                          }}
                          value={formData.bankName}
                          onChange={handleInputBankChange}
                          name="bankName"
                        >
                          {StoreBankList?.map((res, idx) => {
                            return (
                              <option key={idx} value={res.name}>
                                {res.name}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                      <Box>
                        <Typography sx={Styles.CommonTextStyle}>
                          Branch Name
                        </Typography>
                        <TextField
                          value={formData.branchName}
                          name="branchName"
                          onChange={handleInputBankChange}
                          placeholder="Bank Name"
                          variant="standard"
                          InputProps={Styles.InputPropsStyle}
                          sx={Styles.TextFieldStyle}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={Styles.CommonTextStyle}>
                          IFSC
                        </Typography>
                        <TextField
                          value={formData.ifsc}
                          name="ifsc"
                          onChange={handleInputBankChange}
                          placeholder="Bank Name"
                          variant="standard"
                          InputProps={Styles.InputPropsStyle}
                          sx={Styles.TextFieldStyle}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={Styles.CommonTextStyle}>
                          Bank Account Number
                        </Typography>
                        <TextField
                          value={formData.bankAccountNo}
                          name="bankAccountNo"
                          onChange={handleInputBankChange}
                          placeholder="Bank Name"
                          variant="standard"
                          InputProps={Styles.InputPropsStyle}
                          sx={Styles.TextFieldStyle}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {bankAccountLoader ? (
                  <Button
                    className={classes.submitButton}
                    sx={{
                      ...Styles.CommonBtn,
                      mt: 2,
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  >
                    Loading..
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.submitButton}
                    sx={{
                      ...Styles.CommonBtn,
                      mt: 2,
                      width: "100%",
                      maxWidth: "400px",
                    }}
                    onClick={handleSubmitBankData}
                  >
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </Paper>
      <TostMessagesTop PageLocation="Profile" />
    </>
  );
};

export default ProfileInterest;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
};

const styletwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  p: 4,
};
const classes = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    outline: "none",
    width: "400px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const ErrorStyle = {
  color: "red",
};

const reqbtn = {
  width: "114px",
  height: "42px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "none",
};
