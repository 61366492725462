import { createSlice } from "@reduxjs/toolkit";

export const ContantUploadForVoucherSlice = createSlice({
    name: "ContantUpload",
    initialState: {
        ContantUpload: {},
        loading: false,
    },
    reducers: {
        UPLOAD_CONTANT_REQUEST: (state) => {
            state.loading = true;
        },
        UPLOAD_CONTAN_SUCCESS: (state, action) => {
            state.loading = false;
            state.ContantUpload = action.payload;
        },
        UPLOAD_CONTAN_FAIL: (state, action) => {
            state.loading = false;
            state.ContantUpload = action.payload;
        },
        CLEAR_ERRORS: (state) => {
            state.error = null;
        },
    },
});

export const {
    UPLOAD_CONTANT_REQUEST,
    UPLOAD_CONTAN_SUCCESS,
    UPLOAD_CONTAN_FAIL,
    CLEAR_ERRORS,
} = ContantUploadForVoucherSlice.actions;

export default ContantUploadForVoucherSlice.reducer;
