import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetOrderSummaryForBuyer } from "../../../Hooks/OrderActions/useGetOrderSummaryForBuyer";
import AcceptedIcon from "../../../assets/Images/CommonImages/Accepted.png";
import DownIcon from "../../../assets/Images/CommonImages/Down.png";
import GoLeft from "../../../assets/Images/CommonImages/GoLeft.png";
import PendingIcon from "../../../assets/Images/CommonImages/Pending.png";
import RejectedIcon from "../../../assets/Images/CommonImages/Rejected.png";
import stackofcoins from "../../../assets/Stack of Coins.svg";
import CommaSeprator from "../../../components/CommaSeprator";
import CompanyName from "../../../components/CompanyName";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader";
import SkeletonComponent from "../../../components/SkeletonComponent";
import Nodatafound from "../../../components/NoDataFound/NoDataFound";
import sendEvents from "../../../utils/sendEvents";
import ImageAndProductCarousel from "../../../components/Carousel/ImageAndProductCarousel";

function PurchaseOrderList() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);

  const {
    data: purchaseOrderDataBuyer,
    isLoading: purchaseOrderLoadingSeller,
    error: purchaseOrderErrorSeller,
    refetch: refetchBuyer,
  } = useGetOrderSummaryForBuyer(currentPage);

  useEffect(() => {
    if (purchaseOrderDataBuyer) {
      const { finalCount: allCount } = purchaseOrderDataBuyer;
      setInitialPaginationCount(allCount);
    }
  }, [purchaseOrderDataBuyer]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    refetchBuyer(currentPage);
  }, [currentPage, refetchBuyer]);

  return (
    <Paper
      sx={{
        boxShadow: "none",
        background: "transparent",
        width: "100%",
        mx: "auto",
      }}
      elevation={0}
    >
      <BreadCrumbHeader
        MainText={"Product PO (Purchase)"}
        showbreadcrumb={true}
      />

      {purchaseOrderLoadingSeller ? (
        <>
          <SkeletonComponent />
        </>
      ) : (
        <>
          <Grid
            container
            sx={{
              background: "#FFFFFF",
              padding: "1rem",
              borderRadius: "20px",
              minHeight: "70vh",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 1,
              position: "relative",
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                height: "50px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "160px",
                }}
              >
                <Button
                  onClick={() => {
                    navigate(-1);
                    sendEvents("Navigate to home");
                  }}
                  sx={{
                    background: "transparent",
                    boxShadow: "none",
                  }}
                >
                  <img src={GoLeft} width="22px" />
                </Button>
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                sx={{
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "99%",
                }}
              >
                <Grid
                  item
                  xl={0.5}
                  lg={0.5}
                  md={0.5}
                  sm={0.5}
                  xs={0.5}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography sx={tableheading}>No</Typography>
                </Grid>
                <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                  <Typography sx={tableheading}>Product Name</Typography>
                </Grid>

                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Typography sx={tableheading}>Order Number</Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Typography align="center" sx={tableheading}>
                    Total Products
                  </Typography>
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                  <Typography align="center" sx={tableheading}>
                    Barter Coins
                  </Typography>
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                  <Typography align="center" sx={tableheading}>
                    Order date
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Typography align="center" sx={tableheading}>
                    Buyer Status
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Typography align="center" sx={tableheading}>
                    Seller Status
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography align="center" sx={tableheading}>
                    Action
                  </Typography>
                </Grid>
              </Grid>

              {purchaseOrderDataBuyer?.OrderSumm?.length > 0 ? (
                purchaseOrderDataBuyer?.OrderSumm?.map((SellerData, idx) => {
                  let totalPrice = 0;
                  SellerData?.PurchaseOrder?.ProductData?.map((SellerData) => {
                    let timesec = SellerData?.BoughtSeconds;
                    totalPrice +=
                      SellerData?.ProductTypeName === "Media"
                        ? SellerData.DiscountedPrice *
                          SellerData.ProductQuantity *
                          timesec *
                          SellerData?.TimelineToBought
                        : SellerData.DiscountedPrice *
                          SellerData.ProductQuantity;

                    return totalPrice;
                  });

                  function convertDate(inputFormat) {
                    function pad(s) {
                      return s < 10 ? "0" + s : s;
                    }
                    var d = new Date(inputFormat);
                    return [
                      pad(d.getDate()),
                      pad(d.getMonth() + 1),
                      d.getFullYear(),
                    ].join("/");
                  }

                  return (
                    <Accordion
                      sx={{
                        p: 0,
                        boxShadow: "none",
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <img src={DownIcon} style={{ width: "9px" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ p: 0 }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {" "}
                              {idx + 1}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                            sx={{ position: "relative" }}
                          >
                            <ImageAndProductCarousel
                              carouselData={
                                SellerData?.PurchaseOrder?.ProductData
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {SellerData?.OrderId}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {SellerData?.PurchaseOrder?.ProductData?.length}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <img
                                src={stackofcoins}
                                alt="rupieicon"
                                style={{
                                  width: "15px",
                                  height: "auto",
                                }}
                              />
                              <CommaSeprator Price={totalPrice} />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {convertDate(SellerData?.PoDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "12px",
                                color:
                                  SellerData?.PurchaseOrder
                                    ?.BuyerOrderStatus === "Pending"
                                    ? "#FFB600"
                                    : SellerData?.PurchaseOrder
                                        ?.BuyerOrderStatus === "Accepted"
                                    ? "#118A2C"
                                    : "#FF0000",

                                mx: "auto",
                              }}
                            >
                              {SellerData?.PurchaseOrder?.BuyerOrderStatus}
                            </Typography>
                            {SellerData?.PurchaseOrder?.BuyerOrderStatus ===
                            "Pending" ? (
                              <Box
                                component="img"
                                src={PendingIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : SellerData?.PurchaseOrder?.BuyerOrderStatus ===
                              "Accepted" ? (
                              <Box
                                component="img"
                                src={AcceptedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={RejectedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            )}
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "12px",
                                color:
                                  SellerData?.PurchaseOrder
                                    ?.SellerOrderStatus === "Pending"
                                    ? "#FFB600"
                                    : SellerData?.PurchaseOrder
                                        ?.SellerOrderStatus === "Accepted"
                                    ? "#118A2C"
                                    : "#FF0000",

                                mx: "auto",
                              }}
                            >
                              {SellerData?.PurchaseOrder?.SellerOrderStatus}
                            </Typography>
                            {SellerData?.PurchaseOrder?.SellerOrderStatus ===
                            "Pending" ? (
                              <Box
                                component="img"
                                src={PendingIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : SellerData?.PurchaseOrder?.SellerOrderStatus ===
                              "Accepted" ? (
                              <Box
                                component="img"
                                src={AcceptedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={RejectedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            )}
                          </Grid>

                          <Grid
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              display: "flex",
                            }}
                          >
                            <Box
                              onClick={() => {
                                SellerData.IsMedia === true
                                  ? navigate(
                                      `/home/mediapurchaseorderdetails/${SellerData?.PurchaseOrder?._id}`
                                    )
                                  : navigate(
                                      `/home/purchaseorderdetails/${SellerData?.PurchaseOrder?._id}`
                                    );
                                sendEvents("Click on view PO");
                              }}
                              sx={{
                                cursor: "pointer",
                                width: "100px",
                                marginRight: "10px",
                                mx: "auto",
                                height: "30px",
                                background: "#445FD2",
                                borderRadius: "4.39877px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...TableBodyTextStyling,
                                  color: "#fff",
                                }}
                              >
                                View P.O.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0, pb: 2 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            p: 0,
                            ml: 4,
                            width: "100%",
                            maxWidth: "500px",
                          }}
                        >
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              textAlign: "left",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              display: "flex",
                            }}
                          >
                            <CompanyName
                              CompanyId={
                                SellerData?.PurchaseOrderData?.SellerCompanyId
                              }
                              CompanyName={
                                SellerData?.PurchaseOrderData?.SellerDetails
                                  ?.SellerCompanyName
                              }
                              CompanyTypeName={
                                SellerData?.PurchaseOrderData?.SellerDetails
                                  ?.CompanyTypeName
                              }
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Nodatafound />
              )}
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
            <Stack spacing={2}>
              <StyledPagination
                count={initialPaginationCount}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        </>
      )}
    </Paper>
  );
}

export default PurchaseOrderList;

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};
