const TransportationStyle = {
  CommonTextStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#6B7A99",
    textTransform: "none",
 
  },
  ConfirmButtonStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#FFFFFF",
    borderRadius: "4px",
    padding: "10px 20px",
    height: "40px",
    width: "200px",
    background: "#445FD2",
    textTransform: "none",
    "&:hover": {
      background: "#445FD2",
    },
  },
  CancelButtonStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#445FD2",
    background: "#FFFFFF",
    borderRadius: "4px",
    border: "1px solid #445FD2",
    padding: "10px 20px",
    height: "40px",
    width: "200px",
    "&:hover": {
      background: "#FFFFFF",
    },
  },
};

export { TransportationStyle };
