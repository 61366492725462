import { createSlice } from "@reduxjs/toolkit";

export const OrderProgressStatusSlice = createSlice({
    name: "OrderProgress",
    initialState: {
        OrderProgress: {},
    },
    reducers: {
        ORDER_PROGRESS_REQUEST: (state) => {
            state.loading = true;
            state.OrderProgress = {};
        },
        ORDER_PROGRESS_SUCCESS: (state, action) => {
            state.loading = false;
            state.OrderProgress = action.payload;
        },
        ORDER_PROGRESS_FAIL: (state, action) => {
            state.loading = false;
            state.OrderProgress = action.payload;
        },
    },
});

export const {
    ORDER_PROGRESS_REQUEST,
    ORDER_PROGRESS_SUCCESS,
    ORDER_PROGRESS_FAIL,
} = OrderProgressStatusSlice.actions;
export default OrderProgressStatusSlice.reducer;
