import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  Chip,
  Button,
  BottomNavigation,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/system";

import React, { useEffect, useRef, useState } from "react";
import { useUpdateProductQuery } from "./ProductHooksQuery";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ToolTip from "../../../../components/ToolTip";
import { toast } from "react-toastify";
import axios from "axios";

export default function ElectronicsTechInfo() {
  const ProductId = useParams().id;
  const navigate = useNavigate();
  const [weight, setweight] = useState("Grams");

  const [productData, setProductData] = useState({
    weigthbeforepacking: "",
    weigthafterpacking: "",
    packaginganddeliveryinstructions: "",
    instructionstouseproduct: "",
  });

  const [tags, setTags] = useState([]);
  const tagInputRef = useRef(null);

  const handleAddTag = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const currentTag = e.target.value.trim();
      if (currentTag !== "") {
        if (!tags.includes(currentTag)) {
          setTags([...tags, currentTag]);
        }
        tagInputRef.current.value = "";
      }
    }
  };

  const handleAddButtonClick = () => {
    const currentTag = tagInputRef.current.value.trim();
    if (currentTag !== "") {
      if (!tags.includes(currentTag)) {
        setTags([...tags, currentTag]);
      }
      tagInputRef.current.value = "";
    }
  };
  const handleDeleteTag = (tagToDelete) => () => {
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        Guarantee: z.coerce.string().min(1),
        GuaranteePeriod: z.string().min(1),
        Warranty: z.coerce.string().min(1),
        WarrantyPeriod: z.string().min(1),
        WeightBeforePackingPerUnit: z.coerce.number().gte(1),
        WeightBeforePackingPerUnitMeasurUnit: z.string().min(1),
        PackagingInfoPerUnit: z.string().min(1),
        LegalInformation: z.string().min(1),
      })
    ),
    defaultValues: {
      Guarantee: "",
      Warranty: "",
      WarrantyPeriod: "Year",
      GuaranteePeriod: "Year",
      WeightBeforePackingPerUnit: "",
      PackagingInfoPerUnit: "",
      LegalInformation: "",
    },
  });

  const {
    mutate: updateProduct,
    isLoading,
    isError,
    data: recievedproductData,
    error: RegisterError,
  } = useUpdateProductQuery();
  const FetchTechInfo = async () => {
    await axios
      .get("/product/get_product_byId/" + ProductId)
      .then((response) => {
        setProductData(response?.data);
        setValue("Guarantee", response?.data?.ProductTechInfo?.Guarantee);
        setValue("Warranty", response?.data?.ProductTechInfo?.Warranty);
        setValue(
          "LegalInformation",
          response?.data?.ProductTechInfo?.LegalInformation
        );
        setValue(
          "PackagingInfoPerUnit",
          response?.data?.ProductTechInfo?.PackagingInfoPerUnit
        );
        setValue(
          "WeightBeforePackingPerUnit",
          response?.data?.ProductTechInfo?.WeightBeforePackingPerUnit
        );
        setTags([...tags, ...response?.data?.ProductTechInfo?.Tags]);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    FetchTechInfo();
  }, []);
  const createCompany = handleSubmit((ProductTechInfo) => {
    if (
      Number(ProductTechInfo?.WeightAfterPackingPerUnit) <
      Number(ProductTechInfo?.WeightBeforePackingPerUnit)
    ) {
      return toast.error(
        "Weight after packing is must be greater than Weight before packing",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else if (tags?.length === 0) {
      return toast.error("Please add atleast one Tag", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      updateProduct(
        {
          id: ProductId,
          UnitOfMeasurement: "CM",
          UnitOfWeight: "KiloGrams",
          GuaranteePeriod: ProductTechInfo?.GuaranteePeriod,
          WarrantyPeriod: ProductTechInfo?.WarrantyPeriod,
          WeightBeforePackingPerUnitMeasurUnit:
            ProductTechInfo.WeightBeforePackingPerUnitMeasurUnit,
          ProductTechInfo: {
            Guarantee: Number(ProductTechInfo?.Guarantee),
            Warranty: Number(ProductTechInfo?.Warranty),
            WeightBeforePackingPerUnit:
              ProductTechInfo.WeightBeforePackingPerUnit,
            PackagingInfoPerUnit: ProductTechInfo.PackagingInfoPerUnit,
            LegalInformation: ProductTechInfo.LegalInformation,
            Tags: tags,
            ProductUploadStatus: "golive",
          },
        },
        {
          onSuccess: (response) => {
            if (response.status === 200) {
              navigate(`/home/fmcg/fmcggolive/${ProductId}`);
            }
          },
          onError: (error) => {},
        }
      );
    }
  });
  return (
    <>
      <form onSubmit={createCompany}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "716px",
            height: "auto",
            mx: "auto",
          }}
        >
          <Box>
            <Box
              sx={{
                px: "30px",
                height: "auto",
                maxHeight: "100%",
                background: "#FAFBFD",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#FAFBFD",
                  width: "100%",
                  mx: "auto",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  py: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: {
                      xs: "18px",
                      sm: "16px",
                      md: "16px",
                      lg: "14px",
                      xl: "14px",
                    },
                    color: "#6B7A99",
                  }}
                >
                  Technical Information
                </Typography>
                <ToolTip
                  info={
                    "Technical Information refers to specific details and specifications about a product's technical aspects, packaging Material, packing size, Dimensions, logistic or go live information for your offered product, This is Critical Information from Logistic & Buying Perspective for Making Informed Decisions"
                  }
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mt: 2,
                  height: "100%",
                  maxHeight: "100%",
                  overflowY: "auto",
                }}
              >
                <Stack
                  sx={{
                    overflow: "auto",
                    "::-webkit-scrollbar": {
                      display: "flex",
                    },
                    "::-webkit-scrollbar-thumb": {
                      dynamic: "#8d8e90",
                      minHeight: "10px",
                      borderRadius: "8px",
                    },
                    "::-webkit-scrollbar-thumb:vertical": {
                      maxHeight: "30px",
                    },
                    maxHeight: "100%",
                    height: "100%",
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        width: "100%",
                        maxWidth: "290px",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography sx={TypographyStyle}>
                        Warranty <span style={{ color: "red" }}> *</span>
                      </Typography>
                      <Box
                        sx={{
                          width: "auto",
                          maxWidth: "500px",
                          height: "42px",
                          mt: "1%",
                          borderRadius: "10px",
                          border: errors["Warranty"] ? "1px solid red" : null,
                        }}
                      >
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          placeholder="1"
                          type="number"
                          {...register("Warranty")}
                          InputProps={{
                            disableUnderline: "true",
                            style: {
                              color: "#C64091",
                              fontSize: "14px",
                              padding: "7px",
                            },
                          }}
                          InputLabelProps={{
                            inputProps: { min: 0 },
                            style: {
                              color: "red",
                            },
                          }}
                          sx={{
                            width: "70%",
                            height: "100%",
                            background: "#FFFFFF",
                            borderRadius: "10px 0px 0px 10px",
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />

                        <Select
                          sx={{
                            ...GW,
                            border: errors["WarrantyPeriod"]
                              ? "1px solid red"
                              : null,
                          }}
                          defaultValue={"Year"}
                          {...register("WarrantyPeriod")}
                        >
                          <MenuItem sx={MenuItems} value="Year">
                            Year
                          </MenuItem>
                          <MenuItem sx={MenuItems} value="Month">
                            Month
                          </MenuItem>
                          <MenuItem sx={MenuItems} value="Days">
                            Days
                          </MenuItem>
                        </Select>
                        <Typography sx={ErrorStyle}>
                          {errors["Warranty"]?.message}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "grid", width: "100%", maxWidth: "290px" }}
                    >
                      <Typography sx={TypographyStyle}>
                        Guarantee <span style={{ color: "red" }}> *</span>
                      </Typography>
                      <Box
                        sx={{
                          width: "auto",
                          maxWidth: "500px",
                          height: "42px",
                          mt: "1%",
                          borderRadius: "10px",
                          border: errors["Guarantee"] ? "1px solid red" : null,
                        }}
                      >
                        <TextField
                          variant="standard"
                          placeholder="1"
                          type="number"
                          {...register("Guarantee")}
                          InputProps={{
                            inputProps: { min: 0 },
                            disableUnderline: "true",
                            style: {
                              color: "#C64091",
                              fontSize: "14px",
                              padding: "7px",
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: "red",
                            },
                          }}
                          sx={{
                            width: "70%",
                            height: "100%",
                            background: "#FFFFFF",
                            borderRadius: "10px 0px 0px 10px",
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                        ></TextField>
                        <Select
                          sx={{
                            ...GW,
                            border: errors["GuaranteePeriod"]
                              ? "1px solid red"
                              : null,
                          }}
                          defaultValue={"Year"}
                          {...register("GuaranteePeriod")}
                        >
                          <MenuItem sx={MenuItems} value="Year">
                            Year
                          </MenuItem>
                          <MenuItem sx={MenuItems} value="Month">
                            Month
                          </MenuItem>
                          <MenuItem sx={MenuItems} value="Days">
                            Days
                          </MenuItem>
                        </Select>
                        <Typography sx={ErrorStyle}>
                          {errors["Guarantee"]?.message}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ display: "grid", gap: "10px", py: "20px" }}>
                    <Typography sx={TypographyStyle}>
                      Weight before packing per unit{" "}
                      <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Box
                      sx={{
                        width: "auto",
                        maxWidth: "710px",
                        height: "55px",
                        borderRadius: "10px",
                        border: errors["WeightBeforePackingPerUnit"]
                          ? "1px solid red"
                          : null,
                      }}
                    >
                      <TextField
                        id="standard-multiline-static"
                        variant="standard"
                        placeholder="70"
                        InputProps={{
                          disableUnderline: "true",
                          style: {
                            color: "#C64091",
                            fontSize: "14px",
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "red",
                          },
                        }}
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        sx={{
                          width: "85%",
                          height: "100%",
                          background: "#FFFFFF",
                          borderRadius: "10px 0px 0px 10px",
                        }}
                        {...register("WeightBeforePackingPerUnit")}
                      ></TextField>
                      <Select
                        sx={{
                          ...packagingunit,
                          border: errors["WeightBeforePackingPerUnitMeasurUnit"]
                            ? "1px solid red"
                            : null,
                          color: "#C64091",
                        }}
                        defaultValue={"Grams"}
                        {...register("WeightBeforePackingPerUnitMeasurUnit", {
                          onChange: (e) => {
                            setweight(e.target.value);
                          },
                        })}
                      >
                        <MenuItem sx={MenuItems} value="Grams">
                          Grams
                        </MenuItem>
                        <MenuItem sx={MenuItems} value="KiloGrams">
                          KiloGrams
                        </MenuItem>
                      </Select>
                      <Typography sx={ErrorStyle}>
                        {errors["WeightBeforePackingPerUnit"]?.message}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "grid", gap: "10px", py: "20px" }}>
                    <Typography sx={TypographyStyle}>
                      Packaging Info. per unit{" "}
                      <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <TextField
                      id="standard-multiline-static"
                      multiline
                      rows={4}
                      variant="standard"
                      placeholder="Packaging Information"
                      InputProps={{
                        disableUnderline: "true",
                        style: {
                          color: "#C64091",
                          fontSize: "14px",
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "red",
                        },
                      }}
                      {...register("PackagingInfoPerUnit")}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      sx={{
                        ...textfieldstyle,
                        border: errors["PackagingInfoPerUnit"]
                          ? "1px solid red"
                          : null,
                        marginBottom: "0",
                      }}
                    />
                    <Typography sx={ErrorStyle}>
                      {errors["PackagingInfoPerUnit"]?.message}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gap: "10px",
                      py: "20px",
                      marginTop: "0",
                    }}
                  >
                    <Typography sx={TypographyStyle}>
                      Legal Information <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <TextField
                      id="standard-multiline-static"
                      multiline
                      rows={4}
                      variant="standard"
                      placeholder="Your use of this web site constitutes your acceptance of the following Terms of Use. Do not use the site if you do not agree to be bound by these terms. Be sure to check this page periodically for updates, as your continued use of the site signifies your acceptance of any changed items."
                      InputProps={{
                        disableUnderline: "true",
                        style: {
                          color: "#C64091",
                          fontSize: "14px",
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "red",
                        },
                      }}
                      {...register("LegalInformation")}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      sx={{
                        ...textfieldstyle,
                        border: errors["LegalInformation"]
                          ? "1px solid red"
                          : null,
                      }}
                    />
                    <Typography sx={ErrorStyle}>
                      {errors["LegalInformation"]?.message}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "grid", gap: "10px", py: "20px" }}>
                    <Typography sx={TypographyStyle}>
                      Tags (Keywords that can improve your seach visibility on
                      marketplace)<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        background: "#fff",
                        borderRadius: "10px",
                      }}
                    >
                      <TextField
                        placeholder="Add Tags"
                        inputRef={tagInputRef}
                        sx={{
                          width: "100%",
                          background: "#fff",
                          borderRadius: "10px",
                          height: "41px",
                        }}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "rgba(107, 122, 153)",
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "1%",
                            color: "#C64091",
                          },
                        }}
                        inputProps={{ maxLength: 15 }}
                        onKeyDown={handleAddTag}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          background: "#C64091",
                          right: 1,
                          textTransform: "none",
                          fontSize: "12px",
                          alignSelf: "center",
                          "&:hover": {
                            color: "#fff",
                            background: "#C64091",
                            border: "none",
                          },
                        }}
                        onClick={handleAddButtonClick}
                      >
                        Add
                      </Button>
                    </Box>
                    <Typography sx={ErrorStyle}>
                      {errors["height"]?.message}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "auto",
                        gap: "5px",
                      }}
                    >
                      {tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          onDelete={handleDeleteTag(tag)}
                          color="default"
                          fullWidth
                          sx={{
                            fontSize: "14px",
                            background: "#FFFFFF ",
                            color: "#6B7A99",
                            height: "50px",
                            boxShadow: "0px 4px 4px rgba(229, 229, 229, 0.25)",
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              height: "auto",
              background: "#FAFBFD",
            }}
          >
            <BottomNavigation
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                background: "#FAFBFD",
              }}
              showLabels
            >
              <Box sx={{ display: "flex", gap: "10px", p: 1, width: "50%" }}>
                <Button
                  sx={{
                    width: "100%",
                    height: "32px",
                    borderRadius: "10px",
                    background: "#fff",
                    color: "#636161",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: "#f3f6f9",
                      color: "#000",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    let confirm = window.confirm(
                      "Are you sure you want to cancel the product?"
                    );
                    if (confirm) {
                      navigate("/home/sellerhub");
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "32px",
                    borderRadius: "10px",
                    background: "#C64091",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: "#C64091",
                    },
                  }}
                  variant="contained"
                >
                  {isLoading ? <CircularProgress size={20} /> : "Next"}
                </Button>
              </Box>
            </BottomNavigation>
          </Box>
        </Box>
      </form>
    </>
  );
}

const textfieldstyle = {
  width: "100%",
  height: "100px",
  background: "#FFFFFF",
  borderRadius: "10px",
  color: "red",
  fontSize: "14px",
};

const MenuItems = {
  fontSize: "12px",
  color: "#C64091",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};

const TypographyStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#6B7A99",
};

const packagingunit = {
  width: "15%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  height: "100%",
  background: "#FFFFFF",
  color: "#6B7A99",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};

const GW = {
  width: "30%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#FFFFFF",
  height: "100%",
  color: "#C64091",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};

const ErrorStyle = {
  color: "red",
};
