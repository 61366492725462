import React, { useEffect, useRef } from "react";
import Navbar from "./Navbar";
import { Box, Stack } from "@mui/material";
import HowitworksPageBody from "./HowitworksPageBody";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const HowitworksPage = () => {
  const ref = useRef(null);
  const Location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (window.scrollY === 0) {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const HandleFlick = () => {
    navigate("/", {
      state: "ABClick",
    });
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Stack sx={{ width: "100vw", overflow: "hidden" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>How B2B Barter Works Online? </title>
        <meta property="og:title" content="How B2B Barter Works Online? " />
        <meta
          name="og:description"
          content="Learn to Pay By Product & Save Cash with BXI - Barter Exchange of India! Discover the hidden value in your company. Join us to fuel your growth"
        />
        <meta
          name="description"
          content="Learn to Pay By Product & Save Cash with BXI - Barter Exchange of India! Discover the hidden value in your company. Join us to fuel your growth"
        />
      </Helmet>
      <Box>
        <Navbar />
      </Box>
      <Box
        sx={{
          marginTop: "64px",
        }}
      >
        <HowitworksPageBody />
      </Box>
      <Box>
        <Footer HandleFlick={HandleFlick} />
      </Box>
    </Stack>
  );
};

export default HowitworksPage;
