import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, MenuItem, Select } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import moment from "moment";

Chart.register(Title, Tooltip, Legend, CategoryScale, LinearScale, Filler);

const lastOneMonth = ["1 Week", "2 Week", "3 Week", "4 Week", "5 Week"];

export default function LineChartPage() {
  const [filter, setFilter] = useState({
    metric: "ViewCount",
    timeline: "Last7Days",
  });

  const { ProductAnalysisOfLastWeek } = useSelector(
    (state) => state.ProductAnalysisOfWeek
  );
  const { ProductAnalysisOfLastMonth } = useSelector(
    (state) => state.ProductAnalysisOfLastMonth
  );
  const { ProductAnalysisOfLastThreeMonth } = useSelector(
    (state) => state.ProductAnalysisOfThreeMonth
  );
  const { ProductAnalysisOfLastSixMonth } = useSelector(
    (state) => state.ProductAnalysisOfSixMonth
  );
  const { ProductAnalysisOfLastYear } = useSelector(
    (state) => state.ProductAnalysisOfLastYear
  );
  const { ProductAnalysisOfLastFiveYear } = useSelector(
    (state) => state.ProductAnalysisOfLastFiveYear
  );

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let filteredData = [];

    switch (filter.timeline) {
      case "Last7Days":
        filteredData = ProductAnalysisOfLastWeek?.weekViewCounts || [];
        break;
      case "Last1Month":
        filteredData = ProductAnalysisOfLastMonth?.monthViewCounts || [];
        break;
      case "Last3Month":
        filteredData = ProductAnalysisOfLastThreeMonth?.monthsData || [];
        break;
      case "Last6Month":
        filteredData = ProductAnalysisOfLastSixMonth?.monthsData || [];
        break;
      case "Last1Year":
        filteredData = ProductAnalysisOfLastYear?.monthsData || [];
        break;
      case "Last5Year":
        filteredData = ProductAnalysisOfLastFiveYear?.yearsData || [];
        break;
      default:
        filteredData = [];
    }

    switch (filter.metric) {
      case "ViewCount":
        break;
      case "AddToCartCount":
      case "Top Selling":
        filteredData = filteredData.filter((entry) => entry[filter.metric] > 0);
        break;
      default:
        break;
    }

    setChartData(filteredData);
  }, [
    filter,
    ProductAnalysisOfLastWeek,
    ProductAnalysisOfLastMonth,
    ProductAnalysisOfLastThreeMonth,
    ProductAnalysisOfLastSixMonth,
    ProductAnalysisOfLastYear,
    ProductAnalysisOfLastFiveYear,
  ]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Product Analysis",
        font: { size: 20 },
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Timeline",
          font: { size: 16 },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text:
            filter.metric === "ViewCount"
              ? "View Count"
              : filter.metric === "AddToCartCount"
              ? "Add To Cart Count"
              : filter.metric === "TopSelling"
              ? "Top Selling"
              : filter.metric,
          font: { size: 16 },
        },
      },
    },
  };

  const data = {
    labels: chartData.map((entry, index) => {
      if (filter.timeline === "Last7Days") {
        return moment(entry.Day, "dddd").format("ddd");
      } else if (
        filter.timeline === "Last3Month" ||
        filter.timeline === "Last6Month" ||
        filter.timeline === "Last1Year"
      ) {
        return moment(entry.Month, "MMMM YYYY").format("MMM YYYY");
      } else if (filter.timeline === "Last1Month") {
        return lastOneMonth[index];
      } else if (filter.timeline === "Last5Year") {
        return entry.Year;
      } else {
        return "";
      }
    }),
    datasets: [
      {
        label:
          filter.metric === "ViewCount"
            ? "View Count"
            : filter.metric === "AddToCartCount"
            ? "Add To Cart Count"
            : filter.metric === "TopSelling"
            ? "Top Selling"
            : filter.metric,
        data: chartData.map((entry) => entry[filter.metric]),
        backgroundColor: "rgba(101, 193, 222, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        barPercentage: 0.4,
      },
    ],
  };

  return (
    <Box
      sx={{
        height: "460px",
        width: "830px",
        mx: "auto",
        my: 2,
      }}
    >
      <Box className="chart-container">
        <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
          <Box mb={2}>
            <Select
              style={opt}
              className="select-input"
              value={filter.metric}
              onChange={(e) => setFilter({ ...filter, metric: e.target.value })}
            >
              <MenuItem value="ViewCount" sx={menuText}>
                View Count
              </MenuItem>
              <MenuItem value="AddToCartCount" sx={menuText}>
                Add to cart Count
              </MenuItem>
              <MenuItem value="TopSelling" sx={menuText}>
                Top Selling
              </MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <Select
              style={opt}
              className="select-input"
              value={filter.timeline}
              onChange={(e) =>
                setFilter({ ...filter, timeline: e.target.value })
              }
            >
              <MenuItem value="Last7Days" sx={menuText}>
                Last 7 Days
              </MenuItem>
              <MenuItem value="Last1Month" sx={menuText}>
                Last 1 Month
              </MenuItem>
              <MenuItem value="Last3Month" sx={menuText}>
                Last 3 Months
              </MenuItem>
              <MenuItem value="Last6Month" sx={menuText}>
                Last 6 Months
              </MenuItem>
              <MenuItem value="Last1Year" sx={menuText}>
                Last 1 Year
              </MenuItem>
              <MenuItem value="Last5Year" sx={menuText}>
                Last 5 Years
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Box maxWidth={850}>
          <Bar style={{ height: "230px" }} data={data} options={options} />
        </Box>
      </Box>
    </Box>
  );
}

const opt = {
  height: "35px",
  borderRadius: "12px",
  border: "1px solid #E6E9EE",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#AFAFAF",
  maxWidth: 250,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const menuText = {
  fontSize: "12px",
};
