import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
import Cross from "../assets/Images/Toast/Vector.svg";
import Smileywithbox from "../assets/Images/Toast/Group 1000003296.svg";

const Toast = ({ PageContent, PageCompanyName }) => {
  const showToasts = async () => {
    const toastContent = (
      <Box
        sx={{
          background: "F3F6F9",
          width: {
            xl: "470px",
            lg: "470px",
            md: "470px",
            sm: "270px",
            xs: "270px",
          },
          height: "auto",
          minHeight: "85px",
          backgroundColor: "#F5F9FB",
          backgroundPosition: "center",
          border: "1px solid #156DB6",
          backgroundSize: "cover",
          ml: { xl: "30px", lg: "30px", md: "30px", sm: "50px", xs: "50px" },
          backgroundRepeat: "no-repeat",
          position: "relative",
          borderRadius: "25px",
          display: "flex",
          justifyContent: "center", // Center the content horizontally
          alignItems: "center", // Center the content vertically
          flexDirection: "column", // Added to stack the content vertically
          color: "#6B7A99", // Set the text color to white
        }}
      >
        <img
          src={Cross}
          alt="Icon"
          style={{
            width: "11px",
            height: "11px",
            position: "absolute",
            color: "#156DB6",
            right: "20px",
            top: "8px",
          }}
        />

        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            textAlign: "center",
            lineHeight: "30px",
            width: "90%",
            ml: "auto",
          }}
        >
          Hello ! {PageCompanyName}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xl: "14px",
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            fontWeight: "400",
            lineHeight: "20px",
            textAlign: "center",
            lineHeight: "16.8px",
            width: "90%",
            ml: "auto",
          }}
        >
          {PageContent}
        </Typography>
        <img
          src={Smileywithbox}
          alt="Icon"
          style={{
            width: "148px",
            height: "148px",
            position: "absolute",
            top: "-40px",
            left: "-83px",
          }}
        />
      </Box>
    );

    toast.dark(toastContent, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        width: "520px",
        height: "120px",
        background: "transparent",
        boxShadow: "none",
      },
    });
  };

  useEffect(() => {
    showToasts();
  }, []);

  return <></>;
};

export default Toast;
