import axios from "axios";
import {
  Category_Id,
  Category_Ids,
} from "../../../Component/MarketPlaceExplore/ProductDetailsCarousel";

import {
  States,
  PriceRange,
  Product_Qty,
  Clear,
  Refresh,
  PriceShortHightToLow,
  PriceShortLowToHight,
  WhatsNew,
  Popularity,
  Voucher,
  ProductsFilter,
  IsBestSeller,
  IsReadyStock,
} from "../../../Component/MarketPlaceExplore/MarketPlace";
import { Pagination_Count } from "../../../Component/MarketPlaceExplore/ProductList";
import {
  Search,
  Tag,
  ProductDescriptionSearch,
  ProductCompanyNameSearch,
  ProductSubtittleSearch,
} from "../../../Component/MarketPlaceExplore/HeaderExplore";

import {
  product_Explore_Request,
  product_Explore_Success,
  product_Explore_Fail,
} from "../../reduser/Products/products";

// Get All Products
export const getProductExplore =
  (
    search = "",
    price = "",
    sortBy = "",
    productQty = "",
    clear = "",
    categoryIds = "",
    refresh = "",
    SellerCompanyId = "",
    priceShortHightToLow = "",
    priceShortLowToHight = "",
    whatsNew = "",
    selectedCompany = "",
    currentPage = "",
    popularity = false,
    voucher = false,
    tag = "",
    productsFilter = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: product_Explore_Request.toString() });

      if (sortBy === "sort") {
        sortBy = "-createdAt";
      }

      let limit = "";
      let rating = "";
      let deliveryType = "";

      const { data } = await axios.get(
        `product/get_products?Search=${Search.trim()}&PriceRange=${PriceRange}&ProductLocationState=${States}&ProductRating=${rating}&ProductQty=${Product_Qty}&ProductDeliveryType=${deliveryType}&ProductCategory=${Category_Ids}&Clear=${Clear}&Short=${sortBy}&Category_Id=${Category_Id}&Refresh=${Refresh}&SellerCompanyId=${SellerCompanyId}&priceShortHightToLow=${PriceShortHightToLow}&priceShortLowToHight=${PriceShortLowToHight}&whatsNew=${WhatsNew}&companyName=${selectedCompany}&popularity=${Popularity}&Voucher=${Voucher}&Tag=${Tag}&DescriptionSearch=${ProductDescriptionSearch}&SellerCompanyNameSearch=${ProductCompanyNameSearch}&ProductSubtittleSearch=${ProductSubtittleSearch}&productsFilter=${ProductsFilter}&IsBestSeller=${IsBestSeller}&IsReadyStock=${IsReadyStock}&page=${Pagination_Count}&limit=${limit}`,
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: product_Explore_Success.toString(),
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: product_Explore_Fail.toString(),
        payload: error.response.data.message,
      });
    }
  };
