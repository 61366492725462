import React from "react";
import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";

const BulkUploadAllProductsshow = () => {
  return (
    <>
      <BreadCrumbHeader title="All Products" MainText={"All Products"} />
    </>
  );
};

export default BulkUploadAllProductsshow;
