import React, { useState } from 'react';
import MainLogo from './MainLogo.jsx';
import { useCompanyDetails } from '../../Hooks/Auth';
import mainImg from '../../assets/Images/register/companydetails.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Typography, Box, Button, Paper, TextField } from '@mui/material';
import * as StlyesOnBoarding from '../../components/common/Styled/StylesOnBoarding.js';

const CompanyDetails = () => {
  const [companyNature, setCompanyNature] = useState();
  const [companyRenew, setCompanyRenew] = useState();
  // Add company Deatils
  const { mutate } = useCompanyDetails();
  const addCompanyDetails = () => {
    const data = { companyNature, companyRenew };
    mutate(data);
  };
  return (
    <Paper elevation={0} sx={StlyesOnBoarding.mainCommonPage}>
      <Grid
        container
        sx={{
          background: '#fff',
          height: '100vh',
          width: {
            xl: '110%',
            lg: '110%',
            md: '110%',
            sm: '100%',
            xs: '100%',
          },
        }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '-20rem',
          }}
        >
          <Grid
            container
            sx={{
              height: {
                xl: '30%',
                lg: '30%',
                md: '30%',
                sm: '10%',
                xs: '10%',
              },
            }}
          >
            <MainLogo />
          </Grid>
          <Typography sx={StlyesOnBoarding.HeadText}>
            Company Details{' '}
          </Typography>
          <Box
            mt={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              width: '75%',
              gap: '20px',
            }}
          >
            {/* input Box */}
            <ThemeProvider theme={outerTheme}>
              <TextField
                label="Company nature"
                placeholder="12345667888"
                variant="outlined"
                required
                onChange={(e) => {
                  setCompanyNature(e.target.value);
                }}
                autoFocus
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: '1px #CCCCCC',
                  },
                }}
              />
              <TextField
                label="Company revenue"
                placeholder="Company revenue"
                variant="outlined"
                required
                onChange={(e) => {
                  setCompanyRenew(e.target.value);
                }}
                // size="large"
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: '1px #CCCCCC',
                  },
                }}
              />
            </ThemeProvider>
            {/* Buttons next  */}
            <Grid
              container
              mt={3}
              sx={{
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Button
                variant="contained"
                fullWidth
                sx={StlyesOnBoarding.CommonBtn}
                onClick={addCompanyDetails}
              >
                Next
              </Button>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={3}>
                <Typography sx={StlyesOnBoarding.accountText}>
                  Don’t have an account?{' '}
                  <span
                    style={{
                      color: 'rgba(55, 93, 187, 1)',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    }}
                  >
                    {' '}
                    Login
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <img
            src={mainImg}
            alt="img"
            style={{
              ...StlyesOnBoarding.CommonImgStyle,
              marginRight: '150px',
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default CompanyDetails;

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#6B7A99',
    },
  },
  typography: {
    fontSize: 20,
  },
});
