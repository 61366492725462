//  company profile completion percentage useQuery hook

import axios from "axios";
import { useQuery } from "react-query";

const useGetCompanyProfileCompletionPercentage = () => {
  const getCompanyProfileCompletionPercentage = async () => {
    const { data, isLoading, error, refetch } = await axios.get(
      `auth/profilecompletion`
    );
    return {
      data,
      isLoading,
      error,
      refetch,
    };
  };

  return useQuery(["company-profile"], getCompanyProfileCompletionPercentage);
};

export default useGetCompanyProfileCompletionPercentage;
