import React, { useState, useEffect } from "react";
import "./custom.slider.css";
import { Box, Typography } from "@mui/material";

function ImageAndProductCarousel({ children }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 3000)
      );
    }
  }, [slideDone]);

  const slideNext = () => {
    setActiveIndex((val) => {
      if (val >= children.length - 1) {
        return 0;
      } else {
        return val + 1;
      }
    });
  };

  const AutoPlayStop = () => {
    if (timeID > 0) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div
      className="container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
    >
      {children.map((item, index) => (
        <div
          className={"slider__item " + (activeIndex === index ? "active" : "")}
          key={index}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

export default function ImagesSlider({ carouselData, MaxWidth }) {
  return carouselData ? (
    <ImageAndProductCarousel>
      {carouselData.map((image, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            width: "100%",
            maxWidth: {
              xl: "275px",
              lg: "275px",
              md: "275px",
              sm: "150px",
              xs: "150px",
            },
          }}
        >
          <img
            src={image.ProductImage}
            alt={"Images"}
            style={{ width: MaxWidth ? "80px" : "50px", height: "50px" }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "21px",
              color: "#1B212D",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "320px",
            }}
          >
            {image.ProductName}
          </Typography>
        </Box>
      ))}
    </ImageAndProductCarousel>
  ) : null;
}
