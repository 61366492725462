import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import LeftArrow from "../../assets/Images/CommonImages/Left Arrow.png";
import PaymentSuccessIcon from "../../assets/Images/PaymentSuccessIcon.svg";

const PaymentSuccessful = () => {
  const navigate = useNavigate();
  const NavigateToExplore = () => {
    navigate(`/home/myorderlist`);
  };
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 7,
      }}
    >
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
            mt: 5,
          }}
        >
          <Box
            component="img"
            src={LeftArrow}
            alt="left arrow"
            onClick={() => navigate(-1)}
            sx={{
              width: "20px",
              height: "20px",
              position: "absolute",
              top: "5%",
              left: "5%",
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              width: "500px",
              mx: "auto",
              height: "auto",
            }}
          >
            <Typography sx={BigTextStyle}>Payment Successful</Typography>
            <img
              src={PaymentSuccessIcon}
              alt=""
              style={{ height: "auto", width: "500px" }}
            />
            <Typography sx={BigTextStyle}>
              Your Order has been Placed{" "}
            </Typography>

            <Typography sx={SmallTextStyle}>
              Your order has been received & you will have your products soon ,
              you can track the same in your product tracking page.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                mt: 2,
              }}
            >
              <Button sx={ExploreButtonStyle} onClick={NavigateToExplore}>
                View Your Order
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PaymentSuccessful;

const BigTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "24px",
  fontWeight: 600,
  letterSpacing: "0em",
  color: "#6B7A99",
  textAlign: "center",
  lineHeight: "normal",
};

const SmallTextStyle = {
  color: "#6B7A99",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  width: "95%",
  mx: "auto",
  mt: 2,
};

const ExploreButtonStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  width: "100%",
  borderRadius: 2,
  background: "#445FD2",
  color: "#fff",
  fontSize: "12px",
  textTransform: "none",
  "&:hover": {
    background: "#445FD2",
  },
};
