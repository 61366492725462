import { createSlice } from '@reduxjs/toolkit';

export const AllFilterSlice = createSlice({
    name: 'allFilter',
    initialState: {
        AllFilter: {},
    },
    reducers: {
        SET_ALLFILTER_COUNT: (state, action) => {
            state.AllFilter = action.payload;
        },
    },
});


export const { SET_ALLFILTER_COUNT } = AllFilterSlice.actions;
export default AllFilterSlice.reducer;
