import React, { useRef } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import Header from "../../pages/LandingPage/Navbar";
import BannerImg from "../../assets/Blog_Banner.jpg";
import { Helmet } from "react-helmet";
import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import PrintIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import graphimg from "../../assets/bloggraph.png";

const ResponsiveImage = ({ src, alt, sx }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      width: "100%",
      height: { xs: "auto" },
      ...sx,
    }}
  />
);

const CustomTypography = ({ children, sx, onClick }) => (
  <Typography
    sx={{
      fontFamily: "Poppins",
      letterSpacing: "1px",
      fontSize: "15px",
      color: "gray",
      textTransform: "capitalize",
      fontWeight: 400,
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

export default function EconomyInIndia() {
  const card = [
    {
      name: "The Ultimate Guide to Barter Exchange goods without involving money",
      description:
        "One of the oldest and most effective methods of trade that existed several centuries ago is barter which has regained popularity in the modern economy.",
      link: "/barter-exchange-goods-without-involving-money",
    },
    {
      name: "The Difference Between Barter Exchange and Money Exchange",
      description:
        "Commerce and trade are some of the oldest activities known to man and have been present throughout history",
      link: "/difference-between-barter-exchange-and-money-exchange",
    },
    {
      name: "Top 10 Best Barter trade Companies and websites in India",
      description:
        "Barter trade is a very old method of exchange where goods and services are swapped without the use of money",
      link: "/barter-trade-companies",
    },
  ];

  const iconMapping = {
    Favourites: <FavoriteIcon />,
    Share: <ShareIcon />,
    Print: <PrintIcon />,
  };

  const componentRef = useRef();
  const location = useLocation();
  const pathname = location.pathname;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClick = (text) => {
    if (text === "Print") {
      handlePrint();
    } else if (text === "Share") {
      navigator.clipboard.writeText(`https://bxiworld.com${pathname}`);
      toast.info("URL Copied", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "#f9f6ef",
      }}
      ref={componentRef}
    >
      <ToastContainer style={{ fontSize: "16px" }} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Advantages and disadvantages of the barter system of exchange
        </title>
        <meta
          property="og:title"
          content="Advantages and disadvantages of the barter system of exchange"
        />
        <meta
          name="og:description"
          content="barter system has many advantages and helps businesses to function even during adverse situations, there are also some disadvantages of the barter system."
        />
        <meta
          name="description"
          content="barter system has many advantages and helps businesses to function even during adverse situations, there are also some disadvantages of the barter system."
        />
      </Helmet>
      <Header />
      <Box sx={{ position: "relative" }}>
        <ResponsiveImage src={BannerImg} alt="Business meeting" />
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-70px", sm: "-70px", md: "-70px" },
            width: { xs: "70%", sm: "70%", md: "65%" },
            height: { xs: "25%", sm: "25%", md: "25%" },
            bgcolor: "#f9f6ef",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "25px",
                md: "35px",
                lg: "45px",
                xl: "55px",
              },
              width: {
                xs: "82%",
                sm: "82%",
                md: "82%",
                lg: "85%",
                xl: "85%",
              },
              height: {
                xs: "100%",
                sm: "100%",
              },
              fontFamily: "Poppins",
              fontWeight: 500,
              textAlign: "start",
            }}
          >
            Advantages and disadvantages of the barter system of exchange
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{
          mt: { xs: 15, sm: 15, md: 10 },
          px: { xs: 2, md: 4 },
        }}
      >
        <Grid item xs={12} md={8}>
          <Divider />
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {["Favourites", "Share", "Print"].map((text, index) => (
                <CustomTypography
                  key={index}
                  sx={{
                    my: 2,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                      lg: "flex-start",
                      xl: "flex-start",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "center",
                    },
                    width: "50%",
                    gap: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(text)}
                >
                  <IconButton>{iconMapping[text]}</IconButton>
                  {text}
                </CustomTypography>
              ))}
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomTypography sx={{ py: 2 }}>
                <p>
                  {" "}
                  Whether you are planning to start a barter business or want to
                  understand how it works, along with the advantages and
                  disadvantages of the barter system, we cover all aspects to
                  help you know barter exchange in depth. We also aim to clear
                  up any doubts and provide accurate information to assist you
                  in making informed decisions about bartering. The barter
                  system is probably the oldest method of business, and it was
                  first introduced during ancient times when people wanted to
                  get new things related to their daily lives. It is the method
                  of trading where one or two items are exchanged between one of
                  two parties instead of using a currency. Ancient civilizations
                  like Egyptian civilization and Mesopotamian relationships or
                  even Greeks preferred to exchange essential commodities like
                  livestock, grains, food, textiles, weapons, and even precious
                  items like precious stones and jewelry. For them, the barter
                  system was a better method, and the items were exchanged
                  depending on the value of the items and their requirements.
                  While the barter system lost its focus and importance later
                  due to the introduction of currencies and coins, it is still
                  used today. According to IRTA, the global barter exchange is
                  nearly 12-14 billion dollars in the economy. The institution
                  also further diversified the industry. The global barter
                  countertrade accounts for almost 50% of the barter economy,
                  retail barter accounts for 20%, and the rest of the 30% are
                  corporate barter networks.
                </p>
                <ResponsiveImage src={graphimg} alt="Business meeting" />
                <p>
                  In the past 42 years, barter exchange has gained more
                  prevalence. Recently, it gained more importance during the
                  COVID-19 pandemic era. The uncertainty during the pandemic era
                  also forced many businesses to find alternative opportunities
                  and embrace the barter exchange system.
                </p>
              </CustomTypography>
              <Divider />
              <CustomTypography sx={{ py: 2 }}>
                <Typography variant="h4" sx={h2ypo}>
                  Advantages of the Barter System
                </Typography>
                <span style={{ fontSize: "30px" }}>"</span>
                Since its initial introduction during ancient civilization, the
                barter system has existed. It is highly feasible and flexible
                and offers better satisfaction and other advantages. The barter
                exchange system also has multiple advantages, including –
                <li>
                  <b>Simple method</b>
                  <br />
                  The main advantage of the barter system is that it is
                  fundamentally a simple system where two or more parties
                  exchange their services or commodities. It does not require a
                  currency or a financial unit to trade. Instead, the
                  commodities are exchanged directly. It is essential in
                  situations where currencies may not be accessible to the
                  banking system may not be available. The simplicity has
                  recently resulted in 400 barter exchange platforms with the
                  collective membership of more than 40000 businesses worldwide.
                </li>
                <br />
              </CustomTypography>
            </Grid>
          </Grid>
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ResponsiveImage
              src="https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25060.jpg"
              alt="Business meeting"
              sx={{ width: { xs: "100%", md: "90%" }, ml: { md: "auto" } }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <li>
                <b>The whole system is flexible and customizable</b> <br /> In a
                barter trade system, the exchange happens directly without
                monetary exchange. Hence, the system becomes flexible, and
                trading parties can customize it according to their available
                resources and requirements. On top of that, trading parties can
                also negotiate and adjust the trading according to the perceived
                value of their items. From international to local trading
                (LETS), barter systems can be effectively used in any volume. It
                allows parties to exchange commodities in a way that offers
                better satisfaction and a better trading experience.
              </li>
              <br />
              <li>
                <b> Resource optimisation</b> <br />
                Barter exchange systems also allow any business to optimize its
                available resources, minimize losses and wastage, and maximize
                the use of its products and services. A simple example can be
                the local trading channels, where local businesses, like local
                farmers, exchange and trade commodities with the local food
                joints. According to LETSLINK UK, nearly 33,000 active members
                in the UK engage in community-based or local exchange trading
                systems.
              </li>
              <br />
              <li>
                <b> Building a solid business network</b> <br /> A barter
                exchange is done directly without monetary exchange, increasing
                trust among the parties. With this method, a strong community
                can be created where interactions are more heartfelt, creating a
                strong community with strong social values. It also makes a
                platform for stability where businesses can coexist in harmony.
              </li>
              <br />
              <li>
                <b> Smooth trading even during financial instabilities</b>{" "}
                <br />
                Another advantage of the barter system is that it helps smooth
                trading even during financial constraints. It offers an
                alternative way to sustain despite financial sanctions or
                constraints like limited cash flow, economic depressions, or
                emergencies. As businesses trade among themselves without using
                any currency or business network, they can smoothly trade and
                acquire items or raw materials needed, even in adverse
                situations. Daron Acenoglue and James Robinson displayed in
                their book “ Why Nations Fail” that the economy was sustained
                during the Great Depression, as the business adapted barter
                exchange to survive, as the value of currency failed and items
                started becoming scarce.
              </li>
              <br />
              <li>
                <b> Sustainability</b> <br /> In the modern world, reducing
                environmental footprints and achieving sustainability are
                essential. A barter exchange is a sustainable option that
                creates a circular economy where items are not discarded
                randomly. This results in less environmental damage and less
                pollution with proper resource utilization.
              </li>
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Disadvantages of the barter system
              </Typography>
              Although the barter system has many advantages and helps
              businesses to function even during adverse situations, it has some
              disadvantages like-
              <br />
              <br />
              <li>
                <b>No standard measurement unit</b> <br /> One of the main
                disadvantages of a barter exchange system is that there needs to
                be a standard method of creating unit measurements to find the
                approximate value of specific times. Standard units may only
                sometimes be feasible and may lead to further confusion. This
                disadvantage is more prominent when the items exchanged are
                different and diverse.
              </li>
              <br />
              <li>
                <b> Indivisibility of certain trading commodities</b> <br />
                Another issue with the barter system is the indivisibility of
                items. While some items can be divided into smaller units, some
                are impossible. For example, dividieasierock needs to lose
                sobyts usefulness. In this case, minding the exact unit for
                exchanging becomes an issue.
              </li>
              <br />
              <li>
                <b> It is impossible to store or forward wealth.</b> <br /> In
                barter exchange, method goods or services are exchanged for
                instant use or use shortly. Hence, the traded items, except for
                precious items or jewels, often come with expiry value. In such
                cases, the limited shelf life of the exchanged items prevents
                long-term savings and wealth accumulation. Hence, it may offer a
                sustainable way to survive for a short period. But, since it is
                challenging to store wealth or currency, it is not great for
                survival for an extended period if the exchange is not repeated.
              </li>
              <br />
              <li>
                <b> It may be better for diverse and complex economies.</b>{" "}
                <br />A barter system is also challenging to maintain in diverse
                and complex economies. As the economy grows, it becomes more
                complicated and layered. For such large-scale economies, trading
                needs a better and long-lasting medium, like a currency that can
                be traded and used in any condition. The Barter system becomes
                less valuable in a large volume of transactions as the volume
                and needs of diverse items make it difficult to sustain on a
                large scale.
              </li>
              <br />
              <li>
                <b> Double coincident of wants</b> <br /> Both parties must want
                or need specific items or services to make the barter system
                successful. Two parties who need items that they can provide
                each other must also come together for barter exchange. This may
                only sometimes be possible in a complex or large economy.
              </li>
            </CustomTypography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                How the modern barter system works
              </Typography>
              To utilize the barter system properly in modern scenarios, the
              barter system has evolved and adapted coring to modern trades.
              Now, there are multiple barter exchange platforms where two
              organizations can connect and discuss their requirements for goods
              and services. Hence, finding partners or parties to perform barter
              exchange becomes easier.
              <br />
              The exchanges offer barter credits or trading credits, which work
              as a unit for measuring the value of each service and commodity.
              This leads to seamless transactions without losing the value of
              the traded commodities or services. Alongside this, the trading
              credit allows businesses to opt for indirect swaps even if there
              are no double coincidences of needs.
              <br />
              The modern barter system has also created a transaction channel
              according to the regulations and legal business rules, which
              offers holistic transactions without any issues. Furthermore, the
              barter exchanges also create a platform that fosters trust and
              mutual understanding with proper quality during and after the
              exchange.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Conclusion
              </Typography>
              The barter exchange system was discovered when humans started to
              feel the need to acquire different items or services to survive.
              With a straightforward approach, this trading method does not
              require money and can create a sustainable channel even during
              emergencies when cash flow gets limited; however, in the modern
              economy, barter exchange can be a bit difficult due to the complex
              and diverse nature of the economy.
              <br />
              On top of that, the barter system of exchange may also not be
              feasible when the items cannot be divided into units or there are
              no traders who have mutual needs for services or goods. To counter
              this, the modern barter system has evolved where the traditional
              exchange method is filled with a modern twist. Modern barter
              exchanges now offer a diverse and engaging platform where traders
              can come forward and trade their commodities without the
              complexities.
            </CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              mt: 2,
              ml: { xs: 0, md: 3 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "gray",
                textTransform: "capitalize",
                fontSize: "18px",
                fontWeight: 500,
                letterSpacing: "2px",
              }}
            >
              RELATED BLOGS
            </Typography>
          </Box>
          {card.map((data, idx) => {
            const wordLimit = 4;
            function truncateText(text, wordLimit) {
              const words = text.split(" ");
              if (words.length > wordLimit) {
                return words.slice(0, wordLimit).join(" ") + "...";
              }
              return text;
            }
            return (
              <Box>
                <a
                  href={data.link}
                  target="_blank"
                  alt="wtsp"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <ResponsiveImage
                      src={BannerImg}
                      alt="Related exhibition"
                      sx={{
                        width: { xs: "100%", md: "65%" },
                        mt: 2,
                        ml: { xs: 0, md: 3 },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { xs: "-10px", sm: "-10px", md: "-10px" },
                        left: { xs: "0px", sm: "0px", md: "22px" },
                        width: { xs: "50%", sm: "50%", md: "50%" },
                        height: { xs: "15%", sm: "15%", md: "15%" },
                        bgcolor: "#f9f6ef",
                        py: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xs: "15px",
                            sm: "15px",
                            md: "15px",
                            lg: "20px",
                            xl: "20px",
                          },
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          color: "gray",
                          textAlign: "start",
                          width: "100%",
                        }}
                      >
                        {truncateText(data.name, wordLimit)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      mt: 3,
                      ml: { xs: 0, md: 3 },
                      width: { xs: "100%", md: "65%" },
                      lineHeight: "25px",
                      fontFamily: "Poppins",
                      textAlign: "start",
                      color: "#6d6f71",
                      textTransform: "capitalize",
                      fontWeight: 400,
                      fontSize: "15px",
                      letterSpacing: "1px",
                    }}
                  >
                    {data.description}
                  </Typography>
                </a>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

const h2ypo = {
  fontSize: {
    xs: "22px",
    sm: "22px",
    md: "27px",
    lg: "27px",
    xl: "27px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};

const h4typo = {
  fontSize: {
    xs: "15px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};
