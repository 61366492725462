import React from "react";
import "./Loader.css"; // Make sure to import your CSS

const DotLoader = () => {
  return (
    <div className="dot-loader">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default DotLoader;
