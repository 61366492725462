/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import { Typography } from "@mui/material";
import React from "react";

const Membership_Agreement = (props) => {
  return (
    <div>
      <Typography sx={termstextBold} gutterBottom>
        Membership Agreement
      </Typography>
      <Typography sx={termstext} paragraph>
        This Agreement ("Agreement") is entered into between the undersigned
        corporation, hereinafter referred to as "Member," and BXI WORLD LLP,
        hereinafter referred to as "BXI," for the purpose of establishing a
        membership account with BXI for trading products and services within the
        BXI Marketplace network. Member agrees to the following terms and
        conditions as a Member of BXI:
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        1. Offer of Products and Services
      </Typography>
      <Typography sx={termstext} paragraph>
        Member agrees to offer their products and services to other BXI Members
        on the Marketplace platform at their regular prevailing prices,
        providing the same warranties, guarantees, and level of service as
        offered to regular cash customers.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        2. Membership Fees
      </Typography>
      <Typography sx={termstext} paragraph>
        Members shall pay the appropriate membership fee as per the selected
        membership type Standard Membership or Advance Membership.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        3. BXI Success Fees
      </Typography>
      <Typography sx={termstext} paragraph>
        Member agrees to pay BXI a success fee, calculated at a rate of 12% for
        Standard members and 8% for Advance members, based on the total amount
        of each trade purchased from other BXI Members on the Marketplace
        platform.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        4. Barter Coins and Credit Line Policies
      </Typography>
      <Typography sx={termstext} paragraph>
        Member acknowledges, consents, and grants BXI the right and authority to
        regulate and control the BXI Coins within the exchange system, as well
        as administer Credit Line and Conditional Credit Line policies for
        Members as stipulated in this Agreement.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        5. Voluntary Trades and Transactions
      </Typography>
      <Typography sx={termstext} paragraph>
        Member understands and agrees that all trades and transactions between
        Members on the Marketplace platform are voluntary and based on mutual
        agreement.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        6. Responsibility for Trades and Disputes
      </Typography>
      <Typography sx={termstext} paragraph>
        BXI assumes no responsibility for the quality, timely delivery,
        warranty, or any disputes arising between BXI Members regarding the
        traded products or services. Member acknowledges that BXI operates in a
        brokerage capacity and, therefore, agrees to indemnify and hold BXI
        harmless from any claims, debts, reasonable attorney fees, or
        liabilities arising from trade transactions, whether Member is a buyer
        or a seller.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        7. Modification of Agreement
      </Typography>
      <Typography sx={termstext} paragraph>
        BXI may, at its sole discretion, modify, amend, or change this Agreement
        as deemed necessary, provided the changes and policies are reasonably
        uniform among Members.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        8. Trade Rules and Regulations
      </Typography>
      <Typography sx={termstext} paragraph>
        The Member agrees to abide by and adhere to the "Trade Rules and
        Regulations" attached hereto, which form an integral part of this
        Agreement.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        9. Entire Agreement
      </Typography>
      <Typography sx={termstext} paragraph>
        This Agreement, along with the attached "Trade Rules and Regulations,"
        constitutes the entire agreement between Member and BXI World LLP and
        shall be binding upon Member, as well as their heirs, successors,
        personal representatives, and assigns.
      </Typography>

      <Typography sx={termstext} paragraph>
        Member acknowledges that this Agreement becomes effective upon being
        signed or agreed by Member and approved by an authorized representative
        of BXI.
      </Typography>
      <Typography sx={termstext} paragraph>
        IN WITNESS WHEREOF, Member and BXI have executed this Agreement as of
        the date mentioned in this Membership Agreement.
      </Typography>

      <Typography sx={termstext} paragraph>
        Date: {props.Date}
      </Typography>
      <Typography sx={termstext} paragraph>
        Time: {props.Time}
      </Typography>
      <Typography sx={termstext} paragraph>
        Place: {props.Place}
      </Typography>
    </div>
  );
};

export default Membership_Agreement;

const termstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "13px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
};

const termstextBold = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "13px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#000",
};
