//  purchase order constants
export const PURCHASE_ORDER_LIST_REQUEST = "PURCHASE_ORDER_LIST_REQUEST";
export const PURCHASE_ORDER_LIST_SUCCESS = "PURCHASE_ORDER_LIST_SUCCESS";
export const PURCHASE_ORDER_LIST_FAIL = "PURCHASE_ORDER_LIST_FAIL";

export const PURCHASE_ORDER_DETAILS_REQUEST = "PURCHASE_ORDER_DETAILS_REQUEST";
export const PURCHASE_ORDER_DETAILS_SUCCESS = "PURCHASE_ORDER_DETAILS_SUCCESS";
export const PURCHASE_ORDER_DETAILS_FAIL = "PURCHASE_ORDER_DETAILS_FAIL";

export const PURCHASE_ORDER_DELETE_REQUEST = "PURCHASE_ORDER_DELETE_REQUEST";
export const PURCHASE_ORDER_DELETE_SUCCESS = "PURCHASE_ORDER_DELETE_SUCCESS";
export const PURCHASE_ORDER_DELETE_FAIL = "PURCHASE_ORDER_DELETE_FAIL";

export const PURCHASE_ORDER_CREATE_REQUEST = "PURCHASE_ORDER_CREATE_REQUEST";
export const PURCHASE_ORDER_CREATE_SUCCESS = "PURCHASE_ORDER_CREATE_SUCCESS";
export const PURCHASE_ORDER_CREATE_FAIL = "PURCHASE_ORDER_CREATE_FAIL";

export const PURCHASE_ORDER_UPDATE_REQUEST = "PURCHASE_ORDER_UPDATE_REQUEST";
export const PURCHASE_ORDER_UPDATE_SUCCESS = "PURCHASE_ORDER_UPDATE_SUCCESS";
export const PURCHASE_ORDER_UPDATE_FAIL = "PURCHASE_ORDER_UPDATE_FAIL";

export const PURCHASE_ORDER_CREATE_REVIEW_REQUEST =
  "PURCHASE_ORDER_CREATE_REVIEW_REQUEST";
export const PURCHASE_ORDER_CREATE_REVIEW_SUCCESS =
  "PURCHASE_ORDER_CREATE_REVIEW_SUCCESS";
export const PURCHASE_ORDER_CREATE_REVIEW_FAIL =
  "PURCHASE_ORDER_CREATE_REVIEW_FAIL";
export const PURCHASE_ORDER_CREATE_REVIEW_RESET =
  "PURCHASE_ORDER_CREATE_REVIEW_RESET";
