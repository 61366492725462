import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  CLEAR_ERRORS,
} from "../constant/products";

// Get All Products
export const getProductFilter =
  (price = "", sortBy = "", ProductQty = "", categoryId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODUCT_REQUEST.toString() });

      if (sortBy === "sort") {
        sortBy = -1;
      }

      const data = "";

      dispatch({
        type: ALL_PRODUCT_SUCCESS.toString(),
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCT_FAIL.toString(),
        payload: error.response.data.message,
      });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
