import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import HBM from "../../../assets/HomePageImages/becomememberimg.svg";
import Fade from "react-reveal/Fade";
const BecomeMember = () => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          height: {
            xl: "80vh",
            lg: "80vh",
            md: "80vh",
            sm: "100vh",
            xs: "100vh",
          },
          width: "100%",
          mt: { xl: 0, lg: 0, md: 0, sm: 15, xs: 15 },
        }}
      >
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sm={12}
            sx={{ mt: { xl: 4, lg: 4, md: 4, sm: 8, xs: 8 } }}
          >
            <Box
              component={"iframe"}
              src="https://www.youtube.com/embed/SDVXjAgnZFQ"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              sx={{
                width: {
                  xl: "95%",
                  lg: "95%",
                  md: "95%",
                  sm: "90%",
                  xs: "90%",
                },
                mx: "auto",
                height: "55vh",
                objectFit: "cover",
                border: "none",
                marginLeft: "20px",
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={headingStyle}
                  >
                    HOW DOES BXI WORK?
                  </Typography>
                </Fade>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...headingStyle,
                      lineHeight: "10px",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                    }}
                  >
                    Learn To Barter
                  </Typography>
                </Fade>
              </Box>

              <Box
                sx={{
                  width: "90%",
                  mx: "auto",
                  maxWidth: "60%",
                  height: "80%",
                  maxHeight: "48vh",
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <img
                  src={HBM}
                  alt="HBM"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default BecomeMember;
const gradientText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "22px",
    sm: "25px",
    md: "28px",
    lg: "28px",
    xl: "28px",
  },
  letterSpacing: "0.5px",
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  background: "linear-gradient(75deg, #375DBB 29.17%, #00B1FF 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};
const headingStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "22px",
    sm: "25px",
    md: "28px",
    lg: "28px",
    xl: "28px",
  },
  letterSpacing: "0.5px",
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  color: "#0D0E0E",
  marginRight: "10px",
};
