import { SET_ACTIVECURRENTPAGE_COUNT } from "../../reduser/Home-Filter/CurrentPageCount";

export const setCurrentPageCount = (count) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVECURRENTPAGE_COUNT,
      payload: count,
    });
  };
};
