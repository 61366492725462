import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Box } from '@mui/material';
const AnimationBigArrrows = ({
  landingarrowone,
  landingarrow,
  isReset = true,
}) => {
  const image1Spring = useSpring({
    from: { left: -400 },
    to: { left: 0 },
    config: { duration: 2000 },
    reset: isReset,
  });

  const image2Spring = useSpring({
    from: { right: -400 },
    to: { right: 0 },
    config: { duration: 2000 },
    reset: isReset,
  });
  return (
    <>
      <Box
        sx={{
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'none',
            xs: 'none',
          },
        }}
      >
        <animated.img
          src={landingarrowone}
          alt="Image 1"
          style={{
            position: 'absolute',
            top: '-320px',
            left: '0px',
            width: '320px',
            height: 'auto',
            ...image1Spring,
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'none',
              xs: 'none',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'none',
            xs: 'none',
          },
        }}
      >
        <animated.img
          src={landingarrow}
          alt="Image 2"
          style={{
            position: 'absolute',
            bottom: '-390px',
            right: '0px',
            width: '320px',
            height: 'auto',
            ...image2Spring,
          }}
        />
      </Box>
    </>
  );
};

export default AnimationBigArrrows;
