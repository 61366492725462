import React, { useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLoginUser } from "../../Hooks/Auth";
import sendEvents from "../../utils/sendEvents";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { ToastContainer, toast } from "react-toastify";
import mainImg from "../../assets/Images/register/login.svg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import * as StylesOnBoarding from "../../components/common/Styled/StylesOnBoarding.js";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate: LoginClick } = useLoginUser();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        email: z.string(),
        password: z.string(),
      })
    ),
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const queryClient = useQueryClient();

  const LoginUserClick = handleSubmit((data) => {
    const Senddata = { email: data.email, password: data.password };
    if (Senddata.email !== "" && Senddata.password !== "") {
      LoginClick(Senddata, {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("getLoggedInUser");
          if (res.status === 200) {
            GlobalToast("Login Successfull", "success");
          } else if (res.status === 400) {
            GlobalToast("Invalid Credentials", "error");
          } else if (res?.data?.data?.Company?.membershipExpired) {
            GlobalToast("Your Membership has expired", "error");
            navigate("/pricing");
          }
          setTimeout(() => {
            if (res?.data?.data?.Company?.membershipExpired) {
              GlobalToast("Your Membership has expired", "error");
              navigate("/pricing");
            } else if (res?.data?.data?.Profile === 100) {
              navigate("/home");
            } else {
              if (res?.data?.data?.Company?.otpVerified) {
                switch (res?.data?.data?.Company?.companyOnboardingStatus) {
                  case "GST":
                    navigate("/gst");
                    break;
                  case "BANK_DETAILS":
                    navigate("/bank");
                    break;
                  case "REVERSE_DROP":
                    navigate("/forward_penny");
                    break;
                  case "COURT_CASE":
                    navigate("/reverse_penny");
                    break;
                  case "TNC":
                    navigate("/home/terms");
                    break;
                  case "PAYMENT":
                    navigate("/pricing");
                    break;
                  case "UNDER_REVIEW":
                    navigate("/under_review");
                    break;
                  case "FORWARD_DROP":
                    navigate("/home/terms");
                    break;
                  case "MEMBERSHIP":
                    navigate("/pricing");
                    break;
                  case "ONBOARDING_COMPLETE":
                    navigate("/home");
                    break;

                  default:
                    navigate("/");
                    break;
                }
              } else {
                const searchParams = new URLSearchParams();
                searchParams.append("id", res?.data?.data?.Company?._id);
                searchParams.append("email", res?.data?.data?.Company?.email);
                searchParams.append(
                  "companyName",
                  res?.data?.data?.Company?.companyName
                );
                const queryString = searchParams.toString();
                const url = `/otp?${queryString}`;
                navigate(url);
              }
            }
          }, [2000]);
        },
        onError: (error) => {
          if (error?.response?.data === "Invalid Credentials") {
            GlobalToast(error?.response?.data, "error");
          } else {
            GlobalToast(
              error?.response?.data?.message +
                "Please try agian after 15 minutes.",
              "error"
            );
          }
        },
      });
    } else {
      GlobalToast("Please fill all the fields", "error");
    }
    localStorage.setItem("loginData", JSON.stringify(Senddata));
    localStorage.getItem("buyer");
    storeUserTypeOnLocalStorage();
    GetCompanyData();
  });

  const GetCompanyData = async () => {
    try {
      await axios
        .get(`auth/getauthsCompany`, { withCredentials: true })
        .then((res, idx) => {
          GetCompanyTypeData(res?.data?.companyType);
        });
    } catch (error) {}
  };

  const GetCompanyTypeData = async (props) => {
    try {
      await axios
        .get(`company_type/get_companyType/${props}`)
        .then((res, idx) => {
          localStorage.setItem("companyType", res?.data?.CompanyTypeName);
        });
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyData();
  }, []);

  const storeUserTypeOnLocalStorage = () => {
    localStorage.setItem("userhubtype", "buyer");
  };

  return (
    <Paper
      sx={{
        height: "100vh",
        width: "100%",
        maxHeight: "100vh",
        maxWidth: "100vw",
        overflowY: "hidden",
        bgcolor: "red",
      }}
    >
      <ToastContainer style={{ fontSize: "16px" }} />

      <Paper elevation={0} sx={StylesOnBoarding.mainCommonPage}>
        <form onSubmit={LoginUserClick}>
          <Grid
            container
            sx={{
              background: "#fff",
              height: "100vh",
              width: {
                xl: "110%",
                lg: "110%",
                md: "110%",
                sm: "100%",
                xs: "100%",
              },
              overflowY: "hidden",
            }}
          >
            {/* detail Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={5}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: {
                  xl: "-20rem",
                  lg: "-20rem",
                  md: "-20rem",
                  sm: "-30rem",
                  xs: "-30rem",
                },
              }}
            >
              <Grid
                container
                sx={{
                  height: {
                    xl: "20%",
                    lg: "20%",
                    md: "20%",
                    sm: "20%",
                    xs: "20%",
                  },
                }}
              >
                {/* Barter Logo */}
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "90%",
                      mx: "auto",
                      mt: 0,
                      justifyContent: {
                        xl: "flex-start",
                        lg: "flex-start",
                        md: "flex-start",
                        sm: "center",
                        xs: "center",
                      },
                      display: "flex",
                      height: "70px",
                      alignItems: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component="img"
                      src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXI_LOGO.png"
                      alt="img"
                      style={{
                        height: "50px",
                        width: "50px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                        textAlign: "center",
                        color: "#000",
                        mb: "-3px",
                        textTransform: "uppercase",
                      }}
                    >
                      Barter Exchange of India
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography sx={StylesOnBoarding.HeadText}> Log In</Typography>

              <Box
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: "90%",
                  gap: "20px",
                  maxWidth: "450px",
                }}
              >
                {/* input Box */}
                <ThemeProvider theme={outerTheme}>
                  <Box sx={StylesOnBoarding.BankInput}>
                    <Typography sx={StylesOnBoarding.loginLableStyle}>
                      Email Address
                    </Typography>
                    <input
                      type={"text"}
                      {...register("email")}
                      placeholder="barterexchange@bxi.com"
                      style={StylesOnBoarding.loginInputStyle}
                    />
                  </Box>

                  <Box sx={StylesOnBoarding.BankInput}>
                    <Typography sx={StylesOnBoarding.loginLableStyle}>
                      Password
                    </Typography>
                    <input
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      placeholder="********"
                      style={StylesOnBoarding.loginInputStyle}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        togglePasswordVisibility();
                        sendEvents("Click on togglepassword");
                      }}
                      style={{ ...StylesOnBoarding.EyeStyle }}
                    >
                      {showPassword ? (
                        <FaEye color="#445FD2" />
                      ) : (
                        <FaEyeSlash color="#445FD2" />
                      )}
                    </button>
                  </Box>
                </ThemeProvider>
                <Button
                  type="submit"
                  onClick={() => {
                    sendEvents("Click on login");
                  }}
                  variant="contained"
                  sx={StylesOnBoarding.LoginButton}
                >
                  <Typography sx={StylesOnBoarding.loginButtonTextStyle}>
                    Log in
                  </Typography>
                </Button>
                <Grid
                  container
                  sx={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "grid",
                  }}
                >
                  <Link to={"/forget"} style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        ...StylesOnBoarding.accountText,
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "#375DBB",
                      }}
                    >
                      Forgot password ?{" "}
                    </Typography>
                  </Link>

                  <Typography sx={StylesOnBoarding.accountText}>
                    Don’t have an account?{" "}
                    <Link
                      to={"/createaccount"}
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          color: "#375DBB",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Sign up
                      </span>
                    </Link>
                  </Typography>
                  <Typography sx={StylesOnBoarding.accountText}>
                    Explore market place as a{" "}
                    <Link to={"/explore"} style={{ textDecoration: "none" }}>
                      <span
                        style={{
                          color: "#375DBB",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Guest
                      </span>
                    </Link>
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            {/* image Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: {
                  xl: "flex",
                  lg: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <img
                src={mainImg}
                alt="img"
                style={StylesOnBoarding.CommonImgStyle2}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Paper>
  );
};

export default Login;

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#6B7A99",
    },
  },
  typography: {
    fontSize: 20,
  },
});
