import React, { useRef } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import Header from "../../pages/LandingPage/Navbar";
import BannerImg from "../../assets/Blog_Banner.jpg";
import { Helmet } from "react-helmet";
import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import PrintIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ResponsiveImage = ({ src, alt, sx }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      width: "100%",
      height: { xs: "auto" },
      ...sx,
    }}
  />
);

const CustomTypography = ({ children, sx, onClick }) => (
  <Typography
    sx={{
      fontFamily: "Poppins",
      letterSpacing: "1px",
      fontSize: "15px",
      color: "gray",
      textTransform: "capitalize",
      fontWeight: 400,
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

export default function FutureOfBarter() {
  const card = [
    {
      name: "The Ultimate Guide to Barter Exchange goods without involving money",
      description:
        "One of the oldest and most effective methods of trade that existed several centuries ago is barter which has regained popularity in the modern economy.",
      link: "/barter-exchange-goods-without-involving-money",
    },
    {
      name: "Advantages and disadvantages of the barter system of exchange",
      description:
        "Whether you are planning to start a barter business or want to understand how it works",
      link: "/advantages-and-disadvantages-of-the-barter-system-of-exchange",
    },
    {
      name: "Top 10 Best Barter trade Companies and websites in India",
      description:
        "Barter trade is a very old method of exchange where goods and services are swapped without the use of money",
      link: "/barter-trade-companies",
    },
  ];

  const iconMapping = {
    Favourites: <FavoriteIcon />,
    Share: <ShareIcon />,
    Print: <PrintIcon />,
  };

  const componentRef = useRef();
  const location = useLocation();
  const pathname = location.pathname;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClick = (text) => {
    if (text === "Print") {
      handlePrint();
    } else if (text === "Share") {
      navigator.clipboard.writeText(`https://bxiworld.com${pathname}`);
      toast.info("URL Copied", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "#f9f6ef",
      }}
      ref={componentRef}
    >
      <ToastContainer style={{ fontSize: "16px" }} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Difference Between Barter Exchange and Money Exchange</title>
        <meta
          property="og:title"
          content="Difference Between Barter Exchange and Money Exchange"
        />
        <meta
          name="og:description"
          content="The essential difference between barter exchange and money exchange is the mode of value transfer."
        />
        <meta
          name="description"
          content="The essential difference between barter exchange and money exchange is the mode of value transfer."
        />
      </Helmet>
      <Header />
      <Box sx={{ position: "relative" }}>
        <ResponsiveImage src={BannerImg} alt="Business meeting" />
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-70px", sm: "-70px", md: "-70px" },
            width: { xs: "70%", sm: "70%", md: "65%" },
            height: { xs: "25%", sm: "25%", md: "25%" },
            bgcolor: "#f9f6ef",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "25px",
                md: "35px",
                lg: "45px",
                xl: "55px",
              },
              width: {
                xs: "82%",
                sm: "82%",
                md: "82%",
                lg: "85%",
                xl: "85%",
              },
              height: {
                xs: "100%",
                sm: "100%",
              },
              fontFamily: "Poppins",
              fontWeight: 500,
              textAlign: "start",
            }}
          >
            The Difference Between Barter Exchange and Money Exchange
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{
          mt: { xs: 15, sm: 15, md: 10 },
          px: { xs: 2, md: 4 },
        }}
      >
        <Grid item xs={12} md={8}>
          <Divider />
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {["Favourites", "Share", "Print"].map((text, index) => (
                <CustomTypography
                  key={index}
                  sx={{
                    my: 2,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                      lg: "flex-start",
                      xl: "flex-start",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "center",
                    },
                    width: "50%",
                    gap: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(text)}
                >
                  <IconButton>{iconMapping[text]}</IconButton>
                  {text}
                </CustomTypography>
              ))}
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomTypography sx={{ py: 2 }}>
                Commerce and trade are some of the oldest activities known to
                man and have been present throughout history. For thousands of
                years, people have looked for means by which they could trade
                with each other in order to acquire what they required.
                <br />
                The first method of trade was the swapping of goods and services
                for where no form of cash was involved. Today money is the
                primary medium of exchange for goods and services hence
                simplifying the process of exchanging goods and services the
                complexity of the economy.
                <br />
                In this blog, I will explore the{" "}
                <b>
                  difference between barter exchange and money exchange
                </b>{" "}
                this will involve finding out how each system operates and their
                effects on economies especially the developing ones. The
                objective of this guide is to help the user understand the
                historical developments of trade and its importance in the
                current society.
              </CustomTypography>
              <Divider />
              <CustomTypography sx={{ py: 2 }}>
                <Typography variant="h4" sx={h2ypo}>
                  What Sets Barter and Money Exchange Apart
                </Typography>
                <span style={{ fontSize: "30px" }}>"</span>
                The essential{" "}
                <b>difference between barter exchange and money exchange</b> is
                the mode of value transfer. Let's break it down: <br />
                <b> Barter Exchange:</b> In a barter economy, people trade in
                products without money instead, they exchange goods and
                services. For instance, a farmer would barter a bushel of apples
                with a cobbler who provides shoes in return for the apples. As
                it has been indicated, the value of items revolves around the
                two parties in the business.
                <br />
                <b> Money Exchange:</b> In a money-based exchange system,
                products and services are purchased and paid for in money. This
                currency has characteristics of a medium of exchange, a unit of
                account, and a store of value. For example, you may price a pair
                of shoes $50, and the cobbler can use the $50 to get whatever
                they require.
                <br />
                The main distinction is that barter involves swapping goods
                and/or services, while money exchange involves a middleman.
                Thus, this intermediate step, utilizing currency, has its
                benefits and also, drawbacks when it comes to trading.
              </CustomTypography>
            </Grid>
          </Grid>
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ResponsiveImage
              src="https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25060.jpg"
              alt="Business meeting"
              sx={{ width: { xs: "100%", md: "90%" }, ml: { md: "auto" } }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Key Differences Between Barter Exchange and Money Exchange
              </Typography>
              Analyzing the features of barter exchange and money exchange can
              help to explain the strengths and weaknesses of each type of
              exchange. In this case, let us discuss the differences that define
              the two forms of trade more closely.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Tips for Successful Barter Transactions
              </Typography>
              Here are some useful tips that will help you get the most from
              your <b>Barter exchange goods without involving money</b>{" "}
              experience:
              <br />
              <br />
              <Typography sx={h4typo}>Value Determination</Typography>
              One major issue arising in barter is the issue of equal anointing,
              which refers to determining the value of the different goods
              exchanged. In money based system, prices are normally fixed and
              recognized and therefore, it is easier to compare the values of
              different commodities. However, in barter, each trade entails the
              negotiation process. This can create some rather intriguing
              interaction patterns in the context of the exchange.
              <br />
              In barter exchange, value is not absolute but may differ with the
              trade taking place in <b>barter in developing economies</b>. There
              is always a need to negotiate for the <b>trade and commerce</b>{" "}
              because the worth of items may not be the same to both parties.
              This subjectivity can result in more mutually beneficial
              reciprocal exchanges in which both the givers and receivers
              consider themselves as having received something beneficial
              <br />
              On the other hand, money exchange makes it relatively easy to
              determine the cost of service as there is a set rate for the
              various services. One of how it is easier to make this
              determination is the fact that you can always compare the prices
              given by different sellers. There is comparatively less scope for
              bargaining in most of situations, which can be looked at both
              ways, depending on the perspective: favorable for those who do not
              like the idea of bargaining, and unfavorable for those who like to
              bargain.
              <br />
              The subjectivity of barter has its advantages and disadvantages.
              You can easily negotiate the value of the items you are exchanging
              but this often leads to hitches when the two parties do not agree
              on the value of the items that they are exchanging.
              <br />
              <br />
              <Typography sx={h4typo}>Flexibility and Convenience</Typography>
              Yet flexibility and convenience normally lie more with money
              exchange since consumers are willing to do most of the work.
              Having money can be just as simple as having it in your wallet or
              leaving it on your phone screen electronically. It is highly
              recognized, thereby facilitating the purchase of virtually
              everything at any place. You can also save some amount of money
              for future purchases, which can enable you to plan and even invest
              in the future.
              <br />
              However, barter exchange has the following disadvantages and
              advantages compared to bartering: more rigidity. Barter system can
              be useful where people are closely related or during hard economic
              times due to <b>money systems impact</b> when physical money is a
              problem in the market. It enables people to use their skills or
              surplus products in exchange directly, without having to use
              money.
              <br />
              But there are some drawbacks to barter as well. You actually look
              for a person who wants something that you have and who, in return,
              possesses something you desire. If done this way, it can be
              time-consuming and lead to fewer choices. Also, holding value for
              future exchange is not easy because the item or service that is to
              be exchanged may not be stored for future use.
              <br />
              <br />
              <Typography sx={h4typo}>Social and Community Aspects</Typography>
              It is also important to look at the social relations of these
              exchange systems. Barter exchange commonly creates a direct
              interaction and relationship with the trading partners. In
              trading, there is always more interaction with the other party as
              compared to when you are carrying out a sale, perhaps there will
              be more interaction in discussing the items or services that are
              being swapped and other conditions. It can also help promote
              cohesion within a community as members are able to appreciate that
              they all rely on each other.
              <br />
              Money exchange is less personal, but it enables exchange between
              people who may not know each other or can transact over large
              distances. This has fostered trade around the globe and the
              sharing of products and cultures from one country to the other.
              But it can sometimes encourage the aim at possessing rather than
              using, thereby playing a part in the perpetuation of the culture
              of consumption.
              <br />
              On this background, barter exchange seems to promote stronger
              social relations in a community as compared to money exchange that
              encourages more anonymous and widespread economic transactions.
              All of them are significant in creating various forms of economic
              and social relations.
              <br />
              <br />
              <Typography sx={h4typo}>Document the Trade</Typography>
              In cases where the trade is slightly more elaborate, it is
              advisable to jot down the agreement. This can be as formal as
              writing what each of the parties is bringing to the table and what
              each of the parties expects to gain in return. Documentation is
              important to ensure that no conflict arises, and in the event that
              conflicts occur, one can refer to the documentation.
              <br />
              <br />
              <Typography sx={h4typo}>Complexity and Specialization</Typography>
              Barter systems are less complex but not as flexible to accommodate
              the modern economy requirements. Their effectiveness is more
              suitable for the simple and standard forms of transactional
              exchanges.
              <br />
              <li>
                {" "}
                <b>Simple Transactions:</b> Bartering is effective when two
                parties can come to a directly equivalent exchange of goods, but
                it does not adapt well to more complicated needs.
              </li>
              <br />
              <li>
                <b>Limited Specialization:</b> The <b>trade and commerce</b> by
                barter does not allow for the kind of specialization that is the
                hallmark of present day economies.
              </li>
              <br />
              Money systems, on the other hand, allow for intricate and specific
              economic activities to be carried out. They provide a host of
              industries and occupations, which enhances creativity and
              efficiency.
              <br />
              <li>
                {" "}
                <b>Complex Economies:</b> Money helps complex economic
                transactions ranging from the production of goods to complex
                financial dealings.
              </li>
              <br />
              <li>
                <b>Support for Specialization:</b> Money systems allow people to
                become experts in a certain field, which in turn, raises the
                efficiency and innovation rates.
              </li>
              <br />
              <Typography sx={h4typo}>
                Record-Keeping and Accountability
              </Typography>
              One major disadvantage of barter trading is that, it is sometimes
              hard to keep record of the trades made. This means that there are
              usually no written records of trades, which means that the losses
              and gains can hardly be calculated.
              <br />
              Money exchange makes record keeping easy, and any form of
              embezzlement can be easily detected. Transactions can be recorded,
              monitored, and even verified. Business entities, governments, and
              people need to know about their funds’ status and how to handle
              them properly.
              <br />
              <br />
              <Typography sx={h4typo}>
                Psychological and Social Impacts
              </Typography>
              Barter tends to create social relations and a feeling of
              togetherness. It is a direct interaction between individuals who
              create an interface through the barter system.
              <br />
              <li>
                {" "}
                <b>Community Building:</b> Barter is personal and involves
                interpersonal interaction and assistance between individuals.
              </li>
              <br />
              <li>
                <b>Social Value:</b> Barter can convey social values ​​and bring
                community members closer.
              </li>
              <br />
              Money exchange as such is rather effective; however, it also means
              that certain encounters become less personal in nature. But it
              also opens the possibilities of wider economic exchanges and more
              numerous and varied social connections.
              <br />
              <li>
                {" "}
                <b>Broader Interactions:</b> It facilitates trading with a
                larger number of people hence increasing the social circle.
              </li>
              <br />
              <li>
                <b>Impersonal Transactions:</b> Financial transactions are more
                official or business-like than personal.
              </li>
            </CustomTypography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Conclusion
              </Typography>
              The <b>difference between barter exchange and money exchange</b>{" "}
              is an important feature that explains the development of{" "}
              <b>trade and commerce</b>. Compared to bartering, which involves
              swapping goods and services without money, money systems are more
              effective, versatile, and can easily be scaled. Knowing these
              differences is important as it enables one to comprehend
              modern-day complex economies and the impact of money on growth and
              development.
              <br />
              <br />
              It can be noted that for those who want to delve into barter
              exchange, resources such as BXIWORLD are good to go. BXIWORLD puts
              you in contact with your barter partners so you can effectively
              swap your products and services without cash. If you are a
              beginner who wants to engage in bartering or if you are a barter
              who wants to diversify his or her bartering activities, then you
              will find all the help you need at BXIWORLD.
            </CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              mt: 2,
              ml: { xs: 0, md: 3 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "gray",
                textTransform: "capitalize",
                fontSize: "18px",
                fontWeight: 500,
                letterSpacing: "2px",
              }}
            >
              RELATED BLOGS
            </Typography>
          </Box>
          {card.map((data, idx) => {
            const wordLimit = 4;
            function truncateText(text, wordLimit) {
              const words = text.split(" ");
              if (words.length > wordLimit) {
                return words.slice(0, wordLimit).join(" ") + "...";
              }
              return text;
            }
            return (
              <Box>
                <a
                  href={data.link}
                  target="_blank"
                  alt="wtsp"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <ResponsiveImage
                      src={BannerImg}
                      alt="Related exhibition"
                      sx={{
                        width: { xs: "100%", md: "65%" },
                        mt: 2,
                        ml: { xs: 0, md: 3 },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { xs: "-10px", sm: "-10px", md: "-10px" },
                        left: { xs: "0px", sm: "0px", md: "22px" },
                        width: { xs: "50%", sm: "50%", md: "50%" },
                        height: { xs: "15%", sm: "15%", md: "15%" },
                        bgcolor: "#f9f6ef",
                        py: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xs: "15px",
                            sm: "15px",
                            md: "15px",
                            lg: "20px",
                            xl: "20px",
                          },
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          color: "gray",
                          textAlign: "start",
                          width: "100%",
                        }}
                      >
                        {truncateText(data.name, wordLimit)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      mt: 3,
                      ml: { xs: 0, md: 3 },
                      width: { xs: "100%", md: "65%" },
                      lineHeight: "25px",
                      fontFamily: "Poppins",
                      textAlign: "start",
                      color: "#6d6f71",
                      textTransform: "capitalize",
                      fontWeight: 400,
                      fontSize: "15px",
                      letterSpacing: "1px",
                    }}
                  >
                    {data.description}
                  </Typography>
                </a>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

const h2ypo = {
  fontSize: {
    xs: "22px",
    sm: "22px",
    md: "27px",
    lg: "27px",
    xl: "27px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};

const h4typo = {
  fontSize: {
    xs: "15px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};
