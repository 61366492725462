import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DownloadIcon from "../../assets/Images/CommonImages/downloadicon.svg";
import PrintIcon from "../../assets/Images/CommonImages/printicon.svg";
// import LeftArrow from "../../assets/Images/payment/LeftArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useGetInvoiceByOrderSummary } from "../../Hooks/Invoices/useGetInvoiceByOrderSummary";
import LeftArrow from "../../assets/GoLeft.png";
import StacsOfCoinIcon from "../../assets/Images/CommonImages/BXIToken.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import axios from "axios";
import TostMessagesTop from "../../Component/Toast";
import useGetCompanyFullData from "../../Hooks/CompanyData/useGetCompanyFullData";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import { toast } from "react-toastify";

import {
  useCreateDeal,
  useUpdateBuyerEaaaAgreemnetStatus,
  useBuyerUploadPaymentDocs,
} from "./Hooks";
import CommaSeprator from "../../components/CommaSeprator";
import PageLoader from "../../components/LoadingButton/PageLoader";
// import { useBuyerUploadPaymentDocs } from "../pages/PurchaseOrderList/Hooks";

const OrderSummeryDetailsPage = (props) => {
  const { id } = useParams();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [OrderData, setOrderData] = useState({});
  const [PageLoading, setPageLoading] = useState(false);
  const [StorePaymentStatus, setStorePaymentStatus] = useState({});
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/home/paymentprofile/${id}`);
  };

  async function getOrderDetailsById() {
    await axios
      .get(`order/get_order_by_id/${id}`)
      .then((res) => {
        setOrderData(res.data);
        GetEscrowDeal_Status(res.data?.escrowData);
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(true);
      });
  }

  const [isIntervalRunning, setIsIntervalRunning] = useState(false);

  const startInterval = () => {
    setIsIntervalRunning(true);
    const intervalId = setInterval(() => {
      GetEscrowDeal_Status(OrderData?.escrowData);
    }, 5000);
    setIsIntervalRunning(intervalId);
  };

  const stopInterval = () => {
    setIsIntervalRunning(false);
    clearInterval(isIntervalRunning);
  };

  useEffect(() => {
    getOrderDetailsById();
  }, []);

  useEffect(() => {
    if (StorePaymentStatus?.success === "true") {
      let Amount = OrderData?.INRDetails?.totalInrToPay;
      let BatterCoin =
        OrderData?.BXITokenDetails?.totalProductTokens +
        OrderData?.BXITokenDetails?.totalAdCostTokens;
      let InvoiceId = OrderData?._id;

      let buyerCompanyName = "8554887187";
      let parameterName = "buyer_name";
      let parameterValue = buyerCompanyName;
      let parameterName1 = "dynamic_link4_here";
      let parameterValue1 = "https://bxi.unada.in/home/performainvoice";
      navigate(`/home/myorderlist`);
      stopInterval();
    }
  }, [StorePaymentStatus]);

  const {
    mutate: createDealMutate,
    isLoading: createDealLoading,
    data: createDealData,
    error: createDealError,
  } = useCreateDeal();

  const {
    mutate: buyerUploadPaymentDocs,
    isLoading: buyerUploadPaymentDocsLoading,
    error: buyerUploadPaymentDocsError,
  } = useBuyerUploadPaymentDocs();

  const itemDetails = [
    {
      item: "Seller Name :",
      value: OrderData?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
    },
    {
      item: "Address :",
      value: OrderData?.PurchaseOrderData?.SellerDetails?.Address?.AddressLine,
    },
    {
      item: "GSTIN :",
      value: OrderData?.PurchaseOrderData?.SellerDetails?.GSTIN,
    },
    {
      item: "State :",
      value: OrderData?.PurchaseOrderData?.SellerDetails?.Address?.State,
    },
  ];
  const pror = [
    {
      item: "Purchase Order Number :",
      value: OrderData?.PurchaseOrderData?.PoNumber,
    },
    {
      item: "Purchase Order Date :",
      value: new Date(OrderData?.PoDate).toDateString(),
    },
  ];

  const createEscrowDeal = async () => {
    await localStorage.setItem("OrderId", OrderData?._id);
    await createDealMutate(
      {
        OrderId: OrderData?._id,
      },
      {
        onSuccess: (data) => {
          GetPaymentUrl();
        },
        onError: (err) => {},
      }
    );
  };

  const { data: companyData } = useGetCompanyFullData();

  async function GetEscrowDeal_Status(props) {
    await axios
      .post(`escrow/escrow_deal_status/${props?.escrowId}`)
      .then((res) => {
        setStorePaymentStatus(res?.data?.data);
      })
      .catch((err) => {});
  }

  const GetPaymentUrl = async () => {
    buyerUploadPaymentDocs(
      {
        OrderId: OrderData?._id,
        deal_Id: createDealData?.data?.Deal?.dealId,
      },
      {
        onSuccess: (data) => {
          window.open(data?.data?.payment_url, "_blank");
        },
        onError: (err) => {},
      }
    );
  };

  async function CreateWalletTransaction() {
    try {
      axios
        .post(`wallet/create_transaction`, {
          walletAddressFrom:
            OrderData?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyId,
          walletAddressTo:
            OrderData?.PurchaseOrderData?.SellerDetails?.SellerCompanyId,
          amount: OrderData?.PITotals?.TotalCoinsWithAdditionalCost,
          OrderId: OrderData?.OrderId,
          order_id: OrderData?._id,
        })
        .then((res) => {
          axios
            .put(`order/update_order_with_any_data/${id}`, {
              WalletData: res?.data?.body,
            })
            .then((res) => {
              setTimeout(() => {
                toast.success("Payment Successful", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }, 2000);
            });
        });
    } catch (err) {}
  }

  if (PageLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
        <BreadCrumbHeader
          MainText="Order Summary"
          LinkText1="{splitedurl[1]}"
          LinkText2="{splitedurl[2]}"
          link1="Link1"
          link2="link2"
          showbreadcrumb={true}
        />
        <Paper
          sx={{
            bgcolor: "#fff",
            boxShadow: "none",
            p: 3,
            borderRadius: "20px",
            height: "auto",
            minHeight: "520px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          elevation={0}
          // ref={componentRef}
        >
          <Box
            sx={{
              width: "96%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <img
              src={LeftArrow}
              alt="letf-arrow"
              style={{ cursor: "pointer", width: "30px" }}
            />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#6B7A99",
              }}
            >
              Order Summary
            </Typography>
          </Box>
          <Box ref={componentRef}>
            <Grid
              container
              gap={8}
              sx={{
                py: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{
                  ...gridstyle,
                  width: "830px",
                  height: "auto",
                  position: "relative",
                }}
              >
                <Box sx={mainbox}>
                  <Typography sx={headbox}>Supplier Details</Typography>
                </Box>
                <Box sx={contentbox}>
                  {itemDetails?.map((el, idx) => {
                    return (
                      <Box sx={mapbox} key={idx}>
                        <Typography sx={elitem}>{el.item}</Typography>
                        <Typography
                          sx={{
                            ...elvalue,
                            width: "85%",
                            textAlign: "left",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el.value}
                        </Typography>
                      </Box>
                    );
                  })}

                  <Box sx={{ mt: "20px", width: "90%" }}>
                    {pror?.map((el, idx) => {
                      return (
                        <Box sx={{ ...mapbox }} key={idx}>
                          <Typography
                            sx={{
                              ...elitem,
                              width: "300px",
                              textAlign: "left",
                            }}
                          >
                            {el.item}
                          </Typography>
                          <Typography
                            sx={{
                              ...elvalue,
                              width: "85%",
                              textAlign: "left",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {el.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Typography
                    sx={{
                      ...selername,
                      justifyContent: "flex-end",
                      position: "absolute",
                      mt: 8,
                    }}
                  >
                    Code :{" "}
                    {OrderData?.PurchaseOrderData?.SellerDetails?.StateCode}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{
                  ...gridstyle,
                  width: "399px",
                  height: "auto",
                  minHeight: "320px",
                }}
              >
                <Box sx={headbox2}>
                  <Typography sx={detailtext}>INR Details</Typography>
                  <Typography sx={detailtext2}>
                    Details with more info
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>Product Purchase GST </Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.TotalGstAmount}
                      />
                    </Typography>
                  </Box>

                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>Additional Cost</Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={
                          OrderData?.PITotals?.TotalAdditionalCostInRupee +
                          OrderData?.PITotals?.TotalAdditionalCostInBXI
                        }
                      />
                    </Typography>
                  </Box>
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>
                      Bxi Commission(
                      {companyData?.ChoosedPlan === "advance" ? "8" : "12"}%)
                    </Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.TotalBxiCommission}
                      />{" "}
                    </Typography>
                  </Box>
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>
                      GST on Commision (18%)
                    </Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.BxiCommisionGST}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box sx={totaltextbox}>
                  <Box sx={totaltextsec}>
                    <Typography sx={totaltext}>
                      Total without TDS Deduction
                    </Typography>
                    <Typography sx={totaltext}>
                      ₹{" "}
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.TotalAmount}
                      />
                    </Typography>
                  </Box>

                  <Box sx={totaltextsec}>
                    <Typography sx={totaltext}>
                      Deducted TDS (2%) on BXI Commission
                    </Typography>
                    <Typography
                      sx={{ ...totaltext, color: "red", fontWeight: "400" }}
                    >
                      - ₹ <CommaSeprator Price={OrderData?.INRDetails?.TDS} />
                    </Typography>
                  </Box>
                </Box>
                <Box sx={totaltextbox}>
                  <Box sx={totaltextsec}>
                    <Typography sx={totaltext}>Total INR To pay</Typography>
                    <Typography sx={totaltext}>
                      ₹{" "}
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.TotalInrToPay}
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              gap={8}
              sx={{
                py: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xl={11.7}
                lg={11.7}
                md={11.7}
                sm={11.7}
                xs={11.7}
                sx={{
                  ...gridstyle,
                  width: "578px",
                  height: "276px",
                }}
              >
                <Box sx={headbox2}>
                  <Typography sx={detailtext}>Item Details</Typography>
                  <Typography sx={detailtext2}>
                    Details with more info
                  </Typography>
                </Box>

                <Grid
                  container
                  sx={{
                    width: "100%",
                    height: "20%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0rem 0rem 0rem 4rem",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                    <Typography sx={{ ...headtext, textAlign: "left" }}>
                      No
                    </Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Typography sx={{ ...headtext, textAlign: "left" }}>
                      Item
                    </Typography>
                  </Grid>
                  <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                    <Typography sx={headtext}>Quantity</Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Typography sx={headtext}>Rate / Unit</Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Typography sx={headtext}>Additional Cost</Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Typography sx={headtext}>BXI Coin</Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    padding: "1rem 1rem 1rem 4rem",
                    justifyContent: "end",
                    overflow: "scroll",
                  }}
                >
                  {OrderData?.InvoiceData?.ProductData?.map((el, idx) => {
                    return (
                      <Grid container sx={{ mt: "10px" }}>
                        <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {idx + 1}
                          </Typography>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {el.ProductName}
                          </Typography>
                        </Grid>
                        <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,

                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {el.ProductQuantity}
                          </Typography>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,

                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {props?.PageType === "Voucher" ? (
                              <CommaSeprator
                                Price={
                                  props?.PageType === "Voucher"
                                    ? el?.PriceWithoutGST * el?.ProductQuantity
                                    : null
                                }
                              />
                            ) : (
                              <CommaSeprator
                                Price={
                                  el?.ProductTypeName === "Media"
                                    ? el?.DiscountedPrice
                                    : el?.DiscountedPrice
                                }
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              color: "#6B7A99",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              opacity: 1,
                              gap: "5px",
                            }}
                          >
                            <Box
                              component="img"
                              src={StacsOfCoinIcon}
                              sx={{
                                width: "13px",
                                height: "13px",
                              }}
                            />
                            <CommaSeprator
                              Price={
                                el?.AdditionCost
                                  ?.TotalAdditionalCostWtihoutGSTInBXI
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              color: "#6B7A99",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              opacity: 1,
                              gap: "5px",
                            }}
                          >
                            <Box
                              component="img"
                              src={StacsOfCoinIcon}
                              sx={{
                                width: "13px",
                                height: "13px",
                              }}
                            />

                            <CommaSeprator
                              Price={
                                el?.TotalPriceWithoutGST +
                                el?.AdditionCost
                                  ?.TotalAdditionalCostWtihoutGSTInBXI
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                <Box sx={totaltextbox}>
                  <Box sx={totaltextsec}>
                    <Typography sx={totaltext}>Total</Typography>
                    <Typography
                      sx={{
                        ...totaltext,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "5px",
                      }}
                    >
                      <Box
                        component="img"
                        src={StacsOfCoinIcon}
                        sx={{
                          width: "13px",
                          height: "13px",
                        }}
                      />
                      <CommaSeprator
                        Price={
                          OrderData?.PITotals?.TotalProductCoins +
                          OrderData?.PITotals
                            ?.TotalAdditionalCostWtihoutGSTInBXI
                        }
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              gap={8}
              sx={{
                py: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{
                  width: "400px",
                  height: "50px",
                }}
              >
                {createDealLoading ? (
                  <Button
                    variant="contained"
                    // onClick={createEscrowDeal}
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#445FD2",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    <CircularProgress />
                  </Button>
                ) : StorePaymentStatus?.success === false ? (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#445FD2",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Already Purchased
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      createEscrowDeal();
                      startInterval();
                      CreateWalletTransaction();
                    }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#445FD2",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Buy Now
                  </Button>
                )}

                {/* <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    background: "#fff",
                    width: "60%",
                    height: "50%",
                    mx: "auto",
                    mt: 10,
                    gap: "10px",
                  }}
                >
                  <Box>
                    <iframe src={eaaUrl} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      gap: "10px",
                    }}
                  >
                    <Button variant="contained">Deny</Button>
                    <Button variant="contained">Approve</Button>
                  </Box>
                </Box>
              </Modal> */}
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{
                  width: "400px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Button variant="outlined" sx={btn} onClick={handlePrint}>
                  <Box
                    component={"img"}
                    src={PrintIcon}
                    sx={ButtonIconStyle}
                  ></Box>
                  <Typography sx={btntext}>Print</Typography>
                </Button>
                <Button variant="outlined" sx={btn} onClick={handlePrint}>
                  <Box
                    component={"img"}
                    src={DownloadIcon}
                    sx={ButtonIconStyle}
                  ></Box>
                  <Typography sx={btntext}>Download</Typography>
                </Button>
              </Grid>
            </Grid>
            {/* <Grid container>
            <Grid item xl={7} lg={7} md={7}>
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  width: "100%",
                  mx: "auto",
                  height: "100%",
                  background: "#445FD2",
                  borderRadius: "10px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  mt: 0.5,
                  ml: "20px",
                }}
              >
                Buy Now
              </Button>
            </Grid>
          </Grid> */}
          </Box>
        </Paper>
        <TostMessagesTop PageLocation="Order Summary" />
      </Paper>
    </>
  );
};

export default OrderSummeryDetailsPage;

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const headtext = {
  width: "100%",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "15px",
  color: "#6B7A99",
  opacity: 1,
};

const mainbox = {
  width: "100%",
  height: "48px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const headbox = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const contentbox = {
  width: "100%",
  height: "80%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // gap: "3px",
};

const headbox2 = {
  width: "100%",
  height: "64px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const selername = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  opacity: 0.7,
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const elitem = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const totaltext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const totaltextbox = {
  width: "100%",
  height: "15%",
  borderTop: "1px solid rgba(149, 144, 168, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const elvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const detailtext = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const detailtext2 = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  opacity: 0.4,
};

const totaltextsec = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const btntext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#445FD2",
  textTransform: "none",
};

const btn = {
  width: "40%",
  height: "100%",
  border: "1px solid #445FD2",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const inrvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "10px",
  textAlign: "center",
  color: "#6B7A99",
};

const gridstyle = {
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px 10px 0px 0",
};
