import React, { useState } from 'react';
import Navbar from '../../views/LandingPage/Navbar';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { Box, Grid, Typography, Button, Paper, styled } from '@mui/material';
function About(props) {
  const HandleFlick = () => {
    navigate('/', {
      state: 'ABClick',
    });
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Paper elevation={0}>
      <Box id="hello" sx={{ margin: '50px 5px' }}>
        <Grid
          container
          spacing={4}
          sx={{ padding: '55px 0 0 0' }}
          ref={props.scollRef}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                marginLeft: {
                  xl: '60px',
                  lg: '60px',
                  md: '60px',
                  sm: '10px',
                  xs: '10px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xl: 'flex-start',
                    lg: 'flex-start',
                    md: 'flex-start',
                    sm: 'center',
                    xs: 'center',
                  },
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...headingStyle,
                      fontSize: {
                        xl: '30px',
                        lg: '30px',
                        md: '30px',
                        sm: '25px',
                        xs: '25px',
                      },
                      fontWeight: 600,
                    }}
                  >
                    What is BXI ?
                  </Typography>
                  {/* <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...gradientText,
                      fontSize: {
                        xl: '30px',
                        lg: '30px',
                        md: '30px',
                        sm: '25px',
                        xs: '25px',
                      },
                      fontWeight: 600,
                    }}
                  >
                    BXI ?
                  </Typography> */}
                </Fade>
              </Box>
              <Typography variant="inherit" sx={contentStyleee}>
                <span style={boldTextStyle}>
                  BXI, which stands for Barter Exchange of India,
                </span>{' '}
                is a digital marketplace for businesses in India, where
                companies come together to exchange products using{' '}
                <Link to="/howitworks" style={{ textDecoration: 'none' }}>
                  "Barter coins"
                </Link>
                .
              </Typography>

              <Typography sx={contentStyle}>
                BXI acts as a third-party record keeper of transactions, that
                happens and makes it easy for businesses to exchange things they
                have, for things they need.
              </Typography>
              {/* <Typography sx={contentStyle}>
                BXI is third party record keeper for this transactions and helps
                to facilitate the Barter transactions among the registered
                members on the marketplace.
              </Typography> */}
              <Typography sx={{ ...contentStyle, mb: 1 }}>
                Organizations Pay By Products for what they want in exchange.
              </Typography>
              <Typography sx={{ ...contentStyle, mb: 1 }}>
                BXI is a business community where reputed organizations join to
                exchange their goods and services, making it a lively
                marketplace where businesses find what they're looking for
                without paying in Cash but on Barter!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  maxWidth: {
                    xl: '600px',
                    lg: '500px',
                    md: '500px',
                    sm: '500px',
                    xs: '350px',
                  },
                  minWidth: {
                    xl: '600px',
                    lg: '600px',
                    md: '600px',
                    sm: '350px',
                    xs: '350px',
                  },
                  minHeight: '338px',
                  maxHeight: '338px',
                  height: '100%',
                  // clipPath: "polygon(-100% 0%, 100% -64%, 100% 64%, 58% 100%)",
                  transform: 'rotate(90deg)',
                }}
              >
                <iframe
                  // controls
                  autoPlay
                  controlsList="nodownload"
                  src={
                    'https://www.youtube.com/embed/F0pISDazeck?si=c3W9zHfaCduypKTI'
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                    transform: 'rotate(-90deg)',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.25)',
                    border: 'none',
                  }}
                  allowFullScreen
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Fade top duration={1000}>
          <Typography variant="inherit" component="span" sx={headingStyle}>
            Vision & Mission
          </Typography>
        </Fade>
      </Box>
     
      <Box sx={{ width: '90%', mx: 'auto', mt: 2 }}>
        <Typography sx={contentStyleee}>
          <span style={boldTextStyle}> Vision: </span> To revolutionize the B2B
          landscape by pioneering India's first online barter marketplace,
          facilitating seamless exchanges and creating a vibrant ecosystem where
          businesses trade goods, earn barter coins, and unlock limitless
          possibilities for growth and prosperity.
        </Typography>
      </Box>
      <Box sx={{ width: '90%', mx: 'auto', mt: 2 }}>
        <Typography sx={contentStyleee}>
          <span style={boldTextStyle}> Mission: </span> Empowering MSMEs and
          organizations to thrive through innovative digital barter solutions,
          fostering growth, sustainability, and collaboration in the business
          community
        </Typography>
      </Box>
    </Paper>
  );
}

export default About;

const headingStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '30px',
    sm: '30px',
    md: '30px',
    lg: '30px',
    xl: '30px',
  },
  lineHeight: '65px',
  display: 'flex',
  alignItems: 'center',
  // letterSpacing: "-1.62343px",
  color: '#0D0E0E',
  marginRight: '10px',
  textTransform: 'uppercase',
  textAlign: 'center',
};

const contentStyleee = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '12px' },
  // display: "flex",
  alignItems: 'center',
  color: 'rgba(73, 75, 122, 1)',
  marginBottom: '15px',
};

const boldTextStyle = {
  fontWeight: 'bold',
};

const contentStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '12px' },
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(73, 75, 122, 1)',
  marginBottom: '15px',
  // textTransform: "lowercase",
};
