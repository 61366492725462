import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  Typography,
  Modal,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DownloadIcon from "../../assets/Images/CommonImages/downloadicon.svg";
import PrintIcon from "../../assets/Images/CommonImages/printicon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import axios from "axios";
import TostMessagesTop from "../../Component/Toast";
import socketIO from "socket.io-client";
import { ProductAnalysisUpdate } from "../../redux/action/Products/ProductAnalysis";

import { useCreateDeal, useBuyerUploadPaymentDocs } from "./Hooks";
import PageLoader from "../../components/LoadingButton/PageLoader";
import useGetCompanyFullData from "../../Hooks/CompanyData/useGetCompanyFullData";
import sendEvents from "../../utils/sendEvents.js";
import { useDispatch } from "react-redux";
import ProductOrderSummary from "../OrderDetailsPage/ProductOrderSummary";
import MediaOrderSummary from "../OrderDetailsPage/MediaOrderSummary";
import VoucherOrderSummary from "../OrderDetailsPage/VoucherOrderSummary";
import Voucher_Invoice from "../../utils/Voucher_Invoice.js";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";
import ButtonStyle from "../../components/GlobalStyle/ButtonStyle.js";
import { OrderSummaryText } from "../../components/GlobalStyle/TextStyle.js";
import SellerBuyerMeetModal from "../../components/Modals/SellerBuyerMeetModal.jsx";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";

const ENDPOINT = "https://production-api.unada.in/";

export let socket = socketIO(ENDPOINT, { transports: ["websocket"] }),
  selectedChatCompare;

const OrderSummeryDetailsPage = () => {
  const { id } = useParams();
  const componentRef = useRef();
  const dispatch = useDispatch();

  const [OrderData, setOrderData] = useState({});
  const [PageLoading, setPageLoading] = useState(false);
  const [StorePaymentStatus, setStorePaymentStatus] = useState({});
  const [PaymentUrlLoading, setPaymentUrlLoading] = useState(false);

  const [PaymentResponse, setPaymentResponse] = useState(false);
  const [WalletData, setWalletData] = useState();
  const [CouponData, setCouponData] = useState();

  const [isIntervalRunning, setIsIntervalRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [paymentInitiated, setPaymentInitiated] = useState(false);

  const [storeTDS, setStoreTDS] = useState("");

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let TotalPriceWithoutGST = 0;

  OrderData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    TotalAdditionalCostWtihoutGSTInBXI +=
      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();

  const { data: companyData } = useGetCompanyFullData();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function getOrderDetailsById() {
    setPageLoading(true);
    await axios
      .get(`order/get_order_by_id/${id}`)
      .then((res) => {
        setOrderData(res?.data);
        GetEscrowDeal_Status(res.data?.escrowData);
        setStorePaymentStatus(res?.data?.escrowPayment?.payment_status);
        if (res?.data?.escrowPayment?.payment_status === "1") {
          CreateWalletTransaction();
          handleClickOpen();
          setPaymentResponse(false);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(true);
      });
  }

  // Function to start interval for checking escrow deal status
  const startInterval = () => {
    if (OrderData?.escrowData) {
      setTimeout(() => {
        const interval = setInterval(async () => {
          const status = await GetEscrowDeal_Status(OrderData?.escrowData);
          clearInterval(interval);
        }, 5000);
        setIntervalId(interval);
        setIsIntervalRunning(true);
      }, 40000);
    }
  };

  // Function to stop the interval
  const stopInterval = () => {
    setIsIntervalRunning(false);
    clearInterval(intervalId);
  };

  useEffect(() => {
    GetEscrowDeal_Status(OrderData?.escrowData);
  }, [OrderData?.escrowData]);

  useEffect(() => {
    getOrderDetailsById();
  }, []);

  const {
    mutate: createDealMutate,
    isLoading: createDealLoading,
    data: createDealData,
    error: createDealError,
  } = useCreateDeal();

  const {
    mutate: buyerUploadPaymentDocs,
    isLoading: buyerUploadPaymentDocsLoading,
    error: buyerUploadPaymentDocsError,
  } = useBuyerUploadPaymentDocs();

  useEffect(() => {
    axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setWalletData(res?.data?.body);
      });
  }, []);

  const CouponMatch = WalletData?._instant_balance?.filter(
    (el) => el?._coupon_Id === OrderData?.PurchaseOrderData?.CouponData?._id
  );

  const GetallCoupon = async () => {
    await axios
      .get(`/private_deal/get_coupons`, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        setCouponData(res.data.data);
      })
      .catch((err) => {});
  };

  const CouponActiveMatch = CouponData?.filter(
    (el) =>
      el?._id?.toString() === OrderData?.PurchaseOrderData?.CouponData?._id
  );

  useEffect(() => {
    GetallCoupon();
  }, []);

  const { data: IAMUserData } = useGetAuthUser();

  const createEscrowDeal = async () => {
    await startInterval();
    setPaymentResponse(true);
    setPaymentInitiated(true);
    if (OrderData?.escrowData) {
      stopInterval();
    }
    if (OrderData?.IsVoucher) {
      if (
        OrderData?.PurchaseOrderData?.CouponData?._id &&
        CouponMatch?.at(0)?._is_used
      ) {
        GlobalToast("Coupon Is Already Used", "error");
      } else if (CouponActiveMatch?.at(0)?.IsActive === false) {
        GlobalToast("Coupon Is Not Active", "error");
      } else if (
        OrderData?.PurchaseOrderData?.CouponData?._id &&
        !CouponMatch?.at(0)?._is_used
      ) {
        sendEvents("Click on buy now");
        if (OrderData?.INRDetails?.TotalInrToPay < 1) {
          GlobalToast("Less than 1 INR cannot be paid", "error");
          return;
        }
        setPaymentUrlLoading(true);
        let S3data = await Voucher_Invoice(componentRef.current);
        await axios
          .put(`order/update_order_with_any_data/${id}`, {
            InvoiceUrl: S3data?.data?.file,
          })
          .then((res) => {});
        await createDealMutate(
          {
            OrderId: OrderData?._id,
            amount: 3,
          },
          {
            onSuccess: (data) => {
              GetPaymentUrl(data?.data);
            },
            onError: (err) => {},
          }
        );
      } else {
        if (!WalletData?.balance) {
          GlobalToast("Your Wallet balance is low", "error");
        } else {
          if (
            IAMUserData?.data?.superAdmin &&
            WalletData?.balance <
              Number(TotalPriceWithoutGST) +
                Number(TotalAdditionalCostWtihoutGSTInBXI)
          ) {
            GlobalToast("Your Wallet balance is low", "error");
          } else if (
            TotalPriceWithoutGST > IAMUserData?.data?.tokenlimit &&
            !IAMUserData?.data?.superAdmin
          ) {
            GlobalToast(
              "You do not have permission to Burn that much token",
              "error"
            );
          } else {
            startInterval();
            sendEvents("Click on buy now");
            if (OrderData?.INRDetails?.TotalInrToPay < 1) {
              GlobalToast("Less than 1 INR cannot be paid", "error");
              return;
            }
            setPaymentUrlLoading(true);
            let S3data = await Voucher_Invoice(componentRef.current);
            await axios
              .put(`order/update_order_with_any_data/${id}`, {
                InvoiceUrl: S3data?.data?.file,
              })
              .then((res) => {});
            await createDealMutate(
              {
                OrderId: OrderData?._id,
                amount: 3,
              },
              {
                onSuccess: (data) => {
                  GetPaymentUrl(data?.data);
                },
                onError: (err) => {},
              }
            );
          }
        }
      }
    } else {
      if (
        OrderData?.PurchaseOrderData?.CouponData?._id &&
        CouponMatch?.at(0)?._is_used
      ) {
        GlobalToast("Coupon Is Already Used", "error");
      } else if (CouponActiveMatch?.at(0)?.IsActive === false) {
        GlobalToast("Coupon Is Not Active", "error");
      } else {
        startInterval();
        sendEvents("Click on buy now");
        if (OrderData?.INRDetails?.TotalInrToPay < 1) {
          GlobalToast("Less than 1 INR cannot be paid", "error");
          return;
        }
        setPaymentUrlLoading(true);
        let S3data = await Voucher_Invoice(componentRef.current);
        await axios
          .put(`order/update_order_with_any_data/${id}`, {
            InvoiceUrl: S3data?.data?.file,
          })
          .then((res) => {});

        await createDealMutate(
          {
            OrderId: OrderData?._id,
            amount: 3,
          },
          {
            onSuccess: (data) => {
              GetPaymentUrl(data?.data);
            },
            onError: (err) => {},
          }
        );
      }
    }
  };

  const GetPaymentUrl = async (props) => {
    buyerUploadPaymentDocs(
      {
        OrderId: props?._id,
        deal_Id: props?.escrowData?.dealId || props?.escrowData?.deal_id,
      },
      {
        onSuccess: (data) => {
          if (!data?.data?.escrowData?.payment_url) {
            return alert(data?.data);
          }
          if (data?.data?.escrowData?.payment_url) {
            handleClose();
            window.open(data?.data?.escrowData?.payment_url, "_blank");
          }
        },
        onError: (err) => {
          return alert(err?.response?.data?.message);
        },
      }
    );
  };

  async function GetEscrowDeal_Status(props) {
    await axios
      .post(`escrow/escrow_deal_status/${props?.order_id}`)
      .then((res) => {
        setTimeout(() => {
          if (res?.data?.data?.success === "true") {
            CreateWalletTransaction();
            handleClickOpen();
            setPaymentResponse(false);

            stopInterval();
            let productIds = OrderData?.PurchaseOrderData?.ProductData;
            if (productIds?.length > 0) {
              productIds.forEach((productId) => {
                dispatch(
                  ProductAnalysisUpdate(
                    productId?.ProductId,
                    "",
                    "",
                    "ProductPurchaseCount"
                  )
                );
              });
            }
          }
        }, 2000);
      })
      .catch((err) => {
        return err;
      });
  }

  const CheckAndSendVoucherCodes = () => {
    if (OrderData?.IsVoucher && OrderData?._id) {
      if (
        !OrderData?.VoucherCodeSentToBuyer ||
        !OrderData?.VoucherCodeSentToSeller
      ) {
        axios
          .put(`voucher/confirm_voucher_order/${OrderData?._id}`)
          .then((res) => {
            stopInterval();
            return GlobalToast(
              "Voucher codes sent to buyer and seller",
              "success"
            );
          });
      }
    }
  };

  async function CreateWalletTransaction() {
    try {
      await axios
        .post("wallet/create_transaction", {
          walletAddressFrom:
            OrderData?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyId,
          walletAddressTo:
            OrderData?.PurchaseOrderData?.SellerDetails?.SellerCompanyId,
          amount: OrderData?.IsVoucher
            ? Number(OrderData?.BXITokenDetails?.TotalBXiCoins) +
              Number(OrderData?.BXITokenDetails?.TotalAdCostInBxiWithoutGST) -
              Number(OrderData?.INRDetails?.TDS).toFixed(2)
            : Number(OrderData?.PITotals?.TotalCoinsWithAdditionalCost) -
              Number(OrderData?.INRDetails?.TDS).toFixed(2),
          OrderId: OrderData?.OrderId,
          order_id: OrderData?._id,
        })
        .then(async (res) => {
          if (res?.data?.body) {
            if (res.data?.body?._balance) {
              try {
                await axios.put(`order/update_order_with_any_data/${id}`, {
                  WalletData: res?.data?.body,
                });
              } catch (error) {
                return error;
              }
            }
            try {
              setTimeout(() => {
                axios.put(`order/update_order/${id}`, {
                  data: "Success",
                });
              }, 2000);
            } catch (error) {
              return error;
            }
            if (OrderData?.IsVoucher) {
              CheckAndSendVoucherCodes();
            }
          }
        });

      if (OrderData?.PurchaseOrderData?.CouponData?._id) {
        try {
          await axios.put(
            `private_deal/update_use_coupon/${OrderData?.PurchaseOrderData?.CouponData?._id}`,
            { OrderId: OrderData?.OrderId }
          );
        } catch (error) {
          return error;
        }
      }
    } catch (err) {
      return err;
    }
  }

  const handleClose = () => {
    setPaymentUrlLoading(false);
    setOpen(false);
  };

  if (PageLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
        <BreadCrumbHeader
          MainText="Order Summary"
          LinkText1="{splitedurl[1]}"
          LinkText2="{splitedurl[2]}"
          link1="Link1"
          link2="link2"
          showbreadcrumb={true}
        />

        <Box ref={componentRef}>
          {OrderData?.IsVoucher === false && OrderData?.IsMedia === false && (
            <ProductOrderSummary
              ProductSummaryData={OrderData}
              CompanyData={companyData}
              DealLoading={createDealLoading}
              TDS={OrderData?.INRDetails?.TDS_PERCENT ?? 0.1}
            />
          )}

          {OrderData?.IsVoucher === false && OrderData?.IsMedia === true && (
            <MediaOrderSummary
              MediaSummaryData={OrderData}
              CompanyData={companyData}
              DealLoading={createDealLoading}
              TDS={OrderData?.INRDetails?.TDS_PERCENT ?? 0.1}
            />
          )}

          {OrderData?.IsVoucher === true && OrderData?.IsMedia === false && (
            <VoucherOrderSummary
              VoucherSummaryData={OrderData}
              CompanyData={companyData}
              DealLoading={createDealLoading}
              TDS={OrderData?.INRDetails?.TDS_PERCENT ?? 0.1}
            />
          )}
        </Box>
        <Grid
          container
          gap={8}
          sx={{
            py: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xl={7}
            lg={7}
            md={7}
            sm={12}
            xs={12}
            sx={{
              width: "400px",
              height: "50px",
            }}
          >
            {createDealLoading || paymentInitiated ? (
              <Button variant="contained" sx={ButtonStyle.LoadingButtonStyle}>
                <CircularProgress />
              </Button>
            ) : StorePaymentStatus === "1" ? (
              <Button
                variant="contained"
                sx={ButtonStyle.ViewOrderDetails}
                onClick={() => navigate(`/home/myorderlist`)}
              >
                View Order Details
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  createEscrowDeal();
                }}
                sx={ButtonStyle.BuyNowButtonStyle}
              >
                Buy Now
              </Button>
            )}
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              width: "400px",
              height: "50px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={btn}
              onClick={() => {
                handlePrint();
                sendEvents("Click on print");
              }}
            >
              <Box component={"img"} src={PrintIcon} sx={ButtonIconStyle}></Box>
              <Typography sx={OrderSummaryText.PrintButton}>Print</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={btn}
              onClick={() => {
                handlePrint();
                sendEvents("Click on download");
              }}
            >
              <Box
                component={"img"}
                src={DownloadIcon}
                sx={ButtonIconStyle}
              ></Box>
              <Typography sx={OrderSummaryText.PrintButton}>
                Download
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Modal
          open={PaymentUrlLoading}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              sx={OrderSummaryText.PaymentLinkText}
            >
              Please wait while we are creating your payment link
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={OrderSummaryText.DoNotRefreshText}
            >
              Do not refresh the page
            </Typography>
            <CircularProgress sx={{ mt: "20px" }} />
          </Box>
        </Modal>
        {open && (
          <SellerBuyerMeetModal
            open={open}
            OrderData={OrderData}
            PageLocation="Order Summary"
          />
        )}

        <TostMessagesTop PageLocation="Order Summary" />
      </Paper>
    </>
  );
};

export default OrderSummeryDetailsPage;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "grid",
  placeItems: "center",
  borderRadius: "10px",
};

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const btn = {
  width: "40%",
  height: "100%",
  border: "1px solid #445FD2",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};
