import { createSlice } from '@reduxjs/toolkit';

export const AddContactNoSlice = createSlice({
    name: 'AddContact',
    initialState: {
        Contact: {}
    },
    reducers: {
        ADD_CONTACT_REQUEST: (state) => {
            state.loading = true;
            state.Contact = {};
        },
        ADD_CONTACT_SUCCESS: (state, action) => {
            state.loading = false;
            state.Contact = action.payload;
        },
        ADD_CONTACT_FAIL: (state, action) => {
            state.loading = false;
            state.Contact = action.payload;
        }
    }
});

export const { ADD_CONTACT_REQUEST, ADD_CONTACT_SUCCESS, ADD_CONTACT_FAIL } = AddContactNoSlice.actions;

export default AddContactNoSlice.reducer;


