import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import stepstobulkupload from "../../../../assets/Bulk_upload_steps.jpg";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  Container,
  FormControl,
} from "@mui/material";
import GoLeft from "../../../../assets/Images/CommonImages/GoLeft.png";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser.js";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { useSelector, useDispatch } from "react-redux";
import DotLoader from "../../../../components/LoadingButton/DotLoader.js";
import GlobalToast from "../../../../components/Toasts/GlobalToast.jsx";

const useStyles = makeStyles({
  tableRow: {
    border: "1px solid rgba(224, 224, 224, 1)", // Change color as needed
  },
});

const MediaOnline = [
  {
    key: 1,
    value: "Online Media Output File - Airport Screens",
    name: "Airport Screens",
  },
  {
    key: 2,
    value: "Online Media Output File - Multiplex Ads",
    name: "Multiplex Ads",
  },
  {
    key: 3,
    value: "Online Media Output File",
    name: "Cafe Digital Screens",
  },
  {
    key: 4,
    value: "Online Media Output File",
    name: "Digital Ads",
  },
  {
    key: 5,
    value: "Online Media Output File",
    name: "Digital Marketing",
  },
  {
    key: 6,
    value: "Online Media Output File",
    name: "Display Video",
  },
  {
    key: 7,
    value: "Online Media Output File",
    name: "DOOH (Digital Out-of-Home)",
  },
  {
    key: 8,
    value: "Online Media Output File",
    name: "Gyms Digital Screens",
  },
  {
    key: 9,
    value: "Online Media Output File",
    name: "In-app Advertisement",
  },
  {
    key: 10,
    value: "Online Media Output File",
    name: "Influencer Marketing",
  },
  {
    key: 11,
    value: "Online Media Output File",
    name: "Mobile",
  },
  {
    key: 12,
    value: "Online Media Output File",
    name: "Others",
  },
  {
    key: 13,
    value: "Online Media Output File",
    name: "Radio",
  },
  {
    key: 14,
    value: "Online Media Output File",
    name: "Railway Digital Screens",
  },
  {
    key: 15,
    value: "Online Media Output File",
    name: "Single Screen",
  },
  {
    key: 16,
    value: "Online Media Output File",
    name: "Social Media Advertisement",
  },
  {
    key: 17,
    value: "Online Media Output File",
    name: "Web Banners",
  },
];

const MediaOnlineBulkUploadPage = () => {
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offlineCategory, setOfflineCategory] = useState("");
  const [onlineCategory, setOnlineCategory] = useState("");

  const [storeCompanyType, setStoreCompanyType] = useState("");
  const [storeStoredFiles, setStoreStoredFiles] = useState([]);

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setUpload(data);
    };

    reader.onerror = (err) => {
      console.error("Error reading the file:", err);
    };
    reader?.readAsArrayBuffer(file);
    uploadExcelFile(file);
  };

  const uploadExcelFile = async (myForm) => {
    if (onlineCategory === "" || !onlineCategory) {
      alert("Please select an online category");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", myForm);
      formData.append("data", onlineCategory);
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_upload",
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      if (data?.success === true) {
        toast.success("File uploaded successfully", {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setData(data?.success);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const handleMediaOnlineCategoryChange = (event) => {
    setOnlineCategory(event.target.value);
  };

  const findAllWebHookReponsesAndFiles = async () => {
    try {
      await axios
        .get(`publiq_bulk_upload/findAll_BulkUploadResponseAnd_NonStoreFiles`)
        .then((response) => {
          setStoreStoredFiles(response.data?.data);
          console.log("shdgfygsudgfugsudgf", response?.data?.data);
        })
        .catch((error) => {
          console.error("Error fetching webhooks and files:", error.message);
        });
    } catch (error) {
      console;
    }
  };

  const confirmedUploadTheFile = async (Unique_id) => {
    try {
      await axios.post(
        `publiq_bulk_upload/upload_bulkUpload_files_media`,
        {
          data: Unique_id,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      GlobalToast(error.response.data.message, "error");
      console.error("Error uploading the file:", error);
    }
  };

  useEffect(() => {
    const companyType = localStorage.getItem("companyType");
    setStoreCompanyType(companyType);
    findAllWebHookReponsesAndFiles();
  }, []);

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />

      <Paper
        sx={{
          width: "100%",
          height: "70vh",
          position: "relative",
          display: "flex",
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: "40%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data ? (
            <Container
              sx={{
                display: "grid",
                width: "100%",
                maxWidth: "100%",
                textAlign: "center",
                gap: "15px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#444444",
                  fontFamily: "Poppins",
                  marginTop: "24px",
                  fontFamily: "Poppins",
                  display: "grid",
                  gap: "15px",
                }}
              >
                Please wait for some times while we go through your uploaded
                file. <DotLoader />
              </Typography>
            </Container>
          ) : (
            <Container
              sx={{
                display: "grid",
                width: "700px",
                maxWidth: "700px",
                textAlign: "center",
                gap: "15px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                Media Online
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <Button
                    variant="contained"
                    sx={{
                      height: "40px",
                      fontSize: "14px",
                      textTransform: "none",
                      minWidth: "200px",
                      width: "auto",
                      background: "#c64091",
                      "&:hover": {
                        background: "#fff",
                        color: "#c64091",
                        cursor: "pointer",
                        border: "1px solid #c64091",
                      },
                    }}
                  >
                    Download Excel file
                  </Button> */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#444444",
                        fontFamily: "Poppins",
                      }}
                    >
                      Select Category
                    </Typography>
                    <FormControl
                      fullWidth
                      margin="normal"
                      sx={{ maxWidth: "400px" }}
                    >
                      <select
                        labelId="category-label"
                        value={onlineCategory}
                        label="Category"
                        defaultValue={""}
                        onChange={handleMediaOnlineCategoryChange}
                        style={{
                          border: "1px solid #c64091",
                          height: "40px",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "5px",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          lineHeight: "1.5",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <option
                          value={""}
                          disabled={true}
                          style={{
                            padding: "10px",
                            height: "35px",
                            width: "100%",
                          }}
                        >
                          <Typography variant="h6">
                            {`<-- Select -->`}
                          </Typography>
                        </option>
                        {MediaOnline?.map((res, idx) => {
                          return (
                            <option
                              key={idx}
                              value={res?.value}
                              style={{
                                padding: "10px",
                                height: "35px",
                                width: "100%",
                              }}
                            >
                              <Typography variant="h6">{res?.name}</Typography>
                            </option>
                          );
                        })}
                      </select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#444444",
                        fontFamily: "Poppins",
                      }}
                    >
                      Choose you Excel File{" "}
                    </Typography>
                    <input
                      type="file"
                      ref={inputFile}
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="contained"
                      onClick={onButtonClick}
                      sx={{
                        height: "40px",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "400px",
                        background: "#c64091",
                        "&:hover": {
                          background: "#fff",
                          color: "#c64091",
                          cursor: "pointer",
                          border: "1px solid #c64091",
                        },
                      }}
                    >
                      Add file <FileUploadIcon fontSize="large" />
                    </Button>
                  </Box>
                </Box>
              </Box>

              {/* <Button
                variant="contained"
                color="secondary"
                onClick={() => console.log("Create Template")}
                sx={{
                  marginTop: "10px",
                  height: "40px",
                  fontSize: "14px",
                  background: "#c64091",
                  textTransform: "none",
                  "&:hover": {
                    background: "#fff",
                    color: "#c64091",
                    cursor: "pointer",
                    border: "1px solid #c64091",
                  },
                }}
              >
                Create Template
              </Button> */}
            </Container>
          )}
        </Box>
        <Box
          sx={{
            borderLeft: "1px solid #c64091",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60%",
          }}
        >
          <Box
            sx={{
              mx: "auto",
              width: "80%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              Recent Uploaded Media Files
            </Typography>
            <Box
              sx={{
                maxHeight: "300px",
                overflowY: "scroll",
                my: 2,
              }}
            >
              {storeStoredFiles?.map((res, idx) => {
                if (res?.data?.Link) {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "55%",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "12px",
                            wordBreak: "break-all",
                          }}
                        >
                          {res?.data?.Link}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "40%",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            confirmedUploadTheFile(res?.data?.Unique_id)
                          }
                          sx={{
                            height: "40px",
                            fontSize: "14px",
                            textTransform: "none",
                            width: "150px",
                            background: "#c64091",
                            "&:hover": {
                              background: "#fff",
                              color: "#c64091",
                              cursor: "pointer",
                              border: "1px solid #c64091",
                            },
                          }}
                        >
                          Upload Products
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          target="_blank"
                          href={res?.data?.Link}
                          sx={{
                            height: "40px",
                            fontSize: "14px",
                            textTransform: "none",
                            width: "150px",
                            background: "#fff",
                            color: "#c64091",
                            border: "1px solid #c64091",
                            "&:hover": {
                              background: "#c64091",
                              color: "#fff",
                              cursor: "pointer",
                              border: "1px solid #c64091",
                            },
                          }}
                        >
                          Download File
                        </Button>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default MediaOnlineBulkUploadPage;
