import { Box, Typography } from "@mui/material";
import React from "react";

const ProductDescriptions = (props) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 0.5,
        }}
      >
        <Typography
          sx={{
            ...tabText,
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {props?.data && props?.data?.ProductSubtittle}

          {props?.data?.ProductSubtitle}
        </Typography>
        <Typography sx={tabSubText}>
          {props?.data && props?.data?.ProductDescription}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default ProductDescriptions;

const tabText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "1.8rem",
    sm: "1.6rem",
    xs: "1.6rem",
  },
  width: "95%",
  color: "#6B7A99",
};

const tabSubText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  width: "95%",
  textAlign: "justify",
  color: "#6B7A99",
};
