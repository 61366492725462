import axios from "axios";
import {
    SENDMESSAGES_ONWHATSAPP_REQUEST,
    SENDMESSAGES_ONWHATSAPP_SUCCESS,
    SENDMESSAGES_ONWHATSAPP_FAIL
} from "../../reduser/WhatApp/SendMessages";

export const SendMessageOnWhatsApp = (
  phoneNumber,
  template_name,
  parameterName,
  parameterValue,
  parameterName1,
  parameterValue1,
  parameterName2,
  parameterValue2
) => async (dispatch) => {
  try {
    dispatch({ type: SENDMESSAGES_ONWHATSAPP_REQUEST });

    // Create the API link with query parameters
    let link = `WhatApp/sendTempletMessageOnWhatApp?phoneNumber=${phoneNumber}&template_name=${template_name}`;

    // Set the headers for the API request
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    // Make the API request using axios.post
    const { data } = await axios.post(
      link,
      {
        parameterName,
        parameterValue,
        parameterName1,
        parameterValue1,
        parameterName2,
        parameterValue2
      },
      {
        withCredentials: true,
        headers: config.headers // Pass the headers as a separate object for axios
      }
    );

    // If the request is successful, dispatch the success action with the data payload
    dispatch({
      type: SENDMESSAGES_ONWHATSAPP_SUCCESS,
      payload: data
    });
  } catch (error) {
    // If there's an error, dispatch the fail action with the error message payload
    dispatch({
      type: SENDMESSAGES_ONWHATSAPP_FAIL,
      payload: error.response.data.message
    });
  }
};
