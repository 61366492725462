import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ExploreImagePoster = () => {
  const [BannerImage, SetBannerImage] = useState();
  const [buyNowButton, setBuyNowButton] = useState([]);
  const navigate = useNavigate();

  const GetBanner = async () => {
    try {
      const response = await axios.get('/AdminCompanyDetails/GetBannerImage', {
        withCredentials: true,
      });
      SetBannerImage(response?.data?.ImageUrl?.at(0)?.imageUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBuyNow = async () => {
    try {
      const response = await axios.get(
        '/AdminCompanyDetails/getProductImageInBanner',
        {
          withCredentials: true,
        }
      );
      const buyNowData = response?.data.image[0];
      setBuyNowButton(buyNowData);
    } catch (error) {}
  };

  useEffect(() => {
    GetBanner();
    fetchBuyNow();
  }, []);

  return (
    <Paper sx={PosterStyle} elevation={0}>
      <Box
        component={'img'}
        src={BannerImage}
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: '12px',
        }}
      />
    </Paper>
  );
};

export default ExploreImagePoster;

const PosterStyle = {
  width: '95%',
  mx: 'auto',
  height: {
    xl: '270px',
    lg: '270px',
    md: '260px',
    sm: '140px',
    xs: '120px',
  },
  boxShadow: '0px',
  // backgroundRepeat: "no-repeat",
  // backgroundPosition: "center",
  // backgroundSize: "cover",
  borderRadius: '12px',
  position: 'relative',
  my: 3,
  zIndex: 0,
};

const OfferboxStyle = {
  width: 'auto',
  minWidth: {
    xl: '462px',
    lg: '462px',
    md: '450px',
    sm: '400px',
    xs: '260px',
  },
  maxWidth: '475px',
  height: {
    xl: '142px',
    lg: '140px',
    md: '130px',
    sm: '120px',
    xs: '100px',
  },
  background: 'rgba(24, 24, 24, 0.1)',
  backdropFilter: 'blur(2px)',
  borderRadius: '25px',
  position: 'absolute',
  // bgcolor: "red",
  top: { xl: '32%', lg: '32%', md: '32%', sm: '15%', xs: '15%' },
  left: { xl: '8%', lg: '8%', md: '8%', sm: '5%', xs: '4%' },
};

const OfferBoxText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: { xl: '32px', lg: '30px', md: '28px', sm: '28px', xs: '26px' },
  lineHeight: '40px',
  /* identical to box height */
  color: '#FFFFFF',
};

const PosterOfferText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: { xl: '62px', lg: '60px', md: '55px', sm: '50px', xs: '40px' },
  lineHeight: '96px',
  color: '#FFFFFF',
  WebkitTextStroke: '2px #776B47',
};

const ButtonStyle = {
  width: {
    xl: '200px',
    lg: '200px',
    md: '200px',
    sm: '210px',
    xs: '180px',
  },
  height: '50px',
  background: '#fff',
  borderRadius: '15px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xl: '20px',
    lg: '18px',
    md: '18px',
    sm: '18px',
    xs: '16px',
  },
  '&:hover': {
    background: '#C3CAD9',
  },
  lineHeight: '36px',
  color: '#303030',
  bottom: {
    xl: '40%',
    lg: '40%',
    md: '40%',
    sm: '50%',
    xs: '50%',
  },
  left: 'auto',
  left: { xl: '75%', lg: '65%', md: '65%', sm: '50%', xs: '35%' },
  zIndex: 0,
  textTransform: 'none',
};
