import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FeatureName from "../../../components/FeatureName";

const KeyFeatures = (props) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography
        sx={{
          ...pack,
          fontSize: "22px",
          color: "#156DB6",
        }}
      >
        Key Features
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          container
          mt={0.5}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {props?.data?.map((res) => {
            return (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "start",
                    flexDirection: "row",
                    gap: "100px",
                    mt: 1.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                      width: "100%",
                    }}
                  >
                    <FeatureName name={res?.name} />
                    <Box
                      sx={{
                        width: "80%",
                        maxWidth: "825px",
                        height: "auto",
                        wordWrap: "break-word",
                      }}
                    >
                      <Typography
                        sx={{
                          ...packHead,
                          color: "grey",
                        }}
                      >
                        {res.name}
                      </Typography>
                      <Typography
                        sx={{
                          ...packVal,
                          fontSize: "1.5rem",
                        }}
                      >
                        {res.description}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default KeyFeatures;

const pack = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  color: "#6B7A99",
};

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#ADB8CC",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#6B7A99",
};
