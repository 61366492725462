//  gtag events
const sendEvent = (name) => {
  try {
    window.gtag("event", name);
    window.fbq("event", name);
  } catch (err) {}
};

export default sendEvent;

//  gtag pageview
