import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  BottomNavigation,
  Chip,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import stackofcoins from "../../../../assets/Stack of Coins.svg";
import RemoveIcon from "../../../../assets/Images/CommonImages/RemoveIcon.svg";
import EditIcon from "../../../../assets/Images/CommonImages/EditIcon.svg";
import { useUpdateProductQuery } from "./ProductHooksQuery";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import SizeChartTemplate from "../../Common/SizeChartTemplate.jsx";
import { useFieldArray } from "react-hook-form";
import axios from "axios";
import bxitoken from "../../../../assets/Images/CommonImages/BXIToken.svg";
import ToolTip from "../../../../components/ToolTip";

import OthercostsTemplate from "../../../../components/common/voucherTemplates/OthercostsTemplate";
import { TagName } from "../../../../components/Voucher/VoucherNameConstants.js";

const OthercostFieldsarray = [
  "Applicable On",
  "Other cost ",
  "HSN",
  "GST",
  "Reason Of Cost",
];
const RestaurantQSRGeneralInformation = () => {
  const [nameDuplicate, setNameDuplicate] = useState(false);
  const [editId, SetEditId] = useState(null);
  const navigateData = useLocation();
  const ProductId = useParams().id;
  const navigate = useNavigate();
  const [size, setSize] = useState("Length");
  const [currency, setCurrency] = useState({
    currencyType: "",
    amount: "",
    reasonOfCost: "",
  });

  const [OthercostEditId, SetOthercostEditId] = useState(null);
  const [HSNStore, setHSNStore] = useState();
  const [costsArr, setCostsArr] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [paythru, setPaythru] = useState({
    bxitokens: "",
    inr: "",
  });
  const [traits, setTraits] = useState([]);
  const [addItemClicked, setAddItemClicked] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [AllFeature, setAllFeature] = useState([]);
  const [ProductData, setProductData] = useState("");

  const tableRowFields =
    localStorage.getItem("digitalData") === "Offer Specific"
      ? [
          { label: "Price/Voucher", value: "PricePerUnit" },
          { label: "Total QTY", value: "TotalAvailableQty" },
          { label: "HSN", value: "HSN" },
          { label: "GST", value: "GST" },
          { label: "Min", value: "MinOrderQuantity" },
          { label: "Max", value: "MaxOrderQuantity" },
          { label: "Total Uploaded Value", value: "TotalValueUploaded" },
          { label: "Offering Type", value: "OfferingType" },
          { label: "Validity", value: "validityOfVoucherValue" },
        ]
      : [
          { label: "Price/Voucher", value: "PricePerUnit" },
          { label: "Total QTY", value: "TotalAvailableQty" },
          { label: "HSN", value: "HSN" },
          { label: "GST", value: "GST" },
          { label: "Min", value: "MinOrderQuantity" },
          { label: "Max", value: "MaxOrderQuantity" },
          { label: "Total Uploaded Value", value: "TotalValueUploaded" },
          { label: "Validity", value: "validityOfVoucherValue" },
        ];

  const [currentTag, setCurrentTag] = useState("");
  const [tags, setTags] = useState([]);

  const handleAddTag = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      event.preventDefault();
      if (tags?.includes(event.target.value)) {
        return;
      } else {
        setTags([...tags, event.target.value]);
        event.target.value = "";
        setCurrentTag((pre) => "");
      }
    }
  };

  const handleAddButtonClick = (event) => {
    if (!currentTag) {
      return;
    } else {
      if (tags?.includes(currentTag)) {
        event.target.value = "";
        return;
      } else {
        setTags([...tags, currentTag]);
        setCurrentTag((pre) => "");
      }
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const FetchProduct = async () => {
    await axios
      .get("/product/get_product_byId/" + ProductId)
      .then((response) => {
        setProductData(response?.data);
        if (response?.data?.Tags) {
          setTags(response.data.Tags);
        }
        if (response?.data?.ProductsVariantions?.length > 0) {
          append(response?.data?.ProductsVariantions);
          OthercostAppend(response?.data?.OtherCost);
          setItems(response?.data?.ProductFeatures);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    FetchProduct();
  }, []);

  async function FetchAddedProduct() {
    await axios
      .get(`product/get_product_byId/${ProductId}`, {
        withCredentials: true,
      })
      .then((res) => {
        fetchHsnCode(res.data?.ProductSubCategory);
        return res.data;
      });
  }
  async function fetchHsnCode(props) {
    await axios
      .post(
        "hsn/Get_HSNCode",
        { SubCatId: props },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res?.data && res.data != "No Data Found") {
          setHSNStore(res.data);
        }
      });
  }

  useEffect(() => {
    FetchAddedProduct();
  }, []);

  let FeatureArray = [
    "Cuisine",
    "Recommendation",
    "Budget-friendly",
    "Mid-range",
    "High-end",
    "Luxury",
    "Ratings",
    "Distance",
    "Buffet",
    "Dine In",
    "Take Away",
    "Appetizers",
    "Entrees",
    "Desserts",
    "Nutrition",
    "Dietary Restrictions",
    "Online ordering",
    "Delivery options",
    "Music",
    "Live Band",
    "Games",
    "High-end",
    "Unique",
    "Dining experiences",
    "Contactless",
    "Ordering",
    "Payment options.",
    "Drive-thru",
    "Pickup options",
    "Mobile ordering",
    "Speedy",
    "Efficient",
    "Service",
    "Customization",
    "Build-your-own",
    "Options",
    "Sustainable",
    "Eco-friendly",
    "Vegan",
    "Savory",
    "Zesty",
    "Theme",
    "Type",
    "Foodgasm",
    "Chef Special",
    "Popularity",
    "Best Rated",
    "Love About Us",
    "Best Known",
    "We are",
    "Dish Out",
    "Taste",
    "Customer Delight",
    "Spread",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios
          .get(`hotelfeature/Get_hotel_feature`, {
            withCredentials: true,
          })
          .then((res) => {
            fetchHsnCode(res.data?.ProductSubCategory);
            return res.data;
          });
        const jsonData = await response;
        setAllFeature(jsonData);
      } catch (error) {}
    };

    fetchData();
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        size: z.string(),
        fields: z.any(),

        othercost: z.any(),
        selectbestfeature: z.string().min(1),
        featuredescription: z.string().min(1),

        modelname: z.string().min(1),
        additionalFeatures: z.object({
          selectbestfeature: z.string().min(1),
          featuredescription: z.string().min(1),
        }),
        otherCost: z.any(),
      })
    ),
  });
  let invalidFeature = "";
  const { fields, append, prepend, remove, swap, move, insert, update } =
    useFieldArray({
      control,
      name: "ProductsVariantions",
    });
  const {
    fields: OthercostFields,
    append: OthercostAppend,
    remove: OthercostRemove,
    update: OthercostUpdate,
  } = useFieldArray({
    control,
    name: "Othercost",
  });
  const { mutate: updateProduct } = useUpdateProductQuery();
  function handleConsole() {
    setNextClicked(true);
    setAddItemClicked(true);

    const ProductUpdatedata = {
      id: ProductId,
      ProductsVariantions: getValues()?.ProductsVariantions,
      OtherCost: OthercostFields,
      ProductFeatures: items,
      ProductPickupLocation:
        getValues()?.packagerelateddates?.productpickuplocation,
      PickupLocationPinCode:
        getValues()?.packagerelateddates?.pickuplocationpincode,
      ManufacturingDate: getValues()?.packagerelateddates?.manufacturingdate,
      ExpiryDate: getValues()?.packagerelateddates?.expirydate,
      ProductDetails: getValues()?.productdetails,
      LocationDetails: getValues()?.locationdetails,
      ProductUploadStatus: "technicalinformation",
      Tags: tags,
    };

    invalidFeature = false;
    updateProduct(ProductUpdatedata, {
      onSuccess: (response) => {
        if (response.status === 200) {
          navigate(`/home/qsrVoucher/qsrtechinfo/${id}`);
        }
      },
      onError: (error) => {},
    });
  }

  useEffect(() => {
    setValue("traits", fields);
    setValue("othercost", OthercostFields);
  }, [fields, OthercostFields]);
  const { id } = useParams();

  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (items.length >= 5 && fields.length > 0 && tags.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [items, fields, tags]);

  const handleItemAdd = (e) => {
    setAddItemClicked(true);
    e.preventDefault();
    if (name === "" || description === "") {
      invalidFeature = true;
      return;
    } else if (description.length > 75) {
      invalidFeature = true;
      return;
    } else {
      invalidFeature = false;
      const newItem = { name, description };
      if (name.trim() || description.trim() !== "") {
        if (items.length === 0) {
          setItems([...items, newItem]);
        } else if (items.length > 0 && newItem) {
          if (name.toLowerCase() != "other" && name.toLowerCase() != "others") {
            const isNameDuplicate = items.every((item) => item.name !== name);
            if (isNameDuplicate) {
              setItems([...items, newItem]);
              setNameDuplicate(false);
            } else {
              setNameDuplicate(true);
            }
          } else {
            setItems([...items, newItem]);
            setNameDuplicate(false);
          }
        }
      }
    }
    setName("");
    setDescription("");
  };
  const handleDelete = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };
  const [additionalData, setAdditionalData] = useState([]);
  const [CostPrice, setCostPrice] = useState("");
  const [ReasonOfCost, setReasonOfCost] = useState("");

  useEffect(() => {
    setAdditionalData([...additionalData, { CostPrice, ReasonOfCost }]);
  }, [CostPrice, ReasonOfCost]);

  const updateProductTotextilestatus = handleSubmit((data) => {
    updateProduct(data, {
      onSuccess: (response) => {},
    });
  });

  return (
    <>
      <form onSubmit={updateProductTotextilestatus}>
        <Box
          sx={{
            width: "650px",
            boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
            bgcolor: "transparent",
            mx: "auto",
            maxWidth: "716px",
            bgcolor: "#FAFBFD",
            height: "100%",
            overflowY: "auto",
            px: 4,
            py: 3,
          }}
        >
          <Box
            sx={{
              p: 1,
            }}
          >
            <Box
              sx={{
                backgroundColor: "transparent",
                width: "100%",
                mx: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: {
                    xs: "18px",
                    sm: "16px",
                    md: "16px",
                    lg: "14px",
                    xl: "14px",
                  },
                  color: "#6B7A99",
                }}
              >
                Voucher Information
              </Typography>
              <ToolTip
                info={
                  "Voucher Information encompasses essential details and specifications about a specific product/vouchers, including its name, description, features, pricing, and other relevant data, facilitating informed purchasing decisions for potential buyers."
                }
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 2,
                height: "100%",
                padding: "0 10px",
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  display: "flex",
                },
                "::-webkit-scrollbar-thumb": {
                  dynamic: "#8d8e90",
                  minHeight: "10px",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:vertical": {
                  maxHeight: "10px",
                },
              }}
            >
              <Stack>
                <SizeChartTemplate
                  append={(data, index) => {
                    if (index !== null) {
                      update(index, data);
                    } else {
                      append(data);
                    }
                    SetEditId(null);
                  }}
                  defaultValue={editId !== null ? fields[editId] : null}
                  index={editId}
                  size={size}
                  HSNData={HSNStore}
                  nextClicked={nextClicked}
                />

                {fields.length === 0 ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      flexDirection: "column",
                      width: "98%",
                      mx: "auto",
                    }}
                  >
                    <Typography sx={CommonTextStyle}>
                      Added Details ( {fields?.length} )
                    </Typography>
                    <TableContainer
                      sx={{
                        width: "auto",
                        borderRadius: "10px",
                        background: "transparent",
                        border: "1px solid #e3e3e3",
                        ml: 1,
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                          display: "flex",
                          height: "6px",
                        },
                      }}
                    >
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                          borderRadius: "10px",
                          overflowX: "scroll",
                          background: "transparent",
                        }}
                        size="small"
                        aria-label="a dense table"
                      >
                        {fields?.map((item, idx) => {
                          return (
                            <>
                              <TableHead>
                                <TableRow>
                                  {tableRowFields?.map((data) => {
                                    return (
                                      <TableCell
                                        key={data.value}
                                        sx={{
                                          ...tableDataStyle,
                                          padding: "10px",
                                          textTransform: "capitalize",
                                          textAlign: "center",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        <span
                                          style={
                                            data.value ==
                                              "TotalValueUploaded" ||
                                            data.value == "ProductSize"
                                              ? {
                                                  width: "135px",
                                                  display: "inline-block",
                                                }
                                              : {}
                                          }
                                        >
                                          {data.label}
                                        </span>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  borderBottom: "1px solid #EDEFF2",
                                }}
                              >
                                <TableRow
                                  key={item}
                                  style={{
                                    borderBottom: "1px solid #e3e3e3",
                                    padding: "10px",
                                  }}
                                >
                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.PricePerUnit}
                                  </TableCell>

                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.TotalAvailableQty}
                                  </TableCell>

                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.HSN}
                                  </TableCell>
                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.GST}
                                  </TableCell>

                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.MinOrderQuantity}
                                  </TableCell>
                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.MaxOrderQuantity}
                                  </TableCell>

                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.TotalValueUploaded}
                                  </TableCell>

                                  {localStorage.getItem("digitalData") ===
                                    "Offer Specific" && (
                                    <>
                                      <TableCell
                                        align="center"
                                        sx={TableCellStyle}
                                      >
                                        {item.OfferingType}
                                      </TableCell>
                                    </>
                                  )}

                                  <TableCell align="center" sx={TableCellStyle}>
                                    {item.validityOfVoucherValue} &nbsp;{" "}
                                    {item.validityOfVoucherUnit}
                                  </TableCell>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        SetEditId(idx);
                                      }}
                                    >
                                      <Box component="img" src={EditIcon} />
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        remove(idx);
                                      }}
                                    >
                                      <Box component="img" src={RemoveIcon} />
                                    </Button>
                                  </Box>
                                </TableRow>
                              </TableBody>
                            </>
                          );
                        })}
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                {/* size chart option end */}

                {/* OthercostFields start */}
                <OthercostsTemplate
                  append={(data, index) => {
                    if (index !== null) {
                      OthercostUpdate(index, data);
                    } else {
                      OthercostAppend(data);
                    }
                    SetOthercostEditId(null);
                  }}
                  defaultValue={
                    OthercostEditId !== null
                      ? OthercostFields[OthercostEditId]
                      : null
                  }
                  index={OthercostEditId}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",
                    width: "100%",
                    mx: "auto",
                    marginBottom: "10px",
                  }}
                >
                  <TableContainer
                    sx={{
                      width: "auto",
                      borderRadius: "10px",
                      background: "transparent",
                      border:
                        OthercostFields.length === 0
                          ? "none"
                          : "1px solid #e3e3e3",

                      overflow: "auto",
                      "::-webkit-scrollbar": {
                        display: "flex",
                        height: "6px",
                      },
                    }}
                  >
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                        borderRadius: "10px",
                        overflowX: "scroll",
                        background: "transparent",
                      }}
                      size="small"
                      aria-label="a dense table"
                    >
                      {OthercostFields?.map((item, idx) => {
                        return (
                          <>
                            <TableHead>
                              <TableRow>
                                {OthercostFieldsarray?.map((data) => {
                                  if (data === "id" || data === "listPeriod")
                                    return null;
                                  return (
                                    <TableCell
                                      align="left"
                                      key={data}
                                      sx={{
                                        ...tableDataStyle,
                                        padding: "10px",
                                        textTransform: "capitalize",
                                        whiteSpace: "nowrap",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {data}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                borderBottom: "1px solid #EDEFF2",
                              }}
                            >
                              <TableRow
                                key={item}
                                style={{
                                  borderBottom: "1px solid #e3e3e3",
                                  padding: "10px",
                                }}
                              >
                                <TableCell align="center" sx={TableCellStyle}>
                                  {item.AdCostApplicableOn}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    ...TableCellStyle,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.CostPrice}
                                  {"  "}
                                  {item.currencyType === "BXITokens" ? (
                                    <img
                                      src={bxitoken}
                                      style={{ width: "15px", height: "15px" }}
                                      alt="bxitoken"
                                    />
                                  ) : (
                                    item.currencyType
                                  )}
                                </TableCell>
                                <TableCell align="left" sx={TableCellStyle}>
                                  {item.AdCostHSN}
                                </TableCell>
                                <TableCell align="left" sx={TableCellStyle}>
                                  {item.AdCostGST} %
                                </TableCell>
                                <TableCell align="left" sx={TableCellStyle}>
                                  {item.ReasonOfCost}
                                </TableCell>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "20px",
                                    px: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={EditIcon}
                                    sx={{
                                      height: "12px",
                                      width: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      SetOthercostEditId(idx);
                                    }}
                                  />

                                  <Box
                                    component="img"
                                    src={RemoveIcon}
                                    sx={{
                                      height: "12px",
                                      width: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      OthercostRemove(idx);
                                    }}
                                  />
                                </Box>
                              </TableRow>
                            </TableBody>
                          </>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </Box>

                {costsArr?.map((items) => {
                  return (
                    <Box
                      key={items}
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        mt: "30px",
                        width: "100%",
                        gap: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          height: "60px",
                          display: " grid",
                          placeItems: "center",
                          border: "1px solid #E3E3E3",
                          borderRedius: "10px",
                          color: "#C64091",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          borderRadius: "10px",
                        }}
                      >
                        {items?.amount}
                        {paythru === "bxitokens" ? (
                          <Box component="img" src={stackofcoins} />
                        ) : (
                          "₹"
                        )}
                      </Box>

                      <Box
                        sx={{
                          width: "60%",
                          height: "60px",
                          display: " flex",
                          placeItems: "center",
                          border: "1px solid #E3E3E3",
                          borderRedius: "10px",
                          color: "#C64091",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          borderRadius: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{items?.reasonOfCost}</Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              const newCostsArr = costsArr.filter(
                                (item) => item !== items
                              );
                              setCostsArr(newCostsArr);
                              setCurrency(items);
                            }}
                          >
                            <Box component="img" src={EditIcon} />
                          </Button>
                          <Button
                            onClick={() => {
                              const newCostsArr = costsArr.filter(
                                (item) => item !== items
                              );
                              setCostsArr(newCostsArr);
                            }}
                          >
                            <Box component="img" src={RemoveIcon} />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {/* OthercostFields end */}

                {/* select best feature section start */}
                <Box
                  sx={{
                    py: "20px",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Poppins",
                      color: "#6B7A99",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Select the best features that describes your brand/product
                      <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {" "}
                      (The more features you write the more you are discovered){" "}
                    </Typography>
                  </Box>

                  <Box>
                    <Box sx={boxStyle19}>
                      <Typography sx={CommonTextStyle}>
                        Select Best Feature ( Min 5 and Max 20 ){" "}
                        <span style={{ color: "red" }}> *</span>s
                      </Typography>
                      <Select
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={selectStyle1}
                        key={traits}
                      >
                        {FeatureArray?.map((el) => {
                          return (
                            <MenuItem key={el} value={el} sx={CommonTextStyle}>
                              <Typography
                                sx={{
                                  color: "#C64091",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                }}
                              >
                                {el}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {nameDuplicate && items.length < 5 && (
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          selected feature already added, select at least{" "}
                          {5 - items.length} feature
                        </Typography>
                      )}
                      {nameDuplicate &&
                        items.length > 4 &&
                        items.length < 21 && (
                          <Typography
                            sx={{ color: "red", fontFamily: "Poppins" }}
                          >
                            selected feature already added
                          </Typography>
                        )}
                      {addItemClicked && !nameDuplicate && items.length < 5 && (
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          select at least {5 - items.length} feature
                        </Typography>
                      )}
                      {addItemClicked && items.length > 20 && (
                        <Typography sx={errorLableStyle}>
                          can not select more than 20 feature
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ ...CommonTextStyle, pt: "20px" }}>
                        Feature Description
                        <span style={{ color: "red" }}> *</span>
                      </Typography>

                      <TextField
                        focused
                        multiline
                        variant="standard"
                        sx={{
                          ...TextFieldStyle,
                          height: "100%",
                          color: "#C64091",
                        }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        minRows={3}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              }}
                            ></Typography>
                          ),
                          style: {
                            fontFamily: "Poppins",
                            color: "#C64091",
                            fontSize: "14px",
                          },
                        }}
                      />
                      {addItemClicked && description.length > 75 && (
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          Feature description should be less than 75 characters
                        </Typography>
                      )}
                      {addItemClicked && items.length < 5 && (
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          enter at least {5 - items.length} feature description
                        </Typography>
                      )}
                      {addItemClicked && items.length > 20 && (
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          can not enter more than 20 feature description
                        </Typography>
                      )}
                    </Box>

                    <Button
                      variant="contained"
                      onClick={handleItemAdd}
                      sx={buttonStyleAdd}
                      id="AddButton"
                    >
                      Add Feature
                    </Button>

                    <Typography sx={featureStyle}>
                      Key Features({items.length})
                    </Typography>

                    <Box sx={{ width: "100%" }}>
                      {items.map((item, index) => (
                        <Box sx={boxStyle20}>
                          <Box key={index} sx={boxStyle21}>
                            <Typography sx={{ ...mapdata }}>
                              <Typography sx={itemStyle}>
                                {item.name}
                              </Typography>

                              {item.description}
                            </Typography>

                            <Button
                              onClick={() => handleDelete(index)}
                              sx={{ textTransform: "none", fontSize: "15px" }}
                            >
                              X
                            </Button>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Stack>
              {/* tags section starts */}

              <Box sx={{ display: "grid", gap: "10px", py: "20px" }}>
                <Typography sx={TypographyStyle}>
                  {TagName}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <Box
                  sx={{
                    display: "flex",

                    borderRadius: "10px",
                  }}
                >
                  <TextField
                    placeholder="Tags"
                    sx={{
                      width: "100%",
                      background: "#fff",
                      borderRadius: "10px",
                      height: "41px",
                      width: "90%",
                      marginRight: "10px",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: "#C64091",
                        fontSize: "14px",
                        marginTop: "5px",
                        marginLeft: "1%",
                      },
                    }}
                    inputProps={{ maxLength: 15 }}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                    onKeyPress={handleAddTag}
                    value={currentTag}
                    onChange={(e) => {
                      setCurrentTag(e.target.value);
                    }}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      color: "#FFF",
                      right: 1,
                      textTransform: "none",
                      fontSize: "12px",
                      background: "#C64091",
                      alignSelf: "center",
                      "&:hover": {
                        background: "#C64091",
                      },
                    }}
                    onClick={handleAddButtonClick}
                  >
                    Add
                  </Button>
                </Box>
                <Typography sx={ErrorStyle}>
                  {errors["Tags"]?.message}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "auto",
                    gap: "5px",
                  }}
                >
                  {tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      onDelete={handleDeleteTag(tag)}
                      color="default"
                      fullWidth
                      sx={{
                        fontSize: "14px",
                        background: "#FFFFFF ",
                        color: "#6B7A99",
                        height: "50px",
                        boxShadow: "0px 4px 4px rgba(229, 229, 229, 0.25)",
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              mx: "auto",

              background: "#FAFBFD",
            }}
          >
            <BottomNavigation sx={showLabelsStyle} showLabels>
              <Box sx={boxStyle23}>
                <Button
                  sx={cancelStyle}
                  variant="contained"
                  onClick={() => {
                    let confirm = window.confirm(
                      "Are you sure you want to cancel the product?"
                    );
                    if (confirm) {
                      navigate("/home/physical");
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  sx={nextStyle}
                  variant="contained"
                  onClick={handleConsole}
                >
                  Next
                </Button>
              </Box>
            </BottomNavigation>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default RestaurantQSRGeneralInformation;

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const tableDataStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 12,
  color: "#6B7A99",
};

const TableCellStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 12,
  textAlign: "center",
  color: "#C64091",
  overflow: "scroll",
};

const mapdata = {
  color: " #6B7A99",
  fontFamily: "Poppins",
  width: "100%",
  fontSize: "12px",
};

const TextFieldStyle = {
  width: "98%",
  height: "48px",
  background: "#fff",
  borderRadius: "9px",
  border: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#C64091",
  overflow: "auto",
  paddingLeft: "10px",
  "&:focus": {
    outline: "none",
  },
};

const errorLableStyle = { fontFamily: "Poppins", color: "red" };

const boxStyle19 = {
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

const boxStyle20 = {
  border: "1px solid #E3E3E3",
  marginTop: "1rem",
  mx: "auto",
  height: "auto",
  width: "99%",
  display: " flex",
  flexDirection: "column",
  placeItems: "center",
  borderRadius: "10px",
};

const boxStyle21 = {
  display: "flex",
  width: "97%",
  minHeight: "60px",
  justifyContent: "space-between",
  height: "auto",
};

const boxStyle23 = {
  display: "flex",
  gap: "10px",
  p: 1,
  width: "50%",
};

const selectStyle1 = {
  width: "100%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#fff",
  height: "100%",
  borderRadius: "10px",
  color: "#ADB8CC",
  fontSize: "12px",
};

const buttonStyleAdd = {
  width: "100%",
  height: "41px",
  background: "#C64091",
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#FFFFFF",
  textTransform: "none",
  "&:hover": {
    background: "#C64091",
  },
  my: 3,
};

const featureStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fonmtSize: "20px",
  marginRight: "75%",
  marginTop: "1rem",
};

const itemStyle = {
  fontWeight: "bold",
  marginTop: "15px",
  fontSize: "12px",
};

const showLabelsStyle = {
  display: "flex",
  justifyContent: "flex-end",
  bgcolor: "#FAFBFD",
  p: "10px",
};

const cancelStyle = {
  width: "100%",
  height: "32px",
  borderRadius: "10px",
  background: "#fff",
  color: "#636161",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    background: "#f3f6f9",
    color: "#000",
  },
};

const nextStyle = {
  width: "100%",
  height: "32px",
  borderRadius: "10px",
  background: "#C64091",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    background: "#C64091",
  },
};

const TypographyStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#6B7A99",
};
const ErrorStyle = {
  color: "red",
};
