import { useQuery } from "react-query";
import axios from "axios";
export const useGetAllDeletedMembers = () => {
  const { data, error, isLoading } = useQuery("deleted members", () =>
    axios
      .get("assign/get_deleted_IamUser_ByCompanyId", {
        withCredentials: true,
      })
      .then((res) => res.data)
  );

  return { data, error, isLoading };
};
