import { Paper, Box, Typography, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import CommaSeprator from "../../components/CommaSeprator";
import useStyles from "./Styles";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import useGetOrderById from "../../Hooks/OrderActions/useGetOrderById";
import { useParams } from "react-router-dom";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import { useReactToPrint } from "react-to-print";
import Purches from "../../assets/Images/CommonImages/checklist (1) 1.png";
import Perfoma from "../../assets/Images/CommonImages/invoice (4) 1.png";
import Tax from "../../assets/Images/CommonImages/tax 1.png";
import bxiLogo from "../../assets/Images/CommonImages/logo.png";
import Truck from "../../assets/Images/CommonImages/delivery-truck (2) 1.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeliveredIcon from "../../assets/Delivered.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";

const OrderDetailsForSeller = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    data: OrderData,
    isLoading: OrderLoading,
    error: OrderDataError,
  } = useGetOrderById(id);

  const printRef = useRef();

  const downloadRef = useRef(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const ConfirmOrderDelivery = async () => {
    axios
      .put(`order/update_order_with_any_data/${id}`, {
        BuyerOrderStatus: "success",
      })
      .then((res) => {
        toast.success("Order Delivery Confirmation Successfully");
      })
      .catch((err) => {});
  };

  function convertDate(inputFormat) {
    function getMonthName(month) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[month];
    }

    function formatTime(hours, minutes) {
      const period = hours < 12 ? "AM" : "PM";
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${pad(minutes)} ${period}`;
    }

    function pad(s) {
      return s < 10 ? "0" + s : s;
    }

    var d = new Date(inputFormat);
    const day = pad(d.getDate());
    const month = getMonthName(d.getMonth());
    const year = d.getFullYear();
    const time = formatTime(d.getHours(), d.getMinutes());

    return `${day} ${month} ${year} at ${time}`;
  }

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Order Details"} showbreadcrumb={true} />

      <Paper
        sx={{
          width: "100%",
          mx: "auto",
          height: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          borderRadius: "10px",
        }}
        elevation={0}
      >
        <Typography
          sx={{
            marginLeft: "13px",
            color: "#6B7A99",
            fontSize: "15px",
          }}
        >
          <span
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
          >
            Order {OrderData?.OrderId}{" "}
          </span>{" "}
          <span
            style={{
              color: "#808089",
              fontSize: "12px",
              fontWeight: 300,
              marginLeft: "5px",
            }}
          >
            {convertDate(OrderData?.createdAt)}
          </span>
        </Typography>

        <Box
          sx={{
            display: "flex",
            marginRight: "13px",
            width: "60px",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={PrintPurchaseOrder}
            sx={{ width: "22px", height: "auto", cursor: "pointer" }}
            onClick={handlePrint}
          />
          <Box
            component="img"
            src={DocDownloadImg}
            sx={{ width: "21px", height: "auto", cursor: "pointer" }}
            // onClick={handleDownloadClick}
            onClick={handlePrint}
          />
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          borderRadius: "10px",
          // background: "red",
          mt: 1,
        }}
        ref={componentRef}
      >
        <Box
          sx={{
            width: "67%",
            height: "100%",
            minHeight: "200px",
            p: "20px",
            borderRadius: "10px",
            // background: "green",
          }}
        >
          <Box
            sx={{
              background: "white",
              width: "760px",
              height: "350px",
              borderRadius: "10px",
              marginRight: "150px",
              marginLeft: "-20px",
              p: "25px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontWeight: 550,
                fontSize: "14px",
                color: "#6B7A99",
                borderBottom: "1px solid #E4E4E4",
                paddingBottom: "4px",
              }}
            >
              Order Details
            </Typography>
            <Box
              sx={{
                width: "750px",
                marginTop: "10px",
                height: "150px",
                background: "transparent",
                position: "relative",
                bottom: "-4px",
              }}
            >
              {OrderData?.PurchaseOrderData?.ProductData?.map((res, idx) => {
                return (
                  <Box className={classes.rootbox}>
                    <Box className={classes.rootboxChildOne}>
                      <Box
                        className={classes.cartProductStrip}
                        sx={{
                          backgroundImage: `url(${res?.ProductImage})`,
                        }}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          alignContent: "start",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            ...ProductNameTextStyle,
                            width: "300px",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            color: "#525252",
                          }}
                        >
                          {res?.ProductName}
                        </Typography>
                        <Typography
                          sx={{
                            ...ProductMetaTextStyle,
                            width: "300px",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                          }}
                        >
                          {res?.ProductDescription}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            alignContent: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              ...ProductPriceTextStyle,
                              marginTop: "-03px",
                              color: "#525252",
                            }}
                          >
                            Quantity:
                            <Typography
                              sx={{
                                ...ProductPriceTextStyle,
                                marginTop: "-03px",
                                color: "#888888",
                                display: "inline",
                                marginLeft: "4px",
                              }}
                            >
                              {res?.ProductQuantity}
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.rootboxChildTwo}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          alignContent: "center",
                          width: "100%",
                          marginRight: "20px",
                        }}
                      >
                        <Typography sx={ProductPriceTextStyle}>
                          <img
                            src={StacsOfCoinIcon}
                            alt="rupieicon"
                            style={{
                              width: "20px",
                              height: "auto",
                            }}
                          />
                        </Typography>
                        <Typography
                          sx={{
                            ...ProductPriceTextStyle,
                            marginTop: "-03px",
                          }}
                        >
                          &nbsp;
                          <CommaSeprator Price={res?.TotalPriceWithGSTInBXI} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                marginRight: "15px",
                alignItems: "end",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#28282B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Subtotal &nbsp;&nbsp;{" "}
                <span
                  style={{
                    color: "#28282B",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {OrderData?.PITotals?.TotalPriceWithGSTInBXI}
                </span>
              </Typography>{" "}
              <br />
              <Typography
                sx={{
                  color: "#28282B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Shipping &nbsp;&nbsp;{" "}
                <span
                  style={{
                    color: "#28282B",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {OrderData?.BuyerChoosedTransportation?.TotalWithGST || 0}
                </span>
              </Typography>
              <br />
              <Typography
                sx={{
                  color: "#28282B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Tax: &nbsp;&nbsp;{" "}
                <span
                  style={{
                    color: "#00AB56",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  <CommaSeprator
                    Price={
                      OrderData?.INRDetails?.totalBxiCommission ||
                      0 + OrderData?.INRDetails?.bxiCommisionGST ||
                      0
                    }
                  />
                </span>
              </Typography>
              <br />
              <Typography
                sx={{
                  color: "#28282B",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Total &nbsp;&nbsp;{" "}
                <span
                  style={{
                    color: "#28282B",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {OrderData?.PITotals?.TotalPriceWithGSTInBXI ||
                    0 + OrderData?.BuyerChoosedTransportation?.TotalWithGST ||
                    0 + OrderData?.INRDetails?.totalBxiCommission ||
                    0 + OrderData?.INRDetails?.bxiCommisionGST ||
                    0}
                </span>
              </Typography>
              <br />
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              mt: 3,
              height: "100%",
              width: "810px",
              borderRadius: "10px",
              display: "flex",
              marginRight: "150px",
              marginLeft: "-20px",
              // p:"25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column ",
                ml: 2,
                bordrRadius: "10px",
              }}
            >
              <br />
              <Typography
                sx={{
                  color: "#6B7A99",
                  fontSize: "15px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  mt: 2,
                  bordrRadius: "10px",
                }}
              >
                Buyer Details
              </Typography>
              <br />
              <br />

              <span
                style={{
                  color: "#6B7A99",
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  display: "flex",
                }}
              >
                <img
                  src={
                    OrderData?.PurchaseOrderData.BuyerDetails?.BuyerCompanyLogo
                  }
                  style={{ height: "30px", width: "30px" }}
                />
                &nbsp;{" "}
                {OrderData?.PurchaseOrderData.BuyerDetails?.BuyerCompanyName}
              </span>
              <Typography
                sx={{
                  color: "#6B7A99",
                  fontSize: "10px",
                  mt: 1,
                  fontWeight: 400,
                  fontFamily: "Poppins",
                }}
              >
                <PhoneIcon /> &nbsp;&nbsp;{" "}
                {OrderData?.PurchaseOrderData.BuyerDetails?.BuyerCompanyContact}
              </Typography>

              <Typography
                sx={{
                  color: "#6B7A99",
                  fontSize: "10px",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                }}
              >
                <EmailIcon />
                &nbsp;&nbsp;{" "}
                {OrderData?.PurchaseOrderData.BuyerDetails?.BuyerCompanyEmail}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                ml: 5,
                justifyContent: "flex-end",
              }}
            >
              <br />
              <Typography
                sx={{
                  color: "#6B7A99",
                  fontSize: "15px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  mt: 2,
                }}
              >
                Billing Address
              </Typography>
              <br />
              <br />
              <Typography
                sx={{
                  color: "#6B7A99",
                  fontSize: "10px",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  mt: 1,
                  whiteSpace: "nowrap",
                }}
              >
                {
                  OrderData?.PurchaseOrderData?.BuyerDetails?.Address
                    ?.AddressLine
                }
              </Typography>

              <br />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "40%",
            height: "54rem",
            minHeight: "200px",
            p: "25px",
            backgroundColor: "white",
            borderRadius: "10px",
            mt: 1, // Adjust this value to move the box up
          }}
        >
          {OrderData?.PurchaseOrderData ? (
            <>
              <Box
                sx={{
                  width: "100px",
                  mt: 1,
                }}
              >
                <span
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#27272A",
                    fontFamily: "Inter",
                  }}
                >
                  <img
                    src={Purches}
                    style={{ height: "32px", width: "32px" }}
                  />
                  &nbsp; Purchase Order{" "}
                </span>

                <Button
                  variant="outlined"
                  sx={{
                    height: "21px",
                    width: "15px",
                    ml: 4,
                  }}
                  startIcon={<RemoveRedEyeIcon />}
                  onClick={() => {
                    navigate(
                      `/home/purchaseorderdetails/${OrderData?.PurchaseOrderData?._id}`
                    );
                  }}
                >
                  view
                </Button>
              </Box>
              <hr />
            </>
          ) : null}

          <Box>
            {OrderData?.InvoiceData ? (
              <>
                <Box
                  sx={{
                    width: "100px",
                    mt: 1,
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#27272A",
                      fontFamily: "Inter",
                    }}
                  >
                    <img
                      src={Perfoma}
                      style={{ height: "32px", width: "32px" }}
                    />
                    &nbsp; Proforma Invoice{" "}
                  </span>

                  <Button
                    variant="outlined"
                    sx={{
                      height: "21px",
                      width: "21px",
                      ml: 4,
                    }}
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={() => {
                      navigate(
                        `/home/performainvoice/${OrderData?.InvoiceData?.OrderSummeryId}`
                      );
                    }}
                  >
                    view
                  </Button>
                </Box>
                <hr />
              </>
            ) : null}
            {OrderData?.TaxInvoice ? (
              <>
                <Box
                  sx={{
                    width: "100px",
                    mt: 1,
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#27272A",
                      fontFamily: "Inter",
                    }}
                  >
                    <img src={Tax} style={{ height: "32px", width: "32px" }} />
                    &nbsp; Tax Invoice
                  </span>

                  <Button
                    variant="outlined"
                    sx={{
                      height: "21px",
                      width: "21px",
                      ml: 4,
                    }}
                    onClick={() => {
                      navigate(
                        `/home/taxinvoice/${OrderData?.TaxInvoice?._id}`
                      );
                    }}
                    startIcon={<RemoveRedEyeIcon />}
                  >
                    view
                  </Button>
                </Box>
                <hr />
              </>
            ) : null}
            {OrderData?.BXICommision ? (
              <>
                <Box
                  sx={{
                    width: "100px",
                    mt: 1,
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#27272A",
                      fontFamily: "Inter",
                    }}
                  >
                    <img
                      src={bxiLogo}
                      style={{ height: "32px", width: "32px" }}
                    />
                    &nbsp; BXI Commission{" "}
                  </span>

                  <Button
                    variant="outlined"
                    sx={{
                      height: "21px",
                      width: "21px",
                      ml: 4,
                    }}
                    onClick={() => {
                      navigate(`/home/commission/${OrderData?._id}`);
                    }}
                    startIcon={<RemoveRedEyeIcon />}
                  >
                    view
                  </Button>
                </Box>
                <hr />
              </>
            ) : null}
            {OrderData?.ShippingInvoice ? (
              <>
                <Box
                  sx={{
                    width: "100px",
                    mt: 1,
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#27272A",
                      fontFamily: "Inter",
                    }}
                  >
                    <img
                      src={Truck}
                      style={{ height: "32px", width: "32px" }}
                    />
                    &nbsp; Shipping Invoice{" "}
                  </span>

                  <Button
                    variant="outlined"
                    sx={{
                      height: "21px",
                      width: "21px",
                    }}
                    startIcon={<RemoveRedEyeIcon />}
                  >
                    view
                  </Button>
                </Box>
                <hr />
              </>
            ) : null}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                Order Status
              </Typography>
              {OrderData?.OrderStatus === "Delivered" ? (
                <img
                  src={DeliveredIcon}
                  style={{ width: "100px", height: "auto" }}
                />
              ) : (
                <Box
                  sx={{
                    width: "auto",
                    bgcolor: "yellow",
                    px: "10px",
                    py: "5px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                    {OrderData?.OrderStatus
                      ? OrderData?.OrderStatus
                      : "Pending"}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default OrderDetailsForSeller;

const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "12px",
    xs: "12px",
  },
  lineHeight: {
    xl: "15px",
    lg: "15px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const tabText = {
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.1rem",
    sm: "0.8rem",
    xs: "0.8rem",
  },
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "center",
  color: "#6B7A99",
  textTransform: "none",
  width: { xl: "45%", lg: "50%", md: "50%", sm: "80%", xs: "95%" },
  mx: "auto",
  lineHeight: "2rem",
  mt: 2,
};
