import React from 'react';
import MainLogo from './MainLogo.jsx';
import mainImg from '../../assets/Images/register/accverfi.svg';
import protect from '../../assets/Images/verified/protect.svg';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import * as StylesOnBoarding from '../../components/common/Styled/StylesOnBoarding.js';
const AccVerified = () => {
  return (
    <Stack>
      <Paper elevation={0} sx={StylesOnBoarding.mainCommonPage}>
        <Grid
          container
          sx={{
            background: '#fff',
            height: '100vh',
            width: {
              xl: '110%',
              lg: '110%',
              md: '110%',
              sm: '100%',
              xs: '100%',
            },
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: {
                xl: '0rem',
                lg: '0rem',
                md: '0rem',
                sm: '-10rem',
                xs: '-10rem',
              },
            }}
          >
            <MainLogo />
            <Box pb={2}>
              <img
                src={protect}
                alt="protect"
                style={{
                  height: 'auto',
                  width: 'auto',
                }}
              />
            </Box>
            <Typography sx={StylesOnBoarding.HeadText}>
              Account Verified
            </Typography>
            <Box
              mt={2}
              sx={{
                width: {
                  xl: '75%',
                  lg: '75%',
                  md: '70%',
                  sm: '70%',
                  xs: '95%',
                },
                mx: 'auto',
              }}
            >
              <Typography sx={StylesOnBoarding.intoText}>
                Congratulations , BXI Welcome you on-board. You can now start
                trading goods and services on the platform to earn and burn
                Barter Coins them for your purchase.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ ...StylesOnBoarding.CommonBtn, mt: 2 }}
            >
              Continue
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: {
                xl: 'flex',
                lg: 'flex',
                md: 'flex',
                sm: 'none',
                xs: 'none',
              },
            }}
          >
            <img
              src={mainImg}
              alt="img"
              style={{
                ...StylesOnBoarding.CommonImgStyle,
                marginRight: '150px',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default AccVerified;
