import {
  ListedProductsByCompanyReceived,
  ListedProductsByCompanyRequested,
  ListedProductsByCompanyRequestFailed,
  ListedDraftProductsByCompanyReceived,
  ListedDraftProductsByCompanyRequestFailed,
  ListedDraftProductsByCompanyRequested,
  AllListedProductByCompanyReceived,
  AllListedProductByCompanyRequested,
  AllListedProductByCompanyRequestFailed,
  AllRejectedProductByCompanyRequest,
  AllRejectedProductByCompanyReceived,
  AllRejectedProductByCompanyRequestFailed,
  AllDelistProductByCompanyRequest,
  AllDelistProductByCompanyReceived,
  AllDelistProductByCompanyRequestFailed,
  AllPendingProductByCompanyRequest,
  AllPendingProductByCompanyReceived,
  AllPendingProductByCompanyRequestFailed,
} from "../../reduser/ProductReducer/ListedProductsByCompany";

import axios from "axios";

export const ListedProductsByCompanyAction = (page = 1, Type) => async (
  dispatch
) => {
  try {
    dispatch({ type: ListedProductsByCompanyRequested.toString() });
    let data = await axios
      .get(`product/get_product_bySellerCompanyId?page=${page}&Type=${Type}`, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
    dispatch({
      type: ListedProductsByCompanyReceived.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ListedProductsByCompanyRequestFailed.toString(),
      payload: error.response.data.message,
    });
  }
};

export const ListedDraftProductsByCompanyAction = (page = 1, Type) => async (
  dispatch
) => {
  try {
    dispatch({ type: ListedDraftProductsByCompanyRequested.toString() });
    let data = await axios
      .get(`product/get_listed_draft_product?page=${page}&Type=${Type}`, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
    dispatch({
      type: ListedDraftProductsByCompanyReceived.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ListedDraftProductsByCompanyRequestFailed.toString(),
      payload: error.response.data.message,
    });
  }
};

export const AllListedProductByCompanyAction = (page = 1, Type) => async (
  dispatch
) => {
  try {
    dispatch({ type: AllListedProductByCompanyRequested.toString() });
    let data = await axios
      .get(`product/get_product_byCompanyId?page=${page}&Type=${Type}`, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
    dispatch({
      type: AllListedProductByCompanyReceived.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AllListedProductByCompanyRequestFailed.toString(),
      payload: error.response.data.message,
    });
  }
};

export const AllRejectedProductByCompanyAction = (page = 1, Type) => async (
  dispatch
) => {
  try {
    dispatch({ type: AllRejectedProductByCompanyRequest.toString() });
    let data = await axios
      .get(`product/GetListedRejectedProduct?page=${page}&Type=${Type}`, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
    dispatch({
      type: AllRejectedProductByCompanyReceived.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AllRejectedProductByCompanyRequestFailed.toString(),
      payload: error.response.data.message,
    });
  }
};

export const AllDelistProductByCompanyAction = (page = 1, Type) => async (
  dispatch
) => {
  try {
    dispatch({ type: AllDelistProductByCompanyRequest.toString() });
    let data = await axios
      .get(`product/GetListedDelistProduct?page=${page}&Type=${Type}`, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
    dispatch({
      type: AllDelistProductByCompanyReceived.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AllDelistProductByCompanyRequestFailed.toString(),
      payload: error.response.data.message,
    });
  }
};

export const AllPendingProductByCompanyAction = (page = 1, Type) => async (
  dispatch
) => {
  try {
    dispatch({ type: AllPendingProductByCompanyRequest.toString() });
    let data = await axios
      .get(`product/GetListedPendingProduct?page=${page}&Type=${Type}`, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      });
    dispatch({
      type: AllPendingProductByCompanyReceived.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AllPendingProductByCompanyRequestFailed.toString(),
      payload: error.response.data.message,
    });
  }
};
