import { Box, Typography } from "@mui/material";
import React from "react";

const AdditionalCost = (props) => {
  return (
    <React.Fragment>
      {props?.data?.OtherCost && props?.data?.OtherCost?.length !== 0 ? (
        <Box mt={2}>
          <Typography
            sx={{
              ...product,
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "30px",
              color: "#156DB6",
            }}
          >
            Additional Cost
          </Typography>
          {props?.data?.OtherCost?.length === 0
            ? ""
            : props?.data?.OtherCost?.map((cost) => {
                const newValue = cost?.CostPrice.toFixed(2);
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        // gap: "60px",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          sx={{
                            ...listText,
                            wordWrap: "break-word",
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "grey",
                          }}
                        >
                          {" "}
                          &#8226; &nbsp;&nbsp;
                          {cost.AdCostApplicableOn}{" "}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            ...listText,
                            wordWrap: "break-word",
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "grey",
                          }}
                        >
                          {" "}
                          {cost.ReasonOfCost}{" "}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            ...listText,
                            wordWrap: "break-word",
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "grey",
                          }}
                        >
                          HSN - {cost.AdCostHSN}{" "}
                        </Typography>
                      </Box>

                      <Box sx={{}}>
                        <Typography
                          sx={{
                            ...listText,
                            wordWrap: "break-word",
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "25px",
                            color: "grey",
                          }}
                        >
                          GST - {cost.AdCostGST} %
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          gap: "5px",

                          display: "flex",
                        }}
                      >
                        <Typography
                          sx={{
                            ...listText,
                            wordWrap: "break-word",
                            color: "rgba(68, 95, 210, 1)",
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "25px",
                          }}
                        >
                          {newValue}
                        </Typography>
                        <Typography>
                          {cost.currencyType === "BXITokens" ? (
                            <Box
                              component="img"
                              src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                              alt="token"
                              sx={{
                                height: "auto",
                                width: "20px",
                                marginTop: "2px",
                              }}
                            />
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "20px",
                                ml: 1,
                              }}
                            >
                              ₹
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default AdditionalCost;

const listText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.6rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  // color: "#6B7A99",
  color: "grey",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#6B7A99",
};
