import React, { useEffect, useRef } from "react";
import Navbar from "./Navbar";
import LandingPageBody from "./LandingPageBody";
import { Box } from "@mui/material";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import About from "./About";
import Marketplace from "./Marketplace";
import LearnBarter from "./LearnBarter";

const HomePage = () => {
  const ref = useRef(null);
  const Location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (window.scrollY === 0) {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (Location?.state === "ABClick") {
      return ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const handleClick = () => {
    return ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>BXI - Barter Exchange Of India</title>
        <meta property="og:title" content="BXI - Barter Exchange Of India" />
        <meta
          name="og:description"
          content="BXI is India's online B2B marketplace, connecting businesses nationwide for innovative barter solutions. Join our platform to fuel your business growth with Barter Trades."
        />
        <meta
          name="description"
          content="BXI is India's online B2B marketplace, connecting businesses nationwide for innovative barter solutions. Join our platform to fuel your business growth with Barter Trades."
        />
      </Helmet>

      <>
        <Box
          sx={{
            marginTop: "64px",
          }}
        >
          <LandingPageBody />
        </Box>
      </>
    </Box>
  );
};

export default HomePage;
