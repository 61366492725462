import axios from "axios";

import { useQuery, useMutation } from "react-query";

export const useUpdatePurchaseOrder = () => {
  const { data, isLoading, error, mutate } = useMutation(
    (id) =>
      axios
        .put(
          `purchase/update_orderSummary`,
          { data: id },
          { withCredentials: true }
        )
        .then((res) => {
          return res.data;
        }),
    {
      onSuccess: (data) => {
        return data;
      },
    },
    {
      onLoading: (data) => {
        return data;
      },
    },
    {
      onError: (error) => {
        return error;
      },
    },
    {
      onSettled: (data) => {
        return data;
      },
    }
  );
  return { data, isLoading, error, mutate };
};

export const useFetchPurchaseOrder = (id) => {
  return useQuery(["PurchaseOrder", id], async () => {
    const response = await axios.get(`purchase/get_order_summary_by_id/${id}`, {
      withCredentials: true,
    });
    return response.data;
  });
};
