import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCompanyStepDetails, useGetCompanyDetails } from "../Hooks/Auth";
import mainImg from "../assets/Images/register/reverspennydrop.svg";
import cashback from "../assets/Images/verified/cashback.svg";
import MainLogo from "../pages/Register/MainLogo";
const ReversePenny = () => {
  const navigate = useNavigate();
  const {
    data: cdata,
    isError: dataError,
    isLoading: cdataLoading,
    refetch: cdataRefetch,
  } = useGetCompanyDetails();

  const { mutate } = useCompanyStepDetails();

  if (cdata?.data?.companyOnboardingStatus === "UNDER_REVIEW") {
    navigate("/under_review");
  } else if (cdata?.data.companyOnboardingStatus === "TNC") {
    navigate("/home/terms");
  }

  const forwardPennyClick = async () => {
    mutate(
      {},
      {
        onSuccess: (res) => {
          cdataRefetch();
        },
        onError: (err) => {},
      }
    );
  };

  useEffect(() => {
    forwardPennyClick();
  }, []);
  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          boxShadow: "0px",
          height: "100vh",
          width: "100%",
          maxHeight: "100vh",
          maxWidth: "100vw",
          overflowY: "hidden",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Grid
          container
          sx={{
            background: "#fff",
            height: "100vh",
            width: {
              xl: "110%",
              lg: "110%",
              md: "110%",
              sm: "100%",
              xs: "100%",
            },
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <MainLogo />
            <Box pb={2}>
              <img
                src={cashback}
                alt="protect"
                style={{
                  height: "auto",
                  width: "auto",
                }}
              />
            </Box>
            <Typography sx={login}> Depositing INR 1 </Typography>
            <Box
              mt={2}
              sx={{
                width: {
                  xl: "75%",
                  lg: "75%",
                  md: "70%",
                  sm: "70%",
                  xs: "95%",
                },
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Typography sx={intoText}>
                "Please check we have deposited INR 1 in your company bank
                account. We will further debit INR 1 from same bank account in
                the next step."
              </Typography>
            </Box>
            <Box
              mt={2}
              sx={{
                width: {
                  xl: "75%",
                  lg: "75%",
                  md: "70%",
                  sm: "70%",
                  xs: "95%",
                },
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Typography sx={intoText}>
                {" "}
                <span
                  style={{ color: "rgba(68, 95, 210, 1)", fontWeight: 600 }}
                ></span>{" "}
              </Typography>
            </Box>
            <CircularProgress sx={{ marginTop: "2rem" }} />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <img
              src={mainImg}
              alt="img"
              style={{
                height: "auto",
                width: "100%",
                maxHeight: "100vh",
                marginRight: "150px",
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default ReversePenny;
const login = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "3.2rem",
    lg: "3.1rem",
    md: "3rem",
    sm: "2.8rem",
    xs: "2.6rem",
  },
  color: "#6B7A99",
  textAlign: "center",
};

const intoText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  textAlign: "center",
  color: "#6B7A99",
};
