import axios from "axios";
import {
  Category_Id,
  Category_Ids,
} from "../../../components/Carousel/ProductDetailsCarousel";
import {
  Product_Qty,
  Clear,
  Refresh,
  PriceShortHightToLow,
  PriceShortLowToHight,
  WhatsNew,
  Popularity,
  Voucher,
  ProductsFilter,
  IsBestSeller,
  IsReadyStock,
} from "../../../views/MarketPlace";
import { Pagination_Count } from "../../../pages/Marketplace/ProductList";
import {
  product_Fail,
  product_Request,
  product_Success,
} from "../../reduser/Home-Filter/products";
import {
  MediaFilter,
  SelectedCompany,
  PriceRange,
  States,
  Tag,
  ProductSubtittleSearch,
  ProductDescriptionSearch,
  ProductCompanyNameSearch,
} from "../../../components/Filters/SearchBar";

export const getProduct =
  (
    Search = "",
    pricerange = "",
    sortBy = "",
    productQty = "",
    clear = "",
    categoryIds = "",
    refresh = "",
    SellerCompanyId = "",
    priceShortHightToLow,
    priceShortLowToHight,
    whatsNew = "",
    selectedCompany = "",
    currentPage = "",
    popularity = false,
    voucher,
    tag = "",
    productsFilter,
    IsBestSeller,
    IsReadyStock
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: product_Request.toString() });

      if (sortBy === "sort") sortBy = "-createdAt";

      const queryParams = new URLSearchParams({
        Search: Search.trim(),
        PriceRange: PriceRange,
        ProductLocationState: States,
        ProductRating: "",
        ProductQty: Product_Qty,
        ProductDeliveryType: "",
        ProductCategory: Category_Ids,
        Clear: Clear,
        Short: sortBy,
        Category_Id: Category_Id,
        Refresh: Refresh,
        SellerCompanyId: SellerCompanyId,
        priceShortHightToLow: priceShortHightToLow,
        priceShortLowToHight: priceShortLowToHight,
        whatsNew: WhatsNew,
        companyName: SelectedCompany,
        popularity: Popularity,
        Voucher: voucher,
        Tag: Tag,
        DescriptionSearch: ProductDescriptionSearch,
        SellerCompanyNameSearch: ProductCompanyNameSearch,
        ProductSubtittleSearch: ProductSubtittleSearch,
        productsFilter: productsFilter,
        IsBestSeller: IsBestSeller,
        IsReadyStock: IsReadyStock,
        page: currentPage,
        limit: "",
        mediaFilter: MediaFilter,
      }).toString();

      const { data } = await axios.get(`product/get_products?${queryParams}`, {
        withCredentials: true,
      });

      dispatch({
        type: product_Success.toString(),
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: product_Fail.toString(),
        payload: error.response?.data?.message || error.message,
      });
    }
  };
