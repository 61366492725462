import { createSlice } from '@reduxjs/toolkit';

export const SendMessagesSlice = createSlice({
    name: 'SendMessagesOnWhatsApp',
    initialState: {
        SendMessagesOnWhatsApp: {}
    },
    reducers: {
        SENDMESSAGES_ONWHATSAPP_REQUEST: (state) => {
            state.loading = true;
            state.SendMessagesOnWhatsApp = {};
        },
        SENDMESSAGES_ONWHATSAPP_SUCCESS: (state, action) => {
            state.loading = false;
            state.SendMessagesOnWhatsApp = action.payload;
        },
        SENDMESSAGES_ONWHATSAPP_FAIL: (state, action) => {
            state.loading = false;
            state.SendMessagesOnWhatsApp = action.payload;
        }
    }
});

export const { SENDMESSAGES_ONWHATSAPP_REQUEST, SENDMESSAGES_ONWHATSAPP_SUCCESS, SENDMESSAGES_ONWHATSAPP_FAIL } = SendMessagesSlice.actions;

export default SendMessagesSlice.reducer;


