import React from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import first from "../../assets/Selleridetailsmages/Rectangle.svg";
import second from "../../assets/Selleridetailsmages/logo.svg";
import three from "../../assets/Selleridetailsmages/Rectangle (1).svg";
import four from "../../assets/Selleridetailsmages/Rectangle (2).svg";
import User from "../../assets/Selleridetailsmages/Vector (2).svg";
import Phone from "../../assets/Selleridetailsmages/Vector (3).svg";
import Address from "../../assets/Selleridetailsmages/Vector (5).svg";
import SecuredLetter from "../../assets/Selleridetailsmages/Vector (4).svg";

const SellerBuyerMeetModal = (props) => {
  let { OrderData } = props;
  const navigate = useNavigate();

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Grid container>
          <img
            src={first}
            style={{
              background: "transparent",
              position: "absolute",
              marginLeft: "66.5%",
            }}
          />
          <img
            src={second}
            style={{
              background: "transparent",
              position: "absolute",
              marginTop: "110px",
              marginLeft: "80%",
            }}
          />

          <Box sx={{ width: "100%", mx: "auto", mt: 10 }}>
            <img
              src={three}
              style={{
                marginTop: "260px",
                position: "absolute",
                marginLeft: "52px",
              }}
            />

            <img
              src={four}
              style={{
                marginTop: "98px",
                position: "absolute",
                marginLeft: "52px",
                pb: 10,
              }}
            />

            <Typography
              sx={{
                color: "#226192",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "36px",
                textAlign: "center",
              }}
            >
              - Seller Details -
            </Typography>

            <Typography
              sx={{
                color: "#272523B2",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14px",
                textAlign: "center",
                width: "85%",
                mx: "auto",
              }}
            >
              If you experience any difficulties with your order, you may reach
              out to the seller <br /> with this contact information.
            </Typography>

            <Paper
              elevation={3}
              sx={{
                position: "relative",
                mt: 1,
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "15px",
                height: "auto",
                width: "80%",
                mx: "auto",
                pb: "40px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  sm={6}
                  sx={{
                    mt: 6,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={User}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                      }}
                    >
                      {
                        OrderData?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyName
                      }
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={Phone}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                      }}
                    >
                      +91 -{" "}
                      {
                        OrderData?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyContact
                      }
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={Address}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                        ml: 0.5,
                      }}
                    >
                      {
                        OrderData?.PurchaseOrderData?.SellerDetails?.Address
                          ?.AddressLine
                      }
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={SecuredLetter}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                      }}
                    >
                      {
                        OrderData?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyEmail
                      }
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/Selleridetailsmages/6884192+3.svg`}
                    style={{
                      borderRadius: "12px",
                      marginLeft: "140px",
                      mx: "auto",
                      width: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Button
              variant="contained"
              sx={{ ...btnpopup, mx: "auto", width: "25%" }}
              align="left"
              onClick={() => {
                navigate(
                  `/home/orderdetails/${OrderData?._id}/${OrderData?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyId}/buyer`
                );
              }}
            >
              View Order summary
            </Button>

            <br />
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SellerBuyerMeetModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  display: "grid",
  placeItems: "center",
  borderRadius: "10px",
};

const btnpopup = {
  mt: 2,
  display: "flex",
  gap: "5px",
  width: 200,
  height: 45,
  borderRadius: 1.5,
  background: "#226192",
  color: "#fff",
  fontFamily: "poppins",
  fontSize: "14px",
  borderRadius: "15px",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    background: "#226192",
  },
};
