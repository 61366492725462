import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const TermsCondition = () => {
  const navigate = useNavigate();
  const navigateFunction = () => {
    navigate("/home/profile");
  };
  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          borderRadius: "20px",
          minHeight: "520px",
          boxShadow: "none",
          bgcolor: "#fff",
          height: "auto",
          gap: "30px",
          p: 3,
        }}
        elevation={0}
      >
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            overflowY: "scroll",
            display: "flex",
            height: "auto",
            width: "98%",
            gap: "30px",
            p: 1,
          }}
        >
          <Typography sx={termstext}>
            1. Introduction:
            <br />
            BXI facilitates transactions between Members on its platform. In
            transactions on the platform, when a Buyer Member pays commission
            and TDS, these funds are initially credited to the Seller Member's
            account. BXI then collects this commission and TDS from the Seller
            Member's account using eNACH, an electronic National Automated
            Clearing House system.
            <br />
            <br />
            2. E-Mandate Authorization:
            <br />
            By agreeing to this consent, you authorize BXI to utilize eNACH to
            auto debit and collect commission and TDS associated with your
            transactions on the platform. This agreement establishes a maximum
            limit of INR 3,00,000 per transaction for BXI to collect via eNACH
            from your account.
            <br />
            <br />
            3. Maximum Collection Per Transaction:
            <br />
            This agreement ensures that while the actual commission and TDS
            amount may vary depending on the transaction, BXI will not deduct
            more than INR 3,00,000 per transaction from your account.
            <br />
            <br />
            4. Total Collection Over Time:
            <br />
            Please note that the total commission and TDS collected from
            multiple transactions over the validity period of this agreement (2
            years) may exceed the aforementioned per-transaction limit of INR
            3,00,000.
            <br />
            <br />
            5. E-Mandate Frequency:
            <br />
            BXI will utilize eNACH to automatically debit your account for
            commission and TDS reimbursement only when you complete a
            transaction on the platform.
            <br />
            <br />
            6. E-Mandate Validity:
            <br />
            This e-Mandate agreement will remain valid for a period of two (2)
            years from the date of your signature.
            <br />
            <br />
            7. Pre Debit Notification:
            <br />
            Member will be notified at least 24 hours before the commission and
            TDS reimbursement amount is debited
          </Typography>
        </Box>
      </Paper>

      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          mx: "auto",
          display: "flex",
          justifyContent: "space-evenly",
          height: "100%",
          gap: "20px",
          mt: "10px",
        }}
      >
        <Button sx={AcceptButton} onClick={navigateFunction}>
          Continue
        </Button>
      </Box>
    </Paper>
  );
};

export default TermsCondition;

const AcceptButton = {
  width: "100%",
  height: "50px",
  maxWidth: "220px",
  borderRadius: "6px",
  color: "#fff",
  bgcolor: "#2261A2",
  border: "2px solid #2261A2",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  ":hover": {
    background: "#fff",
    color: "#2261A2",
    borderRadius: "6px",
    border: "2px solid #2261A2",
  },
};

const DeclineButton = {
  width: "100%",
  height: "50px",
  maxWidth: "220px",
  borderRadius: "6px",
  color: "darkred",
  bgcolor: "#fff",
  border: "2px solid darkred",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  ":hover": {
    background: "#fff",
    color: "darkred",
    borderRadius: "6px",
    border: "2px solid darkred",
  },
};

const termstext = {
  fontFamily: "Poppins",
  textAlign: "justify",
  fontStyle: "normal",
  lineHeight: "20px",
  color: "#7D8BA6",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
};
