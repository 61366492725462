import { Box, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const PrivateDealAgreement = (props) => {
  const location = useLocation();
  const OrderData = props.OrderData;
  const ProductData = props.ProductData;
  const CouponCodeData = props.CouponCodeData;

  function getDate() {
    let date = new Date();

    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${day} of ${month}, ${year}`;
  }

  return (
    <Paper
      sx={{
        bgcolor: "#fff",
        boxShadow: "none",
        borderRadius: "20px",
        // height: "auto",
        maxHeight: "600px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        // overflowY: "scroll",
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: "100%",
          height: "400px",
          display: "flex",
          justifyContent: "center",
          overflowY: "scroll",
          gap: "30px",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            my: 2,
          }}
        >
          <Typography sx={credittermstext}>
            THIS CREDIT LINE AGREEMENT FOR BARTER COINS <b>(“Agreement”)</b> is
            made and entered into effective as of the{" "}
            <b style={{ borderBottom: "dotted" }}> {getDate()} </b>{" "}
            <b>(“Effective Date”)</b> by and among the following parties: <br />{" "}
            <b>BXI WORLD LLP (Lender)</b> has its Corporate Office at 501-5 th
            Floor Meadows Tower, Sahara Plaza Complex, Sir M.V. Road, Next to
            Kohinoor Continental Hotel, Andheri (East); Mumbai-400059
            (hereinafter referred to as BXI).
          </Typography>
          <Typography sx={{ ...credittermstext, textAlign: "center" }}>
            <b>AND</b>
          </Typography>
          <Typography sx={credittermstext}>
            <b style={{ borderBottom: "dotted" }}>
              {OrderData?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
            </b>{" "}
            and Registered Address{" "}
            <b style={{ borderBottom: "dotted" }}>
              {OrderData?.PurchaseOrderData?.BuyerDetails?.Address?.AddressLine}
            </b>
            . (Hereinafter referred to as Borrower).
          </Typography>
          <Typography sx={credittermstext}>
            <b>WHEREAS, </b>the Borrower is engaged in the business of ________
            and desires to offer its Product and/or Services ____________
            against Credit Line at BXI Marketplace.
          </Typography>
          <Typography sx={credittermstext}>
            <b>WHEREAS, </b>
            the Borrower receives Barter Coins valued according to the Total
            value as determined by BXI in the table below.
          </Typography>
          <Typography sx={credittermstext}>
            <b>WHEREAS, </b>
            the BXI and Borrower have agreed to enter into this Agreement upon
            their free will and consent and upon conditions hereinafter
            appearing as per the following terms and conditions:
          </Typography>
          <Typography sx={{ ...credittermstext, mt: 2 }}>
            Details of the product against which credit line is issued are as
            below.
          </Typography>

          <Typography sx={{ ...credittermstext, mt: 2 }}>
            <TableContainer
              sx={{
                mx: "auto",
                width: "90%",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={table}>Sr. No</TableCell>
                    <TableCell sx={table}>Product Name & Description</TableCell>
                    <TableCell sx={table}>Product Id</TableCell>
                    <TableCell sx={table}>Quantity</TableCell>
                    <TableCell sx={table}>Rate for BXI</TableCell>
                    <TableCell sx={table}>Total Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={table}>1.</TableCell>
                    <TableCell sx={table}>{ProductData?.ProductName}</TableCell>
                    <TableCell sx={table}>
                      {ProductData?.ProductsVariantions?.at(0)?.ProductIdType
                        ? ProductData?.ProductsVariantions?.at(0)?.ProductIdType
                        : "--"}
                    </TableCell>
                    <TableCell sx={table}>
                      {/* {ItemData?.at(0)?._quantity_for_credit === 0 ?  */}
                      --
                      {/* : ItemData?.at(0)?._quantity_for_credit} */}
                    </TableCell>
                    <TableCell sx={table}>
                      {CouponCodeData?.TokenAmount}
                    </TableCell>
                    <TableCell sx={table}>
                      {CouponCodeData?.TokenAmount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "98%",
              ml: "auto",
            }}
          >
            <Typography sx={credittermstext}>
              <b> 1. Purpose of the Credit Line:</b> General Credit Line: The
              Borrower wishes to utilize a Credit Line to purchase Products
              and/or Services from the BXI Marketplace. BXI has assessed the
              value of the Borrower's offered Products and/or Services as
              collateral for this Credit Line. By accepting this Agreement, the
              Borrower confirms and agrees to receive Barter Coins on the Credit
              Line, subject to the outlined terms and conditions.
              <Typography sx={{ ...credittermstext, textAlign: "left" }}>
                <b>OR</b>
              </Typography>
              Conditional Credit Line: The Borrower/Buyer wishes to utilize
              Coupon Code to purchase a specific product from the BXI
              Marketplace. BXI has issued a Coupon Code that specifies the
              amount of the conditional credit line and product for which it is
              applicable. By accepting this Agreement, the Borrower/Buyer
              confirms and agrees to receive Coupon Code of the specific amount,
              subject to the outlined terms and conditions.
            </Typography>
            <Typography sx={credittermstext}>
              {" "}
              <b> 2. Interest:</b> BXI does not impose any interest on Barter
              Coins issued to the Borrower. Barter Coins are interest-free
              credits, credited to the Borrower’s wallet on providing the
              Products and/or Services as security.
            </Typography>
            <Typography sx={credittermstext}>
              {" "}
              <b> 3. Validity of Barter Coins:</b> Barter Coins provided through
              this Credit Line remain valid as long as the Borrower remains
              active on the BXI platform. These coins are exclusively usable
              within the BXI ecosystem and cannot be employed as legal tender
              elsewhere. The Borrower must utilize the Barter Coins before
              leaving the platform. Failure to utilize all Barter Coins means
              the Borrower forfeits any entitlement to repayment in INR for the
              remaining value of the coins.
            </Typography>
            <Typography sx={credittermstext}>
              {" "}
              <b> 4. Rights of Lender: </b>BXI retains the authority over the
              products and services listed on the BXI platform by the Borrower,
              against which a Credit Line has been allocated. These listed
              products and services must remain inaccessible for removal from
              the marketplace until all associated obligations have been met by
              the respective members.
            </Typography>
            <Typography sx={credittermstext}>
              {" "}
              <b> 5. Ready Stock: </b>The Products and/or Services covered by
              the Credit Line must be labeled as “Ready Stock” and have a
              minimum 75% shelf life upon delivery. They must remain listed on
              the Marketplace until the Credit Line is repaid. The Borrower must
              list identical Products and/or Services without discrepancies in
              quality, quantity, rate, or merchantability. If inventory is
              exhausted, BXI may select alternative Products to recover the
              Credit Line. Failure to sell or dispatch designated Products
              constitutes an Event of Default.
            </Typography>
            <Typography sx={credittermstext}>
              {" "}
              <b> 6. Use of Barter Coins:</b> That Borrower shall use these
              Barter Coins to purchase products and services available on the
              marketplace. The borrower is aware that Barter coins are not
              Reward Points; the same cannot be transferred to other members of
              the Platform without a transaction and Barter coins cannot be used
              to pay GST, BXI fees, and other charges. (Note: GST, BXI fees, and
              other charges shall be paid in Indian Currency INR)
            </Typography>
            <Typography sx={credittermstext}>
              {" "}
              <b> 7. Repayment of Barter Coins:</b> Members shall fulfil their
              obligation to repay their Barter Coins by engaging in the sale of
              products and services on the BXI Marketplace, which served as the
              basis for the issuance of their Credit Line.
            </Typography>
            <Typography sx={credittermstext}>
              <b> 8. Security:</b> Members can request a Barter Credit line.
              This Credit Line will be issued against the products and services
              which will be BXI’s security. The same products and services shall
              be listed by Borrower at the BXI Marketplace for sale to the
              interested Members.
            </Typography>
            <Typography sx={credittermstext}>
              <b> 9. Events of Default:</b> <br />
              The following events shall constitute events of default under the
              Credit Line: <br /> <br />
              <Typography sx={{ ...credittermstext, width: "97%", ml: "auto" }}>
                <li>
                  {" "}
                  Breach by the borrower of any other provision of the credit
                  line that is not capable of remedy, or that is capable of
                  remedy but is not remedied by the borrower immediately.{" "}
                </li>
                <br /> <br />{" "}
                <li>
                  {" "}
                  That if the sample of products and services are different from
                  the delivered products and services.
                </li>
                <br /> <br />{" "}
                <li>
                  {" "}
                  That if the quantity, quality and features of products and
                  services are not similar as per the description of the same.
                </li>
                <br /> <br />{" "}
                <li>
                  {" "}
                  That the products and services should not be near to
                  shelf-life and allotted products and services shall be from
                  your Fresh stock.
                </li>
                <br /> <br />{" "}
                <li>
                  {" "}
                  That if the products and services are not delivered during the
                  promised timelines.
                </li>
                <br /> <br />{" "}
                <li>
                  {" "}
                  That products and services can be availed by BXI at any time
                  within 18 months from the date of execution of this Agreement.
                </li>
                <br /> <br />{" "}
                <li>
                  {" "}
                  That no hidden charges shall be disclosed by the borrower at
                  the time of delivery of products and services.
                </li>
              </Typography>
            </Typography>
            <Typography sx={credittermstext}>
              <b> 10. Remedies on Default:</b> Upon the occurrence of an event
              of default, the lender shall be entitled to exercise any remedies
              available to it under Indian law or may take any other action
              necessary to protect its interest I.e., Immediate termination of
              BXI membership.{" "}
            </Typography>
            <Typography sx={credittermstext}>
              <b> 11. Governing Law and Jurisdiction:</b> This Credit Line
              Agreement for Barter Coins shall be governed by and construed in
              accordance with the laws of India. Any dispute arising out of or
              in connection with this agreement shall be submitted to the
              exclusive jurisdiction of the Courts in Mumbai.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default PrivateDealAgreement;

const credittermstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
};

const table = {
  borderLeft: "1px solid #7D8BA6",
  borderRight: "1px solid #7D8BA6",
  borderBottom: "1px solid #7D8BA6",
  borderTop: "1px solid #7D8BA6",
  textAlign: "center",
  fontFamily: "Poppins",
  color: "#7D8BA6",
  fontSize: "12px",
};
