import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import stackofcoin from "../../assets/CartPage/unnamed 1.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import {
  useRequestCredit,
  useRequestCreditMedia,
} from "./useGetAllCompanyProducts";
import { toast } from "react-toastify";
import { notifications } from "../../redux/action/Notification/notification";
import { useDispatch } from "react-redux";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import CommaSeprator from "../../components/CommaSeprator";

const Credit = () => {
  const location = useLocation();
  const [GetProductDataById, setGetProductDataById] = useState();
  const [RequestedUserAmount, setRequestedUserAmount] = useState("");
  const [Loader, setLoader] = useState(false);

  const navigate = useNavigate();
  let dispatch = useDispatch();

  const productId = location?.state?.ProductId;
  const CompanyId = location?.state?.CompanyId;
  const TotalAmount = location?.state?.total;
  const highestValue = location?.state?.highestValue;
  const CheckCompanyType = location?.state?.CheckCompanyType;

  const { data: loggedInUserData } = useGetLoggedInUser();

  const {
    data: ReqBalanceData,
    refetch: ReqBalRefetch,
    mutate: reqBalMutate,
  } = useRequestCredit();

  const {
    data: ReqBalanceMediaData,
    refetch: ReqBalMediaRefetch,
    mutate: reqBalMediaMutate,
  } = useRequestCreditMedia();

  const reqBal = async (ProductId) => {
    if (
      RequestedUserAmount === undefined ||
      RequestedUserAmount === "0" ||
      RequestedUserAmount === ""
    ) {
      return toast.error("Please Add Proper Amount in Field", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      if (CheckCompanyType === "Media") {
        setLoader(true);

        reqBalMediaMutate(
          {
            productId: ProductId,
            companyId: CompanyId,
            requestedUserAmount: RequestedUserAmount,
          },
          {
            onSuccess: () => {
              setTimeout(() => {
                navigate("/home/creditstatus");
                setLoader(false);
              }, [2000]);
            },
            onError: () => {},
          }
        );

        const AdminId = "650ac9c7b29820230639ab99";
        let receiver = AdminId;
        let sender = loggedInUserData?.data?._id;
        let message = `${loggedInUserData?.data?.companyName} company requested for credit issues`;
        let type = "Credit Request";
        dispatch(notifications(receiver, sender, message, type));
      } else {
        setLoader(true);
        reqBalMutate(
          {
            amount: 10,
            productId: ProductId,
            requestedUserAmount: RequestedUserAmount,
          },
          {
            onSuccess: () => {
              navigate("/home/creditstatus");
              setLoader(false);
            },
            onError: () => {},
          }
        );

        const AdminId = "650ac9c7b29820230639ab99";
        let receiver = AdminId;
        let sender = loggedInUserData?.data?._id;
        let message = `${loggedInUserData?.data?.companyName} company requested for credit issues`;
        let type = "Credit Request";
        dispatch(notifications(receiver, sender, message, type));
      }
    }
  };
  const credittoken =
    (Number(TotalAmount + GetProductDataById?.ManualPoint) / 120) * 100;

  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${productId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductDataById(res?.data);
      });
  }
  useEffect(() => {
    GetProductByid();
  }, []);
  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <BreadCrumbHeader
        MainText="Credit Application"
        LinkText1="{splitedurl[1]}"
        LinkText2="{splitedurl[2]}"
        link1="Link1"
        link2="link2"
        showbreadcrumb={true}
      />
      <Paper
        sx={{
          bgcolor: "#fff",
          boxShadow: "none",
          p: 3,
          borderRadius: "20px",
          height: "auto",
          minHeight: "520px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "30px",
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: "30%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100px",
              background: "#FFFFFF",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              //   border: "1px solid #EDEFF2",
              //   borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: {
                  xl: "700px",
                  lg: "700px",
                  md: "700px",
                  sm: "350px",
                  xs: "350px",
                },
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                gap: "2rem",
              }}
            >
              <Box
                sx={{
                  ...imgstyle,
                  backgroundImage:
                    GetProductDataById?.ListingType === "Voucher"
                      ? `url(${GetProductDataById?.VoucherImages?.at(0)?.url})`
                      : `url(${GetProductDataById?.ProductImages?.at(0)?.url})`,
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  alignContent: "start",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Typography sx={ProductNameTextStyle}>
                  {GetProductDataById?.ProductName}
                </Typography>
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Typography sx={ProductMetaTextStyle}>
                    {GetProductDataById?.ProductDescription}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...ProductPriceTextStyle,
                      marginTop: "-03px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <img
                      src={stackofcoin}
                      alt="rupieicon"
                      style={{
                        width: "20px",
                        height: "auto",
                      }}
                    />
                    <CommaSeprator
                      Price={
                        GetProductDataById?.ProductsVariantions?.at(0)
                          ?.DiscountedPrice
                      }
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {CheckCompanyType === "Media" ? null : (
            <Typography sx={{ ...amountstyle, textAlign: "center" }}>
              For this Product, Quantity & Price,
              <br />
              The Maximum Eligible Amount is{" "}
              <CommaSeprator
                Price={((highestValue * credittoken) / 100).toFixed()}
              />{" "}
              Barter Coins
            </Typography>
          )}
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "21px",
              textAlign: "center",
              color: "#6B7A99",
            }}
          >
            On application, BXI team shall connect further for Sample &
            Additional Information.
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "21px",
              textAlign: "center",
              color: "#6B7A99",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              py: "10px",
            }}
          >
            To apply for a Different amount, than the Eligible amount. Please
            enter the value here :
            <TextField
              type="Number"
              min={0}
              InputProps={{
                inputProps: { min: 0 },
                disableUnderline: "true",
                style: {
                  color: "#445FD2",
                  fontSize: "14px",
                  height: "40px",
                  width: "150px",
                  borderRadius: "10px",
                },
              }}
              value={RequestedUserAmount}
              placeholder="Eg. 100"
              onChange={(event) => setRequestedUserAmount(event.target.value)}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "30px",
              color: "#6B7A99",
            }}
          >
            Do you wish to Apply?
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {Loader ? (
              <Button variant="contained" sx={reqbtn}>
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={20}
                />
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={reqbtn}
                onClick={() => {
                  reqBal(productId);
                }}
              >
                Yes
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                ...reqbtn,
                fontWeight: 700,
                background: "none",
                border: "0.7px solid #EBEDEE",
                color: "#445FD2",
              }}
              onClick={() => {
                navigate("/home/dashboard");
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Paper>
    </Paper>
  );
};

export default Credit;

const reqbtn = {
  width: "114px",
  height: "42px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "none",
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  color: "#717171",
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "15px",
    sm: "12px",
    xs: "12px",
  },
  lineHeight: {
    xl: "24px",
    lg: "24px",
    md: "22px",
    sm: "21px",
    xs: "20px",
  },
  alignContent: "center",
  alignItems: "center",
};

const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const imgstyle = {
  ml: "1%",
  height: "100px",
  width: "150px",
  border: "1px solid #EDEFF2",
  borderRadius: "20px",
  maxHeight: "122px",
  minWidth: "150px",
  maxWidth: "150px",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const amountstyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "30px",
  color: "#6B7A99",
};
