import axios from "axios";
import { useMutation, useQuery } from "react-query";

/// crate a hook for send message on whatsapp using react-query

export const useSendMessageOnWhatsApp = () => {
  const { data, isLoading, isError, error, mutate } = useMutation(
    async ({
      template_name,
      phoneNumber,
      parameterName,
      parameterValue,
      parameterName1,
      parameterValue1,
      seller_name,
    }) => {
      try {
        const { data } = await axios.post(
          `/WhatApp/sendTempletMessageOnWhatApp?phoneNumber=${phoneNumber}&template_name=${template_name}`,
          {
            parameterName,
            parameterValue,
            parameterName1,
            parameterValue1,
            seller_name,
          }
        );
        return data;
      } catch (error) {
        throw error;
      }
    }
  );

  return { data, isLoading, isError, error, mutate };
};
