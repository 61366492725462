import React from "react";
import { Grid, Paper, Skeleton } from "@mui/material";

const SkeletonComponent = () => {
  return (
    <Grid
      sx={{
        width: "98%",
        mx: "auto",
      }}
      item
      xl={3}
      lg={3}
      md={4}
      sm={6}
      xs={12}
    >
      <Paper
        elevation={0}
        sx={{
          bgcolor: "transparent",
          borderRadius: "20px",
          maxWidth: "100%",
          mx: "auto",
        }}
      >
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          sx={{
            borderRadius: "10px",
            mt: 3,
            width: "100%",
            bgcolor: "#edf1f5",
            spacing: "10px",
            mx: "auto",
          }}
        />
      </Paper>
    </Grid>
  );
};

export default SkeletonComponent;
