import { Box, Typography } from "@mui/material";
import React from "react";

const PickUplocations = (props) => {
  return (
    <Box>
      <Typography
        sx={{
          ...product,
          // lineHeight: "40px",
          fontSize: "1.7rem",
          fontFamily: "Poppins",
          fontWeight: 600,
          color: "#156DB6",
        }}
      >
        Product Pickup Location & Pincode
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "50px",
          mt: 0.5,
        }}
      >
        <Box>
          <Typography
            sx={{
              ...available,
              width: "100%",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "25px",
              color: "grey",
            }}
          >
            Region
          </Typography>
          <Typography
            sx={{
              ...available,
              lineHeight: "20px",
              fontWeight: 500,
              fontSize: "14px",
              color: "#6B7A99",
              width: "100%",
            }}
          >
            {props?.data?.LocationDetails?.region}
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 7,
          }}
        >
          <Typography
            sx={{
              ...available,
              width: "100%",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "25px",
              color: "grey",
            }}
          >
            State
          </Typography>
          <Typography
            sx={{
              ...available,
              lineHeight: "20px",
              fontWeight: 500,
              fontSize: "14px",
              color: "#6B7A99",
              width: "100%",
            }}
          >
            {props?.data?.LocationDetails?.state}
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 7,
          }}
        >
          <Typography
            sx={{
              ...available,
              width: "100%",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "25px",
              color: "grey",
            }}
          >
            City
          </Typography>
          <Typography
            sx={{
              ...available,
              lineHeight: "20px",
              fontWeight: 500,
              fontSize: "14px",
              color: "#6B7A99",
              width: "100%",
            }}
          >
            {props?.data?.LocationDetails?.city}
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 7,
          }}
        >
          <Typography
            sx={{
              ...available,
              width: "100%",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "25px",
              color: "grey",
            }}
          >
            Landmark
          </Typography>
          <Typography
            sx={{
              ...available,
              lineHeight: "20px",
              fontWeight: 500,
              fontSize: "14px",
              color: "#6B7A99",
              width: "100%",
            }}
          >
            {props?.data?.LocationDetails?.landmark}
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 7,
          }}
        >
          <Typography
            sx={{
              ...available,
              width: "100%",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "25px",
              color: "grey",
            }}
          >
            Pincode
          </Typography>

          <Typography
            sx={{
              ...available,
              lineHeight: "20px",
              fontWeight: 500,
              fontSize: "14px",
              color: "#6B7A99",
              width: "100%",
            }}
          >
            {props?.data?.LocationDetails?.pincode}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PickUplocations;

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "1.6rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#6B7A99",
};
