import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { z } from 'zod';
import OtpInput from 'react-otp-input';
import MainLogo from './MainLogo.jsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOtp, useVerifyresetpassOtp } from '../../Hooks/Auth';
import mainImg from '../../assets/Images/register/forgetpsotp.svg';
import emailIcon from '../../assets/Images/OnBoardingPages/emaiIcon.svg';
import * as StlyesOnBoarding from '../../components/common/Styled/StylesOnBoarding.js';

const Otp = () => {
  const [otp, setOtp] = useState('');
  const Location = useLocation();
  const email = Location?.state?.email;
  const navigate = useNavigate();
  const { mutate, isLoading } = useOtp();

  // here we cant type otp
  const handleChange = (enteredOtp) => {
    const combinedOtp = enteredOtp.split('').join('');
    setOtp(combinedOtp);
  };
  // post verfiOtp api
  const {
    mutate: VerifyOtpMutate,
    isLoading: verifyingOtpLoading,
  } = useVerifyresetpassOtp();
  // use useForm
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
    },
    resolver: zodResolver(z.object({})),
  });

  // Timer
  const [seconds, setSeconds] = useState(600);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // send otp and naviagate to resetpassword
  const submitOtp = handleSubmit((data) => {
    if (otp?.length < 4) {
      setError('otp', {
        type: 'manual',
        message: 'Please Enter Valid OTP of 4 digits',
      });
    } else {
      VerifyOtpMutate(
        {
          email: email,
          resetpassotp: otp,
        },
        {
          onSuccess: (res) => {
            toast.success(
              res?.data?.message ??
                'Otp verified successfully, Please reset your password',
              {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              }
            );
            setTimeout(() => {
              navigate(`/resetpassword`, {
                state: {
                  email: email,
                  resetpassotp: otp,
                },
              });
            }, 1000);
            // }
          },
          onError: (err) => {
            if (err.response.status === 401) {
              setError('otp', {
                type: 'manual',
                message: err.response.data.message,
              });
            }
          },
        }
      );
    }
  });

  return (
    <>
      <form onSubmit={submitOtp}>
        <ToastContainer style={{ fontSize: '16px' }} />
        <Paper elevation={0} sx={StlyesOnBoarding.mainCommonPage}>
          <Grid
            container
            sx={{
              background: '#fff',
              height: '100vh',
              width: {
                xl: '110%',
                lg: '110%',
                md: '110%',
                sm: '100%',
                xs: '100%',
              },
            }}
          >
            {/* detail Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={StlyesOnBoarding.otpGrid}
            >
              <Grid
                container
                sx={{
                  height: {
                    xl: '20%',
                    lg: '20%',
                    md: '20%',
                    sm: '10%',
                    xs: '10%',
                  },
                }}
              >
                <MainLogo />
              </Grid>
              <Box component={'img'} src={emailIcon}></Box>
              <Typography sx={StlyesOnBoarding.HeadText}>
                OTP Verification
              </Typography>
              <Typography sx={metaTextStyle}>
                A One-Time password has been sent to{' '}
              </Typography>
              <Typography sx={metaEmailTextStyle}>{email}</Typography>

              <Box
                mt={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  width: '75%',
                  gap: '5px',
                  maxWidth: '450px',
                }}
              >
                {/* otp input field */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: {
                      xl: '50%',
                      lg: '50%',
                      md: '50%',
                      sm: '80%',
                      xs: '80%',
                    },
                    mx: 'auto',
                  }}
                >
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum={true}
                    containerStyle={{
                      gap: '2rem',
                    }}
                    separateAfter={false}
                    onSubmit={console.log(otp)}
                    inputStyle={StlyesOnBoarding.otpInputStyle}
                  />
                </Box>
                <Typography sx={StlyesOnBoarding.ErrTypo}>
                  {errors['otp']?.message}
                </Typography>

                {/* Buttons next  */}
                <Grid
                  container
                  mt={3}
                  sx={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={StlyesOnBoarding.CommonBtn}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={'20px'}
                        sx={{ color: 'white', width: '50%' }}
                      />
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Grid>
              </Box>
            </Grid>
            {/* image Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              <img
                src={mainImg}
                alt="img"
                style={StlyesOnBoarding.CommonImgStyle2}
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
};

export default Otp;

const metaTextStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: { xl: '15px', lg: '15px', md: '15px', sm: '12px', xs: '12px' },
  lineHeight: { xl: '21px', lg: '21px', md: '21px', sm: '16px', xs: '16px' },
  textAlign: 'center',
  color: '#6B7A99',
  marginTop: '2rem',
  marginBottom: '0.5rem',
};
const metaEmailTextStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: { xl: '15px', lg: '15px', md: '15px', sm: '12px', xs: '12px' },
  lineHeight: { xl: '21px', lg: '21px', md: '21px', sm: '16px', xs: '16px' },
  textAlign: 'center',
  color: '#375DBB',
};
