const ButtonStyle = {
    LoadingButtonStyle: {
        width: "100%",
        height: "100%",
        background: "#445FD2",
        borderRadius: "10px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
        textTransform: "none",
    },
    ViewOrderDetails: {
        width: "100%",
        height: "100%",
        background: "#445FD2",
        borderRadius: "10px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
        textTransform: "none",
    },
    BuyNowButtonStyle: {
        width: "100%",
        height: "100%",
        background: "#445FD2",
        borderRadius: "10px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#FFFFFF",
        textTransform: "none",
    }
};


module.exports = ButtonStyle;