/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdatePurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import LeftArrowIcon from "../../assets/Images/CommonImages/GoLeft.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import radiouncheck from "../../assets/Dashboard/radiouncheck.svg";
import radiocheck from "../../assets/Dashboard/radiocheck.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { getCompanyById } from "../../redux/action/CompanyActions";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import StateData from "../../utils/StateCityArray.json";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import TostMessagesTop from "../../Component/Toast";
import { CircularProgress } from "@material-ui/core";
import NumberToWord from "../../components/NumberToWord";
import { MdKeyboardBackspace } from "react-icons/md";
import CommaSeprator from "../../components/CommaSeprator";
import sendEvents from "../../utils/sendEvents.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import { useGetInvoiceByOrderSummary } from "../../Hooks/Invoices/useGetInvoiceByOrderSummary";
import PageLoader from "../../components/LoadingButton/PageLoader";
import axios from "axios";
import HtmlToPdfConverter from "../../utils/HtmlToPdfConverter.js";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import { useGetAllCompanyProducts } from "../../views/Dashboard/useGetAllCompanyProducts.js";
import OrderProgressBar from "../../components/ProgressBar/OrderProgressBar.jsx";
import PrivateDealAgreement from "../../components/PrivateDealAgrrement.jsx";
import html2pdf from "html2pdf.js";
import { usePDF } from "react-to-pdf";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "auto",
  maxHeight: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const PurchaseOrderDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState("");
  const [CouponCode, setCouponCode] = useState();
  const [CouponCodeData, setCouponCodeData] = useState();
  const [city, setCity] = useState("");
  const [stateArray, setStateArray] = useState();
  const [Address, setAddress] = useState("");
  const [Area, setArea] = useState("");
  const [state, setState] = useState("");
  const [BuyerShippingAddress, setBuyerShippingAddress] = useState();
  const [CityArray, setCityArray] = useState();
  const [checked, setChecked] = useState(false);
  const [Codechecked, setCodeChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [balance, setBalance] = useState("");
  const [ProfilePercentage, setProfilePercentage] = useState(0);
  const [ProductId, setProductId] = useState();
  const [ProductData, setProductData] = useState();
  const [openaddressmodal, setOpenAddressModal] = useState(false);
  const [buyerHubType, setBuyerHubType] = useState();
  const [SelectedAddress, setSelectedAddress] = useState({});
  const [AddressToMap, setAddressToMap] = useState();
  const [StoreOrderId, setStoreOrderId] = useState();
  const [StoreOrderSummaryData, setStoreOrderSummaryData] = useState();
  const [agreecheck, setagreeCheck] = useState(false);

  const handleOpenAddress = () => {
    setOpenAddressModal(true);
  };

  const handleClose = () => {
    setOpenAddressModal(false);
    setSelectedAddress();
  };

  const handleChangeDeliveryAddress = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleCouponCheckChange = (event) => {
    setCodeChecked(event.target.checked);
  };

  useEffect(() => {
    setBuyerShippingAddress({
      PinCode: pincode,
      City: city,
      State: state,
      Address: Address,
    });
  }, [pincode, city, state, Address, Area]);

  const { OrderSummary: OrderSummarydata, loading: OrderSummaryDataLoading } =
    useSelector((state) => state.OrderSummaryD);

  const { data: CompaniesProducts, refetch: refetch } =
    useGetAllCompanyProducts();

  useEffect(() => {
    dispatch(getCompanyById(OrderSummarydata?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  async function GetOrderByInvoice(id) {
    await axios.get(`order/get_order_by_orderSummaryId/${id}`).then((res) => {
      setStoreOrderId(res?.data?._id);
      setStoreOrderSummaryData(res?.data);
    });
  }
  useEffect(() => {
    GetOrderByInvoice(id);
  }, []);

  const { data: InvoiceData, loading: InvoiceLoading } =
    useGetInvoiceByOrderSummary(OrderSummarydata?._id);

  async function GetDeliveryData() {
    await axios.get(`api/v1/signzy-data/gst-from-pan`).then((res) => {
      setAddressToMap(res.data);
    });
  }

  const calculateSums = (productVariations) => {
    let totalValueSum = 0;
    let maxOrderQtySum = 0;

    productVariations?.forEach((item) => {
      const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

      totalValueSum += currentValue;
      maxOrderQtySum += item?.MaxOrderQuantity;
    });

    return {
      totalValueSum,
      maxOrderQtySum,
    };
  };

  let storeDataIds = [];
  let TotalQuantity = 0;
  let totalAmount = 0;
  let totalPricePerUnit = 0;
  let totatlTaxableAmount = 0;
  let totalGST = 0;
  let totalAmountWithGST = 0;
  let totalAmountWithTax = 0;
  let totaltaxvalue = 0;
  let totalCGSTAmount = 0;
  let totalIGSTPercentage = 0;
  let totalSGSTAmount = 0;
  let totalSGSTPercentage = 0;
  let totalCSTPerCentage = 0;
  let TotalGSTAmount = 0;
  let TotalTokanAmount = 0;

  let totaladditionalcostinrupee = 0;
  let totaladditionalcostinbxi = 0;
  OrderSummarydata?.ProductData?.map((item) => {
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
    totalAmount +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalPricePerUnit += item.PricePerUnitWithoutGST;
    TotalTokanAmount +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item?.PriceWithoutGST;

    totatlTaxableAmount +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalGST += item.GST;
    totalAmountWithGST += (
      item?.DiscountedPrice *
      item?.ProductQuantity *
      (item?.GST / 100)
    )?.toFixed(2);
    totalAmountWithTax +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds *
            (item?.GST / 100) +
          item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds
        : item?.TotalPriceWithGSTInRupee;

    totalCGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTPercentage += item.GST / 2;
    totalCSTPerCentage += item.GST / 2;
    totalIGSTPercentage += item.GST;
    TotalGSTAmount += item?.TotalGSTInBXI;
    totaltaxvalue += item?.TotalGSTInBXI;
  });

  const {
    data: updatePurchaseOrderData,
    isLoading: updatePurchaseOrderLoading,
    error: updatePurchaseOrderError,
    mutate: updatePurchaseOrderMutate,
  } = useUpdatePurchaseOrder();

  // logged user
  const { data: IAMUserData } = useGetAuthUser();

  // here we GET wallet balance
  const reqBalance = async () => {
    await axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.body);
      });

    await axios
      .get(
        "auth/profilecompletion",
        {
          withCredentials: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let findPercentage =
          typeof res?.data?.ProfileMessage === "string"
            ? res?.data?.ProfileMessage.split(" ")
            : null;

        let finalPercentage =
          findPercentage && findPercentage.length >= 4
            ? findPercentage[3]?.toString()?.split(".")
            : null;
        setProfilePercentage(finalPercentage[0]);
      });
  };

  async function GetAllCoupons() {
    if (!CouponCode) {
      toast.error("Please enter a coupon code", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await axios.post("private_deal/validate_coupon", {
          SellerCompanyId: OrderSummarydata?.SellerDetails?.SellerCompanyId,
          BuyercompanyId: OrderSummarydata?.BuyerDetails?.BuyerCompanyId,
          CouponCode: CouponCode,
          Token: OrderSummarydata?.POTotals?.TotalCoinsWithAdditionalCost,
        });
        response?.data?.message?.includes("is Valid")
          ? toast.success(response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            })
          : toast.error(response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            });
        setCouponCodeData(response?.data?.data?.at(0));
        if (response?.data?.data?.at(0)?.OnetoMany) {
          setOpen(true);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  }

  async function UpdateInstantBalance(CouponCode) {
    if (!CouponCode) {
      toast.error("Please enter a coupon code", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await axios.put("wallet/update_instant_balance", {
          OrderId: StoreOrderId,
          CouponId: CouponCode?._id,
          balance: Number(CouponCode?.TokenAmount),
          OnetoMany: CouponCode?.OnetoMany,
        });
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  }

  async function UpdateProductReadyStock() {
    try {
      await axios.put("product/product_ready_stock_update", {
        id: ProductId,
      });
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  async function UpdateCouponAgreements() {
    try {
      const response = await axios.put(
        `/private_deal/update_agreements/${CouponCodeData?._id}`,
        {
          Order_Id: StoreOrderSummaryData?._id,
          ReadyStock_Product: ProductData,
          Order_Number: StoreOrderSummaryData?.OrderId,
        }
      );
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  useEffect(() => {
    reqBalance();
    const userHubData = localStorage.getItem("userhubtype");
    setBuyerHubType(userHubData);
  }, []);

  async function mutatePurchaseOrder() {
    const showToast = (message) => {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
      });
    };

    const validateBuyerShippingAddress = (address) => {
      return (
        address?.PinCode && address?.City && address?.State && address?.Address
      );
    };

    const updatePurchaseOrder = async ({
      address,
      invoiceUrl,
      BillingAddress,
    }) => {
      await updatePurchaseOrderMutate({
        status: "Accepted",
        OrderSummaryId: id,
        CouponData: CouponCodeData,
        IsReadyStockProduct: ProductId,
        BuyerShippingAddress: address || "",
        BillingAddress: BillingAddress ? BillingAddress : {},
        InvoiceUrl: invoiceUrl?.data?.file,
      });
    };

    if (
      AddressToMap?.data?.GSTAddresses?.length > 0 &&
      SelectedAddress === undefined
    ) {
      showToast("Please select address");
      return;
    }

    if (SelectedAddress) {
      const userConfirmed = window.confirm(
        "Are you sure you want to use this billing address?\nPlease confirm that the details are correct before proceeding.\nClick 'OK' to confirm or 'Cancel' to abort."
      );
      if (!userConfirmed) {
        handleClose();
        return;
      }
    }

    let Data = AddressToMap?.data?.GSTAddresses?.filter(
      (item) => item?.GST === SelectedAddress
    )?.at(0);

    if (CouponCodeData?._id) {
      if (checked) {
        if (!validateBuyerShippingAddress(BuyerShippingAddress)) {
          showToast("Please fill all the fields");
          return;
        }
      }

      let DataOfS3 = await HtmlToPdfConverter(componentRef.current);
      await updatePurchaseOrder({
        address: checked ? BuyerShippingAddress : "",
        invoiceUrl: DataOfS3,
        BillingAddress: {},
      });
      UpdateInstantBalance(CouponCodeData);

      if (CouponCodeData?.OnetoMany) {
        UpdateProductReadyStock();
        if (ProductData) {
          UpdateCouponAgreements();
        }
      }
    } else if (!balance?.balance) {
      showToast("Your Wallet balance is low");
    } else {
      const grandTotal = Number(
        OrderSummarydata?.POTotals?.TotalCoinsWithAdditionalCost
      );

      const totalCost = grandTotal;

      if (IAMUserData?.data?.superAdmin && balance?.balance < totalCost) {
        showToast("Your Wallet balance is low");
      } else if (
        grandTotal > IAMUserData?.data?.tokenlimit &&
        !IAMUserData?.data?.superAdmin
      ) {
        showToast("You do not have permission to Burn that much token");
      } else {
        if (checked) {
          if (!validateBuyerShippingAddress(BuyerShippingAddress)) {
            showToast("Please fill all the fields");
            return;
          }
        }
        let DataOfS3 = await HtmlToPdfConverter(componentRef.current);
        await updatePurchaseOrder({
          address: checked ? BuyerShippingAddress : "",
          invoiceUrl: DataOfS3,
          BillingAddress: {
            PinCode: Data?.data?.Pincode,
            City: Data?.data?.District,
            State: Data?.data?.State,
            AddressLine: Data?.data?.Address,
            District: Data?.data?.District,
            Country: Data?.data?.Country,
            GSTIN: Data?.GST,
          },
        });
      }
    }
  }

  useEffect(() => {
    if (updatePurchaseOrderData?.status === "Accepted") {
      toast.success("Purchase Order is issued", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/home/productpilist01");
      }, 2000);
    } else if (updatePurchaseOrderData?.status === "Rejected") {
      toast.error("Purchase Order is Declined", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/home/purchaseorderlist");
      }, 2000);
    }
  }, [dispatch, updatePurchaseOrderData]);

  async function mutatePurchaseOrderRejected() {
    const confirmed = window.confirm(
      "Are you sure you want to reject this order?"
    );
    if (confirmed) {
      await updatePurchaseOrderMutate({
        status: "Rejected",
        OrderSummaryId: id,
        BuyerShippingAddress: "",
      });
    }
  }

  const printRef = useRef();

  const downloadRef = useRef(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  const handleDownloadTokensFlow = () => {
    setTimeout(() => {
      html2canvas(document.body)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width + 20;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );

          const noteText = "";
          const noteX = 10;
          const noteY = pdf.internal.pageSize.getHeight() - 160;
          const noteFontSize = 8;
          const noteColor = "black";

          pdf.setFontSize(noteFontSize);
          pdf.setTextColor(noteColor);
          pdf.text(noteText, noteX, noteY);

          const pdfBlob = pdf.output("blob");
          const formData = new FormData();
          formData.append("file", pdfBlob, "PO-Generated.pdf");
          const fetchData = async () => {
            try {
              await axios.post("support/SendPdfOnEmail", formData, {
                withCredentials: true,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            } catch (error) {}
          };

          fetchData();
        })
        .catch((error) => {});
    }, 1000);
  };

  useEffect(() => {
    if (stateArray) {
      let stateData = StateData?.filter((item) => item?.name === stateArray);
      setCityArray(stateData[0]?.data);
    }
  }, [stateArray]);

  OrderSummarydata?.ProductData?.map((item) => {
    if (item?.AdditionCostArray?.length > 0) {
      item?.AdditionCostArray.forEach((item) => {
        if (item?.currencyType === "₹") {
          totaladditionalcostinrupee += item?.TotalWithGst;
        } else if (item?.currencyType === "BXITokens") {
          totaladditionalcostinbxi += item?.TotalWithGst;
        }
      });
    }
  });

  useEffect(() => {
    GetDeliveryData();
  }, []);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getDate()),
      pad(d.getMonth() + 1), // Month (adding 1 to convert to 1-indexed)
      d.getFullYear(), // Year
    ].join("/");
  }

  if (!OrderSummaryDataLoading) {
    <PageLoader />;
  }

  return (
    <Box
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader
        MainText={props?.Title || "Purchase Order"}
        PageName={props?.PageName || "Purchase order"}
        showbreadcrumb={true}
      />

      <OrderProgressBar type={"PurchaseOrder"} Data={OrderSummarydata} />

      {InvoiceLoading ? (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Skeleton
              variant="round"
              width={"650px"}
              height={"200px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
              }}
            />

            <Skeleton
              variant="round"
              width={"650px"}
              height={"200px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
            <Skeleton
              variant="round"
              width={"650px"}
              height={"200px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rectangular"
              width={"1350px"}
              height={"30px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "2px",
              }}
            />
            <Skeleton
              variant="rectangular"
              width={"1350px"}
              height={"60px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "2px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Skeleton
              variant="round"
              width={"700px"}
              height={"200px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
              }}
            />

            <Skeleton
              variant="round"
              width={"700px"}
              height={"200px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="rectangular"
              width={"1350px"}
              height={"60px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "2px",
              }}
            />

            <Skeleton
              variant="rectangular"
              width={"1350px"}
              height={"60px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "2px",
              }}
            />
          </Box>
        </div>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              bgcolor: "white",
              mx: "auto",
              borderRadius: "17px",
              pb: "40px",
            }}
            elevation={1}
          >
            <Paper
              sx={{
                width: "95%",
                mx: "auto",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
              }}
              elevation={0}
            >
              <Box
                component="img"
                src={LeftArrowIcon}
                sx={{ width: "25px", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                  sendEvents("Click");
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  width: "80px",
                  justifyContent: "space-evenly",
                }}
              >
                <Box
                  component="img"
                  src={PrintPurchaseOrder}
                  sx={{ width: "22px", height: "auto", cursor: "pointer" }}
                  onClick={handlePrint}
                />
                <Box
                  component="img"
                  src={DocDownloadImg}
                  sx={{ width: "21px", height: "auto", cursor: "pointer" }}
                  onClick={() => toPDF()}
                />
              </Box>
            </Paper>
            <Box ref={componentRef}>
              <Box
                sx={{
                  width: "95%",
                  mx: "auto",
                  borderLeft: "1px solid #cdcdcd",
                  borderRight: "1px solid #cdcdcd",
                  borderBottom: "1px solid #cdcdcd",
                  borderTop: "1px solid #cdcdcd",
                  px: "0px",
                }}
                ref={targetRef}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "auto",
                    mx: "auto",
                  }}
                  ref={downloadRef}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "130px",
                      width: "30%",
                    }}
                  >
                    <img
                      src={OrderSummarydata?.BuyerDetails?.BuyerCompanyLogo}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderLeft: "1px solid #cdcdcd",
                      height: "130px",
                      width: "40%",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        color: "rgba(107, 122, 153, 1)",
                        opacity: 1,
                        fontWeight: 600,
                      }}
                    >
                      {OrderSummarydata?.BuyerDetails?.BuyerCompanyName}
                    </Typography>
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        textAlign: "center",
                        fontSize: "11px",
                        color: "rgba(107, 122, 153, 1)",
                        opacity: 1,
                        fontWeight: 600,
                      }}
                    >
                      {OrderSummarydata?.BuyerDetails?.BuyerCompanyAddress}
                    </Typography>
                    <Typography
                      sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}
                    >
                      Tel:{OrderSummarydata?.BuyerDetails?.BuyerCompanyContact}
                    </Typography>
                    <Typography
                      sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}
                    >
                      GSTIN:{OrderSummarydata?.BuyerDetails?.GSTIN}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "130px",
                      width: "30%",
                      borderLeft: "1px solid #cdcdcd",
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "42px",
                    mx: "auto",
                    background: props?.SellerPage ? "#c73e91" : "#2261A2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    {props?.PageName || "Purchase order"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mx: "auto",
                    height: "40px",
                    borderRight: "1px solid #cdcdcd",
                    borderLeft: "1px solid #cdcdcd",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      borderRight: "1px solid #F3F2F3",
                      pl: 1,
                    }}
                  >
                    {props?.ProformaInvoice ? (
                      <Typography sx={CommongTextStyle}>
                        PI Number: {InvoiceData?.PINumber}
                      </Typography>
                    ) : (
                      <Typography sx={CommongTextStyle}>
                        PO Number: {OrderSummarydata?.PoNumber}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      pl: 1,
                    }}
                  >
                    {props?.ProformaInvoice ? (
                      <Typography sx={CommongTextStyle}>
                        PI Date:{" "}
                        {new Date(InvoiceData?.createdAt).toLocaleDateString()}
                      </Typography>
                    ) : (
                      <Typography sx={CommongTextStyle}>
                        PO Date:{" "}
                        {convertDate(new Date(OrderSummarydata?.PoDate))}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    border: "1px solid #cdcdcd",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "21px",
                      background: props?.SellerPage ? "#c73e91" : "#2261A2",
                      borderRadius: "3px 0px 0px 3",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        height: "100%",
                        borderRight: "1px solid #F3F2F3",
                      }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          fontSize: "12px",
                          color: "white",
                        }}
                      >
                        Buyer Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        height: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          fontSize: "12px",
                        }}
                      >
                        Seller Details
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",

                      alignItems: "flex-start",

                      width: "100%",
                      py: 0,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                        p: 2,
                      }}
                    >
                      <Table sx={{ p: 0 }}>
                        <TableBody sx={{ p: 0 }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                                width: "90px",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                Buyer Name
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {" "}
                                {
                                  OrderSummarydata?.BuyerDetails
                                    ?.BuyerCompanyName
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                Address:{" "}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {
                                  OrderSummarydata?.BuyerDetails?.Address
                                    ?.AddressLine
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                GSTIN:{" "}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {OrderSummarydata?.BuyerDetails?.GSTIN}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                State:
                              </Typography>{" "}
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                                position: "relative",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {" "}
                                {OrderSummarydata?.BuyerDetails?.Address?.State}
                              </Typography>{" "}
                              <Typography
                                sx={{
                                  ...TextStyleTwo,
                                  textAlign: "right",
                                  position: "absolute",
                                  right: "0px",
                                  top: "0px",
                                }}
                              >
                                Code:{" "}
                                {OrderSummarydata?.BuyerDetails?.StateCode}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                CUID:{" "}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {OrderSummarydata?.Buyer_CUID}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    <Box sx={{ width: "100%", mx: "auto", p: 2 }}>
                      <Table>
                        <TableBody>
                          <TableRow sx={{ p: 0 }}>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                                width: "90px",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                Seller Name
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {
                                  OrderSummarydata?.SellerDetails
                                    ?.SellerCompanyName
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                Address:{" "}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {
                                  OrderSummarydata?.SellerDetails?.Address
                                    ?.AddressLine
                                }
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                GSTIN:{" "}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {OrderSummarydata?.SellerDetails?.GSTIN}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                State:{" "}
                              </Typography>{" "}
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                position: "relative",
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {
                                  OrderSummarydata?.SellerDetails?.Address
                                    ?.State
                                }
                              </Typography>
                              <Typography
                                sx={{
                                  ...TextStyleTwo,
                                  textAlign: "right",
                                  position: "absolute",
                                  right: "0px",
                                  top: "0px",
                                }}
                              >
                                Code:{" "}
                                {OrderSummarydata?.SellerDetails?.StateCode}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTitle}>
                                {" "}
                                CUID:{" "}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                p: 0.5,
                                borderBottom: "none",
                              }}
                            >
                              <Typography sx={TextStyleTwo}>
                                {OrderSummarydata?.Seller_CUID}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Box>
                </Box>
                {OrderSummarydata?.BuyerRequestedAddress ? (
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "21px",
                        background: props?.SellerPage ? "#c73e91" : "#2261A2",
                        borderRadius: "3px 0px 0px 3",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                          borderRight: "1px solid #F3F2F3",
                        }}
                      >
                        <Typography
                          sx={{
                            ...CommongTextStyle,
                            fontSize: "10px",
                            color: "white",
                          }}
                        >
                          Buyer Ship To / Delivery Location Details Below
                        </Typography>
                      </Box>
                    </Box>

                    <Table
                      sx={{
                        p: 0,
                        border: "1px solid #cdcdcd",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        borderRadius: "0px 0px 10px 10px",
                        width: "100%",
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={AddressTextStyleTitle}>
                              {" "}
                              State:{" "}
                              {OrderSummarydata?.BuyerRequestedAddress?.State}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={AddressTextStyleTitle}>
                              {" "}
                              City:{" "}
                              {OrderSummarydata?.BuyerRequestedAddress?.City}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={AddressTextStyleTitle}>
                              {" "}
                              Pincode:{" "}
                              {OrderSummarydata?.BuyerRequestedAddress?.PinCode}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "auto",
                              maxWidth: "300px",
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={AddressTextStyleTitle}>
                              {" "}
                              Address:{" "}
                              {OrderSummarydata?.BuyerRequestedAddress?.Address}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                ) : null}
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "100%",
                    mx: "auto",
                  }}
                >
                  <Table
                    sx={{ minWidth: "700px" }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          height: "42px",
                          bgcolor: props?.SellerPage ? "#c73e91" : "#2261A2",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            width: "20px",
                            maxWidth: "20px",
                          }}
                        >
                          <Typography sx={TableTextStyle}>No.</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={2}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            width: "10px",
                            maxWidth: "10px",
                          }}
                        >
                          <Typography sx={TableTextStyle}>
                            Product / Service Description
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            width: "10px",
                            maxWidth: "10px",
                          }}
                        >
                          <Typography sx={TableTextStyle}>HSN</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography sx={TableTextStyle}>QTY</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography sx={TableTextStyle}>Rate</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography sx={TableTextStyle}>Amount</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            width: "50px",
                            maxWidth: "50px",
                          }}
                        >
                          <Typography sx={TableTextStyle}>
                            Taxable Value
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "200px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "150px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "35px",
                                }}
                              >
                                <Typography sx={TableTextStyle}>
                                  IGST
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "150px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "35px",
                                }}
                              >
                                <Typography sx={TableTextStyle}>
                                  CGST
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "150px",
                                  p: 0,
                                  height: "35px",
                                }}
                              >
                                <Typography sx={TableTextStyle}>
                                  SGST
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}

                          {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "35px",
                                  width: "25%",
                                }}
                              >
                                {" "}
                                <Typography sx={TableTextStyle}>%</Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "25%",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "35px",
                                }}
                              >
                                {" "}
                                <Typography sx={TableTextStyle}>Rs.</Typography>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "35px",
                                  width: "25%",
                                }}
                              >
                                {" "}
                                <Typography sx={TableTextStyle}>%</Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "25%",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "35px",
                                }}
                              >
                                {" "}
                                <Typography sx={TableTextStyle}>Rs.</Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "25%",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "100%",
                                }}
                              >
                                {" "}
                                <Typography sx={TableTextStyle}>%</Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ p: 0, height: "100%", width: "25%" }}
                              >
                                {" "}
                                <Typography sx={TableTextStyle}>Rs.</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            background: props?.SellerPage
                              ? "#c73e91"
                              : "#2261A2",
                            width: "170px",
                            borderLeft: "1px solid #CDCDCD",
                          }}
                        >
                          <Typography sx={TableTextStyle}>Total</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {OrderSummarydata?.ProductData?.map((row, idx) => {
                        let TotalSec = row?.TotalSec;

                        return (
                          <React.Fragment key={idx}>
                            <TableRow
                              sx={{
                                height: "42px",
                                backgroundColor: "#F7F7F7",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {idx + 1}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    fontSize: "14px",
                                    width: "300px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {row?.ProductName}
                                </Typography>

                                {row?.TotalSec && (
                                  <Typography>
                                    {row?.ProductTypeId === "MediaOffline" ||
                                    row?.ProductTypeId ===
                                      "News Papers / Magazines"
                                      ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                      : `(${TotalSec} sec) (${row?.TimelineToBought} ${row?.Timeline})`}
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {row?.HSN}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={row?.ProductQuantity} />
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={
                                      row?.ProductTypeName === "Media"
                                        ? row?.DiscountedPrice
                                        : row?.PricePerUnitWithoutGST
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={row?.TotalPriceWithoutGST}
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {" "}
                                  <CommaSeprator
                                    Price={row?.TotalPriceWithoutGST}
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  p: 0,
                                  maxWidth: "200px",
                                  mx: "auto",
                                }}
                                align="center"
                              >
                                {OrderSummarydata &&
                                OrderSummarydata?.IsIGST ? (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      colSpan={2}
                                      sx={{
                                        width: "150px",
                                        borderRight: "1px solid #CDCDCD",
                                      }}
                                    >
                                      <Typography sx={TableBottomtext}>
                                        {row?.GST}
                                      </Typography>
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      colSpan={2}
                                      sx={{
                                        width: "150px",
                                        borderRight: "1px solid #CDCDCD",
                                      }}
                                    >
                                      <Typography sx={TableBottomtext}>
                                        <CommaSeprator
                                          Price={row?.TotalGSTAmount}
                                        />
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      colSpan={2}
                                      sx={{
                                        width: "150px",
                                        borderRight: "1px solid #CDCDCD",
                                      }}
                                    >
                                      <Typography sx={TableBottomtext}>
                                        {row?.GST / 2}
                                      </Typography>
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      colSpan={2}
                                      sx={{
                                        width: "150px",
                                        borderRight: "1px solid #CDCDCD",
                                      }}
                                    >
                                      <Typography sx={TableBottomtext}>
                                        <CommaSeprator
                                          Price={row?.TotalGSTAmount / 2}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={2}
                                      sx={{
                                        width: "150px",
                                        borderRight: "1px solid #CDCDCD",
                                      }}
                                    >
                                      <Typography sx={TableBottomtext}>
                                        {row?.GST / 2}
                                      </Typography>
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      colSpan={2}
                                      sx={{
                                        width: "150px",
                                        borderRight: "1px solid #CDCDCD",
                                      }}
                                    >
                                      <Typography sx={TableBottomtext}>
                                        <CommaSeprator
                                          Price={row?.TotalGSTAmount / 2}
                                        />
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={1}
                                rowSpan={1}
                                sx={{ borderRight: "1px solid #CDCDCD" }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={row?.TotalPriceWithGSTInBXI}
                                  />
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {row?.AdditionCost?.AdditionCostArray?.map(
                              (res, index) => {
                                let GstOfAdCost = Number(res?.AdCostGST);
                                return (
                                  <TableRow
                                    sx={{
                                      height: "25px",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                    key={index}
                                  >
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      align="left"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                        px: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          width: "450px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        {" "}
                                        {res?.ReasonOfCost} {"  "}({" "}
                                        {res?.AdCostApplicableOn === "PerUnit"
                                          ? "Per Unit"
                                          : "One Time"}{" "}
                                        ){" "}
                                        {res?.currencyType === "₹" ? (
                                          "₹"
                                        ) : (
                                          <img
                                            src={BxiCoin}
                                            style={{
                                              width: "20px",
                                              height: "auto",
                                            }}
                                          />
                                        )}{" "}
                                      </Typography>

                                      {row?.TotalSec && (
                                        <Typography>
                                          {row?.ProductTypeId ===
                                            "MediaOffline" ||
                                          row?.ProductTypeId ===
                                            "News Papers / Magazines"
                                            ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                            : `(${TotalSec} sec) (${row?.TimelineToBought} ${row?.Timeline})`}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      <Typography>{res?.AdCostHSN}</Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      <Typography>-</Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      <Typography>
                                        <CommaSeprator
                                          Price={res?.PriceWithoutGST}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      <Typography>
                                        <CommaSeprator
                                          Price={res?.TotalTaxableAmount}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      <Typography>
                                        <CommaSeprator
                                          Price={res?.TotalTaxableAmount}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        maxWidth: "200px",
                                        mx: "auto",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                      align="center"
                                    >
                                      {OrderSummarydata &&
                                      OrderSummarydata?.IsIGST ? (
                                        <TableRow>
                                          <TableCell
                                            align="center"
                                            colSpan={2}
                                            sx={{
                                              padding: 0,
                                              width: "150px",
                                              borderRight: "1px solid #CDCDCD",
                                              margin: 0,
                                            }}
                                          >
                                            <Typography>
                                              <CommaSeprator
                                                Price={GstOfAdCost}
                                              />
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            colSpan={2}
                                            sx={{
                                              padding: 0,
                                              width: "150px",
                                              borderRight: "1px solid #CDCDCD",
                                              margin: 0,
                                            }}
                                          >
                                            <Typography>
                                              {res?.AdCostApplicableOn ===
                                              "PerUnit" ? (
                                                <CommaSeprator
                                                  Price={
                                                    res?.PriceWithoutGST *
                                                    row?.ProductQuantity *
                                                    (GstOfAdCost / 100)
                                                  }
                                                />
                                              ) : (
                                                <CommaSeprator
                                                  Price={
                                                    res?.PriceWithoutGST *
                                                    (GstOfAdCost / 100)
                                                  }
                                                />
                                              )}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        <TableRow>
                                          <TableCell
                                            align="center"
                                            colSpan={2}
                                            sx={{
                                              padding: 0,
                                              width: "150px",
                                              borderRight: "1px solid #CDCDCD",
                                              margin: 0,
                                            }}
                                          >
                                            <Typography>
                                              <CommaSeprator
                                                Price={GstOfAdCost / 2}
                                              />
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            colSpan={2}
                                            sx={{
                                              padding: 0,
                                              width: "150px",
                                              borderRight: "1px solid #CDCDCD",
                                              margin: 0,
                                            }}
                                          >
                                            <Typography>
                                              {res?.AdCostApplicableOn ===
                                              "PerUnit" ? (
                                                <CommaSeprator
                                                  Price={
                                                    (res?.PriceWithoutGST *
                                                      row?.ProductQuantity *
                                                      (GstOfAdCost / 100)) /
                                                    2
                                                  }
                                                />
                                              ) : (
                                                <CommaSeprator
                                                  Price={
                                                    (res?.PriceWithoutGST *
                                                      (GstOfAdCost / 100)) /
                                                    2
                                                  }
                                                />
                                              )}
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            colSpan={2}
                                            sx={{
                                              padding: 0,
                                              width: "150px",
                                              borderRight: "1px solid #CDCDCD",
                                              margin: 0,
                                            }}
                                          >
                                            <Typography>
                                              <CommaSeprator
                                                Price={GstOfAdCost / 2}
                                              />
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            colSpan={2}
                                            sx={{
                                              padding: 0,
                                              width: "150px",
                                              borderRight: "1px solid #CDCDCD",
                                              margin: 0,
                                            }}
                                          >
                                            <Typography>
                                              {res?.AdCostApplicableOn ===
                                              "PerUnit" ? (
                                                <CommaSeprator
                                                  Price={
                                                    (res?.PriceWithoutGST *
                                                      row?.ProductQuantity *
                                                      (GstOfAdCost / 100)) /
                                                    2
                                                  }
                                                />
                                              ) : (
                                                <CommaSeprator
                                                  Price={
                                                    (res?.PriceWithoutGST *
                                                      (GstOfAdCost / 100)) /
                                                    2
                                                  }
                                                />
                                              )}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      colSpan={1}
                                      rowSpan={1}
                                      sx={{
                                        borderRight: "1px solid #CDCDCD",
                                        padding: 0,
                                        margin: 0,
                                        height: "10px",
                                      }}
                                    >
                                      <Typography>
                                        <CommaSeprator
                                          Price={
                                            row?.ProductTypeName === "Media"
                                              ? TotalTokanAmount
                                              : res?.TotalWithGst
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      })}
                      <TableRow
                        sx={{
                          height: "42px",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={3}
                          rowSpan={3}
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            background: props?.SellerPage
                              ? "#c73e91"
                              : "#2261A2",
                          }}
                        >
                          <Typography
                            sx={{ ...TableBottomtext, color: "white" }}
                          >
                            Total
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            <CommaSeprator
                              Price={OrderSummarydata?.POTotals?.TotalQuantity}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            <CommaSeprator
                              Price={
                                OrderSummarydata?.POTotals
                                  ?.TotalPricePerUnitWithoutGST +
                                OrderSummarydata?.POTotals
                                  ?.TotalTaxableAdditionalCost
                              }
                            />
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            <CommaSeprator
                              Price={
                                OrderSummarydata?.POTotals?.TotalProductCoins +
                                OrderSummarydata?.POTotals
                                  ?.TotalAdditionalCostWtihoutGSTInRupee +
                                OrderSummarydata?.POTotals
                                  ?.TotalAdditionalCostWtihoutGSTInBXI
                              }
                            />
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            <CommaSeprator
                              Price={
                                OrderSummarydata?.POTotals?.TotalProductCoins +
                                OrderSummarydata?.POTotals
                                  ?.TotalAdditionalCostWtihoutGSTInRupee +
                                OrderSummarydata?.POTotals
                                  ?.TotalAdditionalCostWtihoutGSTInBXI
                              }
                            />
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            maxWidth: "200px",
                            mx: "auto",
                          }}
                          align="center"
                        >
                          {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "100px",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    color: "rgba(5, 5, 5, 1)",
                                    opacity: 1,
                                  }}
                                >
                                  <CommaSeprator Price={totalIGSTPercentage} />
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "100px",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    color: "rgba(5, 5, 5, 1)",
                                    opacity: 1,
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      OrderSummarydata?.POTotals
                                        ?.TotalGstAmount +
                                      OrderSummarydata?.POTotals
                                        ?.TotalAdCostGstAmount
                                    }
                                  />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "100px",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    color: "rgba(5, 5, 5, 1)",
                                    opacity: 1,
                                  }}
                                >
                                  <CommaSeprator Price={totalCSTPerCentage} />
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "100px",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    color: "rgba(5, 5, 5, 1)",
                                    opacity: 1,
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      OrderSummarydata?.POTotals
                                        ?.TotalGstAmount /
                                        2 +
                                      OrderSummarydata?.POTotals
                                        ?.TotalAdCostGstAmount /
                                        2
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "100px",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    color: "rgba(5, 5, 5, 1)",
                                    opacity: 1,
                                  }}
                                >
                                  <CommaSeprator Price={totalSGSTPercentage} />
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "100px",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    color: "rgba(5, 5, 5, 1)",
                                    opacity: 1,
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      OrderSummarydata?.POTotals
                                        ?.TotalGstAmount /
                                        2 +
                                      OrderSummarydata?.POTotals
                                        ?.TotalAdCostGstAmount /
                                        2
                                    }
                                  />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          colSpan={1}
                          rowSpan={1}
                          sx={{ borderRight: "1px solid #CDCDCD" }}
                        >
                          <Typography
                            sx={{
                              ...TableBottomtext,
                              color: "rgba(5, 5, 5, 1)",
                              opacity: 1,
                            }}
                          >
                            <CommaSeprator
                              Price={OrderSummarydata?.POTotals?.GrandTotal}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                    mx: "auto",
                    border: "1px solid #cdcdcd",
                  }}
                >
                  <Box
                    sx={{
                      width: "60%",
                      height: "100%",
                      borderRight: "1px solid #CDCDCD",
                    }}
                  >
                    <Box
                      sx={{
                        background: props?.SellerPage ? "#c73e91" : "#2261A2",
                        borderRadius: "3px 0px 0px 3",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Total amount in words
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        minHeight: "100px",
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableTotaltextStyle,
                          fontWeight: 600,
                          fontSize: "13px",
                        }}
                      >
                        {OrderSummarydata?.POTotals && (
                          <NumberToWord
                            number={OrderSummarydata?.POTotals?.GrandTotal}
                          />
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Table
                      sx={{
                        height: "150px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <TableRow sx={{ width: "100%", display: "flex" }}>
                        <TableCell
                          sx={{
                            pl: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                            whiteSpace: "nowrap",
                          }}
                          colSpan={1}
                        >
                          <Typography sx={TableTotaltextStyle}>
                            Total Amount to be paid in Barter Coins
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                          }}
                          align="right"
                        >
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                OrderSummarydata?.POTotals
                                  ?.TotalCoinsWithAdditionalCost
                              }
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ width: "100%", display: "flex" }}>
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                          }}
                        >
                          <Typography sx={TableTotaltextStyle}>
                            Total GST to be paid in INR
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                          }}
                          align="right"
                        >
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                OrderSummarydata?.POTotals?.TotalGstAmount +
                                OrderSummarydata?.POTotals?.TotalAdCostGstAmount
                              }
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ width: "100%", display: "flex" }}>
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                          }}
                        >
                          <Typography sx={TableTotaltextStyle}>
                            Additional cost to be paid in INR
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                          }}
                          align="right"
                        >
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={Number(
                                OrderSummarydata?.POTotals
                                  ?.TotalAdditionalCostWtihoutGSTInRupee
                              )}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography sx={TableTotaltextStyle}>
                            Additional cost to be paid in Barter Coins
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 1,
                            py: 0.5,
                            borderBottom: "none",
                            width: "50%",
                          }}
                          align="right"
                        >
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={Number(
                                OrderSummarydata?.POTotals
                                  ?.TotalAdditionalCostWtihoutGSTInBXI
                              )}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                    <Box
                      sx={{
                        background: props?.SellerPage ? "#c73e91" : "#2261A2",
                        borderRadius: "3px 0px 0px 3",
                        display: "flex",
                        justifyContent: "space-between",
                        px: 1,
                      }}
                    >
                      <Typography sx={TableTextStyle}>
                        Total amount after Tax :
                      </Typography>
                      <Typography sx={TableTextStyle}>
                        <CommaSeprator
                          Price={OrderSummarydata?.POTotals?.GrandTotal}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {props?.SellerPage === true ? null : (
                <Modal
                  open={openaddressmodal}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontSize: "13px",
                        mx: "auto",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      Choose Billing Address
                    </Typography>

                    <Box
                      sx={{
                        width: "auto",
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                          display: "flex",
                        },
                        "::-webkit-scrollbar-thumb": {
                          dynamic: "#8d8e90",
                          minHeight: "10px",
                          borderRadius: "8px",
                        },
                        "::-webkit-scrollbar-thumb:vertical": {
                          maxHeight: "30px",
                        },
                        maxHeight:
                          AddressToMap?.data?.GSTAddresses?.length < 6
                            ? "auto"
                            : "410px",
                        height:
                          AddressToMap?.data?.GSTAddresses?.length < 6
                            ? "auto"
                            : "320px",
                        p: 1,
                      }}
                    >
                      {AddressToMap?.data?.GSTAddresses?.length > 0 ? (
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={SelectedAddress}
                            onChange={handleChangeDeliveryAddress}
                          >
                            {AddressToMap?.data?.GSTAddresses?.map(
                              (item, index) => {
                                if (item && item.data?.Address) {
                                  return (
                                    <FormControlLabel
                                      key={index + 1}
                                      value={item?.GST}
                                      style={{
                                        color: "#6B7A99",
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                        borderBottom: "0.5px solid lightgray",
                                      }}
                                      label={
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            color: "#6B7A99",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item.data.Address}
                                        </span>
                                      }
                                      control={<Radio />}
                                    />
                                  );
                                }
                                return null;
                              }
                            )}
                          </RadioGroup>
                        </FormControl>
                      ) : (
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "16px",
                            textAlign: "center",
                          }}
                        >
                          No Address Found
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        mt: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          bgcolor: props?.SellerPage ? "#c73e91" : "#2261A2",
                          height: "35px",
                          width: "100px",
                          color: "white",
                        }}
                        onClick={() => {
                          mutatePurchaseOrder();
                          sendEvents("Click on accept");
                          handleDownloadTokensFlow();
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          bgcolor: props?.SellerPage ? "#c73e91" : "#2261A2",
                          height: "35px",
                          width: "auto",
                          color: "white",
                        }}
                        onClick={() => {
                          mutatePurchaseOrder();
                          sendEvents("Click on accept");
                        }}
                      >
                        Continue with old address
                      </Button>
                      <Button
                        onClick={() => {
                          handleClose();
                          sendEvents("Click on close");
                        }}
                        variant="contained"
                        color="primary"
                        sx={{
                          bgcolor: props?.SellerPage ? "#c73e91" : "#2261A2",
                          height: "35px",
                          width: "100px",
                        }}
                      >
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              )}

              {props?.SellerPage === true ||
              OrderSummarydata?.ProductTypeName === "Media" ? null : (
                <Box sx={{ width: "95%", mx: "auto" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name="checked"
                        color="primary"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ fontSize: "12px" }}>
                        Click here to add new delivery location
                      </Typography>
                    }
                  />
                </Box>
              )}
              {props?.SellerPage === true ||
              OrderSummarydata?.ProductTypeName === "Media"
                ? null
                : checked && (
                    <React.Fragment>
                      <Box
                        sx={{
                          width: "95%",
                          mx: "auto",
                          height: "20px",
                          bgcolor: props?.SellerPage ? "#c73e91" : "#2261A2",
                          alignContent: "center",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          mb: 2,
                        }}
                      >
                        <Typography sx={TableTextStyle}>
                          Fill the Address Details : Ship To / Delivery Location
                          Details
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          bgcolor: "transparent",
                          display: "flex",
                          justifyContent: "space-evenly",
                          flexDirection: "row",
                          width: "90%",
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <label style={AddressInputTextStyle}>State</label>
                          <Select
                            sx={{
                              width: "100%",
                              maxWidth: "300px",
                              height: "35px",
                              borderRadius: "5px",
                              border: "1px solid #cdcdcd",
                              outline: "none",
                              "&:focus": {
                                border: "1px solid #2261A2",
                              },
                            }}
                            onChange={(e) => {
                              setStateArray(e.target.value);
                              setState(e.target.value);
                            }}
                            name="state"
                            value={stateArray}
                            id="state"
                            required
                            style={AddressInputStyle}
                          >
                            {StateData?.sort((a, b) =>
                              a.name.localeCompare(b.name)
                            ) // Sort the data alphabetically by 'name'
                              .map((res, index) => (
                                <MenuItem key={index} value={res?.name}>
                                  {res?.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <label style={AddressInputTextStyle}>City</label>
                          <Select
                            sx={{
                              width: "100%",
                              maxWidth: "300px",
                              height: "35px",
                              borderRadius: "5px",
                              border: "1px solid #cdcdcd",
                              outline: "none",
                              "&:focus": {
                                border: "1px solid #2261A2",
                              },
                            }}
                            onChange={(e) => setCity(e.target.value)}
                            name="state"
                            value={CityArray}
                            id="state"
                            required
                            style={AddressInputStyle}
                          >
                            {CityArray?.map((res, index) => (
                              <MenuItem key={index} value={res}>
                                {res}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <label style={AddressInputTextStyle}>Pincode</label>
                          <input
                            type="text"
                            placeholder=" "
                            className="inp"
                            onChange={(e) => setPincode(e.target.value)}
                            required
                            style={{ ...AddressInputStyle, width: "200px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "400px",
                          }}
                        >
                          <label style={AddressInputTextStyle}>Address</label>
                          <input
                            type="text"
                            placeholder=" "
                            onChange={(e) => setAddress(e.target.value)}
                            className="inp"
                            required
                            style={{ ...AddressInputStyle, width: "400px" }}
                          />
                        </Box>
                      </Box>
                    </React.Fragment>
                  )}

              {OrderSummarydata?.CouponData ? (
                <Box sx={{ width: "95%", mx: "auto" }}>
                  <label
                    style={{
                      ...AddressInputTextStyle,
                      fontSize: "18px",
                      lineHeight: "15px",
                    }}
                  >
                    Already Coupon Applied
                  </label>
                </Box>
              ) : (
                <Box>
                  {props?.SellerPage === true ? null : (
                    <Box sx={{ width: "95%", mx: "auto" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Codechecked}
                            onChange={handleCouponCheckChange}
                            name="checked"
                            color="primary"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                width: "24px",
                                height: "24px",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" sx={{ fontSize: "12px" }}>
                            Click here to Apply Coupon
                          </Typography>
                        }
                      />
                    </Box>
                  )}

                  {props?.SellerPage === true ? null : Codechecked ? (
                    <Box>
                      {!CouponCodeData?._id ? (
                        <Box
                          sx={{
                            width: "95%",
                            mx: "auto",
                            alignItems: "center",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "5px",
                            mt: 1,
                          }}
                        >
                          <label style={AddressInputTextStyle}>
                            Apply Coupon Code :
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setCouponCode(e.target.value)}
                            required
                            style={{
                              ...AddressInputStyle,
                              width: "120px",
                              height: "25px",
                              border: "0.5px solid black",
                            }}
                          />

                          <Button
                            onClick={() => GetAllCoupons()}
                            variant="contained"
                            sx={{
                              width: "auto",
                              textTransform: "none",
                              height: "25px",
                              bgcolor: props?.SellerPage
                                ? "#c73e91"
                                : "#2261A2",
                              borderRadius: "6px",
                            }}
                          >
                            Apply Coupon Code
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "95%",
                            mx: "auto",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "5px",
                            mt: 1,
                          }}
                        >
                          <label
                            style={{
                              ...AddressInputTextStyle,
                              fontSize: "18px",
                              lineHeight: "5px",
                            }}
                          >
                            Coupon Applied
                          </label>
                        </Box>
                      )}
                    </Box>
                  ) : null}
                </Box>
              )}

              <Box
                sx={{
                  width: "95%",
                  mx: "auto",
                  height: "20px",
                  alignItems: "flex-start",
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    ...ProductPriceTextStyle,
                    fontSize: "12px",
                    color: "#28282B",
                  }}
                >
                  <img
                    src={BxiCoin}
                    style={{
                      width: "12px",
                      height: "auto",
                    }}
                  />{" "}
                  - This symbol denotes Barter Coin that can be used only for
                  transactions within the BXI marketplace.
                  <br />₹ - This symbol denotes the Indian Rupee involved in the
                  Transaction.
                </Typography>
              </Box>
            </Box>
          </Box>

          {props?.SellerPage === true ? null : (
            <Box
              sx={{
                width: "100%",
                mt: 3,
                pb: 3,
              }}
            >
              <Box
                sx={{
                  maxWidth: "500px",
                  mx: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  height: "100%",
                }}
              >
                {OrderSummarydata?.BuyerOrderStatus === "Expired" ||
                OrderSummarydata?.SellerOrderStatus === "Expired" ? (
                  <Box
                    sx={{
                      ...ButtonStyleForAcceptAndReject,
                      border: "0.5px solid #EB5757",
                      bgcolor: "#FFEFEF",
                      color: "#EB5757",
                    }}
                  >
                    Order Expired
                  </Box>
                ) : (
                  <>
                    {OrderSummarydata?.BuyerOrderStatus === "Pending" &&
                    !OrderSummaryDataLoading ? (
                      <Box
                        sx={ButtonStyleForAcceptAndReject}
                        onClick={() => {
                          handleOpenAddress();
                          sendEvents("Click on accept");
                        }}
                      >
                        Issue
                      </Box>
                    ) : OrderSummarydata?.BuyerOrderStatus === "Accepted" &&
                      !OrderSummaryDataLoading ? (
                      <Box
                        sx={ButtonStyleForAcceptAndReject}
                        onClick={() => {
                          navigate("/home/productpilist01");
                          sendEvents("Click on continue");
                        }}
                      >
                        Next Step {"->"}
                      </Box>
                    ) : OrderSummarydata?.BuyerOrderStatus === "Rejected" &&
                      !OrderSummaryDataLoading ? (
                      <Box
                        sx={{
                          ...ButtonStyleForAcceptAndReject,
                          border: "0.5px solid #EB5757",
                          bgcolor: "#FFEFEF",
                          color: "#EB5757",
                        }}
                      >
                        Order Rejected
                      </Box>
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                    {OrderSummarydata?.BuyerOrderStatus === "Rejected" ? (
                      <Box
                        sx={{
                          ...ButtonStyleForAcceptAndReject,
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          bgcolor: "#fff",
                          color: props?.SellerPage ? "#c73e91" : "#2261A2",
                          border: "1px solid #2261A2",
                        }}
                        onClick={() => {
                          navigate("/home/cart");
                          sendEvents("Click on back to cart");
                        }}
                      >
                        <MdKeyboardBackspace size={25} />
                        Back to Cart
                      </Box>
                    ) : OrderSummarydata?.BuyerOrderStatus ===
                      "Accepted" ? null : (
                      <React.Fragment>
                        <Box
                          sx={{
                            ...ButtonStyleForAcceptAndReject,
                            bgcolor: "#fff",
                            border: "1px solid #445FD2",
                            color: "#445FD2",
                          }}
                          onClick={() => {
                            mutatePurchaseOrderRejected();
                            sendEvents("Click on reject");
                          }}
                        >
                          Reject
                        </Box>

                        <Dialog
                          open={open}
                          fullWidth
                          maxWidth="lg"
                          sx={{
                            backdropFilter: "blur(2px)",
                          }}
                          PaperProps={{
                            sx: {
                              width: "60%",
                              maxHeight: 600,
                              borderRadius: "20px",
                            },
                          }}
                        >
                          <DialogTitle id="responsive-dialog-title">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "start",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "80%",
                                  mx: "auto",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    lineHeight: "28px",
                                    color: "#6B7A99",
                                    textAlign: "center",
                                  }}
                                >
                                  Please select the product for which you wish
                                  to put in Ready Stock
                                </Typography>
                              </Box>
                            </Box>
                          </DialogTitle>

                          <DialogContent
                            sx={{
                              overflow: "auto",
                              mr: 2,
                              "::-webkit-scrollbar": {
                                display: "flex",
                              },
                              "::-webkit-scrollbar-thumb": {
                                dynamic: "#8d8e90",
                                minHeight: "10px",
                                borderRadius: "3px",
                              },
                              "::-webkit-scrollbar-thumb:vertical": {
                                miaxHeight: "10px",
                              },
                            }}
                          >
                            {CompaniesProducts &&
                            CompaniesProducts.length > 0 ? (
                              CompaniesProducts.slice()
                                .reverse()
                                .map((el, idx) => {
                                  const sums = calculateSums(
                                    el?.ProductsVariantions
                                  );
                                  return (
                                    <Box key={idx}>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          height: "80px",
                                          maxHeight: "122px",
                                          background: "#FFFFFF",
                                          border: "1px solid #EDEFF2",
                                          borderRadius: "10px 10px 10px 10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          alignContent: "center",
                                          mt: 2,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: "100%",
                                            maxWidth: {
                                              xl: "800px",
                                              lg: "800px",
                                              md: "800px",
                                              sm: "350px",
                                              xs: "350px",
                                            },
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            alignContent: "center",
                                            gap: "2rem",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              ml: "1%",
                                              height: "80px",
                                              width: "150px",
                                              maxHeight: "122px",
                                              minWidth: "150px",
                                              maxWidth: "150px",
                                              borderRadius:
                                                "25px 25px 25px 25px",
                                              backgroundImage:
                                                el?.ListingType === "Voucher"
                                                  ? `url(${
                                                      el?.VoucherImages?.at(0)
                                                        ?.url
                                                    })`
                                                  : `url(${
                                                      el?.ProductImages?.at(0)
                                                        ?.url
                                                    })`,
                                              backgroundSize: "contain",
                                              backgroundPosition: "center",
                                              backgroundRepeat: "no-repeat",
                                            }}
                                          ></Box>
                                          <Box
                                            sx={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "start",
                                              alignContent: "start",
                                              flexDirection: "column",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: "50%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...ProductNameTextStyle,
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: 1,
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                {el?.ProductName}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                width: "50%",
                                              }}
                                            >
                                              <Typography
                                                sx={ProductMetaTextStyle}
                                              >
                                                {el?.ProductDescription}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                alignContent: "center",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...ProductPriceTextStyle,
                                                  marginTop: "-03px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "2px",
                                                  width: "100%",
                                                }}
                                              >
                                                &nbsp;
                                                <span
                                                  style={{
                                                    ...ProductNameTextStyle,
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Max Order Quantity :
                                                </span>{" "}
                                                &nbsp;
                                                <CommaSeprator
                                                  Price={sums?.maxOrderQtySum}
                                                />
                                                &nbsp;
                                                <span
                                                  style={{
                                                    ...ProductNameTextStyle,
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Total Product Value :
                                                </span>{" "}
                                                &nbsp;
                                                <CommaSeprator
                                                  Price={sums?.totalValueSum}
                                                />
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            maxWidth: "60px",
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          {ProductId === el._id ? (
                                            <Box
                                              onClick={() => {
                                                setProductId(null);
                                                sendEvents(
                                                  "Click on radio check"
                                                );
                                              }}
                                              sx={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img src={radiocheck} size={30} />
                                            </Box>
                                          ) : (
                                            <Box
                                              onClick={() => {
                                                setProductId(el?._id);
                                                setProductData(el);
                                              }}
                                              sx={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img
                                                src={radiouncheck}
                                                size={30}
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  );
                                })
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                    color: "#8A8A8A",
                                    lineHeight: 20,
                                  }}
                                >
                                  No Data Found
                                </Typography>
                              </Box>
                            )}
                          </DialogContent>
                          <DialogTitle id="responsive-dialog-title">
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{ ...reqbtn, width: "auto" }}
                                disabled={ProductId ? false : true}
                                onClick={() => {
                                  setOpenSecond(true);
                                  setOpen(false);
                                }}
                              >
                                Put In Ready Stock
                              </Button>
                            </Box>
                          </DialogTitle>
                        </Dialog>
                        <Dialog
                          open={openSecond}
                          sx={{
                            backdropFilter: "blur(2px)",
                          }}
                          fullWidth
                          maxWidth="lg"
                          PaperProps={{
                            sx: {
                              width: "60%",
                              height: "auto",
                              minHeight: "80%",
                              maxHeight: "80%",
                              borderRadius: "20px",
                              justifyContent: "center",
                            },
                          }}
                        >
                          <DialogContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DialogContentText id="alert-dialog-description">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "15px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "15px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      width: "100%",
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "20px",
                                      color: "#7D8BA6",
                                      textAlign: "center",
                                    }}
                                  >
                                    <b>
                                      <u>
                                        CREDIT LINE AGREEMENT FOR THE BARTER
                                        COINS
                                      </u>
                                    </b>
                                  </Typography>
                                  <PrivateDealAgreement
                                    OrderData={StoreOrderSummaryData}
                                    ProductData={ProductData}
                                    CouponCodeData={CouponCodeData}
                                  />
                                  <Typography sx={credittermstext}>
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "20px",
                                        },
                                        padding: "1px",
                                      }}
                                      onClick={() => {
                                        if (agreecheck === false) {
                                          setagreeCheck(true);
                                        } else {
                                          setagreeCheck(false);
                                        }
                                      }}
                                    />
                                    I agree to all the clauses of this Credit
                                    Line Agreement for Barter Coins as set forth
                                    above.
                                  </Typography>
                                </Box>
                              </Box>
                            </DialogContentText>
                          </DialogContent>
                          <DialogTitle id="responsive-dialog-title">
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  ...reqbtn,
                                  fontWeight: 700,
                                  background: "none",
                                  border: "0.7px solid #EBEDEE",
                                  color: "#445FD2",
                                }}
                                onClick={() => {
                                  if (open === false) {
                                    setOpen(true);
                                    setOpenSecond(false);
                                  } else {
                                    setOpen(false);
                                    setOpenSecond(false);
                                  }
                                }}
                              >
                                Decline
                              </Button>
                              <Button
                                variant="contained"
                                sx={reqbtn}
                                disabled={agreecheck ? false : true}
                                onClick={() => {
                                  setOpenAddressModal(true);
                                  setOpenSecond(false);
                                }}
                              >
                                I Agree
                              </Button>
                            </Box>
                          </DialogTitle>
                        </Dialog>
                      </React.Fragment>
                    )}
                  </>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
      <TostMessagesTop PageLocation="Order Details" />
    </Box>
  );
};

export default PurchaseOrderDetails;

const AddressInputStyle = {
  width: "186px",
  height: "35px",
  background: "#F9F9F9",
  borderRadius: "6px",
  border: "none",
  paddingLeft: "10px",
};

const AddressInputTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "10px",
  color: "#6B7A99",
};

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const AddressTextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#505050",
  opacity: 1,

  width: "300px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "12px",
    xs: "12px",
  },
  lineHeight: {
    xl: "15px",
    lg: "15px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};

const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const reqbtn = {
  width: "100px",
  height: "40px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "24px",
  textTransform: "none",
};

const credittermstext = {
  width: "100%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "left",
  color: "#7D8BA6",
};
