import { createSlice } from '@reduxjs/toolkit';

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {
    activePaginationCount: 1, // Set the initial value for the active pagination count
  },
  reducers: {
    SET_ACTIVE_PAGINATION_COUNT: (state, action) => {
      state.activePaginationCount = action.payload;
    },
  },
});

export const { SET_ACTIVE_PAGINATION_COUNT } = paginationSlice.actions;
export default paginationSlice.reducer;
