import { createSlice } from "@reduxjs/toolkit";

export const MediaBulkUploadProductToDBSlice = createSlice({
    name: "MediaBulkUploadProductsToDB",
    initialState: {
        MediaBulkUploadProductsToDB: [],
    },
    reducers: {
        MEDIA_BULK_UPLOAD_TODB_REQUEST: (state) => {
            state.loading = true;
            state.MediaBulkUploadProductsToDB = [];
        },
        MEDIA_BULK_UPLOAD_TODB_SUCCESS: (state, action) => {
            state.loading = false;
            state.MediaBulkUploadProductsToDB = action.payload;
        },
        MEDIA_BULK_UPLOAD_TODB_FAIL: (state, action) => {
            state.loading = false;
            state.MediaBulkUploadProductsToDB = action.payload;
        },
    },
});

export const {
    MEDIA_BULK_UPLOAD_TODB_REQUEST,
    MEDIA_BULK_UPLOAD_TODB_SUCCESS,
    MEDIA_BULK_UPLOAD_TODB_FAIL,
} = MediaBulkUploadProductToDBSlice.actions;
export default MediaBulkUploadProductToDBSlice.reducer;
