import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import "./ProgressBar.css";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const OrderProgressBar = ({ type, Data, userhubtype }) => {
  const [stepCount, setStepCount] = useState();
  const OrderId = useParams().id;
  const [usehubType, setUserHubType] = useState("");
  useEffect(() => {
    const storedVariable = localStorage.getItem("userhubtype");
    setUserHubType(storedVariable);
  }, []);

  const steps =
    usehubType === "seller"
      ? type === "mediapurchaseorder" ||
        type === "mediaProformaInvoice" ||
        type === "mediaordersummary"
        ? [
            { num: 1, label: "PO Received" },
            { num: 2, label: "Seller Accepted PO" },
            { num: 3, label: "Seller Issued PI" },
            { num: 4, label: "Buyer Accepted PI" },
            { num: 5, label: "Payment Done" },
            { num: 6, label: "Product Delivered" },
            { num: 7, label: "Buyer Confirmed Delivery" },
            { num: 8, label: "Payment Released to Seller" },
          ]
        : type === "voucherordersummary"
        ? [
            { num: 1, label: "Payment Done" },
            { num: 2, label: "Voucher Activated by Seller" },
            { num: 3, label: "Buyer Confirmed Activation" },
            { num: 4, label: "Payment Released to Seller" },
          ]
        : [
            { num: 1, label: "PO Received" },
            { num: 2, label: "Seller Accepted PO" },
            { num: 3, label: "Seller Issued PI" },
            { num: 4, label: "Buyer Accepted PI" },
            { num: 5, label: "Logistic Selected" },
            { num: 6, label: "Payment Done" },
            { num: 7, label: "Product Delivered" },
            { num: 8, label: "Buyer Confirmed Delivery" },
            { num: 9, label: "Payment Released to Seller" },
          ]
      : type === "mediapurchaseorder" ||
        type === "mediaProformaInvoice" ||
        type === "mediaordersummary"
      ? [
          { num: 1, label: "Generated PO" },
          { num: 2, label: "Buyer Issued PO" },
          { num: 3, label: "Seller Accepted PO" },
          { num: 4, label: "Buyer Accepted PI" },
          { num: 5, label: "Payment Done" },
          { num: 6, label: "Product Delivered" },
          { num: 7, label: "Buyer Confirmed Delivery" },
          { num: 8, label: "Payment Released to Seller" },
        ]
      : type === "voucherordersummary"
      ? [
          { num: 1, label: "Payment Done" },
          { num: 2, label: "Voucher Activated by Seller" },
          { num: 3, label: "Buyer Confirmed Activation" },
          { num: 4, label: "Payment Released to Seller" },
        ]
      : [
          { num: 1, label: "PO Received" },
          { num: 2, label: "Seller Accepted PO" },
          { num: 3, label: "Seller Issued PI" },
          { num: 4, label: "Buyer Accepted PI" },
          { num: 5, label: "Logistic Selected" },
          { num: 6, label: "Payment Done" },
          { num: 7, label: "Product Delivered" },
          { num: 8, label: "Buyer Confirmed Delivery" },
          { num: 9, label: "Payment Released to Seller" },
        ];

  const fetchOrderProgress = async () => {
    try {
      await axios
        .post(
          `order/order_progress`,
          { type: type, id: OrderId },
          { withCredentials: true }
        )
        .then((res) => {
          setStepCount(res?.data);
        });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (OrderId) {
      fetchOrderProgress();
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={stepCount?.step} alternativeLabel>
        {steps?.map((data) => {
          if (data?.num) {
            return (
              <Step key={data?.num}>
                <StepLabel className="icon-wrapper">
                  <Typography
                    sx={{
                      ...TextStyle,
                      color: usehubType === "seller" ? "#c64091" : "#2261a2",
                    }}
                  >
                    {data?.label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          } else {
            return null;
          }
        })}
        <Box>
          <Typography
            sx={{
              ...TextStyle,
              fontWeight: 500,
              backgroundColor: "#DBEDFF",
              borderRadius: "8px",
              px: 1,
              py: 0.2,
            }}
          >
            {stepCount?.progress}%
          </Typography>
        </Box>
      </Stepper>
    </Box>
  );
};

export default OrderProgressBar;

const TextStyle = {
  color: "#2261A2",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 400,
};
