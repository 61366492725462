import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import landingarrow from "../../assets/HomePageImages/rightarr.svg";
import landingarrowone from "../../assets/HomePageImages/leftarr.svg";
import { useInView } from "react-intersection-observer";
import ClientMarquee from "./ClientMarquee";
import "./Style.css";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom/dist";
import Typewriter from "typewriter-effect/dist/core";
import { motion, useAnimation } from "framer-motion";
import AnimationBigArrrows from "./Animation/AnimationBigArrrows";
import GridItem from "./GridItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClientBox from "./ClientBox";
import HorizontalScrollingList from "./HorizontalScrollingList";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GoLeftImg from "../../assets/Images/CommonImages/GoLeftImg.png";
import GoRightImg from "../../assets/Images/CommonImages/GoRightImg.png";

const clients = [
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/GTPL.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Go+first.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Foce+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/GMR+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Free+Press+Journal+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Fintch.png",
  },

  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Dollar.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Della+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Clothing+cltur.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Limelight.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Mast.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Laromani.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/KAribo+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/jade+blue.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/KFC.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Khushi+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/IRA+(2).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/Hyatt+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/HAwa.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/VR.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/WIngreen.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Xech.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Udaywani+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Twills.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Royal+orchid+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/PPZ+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Tea+Culture.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Timezone.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/TravelBiz.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Byke.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Emotorad.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BG+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/MID+day.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/MOD+(1).png",
  },
];
const LandingPageBody = (props) => {
  const navigate = useNavigate();
  const [goToSlide, setGoToSlide] = useState(null);
  const [backwardImg, setBackwardImg] = useState(false);
  const [forwardImg, setForwardImg] = useState(false);
  const [isReset, setIsReset] = useState(true);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box
        component={"img"}
        className={className}
        onClick={onClick}
        sx={{
          ...style,
          display: "block",
          position: "absolute",
          top: "50%",
          left: "2%",
          height: "auto",
          width: { xl: "4%", lg: "4%", md: "4%", sm: "6%", xs: "6%" },
          zIndex: "10",
        }}
        src={GoLeftImg}
      ></Box>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box
        component={"img"}
        className={className}
        onClick={onClick}
        sx={{
          ...style,
          display: "block",
          position: "absolute",
          top: "50%",
          right: "2%",
          height: "auto",
          width: { xl: "4%", lg: "4%", md: "4%", sm: "6%", xs: "6%" },
          zIndex: "10",
        }}
        src={GoRightImg}
      ></Box>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3, // Default for large screens
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440, // For larger tablets or small laptops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024, // For tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // For large mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  let cards = [
    {
      _id: 1,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/della.mp4",
    },
    {
      _id: 2,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/karibo.mp4",
    },
    {
      _id: 3,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/miraj.mp4",
    },
    {
      _id: 4,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/nilon.mp4",
    },
    {
      _id: 5,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/orchid.mp4",
    },

    {
      _id: 6,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/skoda.mp4",
    },
    {
      _id: 7,
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AdonmoReel.mp4",
    },
    {
      _id: 8,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/stayvista.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/stayvista.mp4",
    },
    {
      _id: 9,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/timezonevertical.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/timezonevertical.mp4",
    },
    {
      _id: 10,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/zephyrvertical.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/zephyrvertical.mp4",
    },
    {
      _id: 11,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AjmalPerfumes.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AjmalPerfumes.mp4",
    },
    {
      _id: 12,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LIMELIGHT.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LIMELIGHT.mp4",
    },
    {
      _id: 13,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/strategicalliances.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/strategicalliances.mp4",
    },
    {
      _id: 14,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part1DURGESHGUPTAA.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part1DURGESHGUPTAA.mp4",
    },
    {
      _id: 15,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part2DURGESHGUPTAA.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part2DURGESHGUPTAA.mp4",
    },
    {
      _id: 16,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/EMotorad.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/EMotorad.mp4",
    },
    {
      _id: 17,
      poster_url:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/whisperingwoods.jpg",
      content:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/whisperingwoods.mp4",
    },
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
      });
    }
  }, [controls, inView]);
  const [ref1, inView1] = useInView();
  useEffect(() => {
    if (inView1) {
      controls.start({
        opacity: 1,
        scale: 1,
        translateX: -400,
      });
    }
  }, [controls, inView1]);

  const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
    const target = document.querySelector(qSelector);
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      target.innerText = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        counterAnim("#count1", 0, 250, 2000);
        counterAnim("#count2", 0, 100, 2000);
        counterAnim("#count3", 0, 1000, 2000);
        counterAnim("#count4", 0, 100000, 2000);
      }, 500);
    }
  }, [inView]);

  const typewriterRef = useRef(null);
  useEffect(() => {
    const typewriter = new Typewriter(typewriterRef.current, {
      loop: true,
    });

    typewriter
      .pauseFor(1000)
      .typeString("Other")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Office Supply")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Hotel")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Electronics")
      .pauseFor(1000)
      .deleteAll()
      .typeString("FMCG")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Lifestyle")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Mobility")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Textile")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Restaurant QSR")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Media")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Airline Ticket")
      .pauseFor(1000)
      .deleteAll()
      .typeString("Entertainment & Events")
      .pauseFor(1000)
      .start();
  }, []);

  return (
    <>
      <ToastContainer style={{ fontSize: "16px" }} />
      <Box
        sx={{
          width: "100%",
          maxHeight: "80%",
          mt: 2,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            // minWidth: "100vw",
            height: "auto",
            // minHeight: "80vh",
            // maxHeight: "80vh",
            objectFit: "cover",
          }}
        >
          <source
            src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/bannerVideo.mp4`}
            type="video/mp4"
          />
        </video>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "12px",
        }}
      >
        <Button
          sx={{
            background: "#fff",
            borderRadius: "23.1178px",
            width: {
              xl: "230px",
              lg: "220px",
              md: "220px",
              sm: "200px",
              xs: "200px",
            },
            height: {
              xl: "50px",
              lg: "50px",
              md: "50px",
              sm: "40px",
              xs: "40px",
            },
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 700,
            alignItems: "center",
            textAlign: "center",
            color: "#375DBB",
            fontSize: {
              xl: "16px",
              lg: "16px",
              md: "16px",
              sm: "14px",
              xs: "14px",
            },
            textTransform: "none",
            boxShadow: "0px 8px 16px rgba(30, 30, 30, 0.5)",
            // boxShadow: "0px 4px 8px ",
            transform: "scale(1)",
            transition: "0.5s ease-in-out",
            "&:hover": {
              background: "#fff",
              color: "#375DBB",
              transform: "scale(1.1)",
              // animation: `${buttonAnimation} 1s ease forwards`,
            },
          }}
          onClick={() => {
            navigate("/explore");
          }}
        >
          Explore Marketplace
        </Button>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontSize: {
                  xl: "35px",
                  lg: "35px",
                  md: "35px",
                  sm: "25px",
                  xs: "25px",
                },
                fontWeight: 600,
                textTransform: "none",
              }}
            >
              Active
            </Typography>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...gradientText,
                fontSize: {
                  xl: "35px",
                  lg: "35px",
                  md: "35px",
                  sm: "25px",
                  xs: "25px",
                },
                fontWeight: 600,
                textTransform: "none",
              }}
            >
              Categories
            </Typography>
          </Fade>
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ padding: "20px 60px" }}>
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Airline.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/AirlineWhite.svg`}
          label="Airline Ticket"
          name="Airlines Tickets"
          width={""}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Hotel.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/HotelWhite.svg`}
          label="Hotel"
          name="Hotel"
          mt={-0.2}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/OfficeSupply.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/OfficeSupplyWhite.svg`}
          label="Office Supply"
          name="Office Supply"
          width={"28px"}
          mt={0.6}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/RestaurantQSR.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/RestaurantQSRWhite.svg`}
          label="Restaurant QSR"
          name="QSR"
          width={"38px"}
          mt={-0.5}
        />
        <Grid item xs={6} sm={6} md={0.8} lg={0.8} xl={0.8}>
          <Box sx={categoriesEmptyBoxDesign} />
        </Grid>
        <Grid item xs={6} sm={6} md={0.8} lg={0.8} xl={0.8}>
          <Box sx={categoriesEmptyBoxDesign} />
        </Grid>
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Media.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/MediaWhite.svg`}
          label="Media"
          name="Media"
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Textile.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/TextileWhite.svg`}
          label="Textile"
          name="Textile"
          width={"40px"}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/FMCG.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/FMCGWhite.svg`}
          label="FMCG"
          name="FMCG"
          width={"35px"}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Mobility.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/MobilityWhite.svg`}
          label="Mobility"
          name="Mobility"
          width={"45px"}
          mt={-1.5}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Lifestyle.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/LifestyleWhite.svg`}
          label="Lifestyle"
          name="Lifestyle"
          width={"38px"}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Events.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/EventsWhite.svg`}
          label="Entertainment & Events"
          name="Entertainment & Events"
          width={"35px"}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Electronics.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/ElectronicsWhite.svg`}
          label="Electronics"
          name="Electronics"
          width={"35px"}
        />
        <GridItem
          imgSrc={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/Other.svg`}
          imgSrcOnHover={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HomePageImages/OtherWhite.svg`}
          label="Other"
          name="Others"
          width={"25px"}
          mt={1.2}
        />
        <Grid item xs={6} sm={6} md={0.8} lg={0.8} xl={0.8}>
          <Box sx={categoriesEmptyBoxDesign} />
        </Grid>
      </Grid>

      <Box id="hello">
        <Grid
          container
          spacing={4}
          sx={{ padding: "55px 0 0 0" }}
          ref={props.scollRef}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                marginLeft: {
                  xl: "60px",
                  lg: "60px",
                  md: "60px",
                  sm: "10px",
                  xs: "10px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xl: "flex-start",
                    lg: "flex-start",
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...headingStyle,
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "25px",
                        xs: "25px",
                      },
                      fontWeight: 600,
                    }}
                  >
                    What is
                  </Typography>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...gradientText,
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "25px",
                        xs: "25px",
                      },
                      fontWeight: 600,
                    }}
                  >
                    BXI ?
                  </Typography>
                </Fade>
              </Box>
              <Typography variant="inherit" sx={contentStyleee}>
                <span style={boldTextStyle}>
                  BXI, which stands for Barter Exchange of India,
                </span>{" "}
                is a digital marketplace for businesses in India, where
                companies come together to exchange products using{" "}
                <Link to="/howitworks" style={{ textDecoration: "none" }}>
                  "Barter coins"
                </Link>
                .
              </Typography>

              <Typography sx={contentStyle}>
                BXI acts as a third-party record keeper of transactions, that
                happens and makes it easy for businesses to exchange things they
                have, for things they need.
              </Typography>
              {/* <Typography sx={contentStyle}>
                BXI is third party record keeper for this transactions and helps
                to facilitate the Barter transactions among the registered
                members on the marketplace.
              </Typography> */}
              <Typography sx={{ ...contentStyle, mb: 1 }}>
                Organizations Pay By Products for what they want in exchange.
              </Typography>
              <Typography sx={{ ...contentStyle, mb: 1 }}>
                BXI is a business community where reputed organizations join to
                exchange their goods and services, making it a lively
                marketplace where businesses find what they're looking for
                without paying in Cash but on Barter!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: {
                    xl: "600px",
                    lg: "600px",
                    md: "600px",
                    sm: "350px",
                    xs: "350px",
                  },
                  minWidth: {
                    xl: "600px",
                    lg: "600px",
                    md: "600px",
                    sm: "350px",
                    xs: "350px",
                  },
                  minHeight: "338px",
                  maxHeight: "338px",
                  height: "100%",
                  // clipPath: "polygon(-100% 0%, 100% -64%, 100% 64%, 58% 100%)",
                  transform: "rotate(90deg)",
                }}
              >
                <iframe
                  // controls
                  autoPlay
                  controlsList="nodownload"
                  src={
                    "https://www.youtube.com/embed/F0pISDazeck?si=c3W9zHfaCduypKTI"
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                    transform: "rotate(-90deg)",
                    borderRadius: "20px",
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
                    border: "none",
                  }}
                  allowFullScreen
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Brands Logo  */}
      <Box sx={{ marginBottom: "30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontWeight: 600,
                fontSize: {
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "25px",
                  xs: "25px",
                },
              }}
            >
              Brands
            </Typography>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...gradientText,
                fontSize: {
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "25px",
                  xs: "25px",
                },
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              Active With BXI
            </Typography>
          </Fade>
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <HorizontalScrollingList fromLeft sx={{ mt: 1 }}>
            {clients.map((client, idx) => {
              return (
                <ClientBox
                  key={idx}
                  image={client.image}
                  directions={props.directions}
                />
              );
            })}
          </HorizontalScrollingList>
          <ClientMarquee />
        </Box>
      </Box>
      <Box
        sx={{
          height: "230px",
          background: "#00AFDF",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Fade top duration={1000}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: {
                xs: "25px",
                sm: "25px",
                md: "30px",
                xl: "30px",
                lg: "30px",
              },
              lineHeight: {
                xl: "72px",
                lg: "72px",
                md: "72px",
                sm: "42px",
                xs: "42px",
              },
              color: "#FFFFFF",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            Do you own or Operate a business in
          </Typography>
        </Fade>
        {/* <Fade top duration={1000}> */}
        <Typography
          ref={typewriterRef}
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 650,
            fontSize: {
              xs: "25px",
              sm: "25px",
              md: "30px",
              xl: "30px",
              lg: "30px",
            },
            lineHeight: "40px",
            color: "#000000",
            textTransform: "uppercase",
          }}
        >
          Office Supply
        </Typography>
        {/* </Fade> */}
      </Box>
      <Box
        sx={{
          height: "280px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Fade top duration={1000}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: {
                xl: "30px",
                lg: "30px",
                md: "30px",
                sm: "25px",
                xs: "25px",
              },
              lineHeight: {
                xl: "45px",
                lg: "45px",
                md: "45px",
                sm: "40px",
                xs: "40px",
              },
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              // letterSpacing: "-1.62343px",
              color: "#00AFDF",
              marginBottom: "20px",
              textTransform: "uppercase",
            }}
          >
            “ Sell Buy Exchange Through Barter “
          </Typography>
        </Fade>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: {
              xl: "27px",
              lg: "27px",
              md: "27px",
              sm: "24px",
              xs: "24px",
            },
            lineHeight: "25px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            // letterSpacing: "-1.62343px",
            color: "#121136",
            textTransform: "uppercase",
          }}
        >
          Pay By Products
        </Typography>
        <Button
          sx={{
            mt: 2,
            background: "#00AFDF",
            borderRadius: "31px",
            width: {
              xl: "250px",
              lg: "250px",
              md: "250px",
              sm: "180px",
              xs: "180px",
            },
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "0px 8px 16px rgba(30, 30, 30, 0.5)",
            transform: "scale(1)",
            transition: "0.5s ease-in-out",
            "&:hover": {
              borderRadius: "30px",
              // border: "3px solid #000 ",
              background: "#00AFDF",
              // animation: `${buttonAnimation} 1s ease forwards`,
              transform: "scale(1.1)",
            },
          }}
          onClick={() => {
            navigate("/createaccount");
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: {
                xl: "22px",
                lg: "22px",
                md: "22px",
                sm: "20px",
                xs: "20px",
              },
              lineHeight: "36px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: "#FFFFFF",
              textTransform: "none",
            }}
          >
            Join Now
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          // overflow: "hidden",
          // height: "1000.53px",
          height: {
            xl: "95vh",
            lg: "95vh",
            md: "95vh",
            sm: "75vh",
            xs: "75vh",
          },
          background: "rgba(130, 114, 181, 1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        inView={inView1}
      >
        {/* new comp */}
        <AnimationBigArrrows
          landingarrowone={landingarrowone}
          landingarrow={landingarrow}
          isReset={isReset}
        />

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginTop: "100px",
              position: "absolute",
              top: "17%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
              // marginLeft:"365px",
            }}
          >
            <Fade top duration={1000}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                    lg: "24px",
                    xl: "28px",
                  },
                  lineHeight: "25px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0px",
                  color: "#FFF",
                  marginTop: {
                    xs: "-80px",
                    sm: "-80px",
                    md: "-90px",
                    lg: "-95px",
                  },
                  marginLeft: 0,
                }}
              >
                Endorsements
              </Typography>
            </Fade>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: { xs: "30px", sm: "40px", md: "40px", lg: "40px" },
            }}
          >
            <Box
              sx={{
                height: {
                  xs: "500px",
                  sm: "500px",
                  md: "500px",
                  lg: "600px",
                  xl: "600px",
                },
                width: {
                  xs: "300px",
                  sm: "600px",
                  md: "600px",
                  lg: "1200px",
                  xl: "1200px",
                },
                marginTop: "30px",
              }}
            >
              <Slider {...settings}>
                {cards.map((data, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component={"video"}
                        poster={data.poster_url ? data.poster_url : ""}
                        controls
                        src={data?.content}
                        muted
                        height="500px"
                        sx={{
                          width: {
                            xs: "80%",
                            sm: "85%",
                            md: "90%",
                            lg: "90%",
                            xl: "90%",
                          },
                          maxWidth: "400px",
                          height: {
                            xs: "400px",
                            sm: "400px",
                            md: "400px",
                            lg: "600px",
                            xl: "600px",
                          },
                          transition: "transform 0.3s ease",
                          cursor: "pointer",
                          objectFit: "fill",
                        }}
                      />
                    </Box>
                  </div>
                ))}
              </Slider>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* map  */}
      <Box>
        <Grid
          container
          spacing={0}
          sx={{
            py: "10px",
          }}
          inView={inView}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                width: "80%",
                maxWidth: "80%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                marginLeft: "70px",
                marginTop: "40px",
              }}
            >
              <motion.div
                ref={ref}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={controls}
                transition={{
                  duration: 0.5,
                  delay: 0.2,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <img
                  src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/IndiaMap.png`}
                  alt="IndiaMap"
                  style={{ width: "90%", height: "auto" }}
                />
              </motion.div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                marginTop: {
                  xl: "130px",
                  lg: "130px",
                  md: "100px",
                  sm: "80px",
                  xs: "80px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xl: "flex-start",
                    lg: "flex-start",
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...headingStyle,
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "25px",
                        xs: "25px",
                      },
                      lineHeight: "44px",
                      textAlign: "center",
                    }}
                  >
                    Making
                  </Typography>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...gradientText,
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "25px",
                        xs: "25px",
                      },
                      lineHeight: "44px",
                      textAlign: "center",
                    }}
                  >
                    A Difference
                  </Typography>
                </Fade>
              </Box>
              <Fade top duration={1000}>
                <Typography sx={gradientSubHeadTypo}>
                  Think Smarter , Let’s Barter !
                </Typography>
              </Fade>
            </Box>
            <Box
              sx={{
                paddingRight: {
                  xl: "80px",
                  lg: "80px",
                  md: "80px",
                  sm: "50px",
                  xs: "50px",
                },
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
                ml: { xl: 0, lg: 0, md: 0, sm: "50px", xs: "50px" },
              }}
              ref={ref}
            >
              <Box>
                <Box
                  sx={{
                    borderLeft: {
                      xl: "4px solid #2264A1",
                      lg: "4px solid #2264A1",
                      md: "3px solid #2264A1",
                      sm: "2px solid #2264A1",
                      xs: "2px solid #2264A1",
                    },
                    paddingLeft: "10px",
                    marginBottom: "80px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {/* <Typography sx={numbersDesign}>150</Typography> */}
                    <Typography sx={numbersDesign} id="count1"></Typography>
                    <Typography sx={{ ...numbersDesign, color: "#445FD2" }}>
                      +
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: {
                        xl: "17px",
                        lg: "17px",
                        md: "17px",
                        sm: "13px",
                        xs: "13px",
                      },
                      lineHeight: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#2E2E2E",
                    }}
                  >
                    Happy Businesses
                  </Typography>
                </Box>

                <Box
                  sx={{
                    borderLeft: {
                      xl: "4px solid #2264A1",
                      lg: "4px solid #2264A1",
                      md: "3px solid #2264A1",
                      sm: "2px solid #2264A1",
                      xs: "2px solid #2264A1",
                    },
                    paddingLeft: "10px",
                    minHeight: "80px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {/* <Typography sx={numbersDesign}>22</Typography> */}
                    <Typography sx={numbersDesign} id="count2"></Typography>
                    <Typography sx={{ ...numbersDesign, color: "#445FD2" }}>
                      +
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: {
                        xl: "17px",
                        lg: "17px",
                        md: "17px",
                        sm: "13px",
                        xs: "13px",
                      },
                      lineHeight: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#2E2E2E",
                    }}
                  >
                    Cities
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    borderLeft: {
                      xl: "4px solid #2264A1",
                      lg: "4px solid #2264A1",
                      md: "3px solid #2264A1",
                      sm: "2px solid #2264A1",
                      xs: "2px solid #2264A1",
                    },
                    minHeight: "80px",
                    paddingLeft: "10px",
                    marginBottom: "80px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {/* <Typography sx={numbersDesign}>600</Typography> */}
                    <Typography sx={numbersDesign} id="count3"></Typography>
                    <Typography sx={{ ...numbersDesign, color: "#445FD2" }}>
                      +
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: {
                        xl: "17px",
                        lg: "17px",
                        md: "17px",
                        sm: "13px",
                        xs: "13px",
                      },
                      lineHeight: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#2E2E2E",
                    }}
                  >
                    Transactions
                  </Typography>
                </Box>

                <Box
                  sx={{
                    borderLeft: {
                      xl: "4px solid #2264A1",
                      lg: "4px solid #2264A1",
                      md: "3px solid #2264A1",
                      sm: "2px solid #2264A1",
                      xs: "2px solid #2264A1",
                    },
                    minHeight: "80px",
                    paddingLeft: "10px",
                    mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {/* <Typography sx={numbersDesign}>12K</Typography> */}
                    <Typography sx={numbersDesign} id="count4"></Typography>
                    <Typography sx={{ ...numbersDesign, color: "#445FD2" }}>
                      +
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: {
                        xl: "17px",
                        lg: "17px",
                        md: "17px",
                        sm: "13px",
                        xs: "13px",
                      },
                      lineHeight: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#2E2E2E",
                    }}
                  >
                    Products & Services
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* NEWSFEED  */}
      <Box mb={5}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: {
              xl: "10px",
              lg: "10px",
              md: "10px",
              sm: "30px",
              xs: "30px",
            },
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontSize: {
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "25px",
                  xs: "25px",
                },
                lineHeight: "44px",
              }}
            >
              NEWSFEED
            </Typography>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...gradientText,
                fontSize: {
                  xl: "30px",
                  lg: "30px",
                  md: "30px",
                  sm: "25px",
                  xs: "25px",
                },
                lineHeight: "44px",
              }}
            >
              & BLOGS
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
        >
          <Typography sx={subNewsText}>
            Insights and Tips from Barter Exchange of India
          </Typography>
        </Box>

        <Slider {...settings}>
          <div>
            <a
              href="/advantages-and-disadvantages-of-the-barter-system-of-exchange"
              target="_blank"
              alt="wtsp"
              style={{
                color: "inherit",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <img
                  src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LegalTax.png`}
                  alt="Implications_Of_Barter"
                  style={{
                    width: "85%",
                    maxWidth: "330px",
                    height: "auto",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                  }}
                />
              </Box>
            </a>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ ...newsFeedTypoStyle, width: "300px" }}>
                Advantages and disadvantages of the barter system of exchange
              </Typography>
            </Box>
          </div>
          <div>
            <a
              href="/difference-between-barter-exchange-and-money-exchange"
              target="_blank"
              alt="wtsp"
              style={{
                color: "inherit",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <img
                  src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/FutureofBarter.png`}
                  alt="Future_Of_Barter"
                  style={{
                    width: "85%",
                    maxWidth: "330px",
                    height: "auto",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                  }}
                />
              </Box>
            </a>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={newsFeedTypoStyle}>
                The Difference Between Barter Exchange and Money Exchange
              </Typography>
            </Box>
          </div>
          <div>
            <a
              href="/barter-exchange-goods-without-involving-money"
              target="_blank"
              alt="wtsp"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <img
                  src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BenefitsofBartering.png`}
                  alt="Benefits_Of_Bartering"
                  style={{
                    width: "85%",
                    maxWidth: "330px",
                    height: "auto",
                    width: "85%",
                    maxWidth: "330px",
                    height: "auto",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                    // filter: "sepia(0.5) saturate(1.5) hue-rotate(330deg)",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                  }}
                />
              </Box>
            </a>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={newsFeedTypoStyle}>
                The Ultimate Guide to Barter Exchange goods without involving
                money
              </Typography>
            </Box>
          </div>
          <div>
            <a
              href="/barter-trade-companies"
              target="_blank"
              alt="wtsp"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <img
                  src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LegalTax.png`}
                  alt="Implications_Of_Barter"
                  style={{
                    width: "85%",
                    maxWidth: "330px",
                    height: "auto",
                    transition: "transform 0.3s ease",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = "scale(1)";
                  }}
                />
              </Box>
            </a>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ ...newsFeedTypoStyle, width: "300px" }}>
                Barter trade Companies
              </Typography>
            </Box>
          </div>
        </Slider>
      </Box>
    </>
  );
};
{
  /* onClick={openEmail} */
}
export default LandingPageBody;
const contentStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "14px", xs: "12px" },
  display: "flex",
  alignItems: "center",
  color: "rgba(73, 75, 122, 1)",
  marginBottom: "15px",
  // textTransform: "lowercase",
};

const contentStyleee = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "14px", xs: "12px" },
  // display: "flex",
  alignItems: "center",
  color: "rgba(73, 75, 122, 1)",
  marginBottom: "15px",
};
const boldTextStyle = {
  fontWeight: "bold",
};
const gradientText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "30px",
    lg: "25px",
    xl: "25px",
  },
  textTransform: "uppercase",
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  background:
    "linear-gradient(90deg, rgba(55, 93, 187, 1), rgba(7, 167, 247, 1))",

  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};
const headingStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "30px",
    sm: "30px",
    md: "30px",
    lg: "30px",
    xl: "30px",
  },
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  // letterSpacing: "-1.62343px",
  color: "#0D0E0E",
  marginRight: "10px",
  textTransform: "uppercase",
  textAlign: "center",
};
const gradientSubHeadTypo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "15px",
    sm: "18px",
    md: "18px",
    xl: "18px",
    lg: "18px",
  },
  lineHeight: "30px",
  display: "flex",
  alignItems: "center",
  color: "rgba(73, 75, 122, 1)",
  textTransform: "uppercase",
  justifyContent: {
    xl: "flex-start",
    lg: "flex-start",
    md: "flex-start",
    sm: "center",
    xs: "center",
  },
};
const numbersDesign = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "40px", lg: "40px", md: "40px", sm: "30px", xs: "30px" },
  // lineHeight: "18px",
  display: "flex",
  alignItems: "center",
  color: "#2E2E2E",
};
const newsFeedTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "12px", xs: "10px" },
  lineHeight: { xl: "29px", lg: "29px", md: "29px", sm: "19px", xs: "19px" },
  alignItems: "center",
  textAlign: "center",
  textTransform: "capitalize",
  color: "rgba(73, 75, 122, 1)",
  marginTop: "20px",
  width: "317.99px",
  height: "58px",
};
const subNewsText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "16px",
    sm: "20px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  lineHeight: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(73, 75, 122, 1)",
  textAlign: "center",
};
const categoriesEmptyBoxDesign = {
  display: { xl: "flex", lg: "flex", md: "flex", sm: "none", xs: "none" },
  border: "1.5px solid #2261A2",
  borderRadius: " 15.121169090270996px",
  width: "100%",
  height: "94.1357421875px",
};
