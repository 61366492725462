import { Box, Paper, Typography } from "@mui/material";
import React from "react";

import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Legal_Policies from "../Legal_Policies";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Legal Policy | BXI</title>
        <meta property="og:title" content="Legal Policy | BXI" />
        <meta
          name="og:description"
          content="Discover how BXI safeguards your privacy with our transparent Legal Policy. Learn about our data protection measures and your rights as a user."
        />
        <meta
          name="description"
          content="Discover how BXI safeguards your privacy with our transparent Legal Policy. Learn about our data protection measures and your rights as a user."
        />
      </Helmet>
      <Box>
        <Navbar />
      </Box>
      <ToastContainer style={{ fontSize: "16px" }} />

      <Paper
        sx={{ mt: "100px", width: "100%", border: "none", boxShadow: "none" }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "36px",
            color: "#6B7A99",
            textAlign: "center",
            mx: "auto",
          }}
        >
          Legal Policy{" "}
        </Typography>
      </Paper>
      <Paper elevation={0}>
        <Box sx={{ ...ContactBoxOneStyle, mt: "50px" }}>
          <Legal_Policies />
        </Box>
      </Paper>

      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;

const ContactBoxOneStyle = {
  width: "90%",
  height: "auto",
  background: "#FFFFFF",
  fontFamily: "Poppins",
  borderRadius: "10px",
  zIndex: 10,
  display: "block",
  justifyContent: "space-evenly",
  mx: "auto",
};
