import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Typography,
  Select,
  MenuItem,
  Button,
  FormGroup,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
// import { useUpdateProductQuery } from "./ProductHooksQuery";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import { Form } from "react-hook-form";
import Bxitoken from "../../../../assets/Images/CommonImages/BXIToken.svg";
import axios from "axios";
const unitsForVolume = ["ml", "oz", "l", "cu ft", "g", "kg", "lbs"];
const unitsForShelfLife = ["days", "months"];
const UnitsOfTemprature = ["C", "F"];
const unitsForBatteryCapacity = ["mAh", "Ah", "Kwh"];
const UnitsOfNutritonValue = ["g", "%DV", "tonne", "kg", "g", "mlg", "mig"];
const unitsForShoesSize = ["cm", "inch"];
const unitsForPowerConsumption = ["W", "KW", "HP", "V", "A"];
const unitsOfResolutions = ["PX", "DPI", "PPI", "LPI"];
const unitsOfFrequency = ["Hz", "KHz", "MHz", "GHz"];
const unitsOfPressure = ["psi", "kPa"];
const GSTOptions = [0, 5, 12, 18, 28];
const showSizesToRender = {
  usShoeSizes: [
    "",
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
  ],
  ukShoeSizes: [
    "",
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10.5,
    11,
    11.5,
  ],
  EuShoeSizes: ["", 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
};

export default function ElectronicsTextileProductInform(props) {
  const ProductId = useParams().id;
  const navigate = useNavigate();

  const [SampleAvailability, setSampleAvailability] = useState();

  const [showContent, setShowContent] = useState(false);

  const handleCheckboxChange = (event) => {
    setShowContent(event.target.checked);
  };
  const [selected, SetSelected] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [storehsn, setStorehsn] = useState();
  const [customhsnFields, setCustomHSNFields] = useState(false);
  const [GSTData, setGSTData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`Update_TDS_GST/get_all_gst`);
        setGSTData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        ProductColor: z.string().length(7),
        ProductSize: z.any(),
        GST: z.coerce.number().lte(28).int({
          message: "GST must be not a decimal",
        }),
        HSN: z
          .string()
          .regex(/^\d{4}$|^\d{6}$|^\d{8}$/, {
            message: "HSN must be 4, 6, or 8 digits",
          })
          .transform((value) => value?.trim()),
        ProductIdType: z.string().min(1),
        PricePerUnit: z.string().min(1),
        DiscountedPrice: z.string().min(1),
        MinOrderQuantity: z.string().min(1),
        MaxOrderQuantity: z.string().min(1),

        sampleavailability: showContent ? z.string().min(1) : z.any(),
        priceofsample: showContent ? z.string().min(1) : z.any(),

        length:
          props.size === "Length" ||
          props.size === "Length x Height" ||
          props.size === "Length x Height x Width" ||
          props.size === "Custom Size"
            ? z.string().min(1)
            : z.string().optional(),
        width:
          props.size === "Length x Height x Width"
            ? z.string().min(1)
            : z.string().optional(),
        height:
          props.size === "Length x Height" ||
          props.size === "Length x Height x Width"
            ? z.string().min(1)
            : z.string().optional(),
        MeasurementUnit: z.string().min(1),
        listPeriod: z.any(),

        gsm: props.size === "GSM" ? z.string().min(1) : z.string().optional(),
        volume:
          props.size === "Volume" ? z.string().min(1) : z.string().optional(),
        calories:
          props.size === "CalorieCount"
            ? z.string().min(1)
            : z.string().optional(),
        shelfLife:
          props.size === "ShelfLife"
            ? z.string().min(1)
            : z.string().optional(),
        nutritionalinformation:
          props.size === "Nutritionalinformation"
            ? z.string().min(1)
            : z.string().optional(),
        temprature:
          props.size === "Temprature"
            ? z.string().min(1)
            : z.string().optional(),
        ShoeSize:
          props.size === "Shoes"
            ? z.coerce.number().optional()
            : z.string().optional(),
        batteryCapacity:
          props.size === "BatteryCapacity"
            ? z.string().min(1)
            : z.string().optional(),
        powerConsumption:
          props.size === "PowerConsumption"
            ? z.string().min(1)
            : z.string().optional(),
        resolution:
          props.size === "Resolution"
            ? z.string().min(1)
            : z.string().optional(),
        amplification:
          props.size === "Amplification"
            ? z.string().min(1)
            : z.string().optional(),
        frequency:
          props.size === "Frequency"
            ? z.string().min(1)
            : z.string().optional(),

        humidity:
          props.size === "Humidity" ? z.string().min(1) : z.string().optional(),
        pressure:
          props.size === "Pressure" ? z.string().min(1) : z.string().optional(),
      })
    ),
  });

  let GST = 0;
  let ShoeSize = getValues().ShoeSize;

  useEffect(() => {
    if (props.defaultValue == null) {
      return;
    }
    for (const [key, value] of Object.entries(props.defaultValue)) {
      setValue(key, value);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    {
      !props?.HSNData
        ? setValue("GST", 0)
        : props?.HSNData?.filter((item) => {
            return item.HSN === storehsn;
          })?.map((item, index) => {
            GST = item.GST;
          });
    }
  }, [GST, storehsn]);

  useEffect(() => {
    setValue("ProductColor", color);
    setValue("GST", GST);
  }, [color, GST]);

  return (
    <Box>
      <Box
        sx={{
          my: 3,
          border: "1px solid #E3E3E3",
          borderRadius: "10px",
          height: "auto",
          minHeight: "100px",
          position: "relative",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          flexDirection: "row",
          gap: "10px",
          px: 2,
          py: 2,
        }}
      >
        <SizeChart size={props.size} register={register} errors={errors} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Color <span style={{ color: "red" }}> *</span>
          </Typography>
          <Box
            sx={{
              borderRadius: "10px",
              background: "white",
              width: "139px",
              height: "42px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              type="color"
              value={color}
              {...register("ProductColor", {
                onChange: (e) => {
                  setColor(e.target.value);
                },
              })}
              style={{
                height: "40px",
                width: "60px",
                border: "1px",
                cursor: "pointer",
              }}
            />
            <Input
              disableUnderline
              value={color}
              {...register("ProductColor", {
                onChange: (e) => {
                  setColor(e.target.value);
                },
              })}
              sx={{
                width: "139px",
                height: "42px",
                background: "#FFFFFF",
                borderRadius: "10px",
                px: 1,
                color: "#C64091",
              }}
            />
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.ProductColor?.message}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            HSN <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box sx={{ position: "relative" }}>
            <Input
              disableUnderline
              // required={true}
              placeholder="998346"
              {...register("HSN", {
                onChange: (event) => {
                  const inputValue = event.target.value;

                  if (inputValue.match(/\D/g)) {
                    const filteredValue = inputValue.replace(/\D/g, "");
                    event.target.value = filteredValue;
                  }
                },
              })}
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
              inputProps={{ maxLength: 8 }}
              sx={{
                width: "139px",
                height: "42px",
                background: "#FFFFFF",
                borderRadius: "10px",
                px: 1,
                fontSize: "12px",
                color: "#C64091",
                border: errors?.HSN?.message ? "1px solid red" : null,
              }}
            />
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.HSN?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            GST <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box sx={{ position: "relative" }}>
            <Select
              sx={{
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                width: "139px",
                height: "42px",
                background: "#FFFFFF",
                borderRadius: "10px",
                px: 1,
                fontSize: "12px",
                color: "#C64091",
                border: errors["GST"] ? "1px solid red" : null,
              }}
              defaultValue="0"
              {...register("GST")}
            >
              {GSTData?.map((gst, idx) => {
                return (
                  <MenuItem sx={MenuItems} value={gst?.GST}>
                    {gst?.GST}
                  </MenuItem>
                );
              })}
            </Select>

            <Typography
              sx={{
                position: "absolute",
                right: "25%",
                bottom: "25%",
                color: "#979797",
                fontSize: "15px",
              }}
            >
              %
            </Typography>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.GST?.message}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            MRP <span style={{ color: "red" }}> *</span>
            <span
              style={{ fontSize: "10px", marginTop: "0", whiteSpace: "nowrap" }}
            >
              (Incl of GST)
            </span>
          </Typography>

          <Box sx={{ position: "relative" }}>
            <Input
              disableUnderline
              // value={data.mro}
              placeholder="1000"
              {...register("PricePerUnit", {
                onChange: (event) => {
                  event.target.value = parseInt(
                    event.target.value.replace(/[^\d]+/gi, "") || 0
                  ).toLocaleString("en-US");
                },
              })}
              sx={{
                width: "139px",
                height: "42px",
                background: "#FFFFFF",
                borderRadius: "10px",
                fontSize: "12px",
                px: 1,
                color: "#C64091",
                border: errors["PricePerUnit"] ? "1px solid red" : null,
              }}
            />

            <img
              src={Bxitoken}
              style={{
                position: "absolute",
                width: "20px",
                right: "7%",
                bottom: "20%",
              }}
            />
          </Box>

          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.PricePerUnit?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Discounted MRP <span style={{ color: "red" }}> *</span>
          </Typography>
          <Box sx={{ position: "relative" }}>
            <Input
              disableUnderline
              // value={data.discount}
              placeholder="900"
              {...register("DiscountedPrice", {
                onChange: (event) => {
                  event.target.value = parseInt(
                    event.target.value.replace(/[^\d]+/gi, "") || 0
                  ).toLocaleString("en-US");
                },
              })}
              sx={{
                width: "139px",
                height: "42px",
                background: "#FFFFFF",
                borderRadius: "10px",
                fontSize: "12px",
                color: "#C64091",
                px: 1,
                border: errors["DiscountedPrice"] ? "1px solid red" : null,
              }}
            />
            <img
              src={Bxitoken}
              style={{
                position: "absolute",
                width: "20px",
                right: "7%",
                bottom: "20%",
              }}
            />
          </Box>

          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.DiscountedPrice?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={{ ...CommonTextStyle, whiteSpace: "nowrap" }}>
            Min Order Quantity <span style={{ color: "red" }}> *</span>
          </Typography>

          <Input
            disableUnderline
            // value={data.minimum}
            placeholder="1"
            {...register("MinOrderQuantity", {
              onChange: (event) => {
                event.target.value = parseInt(
                  event.target.value.replace(/[^\d]+/gi, "") || 0
                ).toLocaleString("en-US");
              },
            })}
            sx={{
              width: "139px",
              height: "42px",
              background: "#FFFFFF",
              borderRadius: "10px",
              fontSize: "12px",
              color: "#C64091",
              px: 1,
              border: errors["MinOrderQuantity"] ? "1px solid red" : null,
            }}
          />
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.MinOrderQuantity?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={{ ...CommonTextStyle, whiteSpace: "nowrap" }}>
            {" "}
            Max Order Quantity <span style={{ color: "red" }}> *</span>
          </Typography>

          <Input
            disableUnderline
            // value={data.maximum}
            placeholder="1000"
            {...register("MaxOrderQuantity", {
              onChange: (event) => {
                event.target.value = parseInt(
                  event.target.value.replace(/[^\d]+/gi, "") || 0
                ).toLocaleString("en-US");
              },
            })}
            sx={{
              width: "139px",
              height: "42px",
              background: "#FFFFFF",
              borderRadius: "10px",
              fontSize: "12px",
              color: "#C64091",
              px: 1,
              border: errors["MaxOrderQuantity"] ? "1px solid red" : null,
            }}
          />

          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.MaxOrderQuantity?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Product ID Type <span style={{ color: "red" }}> *</span>
          </Typography>
          <Input
            disableUnderline
            {...register("ProductIdType", {
              onChange: (event) => {
                const inputValue = event.target.value;

                if (inputValue.match(/[^a-zA-Z0-9\s]/gi)) {
                  const filteredValue = inputValue.replace(
                    /[^a-zA-Z0-9\s]/gi,
                    ""
                  );
                  event.target.value = filteredValue;
                }
              },
            })}
            placeholder="1910WH23"
            sx={{
              width: "139px",
              height: "42px",
              background: "#FFFFFF",
              borderRadius: "10px",
              fontSize: "12px",
              color: "#C64091",
              px: 1,
              border: errors["ProductIdType"] ? "1px solid red" : null,
            }}
            onKeyDown={(e) => {
              if (e.key === " " && e.target.selectionStart === 0) {
                e.preventDefault();
              }
            }}
          />
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.ProductIdType?.message}
          </Typography>
        </Box>

        <Box
          sx={{
            p: 1,
            mt: 2,
            width: "100%",
          }}
        >
          <FormGroup
            value={selected}
            onClick={() => {
              SetSelected(true);
            }}
          >
            <FormControlLabel
              sx={CommonTextStyle}
              control={
                <Checkbox
                  checked={showContent}
                  onChange={handleCheckboxChange}
                  name="showContent"
                  color="primary"
                />
              }
              label="Do you wish to provide a Sample ?"
            />
            {showContent && (
              <Box
                sx={{
                  mt: 1,
                  borderRadius: "10px",
                  height: "auto",
                  position: "relative",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  gap: "30px",
                  px: 0,
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    mt: 1,
                    maxWidth: "140px",
                  }}
                >
                  <Typography sx={{ ...CommonTextStyle, whiteSpace: "nowrap" }}>
                    Min Order Quantity <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Input
                    disableUnderline
                    placeholder="Eg. 1"
                    {...register("sampleavailability", {
                      onChange: (event) => {
                        event.target.value = parseInt(
                          event.target.value.replace(/[^\d]+/gi, "") || 0
                        ).toLocaleString("en-US");
                      },
                    })}
                    sx={{
                      width: "145px",
                      height: "42px",
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      fontSize: "12px",
                      color: "#C64091",
                      px: 1,
                      border: errors["sampleavailability"]
                        ? "1px solid red"
                        : null,
                    }}
                  />
                  <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
                    {errors?.SampleAvailability?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    mt: 1,
                    maxWidth: "140px",
                  }}
                >
                  <Typography sx={CommonTextStyle}> Price Of Sample</Typography>
                  <Box sx={{ position: "relative" }}>
                    <Input
                      disableUnderline
                      placeholder="Eg. 1234"
                      {...register("priceofsample", {
                        onChange: (event) => {
                          event.target.value = parseInt(
                            event.target.value.replace(/[^\d]+/gi, "") || 0
                          ).toLocaleString("en-US");
                        },
                      })}
                      sx={{
                        width: "145px",
                        height: "42px",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        fontSize: "12px",
                        color: "#C64091",
                        px: 1,
                        border: errors["priceofsample"]
                          ? "1px solid red"
                          : null,
                      }}
                    />
                    <img
                      src={Bxitoken}
                      style={{
                        position: "absolute",
                        width: "20px",
                        right: "7%",
                        bottom: "20%",
                      }}
                    />
                  </Box>
                  <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
                    {errors?.priceofsample?.message}
                  </Typography>
                </Box>
              </Box>
            )}
          </FormGroup>
        </Box>
      </Box>
      <Box>
        <Button
          // onClick={handleAdd}
          onClick={async () => {
            if ((await trigger()) === false) {
              return;
            }
            if (
              parseFloat(getValues().MaxOrderQuantity.replace(/,/g, "")) <
              parseFloat(getValues().MinOrderQuantity.replace(/,/g, ""))
            ) {
              setError("MaxOrderQuantity", {
                type: "custom",
                message:
                  "Max Order Quantity can not be less than Min Order Quantity",
              });
            } else if (
              parseFloat(getValues().PricePerUnit.replace(/,/g, "")) <
              parseFloat(getValues().DiscountedPrice.replace(/,/g, ""))
            ) {
              setError("PricePerUnit", {
                type: "custom",
                message: "PricePerUnit can not be less than DiscountedPrice",
              });
            } else {
              if (props?.HSNData && customhsnFields === false) {
                props?.HSNData?.filter((item) => {
                  return item.HSN === storehsn;
                })?.map((item, index) => {
                  GST = item.GST;
                });
                setValue("GST", GST);
              }
              if (props.size === "Length x Height x Width") {
                setValue(
                  "ProductSize",
                  `${getValues().length}${getValues().MeasurementUnit} x ${
                    getValues().height
                  }${getValues().MeasurementUnit} x ${getValues().width}${
                    getValues().MeasurementUnit
                  }`
                );
              } else if (props.size === "Length x Height") {
                setValue(
                  "ProductSize",
                  `${getValues().length}${getValues().MeasurementUnit} x ${
                    getValues().height
                  }${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Custom Size") {
                setValue(
                  "ProductSize",
                  `${getValues().length}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Length") {
                setValue(
                  "ProductSize",
                  `${getValues().length}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Weight") {
                setValue(
                  "ProductSize",
                  `${getValues().weight}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "GSM") {
                setValue(
                  "ProductSize",
                  `${getValues().gsm}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Volume") {
                setValue(
                  "ProductSize",
                  `${getValues().volume}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "CalorieCount") {
                setValue(
                  "ProductSize",
                  `${getValues().calories}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "ShelfLife") {
                setValue(
                  "ProductSize",
                  `${getValues().shelfLife}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Nutritionalinformation") {
                setValue(
                  "ProductSize",
                  `${getValues().nutritionalinformation}${
                    getValues().MeasurementUnit
                  }`
                );
              } else if (props.size === "Temprature") {
                setValue(
                  "ProductSize",
                  `${getValues().temprature}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Shoes") {
                setValue("ProductSize", `${getValues().ShoeSize}`);
              } else if (props.size === "BatteryCapacity") {
                setValue(
                  "ProductSize",
                  `${getValues().batteryCapacity}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "PowerConsumption") {
                setValue(
                  "ProductSize",
                  `${getValues().powerConsumption}${
                    getValues().MeasurementUnit
                  }`
                );
              } else if (props.size === "Resolution") {
                setValue(
                  "ProductSize",
                  `${getValues().resolution}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Amplification") {
                setValue(
                  "ProductSize",
                  `${getValues().amplification}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Frequency") {
                setValue(
                  "ProductSize",
                  `${getValues().frequency}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Humidity") {
                setValue(
                  "ProductSize",
                  `${getValues().humidity}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Pressure") {
                setValue(
                  "ProductSize",
                  `${getValues().pressure}${getValues().MeasurementUnit}`
                );
              } else if (props.size === "Custom Size") {
                setValue(
                  "ProductSize",
                  `${getValues().customsize}${getValues().MeasurementUnit}`
                );
              }

              props.append(getValues(), props.index);
              reset((formValues) => ({
                formValues,
                PricePerUnit: "",
                DiscountedPrice: "",
                MinOrderQuantity: "",
                MaxOrderQuantity: "",
                sampleavailability: "",
                priceofsample: "",
                length: "",
                height: "",
                width: "",
                weight: "",
              }));
            }
          }}
          sx={{
            width: "100%",
            height: "41px",
            background: "#C64091",
            borderRadius: "10px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#FFFFFF",
            textTransform: "none",
            "&:hover": {
              background: "#C64091",
            },
            my: 3,
          }}
        >
          Proceed to Add
        </Button>
      </Box>
    </Box>
  );
}

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const MenuItems = {
  fontSize: "12px",
  color: "#C64091",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};

const GW = {
  width: "55%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#FFFFFF",
  height: "100%",
  color: "#C64091",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};

function SizeChart(props) {
  const [shoeSizeCountry, setShoeSizeCountry] = useState(" ");

  switch (props.size) {
    case "Length":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Size</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.length?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("length")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"in"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value="in">
                in
              </MenuItem>
              <MenuItem sx={MenuItems} value="cm">
                cm
              </MenuItem>
              <MenuItem sx={MenuItems} value="mm">
                mm
              </MenuItem>
              <MenuItem sx={MenuItems} value="m">
                m
              </MenuItem>
              <MenuItem sx={MenuItems} value="km">
                km
              </MenuItem>
              <MenuItem sx={MenuItems} value="ft">
                ft
              </MenuItem>
              <MenuItem sx={MenuItems} value="yd">
                yd
              </MenuItem>
              <MenuItem sx={MenuItems} value="mi">
                mi
              </MenuItem>
              <MenuItem sx={MenuItems} value="nmi">
                nmi
              </MenuItem>
              <MenuItem sx={MenuItems} value="L">
                L
              </MenuItem>
              <MenuItem sx={MenuItems} value="W">
                W
              </MenuItem>
              <MenuItem sx={MenuItems} value="H">
                H
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.length?.message}
          </Typography>
        </Box>
      );
    case "Length x Height":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Size</Typography>
          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: 1,
              mx: "auto",
              borderRadius: "10px",
              border: props?.errors?.length?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("length")}
              id="standard-basic"
              variant="standard"
              placeholder="L"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "35%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />
            <TextField
              {...props.register("height")}
              id="standard-basic"
              variant="standard"
              placeholder="H"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "35%",
                height: "100%",
                background: "#FFFFFF",
              }}
            />

            <Select
              sx={{ ...GW, width: "30%" }}
              defaultValue={"in"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value="in">
                in
              </MenuItem>
              <MenuItem sx={MenuItems} value="cm">
                cm
              </MenuItem>
              <MenuItem sx={MenuItems} value="oz">
                oz
              </MenuItem>
              <MenuItem sx={MenuItems} value="g">
                g
              </MenuItem>
              <MenuItem sx={MenuItems} value="gb">
                gb
              </MenuItem>
              <MenuItem sx={MenuItems} value="tb">
                tb
              </MenuItem>
              <MenuItem sx={MenuItems} value="lb">
                lb
              </MenuItem>
              <MenuItem sx={MenuItems} value="kg">
                kg
              </MenuItem>
              <MenuItem sx={MenuItems} value="mah">
                mah
              </MenuItem>
              <MenuItem sx={MenuItems} value="w">
                w
              </MenuItem>
              <MenuItem sx={MenuItems} value="pixels">
                pixels
              </MenuItem>
              <MenuItem sx={MenuItems} value="hz">
                hz
              </MenuItem>
              <MenuItem sx={MenuItems} value="db">
                db
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.length?.message}
          </Typography>
        </Box>
      );
    case "Length x Height x Width":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Size</Typography>
          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: 1,
              mx: "auto",
              borderRadius: "10px",
              border: props?.errors?.length?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("length")}
              id="standard-basic"
              variant="standard"
              placeholder="L"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "20%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />
            <TextField
              {...props.register("height")}
              id="standard-basic"
              variant="standard"
              placeholder="H"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "20%",
                height: "100%",
                background: "#FFFFFF",
              }}
            />
            <TextField
              {...props.register("width")}
              id="standard-basic"
              variant="standard"
              placeholder="W"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "20%",
                height: "100%",
                background: "#FFFFFF",
              }}
            />
            <Select
              sx={{ ...GW, width: "40%" }}
              defaultValue={"in"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value="in">
                in
              </MenuItem>
              <MenuItem sx={MenuItems} value="cm">
                cm
              </MenuItem>
              <MenuItem sx={MenuItems} value="oz">
                oz
              </MenuItem>
              <MenuItem sx={MenuItems} value="g">
                g
              </MenuItem>
              <MenuItem sx={MenuItems} value="gb">
                gb
              </MenuItem>
              <MenuItem sx={MenuItems} value="tb">
                tb
              </MenuItem>
              <MenuItem sx={MenuItems} value="lb">
                lb
              </MenuItem>
              <MenuItem sx={MenuItems} value="kg">
                kg
              </MenuItem>
              <MenuItem sx={MenuItems} value="mah">
                mah
              </MenuItem>
              <MenuItem sx={MenuItems} value="w">
                w
              </MenuItem>
              <MenuItem sx={MenuItems} value="pixels">
                pixels
              </MenuItem>
              <MenuItem sx={MenuItems} value="hz">
                hz
              </MenuItem>
              <MenuItem sx={MenuItems} value="db">
                db
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.length?.message}
          </Typography>
        </Box>
      );
    case "GSM":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            GSM <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.gsm?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("gsm")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                disableUnderline: "true",
                style: {
                  color: "rgba(107, 122, 153)",
                  fontSize: "14px",
                  padding: "7px",
                  color: "#C64091",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"gsm"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value="gsm">
                gsm
              </MenuItem>
              <MenuItem sx={MenuItems} value="oz">
                oz
              </MenuItem>
              <MenuItem sx={MenuItems} value="sqyard">
                sq yard
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.gsm?.message}
          </Typography>
        </Box>
      );
    case "Volume":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Volume</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.volume?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("volume")}
              id="standard-basic"
              variant="standard"
              placeholder="volume"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"oz"}
              {...props.register("MeasurementUnit")}
            >
              {unitsForVolume?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.volume?.message}
          </Typography>
        </Box>
      );
    case "CalorieCount":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Calorie Count</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.calories?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("calories")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"cal"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value={"cal"}>
                cal
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.calories?.message}
          </Typography>
        </Box>
      );
    case "ShelfLife":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Shelf Life</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.shelfLife?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("shelfLife")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"days"}
              {...props.register("MeasurementUnit")}
            >
              {unitsForShelfLife?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.shelfLife?.message}
          </Typography>
        </Box>
      );
    case "NutritionalInformation":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Nutritional</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.NutritionInfo?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("NutritionInfo")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"g"}
              {...props.register("MeasurementUnit")}
            >
              {UnitsOfNutritonValue?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.NutritionInfo?.message}
          </Typography>
        </Box>
      );
    case "Temprature":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Temprature</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.temprature?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("temprature")}
              id="standard-basic"
              variant="standard"
              placeholder="Temprature"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"PX"}
              {...props.register("MeasurementUnit")}
            >
              {UnitsOfTemprature?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.temprature?.message}
          </Typography>
        </Box>
      );
    case "Shoes":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Size <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              display: "flex",
            }}
          >
            <Select
              sx={{
                ...GW,
                borderRadius: "10px 0px 0px 10px",
                borderRight: "0.5px solid grey",
              }}
              onChange={(e) => {
                setShoeSizeCountry(e.target.value);
              }}
            >
              <MenuItem sx={MenuItems} value={"usShoeSizes"}>
                US
              </MenuItem>
              <MenuItem sx={MenuItems} value={"ukShoeSizes"}>
                UK
              </MenuItem>
              <MenuItem sx={MenuItems} value={"EuShoeSizes"}>
                EU
              </MenuItem>
            </Select>

            <Select sx={GW} defaultValue={"30"} {...props.register("ShoeSize")}>
              {shoeSizeCountry &&
                showSizesToRender[shoeSizeCountry]?.map((item, idx) => {
                  return (
                    <MenuItem sx={MenuItems} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </Box>
        </Box>
      );
    case "BatteryCapacity":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Battery Capacity <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.batteryCapacity?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("batteryCapacity")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"mAh"}
              {...props.register("MeasurementUnit")}
            >
              {unitsForBatteryCapacity?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.batteryCapacity?.message}
          </Typography>
        </Box>
      );
    case "PowerConsumption":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Power <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.powerConsumption?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("powerConsumption")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"W"}
              {...props.register("MeasurementUnit")}
            >
              {unitsForPowerConsumption?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.powerConsumption?.message}
          </Typography>
        </Box>
      );
    case "Resolution":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Resolution <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.resolution?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("resolution")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"PX"}
              {...props.register("MeasurementUnit")}
            >
              {unitsOfResolutions?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.resolution?.message}
          </Typography>
        </Box>
      );
    case "Amplification":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Amplification <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.amplification?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("amplification")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"DB"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value={"DB"}>
                DB
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.amplification?.message}
          </Typography>
        </Box>
      );
    case "Frequency":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Frequency <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.frequency?.message
                ? "1px solid red"
                : null,
            }}
          >
            <TextField
              {...props.register("frequency")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"Hz"}
              {...props.register("MeasurementUnit")}
            >
              {unitsOfFrequency?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.frequency?.message}
          </Typography>
        </Box>
      );
    case "Humidity":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Humidity <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.humidity?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("humidity")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"Hz"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value="%">
                %
              </MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.humidity?.message}{" "}
          </Typography>
        </Box>
      );
    case "Pressure":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>
            Pressure <span style={{ color: "red" }}> *</span>
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.pressure?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("pressure")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"Hz"}
              {...props.register("MeasurementUnit")}
            >
              {unitsOfPressure?.map((unit) => {
                return (
                  <MenuItem sx={MenuItems} value={unit}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.pressure?.message}
          </Typography>
        </Box>
      );
    case "Custom Size":
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Size</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
              border: props?.errors?.length?.message ? "1px solid red" : null,
            }}
          >
            <TextField
              {...props.register("length")}
              id="standard-basic"
              variant="standard"
              placeholder="Amt"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
                borderRight: "0.5px solid rgba(107, 122, 153)",
              }}
            />
            <TextField
              {...props.register("MeasurementUnit")}
              id="standard-basic"
              variant="standard"
              placeholder="unit"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "rgba(107, 122, 153)",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {props?.errors?.length?.message}
          </Typography>
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            mt: 1,
            maxWidth: "140px",
          }}
        >
          <Typography sx={CommonTextStyle}>Size</Typography>

          <Box
            sx={{
              width: "100%",
              height: "42px",
              mt: "1%",
              borderRadius: "10px",
            }}
          >
            <TextField
              {...props.register("length")}
              id="standard-basic"
              variant="standard"
              placeholder=""
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#C64091",
                  fontSize: "14px",
                  padding: "7px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "45%",
                height: "100%",
                background: "#FFFFFF",
                borderRadius: "10px 0px 0px 10px",
              }}
            />

            <Select
              sx={GW}
              defaultValue={"in"}
              {...props.register("MeasurementUnit")}
            >
              <MenuItem sx={MenuItems} value="in">
                in
              </MenuItem>
              <MenuItem sx={MenuItems} value="cm">
                cm
              </MenuItem>
              <MenuItem sx={MenuItems} value="oz">
                oz
              </MenuItem>
              <MenuItem sx={MenuItems} value="g">
                g
              </MenuItem>
              <MenuItem sx={MenuItems} value="gb">
                gb
              </MenuItem>
              <MenuItem sx={MenuItems} value="tb">
                tb
              </MenuItem>
              <MenuItem sx={MenuItems} value="lb">
                lb
              </MenuItem>
              <MenuItem sx={MenuItems} value="kg">
                kg
              </MenuItem>
              <MenuItem sx={MenuItems} value="mah">
                mah
              </MenuItem>
              <MenuItem sx={MenuItems} value="w">
                w
              </MenuItem>
              <MenuItem sx={MenuItems} value="pixels">
                pixels
              </MenuItem>
              <MenuItem sx={MenuItems} value="hz">
                hz
              </MenuItem>
              <MenuItem sx={MenuItems} value="db">
                db
              </MenuItem>
            </Select>
          </Box>
        </Box>
      );
  }
}
