import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../assets/Images/CloseIcon.svg";
import SenderLogo from "../../assets/Images/SenderLogo.png";
import "./BarterGPTModal.css";
import axios from "axios";
import { useBarterGptChatHistory } from "../../Hooks/BarterGptChatHistory/BarterGptChatHistory";

function BarterGPTModalForNewbies(props) {
  const [nameValidated, setNameValidated] = useState(false);
  const [phoneNumberValidated, setPhoneNumberValidated] = useState(false);

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm BXI Genie! Please let me know your name",
      sentTime: "just now",
      sender: "assistant",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  let countnumber = 0;

  const {
    mutate: storeChat,
    loading: storeChatLoading,
    error: storeChatError,
    refetch: storeChatRefetch,
  } = useBarterGptChatHistory();

  async function processMessageToChatGPT(chatMessages, newMessage) {
    try {
      setIsTyping(true);

      const chatResponse = await axios.post(
        "https://chatpdf.unada.in/api/v1/chat-bgpt",
        {
          // old source ids
          // source_id: "src_6hTMab96vZcmpGzXEe6RL",
          // source_id: "src_epMJWwLmTtOws3SthDClL",
          // source_id: "src_ixpYCT9jn7wGTD0CwLtel",
          source_id: "src_8m04pZpcWutny5ihRxsuC",
          messages: [{ content: newMessage, role: "user" }],
        }
      );

      const userMessage = `Please rephrase this answer by removing any mentions of pdf or doc and don't apologise and don't give any page references and consider BXI as Barter Exchange of India for all references. Also make all responses a maximum of 7 lines. For all discussions put your name as BXI Genie instead of AI assistant. If you don't have an answer simply say that - I currently don't have a response. Please try again later! : "${chatResponse.data.content}"`;

      const gptResponse = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer 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",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: userMessage }],
          }),
        }
      ).then((res) => {
        return res.json();
      });

      const gptContent = gptResponse.choices[0].message.content;

      // Create a copy of the messages array and add the response to it
      const updatedMessages = [
        ...chatMessages,
        {
          message: gptContent,
          sender: "ChatGPT",
        },
      ];
      setMessages(updatedMessages);
      setIsTyping(false);
      return gptContent;
    } catch (error) {
      return alert(
        "we are sorry for the inconvenience , something went wrong try later"
      );
    }
  }

  const handleSend = async (message, sender) => {
    countnumber = countnumber + 1;
    const namePattern = /^[A-Za-z\s]+$/;
    const isValidName = namePattern?.test(message);
    const phoneNumberPattern = /^\d{10}$/;
    const isValidPhoneNumber = phoneNumberPattern?.test(message);

    if (countnumber === 1) {
      setMessages([]);
    }

    const newMessage = {
      message,
      direction: "outgoing",
      sentTime: "just now",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    if (!nameValidated) {
      if (!isValidName) {
        const invalidNameMessage = {
          message:
            "Your name should only contain letters and spaces. Please enter your name again.",
          sentTime: "just now",
          sender: "assistant",
        };
        setMessages([...newMessages, invalidNameMessage]);
      } else {
        setNameValidated(true);
        const mobileNumberMessage = {
          message:
            "Thank you for providing your name. Please enter your mobile number.",
          sentTime: "just now",
          sender: "name",
        };
        setMessages([...newMessages, mobileNumberMessage]);
      }
    } else if (!phoneNumberValidated) {
      if (!isValidPhoneNumber) {
        const invalidPhoneNumberMessage = {
          message:
            "Your mobile number is not valid. Please enter a valid 10-digit mobile number.",
          sentTime: "just now",
          sender: "assistant",
        };
        setMessages([...newMessages, invalidPhoneNumberMessage]);
      } else {
        setPhoneNumberValidated(true);

        const staticMessage = {
          message: "Hi, I'm Barter Genie, how can I help you?",
          sentTime: "just now",
          sender: "phone",
        };
        setMessages([...newMessages, staticMessage]);
      }
    }

    if (nameValidated && phoneNumberValidated) {
      const userResponse = message;

      if (userResponse !== null) {
        const chatGptResponse = await processMessageToChatGPT(
          newMessages,
          userResponse
        );
        const chatGptMessage = {
          message: chatGptResponse,
          sender: "ChatGPT",
          key: `chatGpt-${new Date().getTime()}`,
        };

        setMessages([...newMessages, chatGptMessage]);

        setIsTyping(false);
        if (nameValidated && phoneNumberValidated) {
          const userResponse = message;

          if (userResponse !== null) {
            const chatGptResponse = await processMessageToChatGPT(
              newMessages,
              userResponse
            );

            const chatGptMessage = {
              message: chatGptResponse,
              sender: "assistant",
              key: `chatGpt-${new Date().getTime()}`,
            };

            setMessages([...newMessages, chatGptMessage]);

            setIsTyping(false);
          }
        }
      }
    }
  };

  const handleSendEntireChat = async (messages) => {
    if (!nameValidated || !phoneNumberValidated) {
      return null;
    }

    const userData = {
      name: "",
      phone: "",
      chatData: messages,
    };

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const previousMessage = i > 0 ? messages[i - 1] : null;

      if (message.sender === "name" && previousMessage) {
        userData.name = previousMessage.message;
      }

      if (message.sender === "phone" && previousMessage) {
        userData.phone = previousMessage.message;
      }
    }

    await storeChat(userData);
  };

  return (
    <Box
      sx={{
        height: "100%",
        zIndex: 200,
        borderRadius: "0px 0px 10px 10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          minHeight: "80px",
          background: "#334588",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "15px 15px 0px 0px",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={CloseIcon}
          sx={{
            position: "absolute",
            right: "5%",
            top: "20%",
            cursor: "pointer",
          }}
          onClick={() => {
            props.onClose();
            handleSendEntireChat(messages);
          }}
        />
        <Typography sx={{ fontSize: "20px", color: "white" }}>
          Barter GPT
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "white" }}>
          I am your BXI Genie.
        </Typography>
      </Box>
      <Box
        sx={{
          height: "450px",
          // height: "auto",
          overflow: "scroll",
        }}
        className="maindiv"
      >
        <MainContainer
          sx={{
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <ChatContainer>
            <MessageList
              scrollBehavior="smooth"
              typingIndicator={
                isTyping ? (
                  <TypingIndicator
                    content="typing"
                    sx={{
                      background: "#fff",
                      width: "20%",
                      borderRadius: "20px",
                    }}
                  />
                ) : null
              }
            >
              {messages
                ?.filter((e) => e?.sender != "system")
                ?.map((message, i) => {
                  const isLastMessage = i === messages.length - 1;

                  return (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "10px",
                        marginBottom: isLastMessage ? "20px" : "0px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "15px",
                        }}
                      >
                        {message?.sender === "phone" ||
                        message?.sender === "assistant" ||
                        message?.sender === "name" ? (
                          <Box
                            component="img"
                            src={SenderLogo}
                            sx={{
                              height: "20px",
                              width: "20px",
                              marginTop: "10px",
                            }}
                          />
                        ) : null}
                      </Box>
                      <Typography
                        sx={{
                          width: "95%",
                          mx: "auto",
                          fontSize: "12px",
                          fontFamily: "poppins",
                          fontWeight: "400",
                          mb: 1.5,
                        }}
                      >
                        <Message key={i} model={message} />
                      </Typography>
                    </Box>
                  );
                })}
            </MessageList>
            <MessageInput
              placeholder="Type your message here..."
              autoFocus={true}
              onSend={handleSend}
              sx={{
                display: "flex",
                bottom: "0%",
                width: "95%",
                mx: "auto",
                p: 1,
              }}
            />
          </ChatContainer>
        </MainContainer>
      </Box>
    </Box>
  );
}

export default BarterGPTModalForNewbies;
