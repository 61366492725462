import { Box, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { useDispatch } from "react-redux";
import { listPurchaseOrders } from "../../redux/action/PurchaseOrder_Action";
import CompanyName from "../../components/CompanyName";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Skeleton from "@mui/material/Skeleton";
import DownIcon from "../../assets/Images/CommonImages/Down.png";
import GoLeft from "../../assets/Images/CommonImages/GoLeft.png";
import stackofcoins from "../../assets/Stack of Coins.svg";
import useStyles from "../CartPage/Styles";
import { toast } from "react-toastify";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import sendEvents from "../../utils/sendEvents.js";
import ImageAndProductCarousel from "../../components/Carousel/ImageAndProductCarousel";

function VoucherOrderList() {
  const classes = useStyles();

  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [DataIds, setDataIds] = useState([]);
  const [Order, setOrder] = useState([]);
  const [OrderLoading, setOrderLoading] = useState(false);
  const [SaleOrderData, setSaleOrderData] = useState();
  const [PurchaseOrderData, setPurchaseOrderData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(1);
  const [activePanel, setActivePanel] = useState(
    Location?.state ? Location?.state : 0
  );
  const [AllOrders, setAllOrders] = useState();
  const switchPanel = (panelIndex) => {
    setActivePanel(panelIndex);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  async function GetSaleOrderData() {
    try {
      const response = await axios.get(
        `voucher/get_voucher_orders?page=${currentPage}`,
        { withCredentials: true }
      );
      const data = response.data;
      setInitialPaginationCount(data.totalPages);
      setSaleOrderData(data.vouchers);
    } catch (error) {}
  }

  const GetOrdersId = async () => {
    return await axios
      .get(`order/get_all_orders_id`)
      .then((res) => {
        setAllOrders(res?.data?.orders);
      })
      .catch((err) => {});
  };

  async function GetPurchaseOrderData() {
    try {
      const response = await axios.get(
        `voucher/get_bought_voucher_orders?page=${currentPage}`,
        { withCredentials: true }
      );
      const data = response.data;
      setInitialPaginationCount(data.totalPages);
      setPurchaseOrderData(data.vouchers);
    } catch (error) {}
  }
  useEffect(() => {
    GetSaleOrderData();
    GetPurchaseOrderData();
  }, [currentPage]);

  let dataId = [];

  Order.map((el) => {
    dataId.push(el._id);
  });

  async function CancelVoucherOrder(id) {
    try {
      await axios
        .post(
          "voucher/cancel_voucher_order",
          { OrderId: id },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data?.OrderStatus === "Cancelled") {
            toast.success("Order Cancelled Successfully", {
              position: "top-center",
              autoClose: 3000,
            });
          } else {
            toast.error("Order Cancelation Failed", {
              position: "top-center",
              autoClose: 3000,
            });
          }
        });

      await GetSaleOrderData();
      await GetPurchaseOrderData();
    } catch (err) {}
  }

  // cancel order functionality with window confirm
  const CancelOrder = (id) => {
    if (window.confirm("Are you sure you want to cancel this order?")) {
      CancelVoucherOrder(id);
    }
  };

  const handleConfirmVoucherOrder = async (data) => {
    try {
      await axios
        .post(`voucher/create_voucher_invoice/${data?._id}`)
        .then((res) => {
          if (res) {
            navigate(`/home/ordersummerydetails/${data?._id}`);
          }
        });
      // GetMediaOrderData();
      GetSaleOrderData();
      GetPurchaseOrderData();
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(listPurchaseOrders());
    // GetMediaOrderData();
    GetSaleOrderData();
    GetPurchaseOrderData();
  }, [dispatch]);

  useEffect(() => {
    const dataId = Order.map((el) => el._id);
    setDataIds(dataId);
  }, [Order]);

  useEffect(() => {
    GetOrdersId();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOrderLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Paper
      sx={{
        boxShadow: "none",
        background: "transparent",
        width: "100%",
        mx: "auto",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Voucher Trade"} showbreadcrumb={true} />
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          padding: "1rem",
          borderRadius: "20px",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 1,
          position: "relative",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            height: "50px",
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "170px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
              sendEvents("Click on Voucher cart");
            }}
          >
            <img src={GoLeft} width="22px" />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#242120",
                ml: "1rem",
              }}
            ></Typography>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            sx={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "99%",
            }}
          >
            <Grid
              item
              xl={0.5}
              lg={0.5}
              md={0.5}
              sm={0.5}
              xs={0.5}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography sx={tableheading}>No</Typography>
            </Grid>
            <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
              <Typography sx={tableheading}>Voucher Name</Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography align="center" sx={tableheading}>
                Order Number
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Total Voucher
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Barter Coins
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Order date
              </Typography>
            </Grid>

            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Order Action
              </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography align="center" sx={tableheading}>
                Action
              </Typography>
            </Grid>
          </Grid>

          {PurchaseOrderData?.length > 0 ? (
            PurchaseOrderData?.map((data, idx) => {
              let totalPrice = 0;
              data?.ProductData?.map((data) => {
                totalPrice =
                  totalPrice + data.DiscountedPrice * data.ProductQuantity;
                return totalPrice;
              });
              function convertDate(inputFormat) {
                function pad(s) {
                  return s < 10 ? "0" + s : s;
                }
                var d = new Date(inputFormat);
                return [
                  pad(d.getDate()),
                  pad(d.getMonth() + 1),
                  d.getFullYear(),
                ].join("/");
              }

              const CommaSeparator = (props) => {
                const number = props;
                const roundedNumber = Number(number);
                const isValidNumber = !isNaN(roundedNumber);
                const formattedNumber = isValidNumber
                  ? roundedNumber.toLocaleString("en-IN", {
                      minimumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
                      maximumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
                    })
                  : "";
                if (!Number.isInteger(roundedNumber)) {
                  return formattedNumber;
                } else {
                  return formattedNumber + ".00";
                }
              };

              if (OrderLoading) {
                return (
                  <Skeleton variant="rectangular" width={"100%"} height={35} />
                );
              } else {
                return (
                  <Accordion
                    sx={{
                      p: 0,
                      boxShadow: "none",
                      border: "none",
                      borderBottom: "1px solid #F5F5F5",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <img src={DownIcon} style={{ width: "9px" }} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ p: 0 }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xl={0.5}
                          lg={0.5}
                          md={0.5}
                          sm={0.5}
                          xs={0.5}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {" "}
                            {idx + 1}
                          </Typography>
                        </Grid>
                        <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                          <ImageAndProductCarousel
                            carouselData={data?.PurchaseOrderData?.ProductData}
                            MaxWidth={true}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {data?.OrderId}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {data?.PurchaseOrderData?.ProductData?.length}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBodyTextStyling,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <img
                              src={stackofcoins}
                              alt="rupieicon"
                              style={{
                                width: "15px",
                                height: "auto",
                              }}
                            />
                            {CommaSeparator(
                              data?.PurchaseOrderData?.BXITokenDetails
                                ?.TotalBXiCoins +
                                data?.PurchaseOrderData?.BXITokenDetails
                                  ?.TotalAdCostInBxiWithoutGST
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {convertDate(data?.createdAt)}
                          </Typography>
                        </Grid>
                        {data?.escrowPayment &&
                        data?.escrowPayment?.success === "true" ? (
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                width: "100px",
                                mx: "auto",
                                height: "30px",
                                background: "#D9FFE9",
                                borderRadius: "6px ",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...TableBodyTextStyling,
                                  color: "var(--Green-2, #27AE60)",
                                  fontSize: "12px",
                                }}
                              >
                                Completed
                              </Typography>
                            </Box>
                          </Grid>
                        ) : (
                          <>
                            {data?.OrderStatus !== "Cancelled" ? (
                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                xs={1}
                                sx={{
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    width: "100px",
                                    mx: "auto",
                                    height: "30px",
                                    background: "#fff",
                                    boxShadow:
                                      "0px 4px 8px rgba(220, 220, 220, 1)",
                                    borderRadius: "6px ",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                  onClick={() => {
                                    CancelOrder(data._id);
                                    sendEvents("Click on cancel");
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBodyTextStyling,
                                      color: "#445FD2",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Cancel Order
                                  </Typography>
                                </Box>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                xs={1}
                                sx={{
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    sendEvents("Click on Cancelled");
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    width: "100px",
                                    mx: "auto",
                                    height: "30px",
                                    background: "red",
                                    borderRadius: "4.39877px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...TableBodyTextStyling,
                                      color: "#fff",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Cancelled
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </>
                        )}
                        {data?.OrderStatus === "Cancelled" ? null : (
                          <Grid
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                width: "150px",
                                mx: "auto",
                                height: "30px",
                                background: "#445FD2",
                                borderRadius: "4.39877px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                              onClick={() => {
                                handleConfirmVoucherOrder(data);

                                sendEvents("Click on continue");
                              }}
                            >
                              <Typography
                                sx={{
                                  ...TableBodyTextStyling,
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                Next Step
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, pb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          p: 0,
                          ml: 1,
                          width: "100%",
                          maxWidth: "500px",
                        }}
                      >
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <CompanyName
                            CompanyId={
                              data?.PurchaseOrderData?.SellerDetails
                                .SellerCompanyId
                            }
                            CompanyName={
                              data?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })
          ) : (
            <Nodatafound />
          )}
        </Grid>
      </Grid>
      {SaleOrderData?.length > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
          <Stack spacing={2}>
            <StyledPagination
              count={initialPaginationCount}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              strokeWidth={currentPage}
            />
          </Stack>
        </Box>
      ) : null}
    </Paper>
  );
}

export default VoucherOrderList;

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};
