import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeftArrow from "../../src/assets/Images/payment/LeftArrow.png";
import axios from "axios";
import { AiFillHeart } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useRemoveWishlistProductByProductId } from "../Hooks/ProductActions/useRemoveWishlistProduct";

import ShareIcon from "../assets/Images/CommonImages/ShareIcon.svg";
import { GetProductByIdAction } from "../redux/action/ProductActions/GetProductByIdAction";
import GlobalToast from "./Toasts/GlobalToast";
const VoucherPageHeader = (props) => {
  let { id } = useParams();
  let ProductId = id;
  const [like, setLike] = useState(false);
  const dispatch = useDispatch();
  const [GetProductByIdData, setGetProductByIdData] = useState();

  const navigate = useNavigate();
  const [wishlistDataGet, setWishlistDataGet] = useState([]);
  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductByIdData(res?.data);
      });
  }
  useEffect(() => {
    GetProductByid();
  }, []);
  async function getWishtlistProduct() {
    axios
      .get("wishlist/get_wishlist_product", { withCredentials: true })
      .then((res) => {
        setWishlistDataGet(res.data);
      });
  }

  useEffect(() => {
    getWishtlistProduct();
  }, []);

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProductByProductId();

  const GetProductShareLink = async (id) => {
    GlobalToast("Link Copied", "info");
    navigator.clipboard.writeText(
      `https://bxiworld.com/home/voucherdetail` + `/${id}`
    );
  };

  useEffect(() => {
    dispatch(GetProductByIdAction(ProductId));
  }, [dispatch]);

  async function handleAddToWishlist(id) {
    axios
      .post("wishlist/add_to_wishlist", { id: id }, { withCredentials: true })
      .then((res) => {
        getWishtlistProduct();
      });
  }

  async function handleRemoveWishlist(id) {
    removefromwishlist(id);
    getWishtlistProduct();
    setLike(false);
  }
  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        height: "80px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
          width: "120px",
          paddingLeft: "10px",
          mt: "10px",
        }}
        onClick={() => {
          navigate("/home");
        }}
      >
        <Box
          component="img"
          src={LeftArrow}
          alt="LeftArrow"
          sx={{
            width: "22px",
            height: "9px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/home");
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: {
            xl: "15px",
            lg: "15px",
            md: "12px",
            sm: "12px",
            xs: "10px",
          },
          alignItems: "center",
          alignContent: "center",
          allignSelf: "center",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            ...HeaderIconStyle,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            p: {
              xl: 0.7,
              lg: 0.7,
              md: 0.7,
              sm: 0.8,
              xs: 0.8,
            },
          }}
          onClick={() => {
            wishlistDataGet &&
            wishlistDataGet?.find((item) => item?.ProductId?._id === ProductId)
              ? handleRemoveWishlist(ProductId)
              : handleAddToWishlist(ProductId);
          }}
        >
          {wishlistDataGet &&
          wishlistDataGet?.find(
            (item) => item?.ProductId?._id === ProductId
          ) ? (
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={() => {
                handleRemoveWishlist(ProductId);
              }}
            >
              <AiFillHeart
                color={"red"}
                size={"20px"}
                style={{ ...HeaderIconStyle1, width: "18px" }}
              />
            </button>
          ) : (
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                color: "#6B7A99",
              }}
              onClick={() => {
                handleAddToWishlist(ProductId);
              }}
            >
              <AiFillHeart
                color={"#c3cad9"}
                size={"20px"}
                style={{ ...HeaderIconStyle1, width: "18px" }}
              />
            </button>
          )}
        </Box>
        <Box
          component="img"
          src={ShareIcon}
          sx={{ ...HeaderIconStyle, p: 1.15, width: "18px" }}
          onClick={() => GetProductShareLink(ProductId)}
        />
      </Box>
    </Box>
  );
};

export default VoucherPageHeader;

const HeaderIconStyle = {
  border: "1px solid #8C8C8C",
  borderRadius: "6px",
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },

  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

const HeaderIconStyle1 = {
  borderRadius: "6px",
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },

  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};
