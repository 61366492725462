import React, { useRef } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import Header from "../../pages/LandingPage/Navbar";
import BannerImg from "../../assets/Blog_Banner.jpg";
import { Helmet } from "react-helmet";
import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import PrintIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ResponsiveImage = ({ src, alt, sx }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      width: "100%",
      height: { xs: "auto" },
      ...sx,
    }}
  />
);

const CustomTypography = ({ children, sx, onClick }) => (
  <Typography
    sx={{
      fontFamily: "Poppins",
      letterSpacing: "1px",
      fontSize: "15px",
      color: "gray",
      textTransform: "capitalize",
      fontWeight: 400,
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

export default function BartertradeCompanies() {
  const card = [
    {
      name: "The Ultimate Guide to Barter Exchange goods without involving money",
      description:
        "One of the oldest and most effective methods of trade that existed several centuries ago is barter which has regained popularity in the modern economy.",
      link: "/barter-exchange-goods-without-involving-money",
    },
    {
      name: "The Difference Between Barter Exchange and Money Exchange",
      description:
        "Commerce and trade are some of the oldest activities known to man and have been present throughout history",
      link: "/difference-between-barter-exchange-and-money-exchange",
    },
    {
      name: "Advantages and disadvantages of the barter system of exchange",
      description:
        "Whether you are planning to start a barter business or want to understand how it works",
      link: "/advantages-and-disadvantages-of-the-barter-system-of-exchange",
    },
  ];

  const iconMapping = {
    Favourites: <FavoriteIcon />,
    Share: <ShareIcon />,
    Print: <PrintIcon />,
  };

  const componentRef = useRef();
  const location = useLocation();
  const pathname = location.pathname;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClick = (text) => {
    if (text === "Print") {
      handlePrint();
    } else if (text === "Share") {
      navigator.clipboard.writeText(`https://bxiworld.com${pathname}`);
      toast.info("URL Copied", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "#f9f6ef",
      }}
      ref={componentRef}
    >
      <ToastContainer style={{ fontSize: "16px" }} />
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          Advantages and disadvantages of the barter system of exchange
        </title>
        <meta
          property="og:title"
          content="Advantages and disadvantages of the barter system of exchange"
        />
        <meta
          name="og:description"
          content="barter system has many advantages and helps businesses to function even during adverse situations, there are also some disadvantages of the barter system."
        />
        <meta
          name="description"
          content="barter system has many advantages and helps businesses to function even during adverse situations, there are also some disadvantages of the barter system."
        />
      </Helmet> */}
      <Header />
      <Box sx={{ position: "relative" }}>
        <ResponsiveImage src={BannerImg} alt="Business meeting" />
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-70px", sm: "-70px", md: "-70px" },
            width: { xs: "70%", sm: "70%", md: "65%" },
            height: { xs: "25%", sm: "25%", md: "25%" },
            bgcolor: "#f9f6ef",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "25px",
                md: "35px",
                lg: "45px",
                xl: "55px",
              },
              width: {
                xs: "82%",
                sm: "82%",
                md: "82%",
                lg: "85%",
                xl: "85%",
              },
              height: {
                xs: "100%",
                sm: "100%",
              },
              fontFamily: "Poppins",
              fontWeight: 500,
              textAlign: "start",
            }}
          >
            Top 10 Best Barter trade Companies and websites in India
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{
          mt: { xs: 15, sm: 15, md: 10 },
          px: { xs: 2, md: 4 },
        }}
      >
        <Grid item xs={12} md={8}>
          <Divider />
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {["Favourites", "Share", "Print"].map((text, index) => (
                <CustomTypography
                  key={index}
                  sx={{
                    my: 2,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                      lg: "flex-start",
                      xl: "flex-start",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "center",
                    },
                    width: "50%",
                    gap: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(text)}
                >
                  <IconButton>{iconMapping[text]}</IconButton>
                  {text}
                </CustomTypography>
              ))}
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomTypography sx={{ py: 2 }}>
                {" "}
                Barter trade is a very old method of exchange where goods and
                services are swapped without the use of money. Barter trade is
                slowly regaining its lost ground in India and the advancement of
                digital solutions has contributed to this.
                <br /> These platforms enable businesses and people to exchange
                goods and services that they do not need or want in the market.
                <br />
                This blog post will be focusing on the ten most effective barter
                trade companies and websites that exist in India, and how they
                are beneficial to the users. This article will be informative
                for those who are interested in learning more about{" "}
                <b>barter companies in India.</b>
              </CustomTypography>
              <Divider />
              <CustomTypography sx={{ py: 2 }}>
                <Typography variant="h4" sx={h2ypo}>
                  Top 10 Best Barter Trade Companies and Websites in India
                </Typography>
                <span style={{ fontSize: "30px" }}>"</span>
                Now, we will look at the most dominant{" "}
                <b>barter trade companies in India</b>. The{" "}
                <b>barter websites in India and corporate barter companies</b>{" "}
                offer new ways of trading goods and services that are unique and
                efficient. Let us begin with the{" "}
                <b>best Barter Exchange Firms</b>, where we highlight the most
                effective Indian firms and how they are transforming the
                business world.
                <br />
                <Typography sx={h4typo}>Simple method</Typography>
                Barter Exchange of India is one of the premier{" "}
                <b>barter companies in India</b> that has made its mark in the
                internet trading business. In this way, it enables companies to
                trade goods and services using what is referred to as “Barter
                coins” rather than cash. BXI serves as a third-party record
                holder of transactions and provides convenience in the exchange
                of goods that businesses have for goods that they require.
              </CustomTypography>
            </Grid>
          </Grid>
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ResponsiveImage
              src="https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25060.jpg"
              alt="Business meeting"
              sx={{ width: { xs: "100%", md: "90%" }, ml: { md: "auto" } }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              BXI is an active business hub in which prestigious firms enroll
              and transact to exchange their products and services. These
              include airlines, accommodations, office and computer supplies,
              food services and beverages, newspapers and magazines, garments
              and apparel, consumer goods, transport, telecommunication,
              leisure, recreation, celebration, and electronics.
              <br />
              <br />
              <Typography sx={h4typo}>Barter Network</Typography>
              Barter Network Inc is a company which seeks to sell and buy from
              companies that desire to sell their excess stocks without
              suffering a loss on their profit margin. It enables its member
              businesses to swap its products for those of another company with
              ease. These goods can then be resold or be used to delay the
              accrued expenses for a particular period of time. Yes, there are
              asking fees, monthly fees, and barter fees, but the site features
              useful tools such as a personal broker who can guide you through
              the site and manage swaps.
              <br />
              <br />
              <Typography sx={h4typo}>Biz Exchange</Typography>
              BizExchange is yet one of the best <b>Barter websites in India</b>
              exclusive to business trades, that deals mainly with tangible
              goods. The site provides an avenue where the members can trade
              their products to other member businesses using the BizX Dollars.
              <br />
              It monitors usage and develops forms and records of transactions
              that occur on the site. All BizX Dollars are spent on the overall
              cost of doing business, so it doesn’t alter taxation. It can
              obtain revenue through the percentage value of each transaction.
              It is free to join and does not charge a monthly fee, but it has
              its overhead Percentage value for each transaction, which is
              around 6 to 7.5%.
              <br />
              <br />
              <Typography sx={h4typo}>CraigsList</Typography>
              Craigslist.org is one of the most popular{" "}
              <b>Barter websites in India</b>
              that is widely utilized for exchanging products and services
              available on the Internet. When looking for something, users are
              able to click on ‘barter’ as a filter on the left side of the
              screen meaning they get to find people who are interested in
              trading their products for other products.
              <br />
              Another advantage of using Craigslist is its size and capacity to
              exclude location from the results automatically to suit the user.
              However, since it is not exactly for bartering; the site does not
              possess some of the features of other{" "}
              <b>Barter websites in India</b>. For example, it lacks the option
              of searching for people who are in need of what the other user has
              to offer.
              <br />
              <br />
              <Typography sx={h4typo}>GoSwap</Typography>
              GoSwap is a platform intended for those people who would like to
              exchange homes in the long term. The website has so many options
              for filtering but for a user it only allows searching for the
              places, homes or plots that they want or need or some things may
              be which other people might want from them. The website is
              user-friendly and while it offers barter trading, other trading
              platforms are available.
              <br />
              <br />
              <Typography sx={h4typo}>IMSbarter</Typography>
              IMSbarter is a part of International Monetary Systems, which has
              been an important player in the field of finance and business for
              over thirty years. This business-to-business site enables
              companies to advertise their leftover products or even offer their
              spare time to other Indian <b>corporate barter companies</b>{" "}
              within the network in return for the other party’s spare time or
              their excess services. A user can find a lot of information on the
              site as to how it works but there are not many details regarding
              the pricing until one engages the company.
              <br />
              <br />
              <Typography sx={h4typo}>Listia</Typography>
              Listia is primarily designed for individuals rather than
              businesses, and is applicable for items but not services. Members
              of this <b>Indian barter exchange firms</b> list items they wish
              to sell, while other members offer to buy them using ‘Ink,’ the
              currency used in the site. One more form is ink, which is in fact
              earned, similar to the points of some video games, for using
              various parts of the site.
              <br />
              The site is also very friendly and easily accessible, especially
              because there are apps for both Apple and Android; users can also
              log in with their Facebook account.
              <br />
              <br />
              <Typography sx={h4typo}>Rehash</Typography>
              Rehash Clothes is an online platform where people can sign up for
              and exchange the clothes they do not want any more. The site is
              rather simple and intuitive and allows the users to sort the
              offers based on type of clothing they are searching for – men,
              women, shoes, accessories; or whether they need something or
              whether they have a supply on hand. The major issue found in this
              site is that the site is too simplistic. Hence, users may find it
              challenging to locate the particular products they are searching
              for.
              <br />
              <br />
              <Typography sx={h4typo}>Swap</Typography>
              Swap does not have the typical format of{" "}
              <b>Barter websites in India</b>
              but positions itself as an online thrift shop. Users can upload
              items within the social network, and other users can purchase
              them. They can then use that money to acquire goods from the other
              users or, in other words, to make purchases.
              <br />
              However, it like all other aspects of the site still involves the
              swapping of real money, thus it is not as entertaining as some of
              the other barter websites in India discussed above, it does not
              possess any form of membership fees. This makes it easier for
              individuals who want to exchange goods, but do not wish to adopt
              barter as their primary trading method. Swap has a great choice of
              affordable products of clothing, accessories, home-wares and etc.
              <br />
              It can be accessed online, is user-friendly, and can be operated
              using a search engine or by categories. In addition to providing
              customers with the possibility to swap their items, Swap is a
              perfect example of a store that pays close attention to both
              sustainability and saving resources.
              <br />
              <br />
              <Typography sx={h4typo}>Simbi</Typography>
              Simbi is a platform that connects people who need certain services
              with those who can provide them. This among the best{" "}
              <b>Barter trade companies India</b> offers exchane of service for
              service. For instance, if a user buys a guitar lesson from a
              painter of pottery, the painter of pottery will need the guitar
              but the buyer of a lesson may not need the pottery. The pottery
              painter can pay for the lessons with the site’s digital currency
              and then use the received currency on a service that the guitar
              teacher wants.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Conclusion
              </Typography>
              The <b>barter trade companies in India</b> are flexing muscles as
              they change the ways that business people are doing their business
              through offering them with other methods of exchanging goods and
              services. The 10 <b>Barter trade companies in India</b> listed
              above have different features and advantages that are suitable for
              different kinds of businessmen and consumers. So, if you are
              looking for the best <b>barter companies in India</b>, nothing can
              beat Barter Exchange of India.
              <br />
              Whether you are seeking a home swap, looking to exchange
              services/products, or having stocks that were not sold in a
              particular season, there is always a barter that suits the
              situation. Given the current trends in India, where barter trade
              is on the rise, it makes sense to assume that in the future, there
              will be even more interesting innovations in this field.
            </CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              mt: 2,
              ml: { xs: 0, md: 3 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "gray",
                textTransform: "capitalize",
                fontSize: "18px",
                fontWeight: 500,
                letterSpacing: "2px",
              }}
            >
              RELATED BLOGS
            </Typography>
          </Box>
          {card.map((data, idx) => {
            const wordLimit = 4;
            function truncateText(text, wordLimit) {
              const words = text.split(" ");
              if (words.length > wordLimit) {
                return words.slice(0, wordLimit).join(" ") + "...";
              }
              return text;
            }
            return (
              <Box>
                <a
                  href={data.link}
                  target="_blank"
                  alt="wtsp"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <ResponsiveImage
                      src={BannerImg}
                      alt="Related exhibition"
                      sx={{
                        width: { xs: "100%", md: "65%" },
                        mt: 2,
                        ml: { xs: 0, md: 3 },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { xs: "-10px", sm: "-10px", md: "-10px" },
                        left: { xs: "0px", sm: "0px", md: "22px" },
                        width: { xs: "50%", sm: "50%", md: "50%" },
                        height: { xs: "15%", sm: "15%", md: "15%" },
                        bgcolor: "#f9f6ef",
                        py: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xs: "15px",
                            sm: "15px",
                            md: "15px",
                            lg: "20px",
                            xl: "20px",
                          },
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          color: "gray",
                          textAlign: "start",
                          width: "100%",
                        }}
                      >
                        {truncateText(data.name, wordLimit)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      mt: 3,
                      ml: { xs: 0, md: 3 },
                      width: { xs: "100%", md: "65%" },
                      lineHeight: "25px",
                      fontFamily: "Poppins",
                      textAlign: "start",
                      color: "#6d6f71",
                      textTransform: "capitalize",
                      fontWeight: 400,
                      fontSize: "15px",
                      letterSpacing: "1px",
                    }}
                  >
                    {data.description}
                  </Typography>
                </a>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

const h2ypo = {
  fontSize: {
    xs: "22px",
    sm: "22px",
    md: "27px",
    lg: "27px",
    xl: "27px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};

const h4typo = {
  fontSize: {
    xs: "15px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};
