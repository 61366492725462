import React, { useRef } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import Header from "../../pages/LandingPage/Navbar";
import BannerImg from "../../assets/Blog_Banner.jpg";
import { Helmet } from "react-helmet";
import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import PrintIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ResponsiveImage = ({ src, alt, sx }) => (
  <Box
    component="img"
    src={src}
    alt={alt}
    sx={{
      width: "100%",
      height: { xs: "auto" },
      ...sx,
    }}
  />
);

const CustomTypography = ({ children, sx, onClick }) => (
  <Typography
    sx={{
      fontFamily: "Poppins",
      letterSpacing: "1px",
      fontSize: "15px",
      color: "gray",
      textTransform: "capitalize",
      fontWeight: 400,
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </Typography>
);

export default function BenefitsOfBartering() {
  const card = [
    {
      name: "The Difference Between Barter Exchange and Money Exchange",
      description:
        "Commerce and trade are some of the oldest activities known to man and have been present throughout history",
      link: "/difference-between-barter-exchange-and-money-exchange",
    },
    {
      name: "Advantages and disadvantages of the barter system of exchange",
      description:
        "Whether you are planning to start a barter business or want to understand how it works",
      link: "/advantages-and-disadvantages-of-the-barter-system-of-exchange",
    },
    {
      name: "Top 10 Best Barter trade Companies and websites in India",
      description:
        "Barter trade is a very old method of exchange where goods and services are swapped without the use of money",
      link: "/barter-trade-companies",
    },
  ];

  const iconMapping = {
    Favourites: <FavoriteIcon />,
    Share: <ShareIcon />,
    Print: <PrintIcon />,
  };

  const componentRef = useRef();
  const location = useLocation();
  const pathname = location.pathname;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClick = (text) => {
    if (text === "Print") {
      handlePrint();
    } else if (text === "Share") {
      navigator.clipboard.writeText(`https://bxiworld.com${pathname}`);
      toast.info("URL Copied", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "#f9f6ef",
      }}
      ref={componentRef}
    >
      <ToastContainer style={{ fontSize: "16px" }} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Barter Exchange goods without involving money</title>
        <meta
          property="og:title"
          content="Barter Exchange goods without involving money"
        />
        <meta
          name="og:description"
          content="Are you fed up with spending cash and you desire to barter exchange goods without involving money, then you are at the right forum."
        />
        <meta
          name="description"
          content="Are you fed up with spending cash and you desire to barter exchange goods without involving money, then you are at the right forum."
        />
      </Helmet>
      <Header />
      <Box sx={{ position: "relative" }}>
        <ResponsiveImage src={BannerImg} alt="Business meeting" />
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-70px", sm: "-70px", md: "-70px" },
            width: { xs: "70%", sm: "70%", md: "65%" },
            height: { xs: "25%", sm: "25%", md: "25%" },
            bgcolor: "#f9f6ef",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "25px",
                md: "35px",
                lg: "45px",
                xl: "55px",
              },
              width: {
                xs: "82%",
                sm: "82%",
                md: "82%",
                lg: "85%",
                xl: "85%",
              },
              height: {
                xs: "100%",
                sm: "100%",
              },
              fontFamily: "Poppins",
              fontWeight: 500,
              textAlign: "start",
            }}
          >
            The Ultimate Guide to Barter Exchange goods without involving money
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{
          mt: { xs: 15, sm: 15, md: 10 },
          px: { xs: 2, md: 4 },
        }}
      >
        <Grid item xs={12} md={8}>
          <Divider />
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {["Favourites", "Share", "Print"].map((text, index) => (
                <CustomTypography
                  key={index}
                  sx={{
                    my: 2,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-start",
                      lg: "flex-start",
                      xl: "flex-start",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "center",
                    },
                    width: "50%",
                    gap: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(text)}
                >
                  <IconButton>{iconMapping[text]}</IconButton>
                  {text}
                </CustomTypography>
              ))}
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomTypography sx={{ py: 2 }}>
                One of the oldest and most effective methods of trade that
                existed several centuries ago is barter which has regained
                popularity in the modern economy. Are you fed up with spending
                cash and you desire to{" "}
                <b>barter exchange goods without involving money,</b> then you
                are at the right forum. Let me present you with this ultimate
                guide to barter exchange that will explain how it functions,
                what can be exchanged, and how to begin. In fact, there is
                something for everyone who is interested in bartering, whether
                you are a beginner or a veteran in the practice. Alright, let me
                take you into the world of barter exchange and enlighten you on
                what it is all about.
              </CustomTypography>
              <Divider />
              <CustomTypography sx={{ py: 2 }}>
                <Typography variant="h4" sx={h2ypo}>
                  How Barter Exchange Works
                </Typography>
                <span style={{ fontSize: "30px" }}>"</span>
                At its core, barter exchange is simple: it is a mutual exchange
                of items whereby one gives another something in exchange for
                something he/she desires without involving money. It is the
                exchange of goods or services from one party with a similar
                value of goods or services from the other party. Here's a
                breakdown of how it typically works:
                <li>
                  Two people or businesses find things they would like to
                  exchange with each other.
                </li>
                <li>
                  They both have a consensus regarding the worth of the item or
                  service.
                </li>
                <li>
                  They make the exchange and both get what they require without
                  necessarily having to use money.
                </li>
              </CustomTypography>
            </Grid>
          </Grid>
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ResponsiveImage
              src="https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25060.jpg"
              alt="Business meeting"
              sx={{ width: { xs: "100%", md: "90%" }, ml: { md: "auto" } }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              For instance, graphic designer can design a logo for a bakery
              company, in return, he/she is offered bread for a month’s supply.
              Or a handyman could tighten a teacher’s loose tap for a few
              lessons in mathematics for the handymen’s child.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              Trading can be done individually or through intermediaries or
              brokers who connect people who want to exchange goods. These
              networks, which could be on social media, assist in bringing
              together people with corresponding needs. They can help locate
              trade partners and, at times, employ the use of ‘trade credits’ to
              help in many-sided exchanges.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              Flexibility is the beauty of{" "}
              <b>barter exchange goods without involving money </b>
              since it can apply to every business. It can be applied to anyone
              from an individual to a small business person and even a big
              company. It is rather helpful when money is scarce or when you
              have something or knowledge which is not very much in demand.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Types of Goods Commonly Exchanged
              </Typography>
              Goods can be exchanged without the use of money in barter and this
              can be anything you can possibly think of. Here are some popular
              categories:
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h4typo}>
                Physical Goods:
              </Typography>
              <li>Furniture and home decor</li>
              <li>Electronics</li>
              <li>Clothing and accessories</li>
              <li>Books and media</li>
              <li>Tools and equipment</li>
              <li>Handmade crafts</li>
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h4typo}>
                Services:
              </Typography>
              <li>
                Professional skills (accounting, legal advice, web design)
              </li>
              <li>Home repairs and maintenance</li>
              <li>Beauty services (haircuts, massages)</li>
              <li>Tutoring and lessons</li>
              <li>Pet care</li>
              <li>Photography</li>
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h4typo}>
                Food and Produce:
              </Typography>
              <li>Homegrown vegetables and fruits</li>
              <li>Baked goods</li>
              <li>Homemade preserves and canned goods</li>
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h4typo}>
                Space and Accommodations:
              </Typography>
              <li>Storage space</li>
              <li>Parking spots</li>
              <li>Vacation home swaps</li>
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              The focus is on approaching it from a different standpoint and
              what you have to bring to the table. Skills you may consider
              trivial to you might turn out to be very useful to someone else.
              However, it is important to note that the{" "}
              <b>barter exchange goods without involving money </b>mostly
              depends on a successful swapping of items that you have with items
              that the other person needs.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Steps to Start Bartering
              </Typography>
              <span sx={{ fontWeight: 300 }}>
                Now, let us break down the steps in detail:
              </span>
              <br />
              <br />
              <span>Identifying What You Have to Offer</span>
              <br />
              <br />
              The first key in bartering is to assess what you have that you
              will be willing to barter. The tangible goods may be either
              products or assets, while the intangible ones may be a skill or a
              service. Here's how to get started:
              <br />
              <br />
              <li>
                List your strengths. Don’t limit yourself to your skills in your
                respective job. Do you have great organizational skills? Are you
                a handyman? For instance, if you have a problem with your car or
                any other item in your home, you take it to a mechanic or a
                repairman.
              </li>
              <li>
                Go around your house and pick items that you do not need or have
                not used for a long time. They could be valuable to someone
                else.
              </li>
              <li>
                Consider your time. Perhaps you can do babysitting, dog sitting,
                mowing the lawn or any form of petty business.
              </li>
              <li>
                Now is the time to consider any expertise you may have. This
                could include anything from specific software knowledge to a
                history of the place.
              </li>
              <br />
              Just bear in mind, what you may consider normal may be what
              another person has been seeking for so long. Do not undersell
              yourself and try to think outside the box.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h4typo}>
                Finding Barter Partners
              </Typography>
              The next step, having established what you have to barter, is to
              look for people to barter with. Your first place to look for
              volunteers should be your own acquaintances. Inform friends,
              families and co-workers that you are open to swapping. <br />{" "}
              <br />
              Trade in barter clubs or forums that are created to help people
              negotiate barter deals. Search in the local community boards or
              even social media groups. Most regions have swap meets or barter
              circles. Go to the next business event or a local market. These
              can be wonderful places to find like-minded people willing to
              discuss other forms of economies. Consider approaching local
              businesses.
              <br />
              <br />
              Most of the small business people are willing to engage in barter
              because some of the goods they produce are required by other
              business people. The following are some guidelines for barter
              trading: When sourcing for barter trades, be specific on what is
              being traded and what one wants. This makes it easier to find good
              matches.
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h4typo}>
                Negotiating Fair Trades
              </Typography>
              When you have identified a likely barter partner, the next step is
              to engage in a barter deal. This step is important to make sure
              that both the giver and receiver feel they are getting something
              of equal value. Here's how to approach it:
              <br />
              <br />
              <li>
                Specify what you will deliver and the value it will have for
                clients. If you are unclear about anything, consult other
                similar products or services.
              </li>
              <li>
                Hear what is important to the other person about the item or
                service they are providing. Their view could be dissimilar to
                yours.
              </li>
              <li>
                Be willing to compromise. Occasionally, the trade does not have
                to be a one-for-one trade that is perfectly matched. Sometimes,
                becoming inventive or including a third party may be necessary.
              </li>
              <li>
                Discuss the details. Discuss and define the parties'
                deliverables and/or responsibilities for services. Regarding
                goods, it is important to discuss conditions and any discovered
                problems.
              </li>
              <li>
                Always document the agreement, which could be in the form of an
                email. This is very helpful in preventing confusion in the later
                stages of a project.
              </li>
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomTypography sx={{ py: 2, width: { xs: "100%", md: "60%" } }}>
              <Typography variant="h4" sx={h2ypo}>
                Tips for Successful Barter Transactions
              </Typography>
              Here are some useful tips that will help you get the most from
              your <b>Barter exchange goods without involving money</b>{" "}
              experience:
              <br />
              <br />
              <Typography sx={h4typo}>Be Honest and Transparent</Typography>
              The practice of bargaining requires its participants to be
              truthful. Appeal to the buyer’s rationality by clearly stating the
              condition of the goods offered and the level of services provided.
              If you give your barter partner wrong information, they will be
              dissatisfied and future trade will never be possible.
              <br />
              <br />
              <Typography sx={h4typo}>Communicate Clearly</Typography>
              In this understanding, communication must be effective to ensure
              that each party clearly understands the agreed trade. It will also
              be appropriate to talk about the delivery time, the state of the
              goods, and any other useful information. Thus, communication
              clears all misunderstandings and helps develop trust.
              <br />
              <br />
              <Typography sx={h4typo}>Start Small</Typography>
              For the newbies to bargain, you should begin with simple items
              that are easy to bargain for. This enables one to familiarise
              themselves with the process in order to conduct the actual study
              effectively. You can graduate to better ones when you are through
              with basic exchanges.
              <br />
              <br />
              <Typography sx={h4typo}>Document the Trade</Typography>
              In cases where the trade is slightly more elaborate, it is
              advisable to jot down the agreement. This can be as formal as
              writing what each of the parties is bringing to the table and what
              each of the parties expects to gain in return. Documentation is
              important to ensure that no conflict arises, and in the event that
              conflicts occur, one can refer to the documentation.
              <br />
              <br />
              <Typography sx={h4typo}>Be Flexible</Typography>
              It is one of the most important characteristics to{" "}
              <b>Barter exchange goods without involving money.</b> Bartering
              should be flexible. Be ready to make various trades and not stick
              with the same offers. The more you are willing to trade, the
              better your chances of offering and receiving something the other
              party wants in equal measure.
              <br />
              <br />
              <Typography sx={h4typo}>Common Mistakes to Avoid</Typography>
              Even though bartering might be quite beneficial, there are certain
              disadvantages that one should look out for:
              <br />
              <br />
              <Typography sx={h4typo}></Typography>
              <br />
              One can always be tempted to set the price slightly higher than
              the market demands. Always be practical, and if need be, do some
              market research on the value of your items. Doing so can cause
              rejected trades and frustration among the application's users.
              <br />
              <br />
              <Typography sx={h4typo}>Ignoring Quality</Typography>
              Quality matters in bartering. Ensure the goods or services you
              market are well prepared and of the standard expected by your
              trade partner. Having low-quality goods and services featured in
              your barter can damage your reputation.
              <br />
              <br />
              <Typography sx={h4typo}>Failing to Communicate</Typography>
              Self-communication failure is one of the biggest challenges.
              Continuity of communication is very important and the parties to
              the trade should always agree on the terms of the trade. This
              makes it possible for people to have misunderstandings, resulting
              in disagreements and non-occurrence of trades.
              <br />
              <br />
              <Typography sx={h4typo}>Not Being Prepared</Typography>
              When you are engaged in a trade and before you suggest one, ensure
              that you are willing to proceed with it. Be ready with your
              goods/services and assertive about the needs that you want met. As
              such, teams that are not well prepared will end up taking more
              time, and the customers will be dissatisfied.
              <br />
              <br />
              <Typography sx={h4typo}>Forgetting to Follow Up</Typography>
              After the trade, one should check that everything went well. This
              makes the trade to foster relationships so that in future there
              will be more trade. A short ‘thank you’ can help in keeping good
              relations with people.
              <br />
              <br />
              <Typography sx={h2ypo}>Conclusion</Typography>
              <b>Barter exchange goods without involving money</b> is a
              realistic and enjoyable approach that can help you acquire what
              you need while strengthening your networks. Thus, anyone willing
              to barter is equipped with the knowledge of how it operates, what
              are useful items and services to trade, and basic dos and don’ts.
              Some of the ways you can display good interpersonal communication
              is to be clear in your communication, bargain properly and do not
              be sly with your products. If you want a perfect website to begin
              your barter, you will be interested in BXIWORLD. This simplified
              website allows you to reach a number of barter partners, making it
              easier for you to exchange your products and services without any
              form of payment. Begin your search today and find out why more and
              more people are opting for a cashless transaction.
            </CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
              mt: 2,
              ml: { xs: 0, md: 3 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "gray",
                textTransform: "capitalize",
                fontSize: "18px",
                fontWeight: 500,
                letterSpacing: "2px",
              }}
            >
              RELATED BLOGS
            </Typography>
          </Box>
          {card.map((data, idx) => {
            const wordLimit = 4;
            function truncateText(text, wordLimit) {
              const words = text.split(" ");
              if (words.length > wordLimit) {
                return words.slice(0, wordLimit).join(" ") + "...";
              }
              return text;
            }
            return (
              <Box>
                <a
                  href={data.link}
                  target="_blank"
                  alt="wtsp"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <ResponsiveImage
                      src={BannerImg}
                      alt="Related exhibition"
                      sx={{
                        width: { xs: "100%", md: "65%" },
                        mt: 2,
                        ml: { xs: 0, md: 3 },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { xs: "-10px", sm: "-10px", md: "-10px" },
                        left: { xs: "0px", sm: "0px", md: "22px" },
                        width: { xs: "50%", sm: "50%", md: "50%" },
                        height: { xs: "15%", sm: "15%", md: "15%" },
                        bgcolor: "#f9f6ef",
                        py: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: {
                            xs: "15px",
                            sm: "15px",
                            md: "15px",
                            lg: "20px",
                            xl: "20px",
                          },
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          color: "gray",
                          textAlign: "start",
                          width: "100%",
                        }}
                      >
                        {truncateText(data.name, wordLimit)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      mt: 3,
                      ml: { xs: 0, md: 3 },
                      width: { xs: "100%", md: "65%" },
                      lineHeight: "25px",
                      fontFamily: "Poppins",
                      textAlign: "start",
                      color: "#6d6f71",
                      textTransform: "capitalize",
                      fontWeight: 400,
                      fontSize: "15px",
                      letterSpacing: "1px",
                    }}
                  >
                    {data.description}
                  </Typography>
                </a>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

const h2ypo = {
  fontSize: {
    xs: "22px",
    sm: "22px",
    md: "27px",
    lg: "27px",
    xl: "27px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};

const h4typo = {
  fontSize: {
    xs: "15px",
    sm: "15px",
    md: "20px",
    lg: "20px",
    xl: "20px",
  },
  fontFamily: "Poppins",
  fontWeight: 500,
  textAlign: "start",
};
