import axios from "axios";
//
// const baseURL = "https://development-stage.bxi.unada.in/api/v1/";
// const baseURL = "http://localhost:7000/api/v1/";
// const baseURL = "https://api.production-bxi.unada.in/api/v1/";

const baseURL = "https://production-api.unada.in/api/v1/";
// const baseURL = "https://bxiback-gp3.unada.in/api/v1/";
//
const v1Backend = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    bxiapikey: "Bearer K8sY2jF4pL3rQ1hA9gZ6bX7wC5vU0t",
  },
});

export default v1Backend;
