import axios from "axios";
import { useQuery } from "react-query";

export const useGetAllOrdersForTracking = (type, currentPage, filter) => {
  const { data, error, isLoading, refetch } = useQuery("tracking orders", () =>
    axios
      .post(
        `/order/allordersfortracking?page=${currentPage}&filter=${filter}&type=${type}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => res?.data)
  );

  return { data, error, isLoading, refetch };
};



export const useGetAllSellerorderTracking = (activePanel, currentPage, FilterData) => {
  const { data, error, isLoading, refetch } = useQuery("sellerorderstracking", () =>
    axios
    .get(
      `order/allordersforseller?page=${currentPage}&filter=${FilterData}&type=${activePanel}`,
      {
        withCredentials: true,
      }
      )
      .then((res) => res?.data)
  );

  return { data, error, isLoading, refetch };
};
