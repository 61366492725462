// contants for cart reducer
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS";
export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL";
export const GET_CART_DATA_REQUEST = "GET_CART_DATA_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const REMOVE_FROM_CART_SUCCESS = "REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_FAIL = "REMOVE_FROM_CART_FAIL";
export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const CLEAR_ERRORS = "CLEAR_ERRORS";





