import React from "react";
import { z } from "zod";
import MainLogo from "./MainLogo.jsx";
import { useForm } from "react-hook-form";
import { useResetPassword } from "../../Hooks/Auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mainImg from "../../assets/Images/register/resetps.svg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import * as StlyesOnBoarding from "../../components/common/Styled/StylesOnBoarding.js";

const ResetPassword = () => {
  const LocationData = useLocation();
  const navigate = useNavigate();
  // use use Form
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        newpassword: z.string().min(6),
        confirmpassword: z.string().min(6),
      })
    ),
  });
  // post the password
  const { mutate: mutateResetPassword } = useResetPassword();

  // submit the password and navigate to home
  const resSetPasswords = handleSubmit((data) => {
    if (data.newpassword !== data.confirmpassword) {
      setError("confirmpassword", {
        type: "manual",
        message: "Password and Confirm Password must be same",
      });
    } else {
      mutateResetPassword(
        {
          email: LocationData?.state?.email,
          ResetPassOtp: LocationData?.state?.resetpassotp,
          password: data.newpassword,
        },
        {
          onSuccess: (res) => {
            toast.success(
              res?.data?.message ??
                "Otp verified successfully, Please reset your password",
              {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            setTimeout(() => {
              navigate(`/`);
            }, [1000]);
          },
          onError: (err) => {},
        }
      );
    }
  });

  return (
    <>
      <ToastContainer style={{ fontSize: "16px" }} />
      <form onSubmit={resSetPasswords}>
        <Paper elevation={0} sx={StlyesOnBoarding.mainCommonPage}>
          <Grid
            container
            sx={{
              background: "#fff",
              height: "100vh",
              width: {
                xl: "110%",
                lg: "110%",
                md: "110%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            {/* detail Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-20rem",
              }}
            >
              <Grid
                container
                sx={{
                  height: {
                    xl: "30%",
                    lg: "30%",
                    md: "20%",
                    sm: "10%",
                    xs: "10%",
                  },
                }}
              >
                <Link to={"/"}>
                  <MainLogo />
                </Link>
              </Grid>
              <Typography sx={StlyesOnBoarding.HeadText}>
                Reset Password{" "}
              </Typography>
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: "75%",
                  gap: "20px",
                }}
              >
                {/* input Box */}
                <ThemeProvider theme={outerTheme}>
                  <TextField
                    {...register("newpassword")}
                    label="Enter Password"
                    placeholder="*******"
                    variant="outlined"
                    required
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                        border: "1px #CCCCCC",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {errors["newpassword"]?.message}
                  </Typography>
                  <TextField
                    {...register("confirmpassword")}
                    label="Confirm Password"
                    placeholder="*******"
                    variant="outlined"
                    required
                    InputProps={{
                      style: {
                        borderRadius: "12px",
                        border: "1px #CCCCCC",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {errors["confirmpassword"]?.message}
                  </Typography>
                </ThemeProvider>
              </Box>
              {/* Buttons password */}
              <Box sx={{ display: "flex", mt: 4, width: "75%", gap: "10px" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ ...StlyesOnBoarding.CommonBtn, fontSize: "1.4rem" }}
                >
                  Add new Password
                </Button>
              </Box>
            </Grid>
            {/* image Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: {
                  xl: "flex",
                  lg: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <img
                src={mainImg}
                alt="img"
                style={StlyesOnBoarding.CommonImgStyle2}
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
};

export default ResetPassword;

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#6B7A99",
    },
  },
  typography: {
    fontSize: 20,
  },
});
