import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import print from "../assets/Images/CommonImages/PrintInvoice.svg";
import download from "../assets/Images/CommonImages/downloadinvoice.svg";
import BreadCrumbHeader from "../components/Header/BreadCrumbHeader";
import NumberToWord from "../components/NumberToWord";

import axios from "axios";
import CommaSeprator from "../components/CommaSeprator";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";

const Commission = () => {
  const { id } = useParams();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("print.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }, 1000);
  };

  const [CommissionData, setCommissionData] = useState();

  const GetCommission = async () => {
    await axios
      .get(`order/get_order_by_id/${id}`)
      .then((res) => {
        setCommissionData(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetCommission();
  }, []);
  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
        overflowX: "hidden",
      }}
      elevation={0}
    >
      <BreadCrumbHeader
        MainText="Shipping Invoice"
        LinkText1="{splitedurl[1]}"
        LinkText2="{splitedurl[2]}"
        link1="Link1"
        link2="link2"
      />
      <Paper
        sx={{
          width: "100%",
          bgcolor: "white",
          mx: "auto",
          borderRadius: "17px",
          pb: "40px",
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            mx: "auto",
            borderRadius: "17px",
            py: "40px",
          }}
          elevation={1}
          ref={componentRef}
        >
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/Commissionbarterlogo.png"
                  style={{ height: "85px", width: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    width: "98%",
                    color: "#000000",
                  }}
                >
                  BXI WORLD LLP
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    color: "#6B7A99",
                    fontSize: "14px",
                    fontWeight: 600,
                    width: "98%",
                  }}
                >
                  501 , 5th Floor Meadows Tower , Sahar Plaza Complex,
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    color: "#6B7A99",
                    fontSize: "14px",
                    fontWeight: 600,
                    width: "98%",
                  }}
                >
                  Andheri East , Mumbai - 400059
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    width: "98%",
                    color: "#6B7A99",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Tel : 022 - 49646776
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    width: "98%",
                    color: "#6B7A99",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  GSTIN : 27AAXFB2929C1ZA
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // borderRight: "1px solid #cdcdcd",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "30%",
                }}
              >
                <Typography
                  sx={{
                    ...TextLastStyle,
                    width: "98%",
                    color: "#445FD2",
                    fontSize: "14px",
                    fontWeight: 600,
                    mt: 0.5,
                  }}
                >
                  www.bxiworld.com
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: "#156DB6",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                Shipping Invoice
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                  borderRight: "1px solid #CDCDCD",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Invoice No :{CommissionData?.CommitionId}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Invoice Date :{" "}
                          {new Date(
                            CommissionData?.WalletData?.createdAt
                          ).toLocaleDateString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          {" "}
                          State :{" "}
                          {
                            CommissionData?.PurchaseOrderData?.BuyerDetails
                              ?.Address?.State
                          }
                        </Typography>
                        <Typography sx={TextLastStyle}>
                          {" "}
                          Code :{" "}
                          {
                            CommissionData?.PurchaseOrderData?.BuyerDetails
                              ?.StateCode
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Place of Supply :{" "}
                          {
                            CommissionData?.BuyerChoosedTransportation
                              ?.TransportersDetails?.TransportersDetails
                              ?.working?.recipient_city
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          {" "}
                          Order ID: {CommissionData?.OrderId}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={TextLastStyle}>&nbsp;</Typography>
                        <Typography sx={TextLastStyle}></Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: "#156DB6",
                  borderRadius: "3px 0px 0px 3",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                  borderRadius: "3px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "10px",
                      color: "white",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Bill to Party
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "10px",
                      textAlign: "left",
                      width: "100%",
                    }}
                  ></Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  width: "89.3%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow
                        sx={{
                          borderBottom: "1px solid #cdcdcd",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "1px solid #cdcdcd",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography
                            sx={{ ...TextLastStyle, whiteSpace: "nowrap" }}
                          >
                            &nbsp;&nbsp;Buyer Legal Name :{" "}
                            {
                              CommissionData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            &nbsp;&nbsp;Buyer Legal Address :{" "}
                            {CommissionData?.PurchaseOrderData?.BuyerDetails
                              ?.Address?.AddressLine
                              ? CommissionData?.PurchaseOrderData?.BuyerDetails
                                  ?.Address?.AddressLine + ","
                              : ""}{" "}
                            {CommissionData?.PurchaseOrderData?.BuyerDetails
                              ?.Address?.City
                              ? CommissionData?.PurchaseOrderData?.BuyerDetails
                                  ?.Address?.City + ","
                              : ""}{" "}
                            {CommissionData?.PurchaseOrderData?.BuyerDetails
                              ?.Address?.State
                              ? CommissionData?.PurchaseOrderData?.BuyerDetails
                                  ?.Address?.State
                              : ""}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            &nbsp;&nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: "1px solid #cdcdcd",
                          borderTop: "1px solid #cdcdcd",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            &nbsp;&nbsp;GSTIN:{" "}
                            {
                              CommissionData?.PurchaseOrderData?.BuyerDetails
                                ?.GSTIN
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              width: "50%",
                              display: "inline-block",
                            }}
                          >
                            &nbsp;&nbsp;State:{" "}
                            {
                              CommissionData?.PurchaseOrderData?.BuyerDetails
                                ?.Address?.State
                            }
                          </Typography>
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              width: "50%",
                              display: "inline-block",
                            }}
                          >
                            &nbsp;Code:{" "}
                            {
                              CommissionData?.PurchaseOrderData?.BuyerDetails
                                ?.StateCode
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    width: "79%",
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody>
                      <TableRow
                        sx={{
                          borderBottom: "1px solid #cdcdcd",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "1px solid #cdcdcd",
                            width: "100px",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            &nbsp;&nbsp; Terms : {""} Due on Receipt
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            &nbsp;&nbsp;Shipment Data URL :{" "}
                          </Typography>
                          <a
                            target="_blank"
                            href={CommissionData?.shippingData?.label_url}
                          >
                            <span
                              style={{
                                ...TextLastStyle,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                maxWidth: "300px",
                                marginTop: 2,
                              }}
                            >
                              <u>{CommissionData?.shippingData?.label_url}</u>
                            </span>
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            &nbsp;&nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            &nbsp;&nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, width: "50%" }}>
                            {" "}
                            &nbsp;&nbsp;
                          </Typography>{" "}
                          <Typography sx={{ ...TextLastStyle, width: "50%" }}>
                            {" "}
                          </Typography>{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
                boxShadow: "none",
                borderRadius: "0px",
              }}
            >
              <Table
                sx={{
                  minWidth: "700px",
                  boxShadow: "none",
                  borderRight: "none",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow sx={{ height: "33px", bgcolor: "#156DB6" }}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "50px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        SRNo.
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={5}
                      sx={{ ...tablecell, width: "350px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        Item Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "100px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        HSN / SAC code
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "80px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        Amount
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TextLastStyle}>1</Typography>
                      {/* <Typography sx={TextLastStyle}>2</Typography> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TextLastStyle}>
                        Shipping Reimbursement Cost
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>9965</Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        <CommaSeprator
                          Price={(
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.TotalWithGST
                            ) +
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.BXIShippingHandlingFee
                            ) +
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.BXIShippingHandlingFeeGST
                            ) -
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.TDS
                            )
                          ).toFixed(2)}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{ ...tablecell, bgcolor: "#156DB6" }}
                    >
                      <Typography sx={{ ...TextLastStyle, color: "#FFFFFF" }}>
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        Total Amount (Inclusive of GST)
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        <CommaSeprator
                          Price={
                            CommissionData?.Commission?.CommissionPrice +
                            CommissionData?.Commission?.CommissionGST
                          }
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{ ...tablecell }}
                    >
                      <Typography sx={{ ...TextLastStyle, color: "#6B7A99" }}>
                        Total amount payable ( In words )
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        <NumberToWord
                          number={
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.TotalWithGST
                            ) +
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.BXIShippingHandlingFee
                            ) +
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.BXIShippingHandlingFeeGST
                            ) -
                            Number(
                              CommissionData?.BuyerChoosedTransportation
                                ?.TransportersDetails?.TDS
                            )
                          }
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                width: "100%",
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: "transparent",
                  borderBottom: "1px solid #cdcdcd",
                  borderRadius: "3px 0px 0px 3",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                  borderRadius: "3px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #cdcdcd",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "12px",
                      color: "#6B7A99",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    &nbsp; Terms & Conditions
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "#6B7A99",
                      fontSize: "10px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    &nbsp;
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  width: "89.3%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography
                            sx={{ ...TextLastStyle, whiteSpace: "nowrap" }}
                          >
                            &nbsp;&nbsp; The payment should be in favor of "BXI
                            WORLD LLP"
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            &nbsp;&nbsp; All payments should be done through
                            RTGS/NEFT only
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    width: "79%",
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}> </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                          }}
                        >
                          <Typography sx={TextLastStyle}></Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: "1px solid #CDCDCD",
                borderBottom: "1px solid #CDCDCD",
                height: "22px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  ...TextLastStyle,
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: 400,
                }}
              >
                Ceritified that the particulars given above are true and correct
                (E&OE)
              </Typography>{" "}
            </Box>

            <Box
              sx={{
                width: "100%",
                mx: "auto",
                borderRight: "1px solid #CDCDCD",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        bgcolor: "#156DB6",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "#ffffff" }}>
                        BXI Company's Bank Details
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          width: "393px",
                          textAlign: "end",
                          fontSize: "12px",
                        }}
                      >
                        For BXI WORLD LLP
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Account No. : 007863300005298
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        IFS Code : YESB0000078
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Branch Name : Andheri (East)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                        width: "50%",
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        Bank Name : YES BANK
                      </Typography>{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box
                sx={{
                  height: "22px",
                  width: "100%",
                  marginLeft: "70%",
                }}
              >
                <Typography
                  sx={{
                    ...TextLastStyle,
                    //   textAlign: "right",
                  }}
                >
                  Authorised signatory
                </Typography>{" "}
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #CDCDCD",
                  height: "22px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    ...TextLastStyle,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: 400,
                  }}
                >
                  This is a Computer Generated Invoice
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "360px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={print} sx={ButtonIconStyle} />{" "}
              &nbsp;Print
            </Button>

            <Button variant="outlined" sx={btnsx} onClick={handleDownload}>
              <Box component={"img"} src={download} sx={ButtonIconStyle} />{" "}
              &nbsp;Download
            </Button>
          </Box>
        </Box>
      </Paper>
    </Paper>
  );
};

export default Commission;
const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",

  color: "#6B7A99",
};

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#6B7A99",
  opacity: 0.7,
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "20px",

  color: "#505050",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const TextLastStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  fontWeight: 500,
  // fontSize: "12px",
  opacity: 1,
  // whiteSpace: "nowrap",
};

const BanktText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "11px",
  color: "#505050",
};

const BanktbottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "9px",
  lineHeight: "11px",
  color: "#505050",
};

const btnsx = {
  width: "100%",
  height: "51px",
  maxWidth: "168px",
  borderRadius: "10px",
  borderColor: "#156DB6",
  color: "#156DB6",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
