import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const TechnicalInfor = (props) => {
  return (
    <Box>
      <Typography sx={{ ...pack, fontSize: "22px", color: "#156DB6" }}>
        Technical Information
      </Typography>
      <Box
        mt={1}
        sx={{
          display: "flex",
          gap: "10px",
          width: "500px",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {props?.ProductData?.ProductTechInfo?.Warranty === undefined ||
          props?.ProductData?.ProductTechInfo?.Warranty === null ? null : (
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    ...available,
                    width: "100%",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  Warranty
                </Typography>
                <Typography
                  sx={{
                    ...available,
                    lineHeight: "20px",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#6B7A99",
                    width: "100%",
                  }}
                >
                  {props?.ProductData?.ProductTechInfo?.Warranty}{" "}
                  {props?.ProductData?.WarrantyPeriod === null ||
                  props?.ProductData?.WarrantyPeriod === undefined
                    ? props?.ProductData?.UnitOfTime
                    : props?.ProductData?.WarrantyPeriod}
                </Typography>
              </Box>
            </Grid>
          )}
          {props?.ProductData?.ProductTechInfo?.Guarantee === undefined ||
          props?.ProductData?.ProductTechInfo?.Guarantee === null ? null : (
            <Grid
              item
              lg={6}
              xl={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    ...available,
                    width: "100%",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  Guarantee{" "}
                </Typography>
                <Typography
                  sx={{
                    ...available,
                    lineHeight: "20px",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#6B7A99",
                  }}
                >
                  {props?.ProductData?.ProductTechInfo?.Guarantee}{" "}
                  {props?.ProductData?.GuaranteePeriod === null ||
                  props?.ProductData?.GuaranteePeriod === undefined
                    ? props?.ProductData?.UnitOfTime
                    : props?.ProductData?.GuaranteePeriod}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TechnicalInfor;

const pack = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  color: "#6B7A99",
};

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 200,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};
