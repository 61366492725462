import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TostMessagesTop from "../../Component/Toast";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { useAddToWishlist } from "../../Hooks/ProductActions/useAddToWishlist";
import { useGetWishlistData } from "../../Hooks/ProductActions/useGetWishlistData";
import { useRemoveWishlistProductByProductId } from "../../Hooks/ProductActions/useRemoveWishlistProduct";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import bestseller from "../../assets/Images/MarketplaceImages/BestSellernew.svg";
import CommaSeprator from "../../components/CommaSeprator";
import { get_Cart_Items } from "../../redux/action/CartActions";
import { setCurrentPageCount } from "../../redux/action/Home-Filter/CurrentPageCount";
import { getProductExplore } from "../../redux/action/Products/products";
import { allNotification } from "../../redux/action/Notification/getNotifications";
import { setActivePaginationCount } from "../../redux/action/PaginationCount";
import { ProductAnalysisUpdate } from "../../redux/action/Products/ProductAnalysis";
import { socket } from "../../pages/Message/Message";
import Nodataimgur from "../../assets/Images/datanotfound/NoDataFoundWithLogo.png";
import HeaderExplore from "../../Component/MarketPlaceExplore/HeaderExplore";
import ExploreMarketPlace from "../../Component/MarketPlaceExplore/MarketPlace";
import GlobalToast from "../../components/Toasts/GlobalToast";
import { Helmet } from "react-helmet";

export let Pagination_Count = 1;

const ExploreProductList = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Next'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Next'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'Previous'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const { productExplore, loading, error } = useSelector(
    (state) => state.productExplore
  );
  const { activePaginationCount } = useSelector(
    (state) => state.PaginationCount
  );
  const { CurrentPage } = useSelector((state) => state.CurrentPage);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    CurrentPage || 1
  );

  const [currentPage, setCurrentPage] = useState(activePaginationCount || 1);
  const [limit, setLimit] = useState(48);
  const [allToast, setAllToast] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  // const [productViewId, setProductViewId] = useState("");\
  const [IsBestSeller, setIsBestSeller] = useState();
  const [IsReadyStock, setIsReadyStock] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const CategoryName = location?.state?.label;

  const { data: loggedInUserData } = useGetLoggedInUser();

  const {
    data: mutateWishlistData,
    mutate: addtowishlist,
    isLoading: wishlistMutateLoading,
    error: wishlistMutateError,
  } = useAddToWishlist();

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProductByProductId();

  const {
    data: wishlistData,
    isLoading: wishlistLoading,
    error: wishlistError,
    refetch: wishlistRefetch,
  } = useGetWishlistData();

  useEffect(() => {
    if (productExplore && productExplore?.count !== undefined) {
      setInitialPaginationCount(productExplore?.count);
      dispatch(setCurrentPageCount(productExplore?.count));
    }
  }, [productExplore?.count]);

  async function handleAddToWishlist(id, ProductType) {
    axios
      .post(
        "wishlist/add_to_wishlist",
        { id: id, ProductType: ProductType },
        { withCredentials: true }
      )
      .then((res) => {
        wishlistRefetch();
      });
  }

  useEffect(() => {
    wishlistRefetch();
    dispatch(get_Cart_Items());
  }, [wishlistRefetch, mutateWishlistData, mutateRemoveWishlistData]);

  async function handleRemoveWishlist(id) {
    removefromwishlist(id);
  }

  Pagination_Count = currentPage;
  useEffect(() => {
    dispatch(
      getProductExplore(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        currentPage
      )
    );
  }, [dispatch, currentPage]);

  const GetProductCategory = async (
    name,
    id,
    type,
    listingType,
    VoucherType,
    ProductSubCategory
  ) => {
    dispatch(ProductAnalysisUpdate(id, "ProductViewCount"));
    if (listingType === "Voucher") {
      if (
        VoucherType === "Value Voucher / Gift Cards " ||
        ProductSubCategory === "Value Voucher" ||
        ProductSubCategory === "Gift Cards"
      ) {
        navigate(`/home/voucherdetail/${id}`);
      } else {
        navigate(`/home/voucherdetail/${id}`);
      }
    } else if (listingType === "Media") {
      navigate(`/home/mediabuying/${id}`, {
        state: { type: "Media" },
      });
    } else if (listingType === "Product") {
      navigate(`/home/productdetail/${id}`);
    } else if (ProductSubCategory === "Gift Cards") {
      navigate(`/home/voucherdetail/${id}`);
    } else {
      navigate(`/home/productdetail/${id}`);
    }
  };

  const GetProductShareLink = async (id, type) => {
    await axios
      .get(`product_type/get_productType/${type}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.CompanyTypeName) {
          navigator.clipboard.writeText(
            `https://bxiworld.com/home/productdetail` + `/${id}`
          );
          GlobalToast("Link Copied", "info");
        }
      });
  };

  let login_User = loggedInUserData?.data?._id;
  useEffect(() => {
    if (login_User !== null) {
      dispatch(allNotification(login_User));
    }
  }, [login_User]);

  let socketId = socket.id;
  useEffect(() => {
    if (socketId !== undefined && login_User !== undefined) {
      socket.emit("newUser", { login_User, socketId });
    }
  });

  const handlePageChange = (event, page) => {
    dispatch(setActivePaginationCount(page));
    setCurrentPage(page);
  };

  async function handleGetBestSeller() {
    axios
      .get("AdminCompanyDetails/BestSeller")
      .then((res) => {})
      .catch((err) => {});
  }
  async function handleGetReadyStock() {
    axios
      .get("wallet/get_all_credit_line_transaction")
      .then((res) => {
        setIsReadyStock(res.data.body);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    handleGetBestSeller();
    handleGetReadyStock();
  }, []);

  useEffect(() => {
    <TostMessagesTop PageLocation="Marketplace" />;
  }, []);

  return (
    <Paper
      sx={{
        bgcolor: "#F3F6F9",
        position: "relative",
        borderRadius: "0px",
        boxShadow: "none",
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "scroll",
      }}
      elevation={0}
    >
      <Helmet>
        <meta charSet="utf-8" />

        <title>Explore Product Categories | BXI Barter Exchange India</title>
        <meta
          property="og:title"
          content="Explore Product Categories | BXI Barter Exchange India"
        />
        <meta
          name="og:description"
          content="Explore various product categories on BXI - Barter Exchange of India, from airline tickets to electronics, entertainment, FMCG, hotels, lifestyle, media, mobility, office supply, and more."
        />
        <meta
          name="description"
          content="Explore various product categories on BXI - Barter Exchange of India, from airline tickets to electronics, entertainment, FMCG, hotels, lifestyle, media, mobility, office supply, and more."
        />
      </Helmet>
      <HeaderExplore />
      <Paper
        sx={{
          overflowY: "scroll",
          bgcolor: "#F3F6F9",
        }}
        elevation={0}
      >
        <ExploreMarketPlace CategoryName={CategoryName} />

        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper
              elevation={0}
              sx={{
                bgcolor: "transparent",
                boxShadow: "none",
                borderRadius: "0px",
                width: "88%",
                mx: "auto",
                height: "30px",
                display: "flex",
                justifyContent: "space-between",
                my: 0.5,
              }}
            >
              <Typography sx={ExTextStyle}>Explore</Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: "88%",
            mx: "auto",
          }}
        >
          {loading === true ? (
            [...Array(12)].map((e, i) => (
              <Grid
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={6}
                key={i}
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "row",
                  gap: {
                    xl: "25px",
                    lg: "25px",
                    md: "25px",
                    sm: "5px",
                    xs: "5px",
                  },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: "15px",
                    maxWidth: {
                      xl: "300px",
                      lg: "300px",
                      md: "300px",
                      sm: "160px",
                      xs: "160px",
                    },
                    mx: "auto",
                    display: "flex",
                    gap: "15px",
                  }}
                  // gap={3}
                >
                  <Skeleton
                    variant="rectangular"
                    width={290}
                    height={300}
                    sx={{
                      borderRadius: "15px",
                    }}
                  />
                </Paper>
              </Grid>
            ))
          ) : productExplore?.products &&
            productExplore?.products?.length > 0 ? (
            productExplore?.products?.map((res, idx) => {
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={6}
                  sx={{
                    mt: 3,
                    display: "flex",
                    flexDirection: "row",
                    gap: {
                      xl: "25px",
                      lg: "25px",
                      md: "25px",
                      sm: "100px",
                      xs: "100px",
                    },
                  }}
                  key={idx}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      bgcolor: "transparent",
                      borderRadius: "15px",
                      maxWidth: {
                        xl: "300px",
                        lg: "300px",
                        md: "300px",
                        sm: "130px",
                        xs: "130px",
                      },
                      mx: "auto",
                      display: "flex",
                      minWidth: {
                        xl: "300px",
                        lg: "300px",
                        md: "300px",
                        sm: "160px",
                        xs: "160px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: {
                          xl: "100%",
                          lg: "99%",
                          md: "98%",
                          sm: "97%",
                          xs: "96%",
                        },
                        height: "auto",
                        minHeight: {
                          xl: "280px",
                          lg: "280px",
                          md: "280px",
                          sm: "180px",
                          xs: "180px",
                        },
                        bgcolor: "#fff",
                        borderRadius: "13.6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // p: 0.1,
                        cursor: "pointer",
                        border: "0.1px solid transparent",
                        backgroundImage:
                          "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                        transition: "box-shadow .1s",
                        "&:hover": {
                          boxShadow: "0px 0px 17px #ff864547",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mx: "auto",
                          height: "auto",
                          minHeight: {
                            xl: "280px",
                            lg: "280px",
                            md: "280px",
                            sm: "190px",
                            xs: "190px",
                          },
                          bgcolor: "#fff",
                          borderRadius: "13px",
                          padding: "5px",
                        }}
                      >
                        <Box sx={{ display: "flex", position: "relative" }}>
                          {/* {IsReadyStockflatIdsArray?.includes(`${res._id}`) ? (
                          <Box
                            sx={{
                              // mr: "auto",
                              left: "2%",
                              top: "-23%",
                              // marginLeft: "15px",
                              // marginTop: "-1px",
                              position: "absolute",
                            }}
                          >
                            <img
                              src={readystock}
                              alt=""
                              style={{
                                height: "auto",
                                width: "38px",
                              }}
                            />
                          </Box>
                        ) : null} */}
                          {IsBestSeller?.includes(`${res._id}`) ? (
                            <Box
                              sx={{
                                // left: IsReadyStockflatIdsArray?.includes(
                                //   `${res._id}`
                                // )
                                //   ? "15%"
                                //   : "2%",
                                top: "-23%",
                                position: "absolute",
                              }}
                            >
                              <img
                                src={bestseller}
                                alt=""
                                style={{
                                  height: "auto",
                                  width: "38px",
                                }}
                              />
                            </Box>
                          ) : null}
                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              width: "60px",
                              maxWidth: "60px",
                              ml: "auto",
                              mr: 2,
                              bgcolor: "transparent",
                              minHeight: "23px",
                              alignItems: "center",
                              height: "auto",
                              justifyContent: "space-evenly",
                              mt: 1,
                            }}
                          ></Paper>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "auto",
                            minHeight: {
                              xl: "160px",
                              lg: "160px",
                              md: "160px",
                              sm: "100px",
                              xs: "100px",
                            },
                            mx: "auto",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              res?.ProductImages.length > 0
                                ? res?.ProductImages[0].url
                                : res?.VoucherImages?.at(0).url
                            }
                            alt="img"
                            sx={{
                              width: "auto",
                              maxWidth: {
                                xl: "230px",
                                lg: "230px",
                                md: "230px",
                                sm: "130px",
                                xs: "130px",
                              },
                              maxHeight: {
                                xl: "130px",
                                lg: "130px",
                                md: "130px",
                                sm: "80px",
                                xs: "80px",
                              },
                              height: "auto",
                              marginLeft: "auto",
                              marginRight: "auto",
                              borderRadius: {
                                xl: "10px",
                                lg: "10px",
                                md: "10px",
                                sm: "5px",
                                xs: "5px",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            mx: "auto",
                            height: "auto",
                            display: "grid",
                            justifyContent: "center",
                            alignItems: "center",
                            pb: 2,
                            cursor: "pointer",
                            mt: 1,
                          }}
                        >
                          <Paper
                            sx={{
                              width: "90%",
                              mx: "auto",
                              height: "auto",
                              minWidth: {
                                xl: "240px",
                                lg: "240px",
                                md: "240px",
                                sm: "140px",
                                xs: "140px",
                              },
                              maxWidth: {
                                xl: "275px",
                                lg: "275px",
                                md: "275px",
                                sm: "150px",
                                xs: "150px",
                              },
                              display: "flex",
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                            elevation={0}
                          >
                            <Typography
                              sx={{
                                ...MainTextStyle,
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {res?.ProductName}
                            </Typography>
                            <Typography
                              sx={{
                                ...PriceTextStyle,
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <img
                                src={StacsOfCoinIcon}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                }}
                                alt="coin"
                              />
                              <CommaSeprator
                                Price={
                                  res?.ProductsVariantions[0]?.DiscountedPrice
                                    ? res?.ProductsVariantions[0]
                                        ?.DiscountedPrice
                                    : res?.mediaVariation?.price
                                    ? res?.mediaVariation?.price
                                    : 0
                                }
                              />
                            </Typography>{" "}
                          </Paper>
                          <Paper
                            sx={{
                              width: "90%",
                              height: "auto",
                              mx: "auto",
                              minHeight:
                                res?.ProductDescription.length >= 33
                                  ? "33px"
                                  : "34px",
                              minWidth: {
                                xl: "240px",
                                lg: "240px",
                                md: "240px",
                                sm: "140px",
                                xs: "140px",
                              },
                              maxWidth: {
                                xl: "275px",
                                lg: "275px",
                                md: "275px",
                                sm: "150px",
                                xs: "150px",
                              },
                            }}
                            elevation={0}
                          >
                            <Typography sx={BottomTextStyle}>
                              {res?.ProductDescription
                                ? res?.ProductDescription
                                : ""}
                            </Typography>
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              );
            })
          ) : (
            <Box
              sx={{
                mx: "auto",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                width: "100%",
                mt: 2,
              }}
            >
              <Box
                component="img"
                src={Nodataimgur}
                sx={{
                  width: "90%",
                  maxWidth: "250px",
                  height: "auto",
                  mx: "auto",
                }}
              />
              <Typography
                sx={{
                  ...EmptyWishlistTextOne,
                  lineHeight: "20px",
                }}
              >
                No Data Found!
                <span style={{ fontWeight: 400, fontSize: "12px" }}>
                  Sorry .. This information is not available for a moment.
                </span>
              </Typography>
              <Button sx={EmptyWishlistButton} onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Box>
          )}
        </Grid>
        {productExplore?.products && productExplore?.products?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={2}
          >
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCount}
                color="primary"
                // showFirstButton
                // showLastButton
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        ) : null}

        <TostMessagesTop PageLocation="Marketplace" />
      </Paper>
    </Paper>
  );
};

export default ExploreProductList;

const PriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "15px", lg: "15px", md: "15px", sm: "15px", xs: "15px" },
  lineHeight: "24px",
  color: "#717171",
};

const MainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "12px", xs: "12px" },
  lineHeight: "30px",
  color: "#141414",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  maxWidth: "160px",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const BottomTextStyle = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "11px",
    lg: "11px",
    md: "8px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "15px",
  color: "#6B7A99",
  textAlign: "left",
  mt: 0.5,
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const ExTextStyle = {
  fontFamily: "Source Sans Pro",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "35px",
  color: "#6B7A99",
};

const ViewAllTextStyle = {
  fontFamily: "Source Sans Pro",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "21px",

  color: "#6B7A99",
};

const EmptyWishlistTextOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  textAlign: "center",
  color: "#7E8BA6",
  display: "flex",
  flexDirection: "column",
};

const EmptyWishlistButton = {
  width: "231.32px",
  height: "36.67px",
  background: "#445FD2",
  borderRadius: "10px",
  color: "white",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  "&:hover": {
    bgcolor: "#445FD2",
  },
};

// company name product name random value
