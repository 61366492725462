/* eslint-disable react/prop-types */
import { Box, Typography } from "@mui/material";
import React from "react";

const ShadowBox = ({ imagePath, title, width, height }) => {
  return (
    <>
      <Box sx={flexBetween}>
        <Box sx={sellerBoxDesign}>
          <img
            src={imagePath}
            alt="imagePath"
            style={{
              width: width,
              height: height,
            }}
          />
        </Box>
      </Box>
      <Box sx={flexBetween}>
        <Typography
          sx={{
            width: "180px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: {
              xl: "14px",
              lg: "14px",
              md: "14px",
              sm: "12px",
              xs: "12px",
            },
            lineHeight: {
              xl: "26px",
              lg: "26px",
              md: "26px",
              sm: "18px",
              xs: "18px",
            },
            textAlign: "center",
            color: "rgba(73, 75, 122, 1)",
            marginTop: {
              xl: "27px",
              lg: "27px",
              md: "27px",
              sm: "16px",
              xs: "16px",
            },
          }}
        >
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default ShadowBox;
const sellerBoxDesign = {
  width: "82px",
  height: "82px",
  background: "rgba(255, 255, 255, 0.002)",
  borderRadius: "20px",
  border: "1px solid rgba(8, 142, 207, 1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "1px 1px 30.6px 1px rgba(114, 95, 297, 0.50)",
  },
};
const flexBetween = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
