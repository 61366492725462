/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { Stack } from "@mui/material";
import mainImg from "../assets/Images/register/underreview.svg";
import VerifiedRej from "../components/VerifiedRej";
import ImageIdentity from "../assets/under_review.png";
import { useNavigate } from "react-router";
import { useGetCompanyDetails } from "../Hooks/Auth";
import { useEffect } from "react";

const UnderReviewPage = () => {
  const navigate = useNavigate();
  const { data } = useGetCompanyDetails();
  useEffect(() => {
    if (data?.data?.companyOnboardingStatus === "TNC") {
      navigate(`/home/terms`);
    } else if (data?.data?.companyOnboardingStatus === "UNDER_REVIEW") {
      navigate(`/under_review`);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Stack>
        <VerifiedRej
          imgLogoUrl={ImageIdentity}
          imgSize={"115px"}
          mainimg={mainImg}
          headText={"Your Account Is Under Review"}
          subText={
            "Verification typically takes 24 hours, but in some cases, it can take up to two weeks. We will notify you when verification is completed"
          }
          ShowButton
          // showLoader={true}
        />
      </Stack>
    </React.Fragment>
  );
};

export default UnderReviewPage;
