import React, { useState } from 'react';
import Navbar from '../../views/LandingPage/Navbar';
import { Box, Grid, Typography, Paper, styled } from '@mui/material';
import BecomeMember from './HowItWorks/BecomeMember';
import Fade from 'react-reveal/Fade';
import bxifeature from '../../assets/bxifeaturelogo1.png';
import FinalBarterCoin from '../../assets/HomePageImages/FinalBarterCoin.png';
import OnlineShopping from '../../assets/HomePageImages/marketplace.png';
import LegalTender from '../../assets/HomePageImages/legaltender.svg';
import Accounting from '../../assets/HomePageImages/accounting.png';
import Final_BXI_COIN from '../../assets/HomePageImages/Final_BXI_COIN.png';
import Folder from '../../assets/HomePageImages/creditdebit.png';
import Tender from '../../assets/HomePageImages/exchange.png';
import BxiPointer from '../../assets/HomePageImages/BxiPointer.png';

function LearnBarter() {
  const [tab, setTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const tabZero = [
    {
      id: 1,
      question: 'What is barter?',
      answer:
        ' Barter involves exchanging goods and services without cash. Members give what they have and take what they need from other members offerings.',
    },
    {
      id: 2,
      question: 'How does barter work ?',
      answer:
        'Members must pledge their products or services to sell them. They can then use the equivalent value to buy anything listed on the marketplace.',
    },
    {
      id: 3,
      question: 'Who Can Barter ?',
      answer:
        'All reputable organizations registered with the ROC and offering products or services can barter on BXI.',
    },
    {
      id: 4,
      question: 'What Kind of Products & Services are available on Barter ?',
      answer:
        'BXI Marketplace offers a wide range of products and gift vouchers across 10+ categories, including media, airline, hotel, consumer durables, electronics, FMCG, Office Supply and more.',
    },
    {
      id: 5,
      question: 'How Secure is to Barter at BXI ?',
      answer:
        'BXI has a secure transaction process that holds INR payments in escrow until the buyer receives the goods or services.',
    },
    {
      id: 6,
      question: 'Is Barter Legal in India ?',
      answer:
        'Government approves of doing business on barter/kind. Both parties involved are required to issue invoices based on the actual value of goods or services exchanged, and pay the applicable Goods and Services Tax (GST) in INR. ',
    },
    {
      id: 7,
      question:
        'Do we need to pay GST in Barter Deal and How it works ? Will I get input credit in Barter Deal ?',
      answer:
        'On BXI, product values are in Barter Coins, while GST is paid in INR to the seller after a successful transaction. You would get input credit also. ',
    },
    {
      id: 8,
      question: 'Do I trade with BXI or with other Members Directly ?',
      answer:
        'BXI offers transparency by sharing contact details between buyer and seller after payment. Buyers can purchase multiple products from different sellers on the marketplace directly.',
    },
    {
      id: 9,
      question: 'Barter Deals happen on MRP / Cash Rates ?',
      answer:
        'We encourage BXI users to list their products and services at cash rates to promote more barter transactions.',
    },
    {
      id: 10,
      question: 'How does BXI Earn Money on Barter Deals ?',
      answer:
        'We earn through membership fees and success fees charged on successful transactions as per membership plans ',
    },
    {
      id: 11,
      question: 'How to Become a member on BXI Marketplace ?',
      answer:
        'To join BXI, members must be registered with the ROC, have a 3-year-old company, and pass GST verification. They also need to provide bank details to receive payments for sales.',
    },
  ];
  return (
    <>
      <Box sx={{ mt: 15 }}>
        <BecomeMember />
        <Box
          sx={{
            height: {
              xl: '80vh',
              lg: '80vh',
              md: '80vh',
              sm: '60vh',
              xs: '60vh',
            },
          }}
        >
          <Box
            sx={{
              display: {
                xl: 'flex',
                lg: 'flex',
                md: 'flex',
                sm: 'none',
                xs: 'none',
              },
              justifyContent: 'center',
            }}
          >
            <Fade top duration={1000}>
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  ...headingStyle,
                  fontWeight: 600,
                  textAlign: 'center',
                  fontSize: {
                    xs: '22px',
                    sm: '22px',
                    md: '28px',
                    lg: '28px',
                    xl: '28px',
                  },
                  lineHeight: {
                    xl: '65px',
                    lg: '65px',
                    md: '65px',
                    sm: '30px',
                    xs: '30px',
                  },
                }}
              >
                Introducing BXI Barter Coins for Business
              </Typography>
            </Fade>
          </Box>
          {/* small sc */}
          <Box
            sx={{
              display: {
                xl: 'none',
                lg: 'none',
                md: 'none',
                sm: 'flex',
                xs: 'flex',
              },
            }}
          >
            <Fade top duration={1000}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={{
                      ...headingStyle,
                      fontWeight: 600,
                      textAlign: 'center',
                      fontSize: {
                        xs: '22px',
                        sm: '22px',
                        md: '28px',
                        lg: '28px',
                        xl: '28px',
                      },
                      lineHeight: {
                        xl: '65px',
                        lg: '65px',
                        md: '65px',
                        sm: '30px',
                        xs: '30px',
                      },
                      ml: 1,
                    }}
                  >
                    Introducing BXI Barter Coins for Business
                  </Typography>
                </Box>
                <Typography
                  variant="inherit"
                  component="span"
                  sx={{
                    ...gradientText,
                    fontWeight: 600,
                    textAlign: 'center',
                    fontSize: {
                      xs: '22px',
                      sm: '22px',
                      md: '28px',
                      lg: '28px',
                      xl: '28px',
                    },
                    lineHeight: {
                      xl: '65px',
                      lg: '65px',
                      md: '65px',
                      sm: '30px',
                      xs: '30px',
                    },
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  for Business
                </Typography>
              </Box>
            </Fade>
          </Box>
          <Grid
            container
            sx={{
              width: '100%',
              mx: 'auto',
              height: 'auto',
              position: 'relative',
              display: 'flex',
              overflowX: 'clip',
              mt: 2,
            }}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
             sx={{ mt: { xl: 4, lg: 4, md: 4, sm: 8, xs: 8 } }}>
           
           <Box
              component={"iframe"}
                      src="https://www.youtube.com/embed/R7I5xfnAaYg"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      allowfullscreen
                      sx={{
                        width: {
                          xl: "95%",
                          lg: "95%",
                          md: "95%",
                          sm: "90%",
                          xs: "90%",
                        },
                        mx: "auto",
                        height: "55vh",
                        objectFit: "cover",
                        border: "none",
                        marginLeft: "20px",
                      }}
                    ></Box>
                  
                
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  marginLeft: {
                    xl: '200px',
                    lg: '200px',
                    md: '200px',
                    sm: '30px',
                    xs: '30px',
                  },
                  marginTop: '0px',
                  width: '90%',
                }}
              >
                
                <Box
                sx={{
                  position: 'relative',
                  display: {
                    xl: 'flex',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'none',
                    xs: 'none',
                  },
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    position: 'relative',
                    marginLeft: '100px',
                  }}
                >
                  <div className="bgImageWithUrl">
                    <div className="Bounce_img">
                      <Box
                        component="img"
                        src={FinalBarterCoin}
                        alt="altimage"
                        sx={{
                          height: 'auto',
                          maxWidth: '173px',
                          width: '100%',
                        }}
                      />
                    </div>
                    <Typography
                      sx={{
                        ...IBCBTypo,
                        fontSize: '15px',
                        position: 'absolute',
                        fontWeight: 400,
                        top: 220,
                        left:40
                      }}
                    >
                      1 Barter Coin = 1 INR
                    </Typography>
                  </div>
                </Box>
              </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: {
                      xl: '20px',
                      lg: '20px',
                      md: '20px',
                      sm: '8px',
                      xs: '8px',
                    },
                  }}
                >
                  <Fade top duration={1000}>
                    <img
                      src={bxifeature}
                      alt="bxifeature"
                      style={{ width: '20.82px', height: '36.52px' }}
                    />
                    <Typography
                      sx={{
                        ...IBCBTypo,
                        fontWeight: 400,
                        fontSize: {
                          xl: '20px',
                          lg: '20px',
                          md: '20px',
                          sm: '15px',
                          xs: '15px',
                        },
                      }}
                    >
                      Sell Your Products / Services in
                      <br /> the BXI Marketplace
                    </Typography>
                  </Fade>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: {
                      xl: '20px',
                      lg: '20px',
                      md: '20px',
                      sm: '8px',
                      xs: '8px',
                    },
                  }}
                >
                  <Fade top duration={1000}>
                    <img
                      src={bxifeature}
                      alt="bxifeature"
                      style={{ width: '20.82px', height: '36.52px' }}
                    />
                    <Typography
                      sx={{
                        ...IBCBTypo,
                        fontWeight: 400,
                        fontSize: {
                          xl: '20px',
                          lg: '20px',
                          md: '20px',
                          sm: '15px',
                          xs: '15px',
                        },
                      }}
                    >
                      Earn Barter Coins
                    </Typography>
                  </Fade>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '40px',
                  }}
                >
                  <Fade top duration={1000}>
                    <img
                      src={bxifeature}
                      alt="bxifeature"
                      style={{ width: '20.82px', height: '36.52px' }}
                    />
                    <Typography
                      sx={{
                        ...IBCBTypo,
                        fontWeight: 400,
                        fontSize: {
                          xl: '20px',
                          lg: '20px',
                          md: '20px',
                          sm: '15px',
                          xs: '15px',
                        },
                      }}
                    >
                      Barter Coins Allows You to Buy What
                      <br /> You Want From the BXI Marketplace
                    </Typography>
                  </Fade>
               </Box>
                
              </Box>
            </Grid>
            
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: { xl: 5, lg: 5, md: 5, sm: 3, xs: 3 },
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              How Barter Coin Works ?
            </Typography>
          </Fade>
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontWeight: 600,
                lineHeight: '20px',
                textAlign: 'center',
                fontSize: {
                  xl: '22px',
                  lg: '22px',
                  md: '22px',
                  sm: '18px',
                  xs: '18px',
                },
              }}
            >
              How to Earn Barter Coins?
            </Typography>
          </Fade>
          <Typography sx={subSubText}>Two Ways To Get Barter Coins</Typography>
          <br />
          <Typography sx={subSubText}>
            Upload Products - Sell and Earn Barter Coins
          </Typography>
          <Typography sx={subSubText}>
            Upload Products - Apply for Credit Line and Get Barter Coins in
            Advance
          </Typography>
        </Box>
        {/* How Barter Coin Works ?   */}
        <Box
          sx={{
            marginTop: {
              xl: '40px',
              lg: '40px',
              md: '40px',
              sm: '20px',
              xs: '20px',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                marginTop: {
                  xl: '10px',
                  lg: '10px',
                  md: '10px',
                  sm: '30px',
                  xs: '30px',
                },
                display: 'flex',
                alignItems: {
                  xl: 'flex-end',
                  lg: 'flex-end',
                  md: 'flex-end',
                  sm: 'center',
                  xs: 'center',
                },
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xl: 'flex-end',
                    lg: 'flex-end',
                    md: 'flex-end',
                    sm: 'center',
                    xs: 'center',
                  },
                  alignItems: 'center',
                  marginRight: {
                    xl: '50px',
                    lg: '50px',
                    md: '50px',
                    sm: '50px',
                    xs: '0px',
                  },
                }}
              >
                <Fade top duration={1000}>
                  <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography sx={CoinWorks}>
                      Earn and Spend Barter Coins
                    </Typography>
                    <Box
                      component="img"
                      src={OnlineShopping}
                      alt="OnlineShopping"
                      sx={{ width: '50.68px', height: '50.68px' }}
                    />
                  </Box>
                </Fade>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xl: 'flex-end',
                    lg: 'flex-end',
                    md: 'flex-end',
                    sm: 'center',
                    xs: 'center',
                  },
                  alignItems: 'center',
                  marginRight: {
                    xl: '50px',
                    lg: '50px',
                    md: '50px',
                    sm: '50px',
                    xs: '0px',
                  },
                }}
              >
                <Fade top duration={1000}>
                  <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography sx={CoinWorks}>Not a legal tender</Typography>
                    <Box
                      component="img"
                      src={LegalTender}
                      alt="LegalTender"
                      sx={{ width: '45.68px', height: '45.68px' }}
                    />
                  </Box>
                </Fade>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: {
                    xl: 'flex-end',
                    lg: 'flex-end',
                    md: 'flex-end',
                    sm: 'center',
                    xs: 'center',
                  },
                  marginRight: {
                    xl: '50px',
                    lg: '50px',
                    md: '50px',
                    sm: '50px',
                    xs: '0px',
                  },
                  // bgcolor:"red",
                }}
              >
                <Fade top duration={2000}>
                  <Box
                    sx={{
                      width: '250px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      sx={{
                        ...CoinWorks,
                        textAlign: {
                          xl: 'right',
                          lg: 'right',
                          md: 'right',
                          sm: 'center',
                          xs: 'center',
                        },
                      }}
                    >
                      For Accounting Purpose
                    </Typography>
                    <img
                      src={Accounting}
                      alt="OnlineShopping"
                      style={{ width: '50.68px', height: '50.68px' }}
                    />
                  </Box>
                </Fade>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                // marginRight:"10px"
                paddingRight: {
                  xl: '50px ',
                  lg: '50px ',
                  md: '50px ',
                },
              }}
            >
              <Box
                sx={{
                  // height: "400px",
                  // bgcolor: "red",
                  textAlign: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <div className="Bounce_img"> */}
                <Box
                  component="img"
                  src={Final_BXI_COIN}
                  alt="Final_BXI_COIN"
                  sx={{
                    width: {
                      xl: '85%',
                      lg: '85%',
                      md: '85%',
                      sm: '55%',
                      xs: '55%',
                    },
                    height: 'auto',
                    mx: 'auto',
                  }}
                />
                {/* </div> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                marginTop: {
                  xl: '10px',
                  lg: '10px',
                  md: '10px',
                  sm: '30px',
                  xs: '30px',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                gap: '30px',
                marginLeft: {
                  xl: '0px',
                  lg: '0px',
                  md: '0px',
                  sm: '0px',
                  xs: '80px',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Fade top duration={1000}>
                  <img
                    src={Folder}
                    alt="OnlineShopping"
                    style={{ width: '50.68px', height: '50.68px' }}
                  />
                  <Typography sx={CoinWorks2}>
                    Valid Inside Marketplace
                  </Typography>
                </Fade>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Fade top duration={1000}>
                  <img
                    src={Tender}
                    alt="OnlineShopping"
                    style={{ width: '50.68px', height: '50.68px' }}
                  />
                  <Typography sx={CoinWorks2}>Medium of Exchange</Typography>
                </Fade>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Fade top duration={1000}>
                  <img
                    src={Final_BXI_COIN}
                    alt="OnlineShopping"
                    style={{ width: '50.68px', height: '50.68px' }}
                  />
                  <Typography sx={CoinWorks2}>1 Barter Coin = 1 INR</Typography>
                </Fade>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: '60px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Fade top duration={1000}>
              <Typography
                variant="inherit"
                component="span"
                sx={{ ...headingStyle }}
              >
                How To Get Credit Line
              </Typography>
            </Fade>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                ...gradientSubHeadTypo,
                fontSize: {
                  xl: '24px',
                  lg: '24px',
                  md: '24px',
                  sm: '20px',
                  xs: '20px',
                },
              }}
            >
              Need Barter Coins in Advance
            </Typography>
          </Box>
          <Box sx={{ marginTop: '50px', position: 'relative' }}>
            <Box
              sx={{
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'none',
                  xs: 'none',
                },
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
                height: '100%',
                paddingTop: '16px',
                zIndex: -1,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '1px',
                  background: '#8073B5',
                }}
              />
            </Box>
            <Grid container>
              <Grid item xs={6} sm={4} md={2.4}>
                <Box sx={BoxLine}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'white',
                      width: '15%',
                    }}
                  >
                    <img
                      src={BxiPointer}
                      alt="BxiPointer"
                      style={{ width: '22.5px', height: '39.72px' }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '0px',
                    }}
                  >
                    <Typography sx={BoxLineText}>
                      Select product you
                      <br /> want to apply for
                      <br /> credit line
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2.4}>
                <Box sx={BoxLine}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'white',
                      width: '15%',
                    }}
                  >
                    <img
                      src={BxiPointer}
                      alt="BxiPointer"
                      style={{ width: '22.5px', height: '39.72px' }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={BoxLineText}>
                      BXI evaluates
                      <br /> the product
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2.4}>
                <Box sx={BoxLine}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'white',
                      width: '15%',
                    }}
                  >
                    <img
                      src={BxiPointer}
                      alt="BxiPointer"
                      style={{ width: '22.5px', height: '39.72px' }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={BoxLineText}>
                      Credit line limit
                      <br /> is offered
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2.4}>
                <Box sx={BoxLine}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'white',
                      width: '15%',
                    }}
                  >
                    <img
                      src={BxiPointer}
                      alt="BxiPointer"
                      style={{ width: '22.5px', height: '39.72px' }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={BoxLineText}>
                      Sign document
                      <br /> & avail the limit
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={2.4}>
                <Box sx={BoxLine}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'white',
                      width: '15%',
                    }}
                  >
                    <img
                      src={BxiPointer}
                      alt="BxiPointer"
                      style={{ width: '22.5px', height: '39.72px' }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={BoxLineText}>
                      Start to buy even
                      <br /> before you sell
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: {
              xl: '60px',
              lg: '60px',
              md: '60px',
              sm: '40px',
              xs: '40px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '95%',
              alignItems: 'center',
              mx: 'auto',
              textAlign: 'center',
            }}
          >
            <Fade top duration={1000}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    ...headingStyle,
                    fontSize: {
                      xs: '22px',
                      sm: '22px',
                      md: '28px',
                      lg: '28px',
                      xl: '28px',
                    },
                    lineHeight: {
                      xl: '65px',
                      lg: '65px',
                      md: '65px',
                      sm: '30px',
                      xs: '30px',
                    },
                    textAlign: 'center',
                  }}
                >
                  Client Stories
                </Typography>
              </Box>
            </Fade>
          </Box>
          <Paper elevation={0} sx={{ width: 'auto', height: '90%' }}>
            <Box
              sx={{
                width: '90%',
                mx: 'auto',
                marginTop: '45px',
                marginBottom: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/YOJUPi0FBLc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
                style={{
                  width: '100%',
                  height: '100%',
                  maxWidth: '850px',
                  maxHeight: '479px',
                  minHeight: '479px',
                  objectFit: 'cover',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  borderLeft: 'none',
                  borderRight: 'none',
                  backgroundColor: 'white',
                }}
              ></iframe>
            </Box>
          </Paper>
        </Box>
        {/* FAQs */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
            flexDirection: 'column',
            width: '90%',
            mx: 'auto',
            mb: 5,
          }}
        >
          <Typography variant="inherit" component="span" sx={headingStyle}>
            FAQ's
          </Typography>
          <Box
            sx={{
              border: '1px solid rgba(230, 233, 245, 1)',
              borderRadius: '10px',
              width: '90%',
              mx: 'auto',
              height: 'auto',
              px: 3,
            }}
          >
            {tab === 0 ? (
              <Box>
                {tabZero?.map((item, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '95%',
                        mt: 0.5,
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => onTitleClick(index)}
                    >
                      <Typography sx={{ ...contentStyle, marginBottom: 0 }}>
                        {item?.question}
                      </Typography>
                      <Box
                        className="accordion-title"
                        onClick={() => onTitleClick(index)}
                        mt={1}
                        sx={{
                          cursor: 'pointer',
                          color: '#000',
                          fontSize: '20px',
                        }}
                      >
                        {index === activeIndex ? '-' : '+'}
                      </Box>
                    </Box>
                    <AccordionContent
                      className={index === activeIndex ? 'open' : ''}
                    >
                      <Typography sx={summerysubText}>
                        {item?.answer}
                      </Typography>
                    </AccordionContent>
                    <Box
                      sx={{
                        borderBottom: '1px solid rgba(230, 233, 245, 1)',
                        py: 0.5,
                      }}
                    />
                  </div>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LearnBarter;

const headingStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '22px',
    sm: '22px',
    md: '28px',
    lg: '28px',
    xl: '28px',
  },
  letterSpacing: '0.5px',
  lineHeight: { xl: '65px', lg: '65px', md: '65px', sm: '50px', xs: '50px' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#0D0E0E',
  marginRight: '10px',
  textTransform: 'uppercase',
};

const gradientText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '22px',
    sm: '22px',
    md: '28px',
    lg: '28px',
    xl: '28px',
  },
  letterSpacing: '0.5px',
  lineHeight: { xl: '65px', lg: '65px', md: '65px', sm: '50px', xs: '50px' },
  display: 'flex',
  alignItems: 'center',
  background: 'linear-gradient(75deg, #375DBB 29.17%, #00B1FF 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  textTransform: 'uppercase',
};

const IBCBTypo = {
  width: '452.89px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: { xl: '20px', lg: '20px', md: '20px', sm: '10px', xs: '10px' },
  lineHeight: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
  display: 'flex',
  alignItems: 'center',
  color: '#494B7A',
  marginLeft: '20px',
};

const subSubText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xl: '16px',
    lg: '16px',
    md: '15px',
    sm: '15px',
    xs: '15px',
  },
  lineHeight: '24px',
  color: '#717171',
};

const CoinWorks = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '26px',
  color: 'rgba(73, 75, 122, 1)',
  display: 'flex',
  alignItems: 'center',
  textAlign: {
    xl: 'right',
    lg: 'right',
    md: 'center',
    sm: 'center',
    xs: 'center',
  },
  marginRight: '20px',
  fontWeight: 500,
};

const CoinWorks2 = {
  width: {
    xl: '385px',
    lg: '385px',
    md: '340px',
    sm: '300px',
    xs: '300px',
  },
  fontSize: '14px',
  lineHeight: '26px',
  color: 'rgba(73, 75, 122, 1)',
  display: 'flex',
  alignItems: 'center',
  textAlign: {
    xl: 'left',
    lg: 'left',
    md: 'center',
    sm: 'center',
    xs: 'center',
  },
  color: '#494B7A',
  marginLeft: '20px',
};

const gradientSubHeadTypo = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: {
    xs: '22px',
    sm: '28px',
    md: '32px',
    lg: '32px',
    xl: '32px',
  },
  lineHeight: '30px',
  display: 'flex',
  alignItems: 'center',
  color: '#494B7A',
};

const BoxLine = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

const BoxLineText = {
  width: {
    xl: '228px',
    lg: '228px',
    md: '188px',
    sm: '158px',
    xs: '158px',
  },
  height: '89px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: {
    xl: '14px',
    lg: '14px',
    md: '14px',
    sm: '14px',
    xs: '14px',
  },
  // lineHeight: "30px",
  display: 'flex',
  justifyContent: 'center',
  // alignItems: "center",
  mt: 2,
  textAlign: 'center',
  color: 'rgba(73, 75, 122, 1)',
  // bgcolor: "red",
};

const summerysubText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  textAlign: 'left',
  color: 'rgba(23, 22, 24, 1)',
};
const AccordionContent = styled(Box)(({ theme }) => ({
  maxHeight: 0,
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-out',
  '&.open': {
    maxHeight: '500px', // Adjust this value based on your content
    transition: 'max-height 0.3s ease-in',
  },
}));

const contentStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '12px' },
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(73, 75, 122, 1)',
  marginBottom: '15px',
  // textTransform: "lowercase",
};

