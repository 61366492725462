import TabPanel from "@mui/lab/TabPanel";
import {
  Accordion,
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Tabs,
  Typography,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CartIcon from "../../assets/HeaderIcon/Cart.png";
import ChatIcon from "../../assets/HeaderIcon/Chat.png";
import NotifyIcon from "../../assets/HeaderIcon/Notification.png";
import SearchIcon from "../../assets/HeaderIcon/Search.png";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabScrollButton from "@mui/material/TabScrollButton";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "timeago.js";
import CloseIconForNotification from "../../assets/HeaderIcon/CloseIconForNotification.svg";
import FullScreenIcon from "../../assets/HeaderIcon/FullScreenIcon.svg";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { socket } from "../../pages/Message/Message";
import { GetLastSeens } from "../../redux/action/Chat/GetLastSeen";
import { LastSeenSet } from "../../redux/action/Chat/LastSeen";
import { getProductExplore } from "../../redux/action/Products/products";
import { allNotification } from "../../redux/action/Notification/getNotifications";
import { ProfileCompletions } from "../../redux/action/Profile/Profile-Completion";
import sendEvents from "../../utils/sendEvents";
import ToastMessage from "../../Component/Toast";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import { toast, ToastContainer } from "react-toastify";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import useGetCompanyProfileCompletionPercentage from "../../Hooks/CompanyData/CompanyProfile";

export let Search = "";
export let Tag = "";
export let ProductDescriptionSearch = "";
export let ProductCompanyNameSearch = "";
export let ProductSubtittleSearch = "";

const HeaderExplore = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [notification, setNotification] = useState([]);
  const [notificationDot, setNotificationDot] = useState(false);
  const [cartUpdateNotification, setCartUpdateNotification] = useState(false);
  const [messagenotificationDot, setMessageNotificationDot] = useState(false);
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [descriptionSearch, setDescriptionSearch] = useState("");
  const [productCompanyNameSearch, setProductCompanyNameSearch] = useState("");
  const [productSubtittleSearch, setProductSubtittleSearch] = useState("");
  const [totalCountBXIArray, SetTotalCountBXIArray] = useState([]);
  const [totalCountBuyerArray, setTotalCountBuyerArray] = useState([]);
  const [totalCountSellerArray, setTotalCountSellerArray] = useState([]);
  const [incrementAll, setIncrementAll] = useState(false);
  const [incrementBxi, setIncrementBxi] = useState(false);
  const [incrementBuy, setIncrementBuy] = useState(false);
  const [incrementSeller, setIncrementSeller] = useState(false);
  const [unReadCountOfAll, setUnReadCountOfAll] = useState(null);
  const [unReadCountOfBxi, setUnReadCountOfBxi] = useState(null);
  const [unReadCountOfBuyer, setUnReadCountOfBuyer] = useState(null);
  const [unReadCountOfSeller, setUnReadCountOfSeller] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [UpdateNotiticationStatus, setUpdateNotiticationStatus] =
    useState(false);

  const inputRef = useRef(null);

  const handleStyleChange = () => {
    inputRef.current.style.color = "#C3CAD9";
    inputRef.current.style.fontSize = "14px";
    inputRef.current.style.fontFamily = "Poppins";
  };

  const handleFocus = () => {
    if (isExpanded === false) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  const handleBlur = () => {
    setIsExpanded(false);
  };

  Search = search;
  Tag = tag;
  ProductDescriptionSearch = descriptionSearch;
  ProductCompanyNameSearch = productCompanyNameSearch;
  ProductSubtittleSearch = productSubtittleSearch;

  useEffect(() => {
    dispatch(
      getProductExplore(
        search,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        tag,
        descriptionSearch,
        productCompanyNameSearch,
        productSubtittleSearch
      )
    );
  }, [dispatch, search, tag, descriptionSearch, productSubtittleSearch]);

  return (
    <React.Fragment>
      <ToastContainer style={{ fontSize: "16px" }} />
      <Paper
        sx={{
          position: "relative",
          borderBottom: "2px solid #EDEFF2;",
          height: "65px",
          pl: { xl: "70px", lg: "70px", md: "70px", sm: "20px", xs: "20px" },
          background: "transparent",
        }}
        elevation={0}
      >
        <Paper
          sx={{
            width: "100%",
            maxWidth: "100vw",
            overflowX: "hidden",
            borderRadius: "0px",
            boxShadow: "none",
            display: "flex",
            justifyContent: "space-evenly",
            background: "transparent",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
          elevation={0}
        >
          <Grid container>
            <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "end",
                  width: "100%",
                }}
              >
                <a
                  href={"/login"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Box
                    sx={{
                      // bgcolor: "red",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "end",
                      flexDirection: "row",
                      gap: "8px",
                      textDecoration: "none",
                    }}
                  >
                    <img
                      src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXI_LOGO.png"
                      alt=""
                      width="40px"
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        textAlign: "center",
                        color: "#111034",
                        mb: "-4px",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                          xl: "block",
                        },
                      }}
                    >
                      Barter Exchange of India
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
            <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5} xl={7.5}>
              <Box
                sx={{
                  display: "flex",
                  width: "98%",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "center",
                    gap: "05px",
                    width: "400px",
                  }}
                >
                  {location.pathname === "/explore" && (
                    <Box
                      sx={{
                        ...ButtonsStyle,
                        bgcolor: "#FFFFFF",
                        width: isExpanded ? "200px" : "45px",
                        transition: "all 0.6s",
                        height: "45px",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems:"end",
                        alignContent: "end",
                      }}
                    >
                      {isExpanded ? (
                        <input
                          onClick={() => {
                            handleStyleChange();
                            sendEvents("Click on search expand");
                          }}
                          ref={inputRef}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setTag(e.target.value);
                            setDescriptionSearch(e.target.value);
                            setProductCompanyNameSearch(e.target.value);
                            setProductSubtittleSearch(e.target.value);
                          }}
                          type="text"
                          placeholder={isExpanded ? "Search" : ""}
                          style={{
                            border: "none",
                            outline: "none",
                            width: "90%",
                            height: "90%",
                            background: "#FFFFFF",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
                            borderRadius: "100px 0px 00px 100px",
                            cursor: "pointer",
                            paddingLeft: "15px",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "#C3CAD9",
                            marginLeft: "5px",
                          }}
                          onBlur={handleBlur}
                        />
                      ) : null}

                      {isExpanded ? (
                        <img
                          src={SearchIcon}
                          onClick={() => {
                            handleFocus();
                            sendEvents("Click on search button");
                          }}
                          style={{
                            height: "18px",
                            marginRight: "6px",
                          }}
                        />
                      ) : (
                        <img
                          src={SearchIcon}
                          onClick={() => {
                            handleFocus();
                            sendEvents("Click on search button");
                          }}
                          style={{
                            height: "18px",
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>

                <Link to={"/login"} style={{ textDecoration: "none" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "auto",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      maxWidth: "220px",
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar>BXI</Avatar>
                    </Stack>
                  </Box>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default HeaderExplore;

const ExploreButtonStyle = {
  width: "92px",
  height: "44px",
  background: "#FFFFFF",
  borderRadius: "6px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "21px",
  /* identical to box height */
  textAlign: "center",
  color: "#6B7A99",
  textTransform: "none",
};

const ButtonsStyle = {
  width: "45px",
  height: "45px",
  background: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
  borderRadius: "100px",
  cursor: "pointer",
};

const UnActiveExploreButtonStyle = {
  width: "92px",
  height: "44px",
  background: "transparent",
  borderRadius: "6px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "21px",
  /* identical to box height */
  textAlign: "center",
  color: "#ADB8CC",
  textTransform: "none",
};

const HeaderTextStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const UserNameTextStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const style = {
  position: "absolute",
  top: "37%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  height: "370px",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 4,
  p: "30px",
  borderRadiusTopRight: "10px",
  maxWidth: "500px",
  minWidth: "250px",
  borderRadius: "20px 20px 0px 0px",
};

const HeaderIcons = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const SubTabStyle = {
  width: "auto",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "15px",
  color: "#000000",
};

const NotificationMessage = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const NotificationTime = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  color: "#6C6C6C",
};

const notificationcount = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "22px",
  height: "20px",
  background: "#F8E8EA",
  borderRadius: "2px",
  mt: 1.2,
  p: "3px",
  ml: "-3%",
};
