export const MarketPlaceStyle = {
  BoxStyle: {
    width: "100%",
    background: "#f3f6f9",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    mx: "auto",
    pt: "35px",
    top: 0,
    position: "relative",
    zIndex: "2",
  },
};
