// mutate use query to update media content

import { useMutation } from "react-query";
import axios from "axios";

const useUpdateMediaContent = () => {
  return useMutation(
    async (id, formData) => {
      let link = `purchase/update_orderSummarycontant/${id}`;
      const { data } = await axios.put(link, formData, {
        withCredentials: true,
      });

      return data;
    },
    {
      onSuccess: (data) => {
        return data;
      },
      onError: (error) => {
        return error;
      },
    }
  );
};

export default useUpdateMediaContent;
