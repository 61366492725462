import axios from "axios";
import { useQuery } from "react-query";

const useGetCompanyTypeData = (id) => {
  let companyTypeData = localStorage.getItem("companyTypeData");
  if (companyTypeData) {
    return {
      data: JSON.parse(companyTypeData),
      isLoading: false,
      error: false,
      refetch: () => {},
    };
  } else {
    const { data, isLoading, error, refetch } = useQuery(
      ["companyTypeData", id],
      () => axios.get(`company_type/get_companyType/${id}`)
    );
    return { data, isLoading, error, refetch };
  }
};

export default useGetCompanyTypeData;
