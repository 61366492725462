/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography, Tooltip, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "../assets/InfoIcon.svg";

const ToolTip = (props) => {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
          background: #C64091;
          width:200px;
      `);
  return (
    <>
      <CustomTooltip
        title={<Typography sx={ToolTextStyle}>{props.info}</Typography>}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 400 }}
      >
        <Box
          component="img"
          src={InfoIcon}
          sx={{ width: "18px", height: "auto", cursor: "pointer" }}
        />
      </CustomTooltip>
    </>
  );
};

export default ToolTip;

const ToolTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "13px",
  color: "#fff",
  textAlign: "center",
  cursor: "pointer",
};
