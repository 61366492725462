import React, { useState } from 'react';
import MainLogo from './MainLogo.jsx';
import { useAddPassword } from '../../Hooks/Auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import mainImg from '../../assets/Images/register/addpassword.svg';
import { Grid, Typography, Box, Button, Paper, TextField } from '@mui/material';
import * as StlyesOnBoarding from '../../components/common/Styled/StylesOnBoarding.js';

const AddPassoword = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  // Add Password
  const { mutate } = useAddPassword();
  const addPassword = () => {
    const data = { email, password, rePassword };
    data(mutate);
  };
  return (
    <Paper elevation={0} sx={StlyesOnBoarding.mainCommonPage}>
      <Grid
        container
        sx={{
          background: '#fff',
          height: '100vh',
          width: {
            xl: '110%',
            lg: '110%',
            md: '110%',
            sm: '100%',
            xs: '100%',
          },
        }}
      >
        {/* detail Grid */}
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '-20rem',
          }}
        >
          <Grid
            container
            sx={{
              height: {
                xl: '30%',
                lg: '30%',
                md: '30%',
                sm: '10%',
                xs: '10%',
              },
            }}
          >
            <MainLogo />
          </Grid>
          <Typography sx={StlyesOnBoarding.HeadText}>Add Password </Typography>
          <Box
            mt={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              width: '75%',
              gap: '20px',
            }}
          >
            {/* input Box */}
            <ThemeProvider theme={outerTheme}>
              <TextField
                label="Enter Password"
                placeholder="*******"
                variant="outlined"
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: '1px #CCCCCC',
                  },
                }}
              />
              <TextField
                label="Re - write password"
                placeholder="*******"
                variant="outlined"
                required
                onChange={(e) => {
                  setRePassword(e.target.value);
                }}
                InputProps={{
                  style: {
                    borderRadius: '12px',
                    border: '1px #CCCCCC',
                  },
                }}
              />
            </ThemeProvider>
          </Box>
          {/* Buttons next and back */}
          <Box sx={{ display: 'flex', mt: 4, width: '75%', gap: '10px' }}>
            <Button
              variant="outlined"
              fullWidth
              sx={StlyesOnBoarding.outlineBtn}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{ ...StlyesOnBoarding.CommonBtn, fontSize: '1.4rem' }}
              onClick={addPassword}
            >
              Add Password
            </Button>
          </Box>
        </Grid>
        {/* image Grid */}
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <img
            src={mainImg}
            alt="img"
            style={{
              height: 'auto',
              width: '100%',
              maxHeight: '100vh',
              marginRight: '150px',
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default AddPassoword;

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#6B7A99',
    },
  },
  typography: {
    fontSize: 20,
  },
});
