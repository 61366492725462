import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom/dist';
import BxiWhiteLogo from '../../assets/HomePageImages/BXI_WHITE_LOGO.png';
import callfooter from '../../assets/HomePageImages/callfooter.svg';
import messagefooter from '../../assets/HomePageImages/messagefooter.svg';
import IRTA_LOGO from '../../assets/HomePageImages/IRTA_LOGO.png';
import NDA from '../../assets/HomePageImages/NDA.png';
import pdfimgnew from '../../assets/HomePageImages/pdfimgnew.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import NewTwitter from '../../assets/twitterVector.svg';
import YouTubeIcon from '@mui/icons-material/YouTube';
import fb from '../../assets/HomePageImages/fb.svg';
import { keyframes } from '@emotion/react';
const Footer = (props) => {
  const navigate = useNavigate();
  const Location = useLocation();
  const buttonAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;
  async function openEmail(e) {
    window.location.href = 'mailto:business@bxiworld.com';
  }

  return (
    <>
      {/* <Box
        sx={{
          background: "#156DB6",
          marginTop: "10rem",
          padding: "0.5rem",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={2.5}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{}}>
                <img src={BxiWhiteLogo} width={50} alt="BxiWhiteLogo" />
              </Box>

              <Typography
                sx={{
                  ...FooterText,
                  fontWeight: 550,
                  fontSize: "16px",
                  lineHeight: "25px",
                  marginTop: "5px",
                }}
              >
                Pay By Products!
              </Typography>
              <Button
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "32px",
                  width: "112.89px",
                  height: "20.63px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                  boxShadow: "0px 8px 16px rgba(30, 30, 30, 0.5)",
                  transform: "scale(1)",
                  transition: "0.5s ease-in-out",
                  "&:hover": {
                    borderRadius: "32px",
                    // border: "2px solid #000 ",
                    background: "#fff",
                    // animation: `${buttonAnimation} 1s ease forwards`,
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() => navigate("/login")}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "9.3315px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "#121136",
                  }}
                >
                  Join Now
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={1.8} xl={2.5} lg={2.5} sx={{ mt: 0.5 }}>
            <Box>
              <Typography
                sx={{
                  ...FooterText,
                  fontWeight: 550,
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                Helpful Links
              </Typography>
              <Typography sx={FooterText}>How we work</Typography>
              <Box
                onClick={
                  Location?.pathname === "/howitworks"
                    ? props.HandleFlick
                    : props.handleClick
                }
                sx={{
                  ...FooterText,
                  fontWeight: 400,
                  lineHeight: "35px",
                  cursor: "pointer",
                }}
              >
                About BXI
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3.2} xl={3} lg={3} sx={{ mt: 0.5 }}>
            <Box>
              <Typography
                sx={{
                  ...FooterText,
                  fontWeight: 550,
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                Barter Exchange of India
              </Typography>
              <Typography sx={FooterText}>PAN: AAXFB2929C </Typography>
              <Typography sx={FooterText}>GST: 27AAXFB2929C1ZA </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4.5} xl={4} lg={4} sx={{ mt: 0.5 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  ...FooterText,
                  fontWeight: 550,
                  fontSize: "18px",
                  lineHeight: "35px",
                }}
              >
                “Member of Global Barter Council”
              </Typography>
              <Box sx={{ marginTop: "6px" }}>
                <img
                  src={IRTA_LOGO}
                  style={{ width: "166.68px", height: "66.88px" }}
                  alt="imageAlt"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box> */}
      {/* <Box
        sx={{
          padding: "20px 0",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={textDesign}>
                @2023 Barter Exchange of India. All Rights Reserved.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box
                  sx={{
                    ...socialIcons,
                  }}
                >
                  <a
                    href=" https://www.facebook.com/bxi.world?mibextid=ZbWKwL"
                    target="_blank"
                    alt="wtsp"
                    style={{
                      color: "inherit",
                      marginTop: "3px",
                    }}
                  >
                    <Box>
                      <FacebookIcon sx={{ color: "fff", background: "fff" }} />
                    </Box>
                  </a>
                </Box>
                <Box sx={socialIcons}>
                  <a
                    href="https://instagram.com/bxi.world?igshid=MzRlODBiNWFlZA== "
                    target="_blank"
                    alt="wtsp"
                    style={{
                      color: "inherit",
                      marginTop: "3px",
                    }}
                  >
                    <Box>
                      <InstagramIcon sx={{ color: "fff", background: "fff" }} />
                    </Box>
                  </a>
                </Box>
                <Box sx={socialIcons}>
                  <a
                    href=" https://www.linkedin.com/company/barter-exchange-of-india/"
                    target="_blank"
                    alt="wtsp"
                    style={{
                      color: "inherit",
                      marginTop: "3px",
                    }}
                  >
                    <Box>
                      <LinkedInIcon sx={{ color: "fff", background: "fff" }} />
                    </Box>
                  </a>
                </Box>
                <Box sx={socialIcons}>
                  <a
                    href="https://twitter.com/bximarketplace?s=20"
                    target="_blank"
                    alt="wtsp"
                    style={{
                      color: "inherit",
                      marginTop: "3px",
                    }}
                  >
                    <Box>
                      <TwitterIcon sx={{ color: "fff", background: "fff" }} />
                    </Box>
                  </a>
                </Box>
                <Box sx={socialIcons}>
                  <a
                    href="https://www.youtube.com/@bxi.world_"
                    target="_blank"
                    alt="wtsp"
                    style={{
                      color: "inherit",
                      marginTop: "3px",
                    }}
                  >
                    <Box>
                      <YouTubeIcon sx={{ color: "fff", background: "fff" }} />
                    </Box>
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={textDesign}>
                Privacy Policy | Terms & Conditions
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> */}

      <Grid
        container
        sx={{
          background: 'rgba(21, 109, 182, 1)',
          p: 1,
        }}
      >
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xl: 'flex-start',
                lg: 'flex-start',
                md: 'center',
                sm: 'center',
                xs: 'center',
              },
              width: '90%',
              mx: 'auto',
            }}
          >
            <img src={BxiWhiteLogo} width={50} alt="BxiWhiteLogo" />
          </Box>
          <Box>
            <Typography
              sx={{
                ...FooterText,
                width: '90%',
                mx: 'auto',
                lineHeight: '50px',
              }}
            >
              Address
            </Typography>
            <Typography
              sx={{
                ...textDesign,
                width: '90%',
                mx: 'auto',
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              501- Meadows Tower, Sahar Plaza , Sir M.V. Road, Andheri (E),
              Mumbai 400059.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...FooterText,
                width: '90%',
                mx: 'auto',
                lineHeight: '50px',
              }}
            >
              Contact Us
            </Typography>
            <a
              href="https://api.whatsapp.com/send/?phone=9136436776&text&type=phone_number&app_absent=0"
              target="_blank"
              alt="wtsp"
              style={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '95%',
                  mx: 'auto',
                  gap: '10px',
                  justifyContent: {
                    xl: 'flex-start',
                    lg: 'flex-start',
                    md: 'center',
                    sm: 'center',
                    xs: 'center',
                  },
                }}
              >
                <img
                  src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/whatsapp.png`}
                  alt="wtsp"
                  style={{ width: '20px', height: 'auto', marginLeft: '07px' }}
                />

                <Typography sx={{ ...textDesign, mt: 0.5 }}>
                  +91 9136436776
                </Typography>
              </Box>
            </a>
            <Box
              onClick={openEmail}
              sx={{
                display: 'flex',
                width: '95%',
                mx: 'auto',
                gap: '10px',
                cursor: 'pointer',
                mt: 1,
                justifyContent: {
                  xl: 'flex-start',
                  lg: 'flex-start',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              <img
                src={callfooter}
                alt=""
                style={{ width: '30px', height: 'auto' }}
              />
              <Typography sx={{ ...textDesign, mt: 0.7 }}>
                022 4964 6776
              </Typography>
            </Box>
            <Box
              onClick={openEmail}
              sx={{
                display: 'flex',
                width: '95%',
                mx: 'auto',
                gap: '10px',
                cursor: 'pointer',
                mt: 1,
                justifyContent: {
                  xl: 'flex-start',
                  lg: 'flex-start',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              <img
                src={callfooter}
                alt=""
                style={{ width: '30px', height: 'auto' }}
              />
              <Typography sx={{ ...textDesign, mt: 0.7 }}>
                +91 88283 26776
              </Typography>
            </Box>
            <Box
              onClick={openEmail}
              sx={{
                display: 'flex',
                width: '95%',
                mx: 'auto',
                gap: '10px',
                cursor: 'pointer',
                mt: 0,
                justifyContent: {
                  xl: 'flex-start',
                  lg: 'flex-start',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              <img
                src={messagefooter}
                alt=""
                style={{ width: '30px', height: 'auto' }}
              />
              <Typography sx={{ ...textDesign, mt: 0.7 }}>
                business@bxiworld.com
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{ pt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }}
        >
          <Box sx={{ mt: { xl: 5, lg: 5, md: 0, sm: 0, xs: 0 } }}>
            <Typography sx={{ ...FooterText, textAlign: 'center' }}>
              Helpful Links
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '100%',
                ml: { xl: '35%', lg: '35%', md: '0', sm: '0', xs: '0' },
              }}
            >
              <Box
                onClick={() => {
                  navigate('/about');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                sx={{
                  ...textDesign,
                  cursor: 'pointer',

                  mx: 'auto',
                  lineHeight: '25px',
                }}
              >
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  About Us
                </Typography>
              </Box>
              <Link to="/howitworks" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  How We Work
                </Typography>
              </Link>
              <Link to="/blogpage" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Blog
                </Typography>
              </Link>
              <Link to="/termsconditions" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Link>
              <Link to="/privacypolicy" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
              <Link to="/legalpolicy" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Legal Policy
                </Typography>
              </Link>
              <Link to="/contactus" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Contact us
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{ pt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }}
        >
          <Box sx={{ mt: { xl: 6, lg: 6, md: 1, sm: 1, xs: 1 } }}>
            <Typography
              sx={{
                ...FooterText,
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                lineHeight: '20px',
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
              }}
            >
              BXI World LLP
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                fontSize: '9px',
                fontFamily: 'Poppins',
                fontWeight: 200,
              }}
            >
              Registered Name
            </Typography>
            <Typography
              sx={{
                ...textDesign,
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                lineHeight: '25px',
              }}
            >
              PAN: AAXFB2929C
            </Typography>
            <Typography
              sx={{
                ...textDesign,
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                lineHeight: '25px',
              }}
            >
              GST: 27AAXFB2929C1ZA
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{ pt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }}
        >
          <Box
            sx={{ mt: { xl: 5, lg: 5, md: 0, sm: 0, xs: 0 }, width: '340px' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <a href="https://www.irta.com/member-directory/" target="_blank">
                <img
                  src={IRTA_LOGO}
                  style={{ width: '150.68px', height: '50.88px' }}
                  alt="imageAlt"
                />
              </a>
              <Typography
                sx={{
                  ...FooterText,
                  textAlign: 'left',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  mt: 2,
                  ml: 2,
                }}
              >
                Member of Global Barter Council
              </Typography>
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                mt: 2.5,
                gap: "20px",
              }}
            >
              <img
                src={NDA}
                style={{ width: "155px", height: "30px", marginTop: "5px" }}
                alt="imageAlt"
              />
              <Box
                sx={{
                  mt: 0.5,

                  cursor: "pointer",
                  width: "200px",
                }}
                onClick={() => {
                  var win = window.open(
                    "https://bxi-development.s3.ap-south-1.amazonaws.com/invoicepdf/nishitDesaipdf.pdf",
                    "_blank"
                  );
                  win.focus();
                }}
              >
                <Typography
                  sx={{
                    ...legalText,
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "10px",
                    textAlign: "left",
                    position: "relative",
                    lineHeight: "20px",
                  }}
                >
                  Legal Opinion on Business{" "}
                  <span style={{ marginLeft: "10px", position: "absolute" }}>
                    {" "}
                    <img src={pdfimgnew} alt="leagal" />
                  </span>
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Grid>
        <Box
          sx={{
            borderBottom: '1px solid #fff',
            width: '98%',
            mx: 'auto',
            mt: 1,
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xl: 'flex-end',
                lg: 'flex-end',
                md: 'flex-end',
                sm: 'center',
                xs: 'center',
              },
              width: {
                xl: '80%',
                lg: '80%',
                md: '80%',
                sm: '100%',
                xs: '100%',
              },
              mt: 1,
            }}
          >
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Box
                sx={{
                  ...socialIcons,
                }}
              >
                <a
                  href=" https://www.facebook.com/bxi.world?mibextid=ZbWKwL"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box>
                    <FacebookIcon sx={{ color: 'fff', background: 'fff' }} />
                  </Box>
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href="https://instagram.com/bxi.world?igshid=MzRlODBiNWFlZA== "
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box>
                    <InstagramIcon sx={{ color: 'fff', background: 'fff' }} />
                  </Box>
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href=" https://www.linkedin.com/company/barter-exchange-of-india/"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box>
                    <LinkedInIcon sx={{ color: 'fff', background: 'fff' }} />
                  </Box>
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href="https://twitter.com/bximarketplace?s=20"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box>
                    <img
                      src={NewTwitter}
                      alt="Image Description"
                      style={{
                        color: '#fff',
                        background: 'transparent',
                        width: '14px',
                        height: 'auto',
                        borderRadius: '50%',
                      }}
                    />
                  </Box>
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href="https://www.youtube.com/@bxiworld"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box>
                    <YouTubeIcon sx={{ color: 'fff', background: 'fff' }} />
                  </Box>
                </a>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xl: 'flex-end',
                lg: 'flex-end',
                md: 'flex-end',
                sm: 'center',
                xs: 'center',
              },
              width: {
                xl: '80%',
                lg: '80%',
                md: '80%',
                sm: '100%',
                xs: '100%',
              },
            }}
          >
            <Typography
              sx={{
                ...textDesign,
                textAlign: {
                  xl: 'right',
                  lg: 'right',
                  md: 'right',
                  sm: 'center',
                  xs: 'center',
                },
                fontSize: {
                  xl: '14px',
                  lg: '14px',
                  md: '14px',
                  sm: '12px',
                  xs: '12px',
                },
                mt: 1,
              }}
            >
              @2023 BXI World LLP. All Rights Reserved.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
const FooterText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: { xl: '18px', lg: '18px', md: '18px', sm: '16px', xs: '16px' },
  lineHeight: '35px',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  color: '#FFFFFF',
};
const socialIcons = {
  width: '32.39px',
  height: '32.39px',
  background: ' #fff',
  borderRadius: '50%',
  color: '#156DB6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: '#000',
    transform: 'ease 1s',
  },
};
const textDesign = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: { xl: '14px', lg: '14px', md: '13px', sm: '13px', xs: '13px' },
  lineHeight: '18px',
  color: '#fff',
};

const legalText = {
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 400,
  color: '#fff',
  textAlign: 'center',
};
