import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import LeftArrow from "../../assets/Images/payment/LeftArrow.png";
import Artboard1 from "../../assets/Artboard1.svg";
import Artboard2 from "../../assets/Artboard2.svg";
import Artboard3 from "../../assets/Artboard3.svg";
import Artboard4 from "../../assets/Artboard4.svg";
import Artboard5 from "../../assets/Artboard5.svg";
import Artboard6 from "../../assets/Artboard6.svg";
import axios from "axios";
import useGetMemberships from "../../Hooks/Plan/membershipData";
import CommaSeparator from "../../components/CommaSeprator";

const PricingDetails = () => {
  let navigate = useNavigate();
  const [select, setSelect] = useState("1");
  const [MembershipFee, setMembershipFee] = useState(false);
  const [Brokerage, setBrokerage] = useState(false);
  const [Subscription, setSubscription] = useState(false);
  const [AMC, setAMC] = useState(false);
  const [BuyInstantly, setBuyInstantly] = useState(false);
  const [Access, setAccess] = useState(false);
  const [Included, setIncluded] = useState(false);
  const [Manager, setManager] = useState(false);
  const [Listing, setListing] = useState(false);
  const [boughtMembership, setBoughtMembership] = useState({});

  const {
    data: memberShipData,
    isLoading: memberShipDataLoading,
    error: memberShipDataError,
  } = useGetMemberships();

  const makepayment = async (props) => {
    if (window.confirm("Are you sure you want to make payment?")) {
      await axios
        .post(`juspay/create-order`, {
          plan: props,
        })
        .then((res) => {
          window.open(res?.data?.payment_links?.web, "_blank");
          setTimeout(() => {
            navigate(`/payment/${res?.data?.order_id}`);
          }, 5000);
        })
        .catch((err) => {});
    }
  };

  async function GetmemberShipData() {
    await axios.get("juspay/get-membership-data").then((res) => {
      setBoughtMembership(res?.data);
    });
  }
  useEffect(() => {
    GetmemberShipData();
  }, []);

  if (memberShipDataLoading) return <div>Loading...</div>;
  if (memberShipDataError) return <div>Error...</div>;

  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <BreadCrumbHeader MainText="Membership Plans" showbreadcrumb={true} />

      <Paper
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          p: 1,
          borderRadius: "20px",
          height: "auto",
          minHeight: "520px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Box
          component="img"
          src={LeftArrow}
          alt="LeftArrow"
          sx={{
            width: "25px",
            height: "10px",
            px: 2,
            marginRight: "1300px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <Grid
          container
          sx={{
            // display: "flex",
            // justifyContent: "center",
            // width: "80%",
            backgroundColor: "#FFFFFF",
            width: "80%",
            mx: "auto",
            boxShadow: "none",
            borderRadius: "20px",
            height: "auto",
            minHeight: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            py: "50px",
          }}
        >
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                ...paperStyle,
                width: "75%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "left",
                cursor: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  py: 0.5,
                }}
              >
                <Box
                  component={"img"}
                  src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXI_LOGO.png"
                  alt=""
                  width="40px"
                  height="40px"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  height: "auto",
                  py: 1.5,
                }}
              >
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    transform: MembershipFee ? "scale(1.1)" : null,
                    transition: "0.4s",
                    py: 1.6,
                  }}
                  onMouseEnter={() => {
                    setMembershipFee(true);
                  }}
                  onMouseLeave={() => {
                    setMembershipFee(false);
                  }}
                >
                  <img src={Artboard1} alt="" width="24px" height="24px" />
                  Membership Subscription Fee
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    transform: Brokerage ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: 1.2,
                  }}
                  onMouseEnter={() => {
                    setBrokerage(true);
                  }}
                  onMouseLeave={() => {
                    setBrokerage(false);
                  }}
                >
                  <img src={Artboard1} alt="" width="24px" height="24px" />
                  Brokerage
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    transform: Subscription ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: 0.3,
                  }}
                  onMouseEnter={() => {
                    setSubscription(true);
                  }}
                  onMouseLeave={() => {
                    setSubscription(false);
                  }}
                >
                  <img src={Artboard2} alt="" width="24px" height="24px" />
                  Subscription Term
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    transform: AMC ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: 0.2,
                  }}
                  onMouseEnter={() => {
                    setAMC(true);
                  }}
                  onMouseLeave={() => {
                    setAMC(false);
                  }}
                >
                  <img src={Artboard2} alt="" width="24px" height="24px" />
                  AMC
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    transform: Manager ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: -0.3,
                  }}
                  onMouseEnter={() => {
                    setManager(true);
                  }}
                  onMouseLeave={() => {
                    setManager(false);
                  }}
                >
                  <img src={Artboard6} alt="" width="24px" height="24px" />
                  Priority Account Manager
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    transform: Listing ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: -0.3,
                  }}
                  onMouseEnter={() => {
                    setListing(true);
                  }}
                  onMouseLeave={() => {
                    setListing(false);
                  }}
                >
                  <img src={Artboard6} alt="" width="24px" height="24px" />
                  Priority Product Listing
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    transform: BuyInstantly ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: -0.6,
                  }}
                  onMouseEnter={() => {
                    setBuyInstantly(true);
                  }}
                  onMouseLeave={() => {
                    setBuyInstantly(false);
                  }}
                >
                  <img src={Artboard4} alt="" width="24px" height="24px" />
                  Introduction Email To all members
                </Typography>
                <Typography
                  sx={{
                    ...TextStyleInsidePaper,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    transform: Access ? "scale(1.1)" : null,
                    transition: "0.4s",
                    mt: -0.8,
                  }}
                  onMouseEnter={() => {
                    setAccess(true);
                  }}
                  onMouseLeave={() => {
                    setAccess(false);
                  }}
                >
                  <img src={Artboard3} alt="" width="24px" height="24px" />
                  Product Banner on Marketplace for 7 days (*T&C Applied)
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                ...paperStyle,
                backgroundColor: select === "0" ? "#2261A2" : "#F3F6F9",
                boxShadow: "0px 3px 20px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  transform: "scale(1.04)",
                  transition: "0.4s",
                },
              }}
              onClick={() => setSelect("0")}
            >
              <Box sx={card}>
                <Typography
                  sx={{
                    ...preplan,
                    color: select === "0" ? "#FFFFFF" : "#2261A2",
                  }}
                >
                  {memberShipData?.at(0)?.PlanName}
                </Typography>
                <Typography
                  sx={{
                    ...inrtext,
                    width: "90%",
                    borderBottom: "2.5px solid #D9D9D999",
                    color: select === "0" ? "#FFFFFF" : "#2261A2",
                    transform: MembershipFee ? "scale(1.1)" : null,
                    transition: "0.4s",
                  }}
                  onMouseEnter={() => {
                    setMembershipFee(true);
                  }}
                  onMouseLeave={() => {
                    setMembershipFee(false);
                  }}
                >
                  INR &nbsp;
                  <CommaSeparator Price={memberShipData?.at(0)?.PlanPrice} />
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "auto",
                    width: "60%",
                    // bgcolor: "red",
                    py: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: Brokerage ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setBrokerage(true);
                    }}
                    onMouseLeave={() => {
                      setBrokerage(false);
                    }}
                  >
                    {memberShipData?.at(0)?.GST}%
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: Subscription ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setSubscription(true);
                    }}
                    onMouseLeave={() => {
                      setSubscription(false);
                    }}
                  >
                    1 Year
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: AMC ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setAMC(true);
                    }}
                    onMouseLeave={() => {
                      setAMC(false);
                    }}
                  >
                    Free for First Year
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: Manager ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setManager(true);
                    }}
                    onMouseLeave={() => {
                      setManager(false);
                    }}
                  >
                    <CloseIcon
                      sx={{
                        ...checkicon,
                        color: select === "0" ? "#2261A2" : "#FFFFFF",
                        background: select === "0" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: Listing ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setListing(true);
                    }}
                    onMouseLeave={() => {
                      setListing(false);
                    }}
                  >
                    <CloseIcon
                      sx={{
                        ...checkicon,
                        color: select === "0" ? "#2261A2" : "#FFFFFF",
                        background: select === "0" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: BuyInstantly ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setBuyInstantly(true);
                    }}
                    onMouseLeave={() => {
                      setBuyInstantly(false);
                    }}
                  >
                    <CloseIcon
                      sx={{
                        ...checkicon,
                        color: select === "0" ? "#2261A2" : "#FFFFFF",
                        background: select === "0" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "0" ? "#FFFFFF" : "#2261A2",
                      transform: Access ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setAccess(true);
                    }}
                    onMouseLeave={() => {
                      setAccess(false);
                    }}
                  >
                    <CloseIcon
                      sx={{
                        ...checkicon,
                        color: select === "0" ? "#2261A2" : "#FFFFFF",
                        background: select === "0" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                </Box>
                {boughtMembership?.data?.MembershipType === "basic" ? (
                  <Button
                    variant="contained"
                    onClick={() => navigate("/home/membershipfeesinvoice")}
                    sx={{
                      // display: select === "0" ? "block" : "none",
                      width: "145px",
                      height: "37px",
                      borderRadius: "10px",
                      background: "#FFF",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: select === "0" ? "#2261A2" : "#FFFFFF",
                      background: select === "0" ? "#FFFFFF" : "#2261A2",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        color: select === "0" ? "#2261A2" : "#FFFFFF",
                        background: select === "0" ? "#FFFFFF" : "#2261A2",
                      },
                    }}
                  >
                    Billing Details
                  </Button>
                ) : boughtMembership?.data?.MembershipType ===
                  "advance" ? null : (
                  <Button
                    variant="contained"
                    onClick={() => makepayment("basic")}
                    sx={{
                      display: select === "0" ? "block" : "none",
                      width: "145px",
                      height: "37px",
                      borderRadius: "10px",
                      background: "#FFF",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "#2261A2",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        color: "#2261A2",
                        background: "#FFF",
                      },
                    }}
                  >
                    Choose plan
                  </Button>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                ...paperStyle,
                backgroundColor: select === "1" ? "#2261A2" : "#F3F6F9",
                boxShadow: "0px 3px 20px 1px rgba(0, 0, 0, 0.05)",
                "&:hover": {
                  transform: "scale(1.04)",
                  transition: "0.4s",
                },
              }}
              onClick={() => setSelect("1")}
            >
              <Box sx={card}>
                <Typography
                  sx={{
                    ...preplan,
                    color: select === "1" ? "#FFFFFF" : "#2261A2",
                  }}
                >
                  {memberShipData?.at(1)?.PlanName}
                </Typography>
                <Typography
                  sx={{
                    ...inrtext,
                    width: "90%",
                    borderBottom: "2.5px solid #D9D9D999",
                    color: select === "1" ? "#FFFFFF" : "#2261A2",
                    transform: MembershipFee ? "scale(1.1)" : null,
                    transition: "0.4s",
                  }}
                  onMouseEnter={() => {
                    setMembershipFee(true);
                  }}
                  onMouseLeave={() => {
                    setMembershipFee(false);
                  }}
                >
                  INR &nbsp;
                  <CommaSeparator Price={memberShipData?.at(1)?.PlanPrice} />
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "auto",
                    width: "80%",
                    py: 1.8,
                  }}
                >
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: Brokerage ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setBrokerage(true);
                    }}
                    onMouseLeave={() => {
                      setBrokerage(false);
                    }}
                  >
                    {memberShipData?.at(1)?.GST}%
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: Subscription ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setSubscription(true);
                    }}
                    onMouseLeave={() => {
                      setSubscription(false);
                    }}
                  >
                    2 Year
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: AMC ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setAMC(true);
                    }}
                    onMouseLeave={() => {
                      setAMC(false);
                    }}
                  >
                    Free for Two Year
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: Manager ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setManager(true);
                    }}
                    onMouseLeave={() => {
                      setManager(false);
                    }}
                  >
                    <CheckIcon
                      sx={{
                        ...checkicon,
                        color: select === "1" ? "#2261A2" : "#FFFFFF",
                        background: select === "1" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: Listing ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setListing(true);
                    }}
                    onMouseLeave={() => {
                      setListing(false);
                    }}
                  >
                    <CheckIcon
                      sx={{
                        ...checkicon,
                        color: select === "1" ? "#2261A2" : "#FFFFFF",
                        background: select === "1" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: BuyInstantly ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setBuyInstantly(true);
                    }}
                    onMouseLeave={() => {
                      setBuyInstantly(false);
                    }}
                  >
                    <CheckIcon
                      sx={{
                        ...checkicon,
                        color: select === "1" ? "#2261A2" : "#FFFFFF",
                        background: select === "1" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                    {/* Yes, Buy for Value of {memberShipData?.at(1)?.PlanPrice}{" "}
                      Barter Coins */}
                  </Typography>
                  <Typography
                    sx={{
                      ...TextStyleInsidePaper,
                      color: select === "1" ? "#FFFFFF" : "#2261A2",
                      transform: Access ? "scale(1.1)" : null,
                      transition: "0.4s",
                    }}
                    onMouseEnter={() => {
                      setAccess(true);
                    }}
                    onMouseLeave={() => {
                      setAccess(false);
                    }}
                  >
                    <CheckIcon
                      sx={{
                        ...checkicon,
                        color: select === "1" ? "#2261A2" : "#FFFFFF",
                        background: select === "1" ? "#FFFFFF" : "#2261A2",
                      }}
                      fontSize="medium"
                    />
                  </Typography>
                </Box>
                {boughtMembership?.data?.MembershipType === "advance" ? (
                  <Button
                    variant="contained"
                    onClick={() => navigate("/home/membershipfeesinvoice")}
                    sx={{
                      //  display: select === "1" ? "block" : "none",
                      width: "145px",
                      height: "37px",
                      borderRadius: "10px",
                      background: "#FFF",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      //  color: "#2261A2",
                      color: select === "1" ? "#2261A2" : "#FFFFFF",
                      background: select === "1" ? "#FFFFFF" : "#2261A2",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        color: "#2261A2",
                        background: "#FFF",
                      },
                    }}
                  >
                    Billing Details
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => makepayment("platinum")}
                    sx={{
                      display: select === "1" ? "block" : "none",
                      width: "145px",
                      height: "37px",
                      borderRadius: "10px",
                      background: "#FFF",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "#2261A2",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        color: "#2261A2",
                        background: "#FFF",
                      },
                    }}
                  >
                    Choose plan
                  </Button>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      {/* )} */}
    </Paper>
  );
};

export default PricingDetails;

const TextStyleInsidePaper = {
  display: "flex",
  alignItems: "center",
  gap: "1.5rem",
  fontFamily: "Poppins",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "1rem",
    sm: "0.9rem",
    xs: "0.9rem",
  },
  fontWeight: 500,
  color: "#2261A2",
  lineHeight: "1.8",
  cursor: "pointer",
  py: { xl: 1.3, lg: 1.3, md: 1.3, sm: 1, xs: 1 },
};

const paperStyle = {
  width: "70%",
  display: "flex",
  margin: "2rem",
  padding: "2rem",
  height: {
    xl: "50rem",
    lg: "50rem",
    md: "50rem",
    sm: "45rem",
    xs: "45rem",
  },
  cursor: "pointer",
  borderRadius: "14px",
};

const checkicon = {
  color: "#2261A2",
  borderRadius: "32px",
  padding: "2px",
  background: "#E5EAEF",
  fontSize: "18px",
};

const card = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: {
    xl: "0.5rem",
    lg: "0.5rem",
    md: "0.5rem",
    sm: "0.5rem",
    xs: "0.5rem",
  },
};

const inrtext = {
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: {
    xl: "3rem",
    lg: "2.5rem",
    md: "2.5rem",
    sm: "2.2rem",
    xs: "2.2rem",
  },
  lineHeight: {
    xl: "3.8rem",
    lg: "3.8rem",
    md: "3.8rem",
    sm: "3.2rem",
    xs: "3.2rem",
  },
  py: 1.5,
  fontWeight: "700",
  whiteSpace: "nowrap",
};

const preplan = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.8rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  lineHeight: {
    xl: "4.2rem",
    lg: "4.2rem",
    md: "3.8rem",
    sm: "3rem",
    xs: "3rem",
  },
  color: "#FFF",
};
