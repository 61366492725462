import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackToHomeIcon from "../assets/Images/CommonImages/BackToHomeIcon.svg";
import first from "../assets/animation_lo2s0oze 1.svg";
import second from "../assets/animation_lo2s0oze 2.svg";
import three from "../assets/Group 1000003294.svg";
import four from "../assets/Vector.svg";
import five from "../assets/Vector (1).svg";
import "./RouterErrorPageCss.css";
const RouterErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Paper
      elevation={0}
      sx={{ background: "transparent", width: "100%", height: "100px" }}
    >
      <Grid container>
        <img
          src={first}
          style={{
            background: "transparent",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
        <img
          src={second}
          style={{
            background: "transparent",
            position: "absolute",
            right: 0,
            top: 10,
            marginTop: "200px",
          }}
        />

        <Box sx={{ width: "100%", mx: "auto", mt: 19 }}>
          <img
            src={four}
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
            }}
          />

          <img
            src={five}
            style={{
              marginTop: "350px",
              left: 0,
              bottom: 0,
              position: "absolute",
            }}
          />

          <img
            src={three}
            style={{
              marginLeft: "40%",
            }}
          />

          <Typography
            sx={{
              color: "#226192",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: "40px",
              textAlign: "center",
            }}
          >
            404! Page Not Found
          </Typography>

          <Typography
            sx={{
              color: "#272523B2",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Don’t worry, we will fix it.
          </Typography>

          <Typography
            sx={{
              position: "relative",
              color: "#272523B2",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            We are working to build something exiting! please try again after
            sometime.
          </Typography>

          <Button
            variant="contained"
            sx={{ ...btn, mx: "auto" }}
            align="left"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Box component="img" src={BackToHomeIcon} />
            Back to home
          </Button>
        </Box>
      </Grid>
    </Paper>
  );
};

export default RouterErrorPage;

const btn = {
  mt: 5,
  display: "flex",
  gap: "5px",
  width: 200,
  height: 45,
  borderRadius: 1.5,
  background: "#226192",
  color: "#fff",
  fontFamily: "poppins",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    background: "#226192",
  },
};
