import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const ManufacturingAndExpiry = (props) => {
  return (
    <React.Fragment>
      {props?.data?.listperiod === undefined ||
      props?.data?.listperiod === null ||
      props?.data?.listperiod === "undefined" ||
      props?.data?.listperiod === "" ? null : (
        <Box
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          <Typography
            sx={{
              ...product,
              color: "#156DB6",
              fontSize: "18px",
            }}
          >
            Listed This Product For
          </Typography>

          <Typography
            sx={{
              ...packHead,
              fontWeight: 500,
              fontSize: "14px",
              color: "#6B7A99",
            }}
          >
            {props?.data?.listperiod}&nbsp; Days
          </Typography>
        </Box>
      )}
      {props?.data?.ManufacturingDate ? (
        <Grid
          container
          sx={{
            display: "flex",
            width: "86%",
          }}
          mt={2}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  ...available,
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "27px",
                  color: "#156DB6",
                }}
              >
                Manufacturing Date
              </Typography>
              <Typography
                sx={{
                  ...available,
                  lineHeight: "20px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "grey",
                  width: "100%",
                  mt: 1,
                }}
              >
                {new Date(props?.data?.ManufacturingDate).toLocaleDateString(
                  "en-GB",
                  {
                    timeZone: "UTC",
                  }
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  ...available,
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#156DB6",
                }}
              >
                Expiry Date{" "}
              </Typography>
              <Typography
                sx={{
                  ...available,
                  lineHeight: "20px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "grey",
                  mt: 1,
                }}
              >
                {props?.data?.ExpiryDate ? (
                  <>
                    {new Date(props?.data?.ExpiryDate).toLocaleDateString(
                      "en-GB",
                      {
                        timeZone: "UTC",
                      }
                    )}
                  </>
                ) : (
                  "Not Given"
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : props?.data?.ManufacturingData ? (
        <Grid
          container
          sx={{
            display: "flex",
            width: "50%",
          }}
          mt={2}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  ...available,
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "27px",
                  color: "#156DB6",
                }}
              >
                Manufacturing Date
              </Typography>
              <Typography
                sx={{
                  ...available,
                  lineHeight: "20px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "grey",
                  width: "100%",
                  mt: 1,
                }}
              >
                {new Date(props?.data?.ManufacturingData).toLocaleDateString(
                  "en-GB",
                  {
                    timeZone: "UTC",
                  }
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            xl={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  ...available,
                  width: "100%",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#156DB6",
                }}
              >
                Expiry Date{" "}
              </Typography>
              <Typography
                sx={{
                  ...available,
                  lineHeight: "20px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "grey",
                  mt: 1,
                }}
              >
                {props?.data?.ExpiryDate ? (
                  <>
                    {new Date(props?.data?.ExpiryDate).toLocaleDateString(
                      "en-GB",
                      {
                        timeZone: "UTC",
                      }
                    )}
                  </>
                ) : (
                  "Not Given"
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default ManufacturingAndExpiry;

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "1.6rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#6B7A99",
};

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#ADB8CC",
};
