import { Box, Typography } from "@mui/material";
import React from "react";

const InstructionsToUseProduct = (props) => {
  return (
    <React.Fragment>
      {props?.ProductData?.ProductTechInfo?.InstructionsToUseProduct ? (
        <Box sx={{ mt: 2.2 }}>
          <Typography
            sx={{
              ...available,
              fontSize: "1.7rem",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              color: "#156DB6",
            }}
          >
            Instructions to use Product :{" "}
          </Typography>
          <Typography
            sx={{
              ...listText,
              mt: 0.5,
              fontSize: "16px",
            }}
          >
            {props?.ProductData?.ProductTechInfo?.InstructionsToUseProduct}
          </Typography>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default InstructionsToUseProduct;

const listText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.6rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  // color: "#6B7A99",
  color: "grey",
};

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "1.6rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};
