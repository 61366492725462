export const ALL_WISHLIST_REQUEST = "ALL_WISHLIST_REQUEST";
export const ALL_WISHLIST_SUCCESS = "ALL_WISHLIST_SUCCESS";
export const ALL_WISHLIST_FAIL = "ALL_WISHLIST_FAIL";
export const ADD_WISHLIST_FAIL = "ADD_WISHLIST_FAIL";
export const ADD_WISHLIST_REQUEST = "ADD_WISHLIST_REQUEST";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const REMOVE_WISHLIST_FAIL = "REMOVE_WISHLIST_FAIL";
export const REMOVE_WISHLIST_REQUEST = "REMOVE_WISHLIST_REQUEST";
export const REMOVE_WISHLIST_SUCCESS = "REMOVE_WISHLIST_SUCCESS";




export const CLEAR_ERRORS = "CLEAR_ERRORS";