import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const Legal_Policies = () => {
  const contents = [
    "Definitions",
    "Acceptance of Terms",
    "Description of Services of BXI",
    "Creating an account",
    "Auto KYC check on the Marketplace",
    "Type of Memberships available",
    "Payment Terms",
    "Listing conditions",
    "Terms and conditions that members may be required to comply with while listing their products/services",
    "BXI Commission",
    "Transaction through coin/s",
    "Status of transaction through color indicator",
    "What Marketplace offers",
    "Utilization of Barter Coins for Credit Line Recovery",
    "Scrutiny and Rejection policy",
    "Payment flow at Marketplace",
    "Selling Journey",
    "Buying Journey",
    "Goods and Service Tax (GST)",
    "Shipping Policy",
    "Changes to the terms of Use",
    "Ownership of Data on Marketplace",
    "Scope of Service",
    "Member Responsibilities",
    "Return/Refund Policy",
    "Voucher Policy",
    "Feedback Policies",
    "Tri-Party Chatroom",
    "Confidentiality",
    "Intellectual Property Rights",
    "Proprietary Rights",
    "Disclaimer of Warranties and Liabilities",
    "Indemnification",
    "Limitations on Liability",
    "General Provisions",
    "Other businesses",
    "Termination",
    "Effects of Termination",
    "Contracting and Notices",
    "Arbitration",
    "Governing Laws",
  ];
  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          // mx: "auto",
          my: 2,
        }}
      >
        <Typography sx={termstext}>
          This is a legal Agreement (hereinafter referred to as “Terms of
          Use/Terms”) between you i.e. the member company (hereinafter referred
          to as the “Member” or “You”) accessing and registered on the
          Marketplace (as defined hereinafter), and BXI World LLP, (BXI) is a
          Limited Liability Partnership having LLP Identification Number is
          (LLPIN) AAV-3580 and having its Registered Address as Wing C, fantasy
          Land, Cts No. 1, 1407, J.V. Link Road, Opp. Majas Deport, Mumbai -
          400060 and office address at Sahar Plaza, BXI (Barter Exchange of
          India) 501-5th Floor, Meadows Tower, Complex, Sir Mathuradas Vasantji
          Road, Andheri East, Mumbai, Maharashtra 400059, or any of its
          subsidiaries, hereinafter referred to as "BXI", "We", "Our" or "Us",
          which term shall include its affiliates, permitted assigns and
          successors.
        </Typography>
        <Typography sx={termstext}>
          BXI operates a website https://bxiworld.com (hereinafter referred to
          as the “Marketplace”).
        </Typography>
        <Typography sx={termstext}>
          Please read the Terms of Use carefully before using or registering on
          the Marketplace or accessing any material, information, or Services
          through BXI. If you use the Services offered by BXI or visit our
          Marketplace, or any other inbound or outbound linked pages, Content
          and any other services offered by BXI in connection with any of the
          above, you give your absolute, irrevocable, and unconditional consent
          to abide by this Terms of Use and be legally bound by the same.
          Further, you are hereby acknowledging and consenting to the collection
          and use of information, if any, as described in these Terms of Use.
          You may not use the Services if you do not accept the Terms of Use.
        </Typography>
        <Typography sx={termstext}>
          You further represent that you are not barred from receiving Services
          under any and all Applicable laws. BXI reserves the right to refuse
          access to the Marketplace or Services to new members or to terminate
          access granted to existing members at any time.
        </Typography>
        <Typography sx={termstext}>
          You may not access the Services if you are a direct competitor of BXI
          or its affiliates, except with prior written consent. In addition, you
          may NOT access the Services for the purpose of monitoring BXI’s
          availability, performance or functionality, or for any other
          benchmarking or competitive purpose.
        </Typography>
        <Typography variant="h4" gutterBottom>
          TABLE OF CONTENTS
        </Typography>
        <List>
          {contents.map((content, index) => (
            <ListItem key={index}>
              <ListItemText
                primaryTypographyProps={{ fontSize: "14px", fontWeight: 500 }}
                sx={{
                  fontFamily: "Poppins",
                  color: "#7D8BA6",
                }}
                primary={`${index + 1}. ${content}`}
              />
            </ListItem>
          ))}
        </List>
        <Typography sx={termstext}>1. Definitions</Typography>
        <Typography sx={termstext}>
          “Applicable Law”: shall mean any applicable statute, law, regulation,
          ordinance, rule, judgment, rule of law, decree, clearance, approval,
          directive, guideline, policy, requirement, or other governmental
          restriction or any similar form of decision, or determination, or any
          interpretation or administration of any of the foregoing by any
          statutory or regulatory authority whether in effect in India or such
          other jurisdiction, as may be applicable to the relevant Party, and in
          each case, as amended from time to time. “Barter Transactions”: shall
          mean barter transactions between the Members. Barter Transactions does
          not involve cash settlement, it is a transaction of Products /
          Services offered by one Member in exchange for Products / Services
          offered by another Member. “BXI risk management team”: shall mean a
          team of internal members appointed by BXI for taking decisions with
          respect to the approval and allocation of Credit Line to the Member/s.
          “Buyer member”: means a Member whose Products and/or Services are
          listed on the Marketplace and buys or agrees to buy or avails or
          agrees to avail of Products and/or Services from the Seller member via
          Barter Coins issued by BXI. “Seller member”: means a Member whose
          Products and/or Services are listed on the Marketplace and sells or
          agrees to sell or provide or agrees to provide Products and/or
          Services to the Buyer member in exchange for Barter Coins issued by
          BXI. “Content”: shall mean the posts/uploads/pictures/videos/product
          description/product information etc. uploaded by the Members on the
          Marketplace and subject to scrutiny by BXI, the same shall be live on
          the Marketplace. “Credit line”: shall mean a Credit, given to a Member
          against its Products and/or Services with BXI. “Escrow Account and
          Payment Gateway”: Escrow is a Financial Wallet created with the
          purpose of securing the interest of the Buyer member and Seller member
          to indulge in Barter Exchange trade and transact hassle-free and get
          the payment due diligence done in time and properly. Payment Gateway
          is a technology or service that allows secure online transactions,
          specifically the authorization and processing of payments made by the
          Members of Marketplace. “Member” “You” / “Your”: means any Private
          Limited Company, Public Company, or Limited Liability Partnership
          (LLP) that creates an account with BXI and is authorized to list its
          Products / Services on the Marketplace and buy and sell Products /
          Services on the Marketplace. “Marketplace”: shall mean any website or
          portion of a website or mobile application owned and/or operated by
          the BXI. To use the Marketplace, the Member must create an account on
          the Marketplace. “Products and/or Services”: shall mean the Products
          and/or Services listed on the Marketplace by the respective Member in
          accordance with the terms and conditions contained in this Agreement.
          “Reasonable Security Practices and Procedures”: shall have the meaning
          assigned to it in the Information Technology Act. “Sensitive Personal
          Data and Information or “SPDI”: shall have the same meaning as the
          term “SPDI” in Rule 3 of the SPDI Rules. “Sensitive personal data or
          information of a person means such personal information which consists
          of information relating to:— (i)password; (ii) financial information
          such as Bank account or credit card or debit card or other payment
          instrument details; (iii) physical, physiological and mental health
          condition; (iv) sexual orientation; (v) medical records and history;
          (vi) Biometric information;(vii) any detail relating to the above
          clauses as provided to body corporate for providing service; and
          (viii) any of the information received under above clauses by body
          corporate for processing, stored or processed under lawful contract or
          otherwise: provided that, any information that is freely available or
          accessible in the public domain or furnished under the Right to
          Information Act, 2005 or any other law for the time being in force
          shall not be regarded as sensitive personal data or information for
          the purposes of these rules.” “Services of BXI” or Transaction(s):
          shall mean and include Barter transaction of Products and or Services
          between the Members registered on the Marketplace and such other
          services as may be provided by BXI on the Marketplace from time to
          time. “SPDI Rules”: shall mean the Information Technology (Reasonable
          Security Practices and Procedures and Sensitive Personal Data or
          Information) Rules, 2011. “Terms of Use”/“Agreement”: This document
          and any terms or conditions listed within.
        </Typography>
        <Typography sx={termstext}>2. ACCEPTANCE OF TERMS</Typography>
        <Typography sx={termstext} paragraph>
          2.1. BXI owns and operates a website https://www.bxiworld.com (known
          as “Marketplace”).
        </Typography>
        <Typography sx={termstext} paragraph>
          2.2. Any Member who creates an account with BXI and uses the Services
          of BXI agrees that it has read, understood, and has agreed to be bound
          by this Terms of Use as well as all other policies, conditions and
          guidelines incorporated by reference in this document, including the
          Privacy Policy.
        </Typography>
        <Typography sx={termstext} paragraph>
          2.3. In order to use the Services of BXI or initiate a Transaction on
          the Marketplace, you will have to register on the Marketplace and
          create an account (“Account”). You shall ensure and confirm that the
          Account information provided by you is complete, accurate and up to
          date. If there is any change in the Account information, you shall
          promptly update your Account information on the Marketplace.
        </Typography>
        <Typography sx={termstext} paragraph>
          2.4. You are prohibited from transferring your Account to another
          party. If you become aware of any unauthorized access of your Account
          information or any other breach of security, you shall immediately
          notify BXI on the same day from becoming aware of such unauthorized
          use. You may be held liable for losses incurred by the BXI or any
          other Member or visitor to the Marketplace due to unauthorized or
          authorized use of your Account as a result of your failure in keeping
          your Account information secure and confidential.
        </Typography>
        <Typography sx={termstext} paragraph>
          2.5. You may be permitted to access the Services of BXI only upon
          creating an Account and obtaining a registration on the Marketplace by
          paying a membership fee.
        </Typography>
        <Typography sx={termstext} paragraph>
          2.6. Your ability to continue using the Services is subject to your
          continued registration on the Marketplace.
        </Typography>
        <Typography sx={termstext} paragraph>
          2.7. BXI reserves the right to terminate or suspend your Account if
          any of the Account information provided by you is found to be untrue,
          inaccurate, or incomplete, in addition to any right that BXI may have
          against you at law or in equity, for any misrepresentation or
          information provided by you.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          3. DESCRIPTION OF BXI SERVICES
        </Typography>
        <Typography sx={termstext}>
          To use the Marketplace, the Member must create an account with us. The
          Member must provide accurate and complete information when creating
          the account. The Marketplace facilitates Barter transactions between
          the Members registered. The Members list their Products or Services on
          the Marketplace. The Member is responsible for ensuring that any
          barter transaction entered into is legal, and ethical, and does not
          violate any applicable laws or regulations.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          4. CREATING AN ACCOUNT
        </Typography>
        <Typography sx={termstext}>
          In order to fully use the Services, you must register and create an
          account. Creating your account can be done by providing specific
          details such as Company name, official Email ID, Mobile No. to receive
          OTP, Banking details, GSTIN Number, and PAN Number of the Company.
          Upon submission of the requisite information, the Marketplace will run
          an auto KYC check.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          5. AUTO KYC CHECK ON THE MARKETPLACE:
        </Typography>
        <Typography sx={termstext}>
          After the Auto KYC check the Member has to choose a Membership plan to
          proceed ahead. Further, the Company is registered as a Member of the
          Marketplace.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          6. TYPE OF MEMBERSHIPS AVAILABLE
        </Typography>
        <Typography sx={termstext} paragraph>
          6.1. Advance Membership: In consideration of the Advance membership,
          the Member shall pay INR 25,000 to BXI in exchange for access to the
          Marketplace. Now, the Member is allowed to list its Products and/or
          Services on the Marketplace and earn BXI Coins for the sale of
          Products and Services available on the Marketplace.
        </Typography>
        <Typography sx={termstext} paragraph>
          6.2. Standard Membership: In consideration of the Standard membership,
          the Member shall pay INR 5,000 to BXI in exchange for access to the
          Marketplace. Now, the Member is allowed to list its Products and/or
          Services on the Marketplace and earn BXI Coins for the sale of
          Products and Services available on the Marketplace.
        </Typography>
        <Typography sx={termstext} paragraph>
          6.3. Membership Renewal Fees: BXI Marketplace shall impose an annual
          renewal fee of INR 5,000 for all membership renewals on members of
          Marketplace. This renewal fee applies uniformly to both Standard
          Membership and Advanced Membership tiers. By renewing membership,
          members agree to comply with the stipulations set forth in the policy.
          The renewal fee is due and payable on the anniversary of the member's
          initial enrolment date each year. Members will receive a renewal
          notice 30 days prior to the due date. Payment must be made in full by
          the due date to avoid any interruption in membership benefits. Failure
          to remit the annual renewal fee by the specified due date will result
          in suspension of membership privileges until full payment is received.
          BXI Marketplace reserves the right to amend this policy at its
          discretion.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          7. PAYMENT TERMS
        </Typography>
        <Typography sx={termstext} paragraph>
          7.1 The activation of membership shall be contingent upon the receipt
          of membership fees from the onboarding member. Once the membership
          fees are received, the membership shall be activated within 48 hours.
        </Typography>
        <Typography sx={termstext} paragraph>
          7.2 Escrow Account: An Escrow Account shall be established to securely
          hold funds until the successful delivery of goods and services. BXI
          shall promptly notify Escrowpay, requesting the release of the funds
          upon receiving confirmation from the Buyer regarding the receipt and
          satisfaction with the product. Such notification shall be communicated
          to Escrowpay. Upon receiving the notification from BXI, Escrowpay
          shall release the funds to the respective account within two (2)
          working days from the time of receipt of the notification.
        </Typography>
        <Typography sx={termstext} paragraph>
          7.3 Irrevocability of Payment: Once payment is released upon the
          confirmation from the Buyer regarding the Product, no payment shall be
          reversed from the Seller's account. Therefore, the Buyer shall provide
          confirmation only after thoroughly inspecting and being satisfied with
          the products or services ordered.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          8. LISTING CONDITIONS
        </Typography>
        <Typography sx={termstext} paragraph>
          The Members are permitted to list their Products and/or Services on
          the Marketplace, subject to the terms contained herein. There are
          certain products/services which, due to legal restrictions and BXI
          rules, are not allowed to be listed.
        </Typography>
        <Typography sx={termstext} paragraph>
          The following are prohibited and restricted items:
        </Typography>
        <Typography sx={termstext} paragraph>
          8.1 Adult Items: Nudity as showing any part of male or female
          genitalia, anus, or female breasts where the areola or nipple is
          visible. Modeled clothing that is see-through or very tight and shows
          human genitalia, the anus, or the nipple/areola of female breasts;
          Male erections, even if the model is clothed; People engaged in sexual
          contact or activity.
        </Typography>
        <Typography sx={termstext} paragraph>
          8.2 Illegal substances: Substances that are prohibited under the
          Narcotic Drugs and Psychotropic Substances Act, 1985.
        </Typography>
        <Typography sx={termstext} paragraph>
          8.3 Hazardous substances: A Hazardous substance is any substance that
          poses a threat to human health and the environment. Hazardous
          substances are toxic, corrosive, ignitable, explosive, or chemically
          reactive or chemical products.
        </Typography>
        <Typography sx={termstext} paragraph>
          8.4 Arms and Ammunition: As per Arms Rules you are not allowed to sell
          and post pictures of Double barrel breach loading (DBBL) Weapons,
          Single Barrel Breech Loader (SBBL Weapons), ML Weapons, Revolvers,
          Pistols, and others. If it is carried out, then strict legal actions
          will be commenced against a wrongdoer.
        </Typography>
        <Typography sx={termstext} paragraph>
          8.5 Alcohol Policy: Any type of alcohol is not allowed. Collectible
          alcohol containers are also not allowed to be sold.
        </Typography>
        <Typography sx={termstext} paragraph>
          8.6 Animal Products Policy:
        </Typography>
        <Typography sx={termstext} paragraph>
          The following wildlife products are not allowed:
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>Items made from endangered or threatened species.</li>
            <li>
              Items found in the policy from the Coalition to End Wildlife.
            </li>
            <li>Ivory or bones from ivory-producing animals.</li>
            <li>Items made from bears.</li>
            <li>Items made from marine mammals.</li>
            <li>Items made from protected turtles or tortoises.</li>
            <li>Items made from protected birds and their feathers.</li>
            <li>Items made from domesticated cats or dogs.</li>
            <li>Items made from snake venom.</li>
            <li>Items made from cow and pig.</li>
          </ul>
        </Typography>
        <Typography sx={termstext} paragraph>
          8.7 Artifacts and cave formations policy: Looted or stolen products
          can’t be sold on the Marketplace. Speleothems, stalactites, and
          stalagmites obtained from caves on government land can’t be sold on
          BXI Marketplace. Also, products prohibited and restricted under the
          following Acts:
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>The Indian Arts and Crafts Act</li>
            <li>The Native American Graves Protection and Repatriation Act</li>
            <li>The Federal Cave Protection Act</li>
          </ul>
        </Typography>
        <Typography sx={termstext} paragraph>
          8.8 Other (including but not limited to):
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              Body Parts, including organs - Live, cultured, preserved or from
              cadaver.
            </li>
            <li>
              Bulk marketing tools- email list or products enabling unsolicited
              emails/SMS.
            </li>
            <li>
              Copyright unlocking devices- Mod chips or other products designed
              to circumvent copyright protection
            </li>
            <li>
              Copyright media - unauthorized copies of books, music, movies and
              other licensed or protected materials
            </li>
            <li>
              Gaming/Gambling - lottery tickets, sports bets, enrolment in
              online gambling sites.
            </li>
            <li>
              Government ID or documents which includes take IDs, passports
            </li>
            <li>
              Hacking and Cracking materials which include manuals, how-to
              guides, and equipment enabling illegal access to software servers
              or protected property.
            </li>
            <li>
              Pyrotechnic devices and hazardous materials which includes
              fireworks and other toxic/radioactive materials.
            </li>
            <li>Live animal or animal parts/products</li>
            <li>Data received on the Marketplace shall not be sold.</li>
          </ul>
        </Typography>
        <Typography sx={termstext} paragraph>
          8.9 Or any other Products / Services prohibited under law. Member that
          doesn’t follow BXI Listing Conditions could result in a range of
          actions including for example administratively ending or cancelling a
          listing, hiding, or demoting all listings from search results lowering
          seller ratings, buying, or selling restrictions, and account
          suspension. Also, adequate legal action will be commenced against the
          Member.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          9. TERMS AND CONDITIONS THAT MEMBERS MAY BE REQUIRED TO COMPLY WITH
          WHILE LISTING THEIR PRODUCTS /SERVICES:
        </Typography>
        <Typography sx={termstext} paragraph>
          The following are the terms and conditions that Members may be
          required to comply with while listing their Products and/or Services
          on the Marketplace:
        </Typography>
        <Typography sx={termstext} paragraph>
          9.1 Accurate Listing Information: The Members are responsible for
          providing accurate and up-to-date information about their Products
          and/or Services, including descriptions, images, pricing, and any
          relevant specifications or conditions.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.2 Compliance with Laws and Regulations: The Members must ensure that
          their Products and/or Services comply with all Applicable laws,
          regulations, and industry standards. This includes adherence to any
          product safety, labelling, licensing, or certification requirements.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.3 Ownership and Authorization: The Members must have legal ownership
          or authorization to sell the listed Products and/or Services. They
          should not infringe any intellectual property rights or engage in the
          sale of counterfeit, illegal, or unauthorized products.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.4 Quality and Condition: The Members must ensure that the Products
          and/or Services they offer meet the quality, standards and are
          delivered in the promised condition. Any defects, damages, or
          discrepancies should be clearly disclosed on the Marketplace. In the
          case of consumables, the Members must specifically mention the expiry
          date.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.5 Availability and Fulfilment: The Members are expected to have the
          listed Products and/or Services available for sale and fulfil orders
          promptly. Failure to fulfill orders or repeated instances of
          out-of-stock Products / Services may result in penalties or account
          suspension.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.6 Pricing and Payment: The Member should set accurate prices for
          their Products and/or Services and clearly communicate any additional
          charges, such as shipping fees or taxes.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.7 Compliance with Marketplace Policies: The Member must adhere to
          the Marketplace's policies, guidelines, and terms of service. This
          includes rules regarding prohibited items, prohibited activities,
          Content restrictions, and any specific guidelines for product listings
          or promotions.
        </Typography>
        <Typography sx={termstext} paragraph>
          9.8 Indemnification: The Member may be required to indemnify and hold
          the Marketplace harmless from any claims, damages, or liabilities
          arising from its Products or Services, including any infringement of
          intellectual property rights, negligence, or non-compliance with
          Applicable laws.
        </Typography>
        <Typography sx={termstext} paragraph>
          In the event that a listed Product and/or Service reaches its
          expiration date, BXI reserves the right to remove or delete the said
          Product and/or Services from the Marketplace. By listing their
          Products or Services on the Marketplace, the Member understands and
          accepts that BXI has the discretion to remove expired listings and
          that the Member will not hold BXI accountable for any losses, damages,
          or consequences arising from the expiration of Products and/or
          Services listed on the Marketplace.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          10. BXI COMMISSION
        </Typography>
        <Typography sx={termstext} paragraph>
          BXI charges a commission known as BXI Commission for the completion of
          a successful transaction, which may vary depending on the type of
          membership registered by the Member. Any fees will be clearly stated
          prior to entering into a transaction. The BXI Commission charged are
          as follows:
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              Advantage membership: 8% as BXI Commission shall be applicable on
              the total value of purchases made (exclusive of GST)
            </li>
            <li>
              Standard membership: 12% BXI Commission shall be applicable on
              each successful transaction. (exclusive of GST)
            </li>
          </ul>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          11. TRANSACTIONS THROUGH BARTER COIN/S
        </Typography>
        <Typography sx={termstext} paragraph>
          BXI functions as a third-party record keeper for Barter exchanges. BXI
          Members engage in the buying and selling of Products and Services
          using Barter Coins (referred to as "Coins" hereafter).
        </Typography>
        <Typography sx={termstext} paragraph>
          11.1 The Coin/s represents an accounting unit and medium of exchange
          used for recording the value of Products and/or Services transacted
          through the BXI Marketplace, as well as the entitlement to purchase
          Products and Services via the BXI Marketplace.
        </Typography>
        <Typography sx={termstext} paragraph>
          11.2 Each Coin carries an equivalent value of One Indian Rupee (INR).
        </Typography>
        <Typography sx={termstext} paragraph>
          11.3 The Coins are restricted to use solely for the purposes specified
          in the terms herein and must not be construed as legal tender,
          securities, or commodities. They cannot be redeemed for Indian Rupees
          or any other form of cash or currency.
        </Typography>
        <Typography sx={termstext} paragraph>
          11.4 The Coins are non-transferable between Members without a
          transaction.
        </Typography>
        <Typography sx={termstext} paragraph>
          11.5 The Coins possess indefinite validity and remain stored in the
          Member's wallet as long as their account on the Marketplace remains
          active.
        </Typography>
        <Typography sx={termstext} paragraph>
          11.6 BXI retains the rights and authority to regulate and control the
          Coins within the BXI system.
        </Typography>
        <Typography sx={termstext} paragraph>
          11.7 Members of the BXI Marketplace are prohibited from exiting the
          platform with either a negative or positive balance of Barter Coins.
          Members acknowledge that exiting the platform with a negative balance
          of Barter Coins will result in BXI or its affiliates taking
          disciplinary actions to recover the outstanding amount.
        </Typography>
        <Typography sx={termstext} paragraph>
          11.8 In the event that a member of the platform is found to have
          engaged in fraudulent activities or wrongdoings against another member
          of the platform, it shall be deemed that the said member's barter
          coins shall be subject to confiscation by BXI (Barter Exchange India).
          BXI reserves the absolute right to confiscate a member's Barter Coins
          based on the following grounds:
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              Fraudulent Activities: Should a member be discovered to have
              partaken in any form of fraudulent activity, including but not
              limited to misrepresentation of goods or services, deceptive
              practices, or provision of false information, BXI may exercise its
              authority to confiscate their Barter Coins.
            </li>
            <li>
              Breach of Platform Policies: BXI may confiscate Barter Coins if a
              member is found to have violated the platform's policies, rules,
              or guidelines governing the conduct of transactions and
              interactions among members.
            </li>
            <li>
              Harmful Actions: In the event that a member is found to have
              committed actions that cause harm to other members or the
              integrity of the platform, BXI shall be entitled to exercise its
              right to confiscate their Barter Coins.
            </li>
            <li>
              Unlawful Activities: If a member is found to be involved in any
              unlawful activities while utilizing the platform, BXI may take
              appropriate actions, including the confiscation of their Barter
              Coins, and may also report such activities to the relevant
              authorities.
            </li>
            <li>
              Breach of Agreement: BXI retains the power to confiscate Barter
              Coins if a member breaches any agreements or contracts made on the
              platform.
            </li>
          </ul>
        </Typography>
        <Typography sx={termstext} paragraph>
          It is imperative to note that any decision to confiscate Barter Coins
          will be made by BXI after conducting a comprehensive investigation
          into the matter. The member in question will be accorded the
          opportunity to present their defence, and the final decision shall be
          at the sole discretion of BXI, based on the evidence and merits of the
          case.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          12. STATUS OF TRANSACTION THROUGH COLOR INDICATOR
        </Typography>
        <Typography sx={termstext} paragraph>
          The status of every transaction will be indicated through color
          indicators which would ease the understanding. The following
          indicators are seen on the Member’s wallet Dashboard.
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              <strong>Yellow Color Indicator:</strong>
              This indicates the transaction is in progress, with barter coins
              currently in transfer. Upon Buyer’s confirmation—after receiving
              the products, activating vouchers, or completing the media
              campaign—the Seller will then need to remit the BXI commission,
              including GST and TDS, to BXI. Following this, the coins will be
              credited to the Seller's wallet. Until then it will be in transit
              showing a Yellow Indicator.
            </li>
            <li>
              <strong>Green Indicator:</strong> The indicator would turn green
              once the order is successful and the barter coins that were in
              transit have been successfully credited to the seller/member. It
              would also indicate the current coins in the member’s dashboard.
              Further, if the net balance is positive then the Indicator will
              turn Green in color.
            </li>
            <li>
              <strong>Red Indicator:</strong> The indicator would turn red when
              BXI allots Barter Coins via Credit Line. Further, if the net
              balance is negative then the Indicator will turn Red in color.
            </li>
          </ul>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          13. WHAT MARKETPLACE OFFERS
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              <strong>Earn and burn model:</strong>Upon successful selection of
              a membership plan, a Member is registered. The Members are
              provided with the opportunity to list their Products and/or
              Services on the Marketplace. Once the listing is submitted, the
              Product and/or Service will undergo a 48 (forty-eight) hour
              scrutiny process before becoming live on the Marketplace. Once a
              Member’s Product and/or Service is live, it becomes visible to all
              the other Members of the Marketplace. When another Member buys a
              listed Product or Service from the Marketplace and successful
              delivery is made to such Member, the Member who listed the
              Products / Services earns Coins as compensation for the sale of
              the Products and/or Services. The earned Coins are reflected on
              the Member’s dashboard, and they can utilize these Coins to
              procure available Products and/or Services offered on the
              Marketplace.
            </li>
            <li>
              <strong>Credit line for Coins:</strong> A Credit line is granted
              to a Member on the Products and Services they upload for sale on
              the Marketplace. The Member holds the discretion to select
              specific Products or Services and offer them as collateral to BXI
              in order to secure a Credit Line in the form of Barter Coins.
            </li>
            <li>
              Once the Products and Services are pledged to BXI for the Credit
              Line, they cannot be withdrawn or delisted from the Marketplace.
              It is obligatory for the Member to ensure that the pledged
              Products and Services remain listed for sale on the Marketplace
              and are delivered in optimal condition and at the agreed price, in
              accordance with the clauses of the Credit Line Agreement. The
              approval of a credit line to a Member will be determined based on
              the terms, policies, and criteria established by the BXI risk
              management team. The decision of the BXI risk management team
              regarding the credit line is conclusive and binding.
            </li>
            <li>
              If a Member decides to terminate their association with the
              Marketplace and possess a positive Coin balance on the Dashboard,
              they are prohibited from converting such balance into INR. The
              Member must exclusively utilize the Coins within the Marketplace
              or exit while retaining the remaining balance, as deemed
              appropriate by the departing Member.
            </li>
            <li>
              No Member shall be permitted to terminate their membership on the
              BXI Marketplace until they have fulfilled their obligations under
              the Credit Line Agreement with BXI. In the event that a member
              fails to deliver the products and services to the purchasing
              member in accordance with the agreed terms stated in the Credit
              Line and Conditional Credit Line Agreement. The member’s liability
              shall be enforceable regardless of any circumstances that may have
              hindered the product and service delivery, subject to any force
              majeure provisions outlined in the Credit Line Agreement.
            </li>
          </ul>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          WHAT IS CONDITIONAL CREDIT LINE?
        </Typography>
        <Typography sx={termstext} paragraph>
          Conditional Credit Line: This Conditional Credit Policy ("Policy") is
          established between Barter Exchange of India and Buyer. The Buyer
          seeks to acquire a “specific product” from a Seller on the Platform.
          Upon evaluating the Buyer's creditworthiness, BXI offers an automated
          coupon code ("Coupon Code") specifically for the Product. The Coupon
          Code represents conditional credit, solely applicable to the specified
          Product. The issuance of the Coupon Code by BXI includes details
          regarding the amount of conditional credit and the Product to which it
          applies. Upon initiation of a Purchase Order ("PO") by the Buyer for
          the Product on the Platform, the Buyer applies the Coupon Code during
          checkout, thereby agreeing to pledge a product ("Ready Stock") as
          specified by the Platform, thereby accepting the terms of this Policy.
        </Typography>
        <Typography sx={termstext} paragraph>
          The Seller retains the right to accept or reject the PO, and if
          rejected, the Coupon Code remains inactive. Upon acceptance of the PO,
          the Seller issues an invoice ("PI") to the Buyer, who proceeds to the
          payment page. At this stage, the Coupon Code automatically deducts
          from the barter coin cost of the Product. The Buyer settles the
          remaining balance, inclusive of taxes, commission fees, and additional
          charges, to the Seller via the designated payment gateway. Upon
          Buyer’s confirmation—after receiving the products, activating
          vouchers, or completing the media campaign—the Seller will then need
          to remit the BXI commission, including GST and TDS, to BXI. Following
          this, the coins will be credited to the Seller's wallet. The
          conditional credit represented by the Coupon Code is recovered by the
          Platform from all future sales earned by the Buyer until the credit
          amount is fully recovered. Such credit recovery may apply to any
          product sold by the Buyer on the Platform, not limited to the sale of
          the Ready Stock Product.
        </Typography>

        {/* New Sections 14 to 19 */}
        <Typography sx={termstext} variant="h5" gutterBottom>
          14.UTILIZATION OF BARTER COINS FOR CREDIT LINE RECOVERY:
        </Typography>
        <Typography sx={termstext} paragraph>
          Barter Coins may be allocated to members in the form of a Credit Line
          against a specific product, in the event that the member receives
          barter coins due to the sale of another product then, BXI will recover
          the outstanding credit line first and balance barter coins would be
          further credited to respective member barter coin wallet. Our Credit
          Line Recovery Policy is designed to ensure a fair and transparent
          process for both BXI and its members. BXI will provide clear and
          timely notification to members when barter coins are allocated or
          utilized for Credit Line Recovery. Under these policies, Members
          provide whole consent to BXI for the deduction of Barter Coins towards
          the recovery of the Credit Line.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          15. SCRUTINY AND REJECTION POLICY:
        </Typography>
        <Typography sx={termstext} paragraph>
          The listed Products and/or Services shall undergo scrutiny within 48
          (forty-eight) hours i.e., 2 working days, conducted by designated
          representatives of BXI. Upon uploading of Products and/or Services by
          a Seller member, a thorough examination shall be performed to
          ascertain their compliance with the established listing policy. In the
          event that the listed products and services are determined to be
          non-compliant with the aforementioned policy, they shall be rejected
          and not made available for public viewing or purchase on the
          Marketplace. Further, rejection will be formally notified to the
          Seller member by BXI.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          16. PAYMENT FLOW AT MARKETPLACE:
        </Typography>
        <Typography sx={termstext} paragraph>
          The BXI Marketplace has established affiliations with the following
          entities to ease the process of Payment:
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              <strong>Member Onboarding (Membership Fees):</strong> The payment
              for membership fees will be processed through Payments will be
              processed via PineLabs or Easebuzz through Juspay. In the
              subsequent working day, denoted as T+1(subject to change), these
              collected membership fees will be further transferred to the BXI
              Bank Account.
            </li>
            <li>
              <strong>
                Escrowpay (Purchase and Sale Deal) Transactions in INR:
              </strong>{" "}
              Payments for transactions involving the purchase and sale of goods
              or services that include GST, BXI Commission, and any other
              cash-related amounts (e.g., technical fees if applicable). The
              funds held in the escrow account are distributed as follows:
              <ul>
                <li>
                  <strong>GST Amount:</strong> The amount of Goods and Services
                  Tax (GST) applicable on the purchase and sale of transactions
                  will be transferred from the Escrow Account to the seller.
                </li>
                <li>
                  <strong>BXI Commission:</strong> The fees associated with the
                  successful completion of transactions will be settled via the
                  Escrow Account and subsequently transferred to the seller.
                </li>
                <li>
                  <strong>Escrowpay:</strong> will retain the funds until the
                  buyer confirms the successful delivery of goods and services
                  and further BXI gives the approval to release the funds to the
                  respective bank account.
                </li>
                <li>
                  For the aforementioned procedure, an Escrow Account
                  Administration Agreement and Application (EAAA) will be
                  executed. The member acknowledges and agrees that Escrowpay
                  acts solely as a facilitator, operating based on the
                  instructions and authorizations received from the member. The
                  member is aware that Escrowpay, established for the
                  facilitation of escrow services, is provided by Trustmore
                  Technologies Private Limited in collaboration with a leading
                  bank. Members are encouraged to review and understand these
                  terms before engaging in any transactions on the Marketplace.
                </li>
                <li>
                  <strong>Transactional Rule Policy :</strong> Under this policy
                  Product GST, Delivery charges, Packing charges, Tax Deducted
                  at Source (TDS), GST of BXI Commission and BXI Commission and
                  any charges collected in the form of INR will get credited to
                  sellers registered bank account. Further, shall be obligated
                  to repay Tax Deducted at Source (TDS), BXI Commission GST and
                  BXI Commission to BXI Bank Account via payment gateway
                  provided in the commission section of Seller Hub. Furthermore,
                  upon failure of above transfer to the BXI Account, the Product
                  value in the form of Barter Coins shall be withheld by BXI.
                  Furtherance, upon successful payment by Seller to the BXI’s
                  Bank account the Product value in the form of Barter Coins
                  will be released and credited to Seller’s Wallet account.
                </li>
              </ul>
            </li>
          </ul>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          17. SELLING JOURNEY
        </Typography>
        <Typography sx={termstext} component="div">
          <ul>
            <li>
              The member must choose whether they want to sell a Product or a
              Voucher. If the member selects Product, they must follow the steps
              outlined below to upload the product: Choose between Single Upload
              or Bulk Upload options for the product.
            </li>
            <li>
              <strong>SINGLE UPLOAD</strong>
              <ul>
                <li>Step No. 1: Member must choose Single Upload </li>
                <li>
                  Step No. 2: Provide general information about the product
                </li>
                <li>
                  Step No. 3: Provide technical information about the product
                </li>
                <li>
                  Step No. 4: Fill in Go Live information, which includes
                  uploading 6 photos and optionally, 1 video showcasing the
                  product.
                </li>
              </ul>
            </li>
            <li>
              <strong>BULK UPLOAD </strong>
              <ul>
                <li>
                  Step No. 1: Download the format template from the Bulk Upload
                  page
                </li>
                <li>
                  Step No. 2: Fill the pertinent information concerning your
                  product by utilizing the available dropdown options.
                </li>
                <li>
                  Step No. 3: Fill in the general information, technical
                  information and Go Live information sections regarding the
                  product, which involve uploading 6 photos and optionally, 1
                  video showcasing the particular product.
                </li>
              </ul>
            </li>
            <li>
              <strong>VOUCHER UPLOAD</strong>
              <ul>
                <li>
                  The Member must choose whether they want to sell Offer
                  Specific, Value Voucher or Gift Card Voucher.
                </li>
                <li>
                  Offer Specific Voucher: An offer specific voucher is a type of
                  voucher that is specifically tied to a particular product or
                  service, and it is designed to provide a value or special
                  benefit for a specific product or service only.
                </li>
                <li>
                  Value Voucher: A value voucher is a type of voucher that
                  represents a specific monetary value that can be used as a
                  form of payment towards purchases. It acts as a prepaid
                  instrument with a predetermined value that can be redeemed for
                  goods or services.
                </li>
                <li>
                  Gift Card: Gift card is loaded with a specific amount of
                  funds, which can be spent over time until the balance is
                  exhausted. Gift cards are often purchased as gifts or rewards
                  and can be used by the cardholder to make purchases of their
                  choice. They are available in physical card form or as digital
                  codes that can be redeemed online or at a physical point of
                  sale.
                </li>
                <li>Step No. 1: Click on the Voucher section </li>
                <li>
                  Step No. 2: Select the type of Voucher you are offering i.e.,
                  Offer Specific Voucher, Value Voucher, Gift Card
                </li>
                <li>
                  Step No. 3: Provide General information about the product
                  Voucher
                </li>
                <li>
                  Step No. 4: Provide Product information about the product
                  Voucher
                </li>
                <li>
                  Step No. 5: Fill in technical information i.e., Inclusion,
                  Exclusion and Terms & Conditions about the product Voucher
                </li>
                <li>
                  Step No. 6: At the Go Live page upload Photos of the product
                  Voucher and if you don’t have photos of the Voucher then you
                  can create your own Voucher with the help of the Template
                  provided on the same page. Step No. 7: Add key features and
                  upload the product Voucher.
                </li>
                <li>
                  (Note: Provision has been provided to upload PDF of multiple
                  locations/excel. Also, a similar provision is made for size
                  charts and manuals. The above instructions are provided for
                  clarity and ease of understanding).
                </li>
              </ul>
            </li>
          </ul>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          18. BUYING JOURNEY
        </Typography>
        <Typography sx={termstext} component="div">
          <ol>
            <li>Choose a product or voucher of your preference. </li>
            <li>
              Add the selected Product or Voucher to your Cart and submit a
              purchase order.
            </li>
            <li>
              The seller may accept the purchase order and issue a proforma
              invoice. At this stage, you will have two options: the seller may
              provide the logistics services.
            </li>
            <li>
              Upon the Buyer ’s acceptance of the proforma invoice, the Buyer
              must make payment for the product cost via Barter Coins and
              Logistics Charges, BXI Commission and GST in INR.
            </li>
            <li>
              The payments made will be collected and held in an escrow account
              until the successful completion of the delivery.
            </li>
            <li>
              Upon receiving confirmation, the seller will prepare the Goods or
              Services for dispatch.
            </li>
            <li>
              The Goods or Services will be delivered, and the payments will be
              settled to the respective accounts by the escrow account.
            </li>
          </ol>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          19.GOODS, SERVICE TAX (GST) AND TDS:
        </Typography>
        <Typography sx={termstext} paragraph>
          The Member shall abide by applicable Goods and Service Tax, Company,
          and Local laws or regulations appropriate to any trade, and BXI shall
          not be responsible for any failure on the part of the Member to comply
          with the same. The seller shall charge GST and any other taxes as
          applicable and collect and record these during the transaction at the
          time of sale from the buyer. Under no circumstances will BXI be
          responsible for paying GST or any other taxes on behalf of any Member.
          Members are advised that transactions involving Coins are generally
          treated as taxable events for GSTIN tax purposes. The declaration and
          reporting of GST taxes resulting from trade transactions rests solely
          with the Member. BXI is required by law to obtain the correct taxpayer
          identification number in context to GST for the Member (who is
          required by law to provide GST number) and to report all trade sales
          of Members. Also, Tax Deducted at Source (TDS) shall be applicable @
          0.1% rate on gross total of Order Value.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          20. SHIPPING POLICY
        </Typography>
        <Typography sx={termstext} paragraph>
          BXI expressly disclaims any responsibility for shipping facilities.
          Both sellers and buyers are required to independently arrange for the
          shipment of goods or services purchased or sold through the platform.
          Sellers must ensure prompt packaging and delivery of items, while
          buyers are responsible for providing accurate shipping information and
          coordinating receipts. BXI bears no liability for delays, damages, or
          disputes arising from shipping, and users indemnify BXI against any
          related claims.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          21. CHANGES IN THE TERMS OF USE
        </Typography>
        <Typography sx={termstext} paragraph>
          BXI may change any condition in this Terms of Use including other
          policies, conditions, and guidelines governing the Services of BXI; at
          any time, at its sole discretion; and BXI is not required to notify
          you of such changes, whether you are a registered member or not. You
          are requested to review such changes from time to time and regularly
          visit the homepage of the Marketplace to view the most current Terms
          of Use. It shall be your responsibility to check these Terms of Use
          periodically for changes. Your continued use of the Services following
          any change to this Terms of Use and/or the changes effected in the
          Service will constitute as your acceptance of such changes.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          22. OWNERSHIP OF DATA ON MARKETPLACE
        </Typography>
        <Typography sx={termstext} paragraph>
          All data whether collected automatically while you use the Service(s)
          or provided otherwise by you with an intention to use the Service(s),
          including all communication with BXI, including its employees,
          officers and associated third parties WILL BE SOLE PROPERTY OF THE
          BXI. Discontinuation of Services either by you or the BXI, whether
          after use or due to disagreement with any conditions or changes in the
          terms of use; will not result in any change of ownership of data and
          the data thus collected will continue to be the sole property of BXI.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          23. SCOPE OF SERVICE
        </Typography>
        <Typography sx={termstext} paragraph>
          BXI is only a facilitator amongst its Members. BXI is solely intended
          to organize and facilitate the buying and selling of Products and/or
          services between the Members registered on the Marketplace using the
          Barter Coin/s. Any contract between you and any other registered
          user/third-party service provider used by the BXI (“Third Party”) on
          the Marketplace shall be strictly a contract between you and such
          registered user(s) and BXI shall not be a party to the same. The risk
          of any losses or damages incurred by you due to any such contract or
          usage of any information or data provided on the Marketplace shall be
          solely upon you. BXI shall not and is not required to mediate or
          resolve any dispute or disagreement between you and such registered
          user(s) / third party.
          <ul>
            <li>
              BXI does not guarantee the accuracy, completeness, timeliness,
              reliability, suitability or usefulness of any portion of the
              products or Services provided by the Members.
            </li>
            <li>
              BXI will welcome your request for additional services,
              suggestions, and comments; however the implementation or
              acceptance of such requests, suggestions, or comments will be at
              the sole discretion of BXI.
            </li>
            <li>
              BXI or any of its employees, directors, or officers will not be
              responsible for any action you take on the basis of information or
              data provided by BXI, which may result in any litigation, legal
              proceedings, or material damages that may occur to you or to any
              third party.
            </li>
            <li>
              If you have a complaint for any interruption or error in the
              service of BXI, then BXI shall try to remove or cure such
              interruption or error within a reasonable time period.
            </li>
          </ul>
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          24.MEMBER RESPONSIBILITIES
        </Typography>
        <Typography sx={termstext} paragraph>
          The Member hereby represents and warrants that all information that is
          provided by the Member through or in relation to the Services is
          valid, complete, true, and correct on the date of acceptance of the
          Terms and the Member shall update the documents if there is any
          change, so the documents continue to be valid, complete, true, and
          correct throughout the duration of the Member’s use of the
          Marketplace. Further, the Member agrees and acknowledges that BXI does
          not accept any responsibility or liability for any loss or damage the
          Member may suffer or incur if any information, documentation,
          material, or data provided to avail the Services is incorrect,
          incomplete, inaccurate, or misleading, or if the Member fails to
          disclose any material fact. The Member hereby agrees to permit BXI to
          provide all the information shared by the Member to its third-party
          vendors for the purpose of providing effective Services to the Member.
          This will be considered as permission given by the Member in
          accordance with Section 6 (Disclosure of Information) Information
          Technology (Reasonable security practices and procedures and sensitive
          personal data or information) Rules, 2011. The Member shall be solely
          responsible for ensuring compliance with the applicable laws and shall
          be solely liable for any liability that may arise due to a breach of
          its obligations in this regard. By using the Marketplace to avail of
          any Services, the Member agrees that the Member is involved in the
          transmission of sensitive and Personal Information, including but not
          limited to personal details, PAN details, banking details registration
          information and mobile number etc which falls within the ambit of the
          Information Technology (Reasonable Security Practices and Procedures
          and Sensitive Personal Data or Information) Rules, 2011. BXI shall
          take necessary steps to secure and maintain the confidentiality that
          is transmitted in compliance with the Privacy Policy, the Information
          Technology (Reasonable Security Practices and Procedures and Sensitive
          Personal Data or Information) Rules, 2011 and Information Technology
          (Security of Prepaid Payment Instruments) Rules, 2017 and other
          applicable laws. The Member shall use the Services in any manner as
          permitted in this Terms of Use. The Member may not: use the
          Marketplace for any purpose that is prohibited by these Terms of Use
          or other Agreements. The Member is responsible for all of its activity
          in connection with, and use of the Marketplace. Additionally, Member
          shall abide by all applicable local, state, national and international
          laws and regulations and, if Member represents a business, any
          advertising, marketing, privacy, or other self-regulatory code(s)
          applicable to its industry. use the Services to transmit any data or
          send or upload any material that contains viruses, or any other
          harmful programmes or similar computer code designed to adversely
          affect the operation of any mobile, computer software or hardware, or
          any other device; Infringe either directly or indirectly any
          third-party's rights, including but not limited to copyrights,
          patents, trademarks, or trade secrets, right of publicity or other
          right of any other person or entity or violates any law or contractual
          duty or obligations of confidentiality; use the Services in
          furtherance of / to engage in any activity or publish Content which
          may be grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libellous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever; violate Applicable laws in any manner in its use
          of the Marketplace. The Member shall be solely responsible for
          compliance with all the Applicable laws including but not limited to
          the Prevention of Money Laundering Act, 2002 and the rules made
          thereunder. BXI shall not be responsible for any claims or liability
          or losses that may arise due to noncompliance of the applicable
          anti-money laundering laws in India.
        </Typography>
        <Typography sx={termstext} variant="h5" gutterBottom>
          25.RETURN| REFUND POLICY
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>a. RETURN POLICY:</strong>
          <br />
          The member may request a return for Products or Services, member buys
          via Barter coins from the seller Member listed on{" "}
          <a href="http://www.bxiworld.com/">www.bxiworld.com/</a> (hereinafter
          referred to as “the Marketplace”). On the following grounds, the
          Products are returnable if you’ve received them in a condition that is
          physically damaged, has missing parts or accessories, is defective,
          different from the sample received, different from its description on
          the product detail page on the Marketplace etc.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>b. REFUND POLICY:</strong>
          <br />
          If you’ve received Products in a condition that is physically damaged,
          has missing parts or accessories, is defective, different from the
          sample received or different from their description on the product
          detail page on
          <a href="http://www.bxiworld.com/">www.bxiworld.com/</a>the
          Marketplace.
        </Typography>
        <ul>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Return will be proceeded only if:
            <ul>
              <li style={{ ...termstext, fontSize: "14px" }}>
                A tri-party ticket between the Selling member, Buying member and
                BXI is raised by the Buying Member to facilitate the resolution
                of disputes.
              </li>
              <li style={{ ...termstext, fontSize: "14px" }}>
                It is determined that the Product was damaged when a member
                received it.
              </li>
              <li style={{ ...termstext, fontSize: "14px" }}>
                The Product is returned in original condition (with
                brand’s/manufacturer’s box, price tag intact, user manual,
                warranty card and all the accessories therein).
              </li>
            </ul>
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            If a member wishes to return an electronic device that stores any
            personal information, please ensure that you have removed all such
            personal information from the device prior to returning. BXI shall
            not be liable in any manner for any misuse or usage of such
            information.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            However, in the unlikely event of the damaged, defective or wrong
            item delivered to you. We/the seller may contact you to assist or
            ascertain the damage or defect in the Product prior to issuing a
            refund of barter coins/replacement.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            The Products may be eligible for replacement only if the Seller has
            the exact same item in stock or products which are accepted by the
            Buyer or Barter coins will be refunded to the Buyer. (BXI reserves
            the right to provide a final decision that will be binding on the
            parties)
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            In the event the Members are found to misuse the return policy by
            excessively returning, or cancelling or not accepting the orders
            placed, BXI reserves the right to warn and/or suspend and/or block
            and/or terminate such Member’s membership account, as necessary.
            Further, the Buyer Member has to bear the return cost.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            In the event that it is determined that the Seller is at fault for
            the return of Products or Services initiated by the Buyer , the
            burden of the return cost shall be borne by the Seller. Conversely,
            if it is determined that the Buyer is at fault for the return of
            goods the burden of the return cost shall be borne by the Buyer .
          </li>

          <li style={{ ...termstext, fontSize: "14px" }}>
            Member shall commence the following steps before asking for
            Return/Refund:
            <ul>
              <li style={{ ...termstext, fontSize: "14px" }}>
                The Member must record a video on the Mobile phone, or Digital
                Camera while unboxing the Products.
              </li>
              <li style={{ ...termstext, fontSize: "14px" }}>
                The Member must notify regarding fault, defect, deficiency,
                damage, imperfection etc., in the Products within 48 hours of
                delivery. Further the same shall be notified with supporting
                evidence.
              </li>
              <li style={{ ...termstext, fontSize: "14px" }}>
                The Member shall convey the above-mentioned complaint via
                Tri-Party Ticket to requisite persons i.e., Seller member, BXI
                representative and others.
              </li>
              <li style={{ ...termstext, fontSize: "14px" }}>
                If nothing about the Product/s is mentioned within 48 hours,
                then it will be considered as successful delivery, and
                furthermore, no complaints will be entertained.
              </li>
            </ul>
          </li>
        </ul>

        <Typography sx={termstext} variant="h5" gutterBottom>
          THE PROCEDURE TO RETURN THE PRODUCTS?
        </Typography>

        <Typography sx={termstext} paragraph>
          The member must initiate the process for returning products by raising
          a formal concern in the Tri-Party Chat Room by informing the Seller
          and BXI’s representative within 48 hours from the time of product
          receipt. The member is required to provide a detailed explanation
          specifying the reason behind the product’s return. To substantiate the
          claim, the member is obligated to submit requisite photographic and
          video evidence during the unboxing process of the products. Following
          the submission of the concern and evidence, BXI will undertake a
          thorough scrutiny and inspection of the reported issue. In cases where
          the return of products to the origin is necessitated by a member, the
          defaulter shall be responsible for covering the associated return
          logistic costs. The BXI shall render a final decision based on the
          findings of the inspection against the defaulter member. It is
          important to note that the decision made by the BXI shall be
          considered final and binding on both parties involved in the
          transaction.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          26. VOUCHER POLICY
        </Typography>

        <Typography sx={termstext} paragraph>
          This Voucher Policy governs the terms and conditions applicable to the
          purchase, redemption, and use of vouchers. By purchasing, redeeming,
          or using any voucher, Members (hereinafter referred to as "you" or
          "the voucher holder" or “user”) agree to be bound by this Voucher
          Policy. It is recommended that you carefully read and understand the
          terms and conditions outlined herein.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(a)</strong> Under the Voucher Policy the Seller member
          requests the BXI to formulate or generate Codes for Vouchers. Once the
          Buyer member requests to buy VoucherCodes, the formulated or generated
          Codes will be sent via. Email to both parties i.e., the Buyer member
          and the Seller member.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(b)</strong> Upon the transmission of these Codes to both
          parties, BXI would not be responsible, liable, or accountable for any
          issues that may arise from the use of the Voucher Codes.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(c)</strong> The Seller member guarantees that it shall accept
          the aforementioned Voucher Codes under all circumstances, and these
          vouchers shall not be deemed invalid or amount to stoppage without
          prior intimation with a valid reason to the Buyer member. The Seller
          member shall inform in all the outlets that Vouchers shall be accepted
          to avoid issues with regard to the unacceptance of Vouchers.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(d)</strong> The Seller member is strictly prohibited from
          distributing the Vouchers Codes outside the Marketplace. They are only
          permitted to sell these Voucher Codes to Members of the Marketplace.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(e)</strong> The redemption data for the Voucher Codes shall
          be recorded and maintained for both online and offline redemption
          scenarios by the Seller member. If the Buyer member faces any issue
          while redemption of vouchers then the Seller member will have to
          present enough proofs and evidence to justify the reason for issue
          created. If Seller Member fails to do so then it shall be penalized.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(f)</strong> BXI operates as a Marketplace provider and does
          not assume responsibility for any issues or guarantees associated with
          vouchers. Members are advised to exercise caution, perform necessary
          verifications, and rely on their own judgments when engaging with
          vouchers obtained through the Marketplace. This disclaimer is an
          integral part of the BXI Terms of Service and applies to all Members
          accessing the Marketplace and utilizing the voucher-related services.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>(g)</strong> In the event that a seller refuses to accept any
          valid vouchers then the seller may be deemed liable for compensating
          the Buyer . The compensation shall be provided in the form of cash
          payment to the Buyer . This obligation arises from a breach of
          contract or potential false advertising, and the Buyer may be entitled
          to seek legal remedies accordingly.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          27. FEEDBACK POLICIES
        </Typography>

        <Typography sx={termstext} paragraph>
          The Buyer member isn’t allowed to use threats of negative feedback or
          low ratings to get something that wasn't part of the original listing.
          The Seller member isn’t allowed to demand positive feedback from the
          Buyer member. Our feedback system plays a key role in keeping BXI a
          safe and trusted marketplace, and we’re committed to make sure that it
          isn’t misused.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>What are the Feedback policies? </strong>
          <br />
          The Buyer member isn’t allowed to:
        </Typography>

        <ul>
          <li>
            Threaten to use feedback or detailed seller ratings (DSRs) in an
            attempt to force Seller members into meeting their demands. For
            example, a Buyer member can't threaten to leave negative feedback
            unless the Seller member pays for return shipping when the Seller
            member's return policy in the listing didn't state free returns.
          </li>
        </ul>

        <Typography sx={termstext} paragraph>
          <strong>The Seller members aren’t allowed to:</strong>
        </Typography>

        <ul>
          <li>
            Demand that Buyer members take actions related to feedback in order
            to receive their purchased items, such as requiring a Buyer member
            to leave positive feedback before they dispatch the order.
          </li>
          <li>
            Offer refunds or other monetary compensation, or additional items or
            services that weren't part of the original listing, in exchange for
            Buyer members leaving specific feedback or revising negative or
            neutral feedback. Any attempt to manipulate feedback or Detailed
            Seller Ratings (DSRs) is not allowed.
          </li>
          <li>
            Conducting transactions with the purpose of increasing a feedback
            score, or obtaining Top Rated Seller member status or site
            privileges
          </li>
          <li>
            Manipulating another member's feedback through repeat purchases, or
            a pattern of leaving positive feedback with consistently low DSRs
          </li>
          <li>Offering to buy, sell, trade, or give away feedback</li>
        </ul>

        <Typography sx={termstext} paragraph>
          Any misuse of feedback is not allowed. Feedback comments should match
          the positive or negative rating that was given in both Buyer member
          and Seller member feedback. For example, a negative comment should not
          be left with a positive rating.
        </Typography>

        <Typography sx={termstext} paragraph>
          Feedback should only be related to the deal executed. We don't allow:
        </Typography>

        <ul>
          <li>
            Expressing opinions on any political, religious, or social issues
          </li>
          <li>
            Any links, URLs, or other content encouraging sales outside of BXI.
          </li>
          <li>Any implied or explicit threats or intimidation </li>
          <li>The publishing of any personally identifiable information </li>
          <li>Any inappropriate or offensive language </li>
          <li>Any references to investigations </li>
        </ul>

        <Typography sx={termstext} paragraph>
          If you believe that a Member might have breached this policy, you can
          report the buyer or report the seller to let us know
          @support@bxiworld.com The activity/transactions that doesn’t follow
          BXI’s policies could result in a range of actions including, for
          example, administratively ending or cancelling listings, feedback
          removal, buying or selling restrictions, cancellation of membership,
          termination and account suspension. This policy helps maintain trust
          between buyers and sellers and protects the integrity of the BXI
          feedback system.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          MEMBERS BEHAVIOUR POLICIES:
        </Typography>

        <Typography sx={termstext} paragraph>
          One of the foundations of the BXI is the level of trust between all
          Members of the BXI community. To maintain that trust, we expect all
          Members to treat each other with respect.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>UNSOLICITED: </strong>
          <br />
          At BXI, we appreciate comments from our Members on our Products and
          services, and we have a long-standing policy of not accepting
          unsolicited suggestions, ideas, or proposals.
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong>THREATS AND OFFENSIVE LANGUAGE POLICY: </strong>
          <br />
          It is strictly prohibited to engage in threats of bodily or physical
          harm or any kind of harassment using any method including by email,
          Member-to Member messaging, on our public message boards (such as our
          discussion It is strictly prohibited to engage in threats of bodily or
          physical harm or any kind of harassment using any method including by
          email, Member-to Member messaging, on our public message boards (such
          as our discussion
        </Typography>

        <Typography sx={termstext} paragraph>
          <strong> Member to Member/ community contact policy: </strong>
          <br />
          To keep the BXI marketplace functioning effectively, we know it is
          important for Members to be able to communicate easily with each
          other. However, to keep everyone in our community safe we have
          guidelines and policies that cover how you communicate.
          <br />
          We don’t allow our members to:
        </Typography>

        <ul>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Make offers to buy and sell outside of BXI
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>Send spam</li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Threaten others, or use profanity or hate speech
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Exchange any personal information (except names), including email
            addresses, phone numbers, or other contact information, sending
            viruses, malicious code, or software through our messaging service
            Spam, or unsolicited marketing chat, is not allowed on BXI. Members
            can only send messages via the chat box of “Triparty Chatroom” at
            bxiworld.com
          </li>
        </ul>

        <Typography sx={termstext} paragraph>
          <strong>Members can’t commit the following:</strong>
        </Typography>
        <ul>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Send other members messages that are not directly related to a
            purchase or sale on the Marketplace.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Contact other members to make offers to buy or sell products outside
            of BXI, or use information obtained on BXI to solicit sales outside
            of BXI.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Send messages that include email addresses, phone numbers, social
            media links, contact information, usernames, external web links or
            addresses, or other personal or business information.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Any misuse of feedback is not allowed. Feedback comments should
            match the positive or negative rating that were given in both Buyer
            member and Seller member feedback. For example, a negative comment
            should not be left with a positive rating.
          </li>
        </ul>

        <Typography sx={termstext} paragraph>
          <strong>
            Authorization to contact you, Recording calls, Analysing Messages
            Content:
          </strong>
          <br />
          By using the Services of BXI on the Marketplace, you hereby provide
          explicit consent and authorization to BXI to contact you using
          auto-dialled or pre-recorded calls and text messages, on any telephone
          number you have provided to us. This authorization encompasses the
          following purposes:
        </Typography>
        <ol>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Notification: BXI may contact you to provide important notifications
            or updates related to your account.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Troubleshooting: In the event of any issues or problems with your
            account, BXI may contact you to troubleshoot and resolve such
            matters. C. Dispute Resolution: BXI may contact you to address and
            resolve any disputes or conflicts related to your account.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Dispute Resolution: BXI may contact you to address and resolve any
            disputes or conflicts related to your account.
          </li>
          <li style={{ ...termstext, fontSize: "14px" }}>
            Surveys and Questionnaires: BXI may contact you to gather your
            opinions, feedback, or responses through surveys or questionnaires.
            E. Account Servicing and Compliance: BXI may contact you as
            necessary to service your account or enforce our policies,
            Applicable laws, or any other agreements we have with you.
            <br /> Any such electronic communication shall be for your own
            perusal and shall not be transmitted in any manner whatsoever.
            <br />
            Additionally, BXI may contact you via auto-dialed or pre-recorded
            calls and text messages for marketing purposes, such as providing
            offers and promotions, but only if you have given your consent to
            receive such communications. You have the right to opt-out of
            specific text marketing campaigns by replying "STOP" to the
            marketing text messages.
            <br />
            Moreover, it is important to note that BXI employs scanning and
            analysis techniques to review the Content of every message
            transmitted through its messaging Marketplace. This includes
            messages exchanged between the Members. The purpose of this scanning
            and analysis is to identify and prevent fraudulent activities or
            violations of BXI's policies, notices, rules, and other guidelines.
            Consequently, messages may be subject to manual review as part of
            this process. Scanning and analysis may occur prior to, during, or
            after message transmission, as well as during storage. It is
            possible that your message may be delayed or withheld as a result of
            this scanning and analysis. BXI retains the right to store message
            contents for the purpose of conducting these scanning and analysis
            activities.
          </li>
        </ol>

        <Typography sx={termstext} variant="h5" gutterBottom>
          28. TRI-PARTY CHATROOM
        </Typography>

        <Typography sx={termstext} paragraph>
          a. Triparty Chatroom is designed to facilitate communication between
          buyer, seller and BXI (Barter Exchange of India). This chatroom serves
          as a space for discussions related to barter transactions, product
          delivery, logistics and other relevant matters. BXI acts as a mediator
          in case any issues arise between buyers and sellers, ensuring a fair
          and efficient resolution process.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. The Triparty Chatroom aims to create a transparent and reliable
          environment for participants to engage in barter transactions. It
          enables open communication among buyers, sellers, and BXI, fostering
          trust and effective collaboration.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. All registered members of the BXI Marketplace, including buyers and
          sellers, are eligible to access the Tri-Party Chatroom.
        </Typography>

        <Typography sx={termstext} paragraph>
          d. Users are expected to maintain a respectful and professional
          demeanour while interacting in the chatroom. Harassment, hate speech,
          discrimination, or any form of offensive behaviour will not be
          tolerated. Participants must refrain from sharing personal information
          or engaging in activities that may compromise the privacy or security
          of others.
        </Typography>

        <Typography sx={termstext} paragraph>
          e. All communication shall be conducted in a clear, concise and
          respectful manner. Participants should refrain from using profanity,
          offensive language, or engaging in any form of communication that may
          be deemed inappropriate or unprofessional.
        </Typography>

        <Typography sx={termstext} paragraph>
          f. All communication among buyers and sellers is observed and
          monitored by BXI.
        </Typography>

        <Typography sx={termstext} paragraph>
          g. In the event of any disputes or issues between buyers and sellers,
          BXI will act as a neutral mediator to facilitate a fair resolution.
          Participants should cooperate with BXI’s instructions and provide the
          necessary information to help in the resolution process.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          29. CONFIDENTIALITY
        </Typography>

        <Typography sx={termstext} paragraph>
          a. For the purposes of these Terms of Use, “Confidential Information”
          is defined as written business information, strategies, technical
          data, information, trade secrets and/or other proprietary information
          of BXI relating to the product or process that is a part of the BXI
          that is not disclosed to the public in the ordinary course of business
          and may or may not be marked as “Confidential Information”.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. You agree and covenant to keep confidential all the information
          that you may have access to and/or possess by using our Marketplace or
          Services. Such Confidential information shall, without limitation,
          include, information relating to technical, contractual, product,
          Service, pricing and other valuable information that should be
          reasonably understood as confidential.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. You hereby agree to use the same degree of care that you use to
          protect your own confidential information and will not use the
          Confidential Information, except for its intended use, and will not
          disclose to third parties the said Confidential Information except
          upon written permission of BXI or where otherwise required by law. BXI
          is and shall remain the owner of the said Confidential Information
          and, upon termination of your membership on the Marketplace you will
          return Confidential Information to BXI. Your obligations relating to
          Confidential Information shall not extend to information which: (a)is
          in or comes into the public domain from a source other than BXI; (b)
          was independently known or developed by you or already possessed by
          you at the time of disclosure; (c) came from a third party source, not
          under obligation to BXI to maintain the confidentiality thereof;
          and/or (d) is required to be disclosed by operation of law.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          30. INTELLECTUAL PROPERTY RIGHTS
        </Typography>

        <Typography sx={termstext} paragraph>
          a. You agree and acknowledge that nothing herein shall give you any
          right, title or interest in BXI’s Intellectual Property Rights or its
          Proprietary Information.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. You agree to the use of the Services only, (i) for the purposes
          that are permitted by this Terms of Use, and (ii) in accordance with
          any Applicable law or generally accepted practices or guidelines of
          the relevant jurisdictions.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. You agree not to access (or attempt to access) the Marketplace or
          Services by any means other than through the interface that is
          provided by BXI. You shall NOT use any deep-link, robot, spider or
          other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to access, acquire, copy or
          monitor any portion of the Marketplace or Content, or in any way
          reproduce or circumvent the navigational structure or presentation of
          the Marketplace or any Content, to obtain or attempt to obtain any
          materials, documents or information through any means not specifically
          made available through the Marketplace.
        </Typography>

        <Typography sx={termstext} paragraph>
          d. The Marketplace and the processes and their selection and
          arrangement, including but not limited to all text, graphics, user
          interfaces, visual interfaces, computer code and data/information on
          the Marketplace is owned and controlled by the BXI.
        </Typography>

        <Typography sx={termstext} paragraph>
          e. The trademarks, logos and service marks displayed on the
          Marketplace are the property of the BXI or respective third parties.
          You are not permitted to use the marks without the prior consent of
          the BXI or the third party that may own the marks.
        </Typography>

        <Typography sx={termstext} paragraph>
          f. Except as expressly provided herein, you acknowledge and agree that
          you shall not copy, republish, post, display, translate, transmit,
          reproduce or distribute any Content through any medium without
          obtaining the necessary authorization from the BXI.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          31. PROPRIETARY RIGHTS
        </Typography>

        <Typography sx={termstext} paragraph>
          Any Content data, information of BXI (collectively “Proprietary
          Information”) published on the Marketplace and other intellectual
          property laws are the property of BXI. Except as permitted herein, you
          will not directly or indirectly copy Proprietary Information”
          published on the Marketplace, in whole or in part, without the express
          written consent of BXI. You shall not decompile, disassemble,
          electronically transfer, access the data programmatically, de-encrypt,
          or reverse engineer the Proprietary Information or translate it into
          another computer language. All Inquiries regarding permission to copy
          or use Marketplace or the Proprietary Information in any other manner
          should be directed to BXI’s authorized representatives. The
          restrictions set forth in this section shall survive the termination
          of this Agreement.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          32.DISCLAIMER OF WARRANTIES AND LIABILITIES
        </Typography>

        <Typography sx={termstext} paragraph>
          a. You expressly understand and agree that your use of the Marketplace
          and Services is at your sole risk and that the Services are provided
          ‘AS IS’ and ‘AS AVAILABLE’ without warranty or any kind, express,
          implied, statutory or otherwise.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. BXI, its employees, and officers do not represent or warrant to you
          that, (i) the Marketplace and your use of the Services shall meet your
          requirements, (ii) your use of the Marketplace or Services shall be
          free from error, timely, secure, or uninterrupted, or (iii) any errors
          or defects in the Services or the Marketplace will be corrected. No
          advice or information, whether oral or written, obtained by you from
          BXI or through or from use of the Services shall create any warranty
          not expressly stated in the Terms of Use.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. BXI will not be liable for any loss that you may incur as a
          consequence of unauthorized use of your Account or Account information
          in connection with the Marketplace or any Services, either with or
          without your knowledge.
        </Typography>

        <Typography sx={termstext} paragraph>
          d. BXI has endeavoured to ensure that all the information on the
          Marketplace is correct, but the BXI neither warrants nor makes any
          representations regarding the quality, accuracy, or completeness of
          any data, information, or Service.BXI shall not be responsible for the
          delay or inability to use the Marketplace or related functionalities,
          failure to provide functionalities, or for any information, software,
          functionalities and related graphics obtained through the Marketplace,
          or otherwise arising out of the use of the Marketplace, whether based
          on contract, tort, negligence, strict liability or otherwise. Further,
          BXI shall not be held responsible for the non-availability of the
          Marketplace during periodic maintenance operations or any unplanned
          suspension of access to the Marketplace that may occur due to
          technical reasons or for any reason beyond BXI’s control. The BXI
          accepts no liability for any errors or omissions, with respect to any
          information provided to you whether on behalf of itself or third
          parties.
        </Typography>

        <Typography sx={termstext} paragraph>
          e. Any material or data downloaded or otherwise obtained through the
          use of the Marketplace is done at your own discretion and risk. You
          shall be solely responsible for any damage to your computer system or
          other device or loss of data that results from the download of any
          such material or data.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          33. INDEMNIFICATION
        </Typography>

        <Typography sx={termstext} paragraph>
          a. You agree to indemnify, defend and hold harmless BXI from and
          against any and all losses, liabilities, claims, damages, demands,
          costs, and expenses (including legal fees and disbursements in
          connection with the use of Services and interest chargeable thereon)
          asserted against or incurred by the BXI that arise out of, result
          from, or may be payable by virtue of, any breach or non-performance of
          any representation, warranty, covenant or agreement made or obligation
          to be performed by you pursuant to these Terms of Use.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. Further, you agree to hold the BXI harmless against any claims made
          by any third party due to, or arising out of, or in connection with,
          your use of the Marketplace or Services, your material causing damage
          to a third party, your violation of the Terms of Use, or your
          violation of any rights of another, including any intellectual
          property rights. BXI reserves the right, at your expense, to assume
          the exclusive defence and control of any matter for which you are
          permitted to indemnify BXI, and you agree to cooperate with our
          defence of these claims. You agree not to settle any matter without
          prior written consent of BXI.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          34. LIMITATIONS OF LIABILITY
        </Typography>

        <Typography sx={termstext} paragraph>
          a. You expressly understand and agree that the BXI, its officers,
          managers, agents, partners, Members and employees shall not be liable
          to you, or any third party for:
        </Typography>

        <Typography sx={termstext} component="ul">
          <li>
            Any direct, indirect, consequential, exemplary, punitive or
            incidental loss or damages which may be incurred by you, however
            caused. This shall inter–alia include any loss of profit, any loss
            of goodwill or business reputation, any loss of data suffered or
            other intangible losses
          </li>
          <li>
            Any loss or damage incurred by you including but not limited to (a)
            any loss or damage as a result of any changes which BXI may make to
            the Services or any permanent or temporary cessation in the
            provision of the Services or of any features within the Services, or
            (b) the deletion of, corruption of, or failure to store any Content
            and other communications data maintained or transmitted by or
            through your use of the Services.
          </li>
          <li>
            Without limiting the generality of the foregoing, we (including our
            parent, subsidiaries, and affiliates, and our and their officers,
            directors, agents, and employees) are not liable, and you agree not
            to hold us responsible, for any damages or losses (including, but
            not limited to, loss of money, goodwill or reputation, profits,
            other intangible losses, or any special, indirect, or consequential
            damages) resulting directly or indirectly from:
          </li>
          <ul>
            <li>
              The content you provide (directly or indirectly) using our
              Services
            </li>
            <li>Your use of or your inability to use our Services. </li>
            <li>Pricing, format or other guidance provided by BXI</li>
            <li>
              Viruses or other malicious software obtained by accessing or
              linking to our Services
            </li>
            <li>
              glitches, bugs, errors, or inaccuracies of any kind in our
              Service.
            </li>
            <li>
              damage to your hardware device from the use of any BXI Service
            </li>
            <li>
              The content, actions, or inactions of third parties, including
              items listed using our Services or the destruction of allegedly
              fake items
            </li>
            <li>
              the duration or manner in which your listing appears in search
              results as set out in the Listing Conditions section above
            </li>
            <li>
              your need to modify practices, Content, or behaviour, or your loss
              of or inability to do business, as a result of changes to this
              User Agreement or our policies.
            </li>
          </ul>
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          35. GENERAL PROVISIONS
        </Typography>

        <Typography sx={termstext} paragraph>
          a. Any failure of the BXI to enforce at any time, or for any period of
          time, any of the provisions of the terms of use shall not be construed
          as a waiver of such provisions, or of any obligations, or of the right
          of BXI thereafter to enforce each and every such provision.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. If any provision of this Terms of Use is held by a court of
          competent jurisdiction to be contrary to law, the provisions shall be
          modified by the court and interpreted so as best to accomplish the
          objectives of the original provision to the fullest extent permitted
          by law, and the remaining provisions of these Terms of Use shall
          remain in effect. aining provisions of these Terms of Use shall remain
          in effect.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. You may not assign any of your rights or obligations hereunder,
          whether by operation of law or otherwise, without prior written
          consent of BXI. Subject to the foregoing, these Terms of Use shall
          bind and inure to the benefit of the parties, their respective
          successors and permitted assigns.
        </Typography>

        <Typography sx={termstext} paragraph>
          d. These Terms of Use read together with the Privacy Policy constitute
          the entire agreement between the parties and supersede all prior and
          contemporaneous agreements, proposals, or representations, whether
          written or oral, concerning its subject matter.
        </Typography>

        <Typography sx={termstext} paragraph>
          e. No modification, amendment, or waiver of any provision of these
          Terms of Use will be effective unless in writing and either signed or
          accepted electronically by the party against while the modification,
          amendment, or waiver is to be asserted.
        </Typography>

        <Typography sx={termstext} variant="h5" gutterBottom>
          36. OTHER BUSINESSES
        </Typography>

        <Typography sx={termstext} paragraph>
          For your convenience, the Marketplace may provide links to the sites
          of affiliated companies and certain other businesses, which may not be
          in our control. You acknowledge that the Company does not endorse
          these linked sites (even if they pop up in a frame) or any links
          contained in these linked sites. We are not responsible for examining
          or evaluating, and we do not warrant the offerings of any of these
          businesses or individuals or the Content of their website. The The
          company does not assume any responsibility or liability for the
          actions, products, and Content of all these and any other third
          parties. You should carefully review their privacy statements and
          other conditions of use.
        </Typography>

        <Typography sx={termstext} gutterBottom>
          37. TERMINATION
        </Typography>

        <Typography sx={termstext} paragraph>
          The Terms of Use will continue to apply until terminated by either you
          or BXI as set forth below.
        </Typography>

        <Typography sx={termstext} paragraph>
          a. If you want to terminate your agreement with the BXI, you may do so
          by (i) closing your membership Account on the Marketplace; and (ii)
          not accessing the Marketplace.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. BXI may at any time, at its sole discretion, with or without
          notice, terminate the Terms of Use with you if:
        </Typography>

        <Typography sx={termstext} component="ul">
          <li>
            You breach any of the provisions of the Terms of Use, the Privacy
            Policy or any other terms, conditions, or policies that may be
            applicable to you from time to time (or have acted in a manner that
            clearly shows that you do not intend to, or are unable to, comply
            with the same), or if any misconduct from your part is detected;
          </li>
          <li>
            The provision of the Services to you by the BXI is, in the BXI’s
            opinion, no longer commercially viable, or in any way detrimental to
            BXI, its business or the Marketplace; or
          </li>
          <li>
            BXI has elected to discontinue, with or without reason, access to
            the Marketplace, the Services (or any part thereof).
          </li>
        </Typography>

        <Typography sx={termstext} paragraph>
          c. BXI may also terminate or suspend all or a portion of your Account
          or access to the Services with or without reason. Termination of your
          Account may include: (i) removal of access to all offerings within the
          Marketplace or with respect to the Services; (ii) delisting of the
          Products and services of the Member and Account Information, including
          your personal information, log-in ID and password, and all related
          information, files and materials associated with or inside your
          Account; and (iii) barring of further use of the Services.
        </Typography>

        <Typography sx={termstext} paragraph>
          d. You agree that all terminations shall be made in BXI’s sole
          discretion and that BXI shall not be liable to you or any third party
          for any termination of your Account (and accompanying deletion of your
          Account Information), or your access to the Marketplace and Services.
        </Typography>

        <Typography sx={termstext} paragraph>
          e. The termination of your Account shall not relieve you of any
          liability that you may have incurred or may incur in relation to the
          use of Services provided prior to such termination. If you or BXI
          terminate your use of the Marketplace, BXI may delete any Content or
          other materials relating to your use of the Marketplace and Services
          and BXI will have no liability to you or any third party for doing so.
        </Typography>

        <Typography sx={termstext} gutterBottom>
          38. EFFECTS OF TERMINATION
        </Typography>

        <Typography sx={termstext} paragraph>
          a. Upon termination of this Terms due to any reasons whatsoever, the
          Member shall forthwith discontinue usage/access of the Marketplace or
          the Services in any manner whatsoever. The registered account of the
          Member shall be suspended, and the Member shall not have any right to
          access the same in any manner whatsoever.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. BXI will retain the data provided till it is required under any
          provision of law and thereafter it will destroy the same.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. Upon termination of the Terms of Use, the warranty disclaimers,
          limitation of liabilities, indemnities and governing law provision
          stated in this Terms of Use shall survive.
        </Typography>

        <Typography sx={termstext} gutterBottom>
          39. CONTRACTING AND NOTICES
        </Typography>

        <Typography sx={termstext} paragraph>
          a. You are contracting with BXI under this Terms of Use and you should
          direct any and all notices to support@bxiworld.com.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. Except as otherwise specified, all notices, permissions, and
          approvals hereunder must be in writing and shall be deemed to be
          received upon personal delivery, or the 5th (fifth) business day after
          mailing. Notices to you shall be addressed to the system administrator
          designated by you for your relevant membership account, and or the
          contact information provided during registration for the Marketplace
          and/or other Services.
        </Typography>

        <Typography sx={termstext} gutterBottom>
          40. ARBITRATION
        </Typography>

        <Typography sx={termstext} paragraph>
          a. In the event of any dispute, controversy, or claims arising out of
          or relating to this Agreement, termination or invalidity thereof shall
          be referred to and finally resolved by arbitration under the
          provisions of the Arbitration and Conciliation Act, 1996.
        </Typography>

        <Typography sx={termstext} paragraph>
          b. The Arbitration proceedings shall be conducted in English. b. The
          Arbitral Tribunal shall be composed of Sole Arbitrator, appointed by
          the Parties to the Agreement jointly. The cost of Arbitration shall be
          equally borne.
        </Typography>

        <Typography sx={termstext} paragraph>
          c. The principles of natural justice must be observed in the
          arbitration proceedings. The seat and venue of Arbitration shall be in
          Mumbai. The Arbitration award shall be final and binding upon both
          Parties hereto.
        </Typography>

        <Typography sx={termstext} paragraph>
          d. Arbitration Cost Policy: All disputes, controversies, or claims
          arising out of or relating to transactions, interactions, or
          agreements between members of BXI Marketplace shall be resolved
          through binding arbitration. All costs associated with the arbitration
          proceedings, including but not limited to arbitrator fees,
          administrative fees, and any other related expenses, shall be borne
          solely by the members involved in the dispute. BXI Marketplace shall
          not be held liable or responsible for any costs, fees, or expenses
          incurred during the arbitration process. Members agree to indemnify
          and hold harmless BXI Marketplace from any claims, costs, or
          liabilities arising from the arbitration proceedings.
        </Typography>

        <Typography sx={termstext} gutterBottom>
          41.GOVERNING LAW
        </Typography>

        <Typography sx={termstext} paragraph>
          This Agreement will be governed by Indian Law. Subject to Dispute
          Resolution, Competent Courts at Mumbai alone shall have exclusive
          jurisdiction to try, entertain any dispute arising out of or in
          connection with this Agreement.
        </Typography>
      </Box>
    </Stack>
  );
};

export default Legal_Policies;

const termstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "13px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
};
