import React from "react";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

const GetCompanyNameNProfile = (props) => {
  const [companyImages, setCompanyImage] = useState("");

  async function getCompanyByName() {
    await axios.get(`company/get_company/${props.CompanyId}`).then((res) => {
      setCompanyImage(res.data);
    });
  }
  useEffect(() => {
    getCompanyByName();
  }, []);

  let ImageUrl =
    props?.CompanyId === "admin"
      ? "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BXI.png"
      : companyImages?.CompanyAvatar?.url;
  let CompanyName =
    props?.CompanyId === "admin"
      ? "Barter Exchange of India"
      : companyImages?.companyName;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
        gap: "8px",
      }}
    >
      {" "}
      <img src={ImageUrl} style={{ width: "60px", height: "auto" }} />
      <Box>
        <div
          style={{
            width: "200px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Poppins",
            fontSize: "12px",
          }}
        >
          {CompanyName}
        </div>
        <Typography sx={CompanyTypeNameStyle}>
          {companyImages?.CompanyTypeName}
        </Typography>
      </Box>
    </div>
  );
};

export default GetCompanyNameNProfile;

const CompanyTypeNameStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
  lineHeight: "18px",
  color: "#AFAFAF",
};
