const OrderSummaryText = {
    PrintButton: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#445FD2",
        textTransform: "none",
    },

    PaymentLinkText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#000000",
    },

    DoNotRefreshText: {
        mt: 2,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "21px",
        textAlign: "center",
        color: "#000000",
    },

}

module.exports = { OrderSummaryText };