
import { createSlice } from '@reduxjs/toolkit';

export const productExploreSlice = createSlice({
    name: 'productsExploare',
    initialState: {
        productExplore: []
    },
    reducers: {
        product_Explore_Request: (state) => {
            state.loading = true;
            state.productExplore = [];
        },
        product_Explore_Success: (state, action) => {
            state.loading = false;
            state.productExplore = action.payload;
        },
        product_Explore_Fail: (state, action) => {
            state.loading = false;
            state.productExplore = action.payload;
        }
    }
});

export const { product_Explore_Request, product_Explore_Success, product_Explore_Fail } = productExploreSlice.actions;

export default productExploreSlice.reducer;


