import { Grid, Paper, Typography, Box, Button } from "@mui/material";
import React, { useState } from "react";
import first from "../../assets/Selleridetailsmages/Rectangle.svg";
import second from "../../assets/Selleridetailsmages/logo.svg";
import three from "../../assets/Selleridetailsmages/Rectangle (1).svg";
import four from "../../assets/Selleridetailsmages/Rectangle (2).svg";
import { useNavigate } from "react-router-dom";
import User from "../../assets/Selleridetailsmages/Vector (2).svg";
import Phone from "../../assets/Selleridetailsmages/Vector (3).svg";
import Address from "../../assets/Selleridetailsmages/Vector (5).svg";
import SecuredLetter from "../../assets/Selleridetailsmages/Vector (4).svg";
import { Dialog } from "@mui/material";
import axios from "axios";
import { MdCancel } from "react-icons/md";

export default function SellerDetails(props) {
  const navigate = useNavigate();
  const [openChat, setOpenChat] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  let Propsdata = props?.data;

  const handleClose = () => {
    setOpenChat(false);
    setOpen(false);
  };

  async function CreateWalletTransaction() {
    try {
      axios
        .post(`wallet/create_transaction`, {
          walletAddressFrom:
            Propsdata?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyId,
          walletAddressTo:
            Propsdata?.PurchaseOrderData?.SellerDetails?.SellerCompanyId,
          amount: Propsdata?.IsVoucher
            ? Propsdata?.BXITokenDetails?.TotalBXiCoins +
              Propsdata?.BXITokenDetails?.TotalAdCostInBxiWithoutGST
            : Propsdata?.PITotals?.TotalCoinsWithAdditionalCost,
          OrderId: Propsdata?.OrderId,
          order_id: Propsdata?._id,
        })
        .then((res) => {
          if (res?.data?.body?._balance) {
            axios
              .put(`order/update_order_with_any_data/${Propsdata?._id}`, {
                WalletData: res?.data?.body,
              })
              .then(() => {
                axios
                  .put(`order/update_order/${Propsdata?._id}`, {
                    data: "Success",
                  })
                  .then(() => {});
              });
          }
        });
    } catch (err) {}
  }

  let Idd = Propsdata?._id;

  async function CreateShipment() {
    if (
      Propsdata?.BuyerChoosedTransportation?.TransportationType === "pickrr"
    ) {
      await axios
        .post("purchase/create_shipment_order", {
          Idd,
          TransportersDetails:
            Propsdata?.BuyerChoosedTransportation?.TransportersDetails
              ?.TransportersDetails,
        })
        .then((res) => {});
    }
  }

  setTimeout(() => {
    CreateWalletTransaction();
    CreateShipment();
  }, 3000);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: "white",
        height: "77rem",
        width: "auto",
      }}
    >
      <Button variant="outlined" onClick={handleClickOpen}>
        Seller Details
      </Button>
      <Dialog open={open} fullWidth={true} maxWidth="xl">
        <Button
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 15,
            fontSize: "30px",
            "&:hover": {
              background: "none",
            },
          }}
        >
          <MdCancel />
        </Button>

        <Grid container>
          <img
            src={first}
            style={{
              background: "transparent",
              position: "absolute",
              marginLeft: "66.5%",
            }}
          />
          <img
            src={second}
            style={{
              background: "transparent",
              position: "absolute",
              marginTop: "110px",
              marginLeft: "80%",
            }}
          />

          <Box sx={{ width: "100%", mx: "auto", mt: 10 }}>
            <img
              src={three}
              style={{
                marginTop: "260px",
                position: "absolute",
                marginLeft: "52px",
              }}
            />

            <img
              src={four}
              style={{
                marginTop: "98px",
                position: "absolute",
                marginLeft: "52px",
                pb: 10,
              }}
            />

            <Typography
              sx={{
                color: "#226192",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "36px",
                textAlign: "center",
              }}
            >
              - Seller Details -
            </Typography>

            <Typography
              sx={{
                color: "#272523B2",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14px",
                textAlign: "center",
                width: "85%",
                mx: "auto",
              }}
            >
              If you experience any difficulties with your order, you may reach
              out to the seller <br /> with this contact information.
            </Typography>

            <Paper
              elevation={3}
              sx={{
                position: "relative",
                mt: 1,
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "15px",
                height: "auto",
                width: "80%",
                mx: "auto",
                pb: "40px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  xs={6}
                  sm={6}
                  sx={{
                    mt: 6,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={User}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                      }}
                    >
                      {
                        Propsdata?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyName
                      }
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={Phone}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                      }}
                    >
                      +91 -{" "}
                      {
                        Propsdata?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyContact
                      }
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={Address}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                        ml: 0.5,
                      }}
                    >
                      {
                        Propsdata?.PurchaseOrderData?.SellerDetails?.Address
                          ?.AddressLine
                      }
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      mt: 3,
                      marginLeft: "100px",
                    }}
                  >
                    <img
                      src={SecuredLetter}
                      style={{
                        color: "#3B4078",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#555555",
                        fontWeight: 500,
                      }}
                    >
                      {
                        Propsdata?.PurchaseOrderData?.SellerDetails
                          ?.SellerCompanyEmail
                      }
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/Selleridetailsmages/6884192+3.svg`}
                    style={{
                      borderRadius: "12px",
                      marginLeft: "140px",
                      mx: "auto",
                      width: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Button
              variant="contained"
              sx={{ ...btn, mx: "auto", width: "25%" }}
              align="left"
              // onClick={() => {
              //   setOpenChat(true);

              // }}
              onClick={() => {
                navigate(`/home/orderdetails/${Propsdata?._id}`);
              }}
            >
              {/* Chat with seller */}
              View Order summary
            </Button>

            <br />
          </Box>
        </Grid>
      </Dialog>
    </Paper>
  );
}

const btn = {
  mt: 2,
  display: "flex",
  gap: "5px",
  width: 200,
  height: 45,
  borderRadius: 1.5,
  background: "#226192",
  color: "#fff",
  fontFamily: "poppins",
  fontSize: "14px",
  borderRadius: "15px",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    background: "#226192",
  },
};

const btn1 = {
  mt: 1,
  color: "#226192",
  display: "flex",
  gap: "5px",
  width: 200,
  height: 45,
  borderRadius: 1.5,
  fontFamily: "poppins",
  fontSize: "14px",
  borderRadius: "15px",
  fontWeight: 500,
  textTransform: "none",
};
