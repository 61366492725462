export const SidebarStyle = {
  PaperStyle: {
    position: "relative",
    borderRadius: "0px",
    boxShadow: "none",
    bgcolor: "#F3F6F9",
    minHeight: "100vh",
    maxHeight: "100vh",
    overflowY: "scroll",
  },
  MotionPaperStyle: {
    width: "350px",
    height: "100%",
    minHeight: "100vh",
    zIndex: 10,
    borderRadius: "0px 15px 15px 0px",
    transition: "all 2s",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  CloseMenuButton: {
    position: "absolute",
    width: "50px",
    height: "50px",
    transform: "rotate(90deg)",
    top: 10,
    left: -2,
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  BoxStyle: {
    width: "100%",
    marginY: "2px",
    height: { xl: "45x", lg: "45px", md: "45px", sm: "45px", xs: "37px" },
    bgcolor: "white",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "rgba(156, 206, 255, 0.31)",
    },
  },

  TextStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: { xl: "15px", lg: "15px", md: "15px", sm: "14px", xs: "14px" },
    width: "100%",
    color: "#1D3163",
    lineHeight: "25px",
    cursor: "pointer",
    "&:hover": {
      color: "#C83F91",
    },
  },
  SidebarAccordionStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: { xl: "14px", lg: "14px", md: "14px", sm: "14px", xs: "14px" },
    "&:hover": {
      color: "#445FD2",
    },
    width: "100%",
    color: "#1D3163",
    lineHeight: "25px",
    cursor: "pointer",
    mt: 2,
  },

  ButtonStyle: {
    width: "270px",
    height: "50px",
    background: "transparent",
    borderRadius: "10px",
    textTransform: "none",
    transform: "scale(0.98)",
    "&:hover": {
      bgcolor: "#443FD8",
    },
  },

  LogoutText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    color: "#000",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  },

  OutLetParentStyle: {
    width: "98%",
    px: 2,
    py: 3,
    mt: 2,
    mx: "auto",
    bgcolor: "transparent",
    borderRadius: "30px",
    maxHeight: { xl: "82vh", lg: "83vh", md: "79vh", sm: "79vh", xs: "100vh" },
    minHeight: { xl: "82vh", lg: "83vh", md: "79vh", sm: "79vh", xs: "100vh" },
    overflowY: "scroll",
    border: "0px solid #E7E7E7",
    position: "relative",
  },

  OutLetBoxStyle: {
    maxWidth: "2000px",
    width: "96%",
    mx: "auto",
    bgcolor: "transparent",
    position: "relative",
  },

  Barterstyle: {
    position: "fixed",
    top: "55%",
    left: "55%",
    transform: "translate(67%, -52%)",
    width: "350px",
    height: "550px",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
  },

  styleCall: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xl: 400, lg: 400, md: 400, sm: 320, xs: 320 },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
  },
};
