import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import GotLeftImg from "../../assets/Images/CommonImages/GoLeftImg.png";
import GoRightImg from "../../assets/Images/CommonImages/GoRightImg.png";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      component={"img"}
      className={className}
      onClick={onClick}
      sx={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        left: "0%",
        height: "auto",
        width: "3%",
        zIndex: "1",
      }}
      src={GotLeftImg}
    ></Box>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      component={"img"}
      className={className}
      onClick={onClick}
      sx={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        right: "0%",
        height: "auto",
        width: "3%",
        zIndex: "10",
      }}
      src={GoRightImg}
    ></Box>
  );
}

export default function BannerCarousel(props) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    className: "center",
    beforeChange: (current, next) => setCurrentSlide(next),
    customPaging: function (i) {
      return (
        <a>
          <div>
            <li
              style={{
                border: "3px solid #6B7A99",
                backgroundColor: currentSlide === i ? "#6B7A99" : "white",
                borderRadius: "10px",
                width: "5px",
                height: "5px",
              }}
            ></li>
          </div>
        </a>
      );
    },
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 1000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    touchMove: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    fade: true,
  };

  return (
    <Grid
      sx={{
        position: "relative",
      }}
    >
      <Slider {...settings}>
        {props?.ImageDataArray?.map((res, index) => {
          if (!res.bannerIsactive) {
            return null;
          } else {
            return (
              <Box key={index} sx={{ position: "relative" }}>
                <Box
                  component={"img"}
                  src={res.url}
                  sx={{
                    ...ImageStyle,
                  }}
                />
                {res.buynowIsactive && (
                  <a href={res.buynowurl} rel="noopener noreferrer">
                    <Button sx={ButtonStyle}>Buy Now</Button>
                  </a>
                )}
              </Box>
            );
          }
        })}
      </Slider>
    </Grid>
  );
}

const ButtonStyle = {
  width: {
    xl: "200px",
    lg: "200px",
    md: "200px",
    sm: "120px",
    xs: "120px",
  },
  position: "absolute",
  height: { xl: "50px", lg: "50px", md: "50px", sm: "30px", xs: "30px" },
  background: "#fff",
  borderRadius: { xl: "15px", lg: "15px", md: "15px", sm: "8px", xs: "8px" },
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "20px",
    lg: "18px",
    md: "18px",
    sm: "14px",
    xs: "12px",
  },
  "&:hover": {
    background: "#C3CAD9",
  },
  lineHeight: "36px",
  color: "#303030",
  bottom: {
    xl: "15%",
    lg: "15%",
    md: "15%",
    sm: "15%",
    xs: "15%",
  },
  left: { xl: "75%", lg: "65%", md: "65%", sm: "60%", xs: "45%" },
  zIndex: 1,
  textTransform: "none",
};

const ImageStyle = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "12px",
  height: "100%",
  width: "100%",
  maxHeight: {
    xl: "270px",
    lg: "270px",
    md: "260px",
    sm: "140px",
    xs: "120px",
  },
  minHeight: {
    xl: "270px",
    lg: "270px",
    md: "260px",
    sm: "140px",
    xs: "100px",
  },
};
