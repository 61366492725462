import { createSlice } from '@reduxjs/toolkit';

export const SendEmailAndNotificationSlice = createSlice({
    name: 'EmailAndNotification',
    initialState: {
        EmailAndNotification: {}
    },
    reducers: {
        SEND_EMAILANDNOTIFICATION_REQUEST: (state) => {
            state.loading = true;
            state.EmailAndNotification = {};
        },
        SEND_EMAILANDNOTIFICATION_SUCCESS: (state, action) => {
            state.loading = false;
            state.EmailAndNotification = action.payload;
        },
        SEND_EMAILANDNOTIFICATION_FAIL: (state, action) => {
            state.loading = false;
            state.EmailAndNotification = action.payload;
        }
    }
});

export const { SEND_EMAILANDNOTIFICATION_REQUEST, SEND_EMAILANDNOTIFICATION_SUCCESS, SEND_EMAILANDNOTIFICATION_FAIL } = SendEmailAndNotificationSlice.actions;

export default SendEmailAndNotificationSlice.reducer;


