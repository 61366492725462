// Layout.js
import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box } from "@mui/material";
import BxiLogo from "../../assets/BXI_LOGO_OPE.jpg";

const Layout = ({ children }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?69804";
    script.async = true;
    script.onload = () => {
      const options = {
        enabled: true,
        chatButtonSetting: {
          backgroundColor: "#00e785",
          ctaText: "Chat with us",
          borderRadius: "25",
          marginLeft: "0",
          marginRight: "20",
          marginBottom: "20",
          ctaIconWATI: false,
          position: "right",
        },
        brandSetting: {
          brandName: "BXI World LLP",
          brandSubTitle: "undefined",
          brandImg: BxiLogo,
          welcomeText: "Hi there!\nHow can we help you?",
          messageText: "",
          backgroundColor: "#ffffff",
          ctaText: "Chat with us",
          borderRadius: "25",
          autoShow: false,
          phoneNumber: "919136436776",
        },
      };
      window.CreateWhatsappChatWidget(options);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Navbar />
      <Box>{children}</Box>
      <Footer sx={{ marginTop: "20px" }} />
    </>
  );
};

export default Layout;
