import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import stackofcoin from "../../../assets/CartPage/unnamed 1.svg";
import CompanyName from "../../../components/CompanyName";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader";
import { listPurchaseOrders } from "../../../redux/action/PurchaseOrder_Action";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { BiCheckbox } from "react-icons/bi";
import { useGetSellerSidePendingOrder } from "../../../Hooks/OrderActions/useGetSellerSidePendingOrder";
import AcceptedIcon from "../../../assets/Images/CommonImages/Accepted.png";
import DownIcon from "../../../assets/Images/CommonImages/Down.png";
import GoLeft from "../../../assets/Images/CommonImages/GoLeft.png";
import PendingIcon from "../../../assets/Images/CommonImages/Pending.png";
import RejectedIcon from "../../../assets/Images/CommonImages/Rejected.png";
import stackofcoins from "../../../assets/Stack of Coins.svg";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import CommaSeprator from "../../../components/CommaSeprator";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import ImageAndProductCarousel from "../../../components/Carousel/ImageAndProductCarousel";

function SellerPIList() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);
  const [openSecond, setOpenSecond] = useState(false);
  const [AllOrders, setAllOrders] = useState();
  const [userHubType, setUserHubType] = useState();
  const [Invoices, setInvoices] = useState();
  const [InvoiceLoading, setInvoicesLoading] = useState(true);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const FetchAllSellergeneratedProformaInvoice = async (page) => {
    try {
      await axios
        .get(`purchase/get_seller_generated_proforma_invoice/${page}`, {
          withCredentials: true,
        })
        .then((response) => {
          setInitialPaginationCount(response?.data?.finalCount);
          setInvoices(response?.data);
          setInvoicesLoading(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    FetchAllSellergeneratedProformaInvoice(currentPage);
  }, []);

  const GetOrdersId = async () => {
    return await axios
      .get(`order/get_all_orders_id`)
      .then((res) => {
        setAllOrders(res?.data?.orders);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    FetchAllSellergeneratedProformaInvoice(currentPage);
  }, [currentPage]);

  useEffect(() => {
    dispatch(listPurchaseOrders());
  }, [dispatch]);

  useEffect(() => {
    GetOrdersId();
    const userhubtypeData = localStorage.getItem("userhubtype");
    setUserHubType(userhubtypeData);
  }, []);

  return (
    <Paper
      sx={{
        boxShadow: "none",
        background: "transparent",
        width: "95%",
        mx: "auto",
      }}
      elevation={0}
    >
      <BreadCrumbHeader
        MainText={"Proforma Invoice List"}
        showbreadcrumb={true}
      />
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          padding: "1rem",
          borderRadius: "20px",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 1,
          position: "relative",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            height: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "170px",
              mt: "10px",
            }}
          >
            <Link to={"/home"}>
              <img src={GoLeft} width="22px" alt="golefticon" />
            </Link>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#242120",
                ml: "1rem",
              }}
            ></Typography>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            sx={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "99%",
            }}
          >
            <Grid
              item
              xl={0.5}
              lg={0.5}
              md={0.5}
              sm={0.5}
              xs={0.5}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography sx={tableheading}>No</Typography>
            </Grid>
            <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
              <Typography sx={tableheading}>Product Name</Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Order Id
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Total Products
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Barter Coins
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Order date
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Partial
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Status
              </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography align="center" sx={tableheading}>
                Action
              </Typography>
            </Grid>
          </Grid>
          <React.Fragment>
            {Invoices?.InvoiceData?.length > 0 &&
              Invoices?.InvoiceData?.map((data, idx) => {
                if (data?.OrderId === "BXI/BWET/May24/09") {
                }

                let totalPrice = 0;
                data?.InvoiceData?.ProductData?.map((data) => {
                  totalPrice +=
                    data.DiscountedPrice *
                      data?.TimelineToBought *
                      data.ProductQuantity *
                      data?.BoughtSeconds ||
                    data.DiscountedPrice * data.ProductQuantity;
                  return totalPrice;
                });

                function convertDate(inputFormat) {
                  function pad(s) {
                    return s < 10 ? "0" + s : s;
                  }
                  var d = new Date(inputFormat);
                  return [
                    pad(d.getDate()),
                    pad(d.getMonth() + 1),
                    d.getFullYear(),
                  ].join("/");
                }

                if (InvoiceLoading) {
                  return (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={35}
                    />
                  );
                } else {
                  return (
                    <Accordion
                      sx={{
                        p: 0,
                        boxShadow: "none",
                        border: "none",
                        borderBottom:
                          data?.InvoiceData?.PartialOrderDetails?.length > 0
                            ? "1px solid red"
                            : "1px solid #F5F5F5",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <img
                            src={DownIcon}
                            style={{ width: "9px" }}
                            alt="downicon"
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ p: 0 }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {" "}
                              {idx + 1}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                          >
                            <ImageAndProductCarousel
                              carouselData={data?.InvoiceData?.ProductData}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.OrderId}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              <CommaSeprator
                                Price={data?.InvoiceData?.ProductData?.length}
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <img
                                src={stackofcoin}
                                alt="rupieicon"
                                style={{
                                  width: "15px",
                                  height: "auto",
                                }}
                              />
                              <CommaSeprator Price={totalPrice} />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {convertDate(data?.InvoiceData?.PoDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                            >
                              {data?.InvoiceData?.PartialOrderDetails?.length >
                              0
                                ? "Yes"
                                : "No"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "14px",
                                color:
                                  data?.InvoiceData
                                    ?.BuyerInvoiceAcceptanceStatus === "Pending"
                                    ? "#FFB600"
                                    : data?.InvoiceData
                                        ?.BuyerInvoiceAcceptanceStatus ===
                                      "Accepted"
                                    ? "#118A2C"
                                    : "#FF0000",

                                mx: "auto",
                              }}
                            >
                              {data?.InvoiceData?.BuyerInvoiceAcceptanceStatus}
                            </Typography>
                            {data?.InvoiceData?.BuyerInvoiceAcceptanceStatus ===
                            "Pending" ? (
                              <Box
                                component="img"
                                src={PendingIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : data?.InvoiceData
                                ?.BuyerInvoiceAcceptanceStatus ===
                              "Accepted" ? (
                              <Box
                                component="img"
                                src={AcceptedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={RejectedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            )}
                          </Grid>

                          <Grid
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Box
                              onClick={() => {
                                navigate(
                                  `/home/sellerperformainvoice/${data?.InvoiceData?.OrderSummeryId}`
                                );
                              }}
                              sx={{
                                cursor: "pointer",
                                width: "100px",
                                mx: "auto",
                                height: "30px",
                                background:
                                  userHubType === "buyer"
                                    ? "#445FD2"
                                    : "#C64091",
                                borderRadius: "4.39877px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...TableBodyTextStyling,
                                  color: "#fff",
                                }}
                              >
                                View
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0, pb: 2 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            p: 0,
                            ml: 1,
                            width: "100%",
                            maxWidth: "500px",
                          }}
                        >
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <CompanyName
                              CompanyId={data?.BuyerCompanyId}
                              CompanyName={
                                data?.InvoiceData?.BuyerDetails
                                  ?.BuyerCompanyName
                              }
                              CompanyTypeName={
                                data?.InvoiceData?.BuyerDetails?.CompanyTypeName
                              }
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
          </React.Fragment>
          <Dialog
            open={openSecond}
            onClose={() => {
              setOpenSecond(false);
            }}
            sx={{
              backdropFilter: "blur(2px)",
            }}
            fullWidth
            maxWidth="md"
            PaperProps={{
              sx: {
                width: "30%",
                height: "auto",
                minHeight: "180px",
                maxHeight: "180px",
                borderRadius: "20px",
                justifyContent: "center",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "90%",
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: "#000",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (openSecond === false) {
                        setOpenSecond(true);
                      } else {
                        setOpenSecond(false);
                      }
                    }}
                  />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "#6B7A99",
                    }}
                  >
                    Thank you for your Purchase Order. You will be notified as
                    soon as the Seller raises the Proforma Invoice. Your
                    patience is appreciated.
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
        <Stack spacing={2}>
          <StyledPagination
            count={initialPaginationCount}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
}

export default SellerPIList;

const SwitchButtonStyle = {
  borderRadius: "9px",
  boxShadow: "none",
  fontSize: "12px",
  fontWeight: "400",
  textTransform: "capitalize",
  height: "30px",
  width: "100px",
  fontFamily: "Poppins",
};

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};
