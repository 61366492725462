import { Box, Grid, Typography, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import bxifeature from '../../assets/bxifeaturelogo1.png';
import BXIFLOWIMAGE from '../../assets/HomePageImages/Buysellimg.png';
import youtube from '../../assets/HomePageImages/iconoir_youtube.svg';
import BxiPointer from '../../assets/HomePageImages/BxiPointer.png';
import Accounting from '../../assets/HomePageImages/accounting.png';
import Final_BXI_COIN from '../../assets/HomePageImages/Final_BXI_COIN.png';
import FinalBarterCoin from '../../assets/HomePageImages/FinalBarterCoin.png';
import Folder from '../../assets/HomePageImages/creditdebit.png';
import OnlineShopping from '../../assets/HomePageImages/marketplace.png';
import Tender from '../../assets/HomePageImages/exchange.png';
import Pickrr from '../../assets/HomePageImages/Pickrr.png';
import JuspayLogo from '../../assets/Juspaylogo.png';
import Signzy from '../../assets/HomePageImages/signzy.png';
import Escrowpay from '../../assets/HomePageImages/Escrowpay.png';
import Unada from '../../assets/HomePageImages/Unada.png';
import PineLabs from '../../assets/HomePageImages/PineLabs.png';
import Shop_bag from '../../assets/HomePageImages/bwbcoin.png';
import Rotate_arrow from '../../assets/HomePageImages/newcash.svg';
import Rotate_arrow_black from '../../assets/HomePageImages/rotate_arrow.svg';
import Money_bag from '../../assets/HomePageImages/coiniconfinal.svg';
import Sell_product from '../../assets/HomePageImages/seller.svg';
import Cooperation from '../../assets/HomePageImages/bussinessdevelopment.png';
import Clip_path from '../../assets/HomePageImages/Network.png';
import Best_deal from '../../assets/HomePageImages/Bdeal.png';
import Application from '../../assets/HomePageImages/multiplecategories.png';
import Add_to_cart from '../../assets/HomePageImages/whatuwant.png';
import ShadowBox from './ShadowBox';
import BecomeMember from './HowItWorks/BecomeMember';
import Fade from 'react-reveal/Fade';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import './Style.css';
import AnimationOnImg from './Animation/AnimationOnImg';

const HowitworksPageBody = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        scale: 1,
      });
    }
  }, [controls, inView]);
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          height: {
            xl: '90vh',
            lg: '90vh',
            md: '90vh',
            sm: '100vh',
            xs: '100vh',
          },
          width: '100%',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: { xl: 12, lg: 12, md: 12, sm: 2, xs: 2 },
              // marginBottom: "20px",
            }}
          >
            <Fade top duration={1000}>
              <Typography
                variant="inherit"
                component="span"
                sx={{ ...headingStyle, fontWeight: 600 }}
              >
                Seller Benefits
              </Typography>
            </Fade>
          </Box>
          <Box sx={{ mt: { xl: 3, lg: 3, md: 3, sm: 1, xs: 1 } }}>
            <Grid
              container
              sx={{
                width: {
                  xl: '70%',
                  lg: '70%',
                  md: '70%',
                  sm: '90%',
                  xs: '90%',
                },
                mx: 'auto',
              }}
            >
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Sell_product}
                    title={'Sell Your Products/Services'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Money_bag}
                    title={'Earn Barter Coins'}
                    height={'60px'}
                    width={'60px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Cooperation}
                    title={'New Business Development'}
                    height={'48px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Clip_path}
                    title={'Network'}
                    height={'56px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Rotate_arrow}
                    title={'New Cash Flow'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: { xl: 6, lg: 4, md: 4, sm: 1, xs: 1 },
              marginBottom: {
                xl: '30px',
                lg: '30px',
                md: '30px',
                sm: '5px',
                xs: '5px',
              },
            }}
          >
            <Fade top duration={1000}>
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  ...headingStyle,
                  fontWeight: 600,
                }}
              >
                Buyer Benefits
              </Typography>
            </Fade>
          </Box>
          <Box
            sx={{
              marginBottom: {
                xl: '00px',
                lg: '00px',
                md: '00px',
                sm: '25px',
                xs: '25px',
              },
            }}
          >
            <Grid
              container
              sx={{
                width: {
                  xl: '70%',
                  lg: '70%',
                  md: '70%',
                  sm: '90%',
                  xs: '90%',
                },
                mx: 'auto',
              }}
            >
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Add_to_cart}
                    title={'Buy What You Want'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid item xs={6} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Application}
                    title={'Multiple Categories To Choose From'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Rotate_arrow_black}
                    title={'Save Cash Flow'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                md={2.4}
                sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 } }}
              >
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Best_deal}
                    title={'Get The Best Deals'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
              <Grid item xs={12} sm={2.4} md={2.4}>
                <AnimationOnImg>
                  <ShadowBox
                    imagePath={Shop_bag}
                    title={'Buy With Barter Coins'}
                    height={'50px'}
                    width={'50px'}
                  />
                </AnimationOnImg>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      {/* how to become member  */}
      <BecomeMember />

      {/* how to buy and sell  */}
      <Paper
        elevation={0}
        sx={{
          height: {
            xl: '80vh',
            lg: '80vh',
            md: '80vh',
            sm: '50vh',
            xs: '50vh',
          },
          width: '80%',
          mx: 'auto',
          mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontSize: {
                  xs: '22px',
                  sm: '25px',
                  md: '28px',
                  lg: '28px',
                  xl: '28px',
                },
              }}
            >
              To Buy & Sell How
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{
            width: '100%',
            mx: 'auto',
            mt: 5,
            height: '95%',
          }}
        >
          <Box
            component={'img'}
            src={BXIFLOWIMAGE}
            alt="HBS"
            sx={{
              width: '100%',
              height: { xl: '70%', lg: '70%', md: '70%', sm: '60%', xs: '60%' },
              marginTop: '5px',
              marginLeft: {
                xl: '30px',
                lg: '30px',
                md: '30px',
                sm: '0px',
                xs: '0px',
              },
            }}
          />
        </Box>
      </Paper>

      {/* Introducing Barter Coins for Business */}
      <Box
        sx={{
          height: {
            xl: '80vh',
            lg: '80vh',
            md: '80vh',
            sm: '60vh',
            xs: '60vh',
          },
        }}
      >
        <Box
          sx={{
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'none',
              xs: 'none',
            },
            justifyContent: 'center',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...headingStyle,
                fontWeight: 600,
                textAlign: 'center',
                fontSize: {
                  xs: '22px',
                  sm: '22px',
                  md: '28px',
                  lg: '28px',
                  xl: '28px',
                },
                lineHeight: {
                  xl: '65px',
                  lg: '65px',
                  md: '65px',
                  sm: '30px',
                  xs: '30px',
                },
              }}
            >
              Introducing BXI Barter Coins for Business
            </Typography>
          </Fade>
        </Box>
        {/* small sc */}
        <Box
          sx={{
            display: {
              xl: 'none',
              lg: 'none',
              md: 'none',
              sm: 'flex',
              xs: 'flex',
            },
          }}
        >
          <Fade top duration={1000}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="inherit"
                  component="span"
                  sx={{
                    ...headingStyle,
                    fontWeight: 600,
                    textAlign: 'center',
                    fontSize: {
                      xs: '22px',
                      sm: '22px',
                      md: '28px',
                      lg: '28px',
                      xl: '28px',
                    },
                    lineHeight: {
                      xl: '65px',
                      lg: '65px',
                      md: '65px',
                      sm: '30px',
                      xs: '30px',
                    },
                    ml: 1,
                  }}
                >
                  Introducing BXI Barter Coins for Business
                </Typography>
              </Box>
              <Typography
                variant="inherit"
                component="span"
                sx={{
                  ...gradientText,
                  fontWeight: 600,
                  textAlign: 'center',
                  fontSize: {
                    xs: '22px',
                    sm: '22px',
                    md: '28px',
                    lg: '28px',
                    xl: '28px',
                  },
                  lineHeight: {
                    xl: '65px',
                    lg: '65px',
                    md: '65px',
                    sm: '30px',
                    xs: '30px',
                  },
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                for Business
              </Typography>
            </Box>
          </Fade>
        </Box>
        <Grid
          container
          sx={{
            width: '100%',
            mx: 'auto',
            height: 'auto',
            position: 'relative',
            display: 'flex',
            overflowX: 'clip',
            mt: 2,
          }}
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                marginLeft: {
                  xl: '200px',
                  lg: '200px',
                  md: '200px',
                  sm: '30px',
                  xs: '30px',
                },
                marginTop: '0px',
                width: '90%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: {
                    xl: '20px',
                    lg: '20px',
                    md: '20px',
                    sm: '8px',
                    xs: '8px',
                  },
                }}
              >
                <Fade top duration={1000}>
                  <img
                    src={bxifeature}
                    alt="bxifeature"
                    style={{ width: '20.82px', height: '36.52px' }}
                  />
                  <Typography
                    sx={{
                      ...IBCBTypo,
                      fontWeight: 400,
                      fontSize: {
                        xl: '20px',
                        lg: '20px',
                        md: '20px',
                        sm: '15px',
                        xs: '15px',
                      },
                    }}
                  >
                    Sell Your Products / Services in
                    <br /> the BXI Marketplace
                  </Typography>
                </Fade>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: {
                    xl: '20px',
                    lg: '20px',
                    md: '20px',
                    sm: '8px',
                    xs: '8px',
                  },
                }}
              >
                <Fade top duration={1000}>
                  <img
                    src={bxifeature}
                    alt="bxifeature"
                    style={{ width: '20.82px', height: '36.52px' }}
                  />
                  <Typography
                    sx={{
                      ...IBCBTypo,
                      fontWeight: 400,
                      fontSize: {
                        xl: '20px',
                        lg: '20px',
                        md: '20px',
                        sm: '15px',
                        xs: '15px',
                      },
                    }}
                  >
                    Earn Barter Coins
                  </Typography>
                </Fade>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '40px',
                }}
              >
                <Fade top duration={1000}>
                  <img
                    src={bxifeature}
                    alt="bxifeature"
                    style={{ width: '20.82px', height: '36.52px' }}
                  />
                  <Typography
                    sx={{
                      ...IBCBTypo,
                      fontWeight: 400,
                      fontSize: {
                        xl: '20px',
                        lg: '20px',
                        md: '20px',
                        sm: '15px',
                        xs: '15px',
                      },
                    }}
                  >
                    Barter Coins Allows You to Buy What
                    <br /> You Want From the BXI Marketplace
                  </Typography>
                </Fade>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: {
                    xl: 'flex-start',
                    lg: 'flex-start',
                    md: 'center',
                    sm: 'center',
                    xs: 'center',
                  },
                  alignItems: {
                    xl: 'flex-start',
                    lg: 'flex-start',
                    md: 'center',
                    sm: 'center',
                    xs: 'center',
                  },
                  width: {
                    xl: '60%',
                    lg: '60%',
                    md: '60%',
                    sm: '70%',
                    xs: '70%',
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%', // Make the container responsive
                    paddingTop: '56.25%', // 16:9 Aspect Ratio (height / width * 100)
                    overflow: 'hidden',
                    borderRadius: '18px',
                    borderLeft: 'none',
                    borderRight: 'none',
                    backgroundColor: 'white',
                  }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/R7I5xfnAaYg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                  ></iframe>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                position: 'relative',
                display: {
                  xl: 'flex',
                  lg: 'flex',
                  md: 'flex',
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  position: 'relative',
                  marginLeft: '100px',
                }}
              >
                <div className="bgImageWithUrl">
                  <div className="Bounce_img">
                    <Box
                      component="img"
                      src={FinalBarterCoin}
                      alt="altimage"
                      sx={{
                        height: 'auto',
                        maxWidth: '473px',
                        width: '100%',
                      }}
                    />
                  </div>
                  <Typography
                    sx={{
                      ...IBCBTypo,
                      fontSize: '26px',
                      position: 'absolute',
                      fontWeight: 400,
                      right: 200,
                      top: 450,
                    }}
                  >
                    1 Barter Coin = 1 INR
                  </Typography>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
        }}
      >
        <Fade top duration={1000}>
          <Typography
            variant="inherit"
            component="span"
            sx={{
              ...headingStyle,
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            How Barter Coin Works ?
          </Typography>
        </Fade>
        <Fade top duration={1000}>
          <Typography
            variant="inherit"
            component="span"
            sx={{
              ...headingStyle,
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'center',
              fontSize: {
                xl: '22px',
                lg: '22px',
                md: '22px',
                sm: '18px',
                xs: '18px',
              },
            }}
          >
            How to Earn Barter Coins?
          </Typography>
        </Fade>
        <Typography sx={subSubText}>Two Ways To Get Barter Coins</Typography>
        <Typography sx={subSubText}>
          Upload Products - Sell and Earn Barter Coins
        </Typography>
        <Typography sx={subSubText}>
          Upload Products - Apply for Credit Line and Get Barter Coins in
          Advance
        </Typography>
      </Box>
      {/* How Barter Coin Works ?   */}
      <Box
        sx={{
          marginTop: {
            xl: '40px',
            lg: '40px',
            md: '40px',
            sm: '20px',
            xs: '20px',
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              marginTop: {
                xl: '10px',
                lg: '10px',
                md: '10px',
                sm: '30px',
                xs: '30px',
              },
              display: 'flex',
              alignItems: {
                xl: 'flex-end',
                lg: 'flex-end',
                md: 'flex-end',
                sm: 'center',
                xs: 'center',
              },
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: {
                  xl: 'flex-end',
                  lg: 'flex-end',
                  md: 'flex-end',
                  sm: 'center',
                  xs: 'center',
                },
                alignItems: 'center',
                marginRight: {
                  xl: '50px',
                  lg: '50px',
                  md: '50px',
                  sm: '50px',
                  xs: '0px',
                },
              }}
            >
              <Fade top duration={1000}>
                <Box
                  sx={{
                    width: '250px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography sx={CoinWorks}>
                    Earn and Spend Barter Coins
                  </Typography>
                  <Box
                    component="img"
                    src={OnlineShopping}
                    alt="OnlineShopping"
                    sx={{ width: '50.68px', height: '50.68px' }}
                  />
                </Box>
              </Fade>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: {
                  xl: 'flex-end',
                  lg: 'flex-end',
                  md: 'flex-end',
                  sm: 'center',
                  xs: 'center',
                },
                alignItems: 'center',
                marginRight: {
                  xl: '50px',
                  lg: '50px',
                  md: '50px',
                  sm: '50px',
                  xs: '0px',
                },
              }}
            >
              <Fade top duration={1000}>
                <Box
                  sx={{
                    width: '250px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography sx={CoinWorks}>Not a legal tender</Typography>
                  <Box
                    component="img"
                    src={OnlineShopping}
                    alt="OnlineShopping"
                    sx={{ width: '50.68px', height: '50.68px' }}
                  />
                </Box>
              </Fade>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                  xl: 'flex-end',
                  lg: 'flex-end',
                  md: 'flex-end',
                  sm: 'center',
                  xs: 'center',
                },
                marginRight: {
                  xl: '50px',
                  lg: '50px',
                  md: '50px',
                  sm: '50px',
                  xs: '0px',
                },
                // bgcolor:"red",
              }}
            >
              <Fade top duration={2000}>
                <Box
                  sx={{
                    width: '250px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      ...CoinWorks,
                      textAlign: {
                        xl: 'right',
                        lg: 'right',
                        md: 'right',
                        sm: 'center',
                        xs: 'center',
                      },
                    }}
                  >
                    For Accounting Purpose
                  </Typography>
                  <img
                    src={Accounting}
                    alt="OnlineShopping"
                    style={{ width: '60.68px', height: '60.68px' }}
                  />
                </Box>
              </Fade>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              // marginRight:"10px"
              paddingRight: {
                xl: '50px ',
                lg: '50px ',
                md: '50px ',
              },
            }}
          >
            <Box
              sx={{
                // height: "400px",
                // bgcolor: "red",
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              {/* <div className="Bounce_img"> */}
              <Box
                component="img"
                src={Final_BXI_COIN}
                alt="Final_BXI_COIN"
                sx={{
                  width: {
                    xl: '85%',
                    lg: '85%',
                    md: '85%',
                    sm: '55%',
                    xs: '55%',
                  },
                  height: 'auto',
                  mx: 'auto',
                }}
              />
              {/* </div> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              marginTop: {
                xl: '10px',
                lg: '10px',
                md: '10px',
                sm: '30px',
                xs: '30px',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              gap: '30px',
              marginLeft: {
                xl: '0px',
                lg: '0px',
                md: '0px',
                sm: '0px',
                xs: '80px',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Fade top duration={1000}>
                <img
                  src={Folder}
                  alt="OnlineShopping"
                  style={{ width: '60.68px', height: '60.68px' }}
                />
                <Typography sx={CoinWorks2}>
                  Valid Inside Marketplace
                </Typography>
              </Fade>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Fade top duration={1000}>
                <img
                  src={Tender}
                  alt="OnlineShopping"
                  style={{ width: '60.68px', height: '60.68px' }}
                />
                <Typography sx={CoinWorks2}>Medium of Exchange</Typography>
              </Fade>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Fade top duration={1000}>
                <img
                  src={Folder}
                  alt="OnlineShopping"
                  style={{ width: '60.68px', height: '60.68px' }}
                />
                <Typography sx={CoinWorks2}>1 Barter Coin = 1 INR</Typography>
              </Fade>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Can you Get Credit Line */}
      <Box sx={{ marginTop: '60px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{ ...headingStyle }}
            >
              How To Get Credit Line
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              ...gradientSubHeadTypo,
              fontSize: {
                xl: '24px',
                lg: '24px',
                md: '24px',
                sm: '20px',
                xs: '20px',
              },
            }}
          >
            Need Barter Coins in Advance
          </Typography>
        </Box>
        <Box sx={{ marginTop: '50px', position: 'relative' }}>
          <Box
            sx={{
              display: {
                xl: 'flex',
                lg: 'flex',
                md: 'flex',
                sm: 'none',
                xs: 'none',
              },
              justifyContent: 'center',
              position: 'absolute',
              width: '100%',
              height: '100%',
              paddingTop: '16px',
              zIndex: -1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '1px',
                background: '#8073B5',
              }}
            />
          </Box>
          <Grid container>
            <Grid item xs={6} sm={4} md={2.4}>
              <Box sx={BoxLine}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'white',
                    width: '15%',
                  }}
                >
                  <img
                    src={BxiPointer}
                    alt="BxiPointer"
                    style={{ width: '22.5px', height: '39.72px' }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                  }}
                >
                  <Typography sx={BoxLineText}>
                    Select product you
                    <br /> want to apply for
                    <br /> credit line
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Box sx={BoxLine}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'white',
                    width: '15%',
                  }}
                >
                  <img
                    src={BxiPointer}
                    alt="BxiPointer"
                    style={{ width: '22.5px', height: '39.72px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={BoxLineText}>
                    BXI evaluates
                    <br /> the product
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Box sx={BoxLine}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'white',
                    width: '15%',
                  }}
                >
                  <img
                    src={BxiPointer}
                    alt="BxiPointer"
                    style={{ width: '22.5px', height: '39.72px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={BoxLineText}>
                    Credit line limit
                    <br /> is offered
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Box sx={BoxLine}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'white',
                    width: '15%',
                  }}
                >
                  <img
                    src={BxiPointer}
                    alt="BxiPointer"
                    style={{ width: '22.5px', height: '39.72px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={BoxLineText}>
                    Sign document
                    <br /> & avail the limit
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={2.4}>
              <Box sx={BoxLine}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'white',
                    width: '15%',
                  }}
                >
                  <img
                    src={BxiPointer}
                    alt="BxiPointer"
                    style={{ width: '22.5px', height: '39.72px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={BoxLineText}>
                    Start to buy even
                    <br /> before you sell
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={6} sm={4} md={2}>
              <Box sx={BoxLine}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "white",
                    width: "15%",
                  }}
                >
                  <img
                    src={BxiPointer}
                    alt="BxiPointer"
                    style={{ width: "22.5px", height: "39.72px" }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={BoxLineText}>
                    BXI takes the
                    <br /> responsibility <br />
                    to sell
                  </Typography>
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      {/* Our Business Accelerators */}
      <Box sx={{ marginTop: '60px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{ ...headingStyle }}
            >
              OUR
            </Typography>
            <Typography
              variant="inherit"
              component="span"
              sx={{ ...gradientText }}
            >
              BUSINESS ACCELERATORS
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{
            width: { xl: '70%', lg: '70%', md: '70%', sm: '100%', xs: '100%' },
            mx: 'auto',
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Grid item xl={4} lg={4} xs={3} sm={3} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={Signzy}
                    alt="signzy"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '60%',
                        lg: '60%',
                        md: '60%',
                        sm: '100%',
                        xs: '100%',
                      },
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} xs={3} sm={3} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={JuspayLogo}
                    alt="Pickrr"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '60%',
                        lg: '60%',
                        md: '60%',
                        sm: '100%',
                        xs: '100%',
                      },
                      mt: '20px',
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} xs={3} sm={3} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={Escrowpay}
                    alt="Escrowpay"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '60%',
                        lg: '60%',
                        md: '60%',
                        sm: '100%',
                        xs: '100%',
                      },
                      mt: '20px',
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: { xl: '40%', lg: '40%', md: '40%', sm: '60%', xs: '60%' },
            mx: 'auto',
          }}
        >
          <Grid
            container
            sx={{
              marginTop: {
                xl: '40px',
                lg: '40px',
                md: '40px',
                sm: '20px',
                xs: '20px',
              },
            }}
          >
            <Grid item xs={6} sm={6} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: {
                    xl: 'left',
                    lg: 'left',
                    md: 'left',
                    sm: 'center',
                    xs: 'center',
                  },
                }}
              >
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={Unada}
                    alt="Unada"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '20%',
                        lg: '20%',
                        md: '20%',
                        sm: '25%',
                        xs: '25%',
                      },
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <AnimationOnImg>
                  <Box
                    component={'img'}
                    src={PineLabs}
                    alt="PineLabs"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '60%',
                        lg: '60%',
                        md: '60%',
                        sm: '70%',
                        xs: '70%',
                      },
                    }}
                  />
                </AnimationOnImg>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Our clients love our easy process Here’s Why */}
      <Box
        sx={{
          marginTop: {
            xl: '60px',
            lg: '60px',
            md: '60px',
            sm: '40px',
            xs: '40px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '95%',
            alignItems: 'center',
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          <Fade top duration={1000}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  ...headingStyle,
                  fontSize: {
                    xs: '22px',
                    sm: '22px',
                    md: '28px',
                    lg: '28px',
                    xl: '28px',
                  },
                  lineHeight: {
                    xl: '65px',
                    lg: '65px',
                    md: '65px',
                    sm: '30px',
                    xs: '30px',
                  },
                  textAlign: 'center',
                }}
              >
                Client Stories
              </Typography>
            </Box>
          </Fade>
        </Box>
        <Paper elevation={0} sx={{ width: 'auto', height: '90%' }}>
          <Box
            sx={{
              width: '90%',
              mx: 'auto',
              marginTop: '45px',
              marginBottom: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/YOJUPi0FBLc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowfullscreen
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '850px',
                maxHeight: '479px',
                minHeight: '479px',
                objectFit: 'cover',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '17.449px',
                borderLeft: 'none',
                borderRight: 'none',
                backgroundColor: 'white',
              }}
            ></iframe>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default HowitworksPageBody;
const gradientText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '22px',
    sm: '22px',
    md: '28px',
    lg: '28px',
    xl: '28px',
  },
  letterSpacing: '0.5px',
  lineHeight: { xl: '65px', lg: '65px', md: '65px', sm: '50px', xs: '50px' },
  display: 'flex',
  alignItems: 'center',
  background: 'linear-gradient(75deg, #375DBB 29.17%, #00B1FF 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  textTransform: 'uppercase',
};
const headingStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '22px',
    sm: '22px',
    md: '28px',
    lg: '28px',
    xl: '28px',
  },
  letterSpacing: '0.5px',
  lineHeight: { xl: '65px', lg: '65px', md: '65px', sm: '50px', xs: '50px' },
  display: 'flex',
  alignItems: 'center',
  color: '#0D0E0E',
  marginRight: '10px',
  textTransform: 'uppercase',
};
const gradientSubHeadTypo = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: {
    xs: '22px',
    sm: '28px',
    md: '32px',
    lg: '32px',
    xl: '32px',
  },
  lineHeight: '30px',
  display: 'flex',
  alignItems: 'center',
  color: '#494B7A',
};
const IBCBTypo = {
  width: '452.89px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: { xl: '20px', lg: '20px', md: '20px', sm: '10px', xs: '10px' },
  lineHeight: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
  display: 'flex',
  alignItems: 'center',
  color: '#494B7A',
  marginLeft: '20px',
};

const BoxLine = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

const BoxLineText = {
  width: {
    xl: '228px',
    lg: '228px',
    md: '188px',
    sm: '158px',
    xs: '158px',
  },
  height: '89px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: {
    xl: '14px',
    lg: '14px',
    md: '14px',
    sm: '14px',
    xs: '14px',
  },
  // lineHeight: "30px",
  display: 'flex',
  justifyContent: 'center',
  // alignItems: "center",
  mt: 2,
  textAlign: 'center',
  color: 'rgba(73, 75, 122, 1)',
  // bgcolor: "red",
};

const CoinWorks = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '26px',
  color: 'rgba(73, 75, 122, 1)',
  display: 'flex',
  alignItems: 'center',
  textAlign: {
    xl: 'right',
    lg: 'right',
    md: 'center',
    sm: 'center',
    xs: 'center',
  },
  marginRight: '20px',
  fontWeight: 500,
};

const CoinWorks2 = {
  width: {
    xl: '385px',
    lg: '385px',
    md: '340px',
    sm: '300px',
    xs: '300px',
  },
  fontSize: '14px',
  lineHeight: '26px',
  color: 'rgba(73, 75, 122, 1)',
  display: 'flex',
  alignItems: 'center',
  textAlign: {
    xl: 'left',
    lg: 'left',
    md: 'center',
    sm: 'center',
    xs: 'center',
  },
  color: '#494B7A',
  marginLeft: '20px',
};

const subSubText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xl: '16px',
    lg: '16px',
    md: '15px',
    sm: '15px',
    xs: '15px',
  },
  lineHeight: '24px',
  color: '#717171',
};
