import { createSlice } from "@reduxjs/toolkit";

export const OrderDetailsSlice = createSlice({
  name: "OrderDetails",
  initialState: {
    OrderDetails: [],
  },
  reducers: {
    ORDER_DETAILES_REQUEST: (state) => {
      state.loading = true;
      state.OrderDetails = [];
    },
    ORDER_DETAILES_SUCCESS: (state, action) => {
      state.loading = false;
      state.OrderDetails = action.payload;
    },
    ORDER_DETAILES_FAIL: (state, action) => {
      state.loading = false;
      state.OrderDetails = action.payload;
    },
  },
});

export const {
  ORDER_DETAILES_REQUEST,
  ORDER_DETAILES_SUCCESS,
  ORDER_DETAILES_FAIL,
} = OrderDetailsSlice.actions;
export default OrderDetailsSlice.reducer;
