import React, { useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import GoLeft from "../../assets/Images/CommonImages/GoLeft.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetrecentProducts } from "../../Hooks/GetProducts/useGetrecentProducts";
import axios from "axios";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import ProductProgressBar from "../../components/ProgressBar/ProductProgressBar";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{
          color: "#3361FF",
          fontWeight: "bold",
        }}
        thickness={1}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{
            fontFamily: "Poppins",
            color: "#6B7A99",
            fontWeight: 500,
            fontSize: "20px",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const GeneralInfoParent = () => {
  let id;
  id = useParams().id;
  const location = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = React.useState(25);
  const [ProgressStep, setProgressStep] = React.useState(1);

  const {
    data: recentProductsData,
    isLoading,
    isError,
    refetch: refetchRecentProducts,
  } = useGetrecentProducts();

  let path = window.location.pathname;
  const { data: CompanyData } = useGetLoggedInUser();
  const {
    data: CompanyTypeData,
    isLoading: CompanyTypeDataLoading,
    error: CompanyTypeDataError,
  } = useGetCompanyTypeData(CompanyData?.data?.companyType);

  const [currentProduct, setCurrentProduct] = React.useState({});
  const fetchCurrentProduct = async () => {
    await axios
      .get(`product/get_product_byId/${id}`)
      .then((res) => {
        setCurrentProduct(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchCurrentProduct();
    refetchRecentProducts();
  }, [location]);
  useEffect(() => {
    if (
      path.includes("texttileproductinfo") ||
      path.includes("lifestyleproductinfo") ||
      path.includes("electronicsproductinfo") ||
      path.includes("officesupplyproductinfo") ||
      path.includes("mediaonlineproductinfo") ||
      path.includes("mediaofflineproductinfo") ||
      path.includes("hotelsproductinfo") ||
      path.includes("fmcgproductinfo") ||
      path.includes("restaurantproductinfo") ||
      path.includes("eegeneralinformation") ||
      path.includes("othersproductinfo") ||
      // voucher

      path.includes("qsrgeneralinformation") ||
      path.includes("lifestylegeneralinformation") ||
      path.includes("airlinegeneralinformation") ||
      path.includes("othergeneralinformation") ||
      path.includes("hotelsproductinfo") ||
      path.includes("textilegeneralinformation") ||
      path.includes("eegeneralinformation") ||
      path.includes("officesupplygeneralinformation") ||
      path.includes("mobilitygeneralinformation") ||
      path.includes("fmcggeneralinformation") ||
      path.includes("electronicsgeneralinformation")
    ) {
      setProgress(50);
      setProgressStep(2);
    } else if (
      path.includes("technicalinfo") ||
      path.includes("lifestyletechinfo") ||
      path.includes("electronicstechinfo") ||
      path.includes("officesupplytechinfo") ||
      path.includes("mediaonlinetechinfo") ||
      path.includes("mediaofflinetechinfo") ||
      path.includes("hotelstechinfo") ||
      path.includes("fmcgtechinfo") ||
      path.includes("restauranttechinfo") ||
      path.includes("eetechinfo") ||
      path.includes("otherstechinfo") ||
      // voucher
      path.includes("qsrtechinfo") ||
      path.includes("lifestyletechinfo") ||
      path.includes("airlinetechinfo") ||
      path.includes("othertechinfo") ||
      path.includes("hotelstechinfo") ||
      path.includes("textiletechinfo") ||
      path.includes("eetechinfo") ||
      path.includes("officesupplytechinfo") ||
      path.includes("mobilitytechinfo") ||
      path.includes("fmcgtechinfo") ||
      path.includes("electronicstechinfo")
    ) {
      setProgress(75);
      setProgressStep(3);
    } else if (
      path.includes("golive") ||
      path.includes("lifestylegolive") ||
      path.includes("electronicsgolive") ||
      path.includes("officesupplygolive") ||
      path.includes("mediaonlinegolive") ||
      path.includes("mediaofflinegolive") ||
      path.includes("hotelsgolive") ||
      path.includes("fmcggolive") ||
      path.includes("restaurantgolive") ||
      path.includes("voucherdesign") ||
      path.includes("othersgolive")
    ) {
      setProgress(100);
      setProgressStep(4);
    } else if (
      path.includes("") ||
      path.includes("lifestylegeneralinfo") ||
      path.includes("electronicsgeneralinfo") ||
      path.includes("officesupplygolive")
    ) {
      setProgress(25);
    }
  });

  const leftarrow = () => {
    if (
      location.pathname?.includes("Voucher") ||
      location.pathname?.includes("voucher") ||
      location.pathname?.includes("vouchers")
    ) {
      if (
        localStorage.getItem("companyType") === "Mobility" &&
        location.pathname?.includes("mobilitygeneralinformation")
      ) {
        navigate(`/home/mobilityVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Electronics" &&
        location.pathname?.includes("electronicsgeneralinformation")
      ) {
        navigate(`/home/electronicsVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "FMCG" &&
        location.pathname?.includes("fmcggeneralinformation")
      ) {
        navigate(`/home/fmcgVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Others" &&
        location.pathname?.includes("othergeneralinformation")
      ) {
        navigate(`/home/otherVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Office Supply" &&
        location.pathname?.includes("officesupplygeneralinformation")
      ) {
        navigate(`/home/officesupplyVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Lifestyle" &&
        location.pathname?.includes("lifestylegeneralinformation")
      ) {
        navigate(`/home/lifestyleVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "QSR" &&
        location.pathname?.includes("qsrgeneralinformation")
      ) {
        navigate(`/home/qsrVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Entertainment & Events" &&
        location.pathname?.includes("eegeneralinformation")
      ) {
        navigate(`/home/eeVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Textile" &&
        location.pathname?.includes("textilegeneralinformation")
      ) {
        navigate(`/home/textileVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Airlines Tickets" &&
        location.pathname?.includes("airlinegeneralinformation")
      ) {
        navigate(`/home/airlineVoucher/` + id);
      } else if (
        location.pathname?.includes("electronicsVoucher/electronicstechinfo")
      ) {
        navigate(
          `/home/electronicsVoucher/electronicsgeneralinformation/` + id
        );
      } else if (location.pathname?.includes("otherVoucher/othertechinfo")) {
        navigate(`/home/otherVoucher/othergeneralinformation/` + id);
      } else if (location.pathname?.includes("fmcgVoucher/fmcgtechinfo")) {
        navigate(`/home/fmcgVoucher/fmcggeneralinformation/` + id);
      } else if (location.pathname?.includes("eeVoucher/eetechinfo")) {
        navigate(`/home/eeVoucher/eegeneralinformation/` + id);
      } else if (
        location.pathname?.includes("lifestyleVoucher/lifestyletechinfo")
      ) {
        navigate(`/home/lifestyleVoucher/lifestylegeneralinformation/` + id);
      } else if (
        location.pathname?.includes("textileVoucher/textiletechinfo")
      ) {
        navigate(`/home/textileVoucher/textilegeneralinformation/` + id);
      } else if (
        location.pathname?.includes("mobilityVoucher/mobilitytechinfo")
      ) {
        navigate(`/home/mobilityVoucher/mobilitygeneralinformation/` + id);
      } else if (
        location.pathname?.includes("electronicsVoucher/electronicstechinfo")
      ) {
        navigate(
          `/home/electronicsVoucher/electronicsgeneralinformation/` + id
        );
      } else if (
        location.pathname?.includes("officesupplyVoucher/officesupplytechinfo")
      ) {
        navigate(
          `/home/officesupplyVoucher/officesupplygeneralinformation/` + id
        );
      } else if (
        location.pathname?.includes("airlineVoucher/airlinetechinfo")
      ) {
        navigate(`/home/airlineVoucher/airlinegeneralinformation/` + id);
      } else if (location.pathname?.includes("qsrVoucher/qsrtechinfo")) {
        navigate(`/home/qsrVoucher/qsrgeneralinformation/` + id);
      } else if (
        location.pathname?.includes("mobilityVoucher") ||
        location.pathname?.includes("electronicsVoucher") ||
        location.pathname?.includes("fmcgVoucher") ||
        location.pathname?.includes("officesupplyVoucher") ||
        location.pathname?.includes("lifestyleVoucher") ||
        location.pathname?.includes("qsrVoucher") ||
        location.pathname?.includes("textileVoucher") ||
        location.pathname?.includes("airlineVoucher") ||
        location.pathname?.includes("otherVoucher")
      ) {
        navigate(`/home/physical`);
      } else if (location.pathname?.includes("eeVoucher")) {
        navigate(`/home/eephysical`);
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Hotel" &&
        location.pathname?.includes("hotelstechinfo")
      ) {
        navigate("/home/hotelsVoucher/hotelsproductinfo/" + id);
      } else if (location.pathname?.includes("hotelsgolive")) {
        navigate("/home/hotelsVoucher/hotelstechinfo/" + id);
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Hotel" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate("/home/hotelsVoucher/hotelstechinfo/" + id);
      } else if (
        localStorage.getItem("companyType") === "Others" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate("/home/otherVoucher/othertechinfo/" + id);
      } else if (location.pathname?.includes("hotelsproductinfo")) {
        navigate("/home/hotelsVoucher/" + id);
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "FMCG" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate("/home/fmcgVoucher/fmcgtechinfo/" + id);
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Mobility" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate("/home/mobilityVoucher/mobilitytechinfo/" + id);
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Office Supply" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate("/home/officesupplyVoucher/mobilitytechinfo/" + id);
      }
      if (
        localStorage.getItem("companyType") === "Mobility" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/mobilityVoucher/mobilitytechinfo` + id);
      } else if (
        localStorage.getItem("companyType") === "Electronics" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/electronicsVoucher/electronicstechinfo` + id);
      } else if (
        localStorage.getItem("companyType") === "Lifestyle" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/lifestyleVoucher/lifestyletechinfo` + id);
      } else if (
        localStorage.getItem("companyType") === "QSR" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/qsrVoucher/qsrtechinfo` + id);
      } else if (
        localStorage.getItem("companyType") === "Entertainment & Events" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/eeVoucher/eetechinfo` + id);
      } else if (
        localStorage.getItem("companyType") === "Textile" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/textileVoucher/` + id);
      } else if (
        localStorage.getItem("companyType") === "Airlines Tickets" &&
        location.pathname?.includes("voucherdesign")
      ) {
        navigate(`/home/airlineVoucher/airlinetechinfo/` + id);
      }
    } else if (location.pathname?.includes("lifestyleproductinfo")) {
      navigate("/home/lifestyle", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("lifestyletechinfo")) {
      navigate("/home/lifestyle/lifestyleproductinfo/" + id);
    } else if (location.pathname?.includes("lifestylegolive")) {
      navigate("/home/lifestyle/lifestyletechinfo/" + id);
    } else if (location.pathname?.includes("othersproductinfo")) {
      navigate("/home/others", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("otherstechinfo")) {
      navigate("/home/others/othersproductinfo/" + id);
    } else if (location.pathname?.includes("othersgolive")) {
      navigate("/home/others/otherstechinfo/" + id);
    } else if (location.pathname?.includes("officesupplyproductinfo")) {
      navigate("/home/officesupply", { state: { id: id } });
    } else if (location.pathname?.includes("officesupplytechinfo")) {
      navigate("/home/officesupply/officesupplyproductinfo/" + id);
    } else if (location.pathname?.includes("officesupplygolive")) {
      navigate("/home/officesupply/officesupplytechinfo/" + id);
    } else if (location.pathname?.includes("textile/texttileproductInfo")) {
      navigate("/home/textile", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("technicalinfo")) {
      navigate("/home/textile/texttileproductInfo/" + id);
    } else if (location.pathname?.includes("/golive")) {
      navigate("/home/textile/technicalinfo/" + id);
    } else if (location.pathname?.includes("restaurantproductinfo")) {
      navigate("/home/restaurant", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("restauranttechinfo")) {
      navigate("/home/restaurant/restaurantproductinfo/" + id);
    } else if (location.pathname?.includes("restaurantgolive")) {
      navigate("/home/restaurant/restauranttechinfo/" + id);
    } else if (location.pathname?.includes("electronicsproductinfo")) {
      navigate("/home/electronics", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("electronicstechinfo")) {
      navigate("/home/electronics/electronicsproductinfo/" + id);
    } else if (location.pathname?.includes("electronicsgolive")) {
      navigate("/home/electronics/electronicstechinfo/" + id);
    } else if (location.pathname?.includes("hotelsproductinfo")) {
      navigate("/home/hotelsVoucher/" + id);
    } else if (location.pathname?.includes("fmcgproductinfo")) {
      navigate("/home/fmcg", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("fmcgtechinfo")) {
      navigate("/home/fmcg/fmcgproductinfo/" + id);
    } else if (location.pathname?.includes("fmcggolive")) {
      navigate("/home/fmcg/fmcgtechinfo/" + id);
    } else if (location.pathname?.includes("mobilityproductinfo")) {
      navigate("/home/mobility", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("mobilitytechinfo")) {
      navigate("/home/mobility/mobilityproductinfo/" + id);
    } else if (location.pathname?.includes("mobility/mobilitygolive")) {
      navigate("/home/mobility/mobilitytechinfo/" + id);
    } else if (location.pathname?.includes("mediaonlineproductinfo")) {
      navigate("/home/mediaonline", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("mediaonlinetechinfo")) {
      navigate("/home/mediaonline/mediaonlineproductinfo/" + id);
    } else if (location.pathname?.includes("mediaonlinegolive")) {
      navigate("/home/mediaonline/mediaonlinetechinfo/" + id);
    } else if (location.pathname?.includes("mediaofflineproductinfo")) {
      navigate("/home/mediaoffline", {
        state: { id: id },
      });
    } else if (location.pathname?.includes("mediaofflinetechinfo")) {
      navigate("/home/mediaoffline/mediaofflineproductinfo/" + id);
    } else if (location.pathname?.includes("mediaofflinegolive")) {
      navigate("/home/mediaoffline/mediaofflinetechinfo/" + id);
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "auto",
        bgcolor: "transparent",
      }}
      elevation={0}
    >
      <BreadCrumbHeader
        MainText={
          location.pathname?.includes("media")
            ? "My Media"
            : location.pathname?.includes("Voucher")
            ? "My Voucher"
            : "My Products"
        }
      />
      <ProductProgressBar />
      <Paper
        sx={{
          width: "98.5%",
          height: "auto",
          mx: "auto",
          pb: 3,
          borderRadius: "17px",
          position: "relative",
          minHeight: "90vh",
          pt: 2,
          pl: 2,
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            width: "120px",
            paddingLeft: "10px",
            mt: "10px",
          }}
          onClick={leftarrow}
        >
          <img
            src={GoLeft}
            alt="left"
            width={"22px"}
            height="9px"
            style={{ cursor: "pointer" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent:
              recentProductsData?.length > 0 ? "space-between" : "center",
            alignContent: "center",
            height: "100%",
            minHeight: "auto",
            flexWrap: "wrap",
            bgcolor: "transparent",
            width: "100%",
            mx: "auto",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "grid",
              width: location.pathname?.includes("voucherdesign")
                ? "95%"
                : "70%",
              mx: "auto",
              height: "100%",
              maxWidth: location.pathname?.includes("voucherdesign")
                ? "95%"
                : "70%",
              overflow: "hidden",
              boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
              borderRadius: "5px",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Paper>
    </Paper>
  );
};

export default GeneralInfoParent;
