/* eslint-disable react/prop-types */
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";

const TermsAndConditions = (props) => {
  return (
    <div>
      <Typography sx={termstext} variant="h5" gutterBottom>
        TERMS AND CONDITIONS
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        Please read these terms and conditions carefully before using the
        www.bxiworld.com website. By using the www.bxiworld.com website, you
        signify your agreement to be bound by these conditions. In addition,
        when you use any current or further service of www.bxiworld.com, you
        will be subject to the terms, conditions, rules, and regulations
        applicable to the BXI service.
      </Typography>

      <Typography sx={termstext} variant="h6" gutterBottom>
        1. Your Account
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        If you use the website, you are responsible for maintaining the
        confidentiality of your account, password and for restricting access to
        your computer to prevent unauthorized access to your account. You agree
        to accept responsibility for all activities that occur under your
        account or password. You shall take all necessary steps to ensure that
        the password is kept confidential and secure and should inform us
        immediately if you have any reason to believe that your password has
        become known to anyone else. Please ensure that the details you provide
        us with are correct and complete and inform us immediately of any
        changes to the information that you provided when registering. You
        agreed and acknowledge that you will use your account on the website to
        sell and purchase products only for self-use, corporate gifting,
        internal use of the Company and not for sale to 3rd party.
      </Typography>

      <Typography sx={termstext} variant="h6" gutterBottom>
        2. E-Platform for Communication
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        You agree, understand, and acknowledge that the website is an online
        platform that enables you to purchase products listed on the website at
        the number of Barter Coins indicated therein at any time from any
        location. You further agree and acknowledge that BXI is only a
        facilitator and is not and cannot be a party to or control in any manner
        any transactions on the website.
      </Typography>

      <Typography sx={termstext} variant="h6" gutterBottom>
        3. Access to www.bxiworld.com
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        We will do our utmost to ensure that the availability of the website
        will be uninterrupted and that transmissions will be error-free.
        However, due to the nature of the Internet, this cannot be guaranteed.
        Also, your access to the website may also be occasionally suspended or
        restricted to allow for repairs, maintenance, or the introduction of new
        facilities or services at any time without prior notice. We will attempt
        to limit the frequency and duration of any such suspension or
        restriction.
      </Typography>

      <Typography sx={termstext} variant="h6" gutterBottom>
        4. Privacy
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        Please review our Privacy Notice, which also governs your visit to
        www.bxiworld.com, to understand our practices. The personal
        information/data provided to us by you during the course of usage of
        www.bxiworld.com, will be treated as strictly confidential and in
        accordance with the Privacy Notice and applicable laws and regulations.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        The Privacy Policy identifies and describes the way BXI World LLP
        (“BXI”), uses and protects the information we collect about our Members.
        All uses of BXI’s services and the Platform www.bxiworld.com are subject
        to this Privacy Policy. The Privacy Policy is effective from the current
        date.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        We know that you care how information about you is used and shared, and
        we appreciate your trust that we will do so carefully and sensibly. This
        Privacy Policy describes how BXI Seller Services and its affiliates
        including www.bxiworld.com/ “the Platform”, collect and process your
        personal information through our website, devices, products, services,
        online marketplace/Platform, and applications that refer to this Privacy
        Policy.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        This Privacy Policy is an electronic record in the form of an electronic
        contract formed under the Information Technology Act, 2000 and the rules
        made thereunder and the amended provisions pertaining to electronic
        documents / records in various statutes as amended by the Information
        Technology Act, 2000. This privacy policy does not require any physical,
        electronic or digital signature.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        This document is published and shall be construed in accordance with the
        provisions of the Information Technology (reasonable security practices
        and procedures and sensitive personal data of information) rules, 2011
        under Information Technology Act, 2000; that require publishing of the
        Privacy Policy for collection, use, storage and transfer of sensitive
        personal data or information.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        This Privacy Policy is a legally binding document between you and BXI.
        The terms of this Privacy policy will be effective upon your acceptance
        of the same (directly or indirectly in electronic form, by clicking on
        the "I accept" tab or by use of the Platform or by other means) and will
        govern the relationship between you and BXI for your use of the
        Platform.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        Please read this Privacy policy carefully. By using the Platform, you
        indicate that you understand, agree and consent to this Privacy Policy.
        If you do not agree with the terms of this Privacy Policy, please do not
        use this Platform. You hereby provide your unconditional consent or
        agreements to BXI as provided under section 43a and section 72a of
        Information Technology Act, 2000.
      </Typography>
      <Typography sx={termstext} variant="body1" paragraph>
        By using the Services and the Platform you agree to our use of your
        personal information (including sensitive personal information) in
        accordance with this Privacy Policy, as may be amended from time to time
        by us at our discretion. You also agree and consent to us collecting,
        storing, processing, transferring, and sharing your personal information
        (including sensitive personal information) with third parties or service
        providers for the purposes set out in this Privacy Policy.
      </Typography>

      {/* <Typography sx={termstext}>
        Date: <strong>{props.Date}</strong>
      </Typography>
      <Typography sx={termstext}>
        Time: <strong>{props.Time}</strong>
      </Typography>
      <Typography sx={termstext}>
        Place: <strong>{props.Place}</strong>
      </Typography>
      <FormControlLabel
        control={<Checkbox name="acceptTerms" />}
        label="I agree to all the Terms and Conditions"
      />
      <Button variant="contained" color="primary">
        Accept
      </Button> */}
    </div>
  );
};

export default TermsAndConditions;

const termstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
  margin: "14px 0",
};
