import {
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  Paper,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactIcon from "../../assets/ContactUs/ContactIcon.svg";
import EditIcon from "../../assets/ContactUs/Edit.png";
import EmailIconContactUs from "../../assets/ContactUs/EmailIconContactUs.svg";
import EmailIcon from "../../assets/ContactUs/Envelope.png";
import HomeIcon from "../../assets/ContactUs/HomeIcon.svg";
import UserIcon from "../../assets/ContactUs/User.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ContactUs = () => {
  const navigate = useNavigate();
  const [CategoryData, setCategoryData] = useState([
    {
      CompanyTypeName: "Media",
    },
    {
      CompanyTypeName: "Textile",
    },
    {
      CompanyTypeName: "Lifestyle",
    },
    {
      CompanyTypeName: "Hotel",
    },
    {
      CompanyTypeName: "Mobility",
    },
    {
      CompanyTypeName: "FMCG",
    },
    {
      CompanyTypeName: "Airlines Tickets",
    },
    {
      CompanyTypeName: "Office Supply",
    },
    {
      CompanyTypeName: "Electronics",
    },
    {
      CompanyTypeName: "QSR",
    },
    {
      CompanyTypeName: "Others",
    },
    {
      CompanyTypeName: "Entertainment & Events",
    },
  ]);
  const [formDataStore, setFormDataStore] = useState({
    name: "",
    phone: "",
    email: "",
    designation: "",
    subject: "",
    category: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    email: "",
    category: "",
    message: "",
    // ... initialize other fields
  });

  async function submitForm(e) {
    e.preventDefault();

    // Check for form validation errors
    const isValid = validateForm();

    if (isValid) {
      // Perform form submission
      await axios
        .post(
          `contactus/contact`,
          {
            formdata: formDataStore,
          },
          {
            withCredentials: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              bxiapikey: "Bearer K8sY2jF4pL3rQ1hA9gZ6bX7wC5vU0t",
            },
          }
        )
        .then((res) => {
          if (res?.data === "success") {
            toast.success("Your message has been sent successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
  }

  function validateForm() {
    const errors = {};

    if (!formDataStore.name) {
      errors.name = "Name is required";
    }
    if (!formDataStore.phone) {
      errors.phone = "Phone number is required";
    }
    if (!formDataStore.email) {
      errors.email = "Email is required";
    }
    if (!formDataStore.email.includes("@")) {
      errors.email = "Invalid email format";
    }
    if (!formDataStore.category) {
      errors.category = "Category is required";
    }
    if (!formDataStore.message) {
      errors.message = "Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Reach Out to BXI for Barter Solutions</title>
        <meta
          property="og:title"
          content="Contact Us - Reach Out to BXI for Barter Solutions"
        />
        <meta
          name="og:description"
          content="Contact BXI (Barter Exchange of India) at +91 22-49646776 or visit https://bxiworld.com/ for inquiries. Located at 501, 5th Floor, Meadows Tower, Sahar Plaza Complex, Sir M.V. Road, Andheri (East), Mumbai."
        />
        <meta
          name="description"
          content="Contact BXI (Barter Exchange of India) at +91 22-49646776 or visit https://bxiworld.com/ for inquiries. Located at 501, 5th Floor, Meadows Tower, Sahar Plaza Complex, Sir M.V. Road, Andheri (East), Mumbai."
        />
      </Helmet>
      <Box>
        <Navbar />
      </Box>
      <ToastContainer style={{ fontSize: "16px" }} />

      <Paper
        sx={{ mt: "100px", width: "100%", border: "none", boxShadow: "none" }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "36px",
            color: "#6B7A99",
            textAlign: "center",
            mx: "auto",
          }}
        >
          Contact Us
        </Typography>
      </Paper>
      <Grid container>
        <Paper elevation={0}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Box sx={{ ...ContactBoxOneStyle, mt: "50px" }}>
                <Box
                  sx={{
                    width: "90%",
                    mx: "auto",
                    mb: 5,
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "20px",
                  }}
                >
                  <Box sx={{ width: "90%", mx: "auto", maxWidth: "380px" }}>
                    <InputLabel sx={InputLableText}>
                      Name <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Box sx={InputBox}>
                      <Input
                        disableUnderline
                        placeholder="Please enter your name"
                        sx={PlaceHolderColor}
                        name="name"
                        required
                        onChange={(e) => {
                          setFormDataStore({
                            ...formDataStore,
                            name: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={UserIcon}
                        width={"15px"}
                        height={"15px"}
                        alt="usericon"
                      />
                    </Box>
                    {formErrors.name && (
                      <p style={errorMessageStyle}>{formErrors.name}</p>
                    )}
                  </Box>
                  <Box sx={{ width: "90%", mx: "auto", maxWidth: "380px" }}>
                    <InputLabel sx={InputLableText}>
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Box sx={InputBox}>
                      <Input
                        disableUnderline
                        placeholder="Please enter your phone number"
                        sx={PlaceHolderColor}
                        name="phone"
                        required
                        onChange={(e) => {
                          setFormDataStore({
                            ...formDataStore,
                            phone: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={UserIcon}
                        width={"15px"}
                        height={"15px"}
                        alt="usericon"
                      />
                    </Box>
                    {formErrors.phone && (
                      <p style={errorMessageStyle}>{formErrors.phone}</p>
                    )}
                  </Box>
                  <Box sx={{ width: "90%", mx: "auto", maxWidth: "380px" }}>
                    <InputLabel sx={InputLableText}>
                      Work E-mail <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Box sx={InputBox}>
                      <Input
                        disableUnderline
                        placeholder="Please enter your E-mail"
                        sx={PlaceHolderColor}
                        name="email"
                        required
                        onChange={(e) => {
                          setFormDataStore({
                            ...formDataStore,
                            email: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={EmailIcon}
                        width={"15px"}
                        height={"15px"}
                        alt="emailicon"
                      />
                    </Box>
                    {formErrors.email && (
                      <p style={errorMessageStyle}>{formErrors.email}</p>
                    )}
                  </Box>
                  <Box sx={{ width: "90%", mx: "auto", maxWidth: "380px" }}>
                    <InputLabel sx={InputLableText}>Designation</InputLabel>
                    <Box sx={InputBox}>
                      <Input
                        disableUnderline
                        placeholder="Please enter your Designation"
                        sx={PlaceHolderColor}
                        name="designation"
                        onChange={(e) => {
                          setFormDataStore({
                            ...formDataStore,
                            designation: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={EmailIcon}
                        width={"15px"}
                        height={"15px"}
                        alt="emailicon"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: "90%", mx: "auto", maxWidth: "380px" }}>
                    <InputLabel sx={InputLableText}>Subject</InputLabel>
                    <Box sx={InputBox}>
                      <Input
                        disableUnderline
                        placeholder="Enter you Subject"
                        sx={PlaceHolderColor}
                        name="subject"
                        onChange={(e) => {
                          setFormDataStore({
                            ...formDataStore,
                            subject: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={EditIcon}
                        width={"15px"}
                        height={"15px"}
                        alt="editicon"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: "90%", mx: "auto", maxWidth: "380px" }}>
                    <InputLabel sx={InputLableText}>
                      Category <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    {/* <Box sx={InputBox}> */}
                    <Select
                      type="select"
                      disableUnderline
                      placeholder="Please enter your subject"
                      sx={{
                        width: "100%",
                        height: "36px",
                        mt: "8px",
                        background: "rgba(243, 246, 249, 0.5)",
                      }}
                      name="category"
                      onChange={(e) => {
                        setFormDataStore({
                          ...formDataStore,
                          category: e.target.value,
                        });
                      }}
                    >
                      {CategoryData?.map((item, index) => {
                        return (
                          <MenuItem value={item?.CompanyTypeName} key={index}>
                            <Typography
                              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                            >
                              {" "}
                              {item?.CompanyTypeName}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formErrors.category && (
                      <p style={errorMessageStyle}>{formErrors.category}</p>
                    )}
                    {/* </Box> */}
                  </Box>
                  <Box sx={{ width: "92%", mx: "auto" }}>
                    <InputLabel sx={{ ...InputLableText, ml: 2 }}>
                      Message <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Box
                      sx={{
                        ...InputBox,
                        height: "100px",
                        p: 1,
                        width: "95%",
                        mx: "auto",
                      }}
                    >
                      <textarea
                        disableUnderline
                        style={TextAreaStyle}
                        multiline
                        minRows={3}
                        name="message"
                        onChange={(e) => {
                          setFormDataStore({
                            ...formDataStore,
                            message: e.target.value,
                          });
                        }}
                      />
                      <img
                        src={EditIcon}
                        width={"15px"}
                        height={"15px"}
                        alt="editicon"
                      />
                    </Box>
                    {formErrors.message && (
                      <p style={{ ...errorMessageStyle, marginLeft: "15px" }}>
                        {formErrors.message}
                      </p>
                    )}
                  </Box>

                  {/* Display error messages */}

                  {/* ... display other error messages */}
                  <Box
                    sx={{
                      width: "89%",
                      mx: "auto",
                      mt: 0,
                      pb: {
                        xl: "none",
                        lg: "none",
                        md: "none",
                        sm: "10%",
                        xs: "10%",
                      },
                    }}
                  >
                    <Button sx={ButtonStyle} onClick={submitForm}>
                      SUBMIT
                    </Button>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={TitleBottomtext}>
                        We would love to hear from you. We're just a text/call
                        away.<br></br> We are looking forward to Barter with
                        you!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "8%",
              }}
            >
              <Box sx={{ width: "100%", mx: "auto" }}>
                <Box sx={ContactInfoBoxStyle}>
                  <Box>
                    <Typography sx={ContactInformationTitle}>
                      Contact information
                    </Typography>
                  </Box>
                  <Box sx={ContactInformationBox}>
                    <img src={HomeIcon} alt="homeicon" />
                    <Typography sx={ContactInfoInnerText}>
                      BXI (Barter Exchange of India) 501- 5th Floor Meadows
                      Tower, Sahar Plaza Complex, Sir M.V. Road, Next to
                      Kohinoor Continental Hotel, Andheri (East), Mumbai 400059.
                    </Typography>
                  </Box>
                  <Box sx={ContactInformationBox}>
                    <img src={ContactIcon} alt="contacticon" />
                    <Typography sx={ContactInfoInnerText}>
                      +91 22-49646776
                    </Typography>
                  </Box>
                  <Box sx={ContactInformationBox}>
                    <img src={ContactIcon} alt="contacticon" />
                    <Typography sx={ContactInfoInnerText}>
                      +91 88283 26776
                    </Typography>
                  </Box>
                  <Box sx={ContactInformationBox}>
                    <WhatsAppIcon
                      sx={{
                        fontSize: "23px",
                        color: "#6b7a99",
                        mt: 0.8,
                        ml: -0.3,
                      }}
                    />
                    <Typography sx={{ ...ContactInfoInnerText, ml: -0.5 }}>
                      +91 9136436776
                    </Typography>
                  </Box>
                  <Box sx={ContactInformationBox}>
                    <img src={EmailIconContactUs} alt="emailicon" />
                    <Link to="/" style={{ color: "#778DA9" }}>
                      <Typography sx={ContactInfoInnerText}>
                        business@bxiworld.com
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;

const errorMessageStyle = {
  color: "red",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,

  lineHeight: "10px",
};

const TextAreaStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  height: "auto",
  width: "100%",
  minHeight: "90px",
  background: "transparent",
  border: "none",
  marginLeft: "auto",
  marginRight: "auto",
  outline: "none",
  outlineColor: "transparent",
  "&:focus": {
    outline: "none !important",
    border: "none !important",
  },
};

const ContactBoxOneStyle = {
  width: "90%",
  height: "auto",
  background: "#FFFFFF",
  borderRadius: "10px",
  zIndex: 10,
  display: "block",
  justifyContent: "space-evenly",
  mx: "auto",
};

const InputBox = {
  width: "auto",
  height: "40px",
  background: "rgba(243, 246, 249, 0.5)",
  borderRadius: "3px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  pl: 3,
  pr: 1,
  mt: 1,
};

const PlaceHolderColor = {
  width: "100%",
  maxWidth: "380px",
  height: "100%",
  bgcolor: "transparent",
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  // color: " rgba(107, 122, 153, 0.2)",
  // color: "cadetblue"
};

const InputLableText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  color: "#6B7A99",
};

const TitleBottomtext = {
  pt: "3%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#8A8A8A",
  mx: "auto",
};

const ButtonStyle = {
  width: "100%",
  height: "36px",
  // maxWidth: "380px",
  background: "#445FD2",
  borderRadius: "3px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  mx: "auto",
  "&:hover": {
    background: "#C3CAD9",
  },
};

const ContactInfoBoxStyle = {
  py: 5,
  mt: 2,
  width: "100%",
};

const ContactInformationBox = {
  display: "flex",
  gap: {
    xl: "2%",
    lg: "3%",
    md: "4%",
    sm: "5%",
    xs: "6%",
  },
};
const ContactInfoInnerText = {
  width: "100%",
  flexdirection: "row",
  py: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  display: "flex",
  alignItems: "center",
  color: "#778DA9",
  mr: {
    xl: "4rem",
    lg: "4rem",
    md: "3rem",
    sm: "1rem",
    xs: "0",
  },
};

const ContactInformationTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "24px",
    lg: "24px",
    md: "24px",
    sm: "20px",
    xs: "18px",
  },
  display: "flex",
  alignItems: "center",
  color: "#6B7A99",
};
