import { Box, Button, Grid, Typography, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbHeader from "./BreadCrumbHeader";
import { allNotification } from "../../redux/action/Notification/getNotifications";
import { useSelector, useDispatch } from "react-redux";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import Avatargenerator from "../AvatarGenerator";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import sendEvents from "../../utils/sendEvents.js";
import LeftArrowIcon from "../../assets/Images/CommonImages/GoLeft.png";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser.js";

const FullscreenNotification = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { data: loggedInUserData } = useGetLoggedInUser();
  const { getNotification } = useSelector((state) => state.allNotifiactions);
  const { data: AuthUserData } = useGetAuthUser();

  const [active, setActive] = useState(
    AuthUserData?.data?.rightsarray?.includes("finance") ||
      AuthUserData?.data?.superAdmin
      ? "btn1"
      : "btn2"
  );
  const [btn1, setBtn1] = useState(active === "btn1");
  const [btn2, setBtn2] = useState(active === "btn2");
  const [btn3, setBtn3] = useState(active === "btn3");
  const [btn4, setBtn4] = useState(active === "btn4");
  const [bxi, setBxi] = useState([]);
  const [seller, setSeller] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [readMessage, setReadMessage] = useState(false);
  const [totalCountBXIArray, SetTotalCountBXIArray] = useState([]);
  const [totalCountBuyerArray, setTotalCountBuyerArray] = useState([]);
  const [totalCountSellerArray, setTotalCountSellerArray] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btns, setBtns] = useState([]);
  const [incrementAll, setIncrementAll] = useState(false);
  const [incrementBxi, setIncrementBxi] = useState(false);
  const [incrementBuy, setIncrementBuy] = useState(false);
  const [incrementSeller, setIncrementSeller] = useState(false);
  const [unReadCountOfAll, setUnReadCountOfAll] = useState(null);
  const [unReadCountOfBxi, setUnReadCountOfBxi] = useState(null);
  const [unReadCountOfBuyer, setUnReadCountOfBuyer] = useState(null);
  const [unReadCountOfSeller, setUnReadCountOfSeller] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  let login_User = loggedInUserData?.data?._id;
  useEffect(() => {
    if (login_User !== null) {
      dispatch(allNotification(login_User));
    }
  }, [dispatch, login_User, readMessage]);

  const filterUniqueMessages = (
    messagesWithCount,
    totalCountArray,
    setTotalCountArray
  ) => {
    const uniqueMessages = messagesWithCount.filter(
      (message) => !totalCountArray.some((m) => m._id === message._id)
    );

    setTotalCountArray((prevArray) => {
      const updatedArray = [...prevArray];

      uniqueMessages.forEach((message) => {
        if (!updatedArray.some((m) => m._id === message._id)) {
          updatedArray.push(message);
        }
      });

      return updatedArray;
    });
  };

  useEffect(() => {
    if (getNotification !== null) {
      setNotifications(getNotification?.notifications);

      if (getNotification?.notifications) {
        const AllCount = getNotification.notifications.filter(
          (item) => item.read === false
        );
        setUnReadCountOfAll(AllCount.length);

        const messagesWithCountSeller = getNotification.notifications.filter(
          (item) => {
            return (
              item.message.includes("messages") ||
              item.message.includes("contacted") ||
              item.message.includes("product") ||
              item.message.includes("PI")
            );
          }
        );
        filterUniqueMessages(
          messagesWithCountSeller,
          totalCountSellerArray,
          setTotalCountSellerArray
        );
        const SellerCount = messagesWithCountSeller.filter(
          (item) => item.read === false
        );
        setUnReadCountOfSeller(SellerCount.length);

        const messagesWithCountBuyer = getNotification.notifications.filter(
          (item) => {
            return (
              item.message.includes("confirmed") ||
              item.message.includes("rejected") ||
              item.message.includes("Order")
            );
          }
        );
        filterUniqueMessages(
          messagesWithCountBuyer,
          totalCountBuyerArray,
          setTotalCountBuyerArray
        );
        const BuyerCount = messagesWithCountBuyer.filter(
          (item) => item.read === false
        );
        setUnReadCountOfBuyer(BuyerCount.length);

        const messagesWithCountBXI = getNotification.notifications.filter(
          (item) => item.message.includes("BXI")
        );
        filterUniqueMessages(
          messagesWithCountBXI,
          totalCountBXIArray,
          SetTotalCountBXIArray
        );
        const BxiCount = messagesWithCountBXI.filter(
          (item) => item.read === false
        );
        setUnReadCountOfBxi(BxiCount.length);
      }
    }
  }, [getNotification]);

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
  } = useGetLoggedInUser();

  useEffect(() => {
    if (getNotification) {
      const messagesWithCountBXI =
        getNotification.notifications?.filter((item) =>
          item.message.includes("BXI")
        ) ?? [];
      SetTotalCountBXIArray(messagesWithCountBXI);

      const messagesWithCountBuyer =
        getNotification.notifications?.filter(
          (item) =>
            item.message.includes("confirmed") ||
            item.message.includes("rejected") ||
            item.message.includes("Order")
        ) ?? [];
      setTotalCountBuyerArray(messagesWithCountBuyer);

      const messagesWithCountSeller =
        getNotification.notifications?.filter(
          (item) =>
            item.message.includes("messages") ||
            item.message.includes("contacted") ||
            item.message.includes("product") ||
            item.message.includes("PI")
        ) ?? [];
      setTotalCountSellerArray(messagesWithCountSeller);
    }
  }, [getNotification]);

  const UpdateNotifications = async (data) => {
    let status = null;

    if (data?.read === false) {
      status = true;
    } else if (data?.read === true) {
      status = false;
    }

    await axios
      .put(
        `notification/updatenotification/${data._id}`,
        { read: status },
        { withCredentials: true }
      )
      .then(() => {
        // Dispatch the allNotification action to fetch the updated notification data
        dispatch(allNotification(login_User));
      })
      .catch((err) => {
        // Handle error if the update fails
      });
  };

  const DeleteNotifications = async (id) => {
    await axios
      .post(
        `notification/deletenotification/${id}`,
        { read: true },
        { withCredentials: true }
      )
      .then((res) => {
        dispatch(allNotification(login_User));
      })
      .catch((err) => {});
    dispatch(allNotification(login_User));
  };

  const handleClickRedirect = (message) => {
    if (message.includes("messages")) {
      navigate("/home/message");
    } else if (message.includes("contacted")) {
      navigate("/home/message");
    } else if (
      message?.includes("approved by our team") ||
      message?.includes(
        "Attention! You have successfully uploaded your product"
      ) ||
      message?.includes("disapproved by our team")
    ) {
      const productIdRegex = /productId\s*:\s*(\w+)/;
      const match = message.match(productIdRegex);

      let productId = null;
      if (match && match[1]) {
        productId = match[1];
      }

      let productTypeMatch = message.match(/productType:(\w+)/);

      let productType;
      if (productTypeMatch) {
        productType = productTypeMatch[1];
      }

      if (productId && productType === "Product") {
        navigate(`/home/productdetail/${productId}`);
      } else if (productId && productType === "Media") {
        navigate(`/home/mediabuying/${productId}`);
      } else if (productId && productType === "Voucher") {
        navigate(`/home/voucherdetail/${productId}`);
      }
    } else if (message.includes("rejected")) {
      navigate("/home/purchaseorderlist", {
        state: {
          tab: "Sold",
        },
      });
    } else if (message.includes("has confirmed delivery")) {
      navigate("/home/order_tracking");
    } else if (
      message.includes("better benefits!") ||
      message.includes("Profile now to")
    ) {
      navigate("/home/planpricing");
    } else if (message.includes("PI generated by you has been confirmed")) {
      navigate("/home/sellerpilist");
    } else if (message.includes("successfully uploaded your product")) {
      navigate("/home/sellerhub");
    } else if (
      message.includes("E-invoice for order") ||
      message.includes(" for E-invoice of you recent order")
    ) {
      navigate("/home/myorderlist");
    } else if (
      message.includes("has generated PI for Order Id") ||
      message.includes("Proforma Invoice") ||
      message.includes("Attention! PI for the order id") ||
      message.includes("Proforma Invoice (PI) for Order id") ||
      message.includes("has been accepted by the Buyer and payment is done.") ||
      message.includes(
        "Attention! Your Proforma Invoice has been rejected by the Buyer."
      )
    ) {
      const productIdRegex = /piid\s*:\s*(\w+)/;
      const match = message.match(productIdRegex);

      let piId = null;
      if (match && match[1]) {
        piId = match[1];
      }

      if (piId) {
        navigate(`/home/performainvoice/${piId}`);
      }
    } else if (
      message.includes("item") ||
      message.includes("shipment") ||
      message.includes("picked") ||
      message.includes("delivery") ||
      message.includes("AWB")
    ) {
      navigate("/home/trackorders");
    } else if (message.includes("Price And Quantity")) {
      navigate("/home/sellerhub");
    } else if (message.includes("Debited") || message.includes("recovered")) {
      navigate("/home/dashboard");
    } else if (message.includes("credit") || message.includes("Credit")) {
      navigate("/home/creditstatus");
    } else if (message.includes("Credited") || message.includes("Balance")) {
      navigate("/home/dashboard");
    } else if (message.includes("delivered")) {
      navigate("/home/myorderlist");
    } else if (message.includes("reviewed & approved by our team.")) {
      navigate("/home/sellerhub");
    } else if (message.includes("Complete your Profile")) {
      navigate("/home/profile");
    } else if (
      message.includes("PO for your product has been generated") ||
      message.includes("Attention! PO for the order id") ||
      message.includes("Purchase Order (PO) for Order Id") ||
      message.includes("has been partially accepted by the")
    ) {
      const productIdRegex = /poid\s*:\s*(\w+)/;
      const match = message.match(productIdRegex);

      let poId = null;
      if (match && match[1]) {
        poId = match[1];
      }

      if (poId) {
        navigate(`/home/sellerdetailedordersummary/${poId}`);
      }
    } else if (
      message?.includes(
        "about to expire within 28 days and it will be delisted soon"
      ) ||
      message?.includes("is about to be delisted in 14 days")
    ) {
      navigate("/home/sellerhub", {
        state: { tab: "Delist" },
      });
    } else if (
      message.includes(
        "Attention! We have suggested some changes to your product, uploaded on BXI Marketplace."
      )
    ) {
      navigate("/home/sellerhub", {
        state: {
          tab: "Admin Review",
        },
      });
    } else if (message?.includes("Action Required! Uploading of product")) {
      navigate("/home/sellerhub", {
        state: {
          tab: "In Draft",
        },
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const totalPages = Math.ceil(btns?.length / itemsPerPage);

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return btns?.slice(startIndex, endIndex);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setBtn1(active === "btn1");
    setBtn2(active === "btn2");
    setBtn3(active === "btn3");
    setBtn4(active === "btn4");
  }, [active]);

  useEffect(() => {
    let activeBtnArray = [];

    if (btn1) {
      activeBtnArray = notifications;
    } else if (btn2) {
      activeBtnArray = totalCountBXIArray;
    } else if (btn3) {
      activeBtnArray = totalCountSellerArray;
    } else if (btn4) {
      activeBtnArray = totalCountBuyerArray;
    }

    setBtns(activeBtnArray);
    setCurrentPage(1);
  }, [btn1, btn2, btn3, btn4, notifications]);

  return (
    <>
      <BreadCrumbHeader MainText="Notifications" showbreadcrumb={true} />

      <Grid
        container
        sx={{
          background: "#fff",
          width: "100%",
          mx: "auto",
          borderRadius: "17px",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mx: "auto", width: "95%", height: "100%", p: 2 }}
        >
          <Box
            sx={{
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              component="img"
              src={LeftArrowIcon}
              sx={{ width: "25px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />

            <Box sx={ButtonGroupStyle}>
              {AuthUserData?.data?.rightsarray?.includes("finance") ||
              AuthUserData?.data?.superAdmin ? (
                <Button
                  sx={{
                    ...ButtonStyle,
                    borderColor: active === "btn1" ? "#445FD2" : "#E6E9EE",
                    color: active === "btn1" ? "#445FD2" : "#929EAE",
                  }}
                  id="btn1"
                  onClick={() => {
                    setBtn1(true);
                    setBtn2(false);
                    setActive("btn1");
                    sendEvents("Click on all button");
                  }}
                >
                  All
                  <Box sx={notificationcount}>
                    {incrementAll === true
                      ? unReadCountOfAll + 1
                      : unReadCountOfAll}
                  </Box>
                </Button>
              ) : null}

              <Button
                sx={{
                  ...ButtonStyle,
                  borderColor: active === "btn2" ? "#445FD2" : "#E6E9EE",
                  color: active === "btn2" ? "#445FD2" : "#929EAE",
                }}
                id="btn2"
                onClick={() => {
                  setBtn1(false);
                  setBtn2(true);
                  setBtn3(false);
                  setBtn4(false);
                  setActive("btn2");
                  sendEvents("Click on BXI button");
                }}
              >
                BXI
                <Box sx={notificationcount}>
                  {incrementBxi === true
                    ? unReadCountOfBxi + 1
                    : unReadCountOfBxi}
                </Box>
              </Button>

              {AuthUserData?.data?.rightsarray?.includes("purchase") ||
              AuthUserData?.data?.rightsarray?.includes("finance") ||
              AuthUserData?.data?.superAdmin ? (
                <Button
                  sx={{
                    ...ButtonStyle,
                    borderColor: active === "btn3" ? "#445FD2" : "#E6E9EE",
                    color: active === "btn3" ? "#445FD2" : "#929EAE",
                  }}
                  id="btn3"
                  onClick={() => {
                    setBtn3(true);
                    setBtn4(false);
                    setBtn2(false);
                    setBtn1(false);
                    setActive("btn3");
                    sendEvents("Click on Purchase button");
                  }}
                >
                  Purchase
                  <Box sx={notificationcount}>
                    {incrementBuy === true
                      ? unReadCountOfBuyer + 1
                      : unReadCountOfBuyer}
                  </Box>
                </Button>
              ) : null}
              {AuthUserData?.data?.rightsarray?.includes("sales") ||
              AuthUserData?.data?.rightsarray?.includes("finance") ||
              AuthUserData?.data?.superAdmin ? (
                <Button
                  sx={{
                    ...ButtonStyle,
                    borderColor: active === "btn4" ? "#445FD2" : "#E6E9EE",
                    color: active === "btn4" ? "#445FD2" : "#929EAE",
                  }}
                  id="btn4"
                  onClick={() => {
                    setBtn3(false);
                    setBtn4(true);
                    setBtn2(false);
                    setBtn1(false);
                    setActive("btn4");
                    sendEvents("Click on sell button");
                  }}
                >
                  Sell
                  <Box sx={notificationcount}>
                    {" "}
                    {incrementSeller === true
                      ? unReadCountOfSeller + 1
                      : unReadCountOfSeller}
                  </Box>
                </Button>
              ) : null}
            </Box>
          </Box>

          <div>
            {btn1 && (
              <Box sx={{ mt: 1, mb: 1 }}>
                {getPaginatedData()?.length > 0 ? (
                  getPaginatedData()?.map((notification, idx) => {
                    let messages = notification.message;
                    let maskedMessage = messages.replace(
                      /productId:.*|poid:.*|piid:.*/g,
                      ""
                    );
                    return (
                      <>
                        {loading ? (
                          <Box sx={{ width: "95%", mx: "auto" }}>
                            <Skeleton
                              variant="rectangular"
                              height={100}
                              animation="wave"
                              sx={{
                                borderRadius: "10px",
                                mt: 1,
                                width: "100%",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            key={idx}
                            sx={{
                              width: "95%",
                              mx: "auto",
                              minHeight: "75px",
                              background: "#FBFBFB",
                              borderRadius: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "auto",
                              mt: 1,
                              gap: "5px",
                            }}
                          >
                            {notification.read === true ? (
                              <Box
                                sx={{
                                  height: "10px",
                                  width: "10px",
                                  borderRadius: "7px",
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  height: "10px",
                                  width: "10px",
                                  background: "#445FD2",
                                  borderRadius: "7px",
                                }}
                              />
                            )}
                            <Box
                              sx={{
                                width: "97%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Avatargenerator
                                companyname={userData?.data?.companyName}
                                AvatarUrl={userData?.data?.CompanyAvatar?.url}
                              />
                              <Box
                                sx={{
                                  textAlign: "left",
                                  width: "85%",
                                  px: 2,
                                  // bgcolor: "red",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...NameOfNotification,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {notification.type}
                                </Typography>

                                <Typography
                                  style={{ cursor: "pointer" }}
                                  sx={{
                                    ...NameOfNotification,
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    color: "#ADB8CC",
                                  }}
                                  onClick={() => {
                                    handleClickRedirect(notification.message);
                                    sendEvents("Click on message");
                                  }}
                                >
                                  {maskedMessage}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "15%",
                                  // bgcolor: "green",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: "5px",
                                }}
                              >
                                {/* {notification.read === true ? null : ( */}
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    UpdateNotifications(notification);
                                    sendEvents(
                                      "Click on mark as read or unread"
                                    );
                                  }}
                                  sx={{
                                    color: "#445FD2",
                                    border: "none",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    textTransform: "none",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                    "&:hover": {
                                      border: "none",
                                    },
                                  }}
                                >
                                  {notification.read === false
                                    ? "Mark as Read"
                                    : "Mark as Unread"}
                                </Button>
                                {/* )} */}
                                <CancelIcon
                                  key={notification.id}
                                  onClick={() => {
                                    DeleteNotifications(notification?._id);
                                    sendEvents("Click on cancel");
                                  }}
                                  sx={{
                                    color: "#d9d9d9",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    mt: 0.5,
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "500px",
                    }}
                  >
                    <Nodatafound />
                  </div>
                )}
              </Box>
            )}
            {btn2 && (
              <Box sx={{ mt: 1, mb: 1 }}>
                {/* <Box sx={NotificationWholeData}> */}
                {getPaginatedData()?.length ? (
                  getPaginatedData()?.map((bxi) => {
                    let messages = bxi.message;
                    let maskedMessage = messages.replace(
                      /productId:.*|poid:.*|piid:.*/g,
                      ""
                    );
                    return loading ? (
                      <Box sx={{ width: "95%", mx: "auto" }}>
                        <Skeleton
                          variant="rectangular"
                          height={100}
                          animation="wave"
                          sx={{ borderRadius: "10px", mt: 1, width: "100%" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "95%",
                          mx: "auto",
                          minHeight: "75px",
                          background: "#FBFBFB",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "auto",
                          mt: 1,
                          gap: "5px",
                        }}
                      >
                        {bxi.read === true ? (
                          <Box
                            sx={{
                              height: "10px",
                              width: "10px",
                              borderRadius: "7px",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              height: "10px",
                              width: "10px",
                              background: "#445FD2",
                              borderRadius: "7px",
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            width: "97%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Avatargenerator
                            companyname={userData?.data?.companyName}
                            AvatarUrl={userData?.data?.CompanyAvatar?.url}
                          />
                          <Box
                            sx={{
                              textAlign: "left",
                              width: "85%",
                              px: 2,
                              // bgcolor: "red",
                            }}
                          >
                            <Typography
                              sx={{
                                ...NameOfNotification,
                                textTransform: "capitalize",
                              }}
                            >
                              {bxi.type}
                            </Typography>

                            <Typography
                              style={{ cursor: "pointer" }}
                              sx={{
                                ...NameOfNotification,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "17px",

                                color: "#ADB8CC",
                              }}
                              onClick={() => {
                                handleClickRedirect(bxi.message);
                                sendEvents("Click on BXI message");
                              }}
                            >
                              {maskedMessage}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "15%",
                              // bgcolor: "green",
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: "5px",
                            }}
                          >
                            {/* {bxi.read === true ? null : ( */}
                            <Button
                              variant="outlined"
                              onClick={() => {
                                // UpdateNotifications(bxi?._id);
                                UpdateNotifications(bxi);
                                sendEvents("Click on update notifications");
                              }}
                              sx={{
                                color: "#445FD2",
                                border: "none",
                                textTransform: "none",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "18px",
                                "&:hover": {
                                  border: "none",
                                },
                              }}
                            >
                              {bxi.read === false
                                ? "Mark as Read"
                                : "Mark as Unread"}
                            </Button>
                            {/* )} */}
                            <CancelIcon
                              key={bxi.id}
                              onClick={() => {
                                DeleteNotifications(bxi?._id);
                                sendEvents("Click on delete notification");
                              }}
                              sx={{
                                color: "#d9d9d9",
                                fontSize: "20px",
                                mt: 0.5,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "500px",
                    }}
                  >
                    <Nodatafound />
                  </div>
                )}
                {/* </Box> */}
              </Box>
            )}
            {btn3 && (
              <Box sx={{ mt: 1, mb: 1 }}>
                {getPaginatedData()?.length ? (
                  getPaginatedData()?.map((seller) => {
                    let messages = seller?.message;
                    let maskedMessage = messages.replace(
                      /productId:.*|poid:.*|piid:.*/g,
                      ""
                    );
                    return loading ? (
                      <Box sx={{ width: "95%", mx: "auto" }}>
                        <Skeleton
                          variant="rectangular"
                          height={100}
                          animation="wave"
                          sx={{ borderRadius: "10px", mt: 1, width: "100%" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "95%",
                          mx: "auto",
                          minHeight: "75px",
                          background: "#FBFBFB",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "auto",
                          mt: 1,
                          gap: "5px",
                        }}
                      >
                        {seller.read === true ? (
                          <Box
                            sx={{
                              height: "10px",
                              width: "10px",
                              borderRadius: "7px",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              height: "10px",
                              width: "10px",
                              background: "#445FD2",
                              borderRadius: "7px",
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            width: "97%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Avatargenerator
                            companyname={userData?.data?.companyName}
                            AvatarUrl={userData?.data?.CompanyAvatar?.url}
                          />
                          <Box
                            sx={{
                              textAlign: "left",
                              width: "85%",
                              px: 2,
                              // bgcolor: "red",
                            }}
                          >
                            <Typography
                              sx={{
                                ...NameOfNotification,
                                textTransform: "capitalize",
                              }}
                            >
                              {seller.type}
                            </Typography>

                            <Typography
                              style={{ cursor: "pointer" }}
                              sx={{
                                ...NameOfNotification,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#ADB8CC",
                              }}
                              onClick={() => {
                                handleClickRedirect(seller.message);
                                sendEvents("Click on seller message");
                              }}
                            >
                              {maskedMessage}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "15%",
                              // bgcolor: "green",
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: "5px",
                            }}
                          >
                            {/* {seller.read === true ? null : ( */}
                            <Button
                              variant="outlined"
                              onClick={() => {
                                // UpdateNotifications(seller?._id);
                                UpdateNotifications(seller);
                                sendEvents("Click on update notifications");
                              }}
                              sx={{
                                color: "#445FD2",
                                border: "none",
                                textTransform: "none",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "18px",
                                "&:hover": {
                                  border: "none",
                                },
                              }}
                            >
                              {seller.read === false
                                ? "Mark as Read"
                                : "Mark as Unread"}
                            </Button>
                            {/* )} */}
                            <CancelIcon
                              key={seller.id}
                              onClick={() => {
                                DeleteNotifications(seller?._id);
                                sendEvents("Click on delete notification");
                              }}
                              sx={{
                                color: "#d9d9d9",
                                fontSize: "20px",
                                mt: 0.5,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "500px",
                    }}
                  >
                    <Nodatafound />
                  </div>
                )}
              </Box>
            )}
            {btn4 && (
              <Box sx={{ mt: 1, mb: 1 }}>
                {getPaginatedData()?.length ? (
                  getPaginatedData()?.map((buyer) => {
                    let messages = buyer.message;
                    let maskedMessage = messages.replace(
                      /productId:.*|poid:.*|piid:.*/g,
                      ""
                    );
                    return loading ? (
                      <Box sx={{ width: "95%", mx: "auto" }}>
                        <Skeleton
                          variant="rectangular"
                          height={100}
                          animation="wave"
                          sx={{ borderRadius: "10px", mt: 1, width: "100%" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "95%",
                          mx: "auto",
                          minHeight: "75px",
                          background: "#FBFBFB",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "auto",
                          mt: 1,
                          gap: "5px",
                        }}
                      >
                        {buyer.read === true ? (
                          <Box
                            sx={{
                              height: "10px",
                              width: "10px",
                              borderRadius: "7px",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              height: "10px",
                              width: "10px",
                              background: "#445FD2",
                              borderRadius: "7px",
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            width: "97%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Avatargenerator
                            companyname={userData?.data?.companyName}
                            AvatarUrl={userData?.data?.CompanyAvatar?.url}
                          />
                          <Box
                            sx={{
                              textAlign: "left",
                              width: "85%",
                              px: 2,
                              // bgcolor: "red",
                            }}
                          >
                            <Typography
                              sx={{
                                ...NameOfNotification,
                                textTransform: "capitalize",
                              }}
                            >
                              {buyer.type}
                            </Typography>

                            <Typography
                              style={{ cursor: "pointer" }}
                              sx={{
                                ...NameOfNotification,
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "17px",

                                color: "#ADB8CC",
                              }}
                              onClick={() => {
                                handleClickRedirect(buyer.message);
                                sendEvents("Click on redirect");
                              }}
                            >
                              {maskedMessage}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "15%",
                              // bgcolor: "green",
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: "5px",
                            }}
                          >
                            {/* {buyer.read === true ? null : ( */}
                            <Button
                              variant="outlined"
                              onClick={() => {
                                // UpdateNotifications(buyer?._id);
                                UpdateNotifications(buyer);
                                sendEvents("Click on update notifications");
                              }}
                              sx={{
                                color: "#445FD2",
                                border: "none",
                                textTransform: "none",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "18px",
                                "&:hover": {
                                  border: "none",
                                },
                              }}
                            >
                              {buyer.read === false
                                ? "Mark as Read"
                                : "Mark as Unread"}
                            </Button>
                            {/* )} */}
                            <CancelIcon
                              key={buyer.id}
                              onClick={() => {
                                DeleteNotifications(buyer?._id);
                                sendEvents("Click on delete notification");
                              }}
                              sx={{
                                color: "#d9d9d9",
                                fontSize: "20px",
                                mt: 0.5,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "500px",
                    }}
                  >
                    <Nodatafound />
                  </div>
                )}
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
      {getPaginatedData()?.length > 0 &&
      getPaginatedData()?.length > 0 &&
      getPaginatedData()?.length > 0 &&
      getPaginatedData()?.length > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
          <Stack spacing={2}>
            <StyledPagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Box>
      ) : null}
    </>
  );
};

export default FullscreenNotification;

const ButtonGroupStyle = {
  width: "100%",
  maxWidth: "700px",
  height: "auto",
  textAlign: "center",
  mx: "auto",
  display: "flex",
  justifyContent: "space-evenly",
  // py: 2,
  mt: "-10px",
};

const ButtonStyle = {
  border: "1px solid red",
  borderRadius: "20px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  width: "20%",
  height: "40%",
  color: "#929EAE",
  textTransform: "initial",
  //   borderColor: "#E6E9EE",
  "&:hover": {
    borderColor: "#445FD2",
    color: "#445FD2",
  },
};

const NotificationInnerData = {
  display: "grid",
  textAlign: "left",
  px: 2,
};

const NameOfNotification = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "25px",

  color: "#6B7A99",
};

const notificationcount = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "14px",
  height: "14px",
  border: "0.5px solid",
  borderRadius: "15px",
  p: "3px",
  ml: "5px",
};
