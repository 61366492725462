import { Box, Typography } from "@mui/material";
import React from "react";
import BXITokenIcon from "../../assets/Stack of Coins.svg";
import CommaSeparator from "../../components/CommaSeprator";

const DiscountPrice = ({ regularPrice, discountPrice }) => {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const formattedDiscountPercent = discountPercent.toFixed(2);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
          height: "auto",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "13px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#DC3737",
            mt: "2px",
          }}
        >
          {formattedDiscountPercent > 0 && (
            <div>-{formattedDiscountPercent}%</div>
          )}{" "}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#2261A2",
            minWidth: "100px",
            display: "flex",
            gap: "5px",
            ml: "1px",
          }}
        >
          &nbsp;{discountPrice}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          //   lineHeight: "36px",
          letterSpacing: "0.06em",
          textTransform: "capitalize",
          color: "#6B7A99",
          textDecoration: "line-through",
        }}
      >
        {formattedDiscountPercent > 0 && (
          <div>
            MRP: <CommaSeparator Price={regularPrice} />
          </div>
        )}
      </Typography>
    </div>
  );
};

export default DiscountPrice;
