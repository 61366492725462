import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import Terms from "../../views/TermsAndConditions";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | BXI</title>
        <meta property="og:title" content="Terms & Conditions | BXI" />
        <meta
          name="og:description"
          content="BXI's terms and conditions, guide you through our policies and guidelines. Learn to navigate our platform responsibly for optimal bartering."
        />
        <meta
          name="description"
          content="BXI's terms and conditions, guide you through our policies and guidelines. Learn to navigate our platform responsibly for optimal bartering."
        />
      </Helmet>
      <Box>
        <Navbar />
      </Box>
      <ToastContainer style={{ fontSize: "16px" }} />

      <Paper
        sx={{ mt: "100px", width: "100%", border: "none", boxShadow: "none" }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: {
              xl: "26px",
              lg: "26px",
              md: "26px",
              sm: "20px",
              xs: "20px",
            },
            lineHeight: {
              xl: "36px",
              lg: "36px",
              md: "36px",
              sm: "30px",
              xs: "30px",
            },
            color: "#6B7A99",
            textAlign: "center",
            mx: "auto",
          }}
        >
          Terms and Conditions{" "}
        </Typography>
      </Paper>

      <Paper elevation={0}>
        <Box sx={{ ...ContactBoxOneStyle, mt: "25px" }}>
          <Terms />
        </Box>
      </Paper>

      <Footer />
    </React.Fragment>
  );
};

export default TermsAndConditions;

const ContactBoxOneStyle = {
  width: "90%",
  height: "auto",
  background: "#FFFFFF",
  fontFamily: "Poppins",
  borderRadius: "10px",
  zIndex: 10,
  display: "block",
  justifyContent: "space-evenly",
  mx: "auto",
};

const InputLableText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  color: "#000000",
};
const ParaText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "13px", lg: "13px", md: "10px", sm: "10px", xs: "10px" },
  lineHeight: "21px",
  display: "block",
  alignItems: "center",
  color: "#6B7A99",
};

const InformationTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "14px", xs: "14px" },
  display: "flex",

  color: "#000000",
  width: "100%",
  textAlign: "center",
  justifyContent: "center",
};
