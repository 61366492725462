import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import BannerCarousel from "../Carousel/BannerCarousel";

const ImagePoster = () => {
  const [BannerImage, SetBannerImage] = useState();

  const GetBanner = async () => {
    try {
      const response = await axios.get("/AdminCompanyDetails/GetBannerImage", {
        withCredentials: true,
      });
      SetBannerImage(response?.data?.ImageUrl);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetBanner();
  }, []);

  return (
    <Paper sx={PosterStyle} elevation={0}>
      <BannerCarousel ImageDataArray={BannerImage} />
    </Paper>
  );
};

export default ImagePoster;

const PosterStyle = {
  width: "97%",
  mx: "auto",
  height: {
    xl: "270px",
    lg: "270px",
    md: "260px",
    sm: "140px",
    xs: "120px",
  },
  boxShadow: "0px",

  borderRadius: "12px",
  position: "relative",
  background: "transparent",
  my: 1.5,
  zIndex: 0,
};
