// use query to get company data

import { useQuery } from "react-query";
import axios from "axios";

const useGetCompanyFullData = () => {
  const getCompanyFullData = async () => {
    const { data } = await axios.get(`/api/v1/onboard-company`);
    return data;
  };

  return useQuery(["company"], getCompanyFullData);
};

export default useGetCompanyFullData;
