import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const SampleDetails = (props) => {
  return (
    <Box>
      <Typography
        sx={{
          ...product,
          fontSize: "16px",
          fontFamily: "Poppins",
          fontWeight: 600,
          color: "#156DB6",
        }}
      >
        Sample Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              ...available,
              fontWeight: 400,
              mt: 0.5,
              fontSize: "16px",
            }}
          >
            Sample Available :&nbsp;
          </Typography>
          {props?.samplestate ? (
            <Typography
              sx={{
                ...available,
                fontWeight: 600,
                width: "20%",
                fontSize: "1.6rem",
                mt: 0.5,
              }}
            >
              Yes
            </Typography>
          ) : (
            <Typography
              sx={{
                ...available,
                fontWeight: 600,
                width: "20%",
                fontSize: "1.6rem",
                mt: 0.5,
              }}
            >
              No
            </Typography>
          )}
          {props?.samplestate ? (
            <>
              {props?.data?.ProductsVariantions?.map((item) => {
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        width: "500px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "20px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...available,
                                  width: "100%",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "27px",
                                  // color: "#ADB8CC",
                                  color: "grey",
                                }}
                              >
                                Minimum Order Quantity
                              </Typography>
                              <Typography
                                sx={{
                                  ...available,
                                  lineHeight: "20px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "#6B7A99",
                                  width: "100%",
                                }}
                              >
                                {item.SampleAvailability}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "20px",
                            }}
                          >
                            <Box sx={{}}>
                              <Typography
                                sx={{
                                  ...available,
                                  width: "100%",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "27px",
                                  color: "grey",
                                }}
                              >
                                Price Of Sample
                              </Typography>
                              <Typography
                                sx={{
                                  ...available,
                                  lineHeight: "20px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "#6B7A99",
                                }}
                              >
                                {item.PriceOfSample}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SampleDetails;

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "1.6rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#6B7A99",
};
