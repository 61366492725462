import { useMutation } from "react-query";
import axios from "axios";

export const useBarterGptChatHistory = () => {
  const { mutate, data, loading, error, refetch } = useMutation(
    (data) =>
      axios.post(
        "/barterChatHistory/createChat",
        {
          chatData: [data],
          name: data?.name,
          phone: data?.phone,
        },
        { withCredentials: true }
      ),
    {
      onSuccess: () => {},
    },
    {
      onError: () => {},
    }
  );
  return { mutate, data, loading, error, refetch };
};
