// create Account
export const mainCommonPage = {
  boxShadow: "0px",
  height: "100vh",
  width: "100%",
  maxHeight: "100vh",
  maxWidth: "100vw",
  overflowY: "hidden",
  overflowX: "hidden",
  position: "relative",
};

export const login = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#445FD2",
};

export const AllInputBoxes = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  width: { xl: "55%", lg: "55%", md: "55%", sm: "65%", xs: "65%" },
  mx: "auto",
  gap: "20px",
  maxWidth: "450px",
};

export const inputBox = {
  width: "auto",
  borderRadius: "15px",
  minWidth: {
    xl: "400px",
    lg: "400px",
    md: "300px",
    sm: "350px",
    xs: "250px",
  },
  maxWidth: {
    xl: "400px",
    lg: "400px",
    md: "300px",
    sm: "350px",
    xs: "250px",
  },
  height: "45px",
};

export const CommonBtn = {
  background: "#375DBB",
  borderRadius: "12px",
  height: "4rem",
  marginLeft: "auto",
  marginRight: "auto",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  textAlign: "center",
  color: "#FFFFFF",
  textTransform: "none",
};

export const accountText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.4rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#A1A1A1",
  textAlign: {
    xl: "left",
    lg: "left",
    md: "center",
    sm: "center",
    xs: "center",
  },
  marginTop: "05px",
};

export const CommonImgStyle = {
  height: "auto",
  width: "100%",
  maxHeight: "100vh",
  marginRight: "250px",
};
export const CommonImgStyle2 = {
  height: "auto",
  width: "100%",
  maxHeight: "100vh",
  marginRight: "150px",
};

export const inputFieldDesign = {
  width: "auto",
  minWidth: {
    xl: "400px",
    lg: "380px",
    md: "280px",
    sm: "330px",
    xs: "230px",
  },
  maxWidth: {
    xl: "400px",
    lg: "380px",
    md: "280px",
    sm: "330px",
    xs: "230px",
  },
  height: "auto",
  borderRadius: "12px",
  border: "1px #CCCCCC",
  marginTop: "02px",
  position: "relative",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  bgcolor: "transparent",
};

export const inputbox = {
  position: "absolute",
  left: "10%",
  top: "-20%",
  bgcolor: "#fff",
  px: 1,
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "12px",
    sm: "12px",
    xs: "12px",
  },
};

export const inputStyles = {
  mt: 2,
  fontSize: "14px",
  width: "100%",
  height: "4rem",
  borderRadius: "15px",
  padding: "0 1rem",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  border: "1px solid #CCCCCC",
  textAlign: "left",
  color: "#6B7A99",
};

export const loginInputStyle = {
  borderRadius: "15px",
  border: "1px solid #445FD2",
  background: "var(--Neutral - White, #FFF)",
  width: "385px",
  height: "41px",
  paddingLeft: "10px",
  marginTop: "04px",
  color: "#445FD2",
  fontWeight: 500,
  fontSize: "15px",
};

export const loginLableStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#445FD2",
  fontSize: "15px",
  marginLeft: "20px",
};

export const signupLableStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#445FD2",
  fontSize: "15px",
  marginLeft: "20px",
  lineHeight: "0px",
};

export const LoginButton = {
  display: "inline-flex",
  height: "43px",
  padding: "14px 32px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexShrink: 0,
  borderRadius: "15px",
  width: "400px",
  marginTop: "10px",
};

export const loginButtonTextStyle = {
  color: "var(--Neutral-White, #FFF)",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

export const DialogText = {
  color: "#445FD2",
  fontFamily: "Poppins",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "center",
};

// OTP

export const oneSideGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "3.2rem",
    lg: "3.1rem",
    md: "3rem",
    sm: "2.8rem",
    xs: "2.6rem",
  },
  color: "#6B7A99",
};

export const otpInputStyle = {
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  textAlign: "center",
  border: "1px solid rgba(55, 93, 187, 1)",
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: 15,
  lineHeight: 19,
  color: "#000000",
  background: "#F6F6F6",
};

export const HeadText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "3.2rem",
    lg: "3.1rem",
    md: "3rem",
    sm: "2.8rem",
    xs: "2.6rem",
  },
  color: "#6B7A99",
  textAlign: "center",
};

// Verified

export const btn = {
  background: "#fff",
  width: "75%",
  height: "4rem",
  marginLeft: "auto",
  marginRight: "auto",
  border: "1px solid rgba(237, 239, 242, 1)",
  marginTop: "4rem",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  textAlign: "center",
  color: "rgba(107, 122, 153, 1)",
  textTransform: "none",
};

export const BankInput = {
  width: "95%",
  height: "auto",
  borderRadius: "12px",
  border: "1px #CCCCCC",
  marginTop: "0px",
  position: "relative",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  bgcolor: "transparent",
};

// Bank
// AccVerified

export const intoText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  textAlign: "center",
  color: "#6B7A99",
};

//  Add password
export const outlineBtn = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.4rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  height: "4rem",
  borderRadius: "0.6rem",
  textAlign: "center",
  color: "rgba(55, 93, 187, 1)",
  textTransform: "none",
};

// company Deatails
// forget
// forgetPasswordotp

export const otpGrid = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "3.2rem",
    lg: "3.1rem",
    md: "3rem",
    sm: "2.8rem",
    xs: "2.6rem",
  },
  color: "#6B7A99",
  marginTop: "-15rem",
};

export const ErrTypo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  color: "red",
  textAlign: "center",
};
// resset password
// Login
export const EyeStyle = {
  position: "absolute",
  top: "60%",
  left: "75%",
  right: "0%",
  right: "10px",
  transform: "translateY(-10%)",
  background: "none",
  border: "none",
  cursor: "pointer",
};

export const signupEyeStyle = {
  position: "absolute",
  top: "45%",
  left: "78%",
  right: "0%",
  right: "10px",
  transform: "translateY(-10%)",
  background: "none",
  border: "none",
  cursor: "pointer",
};
// OTP Toast

export const ToastMainBox = {
  background: "F3F6F9",
  width: "470px",
  height: "auto",
  minHeight: "85px",
  backgroundColor: "#F5F9FB",
  backgroundPosition: "center",
  border: "1px solid #156DB6",
  backgroundSize: "cover",
  ml: "30px",
  backgroundRepeat: "no-repeat",
  position: "relative",
  borderRadius: "25px",
  display: "flex",
  justifyContent: "center", // Center the content horizontally
  alignItems: "center", // Center the content vertically
  flexDirection: "column", // Added to stack the content vertically
  color: "#6B7A99", // Set the text color to white
};
export const ToasHeadText = {
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "20px",
  textAlign: "center",
  lineHeight: "30px",
  width: "90%",
  ml: "auto",
};
