import { Box, Typography } from "@mui/material";
import React from "react";

const PackagingAndDelivery = (props) => {
  return (
    <>
      {props?.ProductData?.ProductTechInfo
        ?.PackagingAndDeliveryInstructionsIfAny === undefined ||
      props?.ProductData?.ProductTechInfo
        ?.PackagingAndDeliveryInstructionsIfAny === null ? null : (
        <Box
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          <Typography
            mt={1}
            sx={{
              ...product,
              fontSize: "17px",
              color: "#156DB6",
            }}
          >
            Packaging and Delivery Instructions
          </Typography>

          <Typography
            sx={{
              ...listText,
              fontSize: "16px",
            }}
          >
            {
              props?.ProductData?.ProductTechInfo
                ?.PackagingAndDeliveryInstructionsIfAny
            }
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PackagingAndDelivery;

const listText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.6rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  // color: "#6B7A99",
  color: "grey",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#6B7A99",
};
