import React from "react";
import { useGetCompanyDataById } from "../../Hooks/CompanyData/useGetCompanyDataById";
import { Box, Typography } from "@mui/material";
const SellerDetailsPopUp = (props) => {
  const { data: companyData } = useGetCompanyDataById(props.companyId);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "20px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            ...HeadText,
            bgcolor: "#fff",
            zIndex: "1",
          }}
        >
          Seller Details
        </Typography>
        <span
          style={{
            borderTop: "0.5px solid #6B7A99",
            width: "75%",
            position: "absolute",
            top: "50%",
            left: "25%",
          }}
        />
      </Box>
      <Typography
        sx={{
          ...SubDataHeadText,
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        Name :{" "}
        <span
          style={{
            ...SubDataText,
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {companyData?.companyName}
        </span>
      </Typography>
      <Typography sx={SubDataHeadText}>
        Email : <span style={SubDataText}>{companyData?.email}</span>
      </Typography>
      <Typography sx={SubDataHeadText}>
        Phone : <span style={SubDataText}>{companyData?.phone}</span>
      </Typography>
      <Typography sx={SubDataHeadText}>
        Address :{" "}
        <span style={SubDataText}>{companyData?.RegisteredAddress}</span>
      </Typography>
      <Typography sx={SubDataHeadText}>
        Product Name :{" "}
        <span style={SubDataText}>
          {props?.product?.ProductData?.at(0)?.ProductName}
        </span>
      </Typography>
    </React.Fragment>
  );
};

export default SellerDetailsPopUp;

const HeadText = {
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 500,
  color: "#191919CC",
  textAlign: "left",
  py: 1,
  width: "auto",
  MinWidth: "25%",
  pr: 1,
};

const SubDataHeadText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "left",
};
const SubDataText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
};
