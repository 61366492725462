const FinalValueCommaSeprator = (props) => {
    const number = props?.Price;
    const roundedNumber = Number(number);
    const isValidNumber = !isNaN(roundedNumber);
    const formattedNumber = isValidNumber
      ? roundedNumber.toLocaleString("en-IN", {
          minimumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
          maximumFractionDigits: roundedNumber % 1 !== 0 ? 2 : 0,
        })
      : "";
  
    return formattedNumber;
  };
  
  export default FinalValueCommaSeprator;
  