import {
  Box,
  CircularProgress,
  Paper,
  Button,
  Typography,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import {
  useMutatePurchaseOrder,
  useMutateMediaPurchaseOrder,
} from "../../Hooks/PurchaseOrderActions/useMutatePurchaseOrder";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useGetOrderSummaryByIdForBuyer } from "../../Hooks/OrderActions/useGetOrderSummaryByIdForBuyer";
import { getCompanyById } from "../../redux/action/CompanyActions";
import { useSendMessageOnWhatsApp } from "../../utils/SendWhatsappMessagesHook";
import MediaPurchaseOrderDetails from "../BuyingJourneyPages/MediaPo";
import PurchaseOrderDetails from "../BuyingJourneyPages/PurchaseOrderDetails";
import POAcceptModal from "./POAcceptModal";
import axios from "axios";
import CloseIconImg from "../../assets/Images/CommonImages/CloseIcon.svg";
import CheckboxIcon from "../../assets/Images/CommonImages/CheckboxIcon.svg";
import CheckedBoxIcon from "../../assets/Images/CommonImages/CheckedBoxIcon.svg";
import CheckedCheckboxIcon from "../../assets/Images/CommonImages/CheckedCheckboxIcon.svg";
import { useLocation } from "react-use";

const SellerDetailedOrderSummary = () => {
  let dispatch = useDispatch();
  const { id } = useParams();

  const [notificationOn, setNotificationOn] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openrejectModal, setOpenRejectModal] = useState(false);
  const [openTextarea, setOpenTextarea] = useState("price");
  const [Reason, setReason] = useState("");

  const [storeUserHubType, setStoreUserHubType] = useState("buyer");

  useEffect(() => {
    const fetchUserHubType = localStorage.getItem("userhubtype");
    setStoreUserHubType(fetchUserHubType);
  }, []);

  const {
    data: OrderUpdateData,
    isLoading,
    error,
    mutate: OrderUpdateMutate,
  } = useMutatePurchaseOrder();
  const {
    data: SendWhatsappMessageData,
    isLoading: SendWhatsappMessageLoading,
    error: SendWhatsappMessageError,
    mutate: SendWhatsappMessageMutate,
  } = useSendMessageOnWhatsApp();
  const {
    data: OrderUpdateMediaData,
    isLoading: MediaUpdateLoading,
    error: MediaUpdateError,
    mutate: OrderUpdateMediaMutate,
  } = useMutateMediaPurchaseOrder();

  const navigate = useNavigate();

  const { data: orderSummaryData, isLoading: orderSummaryLoading } =
    useGetOrderSummaryByIdForBuyer(id);

  let IsMedia;
  orderSummaryData?.ProductData?.map((res) => {
    if (res?.ProductTypeName === "Media") {
      IsMedia = true;
    }
  });

  let orderData = [];

  for (let i = 0; i < orderSummaryData?.ProductData?.length; i++) {
    orderData.push(orderSummaryData.ProductData[i]);
  }

  let totalPrice = 0;
  orderData?.map((item) => {
    totalPrice += item.PricePerUnit * item.ProductQuantity;
  });

  const { OrderSummary: OrderSummarydata, loading: OrderSummaryDataLoading } =
    useSelector((state) => state.OrderSummaryD);

  useEffect(() => {
    dispatch(getCompanyById(OrderSummarydata?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  let storeDataIds = [];
  let TotalQuantity = 0;
  let totalAmount = 0;
  let totalPricePerUnit = 0;
  let totatlTaxableAmount = 0;
  let totalGST = 0;
  let totalAmountWithGST = 0;
  let totalAmountWithTax = 0;
  let IGST = "true";
  let TotalAdditionalCostInRupee = 0;
  let TotalAdditionalCostInBXI = 0;
  OrderSummarydata?.ProductData?.map((item) => {
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
    totalAmount += item.PricePerUnit * item.ProductQuantity;
    totalPricePerUnit += item.PricePerUnit;
    totatlTaxableAmount += item.PricePerUnit * item.ProductQuantity;
    totalGST += item.GST;
    totalAmountWithGST +=
      item?.PricePerUnit * item?.ProductQuantity * (item?.GST / 100);
    totalAmountWithTax +=
      item?.PricePerUnit * item?.ProductQuantity * (item?.GST / 100) +
      item?.PricePerUnit * item?.ProductQuantity;
    TotalAdditionalCostInRupee += item?.TotalAdditionalCostInRupee;
    TotalAdditionalCostInBXI += item?.TotalAdditionalCostInBXI;
  });

  if (
    OrderSummarydata?.BuyerDetails?.BuyerShippingAddress?.State ===
      OrderSummarydata?.SellerDetails?.SellerCompanyDetailedAddress?.state[0] ||
    OrderSummarydata?.BuyerDetails?.BuyerCompanyDetailedAddress?.state[0] ===
      OrderSummarydata?.SellerDetails?.SellerCompanyDetailedAddress?.state[0]
  ) {
    IGST = "false";
  } else {
    IGST = "true";
  }
  async function UpdateOrRejectThePurchaseOrder(props) {
    if (OrderSummarydata?.IsMedia) {
      if (props !== "Accepted") {
        OrderUpdateMediaMutate(
          {
            status: props,
            OrderSummaryId: id,
            IsMedia: true,
            RejectionType: openTextarea,
            RejectionDescription: Reason,
          },
          {
            onSuccess: (data) => {
              if (data) {
                setNotificationOn("Rejected");
                setTimeout(() => {
                  navigate(-1);
                }, 1000);
                toast.error("Order Rejected", {
                  position: "top-center",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                return;
              }
            },
            onError: (err) => {},
          }
        );
      } else {
        OrderUpdateMediaMutate(
          {
            status: props,
            OrderSummaryId: id,
            IsMedia: true,
          },
          {
            onSuccess: (data) => {
              if (data) {
                if (OrderSummarydata?.IsMedia === true) {
                  navigate("/home/sellermediapilist");
                } else {
                  navigate(`/home/productpilist`);
                }

                setNotificationOn("Accepted");
                toast.success("Order Accepted", {
                  position: "top-center",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                return;
              }
            },
            onError: (err) => {},
          }
        );
      }
    } else {
      if (props === "Rejected") {
        OrderUpdateMutate({
          status: props,
          OrderSummaryId: id,
          IsMedia: false,
        });
        Mail_To_Send({
          OrderData: OrderSummarydata,
          Status: props,
          template: "po-rejected",
          sendTo: "buyer",
        });
        SendWhatsappMessageMutate({
          template_name: "po_rejection",
          phone_number: OrderSummarydata?.BuyerDetails?.BuyerCompanyContact,
          parameterName: "parameterName",
          parameterValue: "parameterName",
          parameterName1: "parameterName",
          parameterValue1: "parameterName",
          seller_name: "seller_name",
        });
      } else {
        OrderUpdateMutate({
          status: props,
          OrderSummaryId: id,
          IsMedia: false,
        });
        await axios
          .post(`WhatApp/sendTempletMessageOnWhatApp`, {
            data: {
              template_name: "po_confirmed",
              phone_number: OrderSummarydata?.BuyerDetails?.BuyerCompanyContact,
              parameterName: "parameterName",
              parameterValue: "parameterName",
              parameterName1: "parameterName",
              parameterValue1: "parameterName",
              seller_name: "seller_name",
            },
          })
          .then((res) => {});
      }
    }
    setOpen(false);
  }

  const Mail_To_Send = async ({ OrderData, Status, template, sendTo }) => {
    try {
      await axios
        .put("communcations/updateCompanyCommunications", {
          OrderData,
          Status,
          template,
          sendTo,
        })
        .then((response) => {});
    } catch (error) {}
  };

  const BuyerMediaViewContent = ({ productMedia, productUrl, productName }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography
          sx={{
            ...TableBottomtext,
            fontSize: "14px",
            width: "300px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Media Name : {productName}
        </Typography>
        <a
          href={productUrl}
          target="_blank"
          download={`Product_${productMedia?.productId}_Content`}
          style={{
            textDecoration: "none",
          }}
        >
          <Button sx={ButtonStyleForView}>View Content</Button>
        </a>
      </Box>
    );
  };

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        boxShadow: "none",
        width: "100%",
      }}
      elevation={0}
    >
      {OrderSummarydata?.IsMedia ? (
        <MediaPurchaseOrderDetails SellerPage={true} Title={"Order Details"} />
      ) : (
        <PurchaseOrderDetails SellerPage={true} Title={"Order Details"} />
      )}

      {OrderSummarydata?.SellerOrderStatus === "Pending" &&
      !OrderSummarydata?.IsMedia ? (
        <POAcceptModal ProductId={id} />
      ) : (
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              mt: 3,
              pb: 3,
            }}
          >
            {OrderSummarydata?.ProductData?.map((item, idx) => {
              const productMedia = OrderSummarydata?.MediaContentUrls?.find(
                (media) => media.productId === item?.ProductId
              );

              return (
                productMedia && (
                  <BuyerMediaViewContent
                    productMedia={productMedia}
                    productUrl={productMedia?.url}
                    productName={item?.ProductName}
                  />
                )
              );
            })}

            <Box
              sx={{
                maxWidth: "500px",
                mx: "auto",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                height: "100%",
              }}
            >
              {OrderSummarydata?.SellerOrderStatus === "Accepted" ? (
                <Box
                  sx={{ ...ButtonStyleForAcceptAndReject }}
                  onClick={() => {
                    OrderSummarydata?.IsMedia === true
                      ? navigate("/home/sellermediapilist")
                      : storeUserHubType === "buyer"
                      ? navigate("/home/productpilist")
                      : navigate("/home/sellerpilist");
                  }}
                >
                  Next Step {"->"}
                </Box>
              ) : OrderSummarydata?.SellerOrderStatus === "Rejected" ? (
                <Box
                  sx={{
                    ...ButtonStyleForAcceptAndReject,
                    border: "0.5px solid #EB5757",
                    bgcolor: "#FFEFEF",
                    color: "#EB5757",
                  }}
                >
                  Order Rejected
                </Box>
              ) : (
                <>
                  <Box
                    sx={ButtonStyleForAcceptAndReject}
                    onClick={() => {
                      UpdateOrRejectThePurchaseOrder("Accepted");
                    }}
                  >
                    Accepts
                  </Box>

                  <Box
                    sx={{
                      ...ButtonStyleForAcceptAndReject,
                      bgcolor: "#fff",
                      border: "1px solid #2261A2",
                      color: "#2261A2",
                    }}
                    onClick={() => {
                      setOpenRejectModal(true);
                    }}
                  >
                    Reject
                  </Box>

                  <Modal
                    open={openrejectModal}
                    onClose={() => setOpenRejectModal(false)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: "translate(0%, 0%)",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#fff",
                        width: "350px",
                        height: openTextarea === "" ? "400px" : "480px",
                        p: 3,
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "poppins",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "#6B7A99",
                            }}
                          >
                            Please select the reason of Rejection
                          </Typography>
                          <Box
                            component="img"
                            src={CloseIconImg}
                            onClick={() => setOpenRejectModal(false)}
                            sx={{
                              cursor: "pointer",
                            }}
                          ></Box>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "15px",
                          width: "100%",
                        }}
                      >
                        <Box sx={ButtonGroupstyle}>
                          <Box
                            sx={{
                              ...RejectReasonBtnStyle,
                              color:
                                openTextarea == "price" ? "#445FD2" : "#6B7A99",
                              border:
                                openTextarea == "price"
                                  ? "1px solid #445FD2"
                                  : "1px solid #E4E7EC",
                            }}
                            onClick={() => {
                              setOpenTextarea("price");
                              setReason("");
                            }}
                          >
                            <Box
                              sx={{
                                ...TextAndCheckBoxGroupStyle,
                                border: "none",
                              }}
                            >
                              {openTextarea == "price" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "price"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={CheckedBoxIcon}
                                      sx={{
                                        width: "25px",
                                        height: "25px",
                                      }}
                                    />
                                    <Typography sx={ReasonTextStyle}>
                                      {" "}
                                      Price{" "}
                                    </Typography>
                                  </Box>
                                  <Box
                                    component="img"
                                    src={CheckedCheckboxIcon}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "price"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "20px",
                                  }}
                                >
                                  <Box component="img" src={CheckboxIcon} />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Price{" "}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <React.Fragment>
                            {openTextarea === "price" ? (
                              <TextField
                                sx={TextAreaStyle}
                                InputProps={InputPropsStyle}
                                placeholder="please explain your reason"
                                multiline
                                rows={4}
                                onChange={(e) => {
                                  setReason(e.target.value);
                                }}
                              ></TextField>
                            ) : null}
                          </React.Fragment>
                        </Box>
                        <Box sx={ButtonGroupstyle}>
                          <Box
                            sx={{
                              ...RejectReasonBtnStyle,
                              color:
                                openTextarea == "deliverydate"
                                  ? "#445FD2"
                                  : "#6B7A99",
                              border:
                                openTextarea == "deliverydate"
                                  ? "1px solid #445FD2"
                                  : "1px solid #E4E7EC",
                            }}
                            onClick={() => {
                              setOpenTextarea("deliverydate");
                              setReason("");
                            }}
                          >
                            <Box sx={TextAndCheckBoxGroupStyle}>
                              {openTextarea == "deliverydate" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "deliverydate"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={CheckedBoxIcon}
                                      sx={{
                                        width: "25px",
                                        height: "25px",
                                      }}
                                    />
                                    <Typography sx={ReasonTextStyle}>
                                      {" "}
                                      Delivery Date{" "}
                                    </Typography>
                                  </Box>
                                  <Box
                                    component="img"
                                    src={CheckedCheckboxIcon}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "deliverydate"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "20px",
                                  }}
                                >
                                  <Box component="img" src={CheckboxIcon} />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Delivery Date{" "}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <React.Fragment>
                            {openTextarea === "deliverydate" ? (
                              <TextField
                                sx={TextAreaStyle}
                                InputProps={InputPropsStyle}
                                placeholder="please explain your reason"
                                multiline
                                rows={4}
                                onChange={(e) => {
                                  setReason(e.target.value);
                                }}
                              ></TextField>
                            ) : null}
                          </React.Fragment>
                        </Box>
                        <Box sx={ButtonGroupstyle}>
                          <Box
                            sx={{
                              ...RejectReasonBtnStyle,
                              color:
                                openTextarea == "quantity"
                                  ? "#445FD2"
                                  : "#6B7A99",
                              border:
                                openTextarea == "quantity"
                                  ? "1px solid #445FD2"
                                  : "1px solid #E4E7EC",
                            }}
                            onClick={() => {
                              setOpenTextarea("quantity");
                              setReason("");
                            }}
                          >
                            <Box sx={TextAndCheckBoxGroupStyle}>
                              {openTextarea == "quantity" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "quantity"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={CheckedBoxIcon}
                                      sx={{
                                        width: "25px",
                                        height: "25px",
                                      }}
                                    />
                                    <Typography sx={ReasonTextStyle}>
                                      {" "}
                                      Quantity{" "}
                                    </Typography>
                                  </Box>
                                  <Box
                                    component="img"
                                    src={CheckedCheckboxIcon}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "quantity"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "20px",
                                  }}
                                >
                                  <Box component="img" src={CheckboxIcon} />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Quantity{" "}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <React.Fragment>
                            {openTextarea === "quantity" ? (
                              <TextField
                                sx={TextAreaStyle}
                                InputProps={InputPropsStyle}
                                placeholder="please explain your reason"
                                multiline
                                rows={4}
                                onChange={(e) => {
                                  setReason(e.target.value);
                                }}
                              ></TextField>
                            ) : null}
                          </React.Fragment>
                        </Box>
                        <Box sx={ButtonGroupstyle}>
                          <Box
                            sx={{
                              ...RejectReasonBtnStyle,
                              color:
                                openTextarea == "other" ? "#445FD2" : "#6B7A99",
                              border:
                                openTextarea == "other"
                                  ? "1px solid #445FD2"
                                  : "1px solid #E4E7EC",
                            }}
                            onClick={() => {
                              setOpenTextarea("other");
                              setReason("");
                            }}
                          >
                            <Box sx={TextAndCheckBoxGroupStyle}>
                              {openTextarea == "other" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "other"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={CheckedBoxIcon}
                                      sx={{
                                        width: "25px",
                                        height: "25px",
                                      }}
                                    />
                                    <Typography sx={ReasonTextStyle}>
                                      {" "}
                                      Other{" "}
                                    </Typography>
                                  </Box>
                                  <Box
                                    component="img"
                                    src={CheckedCheckboxIcon}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent:
                                      openTextarea === "other"
                                        ? "space-between"
                                        : "flex-start",
                                    gap: "20px",
                                  }}
                                >
                                  <Box component="img" src={CheckboxIcon} />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Other{" "}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <React.Fragment>
                            {openTextarea === "other" ? (
                              <TextField
                                sx={TextAreaStyle}
                                InputProps={InputPropsStyle}
                                placeholder="please explain your reason"
                                multiline
                                rows={4}
                                onChange={(e) => {
                                  setReason(e.target.value);
                                }}
                              ></TextField>
                            ) : null}
                          </React.Fragment>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Button
                          onClick={() => setOpenRejectModal(false)}
                          sx={{
                            width: "50%",
                            color: "#000",
                            textTransform: "none",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            border: "1px solid #D0D5DD",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            width: "50%",
                            color: "#fff",
                            background: "#445FD2",
                            textTransform: "none",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            "&:hover": {
                              background: "#445FD2",
                            },
                          }}
                          onClick={() => {
                            UpdateOrRejectThePurchaseOrder("Rejected");
                          }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default SellerDetailedOrderSummary;

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const ButtonStyleForView = {
  width: "100%",
  height: "30px",
  width: "auto",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  curso0r: "pointer",
  my: 1,
  border: "1px solid #fff",
  "&:hover": {
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const ButtonGroupstyle = {
  width: "100%",
  border: "none",
};

const RejectReasonBtnStyle = {
  width: "100%",
  mx: "auto",
  textTransform: "none",
  height: "40px",
  borderRadius: "6px",
  border: "1px solid #E4E7EC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ReasonTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#6B7A99",
};

const TextAndCheckBoxGroupStyle = {
  width: "100%",
  px: "10px",
  cursor: "pointer",
};

const TextAreaStyle = {
  width: "100%",
  mx: "auto",
  background: "#ECEFF1",
  color: "#C7C7CC",
  border: "1px solid lighgray",
  height: "100px",
  "& fieldset": { border: "none" },
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  my: 1,
};

const InputPropsStyle = {
  sx: {
    background: "#ECEFF1",
    fontFamily: "Poppins",
    color: "#445FD2",
    borderRadius: "10px",
    fontSize: "14px",
    width: "100%",
  },
};
