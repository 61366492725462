import React, { useState, useRef } from "react";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const HtmlToPdfConverter = async (props) => {
  try {
    const contentDiv = props;
    const canvas = await html2canvas(contentDiv);
    const pdf = new jsPDF();
    pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 10, 190, 0);
    const pdfBlob = pdf.output("blob");
    const formData = new FormData();
    formData.append("file", pdfBlob);
    formData.append("Key", "pdf/");
    formData.append("ContentType", "application/pdf");
    let APIRes = await axios.post("upload/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return APIRes;
  } catch (error) {
    return error;
  }
};

export default HtmlToPdfConverter;
