import {
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PDFIcon from "../../../assets/pdficon.png";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import CommaSeparator from "../../../components/CommaSeprator";
import { styles } from "../../../components/common/voucherTemplates/styles/commonStyles";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProductAddToCart } from "../../../Hooks/ProductActions/useProductAddToCart";
import BXITokenIcon from "../../../assets/Stack of Coins.svg";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader";
import VoucherPageHeader from "../../../components/VoucherPageHeader";
import { GetProductByIdAction } from "../../../redux/action/ProductActions/GetProductByIdAction";
import { useAddToWishlist } from "../../../Hooks/ProductActions/useAddToWishlist";
import { useGetCartData } from "../../../Hooks/ProductActions/useGetCartData";
import { useRemoveWishlistProductByProductId } from "../../../Hooks/ProductActions/useRemoveWishlistProduct";
import SelectedImg from "../../../assets/Images/CommonImages/Selected.png";
import UnSelectedImg from "../../../assets/Images/CommonImages/Unselected.png";
import CarasoulForVoucherDetails from "../../../components/Carousel/CarasoulForVoucherDetails";
import FeatureName from "../../../components/FeatureName";

import useGetCompanyTypeData from "../../../Hooks/CompanyData/useGetCompanyTypeData";
import { useGetVoucherCartData } from "../../../Hooks/ProductActions/useGetCartData";
import useGetProductById from "../../../Hooks/ProductActions/useGetProductById";
import PageLoader from "../../../components/LoadingButton/PageLoader";
import { ProductAnalysisUpdate } from "../../../redux/action/Products/ProductAnalysis";
import FilledStar from "../../../assets/FilledStar.png";
import BlankStar from "../../../assets/BlankStar.png";

import CommaSeprator from "../../../components/CommaSeprator";
import useGetAuthUser from "../../../Hooks/LoggedInUser/useGetAuthUser";

function DiscountedPrice({ regularPrice, discountPrice, percentage }) {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const formattedDiscountPercent = discountPercent.toFixed(2);
  const gstPrice = discountPrice / (1 + percentage / 100);
  const gstamount = discountPrice - gstPrice;
  const formattedgstPrice = gstPrice?.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedgstamount = gstamount?.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
          mt: "1px",
          // gap: "5px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "10px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#6B7A99",
          }}
        >
          &nbsp; {formattedgstPrice}
          <img
            src={BXITokenIcon}
            style={{
              width: "14px",
              height: "auto",
              marginTop: "-15px",
            }}
          />{" "}
          + {formattedgstamount} ₹
          <span
            style={{
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            GST
          </span>
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "10px",
          textTransform: "none",
          color: "#6B7A99",
          mt: 2,
        }}
      >
        All prices are inclusive of Taxes
      </Typography>
    </div>
  );
}

const ProductDetails = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  let ProductId = id;
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const [currentImage, setCurrentImage] = useState(0);
  const [GetProductByIdData, setGetProductByIdData] = useState();
  const [storeTechnicalInfo, setStoreTechnicalInfo] = useState();
  const [VariationToMap, setVariationToMap] = useState();
  const [WishlistData, setWishlistData] = useState();
  const [open, setOpen] = React.useState(false);
  const [IsSample, setIsSample] = useState("");
  const [CustomQuantity, setCustomQty] = useState(0);
  const [VoucherCartadata, setVoucherCartData] = useState();

  const [color, setColor] = useState("");
  const [storeVariationData, setStoreVariationData] = useState();
  const [borderColor, setBorderColor] = useState(true);
  const [VariationAllData, setVariationAllData] = useState();
  const [addToCardView, setAddToCardView] = useState(false);

  let minValue = 0;
  let maxValue = 0;

  async function getVoucherCartData() {
    await axios
      .get("voucher/get_cart_vouchers", {
        withCredentials: true,
      })
      .then((res) => {
        setVoucherCartData(res?.data);
      })
      .then((res) => {});
  }

  const {
    data: Productdata,
    isLoading: DataLoading,
    error: DataError,
    refetch: ProductRefetch,
  } = useGetProductById(id);

  const {
    data: CompanyTypeData,
    isLoading: CompanyTypeLoading,
    error: CompanyTypeError,
    refetch: CompanyTypeRefetch,
  } = useGetCompanyTypeData(Productdata?.ProductType);

  const { data: voucherCartItems, refetch: voucherCartRefetch } =
    useGetVoucherCartData();

  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductByIdData(res?.data);
        setStoreTechnicalInfo(res?.data?.ProductFeatures);
        setStoreVariationData(res?.data?.ProductsVariantions[0]);
      });
  }
  useEffect(() => {
    GetProductByid();
  }, []);

  const classes = styles();

  const [like, setLike] = useState(false);
  let ColorData = {};
  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (index === 0) {
      ColorData = item;
    }
  });

  const { data: mutateCartData, mutate: addtocart } = useProductAddToCart();

  const ImageDataArray = GetProductByIdData?.ProductImages;

  useEffect(() => {
    dispatch(GetProductByIdAction(ProductId));
  }, [dispatch]);

  let samplestate = false;
  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (item.SampleAvailability) {
      return (samplestate = true);
    } else {
      return (samplestate = false);
    }
  });

  const displayRatingStar = () => {
    try {
      const max_star = 5;
      const filled_star = parseInt(GetProductByIdData?.HotelStars);
      const blank_star = parseInt(max_star - filled_star);
      const arrFilledStar = new Array(filled_star).fill(FilledStar);
      const arrBlankStar = new Array(blank_star).fill(BlankStar);
      return (
        <div>
          {arrFilledStar.map((src, index) => (
            <img key={index} src={src} />
          ))}
          {arrBlankStar.map((src, index) => (
            <img key={index} src={src} />
          ))}
        </div>
      );
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    ColorData = {};
  }, [color, storeVariationData]);

  useEffect(() => {
    if (mutateCartData) {
      toast.success("Added to Cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [mutateCartData]);

  let NewdataArray = [];

  for (let i = 0; i < GetProductByIdData?.ProductsVariantions?.length; i++) {
    let ProductColor = GetProductByIdData?.ProductsVariantions[i].ProductColor;
    let finddata = NewdataArray.find((d) => d.ProductColor === ProductColor);
    if (finddata) {
      finddata.ProductVariations.push(
        GetProductByIdData?.ProductsVariantions[i]
      );
    } else {
      NewdataArray.push({
        ProductColor: GetProductByIdData?.ProductsVariantions[i].ProductColor,
        ProductVariations: [GetProductByIdData?.ProductsVariantions[i]],
      });
    }
  }

  useEffect(() => {
    GetProductByid();
    getVoucherCartData();
  }, []);

  useEffect(() => {
    GetProductByIdData?.ProductsVariantions?.map((res, idx) => {
      if (res?._id === storeVariationData) {
        setStoreVariationData(res?._id);
        setVariationAllData(res);
        return res?._id;
      }
    });
  }, [storeVariationData]);

  const {
    data: mutateWishlistData,
    mutate: addtowishlist,
    isLoading: wishlistMutateLoading,
    error: wishlistMutateError,
  } = useAddToWishlist();

  const { data: AuthUserData } = useGetAuthUser();

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProductByProductId();

  async function fetWishlistData() {
    await axios
      .get("wishlist/get_wishlist_product", {
        withCredentials: true,
      })
      .then((res) => {
        setWishlistData(res?.data);
      });
  }

  useEffect(() => {
    fetWishlistData();
  }, []);

  let priceone;
  let sdgfusdgf;
  GetProductByIdData?.ProductsVariantions?.forEach((item) => {
    if (item?._id === storeVariationData) {
      sdgfusdgf = item.PricePerUnit;
      return sdgfusdgf;
    }
  });
  priceone = storeVariationData?.PricePerUnit
    ? storeVariationData?.PricePerUnit
    : sdgfusdgf;

  let oneone;
  GetProductByIdData?.ProductsVariantions?.forEach((item) => {
    if (item?._id === storeVariationData) {
      oneone = item.DiscountedPrice;
      return oneone;
    }
  });

  let priceTwo = storeVariationData?.DiscountedPrice
    ? storeVariationData?.DiscountedPrice
    : oneone;

  GetProductByIdData?.ProductsVariantions?.map((res, idx) => {
    if (res?._id === storeVariationData) {
      maxValue = res?.MaxOrderQuantity;
      return res.MaxOrderQuantity;
    }
  });

  let ObjectForAddToCart = {
    ProductVariationId: storeVariationData,
    IsSample: IsSample ? true : false,
    ProductQty: !IsSample ? CustomQuantity : null,
    ProductId: ProductId,
    IsMedia: false,
  };
  async function handleAddToCart() {
    if (
      AuthUserData?.data?.rightsarray?.includes("purchase") ||
      AuthUserData?.data?.rightsarray?.includes("finance") ||
      AuthUserData?.data?.superAdmin
    ) {
      const VariationData = GetProductByIdData?.ProductsVariantions?.find(
        (res, idx) => {
          return res?._id === storeVariationData;
        }
      );
      let pricevalue = Number(CustomQuantity);
      if (!IsSample && pricevalue < VariationAllData?.MinOrderQuantity) {
        toast.error(
          `Entered quanity is less than Min Order Quantity ${VariationAllData?.MinOrderQuantity}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (!IsSample && pricevalue > VariationAllData?.MaxOrderQuantity) {
        toast.error(
          `Entered quanity is Greater than Max Order Quantity ${VariationAllData?.MaxOrderQuantity}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (!IsSample && pricevalue === 0) {
        toast.error("Please Select Qty to continue", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        await axios
          .post(
            "voucher/add_voucher_tocart",
            { ObjectForAddToCart },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(
              ProductAnalysisUpdate(ProductId, "", "ProductAddToCardCount")
            );
            getVoucherCartData();
            toast.success("Voucher successfully added to cart. Go to cart", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            voucherCartRefetch();
          })
          .catch((error) => {});
      }
    } else {
      toast.error("You are not authorized to access cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  }

  if (GetProductByIdData === undefined) {
    return <PageLoader />;
  }

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const getRedimptionType = () => {
    switch (GetProductByIdData?.redemptionType) {
      case "online":
        return "Online";
      case "offline":
        return "Offline";
      case "both":
        return "Online and Offline";
      default:
        return "-";
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/product/GetOwnProducts?productId=${id}`,
        {
          withCredentials: true,
        }
      );
      setAddToCardView(response.data);
    } catch (error) {}
  };
  fetchData();

  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader
          MainText={CompanyTypeData?.data?.CompanyTypeName}
          showbreadcrumb={true}
        />

        <Paper
          elevation={0}
          sx={{
            borderRadius: "20px",
            mt: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                height: "70px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <VoucherPageHeader />
            </Box>
          </Box>
          <Grid
            container
            sx={{
              width: "100%",
              mx: "auto",
              mt: 0,
              display: "flex",
            }}
          >
            <Grid
              item
              xl={5.5}
              lg={5.5}
              md={5.5}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                {GetProductByIdData?.VoucherImages?.length > 0 ? (
                  <CarasoulForVoucherDetails
                    imgSrc={GetProductByIdData?.VoucherImages}
                  />
                ) : GetProductByIdData.ProductImages.length > 0 ? (
                  <CarasoulForVoucherDetails
                    imgSrc={GetProductByIdData?.ProductImages}
                  />
                ) : null}
              </Box>
            </Grid>
            <Grid item xl={0.5} lg={0.5} md={0.5} sm={12} xs={12}></Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ ...fixGrid, mt: 0 }}
            >
              <Box sx={{ marginTop: "20px" }}>
                <Typography sx={semi}>
                  {GetProductByIdData?.ProductName}
                </Typography>
                <Typography
                  sx={{
                    ...semi,
                    color: "#6B7A99",
                    textAlign: "justify",
                    fontSize: "13px",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  {GetProductByIdData?.SellerCompanyName}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "10px",
                    letterSpacing: "0.06em",
                    textTransform: "capitalize",
                    color: "#6B7A99",
                    mt: "15px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    {/* <CommaSeprator Price={priceone} /> */}

                    <DiscountedPrice
                      regularPrice={priceone}
                      discountPrice={priceTwo}
                      percentage={
                        GetProductByIdData &&
                        GetProductByIdData?.ProductsVariantions?.length > 0 &&
                        GetProductByIdData?.ProductsVariantions[0]?.GST
                      }
                    />
                  </Box>
                </Typography>

                <Box sx={{ mt: "15px" }}>
                  {GetProductByIdData?.HotelStars && displayRatingStar()}
                </Box>

                {CompanyTypeData?.data?.CompanyTypeName === "FMCG" ||
                CompanyTypeData?.data?.CompanyTypeName === "Hotel" ||
                CompanyTypeData?.data?.CompanyTypeName === "Airlines Tickets" ||
                CompanyTypeData?.data?.CompanyTypeName ===
                  "Entertainment & Events" ||
                CompanyTypeData?.data?.CompanyTypeName === "QSR" ||
                GetProductByIdData?.VoucherType ===
                  "Value Voucher / Gift Cards " ? null : (
                  <Box sx={{ display: "flex", gap: "100px" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "grey",
                          mt: 2,
                        }}
                      >
                        Available colors
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: {
                            x: "flex-start",
                            lg: "flex-start",
                            md: "flex-start",
                            sm: "flex-start",
                            xs: "center",
                          },
                          width: "100%",
                          overflow: "auto",
                          "::-webkit-scrollbar": {
                            mt: 1,
                            display: "flex",
                            height: "4px",
                          },
                        }}
                      >
                        {GetProductByIdData &&
                          NewdataArray?.map((res, idx) => {
                            return (
                              <Box
                                key={idx}
                                onClick={() => {
                                  setVariationToMap(res?.ProductVariations);
                                  setBorderColor(res?.ProductColor);
                                }}
                                sx={{
                                  background: res?.ProductColor,
                                  width: {
                                    xl: "25%",
                                    lg: "25%",
                                    md: "25%",
                                    sm: "5%",
                                    xs: "13%",
                                  },
                                  mb: 1,
                                  ml: 0,
                                  mt: 0.5,
                                  height: "100%",
                                  minHeight: "30px",
                                  maxWidth: "25%",
                                  minWidth: "25%",
                                  borderRadius: "0.5rem",
                                  cursor: "pointer",
                                  transition:
                                    res?.ProductColor === borderColor
                                      ? "0.2s linear"
                                      : null,
                                  boxShadow:
                                    res?.ProductColor === borderColor
                                      ? "5px 5px 5px grey"
                                      : null,

                                  border:
                                    res?.ProductColor === borderColor
                                      ? "2px solid #000"
                                      : "2px solid lightgray",
                                }}
                              ></Box>
                            );
                          })}
                      </Box>
                    </Box>
                    <Box>
                      {GetProductByIdData?.gender === null ||
                      GetProductByIdData?.gender === undefined ||
                      GetProductByIdData?.VoucherType ===
                        "Value Voucher / Gift Cards " ? null : (
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "grey",
                              mt: 2,
                            }}
                          >
                            Gender
                          </Typography>
                          <Typography
                            sx={{
                              ...tableData,
                              textAlign: "start",
                              lineHeight: "4rem",
                              color: "#B1B1B1",
                            }}
                          >
                            {GetProductByIdData?.gender}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}

                <Box
                  mt={1}
                  sx={{
                    width: "100%",
                    mx: "auto",
                    overflow: "hidden",
                  }}
                >
                  <TableContainer sx={{ overflow: "hidden" }}>
                    <Table sx={{ overflow: "hidden" }}>
                      <TableHead>
                        <TableRow>
                          {(CompanyTypeData?.data?.CompanyTypeName ===
                            "Textile" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Lifestyle" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Office Supply" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Electronics" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName === "FMCG" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Mobility" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Others" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ? (
                            <TableCell sx={{ borderBottom: "none" }}>
                              <Typography
                                sx={{
                                  marginLeft: "-15px",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "grey",
                                }}
                              >
                                Sizes
                              </Typography>
                            </TableCell>
                          ) : (
                            <>
                              <TableCell sx={{ borderBottom: "none" }}>
                                <Typography
                                  sx={{
                                    marginLeft: "-15px",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "grey",
                                  }}
                                >
                                  Price/Sizes
                                </Typography>
                              </TableCell>
                            </>
                          )}
                          {CompanyTypeData?.data?.CompanyTypeName ===
                          "Entertainment & Events" ? (
                            <TableCell
                              sx={{ borderBottom: "none", width: "100px" }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "grey",
                                }}
                              >
                                Date of the Event
                              </Typography>
                            </TableCell>
                          ) : null}

                          <TableCell
                            sx={{
                              borderBottom: "none",
                              width: "90px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "grey",
                              }}
                            >
                              Min QTY
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: "none", width: "90px" }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "grey",
                              }}
                            >
                              Max QTY
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ borderBottom: "none", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "grey",
                              }}
                            >
                              GST{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{}}>
                          {(CompanyTypeData?.data?.CompanyTypeName ===
                            "Textile" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Lifestyle" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Office Supply" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Electronics" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName === "FMCG" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Mobility" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ||
                          (CompanyTypeData?.data?.CompanyTypeName ===
                            "Others" &&
                            GetProductByIdData?.VoucherType ===
                              "Offer Specific") ? (
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                width: "150px",
                                height: "30px",
                                p: 0,
                              }}
                            >
                              <Select
                                sx={{
                                  width: "140px",
                                  marginLeft: "0px",
                                  marginRight: "auto",
                                  height: "30px",
                                  marginTop: "-10px",
                                  border: "1px solid #8C8C8C",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "transparent",
                                      border: "transparent",
                                    },
                                }}
                                defaultValue={"hello"}
                                onChange={(e) => {
                                  setStoreVariationData(e.target.value);
                                  setIsSample(false);
                                }}
                              >
                                <MenuItem disabled>
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: "black",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Select a Size
                                  </Typography>
                                </MenuItem>
                                {!VariationToMap && NewdataArray?.length > 0
                                  ? !VariationToMap &&
                                    NewdataArray?.map((el, idx) => {
                                      return (
                                        <MenuItem
                                          key={idx}
                                          value={
                                            el?.ProductVariations?.at(0)?._id
                                          }
                                        >
                                          <Typography
                                            sx={{
                                              cursor: "pointer",
                                              color: "black",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {
                                              el?.ProductVariations?.at(0)
                                                ?.ProductSize
                                            }
                                          </Typography>
                                        </MenuItem>
                                      );
                                    })
                                  : VariationToMap?.map((el, idx) => {
                                      return (
                                        <MenuItem key={idx} value={el?._id}>
                                          <Typography
                                            sx={{
                                              cursor: "pointer",
                                              color: "black",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {el?.ProductSize}
                                          </Typography>
                                        </MenuItem>
                                      );
                                    })}
                              </Select>
                            </TableCell>
                          ) : (
                            <>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  width: "150px",
                                  height: "30px",
                                  p: 0,
                                }}
                              >
                                <Select
                                  sx={{
                                    width: "140px",
                                    marginLeft: "0px",
                                    marginRight: "auto",
                                    height: "30px",
                                    marginTop: "-10px",
                                    border: "1px solid #8C8C8C",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                        border: "transparent",
                                      },
                                  }}
                                  defaultValue={"hello"}
                                  onChange={(e) => {
                                    setStoreVariationData(e.target.value);
                                    setIsSample(false);
                                  }}
                                >
                                  <MenuItem disabled>
                                    <Typography
                                      sx={{
                                        cursor: "pointer",
                                        color: "black",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Select a Price
                                    </Typography>
                                  </MenuItem>
                                  {!VariationToMap && NewdataArray?.length > 0
                                    ? !VariationToMap &&
                                      GetProductByIdData?.ProductsVariantions?.map(
                                        (el, idx) => {
                                          return (
                                            <MenuItem key={idx} value={el?._id}>
                                              <Typography
                                                sx={{
                                                  cursor: "pointer",
                                                  color: "black",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {el?.PricePerUnit}
                                              </Typography>
                                            </MenuItem>
                                          );
                                        }
                                      )
                                    : VariationToMap?.map((el, idx) => {
                                        return (
                                          <MenuItem key={idx} value={el?._id}>
                                            <Typography
                                              sx={{
                                                cursor: "pointer",
                                                color: "black",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {el?.PricePerUnit}
                                            </Typography>
                                          </MenuItem>
                                        );
                                      })}
                                </Select>
                              </TableCell>
                            </>
                          )}

                          {CompanyTypeData?.data?.CompanyTypeName ===
                          "Entertainment & Events" ? (
                            <>
                              {GetProductByIdData?.ProductsVariantions?.map(
                                (res, idx) => {
                                  if (res?._id === storeVariationData) {
                                    maxValue = res?.DateOfTheEvent;
                                    return (
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          px: 2,
                                          py: 0,
                                          width: "120px",
                                        }}
                                      >
                                        <Typography sx={tableData}>
                                          {res.DateOfTheEvent}
                                        </Typography>
                                      </TableCell>
                                    );
                                  }
                                }
                              )}{" "}
                            </>
                          ) : null}
                          {GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              if (res?._id === storeVariationData) {
                                minValue = res.MinOrderQuantity;
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      px: 2,
                                      py: 0,
                                      width: "120px",
                                    }}
                                    align="center"
                                  >
                                    <Typography sx={tableData}>
                                      {res.MinOrderQuantity}
                                    </Typography>
                                  </TableCell>
                                );
                              }
                            }
                          )}
                          {GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              if (res?._id === storeVariationData) {
                                maxValue = res?.MaxOrderQuantity;
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      px: 2,
                                      py: 0,
                                      width: "120px",
                                    }}
                                  >
                                    <Typography sx={tableData}>
                                      {res.MaxOrderQuantity}
                                    </Typography>
                                  </TableCell>
                                );
                              }
                            }
                          )}
                          {GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              if (res?._id === storeVariationData) {
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      px: 2,
                                      py: 0,
                                      width: "120px",
                                    }}
                                  >
                                    <Typography sx={tableData}>
                                      {" "}
                                      {res.GST}
                                      &nbsp;%
                                    </Typography>
                                  </TableCell>
                                );
                              }
                            }
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "45px",
                    mt: "40px",
                  }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "30px",
                        color: "grey",
                      }}
                    >
                      Add Quantity
                    </InputLabel>
                    <Input
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      sx={{
                        ...tableData,
                        mt: 1,
                        width: "140px",
                        height: "30px",
                        background: "#fff",
                        boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                        borderRadius: "12px 12px 12px 12px",
                        textAlign: "center",
                        px: 2,
                      }}
                      value={CustomQuantity}
                      onChange={(e) => {
                        const inputValue = e.target.value;

                        if (
                          inputValue.includes(".") ||
                          e.nativeEvent.inputType === "insertFromPaste"
                        ) {
                          e.preventDefault();
                          return;
                        }

                        setCustomQty(inputValue);
                      }}
                      type="number"
                      inputProps={{
                        min: minValue,
                        max: maxValue,
                      }}
                    />
                  </Box>
                  <Box>
                    <InputLabel
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#6B7A99",
                      }}
                    >
                      Total Price
                    </InputLabel>
                    <Box
                      sx={{
                        mt: 1.8,
                        width: "100px",
                        height: "30px",
                        background: "#FFF",
                        boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                        borderRadius: "12px 12px 12px 12px",
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        px: 2,
                      }}
                    >
                      {GetProductByIdData?.ProductsVariantions?.map(
                        (res, idx) => {
                          if (res?._id === storeVariationData) {
                            const gstPrice =
                              res?.DiscountedPrice / (1 + res?.GST / 100);
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  width: "100px",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{ ...tableData, color: "#B1B1B1" }}
                                >
                                  <CommaSeparator
                                    Price={gstPrice * CustomQuantity}
                                  />
                                </Typography>
                                <img
                                  src={BXITokenIcon}
                                  style={{
                                    widows: "20px",
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              </Box>
                            );
                          }
                        }
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <InputLabel
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "30px",
                        color: "grey",
                      }}
                    >
                      Total GST
                    </InputLabel>
                    <Box
                      sx={{
                        mt: 1,
                        width: "100px",
                        height: "30px",
                        background: "#FFF",
                        boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                        borderRadius: "12px 12px 12px 12px",
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        px: 2,
                      }}
                    >
                      {GetProductByIdData?.ProductsVariantions?.map(
                        (res, idx) => {
                          if (res?._id === storeVariationData) {
                            const gstPrice =
                              res?.DiscountedPrice / (1 + res?.GST / 100);

                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  width: "100px",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{ ...tableData, color: "#6B7A99" }}
                                >
                                  <CommaSeparator
                                    Price={
                                      (gstPrice *
                                        CustomQuantity *
                                        Number(res?.GST)) /
                                      100
                                    }
                                  />
                                  &nbsp; ₹
                                </Typography>
                              </Box>
                            );
                          }
                        }
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      // bgcolor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    {!VariationAllData ? (
                      <>
                        {VoucherCartadata?.find(
                          (item) => item?.ProductId?._id === ProductId
                        ) ? (
                          <Button
                            sx={{
                              ...uploadBtn,
                              width: "auto",
                              minWidth: "110px",
                              height: "30px",
                              fontSize: "13px",
                              borderRadius: "12px",
                              boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                            }}
                            onClick={() =>
                              navigate("/home/cart", {
                                state: 2,
                              })
                            }
                          >
                            Go To Cart
                          </Button>
                        ) : (
                          addToCardView === false && (
                            <Button
                              sx={{
                                ...uploadBtn,
                                width: "auto",
                                minWidth: "110px",
                                height: "30px",
                                fontSize: "13px",
                                borderRadius: "12px",
                                boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                              }}
                              onClick={handleAddToCart}
                            >
                              Add to cart
                            </Button>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        {GetProductByIdData?.ProductsVariantions?.map(
                          (res, idx) => {
                            if (res?._id === storeVariationData) {
                              maxValue = res?.DateOfTheEvent;
                              const error =
                                res.MaxOrderQuantity < res.MinOrderQuantity ||
                                res.MaxOrderQuantity === 0;

                              return error ? (
                                <Button
                                  sx={{
                                    ...uploadBtn,
                                    width: "auto",
                                    minWidth: "110px",
                                    height: "30px",
                                    fontSize: "13px",
                                    borderRadius: "12px",
                                    boxShadow:
                                      "0px 4px 8px rgba(220, 220, 220, 1)",
                                    background: "red",
                                    "&:hover": {
                                      background: "red",
                                    },
                                  }}
                                >
                                  Out Of Stock
                                </Button>
                              ) : (
                                <>
                                  {VoucherCartadata?.find(
                                    (item) => item?.ProductId?._id === ProductId
                                  ) ? (
                                    <Button
                                      sx={{
                                        ...uploadBtn,
                                        width: "auto",
                                        minWidth: "110px",
                                        height: "30px",
                                        fontSize: "13px",
                                        borderRadius: "12px",
                                        boxShadow:
                                          "0px 4px 8px rgba(220, 220, 220, 1)",
                                      }}
                                      onClick={() =>
                                        navigate("/home/cart", {
                                          state: 2,
                                        })
                                      }
                                    >
                                      Go To Cart
                                    </Button>
                                  ) : (
                                    addToCardView === false && (
                                      <Button
                                        sx={{
                                          ...uploadBtn,
                                          width: "auto",
                                          minWidth: "110px",
                                          height: "30px",
                                          fontSize: "13px",
                                          borderRadius: "12px",
                                          boxShadow:
                                            "0px 4px 8px rgba(220, 220, 220, 1)",
                                        }}
                                        onClick={handleAddToCart}
                                      >
                                        Add to cart
                                      </Button>
                                    )
                                  )}
                                </>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",
                    mt: "15px",
                    pt: "10px",
                  }}
                >
                  <Box>
                    {GetProductByIdData?.ProductsVariantions[0]
                      ?.PriceOfSample ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {!IsSample ? (
                            <Box
                              component="img"
                              src={UnSelectedImg}
                              sx={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={() => setIsSample(true)}
                            ></Box>
                          ) : (
                            <Box
                              component="img"
                              src={SelectedImg}
                              sx={{
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                              onClick={() => setIsSample(false)}
                            ></Box>
                          )}
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#6B7A99",
                            }}
                          >
                            Do you want to first try a product sample ?
                          </Typography>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: 6 }}>
              <Box
                sx={{
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  typography: "body1",
                }}
              >
                <TabContext value={value}>
                  <Box>
                    <TabPanel value="1">
                      <Box>
                        <Box style={{ marginBottom: "25px" }}>
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              color: "#6B7A99",
                              fontFamily: "Poppins",
                              lineHeight: "30px",
                            }}
                          >
                            {GetProductByIdData?.ProductSubtitle}
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#6B7A99",
                              fontFamily: "Poppins",
                              lineHeight: "30px",
                            }}
                          >
                            {GetProductByIdData?.ProductDescription}
                          </Typography>
                        </Box>

                        <Box style={{ marginBottom: "20px" }}>
                          {GetProductByIdData?.redemptionType &&
                            (GetProductByIdData.redemptionType == "offline" ||
                              GetProductByIdData.redemptionType == "both") && (
                              <>
                                <Typography sx={detailsText}>
                                  Store Details
                                </Typography>
                                {GetProductByIdData?.Area === null ||
                                GetProductByIdData?.Area ===
                                  undefined ? null : (
                                  <Box style={{ marginBottom: "10px" }}>
                                    <Typography sx={storeText}>
                                      Store Address
                                    </Typography>
                                    <Typography className={classes.titleDesc}>
                                      {GetProductByIdData?.Address},&nbsp;
                                      {GetProductByIdData?.Area},&nbsp;
                                      {GetProductByIdData?.Landmark},&nbsp;
                                      {GetProductByIdData?.City},&nbsp;
                                      {GetProductByIdData?.State}
                                    </Typography>
                                  </Box>
                                )}

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "60%",
                                  }}
                                >
                                  <Box>
                                    <Typography sx={locationHeader}>
                                      Area
                                    </Typography>
                                    <Typography sx={locationData}>
                                      {GetProductByIdData?.Area}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography sx={locationHeader}>
                                      Landmark
                                    </Typography>
                                    <Typography sx={locationData}>
                                      {GetProductByIdData?.Landmark}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography sx={locationHeader}>
                                      City
                                    </Typography>
                                    <Typography sx={locationData}>
                                      {GetProductByIdData?.City}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography sx={locationHeader}>
                                      State
                                    </Typography>
                                    <Typography sx={locationData}>
                                      {GetProductByIdData?.State}
                                    </Typography>
                                  </Box>
                                </Box>

                                {GetProductByIdData?.HotelsListUrls &&
                                  GetProductByIdData?.HotelsListUrls.length >
                                    0 && (
                                    <Box style={{ marginTop: "20px" }}>
                                      <Typography
                                        className={classes.SpecificTitle}
                                      >
                                        Store List
                                      </Typography>
                                      <Box
                                        component={"a"}
                                        download="StoreList"
                                        target="_blank"
                                        href={
                                          GetProductByIdData?.HotelsListUrls &&
                                          GetProductByIdData?.HotelsListUrls[0]
                                            ?.url
                                            ? GetProductByIdData
                                                ?.HotelsListUrls[0]?.url
                                            : null
                                        }
                                        sx={CommonTypoStyle2}
                                        style={{
                                          marginTop: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          src={PDFIcon}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            marginRight: "10px",
                                          }}
                                          alt=""
                                        />
                                        click to download
                                      </Box>
                                    </Box>
                                  )}
                              </>
                            )}

                          {GetProductByIdData?.redemptionType &&
                            GetProductByIdData.redemptionType == "online" && (
                              <>
                                <Box style={{ marginTop: "20px" }}>
                                  <Typography className={classes.SpecificTitle}>
                                    Store Link
                                  </Typography>
                                  {GetProductByIdData?.Link && ( // Add a conditional check to render the link only if GetProductByIdData?.Link exists
                                    <a
                                      style={{
                                        color: "#445FD2",
                                        textAlign: "justify",
                                        fontSize: 12,
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                      }}
                                      target="_blank"
                                      href={
                                        (GetProductByIdData?.Link).includes(
                                          "https://"
                                        )
                                          ? GetProductByIdData?.Link
                                          : "https://" +
                                            GetProductByIdData?.Link
                                      }
                                    >
                                      {(GetProductByIdData?.Link).includes(
                                        "https://"
                                      )
                                        ? GetProductByIdData?.Link
                                        : "https://" + GetProductByIdData?.Link}
                                    </a>
                                  )}
                                </Box>
                              </>
                            )}

                          <Box style={{ marginTop: "20px", width: "700px" }}>
                            <table style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  <td className={classes.SpecificList}>
                                    Redemption Type
                                  </td>
                                  <td className={classes.SpecificList}>
                                    Listed This Product For
                                  </td>
                                  {localStorage.getItem("companyType") ===
                                    "Textile" && (
                                    <td className={classes.subTitle}>Gender</td>
                                  )}
                                  {localStorage.getItem("companyType") ===
                                    "Airlines Tickets" && (
                                    <td className={classes.subTitle}>From</td>
                                  )}
                                  {localStorage.getItem("companyType") ===
                                    "Airlines Tickets" && (
                                    <td className={classes.subTitle}>
                                      Destination
                                    </td>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className={classes.titleDesc}>
                                    {getRedimptionType()}
                                  </td>
                                  <td className={classes.titleDesc}>
                                    {
                                      GetProductByIdData?.ListThisProductForAmount
                                    }{" "}
                                    {
                                      GetProductByIdData?.ListThisProductForUnitOfTime
                                    }{" "}
                                  </td>
                                  {localStorage.getItem("companyType") ===
                                    "Textile" && (
                                    <td className={classes.titleDesc}>
                                      {GetProductByIdData?.gender}
                                    </td>
                                  )}
                                  {localStorage.getItem("companyType") ===
                                    "Airlines Tickets" && (
                                    <td className={classes.titleDesc}>
                                      {GetProductByIdData?.fromLocation}
                                    </td>
                                  )}
                                  {localStorage.getItem("companyType") ===
                                    "Airlines Tickets" && (
                                    <td className={classes.titleDesc}>
                                      {GetProductByIdData?.destinationLocation}
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </Box>
                        </Box>

                        {GetProductByIdData?.OtherCost &&
                        GetProductByIdData?.OtherCost?.length !== 0 ? (
                          <Box mt={2}>
                            <Typography
                              sx={{
                                ...product,
                                fontWeight: 600,
                                fontSize: "18px",
                                lineHeight: "15px",
                                color: "#156DB6",
                              }}
                            >
                              Additional Cost
                            </Typography>
                            {GetProductByIdData?.OtherCost?.length === 0
                              ? ""
                              : GetProductByIdData?.OtherCost?.map((cost) => {
                                  const newValue = cost?.CostPrice.toFixed(2);
                                  return (
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "40px",
                                          mt: 0.5,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            minWidth: "160px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...fetchValue,
                                              color: "grey",
                                            }}
                                          >
                                            {" "}
                                            {cost?.ReasonOfCost}{" "}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            minWidth: "160px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...fetchValue,
                                              color: "grey",
                                            }}
                                          >
                                            HSN - {cost?.AdCostHSN}{" "}
                                          </Typography>
                                        </Box>

                                        <Box
                                          sx={{
                                            minWidth: "160px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...fetchValue,
                                              color: "grey",
                                            }}
                                          >
                                            GST - {cost?.AdCostGST}%
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            minWidth: "160px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...fetchValue,
                                              color: "grey",
                                            }}
                                          >
                                            {cost?.AdCostApplicableOn}
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{
                                            gap: "5px",
                                            minWidth: "160px",
                                            display: "flex",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...fetchValue,
                                              color: "grey",
                                            }}
                                          >
                                            {newValue}
                                          </Typography>
                                          <Typography>
                                            {cost.currencyType ===
                                            "BXITokens" ? (
                                              <Box
                                                component="img"
                                                src={BXITokenIcon}
                                                alt="token"
                                                sx={{
                                                  height: "auto",
                                                  width: "15px",
                                                }}
                                              />
                                            ) : (
                                              <Typography
                                                sx={{
                                                  fontSize: "20px",
                                                  ml: 1,
                                                  color: "#156DB6",
                                                  mt: 0.5,
                                                }}
                                              >
                                                ₹
                                              </Typography>
                                            )}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  );
                                })}
                          </Box>
                        ) : null}
                        {/* additional section ends */}
                      </Box>

                      <Box
                        sx={{
                          borderTop: "0.2px solid #156DB6",
                          mt: 4,
                          wordBreak: "break-word",
                        }}
                      >
                        <Box
                          sx={{
                            mt: 4,
                          }}
                        >
                          <Typography className={classes.sectionTitleColored}>
                            Technical Information
                          </Typography>

                          <Typography className={classes.SpecificTitle}>
                            Inclusion
                          </Typography>
                          <Box sx={{ pt: "0.8%", padding: "10px" }}>
                            <Typography sx={dots} className={classes.titleDesc}>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "7px", pt: "10px" }}
                              />
                              {GetProductByIdData?.Inclusions}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ wordBreak: "break-word" }}>
                        <Typography className={classes.SpecificTitle}>
                          Exclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.Exclusions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ wordBreak: "break-word" }}>
                        <Typography className={classes.SpecificTitle}>
                          Terms & Conditions
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.TermConditions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ wordBreak: "break-word" }}>
                        <Typography className={classes.SpecificTitle}>
                          Redemption Steps
                        </Typography>
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.RedemptionSteps}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ borderTop: "0.2px solid #156DB6", mt: 4 }}>
                        <Typography sx={{ ...pack, mt: 4 }}>
                          Key Features
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid
                            container
                            mt={2}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {GetProductByIdData?.ProductFeatures?.map((res) => {
                              return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                                  <Box
                                    sx={{
                                      // px: 2,
                                      display: "flex",
                                      // flexWrap: "wrap",
                                      textAlign: "start",
                                      flexDirection: "row",
                                      gap: "70px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <FeatureName name={res?.name} />
                                      <Box
                                        sx={{
                                          width: "80%",
                                          maxWidth: "825px",
                                          height: "auto",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <Typography sx={packHead}>
                                          {res.name}
                                        </Typography>
                                        <Typography sx={packVal}>
                                          {res.description}{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                      {/* main container for Desc section ends */}
                    </TabPanel>
                    <TabPanel value="2">
                      <Box>
                        <Typography className={classes.sectionTitleColored}>
                          Technical Information
                        </Typography>

                        <Typography className={classes.SpecificTitle}>
                          Inclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.Inclusions}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Exclusion
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.Exclusions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Terms & Conditions
                        </Typography>
                        <Box sx={{ pt: "0.8%", padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.TermConditions}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography className={classes.SpecificTitle}>
                          Redemption Steps
                        </Typography>
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={dots} className={classes.titleDesc}>
                            <FiberManualRecordIcon
                              sx={{ fontSize: "7px", pt: "10px" }}
                            />
                            {GetProductByIdData?.RedemptionSteps}
                          </Typography>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value="3">
                      <Box>
                        <Typography sx={pack}>Key Features</Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid
                            container
                            mt={2}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {GetProductByIdData?.ProductFeatures?.map((res) => {
                              return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                                  <Box
                                    sx={{
                                      // px: 2,
                                      display: "flex",
                                      // flexWrap: "wrap",
                                      textAlign: "start",
                                      flexDirection: "row",
                                      gap: "70px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <FeatureName name={res?.name} />
                                      <Box
                                        sx={{
                                          width: "80%",
                                          maxWidth: "825px",
                                          height: "auto",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <Typography sx={packHead}>
                                          {res.name}
                                        </Typography>
                                        <Typography sx={packVal}>
                                          {res.description}{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
            }}
          >
            {!VariationAllData ? (
              <>
                {VoucherCartadata?.find(
                  (item) => item?.ProductId?._id === ProductId
                ) ? (
                  <Button
                    sx={uploadBtn}
                    onClick={() =>
                      navigate("/home/cart", {
                        state: 2,
                      })
                    }
                  >
                    Go To Cart
                  </Button>
                ) : (
                  addToCardView === false && (
                    <Button sx={uploadBtn} onClick={handleAddToCart}>
                      Add to cart
                    </Button>
                  )
                )}
              </>
            ) : (
              <>
                {" "}
                {GetProductByIdData?.ProductsVariantions?.map((res, idx) => {
                  if (res?._id === storeVariationData) {
                    maxValue = res?.DateOfTheEvent;
                    const error =
                      res.MaxOrderQuantity < res.MinOrderQuantity ||
                      res.MaxOrderQuantity === 0;

                    return error ? (
                      <Button
                        sx={{
                          ...uploadBtn,
                          background: "red",
                          "&:hover": {
                            background: "red",
                          },
                        }}
                      >
                        Out Of Stock
                      </Button>
                    ) : (
                      <>
                        {VoucherCartadata?.find(
                          (item) => item?.ProductId?._id === ProductId
                        ) ? (
                          <Button
                            sx={uploadBtn}
                            onClick={() =>
                              navigate("/home/cart", {
                                state: 2,
                              })
                            }
                          >
                            Go To Cart
                          </Button>
                        ) : (
                          addToCardView === false && (
                            <Button sx={uploadBtn} onClick={handleAddToCart}>
                              Add to cart
                            </Button>
                          )
                        )}
                      </>
                    );
                  }
                })}
              </>
            )}
          </Box>
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default ProductDetails;

const mainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  color: "#6B7A99",
  p: 3,
};

const fixGrid = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const semi = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "2.2rem",
    lg: "2.2rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#4D4D4D",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
};

const tabTexts = {
  textTransform: "none",
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.4rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
};
const semiPrice = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "3.6rem",
    lg: "3.6rem",
    md: "3.6rem",
    sm: "3.4rem",
    xs: "2.5rem",
  },
  letterSpacing: "0.06em",
  textTransform: "capitalize",
  color: "#6B7A99",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const tableData = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.6rem",
    sm: "1.6rem",
    xs: "1.5rem",
  },
  color: "#B1B1B1",
  lineHeight: "4rem",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const find = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.4rem",
    sm: "1.3rem",
    xs: "1.3rem",
  },
  color: "#6B7A99",
  lineHeight: "5rem",
  color: "#445FD2",
  cursor: "pointer",
};

const chart = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.4rem",
    sm: "1.3rem",
    xs: "1.3rem",
  },
  lineHeight: "5rem",
  color: "#445FD2",
};

const tabText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "1.8rem",
    sm: "1.6rem",
    xs: "1.6rem",
  },
  width: "95%",
  color: "#6B7A99",
  // textAlign: "center",
};

const tabSubText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  width: "95%",
  textAlign: "justify",
  color: "#6B7A99",
};

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "1.6rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};

const CommonTypoStyle2 = {
  marginTop: "1%",
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "20px",
    lg: "15px",
    xl: "15px",
  },
  letterSpacing: "1px",
  color: "#6B7A99",
  width: "auto",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#6B7A99",
};

const pack = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  color: "#156DB6",
};

const dots = {
  display: "flex",
  gap: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "20px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  textAlign: "justify",
  color: "#6B7A99",
};

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#ADB8CC",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#6B7A99",
};

const uploadBtn = {
  width: "100%",
  background: "rgba(68, 95, 210, 1)",
  "&:hover": {
    background: "rgba(68, 95, 210, 1)",
  },
  color: "#fff",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  borderRadius: "0.6rem",
  textTransform: "none",
};

const detailsText = {
  color: "#156DB6",
  fontSize: 20,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
};

const storeText = {
  color: "#156DB6",
  textAlign: "justify",
  fontSize: 16,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
};

const locationHeader = {
  color: "#808080",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
};

const locationData = {
  color: "#B1B1B1",
  fontSize: 14,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
};

const fetchValue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "14px",
  color: "grey",
  marginTop: "7px",
};
