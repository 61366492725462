/* eslint-disable no-prototype-builtins */
import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Radio,
  Box,
  RadioGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@mui/material";
import DeleteIcon from "../../../assets/Images/CommonImages/DeleteIcon.svg";
import { styles } from "./styles/commonStyles";
import { usePostProductQuery } from "../../../Hooks/Products/AddProduct";
import { useGetProductById } from "../../../Hooks/GetProducts/useGetProductById";
import useGetProductVoucherFields from "../../../Hooks/ProductActions/useGetProductVoucherFields";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import UploadtoCloud from "../../../assets/UploadtoCloud.svg";
import ToolTip from "../../ToolTip";
import { Stack } from "@mui/system";
import readXlsxFile from "read-excel-file";
import VouhcerCodeTemplateFile from "../../../assets/ExelSheets/Vouchercodetemplatefile.xlsx";
import { LINKName } from "../../Voucher/VoucherNameConstants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const dbData = {
  StepTechInfo: [
    {
      FieldName: "Inclusions",
      FieldLabel: "Inclusions",
      FieldType: "textArea",
      MinValue: "0",
      Input: true,
      required: true,
    },
    {
      FieldName: "Exclusions",
      FieldLabel: "Exclusions",
      FieldType: "textArea",
      MinValue: "0",
      Input: true,
      required: true,
    },
    {
      FieldName: "TermConditions",
      FieldLabel: "Terms & Conditions",
      FieldType: "textArea",
      MinValue: "0",
      Input: true,
      required: true,
    },
    {
      FieldName: "RedemptionSteps",
      FieldLabel: "Redemption Steps",
      FieldType: "textArea",
      MinValue: "0",
      Input: true,
      required: true,
    },
    {
      FieldName: "redemptionType",
      FieldLabel: "How can it be redeemed by buyer ?",
      FieldType: "radioGroup",
      MinValue: "0",
      Input: true,
      required: true,
      options: [
        { label: "Online", value: "online" },
        { label: "Offline", value: "offline" },
        { label: "Both", value: "both" },
      ],
      hasDependentFields: true,
      fields: [
        {
          online: [
            {
              FieldName: LINKName,
              FieldLabel: "Add URL",
              FieldType: "text",
              required: true,
            },
          ],
          offline: [
            {
              FieldName: "Address",
              FieldLabel: "Address ( If Single ) Type Below",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "Area",
              FieldLabel: "Area",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "Landmark",
              FieldLabel: "Landmark",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "City",
              FieldLabel: "City",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "State",
              FieldLabel: "State",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "HotelLocations",
              FieldLabel: "Upload Store List ( If Multiple Locations) ",
              FieldType: "file",
              required: true,
            },
          ],
          both: [
            {
              FieldName: "Address",
              FieldLabel: "Address ( If Single ) Type Below",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "Area",
              FieldLabel: "Area",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "Landmark",
              FieldLabel: "Landmark",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "City",
              FieldLabel: "City",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "State",
              FieldLabel: "State",
              FieldType: "text",
              required: true,
            },
            {
              FieldName: "HotelLocations",
              FieldLabel: "Upload Store List ( If Multiple Locations) ",
              FieldType: "file",
              required: true,
            },
            {
              FieldName: LINKName,
              FieldLabel: "Add URL",
              FieldType: "text",
              required: true,
            },
          ],
        },
      ],
    },
    {
      FieldName: "CodeGenerationType",
      FieldLabel: `How do you want to upload your voucher codes?
      (Bxi will generate them for you or you can upload them)`,
      FieldType: "radioGroup",
      MinValue: "0",
      Input: true,
      required: true,
      options: [
        { label: "BXI", value: "bxi" },
        { label: "Upload Now", value: "self" },
      ],
      hasDependentFields: true,
      fields: [
        {
          self: [
            {
              FieldName: "voucherFiles",
              FieldLabel: "",
              FieldType: "voucherFiles",
              required: true,
            },
          ],
        },
      ],
    },
  ],
};

const TechInfoTemplate = () => {
  const productId = useParams().id;
  const classes = styles();
  const navigate = useNavigate();
  const inputRef = useRef();
  const fullPath = useLocation().pathname;

  const { data: voucherData } = useGetProductVoucherFields();
  const { data: ProductData } = useGetProductById(productId);
  const [VoucherCodeFiles, setVoucherCodeFiles] = useState([]);
  const createRefCallback = (index) => (element) => {
    fileInputRefs.current[index] = element;
  };
  const fileInputRefs = React.useRef([]);
  const handleFileChange = async (event, item, index) => {
    const file = event.target.files[0];
    const isFileAlreadyUploaded = VoucherCodeFiles.some(
      (uploadedFile) => uploadedFile.name === file.name
    );

    if (isFileAlreadyUploaded) {
      alert("This file has already been uploaded.");
      return;
    }
    readXlsxFile(file).then((rows) => {
      const extractedData = rows.map((row) => row[0]);
      if (extractedData.some((code) => code === null)) {
        alert("Please ensure there are no null values in the file.");
        return;
      }
      if (extractedData?.at(0) === "UniqueVoucherCodes") {
        if (extractedData.length === Number(item.TotalAvailableQty) + 1) {
          alert("succesfully uploaded");
          setVoucherCodeFiles((prev) => [
            ...prev,
            { file, id: item._id, voucherCodes: extractedData.slice(1) },
          ]);
        } else {
          alert(
            "You have to upload " +
              item.TotalAvailableQty +
              " codes and you have uploaded " +
              (extractedData.length - 1) +
              " codes"
          );
        }
      } else {
        alert("Please upload a valid file containing voucher codes");
      }
      // Update the state with the extracted data
    });
    clearInput(fileInputRefs.current[index]);
  };
  const clearInput = (inputElement) => {
    if (inputElement) {
      inputElement.value = null; // Clear the input value
    }
  };

  const { mutateAsync, isLoading } = usePostProductQuery();

  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState(null);

  useEffect(() => {
    if (ProductData && voucherData) {
      updateFieldWithDBValues();
    }
  }, [ProductData, voucherData]);

  const updateFieldWithDBValues = () => {
    if (voucherData && voucherData.StepTechInfo) {
      voucherData &&
        voucherData.StepTechInfo.forEach((element) => {
          if (ProductData.hasOwnProperty(element.FieldName)) {
            setFormValues((prevFormValues) =>
              Object.assign({}, prevFormValues, {
                [element.FieldName]: ProductData[element.FieldName],
              })
            );
          }
          if (element.hasDependentFields) {
            element.fields.forEach((x) => {
              if (x[ProductData[element.FieldName]]) {
                if (
                  x[ProductData[element.FieldName]] &&
                  x[ProductData[element.FieldName]].length > 0
                ) {
                  x[ProductData[element.FieldName]].forEach((y) => {
                    if (
                      ProductData.hasOwnProperty(y.FieldName) ||
                      y.FieldName == "HotelLocations"
                    ) {
                      if (
                        element.FieldType === "radioGroup" &&
                        element.FieldName == "HotelLocations"
                      ) {
                        if (ProductData?.HotelsListUrls && !files) {
                          setFiles({
                            name: ProductData.HotelsListUrls[0]["nameKey"],
                          });
                        }
                      } else {
                        setFormValues((prevFormValues) =>
                          Object.assign({}, prevFormValues, {
                            [y.FieldName]: ProductData[y.FieldName],
                          })
                        );
                      }
                    }
                  });
                }
              }
            });
          }
        });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: files[0],
      }));
    } else {
      setFormValues((prevFormValues) =>
        Object.assign({}, prevFormValues, { [name]: value })
      );
    }
    // formValidation();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formValidation();
  };

  const formValidation = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      if (formValues["redemptionType"] != "online") {
        if (!files && !formValues["Address"]) {
          setErrors({
            Address: "Complete store address or Store list is required.",
          });
          return;
        }
        if (
          formValues["Address"] &&
          (!formValues["Area"] ||
            !formValues["Landmark"] ||
            !formValues["City"] ||
            !formValues["State"])
        ) {
          setErrors({ Address: "Complete store address is required." });
          return;
        }
        if (formValues["redemptionType"] == "both" && !formValues[LINKName]) {
          setErrors({ Link: "This field is required." });
          return;
        }

        if (
          formValues["redemptionType"] == "both" &&
          formValues[LINKName] &&
          !formValues[LINKName].match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          )
        ) {
          setErrors({ Link: "This field is required." });
          return;
        }
      } else {
        if (formValues[LINKName].includes("bxiworld")) {
          toast.error("You can not use BXI world in Website Link", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          return;
        }
        if (!formValues[LINKName]) {
          setErrors({ Link: "This field is required." });
          return;
        }
        if (
          formValues[LINKName] &&
          !formValues[LINKName].match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          )
        ) {
          setErrors({ Link: "Please enter valid URL." });
          return;
        }
      }

      setErrors({});

      var formData = new FormData();
      const voucherCodesArray = VoucherCodeFiles.map((voucherFile) => ({
        variationId: voucherFile.id,
        voucherCodes: voucherFile.voucherCodes,
      }));

      formData.append("HotelLocations", files);

      Object.entries(formValues).map(([key, value]) => {
        return formData.append(key, value);
      });
      formData.append("id", productId);
      formData.append(
        "voucherCodes",
        JSON.stringify({
          ProductId: productId,
          codes: voucherCodesArray,
        })
      );

      mutateAsync(formData, {
        onSuccess: (response) => {
          if (response?.data._id) {
            let chunks = fullPath.split("/");
            let path = chunks[chunks.length - 1];
            navigate(`/home/vouchers/voucherdesign/` + response?.data._id);
          } else {
            alert("Somethings has gone wrong");
          }
        },
        onError: (error) => {},
      });
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (
      (formValues?.redemptionType === "both" ||
        formValues?.redemptionType === "offline") &&
      files?.name === undefined
    ) {
      toast.error("Please select Store List file ", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dbData["StepTechInfo"].forEach((field) => {
      if (field.required) {
        if (field.FieldType === "dropdown" && !formValues[field.FieldName]) {
          errors[field.FieldName] = "This field is required";
        } else if (
          field.FieldType === "text" ||
          field.FieldType === "textArea"
        ) {
          if (!formValues[field.FieldName]) {
            errors[field.FieldName] = "This field is required";
          } else if (
            field?.MinLength &&
            formValues[field.FieldName].length < field.MinLength
          ) {
            errors[
              field.FieldName
            ] = `This field must contain at least ${field.MinLength} character(s)`;
          } else if (
            field?.MaxLength &&
            formValues[field.FieldName].length > field.MaxLength
          ) {
            errors[
              field.FieldName
            ] = `This field must contain less than ${field.MaxLength} character(s)`;
          }
        } else if (field.FieldType === "file" && !formValues[field.FieldName]) {
          alert("Please select a file field type");
          errors[field.FieldName] = "Please select a file";
        } else if (field.FieldType === "file" && !formValues[field.FieldName]) {
          errors[field.FieldName] = "Please select a file";
        } else if (!formValues[field.FieldName]) {
          errors[field.FieldName] = "Please select an option";
        } else if (formValues[field.FieldName] === "www.bxiworld.com") {
          errors[field.FieldName] = "You can not enter bxiworld";
        }
        if (
          field.FieldName === "CodeGenerationType" &&
          formValues[field.FieldName] === "self"
        ) {
          const productVariationsCount =
            ProductData?.ProductsVariantions?.length || 0;
          const voucherFilesCount = VoucherCodeFiles?.length || 0;

          if (voucherFilesCount !== productVariationsCount) {
            errors[field.FieldName] =
              "Please upload voucher files for all product variations";
          }
        }
      }
    });

    return errors;
  };
  const renderlabel = (FieldLabel) => {
    return (
      <>
        {FieldLabel} <span style={{ color: "red" }}> *</span>
      </>
    );
  };
  const renderFormFields = (fields) => {
    return fields.map((ele, ind) => {
      switch (ele.FieldType) {
        case "text":
          return (
            <Box className={classes.fieldBox}>
              <label className={classes.fieldLabel}>
                {ele.FieldLabel}
                <span style={{ color: "red" }}> *</span>
              </label>
              <TextField
                variant="standard"
                name={ele.FieldName}
                placeholder={ele.FieldLabel}
                InputProps={{
                  disableUnderline: "true",
                  style: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "red",
                  },
                }}
                value={formValues[ele.FieldName] || ""}
                onChange={handleChange}
                className={classes.textField}
                error={!!errors[ele.FieldName]}
              />
              {errors[ele.FieldName] && (
                <span className={classes.validationError}>
                  {errors[ele.FieldName]}
                </span>
              )}
            </Box>
          );

        case "textArea":
          return (
            <Box className={classes.fieldBox}>
              <label className={classes.fieldLabel}>
                {ele.FieldName === "TermConditions"
                  ? "Terms & Conditions"
                  : ele.FieldName}
                <span style={{ color: "red" }}> *</span>
              </label>
              <TextField
                multiline
                variant="standard"
                name={ele.FieldName}
                placeholder={ele.FieldLabel}
                InputLabelProps={{
                  style: {
                    color: "red",
                  },
                }}
                value={formValues[ele.FieldName] || ""}
                sx={{ ...TextFieldStyle, height: "100%" }}
                minRows={3}
                InputProps={InputPropsStyle}
                onChange={handleChange}
                error={!!errors[ele.FieldName]}
              />
              {errors[ele.FieldName] && (
                <span className={classes.validationError}>
                  {errors[ele.FieldName]}
                </span>
              )}
            </Box>
          );
        case "radioGroup":
          return (
            <Box className={classes.fieldBox}>
              <label className={classes.fieldLabel}>
                {ele.FieldLabel}
                <span style={{ color: "red" }}> *</span>
              </label>
              <RadioGroup
                row
                name={ele.FieldName}
                className={classes.radioField}
                value={ele?.options[0]?.value}
              >
                {ele?.options ? (
                  <>
                    {ele.options.map((element) => {
                      return (
                        <FormControlLabel
                          value={element.value}
                          key={element.value}
                          control={<Radio />}
                          label={element.label}
                          name={element.FieldName}
                          checked={formValues[ele.FieldName] === element.value}
                          onChange={handleChange}
                          className={
                            formValues[ele.FieldName] === element.value &&
                            classes.selectedRadioGroup
                          }
                          style={{
                            color:
                              formValues[ele.FieldName] === element.value
                                ? "rgb(68, 95, 210)"
                                : "#ADB8CC",
                            marginLeft: "0px",
                          }}
                        />
                      );
                    })}
                  </>
                ) : null}
              </RadioGroup>
              {errors[ele.FieldName] && (
                <span className={classes.validationError}>
                  {errors[ele.FieldName]}
                </span>
              )}
              {ele?.hasDependentFields && formValues[ele.FieldName] ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  {ele?.fields[0][formValues[ele.FieldName]]?.map((x) => {
                    return (
                      <>
                        {x.FieldType == "text" && (
                          <>
                            <TextField
                              variant="standard"
                              type="text"
                              label={renderlabel(x.FieldName)}
                              name={x.FieldName}
                              value={formValues[x.FieldName] || ""}
                              onChange={handleChange}
                              className={classes.textField}
                              style={{ margin: "10px 0" }}
                              InputLabelProps={{
                                className: classes.fieldLabel2,
                              }}
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <label
                                    style={{ fontFamily: "Poppins" }}
                                  ></label>
                                ),
                                startAdornment: (
                                  <label
                                    style={{ marginLeft: "inherit" }}
                                  ></label>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  color: " #6B7A99",
                                  marginTop: "25px",
                                  marginLeft: "0px",
                                  fontSize: "14px",
                                  height: "41px",
                                  background: "#FFFFFF",
                                  borderRadius: "9px",
                                  padding: "10px",
                                },
                              }}
                            />
                          </>
                        )}

                        {x.FieldType == "file" && (
                          <>
                            <Box>
                              <label
                                className={classes.fieldLabel}
                                style={{ mt: "2rem" }}
                              >
                                {x.FieldLabel}
                                <span style={{ color: "red" }}> *</span>
                              </label>
                            </Box>
                            <Box
                              className={classes.fileBox}
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                              onClick={() => inputRef.current.click()}
                            >
                              <Box className={classes.fileInnerBox}>
                                <Box className={classes.fileBoxContainer}>
                                  <Box
                                    component="img"
                                    src={UploadtoCloud}
                                    sx={{ position: "absolute", left: "10%" }}
                                  />
                                  <label
                                    className={classes.dragLabel}
                                    onClick={() => inputRef.current.click()}
                                  >
                                    Drag & Drop upload or browse to choose a
                                    file
                                  </label>
                                  <label className={classes.supportText}>
                                    Supported format : All images formats
                                  </label>
                                </Box>
                              </Box>
                              <input
                                type="file"
                                name={x.FieldName}
                                multiple
                                onChange={(event) => {
                                  setFiles(event.target.files[0]);
                                }}
                                hidden
                                accept=".png,.jpeg,.xlsx,.pdf"
                                ref={inputRef}
                              />
                            </Box>
                            {files && (
                              <Box sx={{ width: "99%", my: "2%" }}>
                                <label className={classes.uploadedLabel}>
                                  {" "}
                                  Uploaded{" "}
                                </label>
                                <Box className={classes.uploadedFileBox}>
                                  <label className={classes.uploadedFileName}>
                                    {" "}
                                    {files?.name}{" "}
                                  </label>
                                  <Box
                                    component="img"
                                    src={DeleteIcon}
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setFiles(null);
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                          </>
                        )}
                        {x.FieldType === "voucherFiles" && (
                          <>
                            <Box
                              component={"a"}
                              sx={{
                                width: "30%",
                                mx: "auto",
                                background: "#C64091",
                                color: "#fff",
                                borderRadius: "5px",
                                padding: "5px",
                                fontFamily: "Poppins",
                                cursor: "pointer",
                                textDecoration: "none",
                                textAlign: "center",
                                mb: "2%",
                              }}
                              color="primary"
                              download="VouhcerCodeTemplate"
                              target="_blank"
                              href={VouhcerCodeTemplateFile}
                            >
                              <Typography
                                color="#fff"
                                sx={{
                                  mx: "auto",
                                }}
                              >
                                Download VoucherFile code
                              </Typography>
                            </Box>
                            {ProductData?.ProductsVariantions?.map(
                              (item, index) => {
                                return (
                                  <Box key={item._id}>
                                    <Box>
                                      <label
                                        className={classes.fieldLabel}
                                        style={{ mt: "2rem" }}
                                      >
                                        Upload file containing{" "}
                                        {item.TotalAvailableQty} Voucher Codes
                                        <span style={{ color: "red" }}> *</span>
                                      </label>
                                    </Box>
                                    <Box
                                      className={classes.fileBox}
                                      onDragOver={handleDragOver}
                                      onDrop={handleDrop}
                                      // onClick={() => inputRef.current.click()}
                                    >
                                      <Box className={classes.fileInnerBox}>
                                        <Box
                                          className={classes.fileBoxContainer}
                                        >
                                          <Box
                                            component="img"
                                            src={UploadtoCloud}
                                            sx={{
                                              position: "absolute",
                                              left: "10%",
                                            }}
                                          />
                                          <label
                                            className={classes.dragLabel}
                                            onClick={() => {
                                              fileInputRefs.current[
                                                index
                                              ].click();
                                            }}
                                          >
                                            Drag & Drop upload or browse to
                                            choose a file
                                          </label>
                                          <label
                                            className={classes.supportText}
                                          >
                                            Supported format : XLSX
                                          </label>
                                        </Box>
                                      </Box>
                                      <input
                                        type="file"
                                        name={x?.FieldName}
                                        onChange={(e) => {
                                          handleFileChange(e, item, index);
                                        }}
                                        hidden
                                        accept=".xlsx"
                                        ref={createRefCallback(index)}
                                      />
                                    </Box>
                                    {VoucherCodeFiles.some(
                                      (uploadedFile) =>
                                        uploadedFile.id === item._id
                                    ) && (
                                      <Box sx={{ width: "99%", my: "2%" }}>
                                        <label
                                          className={classes.uploadedLabel}
                                        >
                                          {" "}
                                          Uploaded{" "}
                                        </label>
                                        <Box
                                          className={classes.uploadedFileBox}
                                        >
                                          <label
                                            className={classes.uploadedFileName}
                                          >
                                            {" "}
                                            {
                                              VoucherCodeFiles.find(
                                                (file) => file.id === item._id
                                              )?.file.name
                                            }{" "}
                                          </label>
                                          <Box
                                            component="img"
                                            src={DeleteIcon}
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setVoucherCodeFiles((prev) =>
                                                prev.filter(
                                                  (file) => file.id !== item._id
                                                )
                                              );
                                              clearInput(
                                                fileInputRefs.current[index]
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                );
                              }
                            )}
                          </>
                        )}

                        {errors[x.FieldName] && (
                          <span className={classes.validationError}>
                            {errors[x.FieldName]}
                          </span>
                        )}
                      </>
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          );
        default:
          return "";
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "716px",
          height: "auto",
          mx: "auto",
        }}
      >
        {/* <ToastContainer /> */}
        <Box
          sx={{
            px: "30px",
            height: "auto",
            maxHeight: "100%",
            background: "#EEF1F6",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EEF1F6",
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "0",
              py: "10px",
            }}
          >
            <label className={classes.pageTitle}>Technical Information</label>
            <ToolTip
              info={
                "Technical Information refers to specific details and specifications about a product's technical aspects, packaging Material, packing size, Dimensions, logistic or go live information for your offered product, This is Critical Information from Logistic & Buying Perspective for Making Informed Decisions"
              }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: "100%",
              overflowY: "hidden",
            }}
          >
            <Stack
              sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                  display: "flex",
                },
                "::-webkit-scrollbar-thumb": {
                  dynamic: "#8d8e90",
                  minHeight: "10px",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:vertical": {
                  maxHeight: "30px",
                },
                maxHeight: "100%",
                height: "100%",
                p: 1,
              }}
            >
              {dbData &&
              dbData["StepTechInfo"] &&
              dbData["StepTechInfo"].length > 0
                ? renderFormFields(dbData["StepTechInfo"])
                : null}
            </Stack>
          </Box>
        </Box>
        <div className={classes.formNavigation}>
          <div
            className={classes.formNavigationBar}
            style={{ padding: "0 30px" }}
          >
            <button
              className={classes.resetLabel}
              onClick={() => {
                setFormValues({});
              }}
            >
              &nbsp;
              {/* <Box component="img" sx={{ width: "23px", height: "23px" }} src={RedoIcon} alt="" /> Reset to Default */}
            </button>
            <div
              className={classes.navigationButtonSection}
              style={{ padding: "10px" }}
            >
              <button
                className={classes.navigationCancelButton}
                onClick={() => {
                  let confirm = window.confirm(
                    "Are you sure you want to cancel the product?"
                  );
                  if (confirm) {
                    navigate("/home/sellerhub");
                  }
                }}
              >
                Cancel
              </button>
              <button type="submit" className={classes.navigationSubmitButton}>
                {isLoading ? <CircularProgress size={20} /> : "Next"}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </form>
  );
};

export default TechInfoTemplate;

const InputPropsStyle = {
  disableUnderline: true,
  style: {
    background: "#fff",
    fontFamily: "Poppins",
    color: "#C64091",
    borderRadius: "9px",
    height: "100%",
    paddingLeft: "10px",
    fontSize: "14px",
  },
};

const TextFieldStyle = {
  width: "100%",
  height: "48px",
  background: "#fff",
  borderRadius: "9px",
  border: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#C64091",
  overflow: "auto",
  paddingLeft: "0px",
  "&:focus": {
    outline: "none",
  },
};
